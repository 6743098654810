<breadcrumb></breadcrumb>
<div class="row" *ngIf="project_profile_id && project_supervision_id">
	<div class="col-md-2 col-sm-3 col-xs-12 mb-10">
		<button
			type="button"
			routerLink="/projects/BbVUrWitLE"
			class="albutton"
			mat-button
		>
			{{ "lang_all_projects" | language }}
		</button>
	</div>
	<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
		<button
			type="button"
			routerLink="/projects/LtYh/{{ project_profile_id }}"
			class="albutton"
			mat-button
		>
			{{ "lang_go_to_project_view" | language }}
		</button>
	</div>
	<div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
	<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
		<button
			type="button"
			routerLink="/projects/Rpq7t/{{ project_profile_id }}/{{
				project_supervision_id
			}}"
			class="albutton"
			mat-button
		>
			{{ "lang_supervision_operations" | language }}
		</button>
	</div>
</div>

<div class="row pt-2">
	<div class="col-12">
		<mat-card style="height: 95%;">
			<mat-card-title>
				{{"lang_units" | language}}
			</mat-card-title>
			<mat-card-content>
				<div class="row">
					<div class="col-12 col-sm-3 mb-3">
						<div class="card-item" *ngIf="!count_data_loading">
							<div class="item-icon">
								<i class="fa fa-map"></i>
							</div>
							<div class="item-text">
								<h5>{{'lang_zones' | language}}</h5>
								<h4>{{project_setting_data?.total_no_of_zones}}</h4>
							</div>
						</div>
						<ngx-skeleton-loader  style="width: 100%;" *ngIf="count_data_loading"  count="1" appearance="circle" [theme]="{
							width: '100%',
							height: '70px',
							'border-radius': '10px'
							}"></ngx-skeleton-loader>
					</div>
					<div class="col-12 col-sm-3 mb-3">
						<div class="card-item" *ngIf="!count_data_loading" style="background: linear-gradient(60deg, #2644da, #006dc1);">
							<div class="item-icon">
								<i class="fa fa-th-large"></i>
							</div>
							<div class="item-text">
								<h5>{{'lang_blocks'|language}}</h5>
								<h4>{{project_setting_data?.total_no_of_blocks}}</h4>
							</div>
						</div>
						<ngx-skeleton-loader  style="width: 100%;" *ngIf="count_data_loading"  count="1" appearance="circle" [theme]="{
							width: '100%',
							height: '70px',
							'border-radius': '10px'
							}"></ngx-skeleton-loader>
					</div>
					<div class="col-12 col-sm-3 mb-3">
						<div class="card-item" *ngIf="!count_data_loading" style="background: linear-gradient(60deg, #26dad2, #00c174);">
							<div class="item-icon">
								<i class="fa fa-road"></i>
							</div>
							<div class="item-text">
								<h5>{{'lang_clusters'|language}}</h5>
								<h4>{{project_setting_data?.total_no_of_clusters}}</h4>
							</div>
						</div>
						<ngx-skeleton-loader  style="width: 100%;" *ngIf="count_data_loading"  count="1" appearance="circle" [theme]="{
							width: '100%',
							height: '70px',
							'border-radius': '10px'
							}"></ngx-skeleton-loader>
					</div>
					<div class="col-12 col-sm-3 mb-3">
						<div class="card-item" *ngIf="!count_data_loading" style="background: linear-gradient(60deg, #be26dae3, #c15000d1);">
							<div class="item-icon">
								<i class="fa fa-building"></i>
							</div>
							<div class="item-text">
								<h5>{{'lang_units'|language}}</h5>
								<h4>{{project_setting_data?.total_no_of_units}}</h4>
							
							</div>
						</div>
						<ngx-skeleton-loader  style="width: 100%;" *ngIf="count_data_loading"  count="1" appearance="circle" [theme]="{
							width: '100%',
							height: '70px',
							'border-radius': '10px'
							}"></ngx-skeleton-loader>
					</div>
				</div>
				
			</mat-card-content>
		</mat-card>
	</div>
	<div class=" col-sm-12">
		<mat-card>
			<mat-card-title class="d-flex justify-content-between flex-wrap">
				{{"lang_project_plan" | language}}
			</mat-card-title>
			<mat-card-content>
				<div class="row">
					<div class="col-12">
						<div id="axis3-chart" class="axis3-chart">
						
						</div>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
	<div class=" col-sm-12">
		<mat-card>
			<mat-card-title>
				{{"lang_project_date" | language}}
			</mat-card-title>
			<mat-card-content>
				<div class="row">
					<div class="col-12 col-md-5">
						<div class="row">
							<div class="col-12 mb-5 d-flex justify-content-around">
								<span style="font-size: 20px;font-weight: 600;">{{"lang_from" | language}}: {{project_setting_data?.supervision_start_date}}</span>
								<span style="font-size: 20px;font-weight: 600;">{{"lang_to" | language}}: {{project_setting_data?.supervision_expiry_date}}</span>
							</div>
							<div class="col-12 col-md-6 mb-3">
								<div class="card-item" style="background: linear-gradient(60deg, #2196f3, #3f51b5);">
									<div class="item-icon">
										<i class="fa fa-calendar"></i>
									</div>
									<div class="item-text">
										<h5>{{'lang_total_days'|language}}</h5>
										<h4>{{project_setting_data?.total_days}} {{"lang_day" | language}}</h4>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-6 mb-3">
								<div class="card-item" style="background: linear-gradient(60deg, #26dad2, #00c174);">
									<div class="item-icon">
										<i class="fa fa-calendar"></i>
									</div>
									<div class="item-text">
										<h5>{{'lang_done_days'|language}}</h5>
										<h4>{{project_setting_data?.done_days}} {{"lang_day" | language}}</h4>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-6 mb-3">
								<div class="card-item" style="background: linear-gradient(60deg, #ef7067, #ed3b78);">
									<div class="item-icon">
										<i class="fa fa-calendar"></i>
									</div>
									<div class="item-text">
										<h5>{{'lang_left_days'|language}}</h5>
										<h4>{{project_setting_data?.left_days}} {{"lang_day" | language}}</h4>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-6 mb-3">
								<div class="card-item" style="background: linear-gradient(60deg, #d7688d, #cd3535)">
									<div class="item-icon">
										<i class="fa fa-calendar"></i>
									</div>
									<div class="item-text">
										<h5>{{'lang_late_days'|language}}</h5>
										<h4>{{project_setting_data?.late_days}} {{"lang_day" | language}}</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-7">
						<div id="project-time-chart" class="project-time-chart">
						</div>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
	<div class="col-12">
		<mat-card>
			<mat-card-title>
				{{"lang_total_forms" | language}} <span style="font-weight: 600;margin-left: 5px;"></span>
			</mat-card-title>
			<mat-card-content>
				<div class="row">
					<div class="col-12">
						<div class="forms-chart" id="forms-chart"></div>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>

	<div class=" col-sm-12">
		<mat-card>
			<mat-card-title class="d-flex justify-content-between">
				<span>{{"lang_compeletion_rate" | language}}</span>
				<span>
					<i class="fa fa-filter mnabricon" matTooltip="{{ 'lang_advanced_filter' | language }}"  (click)="openAverageFilters()"></i>
				</span>
			</mat-card-title>
			<mat-card-content>
				<div class="row">
					<div class="col-12">
						<div class="row">
							<div class="col-12 col-md-3 mb-4">
								<div class="card-item" style="background: linear-gradient(60deg, #688bd7, #35aacd)">
									<div class="item-icon">
										<i class="fa fa-calendar"></i>
									</div>
									<div class="item-text" style="align-items: start;width: 100%;">
										<h5 style="font-size: 16px;
										text-transform: capitalize;
										letter-spacing: normal;font-weight: 600;">{{'lang_from'|language}} : {{from_date_compeletion}}</h5>
										<h4 style="font-size: 16px;font-weight: 600;">{{'lang_to'|language}} : {{to_date_compeletion}}</h4>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-3 mb-4">
								<div class="card-item" style="background: linear-gradient(60deg, #689ad7, #2860c7);flex-direction: column;
								padding: 0px;
								padding-top: 10px;">
									<div class="item-text" style="flex-direction: row;
									justify-content: space-between;
									padding: 16px;width: 100%;flex-wrap: wrap">
										<h5 style="font-size: 1rem;
										font-weight: 600;
										margin-bottom: 0;">{{'lang_total_ratio'|language}}</h5>
										<h4 style="font-size: 1rem;
										font-weight: 600;">{{project_total_ratio}} %</h4>
									</div>
									<mat-progress-bar mode="determinate" [value]="project_total_ratio" style="margin: 0;
									height: 5px !important;
									border-bottom-left-radius: 5px;
									border-bottom-right-radius: 5px;"></mat-progress-bar>
								</div>
							</div>
							<div class="col-12 col-md-3 mb-4">
								<div class="card-item" style="background: linear-gradient(60deg, #7780eb, #1e79e9);flex-direction: column;
								padding: 0px;
								padding-top: 10px;">
									<div class="item-text" style="flex-direction: row;
									justify-content: space-between;
									padding: 16px;width: 100%;flex-wrap: wrap">
										<h5 style="font-size: 1rem;
										font-weight: 600;
										margin-bottom: 0;">{{'lang_total_weight'|language}}</h5>
										<h4 style="font-size: 1rem;
										font-weight: 600;">{{total_weight_result}} %</h4>
									</div>
									<mat-progress-bar mode="determinate" [value]="total_weight_result" 
											style="margin: 0;
											height: 5px !important;
											border-bottom-left-radius: 5px;
											border-bottom-right-radius: 5px;">
									</mat-progress-bar>
								</div>
							</div>
							<div class="col-12 col-md-3 mb-4">
								<div class="card-item" style="background: linear-gradient(60deg, #689ad7, #2860c7);flex-direction: column;
								padding: 0px;
								padding-top: 10px;">
									<div class="item-text" style="flex-direction: row;
									justify-content: space-between;
									padding: 16px;width: 100%;flex-wrap: wrap;">
										<h5 style="font-size: 1rem;
										font-weight: 600;
										margin-bottom: 0;">{{'lang_total_opening'|language}}</h5>
										<h4 style="font-size: 1rem;
										font-weight: 600;">{{total_opening}} %</h4>
									</div>
									<mat-progress-bar mode="determinate" [value]="project_total_ratio"
											style="margin: 0;
											height: 5px !important;
											border-bottom-left-radius: 5px;
											border-bottom-right-radius: 5px;">
									</mat-progress-bar>
								</div>
							</div>
							<div class="col-md-6">
								<div id="zone-result-chart1" class="zone-result-chart">
						
								</div>
							</div>
							<div class="col-md-6">
								<div id="zone-result-chart2" class="zone-result-chart">
						
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 mt-3">
						<div class="row">
							<div class="col-md-6">
								<div id="division-result-chart1" class="division-result-chart">
									
								</div>
							</div>
							<div class="col-md-6">
								<div id="division-result-chart2" class="division-result-chart">
						
								</div>
							</div>
						</div>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>

	<div class=" col-sm-12" *ngIf="is_price_allow">
		<mat-card>
			<mat-card-title class="d-flex justify-content-between flex-wrap">
				<span>{{"lang_project_price" | language}}</span>
				<span><i class="fa fa-filter mnabricon ml-1 mr-1" matTooltip="{{ 'lang_advanced_filter' | language }}"  (click)="openPriceFilters()"></i></span>
			</mat-card-title>
			<mat-card-content>
				<div class="row">
					<div class="col-12 col-sm-3 mb-3">
						<div class="card-item" *ngIf="!count_data_loading">
							<div class="item-icon">
								<i class="fa fa-dollar"></i>
							</div>
							<div class="item-text" style="width: 70%;">
								<h5>{{'lang_projects_total_construction_cost' | language}}</h5>
								<h4>{{convertNumToCurrency(total_construction_cost)}}</h4>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-3 mb-3">
						<div class="card-item">
							<div class="item-icon">
								<i class="fa fa-dollar"></i>
							</div>
							<div class="item-text" style="width: 70%;">
								<h5>{{'lang_this_template_cost' | language}}</h5>
								<h4>{{convertNumToCurrency(template_price)}}</h4>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-3 mb-3">
						<div class="card-item">
							<div class="item-icon">
								<i class="fa fa-dollar"></i>
							</div>
							<div class="item-text" style="width: 70%;">
								<h5>{{'lang_total_price' | language}}</h5>
								<h4>{{convertNumToCurrency(total_project_price)}}</h4>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div id="zone-price-chart" class="zone-price-chart">
						
						</div>
					</div>
					<div class="col-12">
						<div id="division-price-chart" class="division-price-chart">
						
						</div>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>

	
</div>
