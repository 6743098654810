import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import * as mnabreditor from '../../../../../../ckeditor5/build/ckeditor';

declare var $:any;
@Component({
  selector: 'app-formhrj1-add',
  templateUrl: './formhrj1-add.component.html',
  styleUrls: ['./formhrj1-add.component.scss']
})
export class Formhrj1AddComponent implements OnInit {
  public Editor = mnabreditor;
  @Output() joiningEvent = new EventEmitter();
  @Input() employee_data;
  @Input() form_request_id;
  @Input() form_key;
  @Input() id;
  @Input() branch_id;
  form:FormGroup;
  lang_key          = localStorage.getItem('lang_key') || 'en';
  submitted         = false;
  joining_type:any  = [];
  users:any         = [];
  markstatus        = false;
  reviewstatus      = false;
  showmarkdelete    = true;
  showreviewdelete  = true;
  direct_anager = []
  constructor(public ds:DataService, public datepipe : DatePipe , public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.spinner.show();
    this.load_required_datas();
    this.form = new FormGroup({
      subject                           : new FormControl(this.lang.transform('lang_joining_form'), [Validators.required]),
      transaction_from                  : new FormControl({value : this.employee_data.branch_name, disabled : true}, [Validators.required]),
      employee_number                   : new FormControl(this.employee_data.employee_number, [Validators.required]),
      joining_type                      : new FormControl('', [Validators.required]),
      joining_start_date_english        : new FormControl('', [Validators.required]),
      joining_start_date_arabic         : new FormControl(null, [Validators.required]),
      joining_description               : new FormControl(null, [Validators.required]),     
      direct_manager                    : new FormControl({value:null,disabled:this.direct_anager.length > 0}, [Validators.required]),     

     
    });
  
  }
  
  public load_required_datas() {
    let param = new FormData();
    param.append('lang_key', this.lang_key);
    /* param.append('employee_number', this.employee_data.employee_number); */
    let url = ['form/FORM_HRJ1/joining_type','form/FORM_HRJ1/appusers'];
    this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(res => {
      this.spinner.hide();
      if(res[0].status) {
        this.joining_type = res[0].records;
      }
      this.users = res[1].records;
      /* if(res[1].status) {
       ;
        this.users.forEach((v, k) => {
          if(v.position == "A" && v.value) {
            this.form.get('signature_a_title').setValue(v.title);
            this.form.get('signature_a_user_id').setValue(v.value);
          }
          if(v.position == "B" && v.value) {
            this.form.get('signature_b_title').setValue(v.title);
            this.form.get('signature_b_user_id').setValue(v.value);
          }
          if(v.position == "C" && v.value) {
            this.form.get('signature_c_title').setValue(v.title);
            this.form.get('signature_c_user_id').setValue(v.value);
          }
        });
        if(this.form.get('signature_b_user_id').value) {

        } else {
          this.form.get('signature_b_title').setValue('');
          this.form.get('signature_b_title').enable();
          this.form.get('signature_b_user_id').setValue(null);
          this.form.get('signature_b_user_id').enable();
        }
      } */
    }, error => {
      this.spinner.hide();
    });
  }
  //Other Terms & Conditions End
  public search_users(key) {
		this.users = [];
		let param = new FormData();
		param.append('search', key.term);
		param.append('lang_key', this.lang_key);
		param.append('user_type_id', '1');
		this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.users = res.list;
			}
		})
	}
  /* public enable_field(field) {
		if (field == 'mark') {
      this.form.get('needMark').setValue('1');
		  this.form.get('marks').setValidators(Validators.required);
		  this.form.get('marks').updateValueAndValidity();
		  this.markstatus = true;
		} else if (field == 'review') {
      this.form.get('needReview').setValue('1');
		  this.form.get('reviews').setValidators(Validators.required);
		  this.form.get('reviews').updateValueAndValidity();
		  this.reviewstatus = true;
		}
	}
	public disable_field(field) {
		if (field == 'mark') {
      this.form.get('needMark').setValue('0');
			this.form.get('marks').setValue(null);
			this.form.get('marks').clearValidators();
			this.form.get('marks').updateValueAndValidity();
			this.markstatus = false;
		} else if (field == 'review') {
      this.form.get('needReview').setValue('0');
			this.form.get('reviews').setValue(null);
			this.form.get('reviews').clearValidators();
			this.form.get('reviews').updateValueAndValidity();
			this.reviewstatus = false;
		}
		this.form.updateValueAndValidity();
  } */
  public submit() {
    this.alert.clear();
    this.submitted = true;
    if(this.form.valid) { this.submitted = false; }
    this.spinner.show();
    let param = new FormData();
    param.set('transaction_request_id', this.form_request_id);
    param.set('subject', this.form.get('subject').value || '');
    param.set('employee_number', this.form.get('employee_number').value || '');
    param.set('transaction_from', this.form.get('transaction_from').value || '');
    param.set('joining_type', this.form.get('joining_type').value || '');
    param.set('joining_start_date_english', this.form.get('joining_start_date_english').value || '');
    param.set('joining_start_date_arabic', this.form.get('joining_start_date_arabic').value || '');
    param.set('joining_description', this.form.get('joining_description').value || '');
    param.set('direct_manager', this.form.get('direct_manager').value || '');

    /* param.set('signature_a_title', this.form.get('signature_a_title').value || '');
    param.set('signature_a_user_id', this.form.get('signature_a_user_id').value || '');
    param.set('signature_b_title', this.form.get('signature_b_title').value || '');
    param.set('signature_b_user_id', this.form.get('signature_b_user_id').value || '');
    param.set('signature_c_title', this.form.get('signature_c_title').value || '');
    param.set('signature_c_user_id', this.form.get('signature_c_user_id').value || ''); */
    /* param.set('needMark', this.form.get('needMark').value);
    if (this.form.get('needMark').value == "1") {
      param.set('marks', this.form.get('marks').value.toString() || '');
    }
    param.set('needReview', this.form.get('needReview').value || '');
    if (this.form.get('needReview').value == "1") {
      param.set('reviews', this.form.get('reviews').value.toString() || '');
    } */
    this.ds.postActionByUrl(param,'form/FORM_HRJ1/cr/0').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
          this.alert.success(this.lang.transform('lang_success'));
          this.form.reset();
          setTimeout(() => {
            this.router.navigate(['transactions/allforms/']);
          }, 1000);
      } else {
          this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
          this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
}
