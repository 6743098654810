<h3 mat-dialog-title>{{ "lang_template_filter" | language }}</h3>
<mat-dialog-content class="mat-typography">
	<div class="row">
        <div class="col-12 col-sm-6 mb-2">
            <label for="zone">{{ "lang_zone" | language }}</label>
			<ng-select
                [items]="zones"
                [multiple]="false"
                bindLabel="label"
                appendTo="body"
                clearAllText="Clear"
                placeholder="{{ 'lang_zone' | language }}"
                [(ngModel)]="zone"
                id="zone"
                (change)="get_blocks()"
            >
            </ng-select>
        </div>
        <div class="col-12 col-sm-6 mb-2">
            <label for="block">{{ "lang_blocks" | language }}</label>
            <ng-select
                [items]="blocks"
                [multiple]="false"
                bindLabel="label"
                appendTo="body"
                clearAllText="Clear"
                placeholder="{{ 'lang_blocks' | language }}"
                [(ngModel)]="block"
                id="block"
                (change)="get_clusters()"
            >
            </ng-select>
        </div>
        <div class="col-12 col-sm-6 mb-2">
            <label for="cluster">{{ "lang_clusters" | language }}</label>
            <ng-select 
                [items]="clusters"
                [multiple]="false"
                bindLabel="label"
                appendTo="body"
                clearAllText="Clear"
                placeholder="{{ 'lang_clusters' | language }}"
                [(ngModel)]="cluster"
                id="cluster"
            >
            </ng-select>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div style="width: 100px">
        <button
            mat-button
            class="albutton mnabrbutton mat-accent"
            (click)="saveZoneFilter()"
        >
            {{ "lang_save" | language }}
        </button>
    </div>
</mat-dialog-actions>
