import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-supervision-teams',
  templateUrl: './supervision-teams.component.html',
  styleUrls: ['./supervision-teams.component.scss']
})
export class SupervisionTeamsComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() supervision_data
  @Input() able2add
  @Input() able2edit
  @Input() project_data
  searchKey = "";
  able2delete = JSON.parse(this.ls.getItem('is_admin'));
  settings = environment.singledropdown;
  multiple = environment.multipledropdown;
  users:any = [];
  positionItems:any = [];
  positions:any = [];
  tpageno = 1;
  tpagesize = 10;
  tListData:any = [];
  tnodata: string = this.lang.transform('lang_loading');
  error_message:string = "";
  error_status:boolean = false;
  form:FormGroup;
  team_posistions:FormArray;
  submitted = false;
  viewdata:any = [];
  directors:any = [];
  managers:any = [];
  public config = environment.modelconfig;
  //form steps
  forms = [];
  formName = 'FORM_WIR';
  tableHeaders = [];
  tableContent = [];
  step_completed      = true;
  step_pending        = true;
  form_steps_pageno   = 1;
  form_steps_pagesize = 10;
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.get_positions();
    this.load_supervision_teams();
    this.buildform();
  }
  public selectallposition() {
    this.positionItems = this.positions.map(item => item.value);
    this.load_supervision_teams();
  }
  public get_positions() {
		this.positions = [];
		this.ds.getActionByUrl([], '366484fd45').subscribe(res => {
			if(res.status) {
				this.positions = res.records;
			}
		})
  }
  public buildform() {
    this.form = new FormGroup({
      director_position_id            : new FormControl('', [Validators.required]),
      project_supervision_director    : new FormControl('', [Validators.required]),
      manager_position_id             : new FormControl('', [Validators.required]),
      project_supervision_manager     : new FormControl('', [Validators.required]),
      teams                           : this.fb.array([]), 
    });
    this.team_posistions = this.form.get("teams") as FormArray;
  }
  public items_group() {
		return this.form.get("teams") as FormArray;
	}
	get itemsgroup() {
		return this.form.get("teams") as FormArray;
	}
	public add_new_items() {
		if (this.team_posistions.length < 25) {
			this.team_posistions.push(this.item_records());
		}
	}
	public removeItems(index) {
    this.users = [];
		if (this.team_posistions.length > 1) {
			this.team_posistions.removeAt(index);
		}
	}
	public item_records(): FormGroup {
		return this.fb.group({
      project_settings_id: new FormControl(null, [Validators.required]),
      project_user_group_user_id: new FormControl(null, [Validators.required]),			
		});
  }
  public search_users(key) {
    this.users = [];
		let param = new FormData();
		if(key.term && key.term != undefined) {
      param.append('user_type_id', '1');
      param.append('branch_id', this.project_data.branch_id);
      param.append('search', key.term);
      this.ds.getActionByUrl(this.ds.formData2string(param), 'sZMVmoFEATU9YlN/projects_add').subscribe(res => {
        if (res.status) {
          this.users = res.records;
        }
      })
    }
  }
  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }
  public emptyform() {
    this.alert.clear();
    this.form.reset();
    this.itemsgroup.controls = [];
    this.add_new_items();
  }
  public emptyFormEdit() {
    this.alert.clear();
    this.itemsgroup.controls = [];
    this.spinner.show();
		this.ds.getActionByUrl([], 'jS94xI5PLIi5HHW/'+this.supervision_data.projects_profile_id+'/'+this.supervision_data.projects_work_area_id).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.users = res.records;
        res.records.forEach((v, k) => {
          if(v.project_user_group_mention_key == "PSD") {
            this.form.get('director_position_id').setValue(res.records[k]['project_settings_id']);
            this.form.get('project_supervision_director').setValue(res.records[k]['value']);
          }
          if(v.project_user_group_mention_key == "PSM") {
            this.form.get('manager_position_id').setValue(res.records[k]['project_settings_id']);
            this.form.get('project_supervision_manager').setValue(res.records[k]['value']);
          }
        });
        let uniquesettings = this.getDistinct(res.records);
        uniquesettings.forEach((v, k) => {
          if(v.key == "PSV") {
            this.add_new_items();
            this.team_posistions.controls[k].get('project_settings_id').setValue(v.value);
            this.team_posistions.controls[k].get('project_user_group_user_id').setValue(this.team_users(res.records, v.value));
          }
        });
      } else {
        this.add_new_items();
      }
		}, error => {
      this.spinner.hide();
      this.add_new_items();
    });
  }
  private team_users(records, id) {
    let ss = [];
    records.forEach((v, k) => {
      if(v.project_settings_id === id)   {
        ss.push(v.value);
      }
    });
    return ss;
  }
  public getDistinct(data){
    let resArr = [];
    let uniqueid = [];
    data.forEach(function(item) {
      if (uniqueid.indexOf(item.project_settings_id) === -1 && item.project_settings_id !="0") {
        uniqueid.push(item.project_settings_id);
        resArr.push({value: item.project_settings_id, label: item.position, key : item.project_user_group_mention_key});
      }
    });
    return resArr;
  }
  public submit() {
    this.spinner.show();
    let param = new FormData();
    param.set('projects_profile_id', this.supervision_data.projects_profile_id);
    param.set('projects_supervision_id', this.supervision_data.projects_supervision_id);
    param.set('director_position_id', this.ds.select_array_string(this.form, 'director_position_id', 'value') || '');
    param.set('project_supervision_director', this.ds.select_array_string(this.form, 'project_supervision_director', 'value') || '');
    param.set('manager_position_id', this.ds.select_array_string(this.form, 'manager_position_id', 'value') || '');
    param.set('project_supervision_manager', this.ds.select_array_string(this.form, 'project_supervision_manager', 'value') || '');
    this.team_posistions.value.forEach((v, k) => {
      if(v.project_settings_id) {
        param.set("project_settings_id["+k+"]", v.project_settings_id || '');
      }
      if(v.project_user_group_user_id) {
        param.set("project_user_group_user_id["+k+"]", v.project_user_group_user_id.toString()); 
      }
    });
    this.ds.postActionByUrl(param, 'MII2kBvbAePmHjG').subscribe(res => {
      this.spinner.hide();
      this.error_status = res.status;
      if(res.status) {
        this.alert.success(res.msg);
        this.form.reset();
        this.load_supervision_teams();
        setTimeout(() => {
          this.modalRef.hide();
        }, 1000);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public search_supervision_teams() {
    this.tpageno = 1;
    this.tpagesize = 10;
    this.load_supervision_teams();
  }
  public load_supervision_teams_page(page) {
    this.spinner.show();
    this.tpageno = page;
    this.tpagesize = this.tpagesize;
    this.load_supervision_teams();
  }
  public load_supervision_teams_pagesize(size) {
    this.spinner.show();
    this.tpageno = 1;
    this.tpagesize = size;
    this.load_supervision_teams();
  }
  public load_supervision_teams() {
    let formdata = new FormData();
    formdata.append('search_key', this.searchKey);
    formdata.append('positions', this.positionItems.toString());
    this.ds.getActionByUrl(this.ds.formData2string(formdata), 'PMypAe3X1IuW3jx/'+this.project_data.projects_profile_id+'/'+this.supervision_data.projects_work_area_id+'/'+ this.tpageno + '/' + this.tpagesize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.tListData = res;
      } else {
        this.tListData = [];
        this.tnodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.tListData = [];
      this.tnodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public checkAllItemsTeam(event) {
    if (event.target.checked) {
      $(".supervision_teams .eachItemTeam").prop("checked", true);
      $(".supervision_teams .makeback").addClass("bground");
      $(".supervision_teams .deleteIconDiv").show();
    } else {
      $(".supervision_teams .eachItemTeam").prop("checked", false);
      $(".supervision_teams .makeback").removeClass("bground");
      $(".supervision_teams .deleteIconDiv").hide();
    }
  }
  public checkEachItemTeam(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".supervision_teams .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".supervision_teams .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".supervision_teams .eachItemTeam:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".supervision_teams .eachItemTeam").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".supervision_teams .deleteIconDiv").show();
    } else {
      $(".supervision_teams .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".supervision_teams #allItemsTeam").prop("checked", true);
    } else {
      $(".supervision_teams #allItemsTeam").prop("checked", false);
    }
  }
  public deleteBulkDataTeam() {
    const checkedtotal = [];
    $(".supervision_teams .eachItemTeam:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'Ub9ccBi6kgodkbZ/' + this.supervision_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id).subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.load_supervision_teams();
                $(".supervision_teams #allItemsTeam").prop("checked", false);
                $(".supervision_teams .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }


  scrollLeft() {
    document.getElementById('table-container').scrollLeft -= 150;
  }
  scrollRight() {
    document.getElementById('table-container').scrollLeft += 150;
  }
  selectedListTabValue(e) {
    if(e?.tab?.textLabel == 'teams') {
      
    } else if(e?.tab?.textLabel == 'form_steps') {
      this.getFormsNames();
      this.getFormSteps();
    }
  }
  // form steps 
  getFormsNames() {
    this.spinner.show();
    let params = new FormData();
    params.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || '');
    this.ds.getActionByUrl(this.ds.formData2string(params), 'pr/get_forms_list').subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.forms = res?.records
      }
    }, (err) => {
      this.spinner.hide();
    })
  }
  getFormSteps() {
    this.spinner.show();
    let params = new FormData();
    params.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || '');
    params.append('transaction_key', this.formName || '');
    params.append('user_id', '');
    this.ds.getActionByUrl(this.ds.formData2string(params), 'pr/get_users_report_list').subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.tableHeaders = res?.table_headers;
        this.tableContent = res?.records;
      }
    }, err => {
      this.spinner.hide();
    })
  }
}