<breadcrumb></breadcrumb>
<div class="themesection">
    <div class="container mb-10">
        <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-12 mar0auto"></div>
            <div class="col-md-3 col-sm-6 col-xs-12 mar0auto">
                <div *ngIf="branch_id =='0' " class="red pad0 text-center">{{'lang_choose_branch' | language }}</div>
                <select name="branch_id" class="form-control almnabrformcontrol" matTooltip="{{'lang_branch' | language }}" [(ngModel)]="branch_id" (change)="spinner.show();selectBrach($event.target.value);">
                    <option value="0">{{'lang_choose_options' | language }}</option>
                    <option *ngFor="let branch of branches" value="{{branch.id}}">{{branch.title}}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12 mar0auto" *ngIf="branch_id && branch_id !='0' ">
                <ng-select 
                    [items]="finances" 
                    [multiple]="false" 
                    bindLabel="label" 
                    bindValue="value" 
                    clearAllText="Clear"
                    appendTo="body" 
                    [(ngModel)]="finance_id"
                    placeholder="{{'lang_financial_years' | language}}"
                    (change)="spinner.show();load_purchase_invoices();">
                </ng-select>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12 mar0auto"></div>
        </div>
    </div>
    <section class="panel-expansion matborderno" *ngIf="branch_id && branch_id !='0' ">
        <mat-accordion class="selling_invoices_lists">
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_purchase_invoices' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body">
                    <div class="mbrl15">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <input type="text" placeholder="{{'lang_search' | language }}" (keyup)="search_invoices();" [(ngModel)]="searchKey" class="form-control almnabrformcontrol search_key" id="search_key" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive dropdownyes customResponsive">
                                    <table border="1" class="table CustomTable">
                                        <thead>
                                            <tr>
                                                <td>#</td>
                                                <td>{{'lang_invoice_no' | language }}</td>
                                                <td>{{'lang_transaction_number' | language }}</td>
                                                <td>{{'lang_invoice_date' | language }}</td>
                                                <td>{{'lang_payment_mode' | language }}</td>
                                                <td>{{'lang_suppliers' | language }}</td>
                                                <td>{{'lang_writer' | language }}</td>
                                                <td>{{'lang_ondate' | language }}</td>
                                                <td>{{'lang_action' | language }}</td>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="InvData.length != '0' " class="AppendList">
                                            <tr *ngFor="let data of InvData.records; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{data.invoice_id}}">
                                                <td>{{i+1}}</td>
                                                <td>
                                                    <a matTooltip="{{'lang_view' | language }}" routerLink="/accounts/vpinvoices/{{data.branch_id}}/{{data.invoice_id}}/view">{{data.invoice_system_code}}</a>
                                                </td>
                                                <td>
                                                    <a matTooltip="{{'lang_view' | language }}" routerLink="/accounts/vpinvoices/{{data.branch_id}}/{{data.invoice_id}}/view">{{data.transaction_id}}</a>
                                                </td>
                                                <td>
                                                    <a matTooltip="{{'lang_view' | language }}" routerLink="/accounts/vpinvoices/{{data.branch_id}}/{{data.invoice_id}}/view">{{data.invoice_date}}</a>
                                                </td>
                                                <td>
                                                    <a matTooltip="{{'lang_view' | language }}" routerLink="/accounts/vpinvoices/{{data.branch_id}}/{{data.invoice_id}}/view">{{data.payment_name}}</a>
                                                </td>
                                                <td>{{data.customer_account}}</td>
                                                <td>{{data.writer_name}}</td>
                                                <td>{{data.invoice_created_date}}</td>
                                                <td class="dropdown">
                                                    <mat-icon class="mnabricon" [matMenuTriggerFor]="menu">settings</mat-icon>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item matTooltip="{{'lang_view' | language }}" routerLink="/accounts/vpinvoices/{{data.branch_id}}/{{data.invoice_id}}/view"><span>{{'lang_view' | language }}</span></button>
                                                        <button mat-menu-item *ngIf="InvData.permits.edit && InvData.financial_year.finance_id == data.finance_id" routerLink="/accounts/epinvoices/{{data.branch_id}}/{{data.invoice_id}}/edit"><span>{{'lang_edit' | language }}</span></button>
                                                        <button mat-menu-item *ngIf="InvData.permits.delete && InvData.financial_year.finance_id == data.finance_id" (click)="remove_invoice(data)"><span>{{'lang_delete' | language }}</span></button>
                                                        <button mat-menu-item (click)="ds.export_account_transaction(data.invoice_id, 'PINV', 'PDF', branch_id);"><span>{{'lang_export_pdf' | language }}</span></button>
                                                        <button mat-menu-item (click)="ds.export_account_transaction(data.invoice_id, 'PINV', 'EXL', branch_id);"><span>{{'lang_export_excel' | language }}</span></button>
                                                        <button mat-menu-item (click)="ds.export_account_transaction(data.invoice_id, 'PINV', 'EPDF', branch_id);"><span>{{'lang_export_pdf_to_email' | language }}</span></button>
                                                        <button mat-menu-item (click)="ds.export_account_transaction(data.invoice_id, 'PINV', 'EEXL', branch_id);"><span>{{'lang_export_excel_to_email' | language }}</span></button>
                                                    </mat-menu>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="InvData.length == '0' " class="AppendList">
                                            <tr class="odd">
                                                <td colspan="9" align="center">{{lodingdatas}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="InvData.length != '0' " class="card-footer padb0">
                        <div class="row">
                            <div class="col-lg-3 col-sm-6 col-6 mb-10">{{'lang_total' | language }} : {{InvData.paging.total_records}}</div>
                            <div class="col-lg-5 col-sm-6 mb-10">
                                <mat-radio-group>
                                    <mat-radio-button value="10" [checked]="(Invpage_size =='10') ? true : false" (click)="loadInvPagesize(10)"> 10 </mat-radio-button>
                                    <mat-radio-button value="20" [checked]="(Invpage_size =='20') ? true : false" (click)="loadInvPagesize(20)"> 20 </mat-radio-button>
                                    <mat-radio-button value="50" [checked]="(Invpage_size =='50') ? true : false" (click)="loadInvPagesize(50)"> 50 </mat-radio-button>
                                    <mat-radio-button value="100" [checked]="(Invpage_size =='100') ? true : false" (click)="loadInvPagesize(100)"> 100 </mat-radio-button>
                                    <mat-radio-button value="500" [checked]="(Invpage_size =='500') ? true : false" (click)="loadInvPagesize(500)"> 500 </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                {{'lang_goto' | language }} :
                                <select class="customselect" (change)="loadPageInv($event.target.value)">
                                    <option *ngFor="let ks of ds.getrange(InvData.paging.total_pages)" [selected]="(Invpage_no == ks) ? true : false" value="{{ks}}">{{ks}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</div>