import {
	trigger,
	state,
	style,
	transition,
	animate,
} from "@angular/animations";
import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
	PreloaderService,
	SettingsService,
	StartupService,
	TokenService,
} from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { MessagingService } from "@core/bootstrap/messaging.service";
import { MnabrthemeService } from "@core/bootstrap/mnabrtheme.service";
import { PermissionService } from "@core/bootstrap/permission.service";
import { environment } from "@env/environment";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { ApiRoutes } from "app/routes/apiroutes";
@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ['./login.component.scss'],
	animations: [
		trigger("fade", [
			state("in", style({ opacity: 1 })),
			transition(":enter", [style({ opacity: 0 }), animate(2000)]),
			transition(":leave", animate(0, style({ opacity: 0 }))),
		]),
	],
})
export class LoginComponent implements OnInit {
	form: FormGroup;
	profileimage: string = "";
	error_status: boolean = false;
	error_message: string = "";
	languages: any = this.ds.languages;
	options = this.settings.getOptions();
	lang_key = localStorage.getItem("lang_key") || "en";
	constructor(
		public lang: LanguagePipe,
		private fb: FormBuilder,
		private router: Router,
		private token: TokenService,
		private startup: StartupService,
		private theme: MnabrthemeService,
		private settings: SettingsService,
		private ds: DataService,
		private spinner: PreloaderService,
		private ls: LocalStorage,
		private alert: AlertService,
		private permissionService:PermissionService,
		private messagingService: MessagingService
	) {
		this.form = this.fb.group({
			username: ["", [Validators.required]],
			password: ["", [Validators.required]],
		});
	}
	ngOnInit() {}
	switchlanguage(lang): void {
		localStorage.setItem("lang_key", lang.lang_key);
		if (localStorage.getItem("direction") != lang.lang_dir) {
			localStorage.setItem("direction", lang.lang_dir);
			window.location.reload();
		}
	}
	login() {
		let fireToken = this.messagingService.fireToken;
		this.spinner.show();
		const formData = new FormData();
		formData.append("username", this.form.get("username").value);
		formData.append("password", this.form.get("password").value);
		formData.append("noti_registrationId", fireToken);
		formData.append("platform", "web");
		formData.append(
			"language",
			localStorage.getItem("lang_key") == null
				? "en"
				: localStorage.getItem("lang_key")
		);
		this.ds.post(ApiRoutes.login, formData).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					localStorage.setItem("loggedin", "1");
					const { token, uid, username } = {
						token: this.ls.getsetdata("token", res.user_data.token),
						uid: this.ls.getsetdata("user_id", res.user_data.user_id),
						username: this.ls.getsetdata(
							"user_username",
							res.user_data.user_username
						),
					};
					this.settings.setUser({
						id: this.ls.getItem("user_id"),
						name: this.ls.getsetdata(
							"user_username",
							res.user_data.user_username
						),
						email: this.ls.getsetdata("user_email", res.user_data.user_email),
						mobile: this.ls.getsetdata("user_phone", res.user_data.user_phone),
						admin: this.ls.getsetdata("is_admin", res.user_data.is_admin),
						usertype: this.ls.getsetdata(
							"user_type_id",
							res.user_data.user_type_id
						),
						username: this.ls.getItemString("user_username"),
						user_active_status: this.ls.getsetdata(
							"user_active_status",
							res.user_data.user_active_status
						),
					});
					this.permissionService.load()
					this.token.set({ token, uid, username });
					this.theme.load();
					this.startup.load().then(() => {});
					this.router.navigateByUrl("/");
				} else {
					this.alert.error(res.error);
				}
			},
			(error) => {
				this.spinner.hide();
				this.alert.error(
					error && error.error && error.error.error
						? error.error.error
						: this.lang.transform("lang_internal_server_error")
				);
			}
		);
	}

	getProfileImg(e) {
		let params = {
			user_id: "",
			user_username: e?.target?.value,
		};
		let headers = new HttpHeaders();
		headers.append("X-API-KEY", environment.X_API_KEY);
		this.ds
			.getActionByNoTokenFullUrl(params, "user/avatar", headers)
			.subscribe((res) => {
				if (res?.avatar) {
					this.profileimage = res?.avatar;
				} else {
					this.profileimage = "";
				}
			});
	}

	slides = [
		{
			img:
				"https://drive.almnabr.com/apps/files_sharing/publicpreview/Z8ge6mr56k6YXNq?x=1920&y=587&a=true&file=PHOTO-2021-11-03-19-04-07.jpg&scalingup=0",
		},
		{ img: "https://wallpapercave.com/wp/wp8414109.jpg" },
		{
			img:
				"https://wallpaperboat.com/wp-content/uploads/2020/12/07/63377/construction-02.jpg",
		},
	];

	slideConfig2 = {
		className: "center",
		centerMode: true,
		infinite: true,
		centerPadding: "0",
		slidesToShow: 1,
		slidesToScroll: 3,
		autoplay: true,
		autoplaySpeed: 2000,
		speed: 500,
		dots: true,
		rtl: this.lang_key == "en" ? false : true,
		prevArrow: false,
		nextArrow: false,
	};
}
