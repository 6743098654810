import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormCpAddComponent } from '../transactions/FORM_CP/form-cp-add/form-cp-add.component';
import { ProjectsCreateComponent } from './projects-create/projects-create.component';
import { ProjectsEditComponent } from './projects-edit/projects-edit.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { ProjectsViewComponent } from './projects-view/projects-view.component';
import { SupervisionDashboardComponent } from './services/supervision/supervision-dashboard/supervision-dashboard.component';
import { SupervisionMainDashboardComponent } from './services/supervision/supervision-main-dashboard/supervision-main-dashboard.component';
import { TrwHistoryComponent } from './services/supervision/supervision-test-report-workarea/trw-history/trw-history.component';
import { ConsultantSettingsComponent } from './settings/consultant-settings/consultant-settings.component';
import { SettingsComponent } from './settings/settings.component';
const routes: Routes = [
  {
    path:'kxzaCSCss2',
    component : ProjectsCreateComponent,
    data: { title: 'Create New Project' } 
  },
  {
    path:'BbVUrWitLE',
    component : ProjectsListComponent,
    data: { title: 'Projects List' } 
  },
  {
    path: 'LtYh/:id',
    component: ProjectsViewComponent,
    data: { title: 'View Project Details' }
  },
  {
    path:'NxBf/:id',
    component : ProjectsEditComponent,
    data: { title: 'Edit Project Details' }
  },
  {
    path: 'psmaindashboard/:pid/:sid',
    component: SupervisionMainDashboardComponent,
  },
  {
    path: 'Rpq7t/:pid/:sid',
    component: SupervisionDashboardComponent,
  },
  {
    path: 'Rpq7t/:pid/:sid/tsr/history/soil/:tsrid',
    component: TrwHistoryComponent,
  },
  {
    path: 'Rpq7t/:pid/:sid/tsr/history/concrete/:tsrid',
    component: TrwHistoryComponent,
  },
  {
    path: 'Rpq7t/:pid/:sid/cp/create',
    component: FormCpAddComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
  {
    path: 'csettings',
    component: ConsultantSettingsComponent,
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }
