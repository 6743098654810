<div class="w-full h-full" fxLayout="row wrap" [@fade]="'in'">
	 <!-- <div class="search-document">
		<div class="search-document-container">
			<form
				id="form"
				[formGroup]="form"
				class="form"
				(ngSubmit)="spinner.show(); submit()"
			>
				<div class="row">
					<div class="col-12 text-center mb-5 mt-2 search-document-title">
						{{ "lang_search_documents" | language }}
					</div>
					<div class="col-12 col-sm-6 mb-10">
						<div class="form-group">
							<label for="barcode"
								>{{ "lang_document_number" | language }} :
								<span class="red">*</span></label
							>
							<input
								formControlName="barcode"
								type="text"
								placeholder="{{ 'lang_document_number' | language }}"
								class="form-control almnabrformcontrol"
							/>
							<mat-error
								*ngIf="
									form.controls['barcode'].touched &&
									form.controls['barcode'].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
					<div class="col-12 col-sm-6 mb-10">
						<div class="form-group">
							<label for="code"
								>{{ "lang_code" | language }} :
								<span class="red">*</span></label
							>
							<input
								formControlName="code"
								type="text"
								placeholder="{{ 'lang_code' | language }}"
								class="form-control almnabrformcontrol"
							/>
							<mat-error
								*ngIf="
									form.controls['code'].touched &&
									form.controls['code'].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</div>
					</div>
				</div>

				<div class="row" *ngIf="!barcode && !code">
					<div class="col-12 mb-10">
						<button
							class="albutton mnabrbutton mat-accent search-btn"
							mat-button
							[disabled]="!form.valid"
						>
							{{ "lang_search" | language }}
						</button>
					</div>
				</div>
			</form>
		</div>
	</div> -->


	<div class="search-document">
		<div class="s-d-card">
			<div class="row">
				<div class="col-12 col-md-5" style="background: #333846; padding: 70px 20px !important; border-bottom-left-radius: 10px; border-top-left-radius: 10px;">
					<div class="s-d-logo">
						<span style="width: 50px; display: flex;">
							<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 555.05 554.91">
								<defs><style>.cls-1{fill:#ffd740;}.cls-2{fill:#fff;}</style></defs>
								<path class="cls-1" d="M359.09,496.76l-.06.06c-36.58-38-72.4-75.73-81.42-86.82-18.75-23.07-40.4-35-68.89-32.45H186.38V194.61c18-4.84,30.86-.79,43.36,12.43,31.45,33.28,64.48,65.09,96.93,97.38,2.83,2.81,6.23,5,10.08,8.06v-79.9c-24.91,5.53-41.9-4.82-56.89-22.28-12.86-15-27.8-28.19-42-42-3.12-3-7.05-7.33-10.72-7.43-24.58-.65-49.19-.33-74.18-.33V412h69.95c9.09,1.84,15.56,7.25,21.71,13.71l0,0c.93,1,1.85,2,2.78,3l10.25,11.1c.5.5,1,1,1.51,1.48H259l71.82,75.8a103.48,103.48,0,0,1-118.48-19.74L69.78,354.75a103.29,103.29,0,0,1,0-146.09l110-110L157.81,75.92,47.44,186.3a134.88,134.88,0,0,0,0,190.8L190,519.7a134.79,134.79,0,0,0,190.8,0l.19-.2Z" transform="translate(-7.91 -4.25)"/><polygon class="cls-1" points="171.78 72.09 102.11 71.67 137.16 106.72 172.2 141.76 171.78 72.09"/><path class="cls-2" d="M523.44,186.3,380.84,43.7c-47-47-120.22-52-172.81-15.1a133.45,133.45,0,0,0-18,15.1l-7.62,7.61-.28.29c6.91,7.33,14.22,15.05,21.71,23,40.29,42.61,85.47,90.31,102,107.22,3.58,2.75,10,6,20.39,6.42,15.84.4,31.7.14,48.06.14V373.58H350c-8.47-2-16.08-6.74-23.45-14.68-29.25-31.48-60.36-61.21-90.8-91.61-3.28-3.28-7.11-6.05-11.52-9.76v77.31c36.11,4.27,36.11,4.27,55.31,23.29,13.28,13.14,26.49,26.31,39.9,39.29,3.18,3.08,7,7.73,10.57,7.8,25.66.7,51.34.38,76.8.38V156.73H336.37c-9.52-2-16.91-7.25-23.7-14.15l0,0c-3.72-3.75-7.25-8-10.87-12.38L229.7,52.09A103.51,103.51,0,0,1,358.5,66L501.1,208.66a103.29,103.29,0,0,1,0,146.09L383.6,472.25l22.19,22.49L523.44,377.1a134.91,134.91,0,0,0,0-190.8Z" transform="translate(-7.91 -4.25)"/><polygon class="cls-2" points="375.88 490.07 445.55 490.49 410.5 455.44 375.46 420.4 375.88 490.07"/></svg>
						</span>
						<span class="logo-name">Nahidh</span>
					</div>
					<p class="text-center" style="color: #ffffffdb;letter-spacing: 1px;margin-top: 20px;">
						Welcome back! This place hasn’t been the same without you.
					</p>
					<div class="ads-area">
						Ads Area
					</div>
				</div>
				<div class="col-12 col-md-7" style="padding: 70px 20px !important;">
					<form
				id="form"
				[formGroup]="form"
				class="form"
				(ngSubmit)="spinner.show(); submit()"
			>
				<div class="row">
					<div class="col-12 text-center mb-5 mt-2 search-document-title">
						{{ "lang_search_documents" | language }}
					</div>
					<div class="col-12 col-sm-6 mb-10" [dir]="lang_key == 'en' ? 'ltr':'rtl'">
						<mat-form-field appearance="outline" style="width: 100%;">
							<mat-label>{{ "lang_document_number" | language }}</mat-label>
							<input matInput formControlName="barcode" required>
							<mat-error
								*ngIf="
									form.controls['barcode'].touched &&
									form.controls['barcode'].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-12 col-sm-6 mb-10" [dir]="lang_key == 'en' ? 'ltr':'rtl'">
						<mat-form-field appearance="outline" style="width: 100%;">
							<mat-label>{{ "lang_code" | language }}</mat-label>
							<input matInput formControlName="code" required>
							<mat-error
								*ngIf="
									form.controls['code'].touched &&
									form.controls['code'].errors?.required
								"
							>
								{{ "lang_field_required" | language }}
							</mat-error>
						</mat-form-field>
					</div>
				</div>

				<div class="row" *ngIf="!barcode && !code">
					<div class="col-12 mb-10">
						<button
							class="albutton mnabrbutton mat-accent search-btn"
							mat-button
							[disabled]="!form.valid"
						>
							{{ "lang_search" | language }}
						</button>
					</div>
				</div>
			</form>
				</div>
			</div>
		</div>
	</div>
</div>
