import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
interface list {
  id?: number;
  reports_id: string,
  reports_description: string,
  reports_date: string,
  reports_status: string,
  reports_attachment_url: string,
  reports_writer: string,
  reports_created_datetime:string
}
@Component({
  selector: 'app-supervision-reports',
  templateUrl: './supervision-reports.component.html',
  styleUrls: ['./supervision-reports.component.scss']
})
export class SupervisionReportsComponent implements OnInit {
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  modalRef: BsModalRef;
  @Input() supervision_data
  @Input() able2add
  @Input() able2edit
  @Input() project_data
  @Input() filelevels
  able2delete = JSON.parse(this.ls.getItem('is_admin'));
  reppageno = 1;
  reppagesize = 10;
  repListData:any = [];
  repnodata = this.lang.transform('lang_loading');
  reportsearchKey = "";
  reports_status = "";
  project_report_type;
  report_types:any = [];
  form:FormGroup;
  updateform:FormGroup;
  isFile:boolean = false;
  filename = this.lang.transform('lang_select_files');
  listofdatas: list[];
  page = 1;
  pageSize = 10;
  collectionSize;
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.load_report_types();
    this.load_supervision_reports();
    this.form = new FormGroup({
      reports_id: new FormControl(''),
      projects_profile_id: new FormControl(this.supervision_data.projects_profile_id, [Validators.required]),
      projects_supervision_id: new FormControl(this.supervision_data.projects_supervision_id, [Validators.required]),
      project_settings_id: new FormControl('', [Validators.required]),
      reports_status: new FormControl('', [Validators.required]),
      reports_date: new FormControl('', [Validators.required]),
      reports_description: new FormControl('', [Validators.required]),
      reports_attachment_url: new FormControl('', [Validators.required]),
      level_keys : new FormControl("", [Validators.required]),
    });
  }
  public load_report_types() {
    this.report_types = [];
    this.spinner.show();
    this.ds.getActionByUrl([], 'ZyYkGcK85IUr/RT001').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.report_types = res.records;
      }
    }, error => {
      this.spinner.hide();
    })    
  }
  public search_supervision_reports() {
    this.reppageno = 1;
    this.reppagesize = 10;
    this.load_supervision_reports();
  }
  public load_supervision_reports_page(page) {
    this.spinner.show();
    this.reppageno = page;
    this.reppagesize = this.reppagesize;
    this.load_supervision_reports();
  }
  public load_supervision_reports_pagesize(size) {
    this.spinner.show();
    this.reppageno = 1;
    this.reppagesize = size;
    this.load_supervision_reports();
  }
  public load_supervision_reports() {
    let formdata = new FormData();
    formdata.append('search_key', this.reportsearchKey);
    formdata.append('reports_status', this.reports_status);
    formdata.append('project_settings_id', (this.project_report_type) ? this.project_report_type : '');
    this.ds.getActionByUrl(this.ds.formData2string(formdata), 'GaoHtRiv0PTiZSr/'+this.project_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.repListData = res;
        this.listofdatas = res.records;
        this.collectionSize = res.records.length;
        this.refreshReports();
      } else {
        this.repListData = [];
        this.repnodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.repListData = [];
      this.repnodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public refreshReports() {
    this.repListData = this.listofdatas.map(
      (list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize
    );
  }
  public change_report_status(value) {
    this.isFile = false;
    this.filename = this.lang.transform('lang_select_files');
    this.form.get('reports_description').setValue('');
    this.form.get('reports_attachment_url').setValue('');
    this.form.get('reports_attachment_url').clearValidators(); // 5.Set Required Validator
    this.form.get('reports_attachment_url').updateValueAndValidity();
    if(value == "1" && !this.form.get('reports_id').value) {
      this.form.get('reports_attachment_url').setValidators([Validators.required]); // 5.Set Required Validator
      this.form.get('reports_attachment_url').updateValueAndValidity();
    }
  }
  public emptyaddform() {
    this.alert.clear();
    this.form.reset();
    this.form.get('projects_profile_id').setValue(this.supervision_data.projects_profile_id);
    this.form.get('projects_supervision_id').setValue(this.supervision_data.projects_supervision_id);
    this.form.get('reports_status').setValue("1");
    this.form.get('project_settings_id').setValue("");
    this.filename = this.lang.transform('lang_select_files');
  }
  public emptyEditform(list) {    
    this.isFile = false;
    this.alert.clear();
    this.form.reset();
    this.filename = this.lang.transform('lang_select_files');
    this.form.get('reports_id').setValue(list.reports_id);
    this.form.get('projects_profile_id').setValue(this.supervision_data.projects_profile_id);
    this.form.get('projects_supervision_id').setValue(this.supervision_data.projects_supervision_id);
    this.form.get('reports_description').setValue(list.reports_description);
    this.form.get('project_settings_id').setValue(list.project_settings_id);
    this.form.get('reports_status').setValue(list.reports_status);
    this.form.get('reports_date').setValue(this.ds.encon(list.reports_date));
    this.form.get('level_keys').setValue((list.level_keys) ? list.level_keys.split(',') : null);
    this.form.get('reports_attachment_url').clearValidators(); // 5.Set Required Validator
    this.form.get('reports_attachment_url').updateValueAndValidity();
  }
  public UploadFile(event, form) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      this.isFile = true;
      this.filename = event.target.files[0].name;
      form.setValue(event.target.files[0], { emitEvent: true });
    } else {
      this.isFile = false;
      form.setValue(null);
      this.filename = this.lang.transform('lang_select_files');
    }
  }
  public removeFile() {
    this.isFile = false;
    this.form.get("reports_attachment_url").setValue(null);
    this.filename = this.lang.transform('lang_select_files');
  }
  public submit() {
    this.spinner.show();
    let param = new FormData();
    param.append('projects_profile_id', this.supervision_data.projects_profile_id || '');
    param.append('projects_supervision_id', this.supervision_data.projects_supervision_id || '');
    param.append('project_settings_id', this.form.get('project_settings_id').value || '');
    param.append('reports_status', this.form.get('reports_status').value || '');
    param.append('reports_date', this.form.get('reports_date').value || '');
    param.append('reports_description', this.form.get('reports_description').value || '');
    param.append('reports_attachment_url', this.form.get('reports_attachment_url').value || '');
    let url = (this.form.get('reports_id').value && this.form.get('reports_id').value != null) ? 'jJ1woJVnWCmhtgh/'+this.form.get('reports_id').value : '6JODOEhFlPvCrrD';
    this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), url).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.alert.success(res.msg)
        this.form.reset();
        this.load_supervision_reports();
        setTimeout(() => {
          this.modalRef.hide();
        }, 1000);
      } else {
        this.alert.error(res.error)
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'))
    })
  }
  public checkAllItemsRep(event) {
    if (event.target.checked) {
      $(".supervision_reports .eachItemRep").prop("checked", true);
      $(".supervision_reports .makeback").addClass("bground");
      $(".supervision_reports .deleteIconDiv").show();
    } else {
      $(".supervision_reports .eachItemRep").prop("checked", false);
      $(".supervision_reports .makeback").removeClass("bground");
      $(".supervision_reports .deleteIconDiv").hide();
    }
  }
  public checkEachItemRep(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".supervision_reports .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".supervision_reports .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".supervision_reports .eachItemRep:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".supervision_reports .eachItemRep").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".supervision_reports .deleteIconDiv").show();
    } else {
      $(".supervision_reports .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".supervision_reports #AllItemsRep").prop("checked", true);
    } else {
      $(".supervision_reports #AllItemsRep").prop("checked", false);
    }
  }
  public deleteBulkDataRep() {
    const checkedtotal = [];
    $(".supervision_reports .eachItemRep:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, '7Km8Qn9i46gxc7s/' + this.supervision_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id).subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.load_supervision_reports();
                $(".supervision_reports #AllItemsRep").prop("checked", false);
                $(".supervision_reports .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
