import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-tax-settings',
  templateUrl: './tax-settings.component.html',
  styleUrls: ['./tax-settings.component.scss']
})
export class TaxSettingsComponent implements OnInit {
  branches: any = [];
  branch_id: any = (localStorage.getItem('selectedBranch') != null) ? localStorage.getItem('selectedBranch') : '0';
  taxform: FormGroup;
  constructor(public ds:DataService,public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.tax_form();
    if (this.branch_id && this.branch_id != '0') {
      this.get_tax_datas();
    }
  }
  public changeBranch(branch) {
    this.spinner.show();
    this.branch_id = branch;
    this.get_tax_datas();
  }
  public tax_form() {
    this.taxform = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      item_tax: new FormControl(0),
      global_tax: new FormControl(0),
      item_discount: new FormControl(0),
      global_discount: new FormControl(0),
    });
  }
  public get_tax_datas() {
    this.ds.postActionByUrl([], 'gts/' + this.branch_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.taxform.get('branch_id').setValue(res.records.branch_id)
        this.taxform.get('item_tax').setValue(JSON.parse(res.records.item_tax))
        this.taxform.get('global_tax').setValue(JSON.parse(res.records.global_tax))
        this.taxform.get('item_discount').setValue(JSON.parse(res.records.item_discount))
        this.taxform.get('global_discount').setValue(JSON.parse(res.records.global_discount))
      } else {
        this.taxform.reset();
      }
    }, error => {
        this.spinner.hide();
        this.taxform.reset();
    })
  }
  public save_tax() {
    this.spinner.show();
    let param = new FormData();
    param.append('branch_id', this.branch_id);
    param.append('item_tax', (this.taxform.get('item_tax').value == true) ? '1' : '0');
    param.append('global_tax', (this.taxform.get('global_tax').value == true) ? '1' : '0');
    param.append('item_discount', (this.taxform.get('item_discount').value == true) ? '1' : '0');
    param.append('global_discount', (this.taxform.get('global_discount').value == true) ? '1' : '0');
    this.ds.postActionByUrl(param, 'ts').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.get_tax_datas();
        this.ds.dialogf('', res.msg);
      } else {
        this.ds.dialogf('', res.error);
      }
    }, error => {
      this.spinner.hide();
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
}
