import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {
  @Input() user_data:any;
  @Input() able2add:Boolean;
  wholedata: any = [];
  searchKey = '';
  size = 10;
  page = 1;
  constructor(public router:Router, public ds:DataService,public route :ActivatedRoute, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService) { }

  ngOnInit(): void {
    
  }

  get_employee_communications() {

  }

  newCommunication(name) {
    if(name == 'incoming') {
      this.router.navigate(['/Communication/form/FORM_C2/cr/0'], { state: { from: 'human_resources', id: this.user_data?.employee_number }})
    } else if (name == 'outgoing') {
      this.router.navigate(['/Communication/form/FORM_C1/cr/0'], { state: { from: 'human_resources', id: this.user_data?.employee_number }})
    }
    
  }

  public load_page_size(pageno) {
    this.size = pageno;
    this.get_employee_communications();
  }
  public page_length(page) {
    this.page = page;
    this.get_employee_communications();
  }
}
