<form [formGroup]="form" (ngSubmit)="completeStep()">
	<div class="card">
		<div class="card-header">
			{{ "lang_contractor_close_NCR" | language }}
		</div>
		<div class="mbrl15">
			<div class="row p-3">
				<div class="col-sm-6 mb-2">
					<label for="close_ncr_request_status">{{
						"lang_close_ncr_request" | language
					}}</label>
					<ng-select
						[items]="close_ncr_request_arr"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						[searchFn]="ds.customSearchFn"
						appendTo="body"
						formControlName="close_ncr_request_status"
						placeholder="{{ 'lang_close_ncr_request' | language }}"
					>
					</ng-select>
				</div>

				<div class="col-sm-6">
					<label>{{ "lang_note" | language }}</label>
					<textarea
						cols="30"
						rows="10"
						style="max-height: 200px; min-height: 50px; height: 50px"
						formControlName="note"
						placeholder="{{ 'lang_note' | language }}"
						autocomplete="off"
						class="form-control almnabrformcontrol"
					>
					</textarea>
				</div>
			</div>
			<div class="row mb-10">
				<!-- <div class="col-sm-6">
					<div class="form-group">
						<label for="verification_code"
							>{{ "lang_enter_verification_code" | language }}
							<span class="red">*</span>:
							<i
								style="padding: 0 10px"
								class="fa fa-info-circle orangestatus-color"
								matTooltip="{{
									'lang_if_you_not_have_code_hit_sendcode_button' | language
								}}"
							></i>
						</label>
						<input
							type="text"
							required
							class="form-control almnabrformcontrol"
							formControlName="transactions_persons_action_code"
						/>
					</div>
				</div> -->
				<div class="col-sm-12">
					<app-alert id="default-alert"></app-alert>
				</div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<!-- <div>
						<button
							class="albutton mnabrbutton mat-accent"
							type="button"
							(click)="openModal()"
							mat-button
						>
							{{ "lang_send_code" | language }}
						</button>
					</div> -->
				</div>
				<div class="col-sm-4 col-xs-12 mb-10"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<div>
						<input
							type="submit"
							value="{{ 'lang_submit' | language }}"
							class="albutton mnabrbutton mat-accent"
							mat-button
							
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<!--steps history-->
<app-ncr-steps-history
	[allData]="allData"
	[step_no]="11"
></app-ncr-steps-history>
