import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import * as moment from 'moment';

@Component({
  selector: 'app-createBonus',
  templateUrl: './createBonus.component.html',
  styleUrls: ['./createBonus.component.scss']
})
export class CreateBonusComponent implements OnInit {

  @Output() loanEvent = new EventEmitter();
  @Input() employee_data;
  @Input() form_request_id;
  @Input() form_key;
  @Input() id;
 
  users:any         = [];
  direct_anager:any = [];
  form:FormGroup;
  submitted:boolean = false;
  lang_key          = localStorage.getItem('lang_key') || 'en';
  constructor(public ds:DataService, public datepipe : DatePipe ,   private snackBar:MatSnackBar, public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.spinner.show();

    this.form = this.fb.group({
      financial_type:['Bonus'],
      employee_number: ['123'],
      bonus_date_english: ['', Validators.required],
      bonus_date_arabic: ['', Validators.required],
      bonus_amount: ['', Validators.required],
      direct_manager: ['', Validators.required],
   
    });
 
    this.spinner.hide();
    console.log(this.form);
    
  }
  

  
  submit(){
    this.submitted = true;
    if(this.form?.status == 'VALID'){

      this.spinner.show();
      let formData = new FormData();
      formData.append('employee_number',this.employee_data.employee_number);
      formData.append('bonus_date_english',moment(this.form.value.bonus_date_english).format('YYYY/MM/DD'));
      formData.append('bonus_date_arabic',this.form.value.bonus_date_arabic);
      formData.append('bonus_amount',this.form.value.bonus_amount);
      
      formData.append('direct_manager', this.form.value.direct_manager || '');

      this.ds.post(`form/${this.form_key}/cr/0`, formData).subscribe(data => {
        
        console.log('res',data);
        
        this.alert.success(data.msg);
        this.submitted = false;
        this.form.reset()
        setTimeout(() => {
          this.router.navigate(['transactions/allforms/']);
        }, 1000);
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
          this.alert.error(error);
      })
   
   
      
    }else{
      this.form.controls.createInstallments.enable();
      this.showMsg('Please fill all fields first', 'ok', 'error-snackbar');
    }
  }


  showMsg(msg:string, action:string, className:string) {
    this.snackBar.open(msg, action, {
      duration:4000,
      panelClass: [className]
    });
  }

  public search_users(key) {
    if(key){
      this.users = [];
      let param = new FormData();
      param.append('search', key.term);
      param.append('lang_key', this.lang_key);
      param.append('user_type_id', '1');
      this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      })
    }
  
  }
}
