import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
declare var $: any;
@Component({
  selector: 'app-rstep2',
  templateUrl: './rstep2.component.html',
  styleUrls: ['./rstep2.component.scss']
})
export class Rstep2Component implements OnInit {
  @Input() signupForm: FormGroup;
  @Input() action;
  @Input() files;
  view_profile_image = "./assets/images/default1.png ";
  singlesettings = environment.singledropdown;
  apiurl = environment.SERVER_ORIGIN;
  countries: any = [];
  fileurl;
  idfilename = this.lang.transform('lang_select_files');
  etitles: any = [];
  types: any;
  lang_key = localStorage.getItem('lang_key') || 'en';
  constructor(private cdRef: ChangeDetectorRef, public fb:FormBuilder ,public ds: DataService, public route:ActivatedRoute ,public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  ngOnInit(): void {
    this.employeeTitles();
    this.get_countries();
  }
  get f() {
    return this.signupForm.controls.idDetails;
  }
  get_countries() {
    this.countries = [];
    this.ds.getActionByNoToken([], 'countries/'+this.lang_key).subscribe(data => {
      if (data.status) {
        this.countries = data.records;
      }
    })
  }
  employeeTitles() {
    this.etitles = [];
    this.ds.getActionByNoToken([], 'signup/get_settings/ETIT/' + this.lang_key).subscribe(data => {
      if (data.status) {
        this.etitles = data.titles;
      }
    })
  }
  onFileChange(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      var reader = new FileReader();
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        if (event) {
          this.fileurl = (<FileReader>event.target).result;
        }
      };
      this.signupForm.get("idDetails").get("profile_image").setValue(file, { emitEvent: true });
    } else {
      this.signupForm.get("idDetails").get("profile_image").setValue('');
      this.fileurl = "";
    }
  }
  removeProfile() {
    this.signupForm.get("idDetails").get("profile_image").setValue([]);
    this.fileurl = "";
  }
  uploadIDFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.signupForm.get("idDetails").get("idFileUpload").setValue(fileid, { emitEvent: true });
    } else {
      this.signupForm.get("idDetails").get("idFileUpload").setValue([]);
      this.idfilename = this.lang.transform('lang_select_files');
    }
  }
  rstep2Submitted() {
    this.signupForm.get("idDetails").get("employee_id_number").markAsTouched();
    this.signupForm.get("idDetails").get("employee_id_number").updateValueAndValidity();
    this.signupForm.get("idDetails").get("firstname_english").markAsTouched();
    this.signupForm.get("idDetails").get("firstname_english").updateValueAndValidity();
    this.signupForm.get("idDetails").get("secondname_english").markAsTouched();
    this.signupForm.get("idDetails").get("secondname_english").updateValueAndValidity();
    this.signupForm.get("idDetails").get("lastname_english").markAsTouched();
    this.signupForm.get("idDetails").get("lastname_english").updateValueAndValidity();
    this.signupForm.get("idDetails").get("firstname_arabic").markAsTouched();
    this.signupForm.get("idDetails").get("firstname_arabic").updateValueAndValidity();
    this.signupForm.get("idDetails").get("secondname_arabic").markAsTouched();
    this.signupForm.get("idDetails").get("secondname_arabic").updateValueAndValidity();
    this.signupForm.get("idDetails").get("lastname_arabic").markAsTouched();
    this.signupForm.get("idDetails").get("lastname_arabic").updateValueAndValidity();
    this.signupForm.get("idDetails").get("iqama_expiry_date_english").markAsTouched();
    this.signupForm.get("idDetails").get("iqama_expiry_date_english").updateValueAndValidity();
    this.signupForm.get("idDetails").get("copy_number").markAsTouched();
    this.signupForm.get("idDetails").get("copy_number").updateValueAndValidity();
    this.signupForm.get("idDetails").get("birth_date_english").markAsTouched();
    this.signupForm.get("idDetails").get("birth_date_english").updateValueAndValidity();
    this.signupForm.get("idDetails").get("gender").markAsTouched();
    this.signupForm.get("idDetails").get("gender").updateValueAndValidity();
    this.signupForm.get("idDetails").get("nationality").markAsTouched();
    this.signupForm.get("idDetails").get("nationality").updateValueAndValidity();
    this.signupForm.get("idDetails").get("job_title_iqama").markAsTouched();
    this.signupForm.get("idDetails").get("job_title_iqama").updateValueAndValidity();
    this.signupForm.get("idDetails").get("id_filename").markAsTouched();
    this.signupForm.get("idDetails").get("id_filename").updateValueAndValidity();
    this.signupForm.get("idDetails").get("idFileUpload").markAsTouched();
    this.signupForm.get("idDetails").get("idFileUpload").updateValueAndValidity();
  }
  id_exists(id) {
    if (this.action !== "EDIT" && id.length == 10) {
      this.ds.getActionByNoToken([], 'signup/id_exists/' + id).subscribe(data => {
        if (data.status) {
          this.f.get('employee_id_number').setValue([]);
          this.ds.dialogf('', data.msg);
        }
      }, error => {
          this.f.get('employee_id_number').setValue([]);
          this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    }
  }
  get_profile_image(url) {
    this.view_profile_image = "./assets/images/default1.png ";
    this.ds.getActionByNoToken([], url).subscribe(data => {
      if (data.status) {
        this.view_profile_image = 'data:' + data['content-type'] + ';base64,' + data.base64;
      }
    });
  }
}
