<div class="card appinsurances">
    <div class="card-header">{{'lang_human_resources_insurance_details' | language }} </div>
    <div class="card-body p-0">
      <div class="mbrl15 martb0">
          <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12">
                  <div [ngClass]="{'red': !user_data.insurance_number}"> {{'lang_insurance_number' | language }}</div>
                  <div class="mb-10" [ngClass]="{'red': !user_data.insurance_number}">
                      <b *ngIf="user_data.insurance_number">{{user_data.insurance_number}}</b>
                      <b *ngIf="!user_data.insurance_number">-</b>
                  </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12">
                  <div [ngClass]="{'red': !user_data.insurance_date}"> {{'lang_insurance_date' | language }}</div>
                  <div class="mb-10" [ngClass]="{'red': !user_data.insurance_date}">
                      <b *ngIf="user_data.insurance_date">{{user_data.insurance_date}}</b>
                      <b *ngIf="!user_data.insurance_date">-</b>
                  </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12">
                  <div [ngClass]="{'red': !user_data.insurance_type_class}"> {{'lang_insurance_type_class' | language }}</div>
                  <div class="mb-10" [ngClass]="{'red': !user_data.insurance_type_class}">
                      <b *ngIf="user_data.insurance_type_class">{{user_data.insurance_type_class}}</b>
                      <b *ngIf="!user_data.insurance_type_class">-</b>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                  <select class="form-control almnabrformcontrol" (change)="search_my_insurance()" matTooltip="{{'lang_insurance_reaplationship' | language }}" id="isearchStatus">
                      <option value="">{{'lang_all' | language }}</option>
                      <option value="1">{{'lang_spouse' | language }}</option>
                      <option value="2">{{'lang_son' | language }}</option>
                      <option value="3">{{'lang_daugther' | language }}</option>
                      <option value="4">{{'lang_others' | language }}</option>
                  </select>
              </div>
              <div class="col-md-9 col-sm-6 col-xs-12 mb-10">
                  <div class="input-group input-group-sm">
                      <input type="text" class="form-control almnabrformcontrol" (keyup)="search_my_insurance()" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                      <span class="input-group-btn" *ngIf="(user_data.employee_status == '1' && able2add) || requestfor !== 'profile'">
                          <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" id="btnSearch" type="button"  (click)="openModalA(addInsurance);emptyInsuranceForm(user_data.insurance_number, user_data.insurance_date, user_data.insurance_type_class);" matTooltip="{{'lang_add_insurance' | language }}"></button>
                      </span>
                      <span class="input-group-btn" *ngIf="requestfor !== 'profile' && user_data.employee_status == '1' && able2add">
                          <button class="btnSearch h34 btn btn-md btn-gray fa fa-upload mnabricon" id="btnSearch" type="button" (click)="openModalFile(addNeedUploads);emptyAttachForm('IR0001');" matTooltip="{{'lang_upload_attachments' | language }}"></button>
                      </span>
                      <span class="input-group-btn" *ngIf="attachments.ir0001">
                          <button class="btnSearch h34 btn btn-md btn-gray fa fa-paperclip mnabricon" id="btnSearch" type="button" (click)="ds.previewData(attachments.ir0001);" matTooltip="{{'lang_recent_attachment' | language }} : {{attachments.ir0001_d}}"></button>
                      </span>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="table-responsive dropdownyes customResponsive col-12">
                  <table border="1" class="table CustomTable"  *ngIf="insdata.length != '0'" >
                      <thead>
                          <tr>
                              <td>#</td>
                              <td *ngIf="requestfor !=='profile'">
                                  <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                      <input type="checkbox" class="allItemsIns checkbox" id="allItemsIns" (click)="checkAllIns($event)"/>
                                      <label for="checkbox"></label>
                                  </div>
                              </td>
                              <td>{{'lang_dependent_name' | language }}</td>
                              <td>{{'lang_dependent_id' | language }}</td>
                              <td>{{'lang_insurance_number' | language }}</td>
                              <td>{{'lang_insurance_reaplationship' | language }}</td>
                              <td>{{'lang_writer' | language }}</td>
                              <td>{{'lang_action' | language }}</td>
                          </tr>
                      </thead>
                      <tbody class="AppendList">
                          <tr *ngFor="let ins of insdata.records; let i = index let odd=odd; let even=even"
                              [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{ins.insurance_dependent_id}}">
                              <td>{{i+1}}</td>
                              <td *ngIf="requestfor !=='profile'">
                                  <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                      <input type="checkbox"  class="eachItemIns checkbox" value="{{ins.insurance_dependent_id}}" (click)="checkEachItemIns($event)"/>
                                      <label for="checkbox"></label>
                                  </div>
                              </td>
                              <td><span class="breakall">{{ins.insurance_dependent_name}}</span></td>
                              <td><span class="breakall">{{ins.insurance_dependent_number}}</span></td>
                              <td><span class="breakall">{{ins.insurance_dependent_ins_no}}</span></td>
                              <td>
                                  <span class="breakall" *ngIf="ins.insurance_dependent_reaplationship == '1'">{{'lang_spouse' | language }}</span>
                                  <span class="breakall" *ngIf="ins.insurance_dependent_reaplationship == '2'">{{'lang_son' | language }}</span>
                                  <span class="breakall" *ngIf="ins.insurance_dependent_reaplationship == '3'">{{'lang_daugther' | language }}</span>
                                  <span class="breakall" *ngIf="ins.insurance_dependent_reaplationship == '4'">{{'lang_others' | language }}</span>
                              </td>
                              <td *ngIf="!ins.name">{{ins.username}}</td>
                              <td *ngIf="ins.name">{{ins.name}}</td>
                              <td>
                                  <i matTooltip="{{'lang_view' | language }}" class="fa fa-eye fa-icon mnabricon s2icon" (click)="openModalV(viewInsModal);view_my_insurance(ins);"></i>
                                  <i *ngIf="able2edit && user_data.employee_status == '1' " matTooltip="{{'lang_edit' | language }}" class="fa fa-pencil-square-o fa-icon mnabricon s2icon" (click)="openModalU(editNewInsurance);edit_insurance(ins);"></i>
                              </td>
                          </tr>
                      </tbody>
                     <!--  <tbody *ngIf="insdata.length == '0'" class="AppendList">
                          <tr class="odd">
                              <td colspan="8" align="center">{{inserrormsglist}}</td>
                          </tr>
                      </tbody> -->
                  </table>
                  <no-data style="margin: auto;"  [Opened]="insdata.length < 1 "></no-data>

              </div>
          </div>
      </div>
      <div *ngIf="insdata.length != '0'" class="card-footer padb0 bottomfooter">
          <div class="row">
              <div *ngIf="able2delete && requestfor !== 'profile' " class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                  <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataIns()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
              </div>
              <div class="col-lg-2 col-sm-6 col-6  mb-10">
                  {{'lang_total' | language }} : {{insdata.page.total_records}}
              </div>
              <div class="col-lg-5 col-sm-6 col-12 mb-10">
                  <mat-radio-group aria-label="Select an option">
                      <mat-radio-button value="10" checked (click)="spinner.show();inspage(10)"> 10 </mat-radio-button>
                      <mat-radio-button value="20" (click)="spinner.show();inspage(20)"> 20 </mat-radio-button>
                      <mat-radio-button value="50" (click)="spinner.show();inspage(50)"> 50 </mat-radio-button>
                      <mat-radio-button value="100" (click)="spinner.show();inspage(100)"> 100 </mat-radio-button>
                      <mat-radio-button value="500" (click)="spinner.show();inspage(500)"> 500 </mat-radio-button>
                  </mat-radio-group>
              </div>
              <div class="col-lg-4 col-sm-6 col-12 mb-10">
                  {{'lang_goto' | language }} :
                  <select class="customselect" (change)="spinner.show();inslength($event.target.value)">
                      <option *ngFor="let k of ds.getrange(insdata.page.total_pages)" value="{{k}}">{{k}}</option>
                  </select>
              </div>
          </div>
      </div>
    </div>
</div>
<ng-template #addNeedUploads>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_attachments' | language }}</h4>
            <button type="button" class="close" (click)="modalRefF.hide();">&times;</button>
        </div>
        <form [formGroup]="attachform" (ngSubmit)="upload_files()">
            <div class="modal-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="attachment_descriptions">{{'lang_description' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" formControlName="attachment_descriptions" class="form-control almnabrformcontrol" placeholder="{{'lang_description' | language }}" />
                            <mat-error *ngIf="attachform.controls['attachment_descriptions'].touched && attachform.controls['attachment_descriptions'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="level_keys">{{'lang_file_secutrity_levels' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <ng-select 
                                [items]="filepermissionlevels" 
                                [multiple]="true" 
                                bindLabel="label" 
                                appendTo="body" 
                                bindValue="value" 
                                clearAllText="Clear"
                                placeholder="{{'lang_file_secutrity_levels' | language}}"
                                formControlName="level_keys">
                            </ng-select>
                            <mat-error *ngIf="attachform.controls['level_keys'].touched && attachform.controls['level_keys'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="attachment_link">{{'lang_select_files' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <div class="IDDIV">
                                    <input name="attachment_link" type="file" required onclick="this.value = null" (change)="uploadAttachFile($event)" class="nghide albutton width100">
                                    <label id="uploadIDButton" class="albutton changeFileName">{{idfilename}} </label>
                                    <mat-error *ngIf="attachform.controls['attachment_link'].touched && attachform.controls['attachment_link'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" (click)="modalRefF.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #addInsurance>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_add_insurance' | language }}</h4>
            <button type="button" class="close" (click)="modalRefA.hide();">&times;</button>
        </div>
        <form [formGroup]="insmainform" (ngSubmit)="create_insurance()">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="form-group">
                            <label for="insurance_number">{{'lang_insurance_number' | language }}:<span class="red">*</span></label>
                            <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_insurance_number' | language }}" formControlName="insurance_number" />
                            <mat-error *ngIf="insmainform.controls['insurance_number'].touched && insmainform.controls['insurance_number'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="form-group">
                            <label for="insurance_date">{{'lang_insurance_date' | language }}:<span class="red">*</span></label>
                            <div class="positionrelatiove">
                                <input formControlName="insurance_date" class="form-control almnabrformcontrol" [readonly]="true" [matDatepicker]="idate" placeholder="YYYY/MM/DD" />
                                <mat-datepicker-toggle matSuffix [for]="idate" class="posiab"></mat-datepicker-toggle>
                                <mat-datepicker #idate disabled="false"></mat-datepicker>
                                <mat-error *ngIf="insmainform.controls['insurance_date'].touched && insmainform.controls['insurance_date'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="form-group">
                            <label for="insurance_type_class">{{'lang_insurance_type_class' | language }}:<span class="red">*</span></label>
                            <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_insurance_type_class' | language }}" formControlName="insurance_type_class" />
                            <mat-error *ngIf="insmainform.controls['insurance_type_class'].touched && insmainform.controls['insurance_type_class'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group">
                        <div class="col-2 col-sm-2 col-xs-12">
                            <i (click)="dynamicInsuranceAdd()" class="fa fa-plus-square green fa-icon" matTooltip="{{'lang_add' | language }}"></i>
                        </div>
                    </div>
                </div>
                <div formArrayName="insgroups">
                    <div *ngFor="let insurance of insuranceFormGroup.controls; let i = index; let nnnn = i;">
                        <div class="row mb-10" [formGroupName]="i">
                            <input type="hidden" formControlName="employee_number">
                            <div class="col-md-2 col-sm-2 col-xs-12 mt-30">
                                <label for="insurance_dependent_name">{{'lang_dependent_name' | language }}:<span class="red">*</span></label>
                                <input required type="text" placeholder="{{'lang_dependent_name' | language }}" formControlName="insurance_dependent_name" class="form-control almnabrformcontrol">
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-12 mt-30">
                                <label for="insurance_dependent_number">{{'lang_dependent_id' | language }}:<span class="red">*</span></label>
                                <input required type="text" (keypress)="ds.numberOnly($event)" minlength="10" maxlength="10" placeholder="{{'lang_dependent_id' | language }}" formControlName="insurance_dependent_number" class="form-control almnabrformcontrol">
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-12 mt-30">
                                <label for="insurance_dependent_ins_no">{{'lang_insurance_number' | language }}:<span class="red">*</span></label>
                                <input required type="text" placeholder="{{'lang_insurance_number' | language }}" formControlName="insurance_dependent_ins_no" class="form-control almnabrformcontrol">
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-12 mt-30">
                                <label for="insurance_dependent_reaplationship">{{'lang_insurance_reaplationship' | language }}:<span class="red">*</span></label>
                                <select required type="text" matTooltip="{{'lang_insurance_reaplationship' | language }}" formControlName="insurance_dependent_reaplationship" class="form-control almnabrformcontrol">
                                    <option value="">{{'lang_choose_options' | language }}</option>
                                    <option value="1">{{'lang_spouse' | language }}</option>
                                    <option value="2">{{'lang_son' | language }}</option>
                                    <option value="3">{{'lang_daugther' | language }}</option>
                                    <option value="4">{{'lang_others' | language }}</option>
                                </select>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12 mt-30">
                                <label for="insurance_dependent_date">{{'lang_insurance_date' | language }}:<span class="red">*</span></label>
                                <div class="input-group input-group-sm">
                                    <input [matDatepicker]="insurance_dependent_date" formControlName="insurance_dependent_date" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly>
                                    <span class="input-group-btn">
                                        <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="insurance_dependent_date"></mat-datepicker-toggle>
                                        <mat-datepicker #insurance_dependent_date></mat-datepicker>
                                    </span>
                                    <span class="input-group-btn">
                                        <button (click)="insuranceFormGroup.controls[i].get('insurance_dependent_date').setValue('');" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-1 col-sm-1 col-xs-12 mt-30">
                                <label class="opacity0 width100">hidden:</label>
                                <div (click)="dynamicInsuranceRemove(i)" class="fa-minus-square fa fa-icon red pad0" matTooltip="{{'lang_delete' | language }}"></div>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" (click)="modalRefA.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<!--view insurance data-->
<ng-template #viewInsModal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_human_resources_insurance_details' | language }}</h4>
            <button type="button" class="close" (click)="modalRefV.hide();">&times;</button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_dependent_name' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{insuranceviewdata.insurance_dependent_name}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_dependent_id' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{insuranceviewdata.insurance_dependent_number}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_insurance_number' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{insuranceviewdata.insurance_dependent_ins_no}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_insurance_date' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{insuranceviewdata.insurance_dependent_date}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_insurance_reaplationship' | language }}
                        :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngIf="insuranceviewdata.insurance_dependent_reaplationship == '1'">{{'lang_spouse' | language }}</b>
                        <b class="breakall" *ngIf="insuranceviewdata.insurance_dependent_reaplationship == '2'">{{'lang_son' | language }}</b>
                        <b class="breakall" *ngIf="insuranceviewdata.insurance_dependent_reaplationship == '3'">{{'lang_daugther' | language }}</b>
                        <b class="breakall" *ngIf="insuranceviewdata.insurance_dependent_reaplationship == '4'">{{'lang_others' | language }}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_writer' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngIf="insuranceviewdata.name">{{insuranceviewdata.name}}</b>
                        <b class="breakall" *ngIf="!insuranceviewdata.name">{{insuranceviewdata.username}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_created_date' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{insuranceviewdata.insurance_dependent_createddate}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_onupdate' | language }}
                        :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{insuranceviewdata.insurance_dependent_updatedate}}</b>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modalRefV.hide();">{{'lang_close' | language }}</button>
        </div>
    </div>
</ng-template>
<ng-template #editNewInsurance>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_update_insurance' | language }}</h4>
            <button type="button" class="close" (click)="modalRefU.hide();">&times;</button>
        </div>
        <form [formGroup]="inseditform" (ngSubmit)="update_insurance()">
            <div class="modal-body">
                <input type="hidden" formControlName="insurance_dependent_id" />
                <div class="form-group">
                    <label for="insurance_dependent_name">{{'lang_dependent_name' | language }}:<span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_dependent_name' | language }}" formControlName="insurance_dependent_name" />
                    <mat-error *ngIf="inseditform.controls['insurance_dependent_name'].touched && inseditform.controls['insurance_dependent_name'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="insurance_dependent_number">{{'lang_dependent_id' | language }}:<span class="red">*</span></label>
                    <input type="text" required minlength="10" maxlength="10" class="form-control almnabrformcontrol" (keypress)="ds.numberOnly($event)" placeholder="{{'lang_dependent_id' | language }}" formControlName="insurance_dependent_number" />
                    <mat-error *ngIf="inseditform.controls['insurance_dependent_number'].touched && inseditform.controls['insurance_dependent_number'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                    <mat-error *ngIf="inseditform.controls['insurance_dependent_number'].errors?.minlength"> {{'lang_minimum' | language }} 10 </mat-error>
                    <mat-error *ngIf="inseditform.controls['insurance_dependent_number'].errors?.maxlength"> {{'lang_maximum' | language }} 10 </mat-error>
                </div>
                <div class="form-group">
                    <label for="insurance_dependent_ins_no">{{'lang_insurance_number' | language }}:<span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_insurance_number' | language }}" formControlName="insurance_dependent_ins_no" />
                    <mat-error *ngIf="inseditform.controls['insurance_dependent_ins_no'].touched && inseditform.controls['insurance_dependent_ins_no'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="insurance_dependent_reaplationship">{{'lang_insurance_reaplationship' | language }}:<span class="red">*</span></label>
                    <select required type="text" matTooltip="{{'lang_insurance_reaplationship' | language }}" formControlName="insurance_dependent_reaplationship" class="form-control almnabrformcontrol">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option value="1">{{'lang_spouse' | language }}</option>
                        <option value="2">{{'lang_son' | language }}</option>
                        <option value="3">{{'lang_daugther' | language }}</option>
                        <option value="4">{{'lang_others' | language }}</option>
                    </select>
                    <mat-error *ngIf="inseditform.controls['insurance_dependent_reaplationship'].touched && inseditform.controls['insurance_dependent_reaplationship'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="insurance_dependent_date">{{'lang_insurance_date' | language }}:<span class="red">*</span></label>
                    <div class="input-group input-group-sm">
                        <input [matDatepicker]="insurance_dependent_dateU" formControlName="insurance_dependent_date" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly>
                        <span class="input-group-btn">
                            <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="insurance_dependent_dateU"></mat-datepicker-toggle>
                            <mat-datepicker #insurance_dependent_dateU></mat-datepicker>
                        </span>
                        <span class="input-group-btn">
                            <button (click)="inseditform.get('insurance_dependent_date').setValue('');" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                        </span>
                    </div>
                    <mat-error *ngIf="inseditform.controls['insurance_dependent_date'].touched && inseditform.controls['insurance_dependent_date'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>    
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefU.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>