<form [formGroup]="form">
	<div mat-dialog-title>
		<div class="d-flex justify-content-between">
			<h4>{{ 'lang_modify_shift' | language }} ({{dayInfo | date:'fullDate'}})</h4>
			<span (click)="closeModal()"><i class="fa fa-times"></i></span>
		</div>
	</div>
	<mat-dialog-content class="mat-typography">
	 
		<form [formGroup]="form">
			<div
			
	 
		>
			<div class="row">
			  <!--   <div class="form-group col-md-6 mb-1">
					<h5 class="head">
						{{ "lang_plan_no" | language }}.
						{{ i + 1 }}
					</h5>
				</div>
				<div
					class="form-group col-md-6 mb-1"
					style="text-align: right"
				>
					<a
						class="danger"
						*ngIf="
							i <
							getSmallSteps.project_plan_requirements
								?.value.length -
								plans.length
						"
						(click)="deletePaln(i)"
						matTooltip="{{
							'lang_delete_plan' | language
						}}"
					>
						<i
							class="fa fa-trash deleteICO font-medium-3 m-2"
						></i>
					</a>
				</div> -->
				<div
					class="col-12 col-sm-3"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_shift_start_time" | language
						}}</mat-label>

						<input
							matInput
							type="time"
							 
							formControlName="shift_start_time"
							required
						/>
						<mat-error
							*ngIf="
								form.controls[
									'shift_start_time'
								].touched &&
								form.controls[
								'shift_start_time'].errors
									?.required
							"
						>
							{{ "lang_field_required" | language }}
						</mat-error>
					</mat-form-field>
				</div>

				<div
					class="col-12 col-sm-3"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_shift_end_time" | language
						}}</mat-label>

						<input
							matInput
							type="time"
							formControlName="shift_end_time"
							required
						/>
						<mat-error
						*ngIf="
							form.controls[
								'shift_end_time'
							].touched &&
							form.controls[
							'shift_end_time'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
					</mat-form-field>
				</div>

				<div
					class="col-12 col-sm-3"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_shift_total_hours" | language
						}}</mat-label>

						<input
							matInput
							type="number"
							formControlName="shift_total_hours"
							required
						/>
						<mat-error
						*ngIf="
							form.controls[
								'shift_total_hours'
							].touched &&
							form.controls[
							'shift_total_hours'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
					</mat-form-field>
				</div>

				<div
					class="col-12 col-sm-3"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_shift_break_hours" | language
						}}</mat-label>

						<input
							matInput
							type="number"
							formControlName="shift_break_hours"
							required
						/>
						<mat-error
						*ngIf="
							form.controls[
								'shift_break_hours'
							].touched &&
							form.controls[
							'shift_break_hours'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
					</mat-form-field>
				</div>

				<div
					class="col-12 col-sm-3"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_check_in_start" | language
						}}</mat-label>

						<input
							matInput
							type="time"
							formControlName="check_in_start"
							required
						/>
						<mat-error
						*ngIf="
							form.controls[
								'check_in_start'
							].touched &&
							form.controls[
							'check_in_start'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
					</mat-form-field>
				</div>

				<div
					class="col-12 col-sm-3"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_check_in_end" | language
						}}</mat-label>

						<input
							matInput
							type="time"
							formControlName="check_in_end"
							required
						/>
						<mat-error
						*ngIf="
							form.controls[
								'check_in_end'
							].touched &&
							form.controls[
							'check_in_end'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
					</mat-form-field>
				</div>

				<div
					class="col-12 col-sm-3"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_break_out_start" | language
						}}</mat-label>

						<input
							matInput
							type="time"
							formControlName="break_out_start"
							required
						/>
						<mat-error
						*ngIf="
							form.controls[
								'break_out_start'
							].touched &&
							form.controls[
							'break_out_start'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
					</mat-form-field>
				</div>


				<div
					class="col-12 col-sm-3"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_break_out_end" | language
						}}</mat-label>

						<input
							matInput
							type="time"
							formControlName="break_out_end"
							required
						/>
						<mat-error
						*ngIf="
							form.controls[
								'break_out_end'
							].touched &&
							form.controls[
							'break_out_end'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
					</mat-form-field>
				</div>

				<div
					class="col-12 col-sm-3"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_break_in_start" | language
						}}</mat-label>

						<input
							matInput
							type="time"
							formControlName="break_in_start"
							required
						/>
						<mat-error
						*ngIf="
							form.controls[
								'break_in_start'
							].touched &&
							form.controls[
							'break_in_start'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
					</mat-form-field>
				</div>

				<div
					class="col-12 col-sm-3"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_break_in_end" | language
						}}</mat-label>

						<input
							matInput
							type="time"
							formControlName="break_in_end"
							required
						/>
						<mat-error
						*ngIf="
							form.controls[
								'break_in_end'
							].touched &&
							form.controls[
							'break_in_end'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
					</mat-form-field>
				</div>

				<div
					class="col-12 col-sm-3"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_check_out_start" | language
						}}</mat-label>

						<input
							matInput
							type="time"
							formControlName="check_out_start"
							required
						/>
						<mat-error
						*ngIf="
							form.controls[
								'check_out_start'
							].touched &&
							form.controls[
							'check_out_start'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
					</mat-form-field>
				</div>

				<div
					class="col-12 col-sm-3"
					[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
				>
					<mat-form-field
						style="width: 100%"
						appearance="outline"
					>
						<mat-label>{{
							"lang_check_out_end" | language
						}}</mat-label>

						<input
							matInput
							type="time"
							formControlName="check_out_end"
							required
						/>
						<mat-error
						*ngIf="
							form.controls[
								'check_out_end'
							].touched &&
							form.controls[
							'check_out_end'].errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
					</mat-form-field>
				</div>


			 

			     <div class="col-md-12">
					<mat-checkbox
					class="example-margin"
					(change)="dayOff($event)"
					formControlName="shift_time_day_off"
					 >
			     	 {{'lang_shift_time_day_off'|language}}
				</mat-checkbox>
				</div> 

			<!-- 	<div class="col-md-12">
					<mat-checkbox
					class="example-margin"
				 
					formControlName="copy_all_days_month"
					 >
			     	 {{'lang_copy_this_to_all_days_in_month'|language}}
				</mat-checkbox>
				</div>  -->
				<div class="col-md-12">
					<mat-checkbox
					class="example-margin2"
					(change)="getAllDaysByNameiNMoth($event)"
					formControlName="copy_all_days_names"
					 >
			     	 {{'lang_copy_this_to_all_days_same_name'|language}}
				</mat-checkbox>
				</div>
			
			</div>
		 
		</div>
		  </form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">

		<div class="col-md-4 col-xs-12">
			<button
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				(click)="closeModal()"
			>
				{{ "lang_close" | language }}
			</button>
		</div>
		<div class="col-md-4 col-xs-12"></div>
		<div class="col-md-4 col-xs-12">
			<button
				type="button"
				class="albutton mnabrbutton mat-accent"
				mat-button
				[disabled]="form.invalid"
				(click)="save()"
			>
			<!-- [disabled]="form.invalid" -->
				{{ 'lang_save'|language }}
			</button>
		</div>
		
	</mat-dialog-actions>
</form>
