<form [formGroup]="form" (ngSubmit)="completeStep5()">
	<div class="card">
		<div class="card-header">
			{{ "lang_technical_assistant" | language }}
			<i
				style="padding: 0 10px"
				class="fa fa-info-circle orangestatus-color"
				matTooltip="{{ 'lang_technical_assistant_tooltip' | language }}"
			></i>
		</div>
		<div class="card-body pad0" *ngIf="!viewMode">
			<div class="mbrl15">
				<div class="row">
					<div class="col-md-12 TableParts">
						<p class="mb-10 bold">
							{{ "lang_technical_assistants_evaluation" | language
							}}<span class="red">*</span>
						</p>
						<div
							class="table-responsive customResponsive tablefields quotationitemtable"
						>
							<table
								border="1"
								class="table CustomTable"
								width="100%"
								formArrayName="Technical_Assistants_Evaluation"
							>
								<thead>
									<tr>
										<td style="padding: 5px; width: 5%" class="text-center">
											#
										</td>
										<td style="padding: 5px; width: 5%" class="text-center">
											<mat-icon
												class="mnabricon"
												matTooltip="{{ 'lang_add' | language }}"
												(click)="add_assistances()"
												>add_box</mat-icon
											>
										</td>
										<td style="padding: 5px; width: 45%" class="text-center">
											{{ "lang_title" | language }} <span class="red">*</span>
										</td>
										<td style="padding: 5px; width: 20%" class="text-center">
											{{ "lang_status" | language }} <span class="red">*</span>
										</td>
										<td style="padding: 5px; width: 20%" class="text-center">
											{{ "lang_evaluation_result" | language }}
											<span class="red">*</span>
										</td>
										<td style="padding: 5px; width: 5%" class="text-center">
											{{ "lang_attachments" | language }}
										</td>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="
											let assistant of _assistancesgroup.controls;
											let i = index;
											let odd = odd;
											let even = even
										"
										[formGroupName]="i"
									>
										<td
											class="verticalalignmid text-center"
											style="padding: 5px; word-break: break-all"
										>
											{{ i + 1 }}
										</td>
										<td class="verticalalignmid text-center">
											<mat-icon
												*ngIf="
													other_assistances.controls[i].get('delete').value
												"
												(click)="remove_assistances(i)"
												class="mnabricon red"
												matTooltip="{{ 'lang_delete' | language }}"
												>delete_forever</mat-icon
											>
										</td>
										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											<input
												*ngIf="
													other_assistances.controls[i].get('delete').value
												"
												type="text"
												formControlName="title"
												placeholder="{{ 'lang_description' | language }}"
												autocomplete="off"
												class="form-control almnabrformcontrol"
											/>
											<p
												*ngIf="
													!other_assistances.controls[i].get('delete').value
												"
											>
												{{ other_assistances.controls[i].get("title").value }}
											</p>
										</td>
										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											<mat-radio-group
												aria-label="Select an option"
												formControlName="status"
											>
												<mat-radio-button
													style="margin-top: 10px"
													value="1"
													(change)="radioChange($event, i)"
													>{{ "lang_yes" | language }}</mat-radio-button
												>
												<mat-radio-button
													style="margin-top: 10px"
													value="0"
													(change)="radioChange($event, i)"
													>{{ "lang_no" | language }}</mat-radio-button
												>
											</mat-radio-group>
										</td>
										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											<span
												*ngIf="
													!other_assistances.controls[i].get('showSelect').value
												"
											>
												{{
													other_assistances.controls[i].get("status").value ==
													"1"
														? other_assistances.controls[i].get(
																"yes_code_result"
														  ).value
														: other_assistances.controls[i].get(
																"no_code_result"
														  ).value
												}}
											</span>
											<select
												formControlName="result"
												*ngIf="
													other_assistances.controls[i].get('showSelect').value
												"
												placeholder="{{ 'lang_evaluation_result' | language }}"
												autocomplete="off"
												class="form-control almnabrformcontrol"
											>
												<option value="">
													{{ "lang_choose_options" | language }}
												</option>
												<option
													value="A"
													*ngIf="
														other_assistances.controls[i].get('status').value ==
														'1'
													"
												>
													A
												</option>
												<option
													value="B"
													*ngIf="
														other_assistances.controls[i].get('status').value ==
														'1'
													"
												>
													B
												</option>
												<option
													value="C"
													*ngIf="
														other_assistances.controls[i].get('status').value ==
														'0'
													"
												>
													C
												</option>
												<option
													value="D"
													*ngIf="
														other_assistances.controls[i].get('status').value ==
														'0'
													"
												>
													D
												</option>
											</select>
										</td>
										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											<app-dynamic-files-control
												(getAttachments)="getAttachments($event)"
												[rawIndex]="rawIndex"
												[actionMode]="'ADD'"
												(click)="rawIndex = i"
												[required]="true"
											></app-dynamic-files-control>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<br />
				<div class="row">
					<div class="col-md-12 TableParts">
						<p class="mb-10 bold">
							{{ "lang_saudi_building_codes" | language }} :
						</p>
						<div
							class="table-responsive customResponsive tablefields quotationitemtable"
						>
							<table
								border="1"
								class="table CustomTable"
								width="100%"
								formArrayName="Saudi_Building_Codes"
							>
								<thead>
									<tr>
										<td style="padding: 5px; width: 5%" class="text-center">
											#
										</td>
										<td style="padding: 5px; width: 5%" class="text-center">
											<mat-icon
												class="mnabricon"
												matTooltip="{{ 'lang_add' | language }}"
												(click)="add_building_code()"
												>add_box</mat-icon
											>
										</td>
										<td style="padding: 5px; width: 70%" class="text-center">
											{{ "lang_title" | language }} <span class="red">*</span>
										</td>
										<td style="padding: 5px; width: 20%" class="text-center">
											{{ "lang_status" | language }} <span class="red">*</span>
										</td>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="
											let building_codes of _building_codegroup.controls;
											let i = index;
											let odd = odd;
											let even = even
										"
										[formGroupName]="i"
									>
										<td
											class="verticalalignmid text-center"
											style="padding: 5px; word-break: break-all"
										>
											{{ i + 1 }}
										</td>
										<td class="verticalalignmid text-center">
											<mat-icon
												*ngIf="
													other_building_code.controls[i].get('delete').value
												"
												(click)="remove_building_code(i)"
												class="mnabricon red"
												matTooltip="{{ 'lang_delete' | language }}"
												>delete_forever</mat-icon
											>
										</td>
										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											<input
												*ngIf="
													other_building_code.controls[i].get('delete').value
												"
												type="text"
												formControlName="title"
												placeholder="{{ 'lang_description' | language }}"
												autocomplete="off"
												class="form-control almnabrformcontrol"
											/>
											<p
												*ngIf="
													!other_building_code.controls[i].get('delete').value
												"
											>
												{{ other_building_code.controls[i].get("title").value }}
											</p>
										</td>
										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											<mat-radio-group
												aria-label="Select an option"
												formControlName="status"
											>
												<mat-radio-button style="margin-top: 10px" value="1">{{
													"lang_yes" | language
												}}</mat-radio-button>
												<mat-radio-button style="margin-top: 10px" value="0">{{
													"lang_no" | language
												}}</mat-radio-button>
											</mat-radio-group>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<br />
				<form [formGroup]="mainform">
					<div class="row">
						<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
							<label
								>{{ "lang_special_approvals_required" | language }} !?</label
							>
							<section class="example-section">
								<mat-checkbox
									formControlName="special_confirmation_required"
									(change)="
										mainform.get('special_confirmation_users').setValue(null)
									"
									class="example-margin"
								></mat-checkbox>
							</section>
						</div>
						<div
							class="col-md-4 col-sm-4 col-xs-12 mb-10"
							*ngIf="mainform.get('special_confirmation_required').value"
						>
							<label>{{ "lang_special_approvers" | language }} </label>
							<ng-select
								[items]="users"
								[multiple]="true"
								bindLabel="label"
								bindValue="value"
								clearAllText="Clear"
								(search)="search_special_approvers($event)"
								[searchFn]="ds.customSearchFn"
								appendTo="body"
								formControlName="special_confirmation_users"
								placeholder="{{ 'lang_special_approvers' | language }}"
								(focus)="users = []"
							>
							</ng-select>
						</div>
					</div>
				</form>
				<div class="row">
					<div class="col-12">
						<app-alert id="default-alert"></app-alert>
					</div>
				</div>
			</div>
		</div>
		<div class="card-body pad0" *ngIf="viewMode">
			<div class="mbrl15">
				<div class="row">
					<div class="col-md-12 TableParts">
						<p class="mb-10 bold">
							{{ "lang_technical_assistants_evaluation" | language }}
						</p>
						<div
							class="table-responsive customResponsive tablefields quotationitemtable"
						>
							<table border="1" class="table CustomTable" width="100%">
								<thead>
									<tr>
										<td style="padding: 5px; width: 5%" class="text-center">
											#
										</td>

										<td style="padding: 5px; width: 75%" class="text-center">
											{{ "lang_title" | language }}
										</td>
										<td style="padding: 5px; width: 20%" class="text-center">
											{{ "lang_evaluation_status" | language }}
										</td>
										<td style="padding: 5px; width: 20%" class="text-center">
											{{ "lang_evaluation_result" | language }}
										</td>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of displayTechnical; let i = index">
										<td
											class="verticalalignmid text-center"
											style="padding: 5px; word-break: break-all"
										>
											{{ i + 1 }}
										</td>

										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											<span
												*ngIf="item?.extra1_title.length > 40"
												matTooltip="{{ item?.extra1_title }}"
											>
												{{ item?.extra1_title | shortenLength: 40 }}
											</span>
											<span *ngIf="item?.extra1_title.length <= 40">
												{{ item?.extra1_title | shortenLength: 40 }}
											</span>
										</td>
										<td *ngIf="item.extra1_status == 1">
											<i
												class="fa fa-check faicon successgreen"
												aria-hidden="true"
											></i>
										</td>
										<td *ngIf="item.extra1_status == 0">
											<i class="fa fa-close faicon red" aria-hidden="true"></i>
										</td>
										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											{{ item?.extra1_result }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="col-md-12 TableParts mt-4 mb-2">
						<p class="mb-10 bold">
							{{ "lang_saudi_building_codes" | language }}
						</p>
						<div
							class="table-responsive customResponsive tablefields quotationitemtable"
						>
							<table border="1" class="table CustomTable" width="100%">
								<thead>
									<tr>
										<td style="padding: 5px; width: 5%" class="text-center">
											#
										</td>

										<td style="padding: 5px; width: 75%" class="text-center">
											{{ "lang_title" | language }}
										</td>

										<td style="padding: 5px; width: 20%" class="text-center">
											{{ "lang_evaluation_status" | language }}
										</td>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of displaySaudiBuilding; let i = index">
										<td
											class="verticalalignmid text-center"
											style="padding: 5px; word-break: break-all"
										>
											{{ i + 1 }}
										</td>

										<td
											style="padding: 5px; position: relative"
											class="text-center"
										>
											<span
												*ngIf="item?.extra1_title.length > 40"
												matTooltip="{{ item?.extra1_title }}"
											>
												{{ item?.extra1_title | shortenLength: 40 }}
											</span>
											<span *ngIf="item?.extra1_title.length <= 40">
												{{ item?.extra1_title | shortenLength: 40 }}
											</span>
										</td>

										<td *ngIf="item.extra1_status == 1">
											<i
												class="fa fa-check faicon successgreen"
												aria-hidden="true"
											></i>
										</td>
										<td *ngIf="item.extra1_status == 0">
											<i class="fa fa-close faicon red" aria-hidden="true"></i>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer" *ngIf="!viewMode">
			<div class="row">
				<div class="col-sm-8 col-xs-12 mb-10"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<input
						type="submit"
						value="{{ 'lang_submit' | language }}"
						class="albutton mnabrbutton mat-accent"
						mat-button
					/>
				</div>
			</div>
		</div>
	</div>
</form>
