import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sinvoice-view',
  templateUrl: './sinvoice-view.component.html',
  styleUrls: ['./sinvoice-view.component.scss']
})
export class SinvoiceViewComponent implements OnInit {
  public branch_id = this.route.snapshot.paramMap.get('bid');
  public invoice_id = this.route.snapshot.paramMap.get('iid');
  viewdata_status: boolean = false;
  showfield: boolean = false;
  transactions: any = [];
  items:any;
  InvData:any = [];
  viewdata: any = [];
  constructor(public ds:DataService,public route : ActivatedRoute, public datePipe: DatePipe,public router : Router, public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.getdata_forview();
  }
  public getdata_forview() {
    this.spinner.show();
    this.ds.getActionByUrl([], 'viewsinvoice/' + this.branch_id + '/' + this.invoice_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.viewdata_status = true;
        this.InvData = res;
        this.viewdata = res.records;
        this.transactions = res.transactions;
        this.items = res.invoice_items;
      } else {
        this.viewdata_status = false;
        this.ds.dialogf('',res.error);
        this.router.navigate(['accounts/sinvoices/']);
      }
    }, error => {
      this.spinner.hide();
      this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
}
