<breadcrumb></breadcrumb>
<div class="supervisiondashboard" *ngIf="template_show_option == 'VIEW'">
	<section class="panel-expansion matborderno" *ngIf="wholedata.length != '0'">
		<div class="row" *ngIf="project_profile_id && project_supervision_id">
			<div class="col-md-4 col-sm-3 col-xs-12 mb-10">
				<button
					*ngIf="view"
					type="button"
					routerLink="/projects/LtYh/{{ project_profile_id }}"
					class="albutton mnabrbutton"
					mat-raised-button
				>
					{{ "lang_project_view" | language }}
				</button>
			</div>
			<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
				<button
					*ngIf="view"
					type="button"
					routerLink="/projects/psmaindashboard/{{ project_profile_id }}/{{
						project_supervision_id
					}}"
					class="albutton mnabrbutton"
					mat-button
				>
					{{ "lang_supervision_dashboard" | language }}
				</button>
			</div>
			<div class="col-md-1 col-sm-1 col-xs-12 mb-10"></div>
			<div class="col-md-3 col-sm-4 col-xs-12 mb-10">
				<button
					*ngIf="view"
					type="button"
					routerLink="/projects/BbVUrWitLE"
					class="albutton mnabrbutton"
					mat-button
				>
					{{ "lang_all_projects" | language }}
				</button>
			</div>
		</div>
		<mat-accordion *ngIf="supervision_data.length != '0'">
			<mat-expansion-panel class="mpanel" [expanded]="true">
				<mat-expansion-panel-header>{{
					"lang_supervision_operation_details" | language
				}}</mat-expansion-panel-header>
				<div class="material-panel-body">
					<div class="mbrl15 martb0">
						<div
							class="row"
							[ngStyle]="
								lang_key == 'ar'
									? {
											'padding-left': '10px',
											'padding-right': '10px'
									  }
									: null
							"
						>
							<div class="col-xs-12 col-12 pad0 suppervision-stepper">
								<div class="wrapper">
									<owl-carousel-o [options]="customOptions" #stepperCar>
										<ng-container>
											<ng-template carouselSlide id="s1">
												<div (click)="moveStepper('form_transaction')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-id-card-o fa-icon stepper-header-icon stepper-active form_transaction"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_form_transaction' | language }}"
														style="text-align: center"
													>
														{{ "lang_form_transaction" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										
										<ng-container *ngIf="ds.allowMe('projects', 'projects_template_permission')">
											<ng-template carouselSlide id="s2">
												<div (click)="moveStepper('formations')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-list fa-icon stepper-header-icon formations"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_formations' | language }}"
														style="text-align: center"
													>
														{{ "lang_formations" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>

										<ng-container *ngIf="tabs_permission?.tab_phases">
											<ng-template carouselSlide id="s3">
												<div (click)="moveStepper('phases')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-building fa-icon stepper-header-icon phases"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{
															'lang_implementation_phases' | language
														}}"
														style="text-align: center"
													>
														{{ "lang_implementation_phases" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_testing_report">
											<ng-template carouselSlide id="s4">
												<div (click)="moveStepper('test_report_workarea')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-building fa-icon stepper-header-icon test_report_workarea"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{
															'lang_test_report_workarea' | language
														}}"
														style="text-align: center"
													>
														{{ "lang_test_report_workarea" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_team_users">
											<ng-template carouselSlide id="s5">
												<div (click)="moveStepper('team_users')">
													<div class="step">
														<div class="st">
															<mat-icon
																class="mnabricon stepper-header-icon team_users"
																>supervisor_account</mat-icon
															>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_team_users' | language }}"
														style="text-align: center"
													>
														{{ "lang_team_users" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<!-- *ngIf="tabs_permission?.tab_communication" -->
										<ng-container >
											<ng-template carouselSlide id="s6">
												<div (click)="moveStepper('communication')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-link fa-icon stepper-header-icon communication"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_communications' | language }}"
														style="text-align: center"
													>
														{{ "lang_communications" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_contacts">
											<ng-template carouselSlide id="s7">
												<div (click)="moveStepper('contacts')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-mobile fa-icon stepper-header-icon contacts"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_contacts' | language }}"
														style="text-align: center"
													>
														{{ "lang_contacts" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_contractors">
											<ng-template carouselSlide id="s8">
												<div (click)="moveStepper('contractors')">
													<div class="step">
														<div class="st">
															<mat-icon
																class="mnabricon stepper-header-icon contractors"
																>account_circle</mat-icon
															>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_contractors' | language }}"
														style="text-align: center"
													>
														{{ "lang_contractors" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_customers">
											<ng-template carouselSlide id="s9">
												<div (click)="moveStepper('customers')">
													<div class="step">
														<div class="st">
															<mat-icon
																class="mnabricon stepper-header-icon customers"
																>supervised_user_circle</mat-icon
															>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_customers' | language }}"
														style="text-align: center"
													>
														{{ "lang_customers" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>

										<ng-container *ngIf="tabs_permission?.tab_mobilization">
											<ng-template carouselSlide id="s10">
												<div (click)="moveStepper('mobilization')">
													<div class="step">
														<div class="st">
															<mat-icon
																class="mnabricon stepper-header-icon mobilization"
																>perm_phone_msg</mat-icon
															>
														</div>
													</div>
													<div
														matTooltip="{{
															'lang_mobilization_general_form_settings' | language
														}}"
														style="text-align: center"
													>
														{{ "lang_mobilization" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>

										<ng-container *ngIf="tabs_permission?.tab_reports">
											<ng-template carouselSlide id="s11">
												<div (click)="moveStepper('report')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-book fa-icon stepper-header-icon report"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_report' | language }}"
														style="text-align: center"
													>
														{{ "lang_report" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_tender_drawing">
											<ng-template carouselSlide id="s12">
												<div (click)="moveStepper('tender_drawing')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-pencil-square fa-icon stepper-header-icon tender_drawing"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_tender_drawing' | language }}"
														style="text-align: center"
													>
														{{ "lang_tender_drawing" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_bill_quantities">
											<ng-template carouselSlide id="s13">
												<div (click)="moveStepper('bill_quantities')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-chain-broken fa-icon stepper-header-icon bill_quantities"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_bill_quantities' | language }}"
														style="text-align: center"
													>
														{{ "lang_bill_quantities" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										
										<ng-container  *ngIf="tabs_permission?.tab_time_schedule">
											<ng-template carouselSlide id="s14">
												<div (click)="moveStepper('time_schedule')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-clock-o fa-icon stepper-header-icon time_schedule"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_time_schedule' | language }}"
														style="text-align: center"
													>
														{{ "lang_time_schedule" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_site_testing">
											<ng-template carouselSlide id="s15">
												<div (click)="moveStepper('site_testing_file')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-comments-o fa-icon stepper-header-icon site_testing_file"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_site_testing_file' | language }}"
														style="text-align: center"
													>
														{{ "lang_site_testing_file" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_documents">
											<ng-template carouselSlide id="s16">
												<div (click)="moveStepper('documents')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-files-o fa-icon stepper-header-icon documents"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_documents' | language }}"
														style="text-align: center"
													>
														{{ "lang_documents" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container  *ngIf="tabs_permission?.tab_project_completion">
											<ng-template carouselSlide id="s17">
												<div (click)="moveStepper('project_completion')">
													<div class="step">
														<div class="st">
															<mat-icon
																class="mnabricon stepper-header-icon project_completion"
																>close</mat-icon
															>
														</div>
													</div>
													<div
														matTooltip="{{
															'lang_project_completion' | language
														}}"
														style="text-align: center"
													>
														{{ "lang_project_completion" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_contractor_payments">
											<ng-template carouselSlide id="s18">
												<div (click)="moveStepper('contract_payments')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-money fa-icon stepper-header-icon contract_payments"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_contract_payments' | language }}"
														style="text-align: center"
													>
														{{ "lang_contract_payments" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="tabs_permission?.tab_setting">
											<ng-template carouselSlide id="s19">
												<div (click)="moveStepper('settings')">
													<div class="step">
														<div class="st">
															<i
																class="icon-5x fa fa-cog fa-icon stepper-header-icon settings"
															></i>
														</div>
													</div>
													<div
														matTooltip="{{ 'lang_settings' | language }}"
														style="text-align: center"
													>
														{{ "lang_settings" | language }}
													</div>
												</div>
											</ng-template>
										</ng-container>
									</owl-carousel-o>
									<button
										(click)="
											lang_key == 'en' ? stepperCar.prev() : stepperCar.next()
										"
										class="carousel-btn-pre albutton mnabrbutton"
									>
										<i class="fa fa-chevron-left"></i>
									</button>
									<button
										(click)="
											lang_key == 'en' ? stepperCar.next() : stepperCar.prev()
										"
										class="carousel-btn-next albutton mnabrbutton"
									>
										<i class="fa fa-chevron-right"></i>
									</button>
								</div>
								<mat-horizontal-stepper #stepper class="pad0">
									<mat-step>
										<app-project-workarea-forms
											*ngIf="selectedName == 'form_transaction'"
											(templateAction)="change_template_action($event)"
											[project_data]="wholedata.data"
											[filelevels]="filelevels"
											[supervision_data]="supervision_data"
										></app-project-workarea-forms>
									</mat-step>
									<ng-container *ngIf="ds.allowMe('projects', 'projects_template_permission')">
										<mat-step>
											<app-formation-lists
												*ngIf="selectedName == 'formations'"
												(templateAction)="change_template_action($event)"
												[project_data]="wholedata.data"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
											></app-formation-lists>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_phases">
										<mat-step>
											<app-supervision-i-phases
												*ngIf="selectedName == 'phases'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-i-phases>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_testing_report">
										<mat-step>
											<app-supervision-test-report-workarea
												*ngIf="selectedName == 'test_report_workarea'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
												[makeAction]="tabs_permission?.action_work_area_add"
											></app-supervision-test-report-workarea>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_team_users">
										<mat-step>
											<app-supervision-teams
												*ngIf="selectedName == 'team_users'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-teams>
										</mat-step>
									</ng-container>
									<!-- *ngIf="tabs_permission?.tab_communication" -->
									<ng-container >
										<mat-step>
											<app-communication
												*ngIf="selectedName == 'communication'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-communication>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_contacts">
										<mat-step>
											<app-supervision-contacts
												*ngIf="selectedName == 'contacts'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-contacts>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_contractors">
										<mat-step>
											<app-supervision-contractors
												*ngIf="selectedName == 'contractors'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-contractors>
										</mat-step>
									</ng-container>
									
									<ng-container *ngIf="tabs_permission?.tab_customers">
										<mat-step>
											<app-supervision-customers
												*ngIf="selectedName == 'customers'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-customers>
										</mat-step>
									</ng-container>
									
									<ng-container *ngIf="tabs_permission?.tab_mobilization">
										<mat-step>
											<app-supervision-mobilizations
												*ngIf="selectedName == 'mobilization'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-mobilizations>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_reports">
										<mat-step>
											<app-supervision-reports
												*ngIf="selectedName == 'report'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-reports>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_tender_drawing">
										<mat-step>
											<app-supervision-drawings
												*ngIf="selectedName == 'tender_drawing'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-drawings>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_bill_quantities">
										<mat-step>
											<app-supervision-bill-quantities
												*ngIf="selectedName == 'bill_quantities'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-bill-quantities>
										</mat-step>
									</ng-container>
									
									<ng-container *ngIf="tabs_permission?.tab_time_schedule">
										<mat-step>
											<app-supervision-schedules
												*ngIf="selectedName == 'time_schedule'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-schedules>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_site_testing">
										<mat-step>
											<app-supervision-test-proofs
												*ngIf="selectedName == 'site_testing_file'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-test-proofs>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_documents">
										<mat-step>
											<app-supervision-documents
												*ngIf="selectedName == 'documents'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-documents>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_project_completion">
										<mat-step>
											<app-supervision-project-completion
												*ngIf="selectedName == 'project_completion'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-project-completion>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_contractor_payments">
										<mat-step>
											<app-supervision-contractor-payments
												*ngIf="selectedName == 'contract_payments'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-contractor-payments>
										</mat-step>
									</ng-container>
									<ng-container *ngIf="tabs_permission?.tab_setting">
										<mat-step>
											<app-supervision-alerts
												*ngIf="selectedName == 'settings'"
												[filelevels]="filelevels"
												[supervision_data]="supervision_data"
												[able2add]="super_add"
												[able2edit]="super_edit"
												[project_data]="wholedata.data"
											></app-supervision-alerts>
										</mat-step>
									</ng-container>
								</mat-horizontal-stepper>
							</div>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel class="mt-2">
				<mat-expansion-panel-header>{{
					"lang_workarea_transactions" | language
				}}</mat-expansion-panel-header>
				<div class="material-panel-body">
					<div class="mbrl15 martb0">
						<app-supervision-my-transctions
							[projects_work_area_id]="supervision_data?.projects_work_area_id"
							[projects_supervision_id]="project_supervision_id"
							[projects_profile_id]="project_profile_id"
						></app-supervision-my-transctions>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</section>
	<div class="modal" id="pdfPreviewSuperVision">
		<div class="modal-dialog width70">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">{{ "lang_preview" | language }}</h4>
					<button type="button" class="close" data-dismiss="modal">
						&times;
					</button>
				</div>
				<div class="modal-body">
					<div class="pdf-container">
						<iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
					</div>
				</div>
				<!-- Modal footer -->
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-dismiss="modal">
						{{ "lang_close" | language }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="" *ngIf="template_show_option == 'TADD'">
	<app-formation-add
		(showAction)="change_template_action($event)"
		[project_data]="project_data"
		[filelevels]="filelevels"
		[supervision_data]="supervision_data"
		[template_edit_data]="template_edit_data"
		[template_id]="template_id"
		[actionMode]="actionMode"
	></app-formation-add>
</div>
<!-- wholedata.data.template_platform_code_system -->
<div
	class=""
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'DSR'
	"
>
	<app-form-ps-tr1-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[form_request_id]="'0'"
		[unitsOfTemplate]="unitsOfTemplate"
		(showAction)="change_template_action($event)"
	>
	</app-form-ps-tr1-add>
</div>

<div
	class=""
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'WIR'
	"
>
	<app-form-wri-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[form_request_id]="'0'"
		[unitsOfTemplate]="unitsOfTemplate"
		(showAction)="change_template_action($event)"
	>
	</app-form-wri-add>
</div>

<div
	class=""
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'SQR'
	"
>
	<app-form-sqr-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[form_request_id]="'0'"
		[unitsOfTemplate]="unitsOfTemplate"
		(showAction)="change_template_action($event)"
	>
	</app-form-sqr-add>
</div>

<div
	class=""
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'MSR'
	"
>
	<app-form-msr-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[form_request_id]="'0'"
		[unitsOfTemplate]="unitsOfTemplate"
		(showAction)="change_template_action($event)"
	>
	</app-form-msr-add>
</div>

<div
	class=""
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'MIR'
	"
>
	<app-form-mir-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[form_request_id]="'0'"
		[unitsOfTemplate]="unitsOfTemplate"
		(showAction)="change_template_action($event)"
	>
	</app-form-mir-add>
</div>

<div
	class=""
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'NCR'
	"
>
	<app-form-ncr-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[unitsOfTemplate]="unitsOfTemplate"
		[form_request_id]="'0'"
		(showAction)="change_template_action($event)"
	>
	</app-form-ncr-add>
</div>

<div
	class=""
	*ngIf="
		template_show_option == 'FTNEW' &&
		template_edit_data.template_platform_group_type_code_system == 'SI'
	"
>
	<app-form-si-add
		[project_data]="wholedata.data"
		[supervision_data]="supervision_data"
		[template_data]="template_edit_data"
		[form_key]="template_form_key"
		[form_request_id]="'0'"
		(showAction)="change_template_action($event)"
	>
	</app-form-si-add>
</div>