import { APP_INITIALIZER } from '@angular/core';
import { TranslateLangService } from './bootstrap/translate-lang.service';
export function TranslateLangServiceFactory(translateLangService: TranslateLangService) {
  return () => translateLangService.load();
}
import { StartupService } from './bootstrap/startup.service';
export function StartupServiceFactory(startupService: StartupService) {
  if(localStorage.getItem('loggedin') == "1") {
    return () => startupService.load();
  } else {
    return () => [];
  }
}
import { LanguageService } from './bootstrap/language.service';
export function LanguageServiceFactory(languageService: LanguageService) {
  return () => languageService.load();
}
import { PhrasesService } from './bootstrap/phrases.service';
export function PhrasesServiceFactory(PhrasesService: PhrasesService) {
  return () => PhrasesService.load();
}
import { PermissionService } from './bootstrap/permission.service';
export function MnabrloaderServiceFactory(permit: PermissionService) {
  console.log(localStorage.getItem('loggedin'));
  
  if(localStorage.getItem('loggedin') == "1") {
    return () => permit.load();
  } else {
    return () => [];
  }
}
import { MnabrthemeService } from './bootstrap/mnabrtheme.service';
export function MnabrthemeServiceFactory(mnabrthemeService: MnabrthemeService) {
  if(localStorage.getItem('loggedin') == "1") {
    return () => mnabrthemeService.load();
  } else {
    return () => [];
  }
}
export const appInitializerProviders = [
  {
    provide: APP_INITIALIZER,
    useFactory: LanguageServiceFactory,
    deps: [LanguageService], //load languages
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: PhrasesServiceFactory,
    deps: [PhrasesService], // load phrases
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: MnabrloaderServiceFactory,
    deps: [PermissionService], // load loader
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: MnabrthemeServiceFactory,
    deps: [MnabrthemeService], //load theme
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: StartupServiceFactory,
    deps: [StartupService], //load menu
    multi: true,
  },
  /*
  {
    provide: APP_INITIALIZER,
    useFactory: TranslateLangServiceFactory,
    deps: [TranslateLangService],
    multi: true,
  },*/
];
