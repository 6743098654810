import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
interface list {
  id?: number;
  account_code: string,
  account_name: string,
  opening_debit: string,
  opening_credit: string,
  opening_balance: string,
  transaction_debit: string,
  transaction_credit: string,
  closing_debit: string,
  closing_credit: string,
  closing_balance: string,
}
@Component({
  selector: 'app-account-trial',
  templateUrl: './account-trial.component.html',
  styleUrls: ['./account-trial.component.scss']
})
export class AccountTrialComponent implements OnInit {

  page = 1;
  pageSize = 20;
  collectionSize;
  listofdatas: list[];
  form: FormGroup;
  branches: any = [];
  finances: any = [];
  lodingdatas = this.lang.transform('lang_loading');
  fromaccounts: any = [];
  toaccounts: any = [];
  finance_id: any = "";
  datastatus = false;
  data: any = [];
  totals: any = [];
  settings = environment.singledropdown;
  branch_id: any = (localStorage.getItem('selectedBranch') != null) ? localStorage.getItem('selectedBranch') : '0';
  constructor(public ds:DataService,public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.buildform();
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != '0') {
      this.load_financial_years();
    }
  }
  public selectBrach(branch) {
    this.data = [];
    this.datastatus = false;
    if (branch && branch != '0') {
      localStorage.setItem('selectedBranch', branch);
      this.branch_id = branch;
      this.load_financial_years();
    } else {
      this.spinner.hide();
    }
  }
  public selectFinanceYear(financeid) {
    if (financeid && financeid != '0') {
      this.finance_id = financeid;
    }
  }
  public load_branches() {
    this.branches = [];
    this.ds.getActionByUrl([], 'ab/accounts_view').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.branches = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public load_financial_years() {
    this.finances = [];
    this.form.get('finance_id').setValue('');
    this.ds.getActionByUrl([], 'financial/' + this.branch_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.finances = res.records;
        this.finances.forEach((v, k) => {
          if (v.finance_status == "1") {
            this.form.get('finance_id').setValue(v.finance_id);
            this.form.get('period_from').setValue(v.finance_start_date);
            this.form.get('period_to').setValue(v.finance_end_date);
          }
        });
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public counter(i: number) {
    return new Array(i);
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      finance_id: new FormControl(null, [Validators.required]),
      report_type: new FormControl('ATRAIL', [Validators.required]),
      period_from: new FormControl(null, [Validators.required]),
      period_to: new FormControl(null, [Validators.required]),
      account_level: new FormControl('1'),
    })
  }
  public fetch_statements() {
    if (this.form.valid) {
      this.page       = 1;
      this.pageSize   = 20;
      this.spinner.show();
      let param = new FormData();
      param.set('branch_id', this.form.get('branch_id').value);
      param.set('finance_id', (this.form.get('finance_id').value) ? this.form.get('finance_id').value : '0');
      param.set('report_type', this.form.get('report_type').value);
      if (this.form.get('report_type').value == 'ALEVEL') {
        param.set('account_level', (this.form.get('account_level').value) ? this.form.get('account_level').value : '1');
      } else {
        param.set('account_level', '');
      }
      param.set('period_from', (this.form.get('period_from').value != null) ? this.form.get('period_from').value : '');
      param.set('period_to', (this.form.get('period_to').value != null) ? this.form.get('period_to').value : '');
      this.ds.postActionByUrl(param, 'account_trial').subscribe(res => {
        this.datastatus = res.status;
        this.spinner.hide();
        this.calc(res.records);
        if (res.status && res.records.length > 0) {
          this.listofdatas = res.records;
          this.collectionSize = res.records.length;
          this.totals = res.totals;
          this.refreshLists();
        } else {
          this.lodingdatas = res.error;
          this.listofdatas = [];
          this.data = [];
          this.ds.dialogf('', res.error);
        }
      }, error => {
        this.lodingdatas = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
        this.datastatus = false;
        this.listofdatas = [];
        this.spinner.hide();
        this.data = [];
        this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      this.ds.dialogf('', this.lang.transform('lang_fill_required_fields'))
    }
  }
  public refreshLists() {
    this.data = this.listofdatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  public absolute(amount) {
    if(parseInt(amount) >= 0) {
      let famount:any   = Math.abs(amount);
      return (amount == "0.00") ? "0.00" : this.ds.addCommas(parseFloat(famount).toFixed(2));
    } else {
      let famount:any   = Math.abs(amount);
      return "( "+this.ds.addCommas(parseFloat(famount).toFixed(2))+" )";
    }
  }
  calc(records) {
    /*let opening_debit:any       = 0.00;
    let opening_credit:any      = 0.00;
    let closing_credit:any      = 0.00;
    let closing_debit:any       = 0.00;
    let transaction_credit:any  = 0.00;
    let transaction_debit:any   = 0.00;
    records.forEach((v , k) => {
      if(v.transaction_allow == "0") {
        let od:any      = Math.abs(v.opening_debit);
        opening_debit   += parseFloat(od);
        let oc:any      = Math.abs(v.opening_credit);
        opening_credit   += parseFloat(oc);
        let tc:any      = Math.abs(v.transaction_credit);
        transaction_credit   += parseFloat(tc);
        let td:any      = Math.abs(v.transaction_debit);
        transaction_debit   += parseFloat(td);
        let cc:any      = Math.abs(v.closing_credit);
        closing_credit   += parseFloat(cc);
        let cd:any      = Math.abs(v.closing_debit);
        closing_debit   += parseFloat(cd);
      }
    });
    console.log(opening_debit.toFixed(2));
    console.log(opening_credit.toFixed(2));
    console.log(transaction_credit.toFixed(2));
    console.log(transaction_debit.toFixed(2));
    console.log(closing_credit.toFixed(2));
    console.log(closing_debit.toFixed(2));*/
  }

}
