import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  admin = JSON.parse(this.ls.getItem('is_admin'));
  modalRef: BsModalRef;
  modalRefC: BsModalRef;
  modalRefV: BsModalRef;
  modalRefU: BsModalRef;
  able2delete = JSON.parse(this.ls.getItem('is_admin'));
  permissionForm: FormGroup;
  branches: any = [];
  groups: any = [];
  users: any = [];
  mention_allow: boolean = false;
  pmpage_no = 1;
  pmpage_size = 10;
  pmdata: any = [];
  lodingdatas = this.lang.transform('lang_loading');
  searchBranch= null;
  searchGroup = null;
  searchUser = null;
  //Human Resuorces Settings Starts
  hrpageno = 1;
  hrpagesize = 10;
  settingForm: FormGroup;
  licencesList: FormArray;
  formstatus: boolean = false;
  ferrorMsg: string = "";
  needshortcode: boolean = false;
  needlicence: boolean = false;
  bankshortcodevalue: string = "";
  needlicencevalue: string = "";
  licencefileds: boolean = false;
  allsettings: any = [];
  slstatus: boolean = false;
  slerrorMsg: string = "";
  sltotal: number = 0;
  sltotalpage: number = 0;
  viewstatus: boolean = false;
  viewdata: any = [];
  viewLicenceData: any = [];
  groupformstatus = false;
  groupferrorMsg = "";
  userslist: any = [];
  searchType = 'ALL';
  searchKey = ""; 
  get licenceFormGroup() {
    return this.settingForm.get("licenses") as FormArray;
  }
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.permissionform();
    this.get_branches();
    this.get_groups();
    this.get_users(0);
    this.get_permission_mentions();
    this.loadSettings();
  }
  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  public openModelC(template: TemplateRef<any>) {
    this.modalRefC = this.modalService.show(template, environment.modelconfig);
  }
  public permissionform() {
    this.permissionForm = this.fb.group({
      branch_id: ['', [Validators.required]],
      group_id: ['', [Validators.required]],
      users_id: [null, [Validators.required]],
    });
  }
  public get_branches() {
    this.branches = [];
    this.ds.getActionByUrl([], 'human_resources/get_branches').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.mention_allow = res.mention_allow;
        this.branches = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public get_groups() {
    this.groups = [];
    this.ds.getActionByUrl([], 'human_resources/get_groups').subscribe(res => {
      if (res.status) {
        this.groups = res.records;
      }
    })
  }
  public get_users(value) {
    this.permissionForm.get('users_id').setValue(null);
    this.users = [];
    let bid = (value) ? value : 0;
    let param = new FormData();
    param.append('user_type_id', '1');
    this.ds.postActionByUrl(param, 'human_resources/get_users/' + bid).subscribe(res => {
      this.spinner.hide();;
      if (res.status) {
        this.users = res.records;
      }
    }, error => {
        this.spinner.hide();;
    })
  }
  public emptypermissionfields() {
    this.permissionForm.reset();
    this.permissionForm.get('branch_id').setValue('');
    this.permissionForm.get('group_id').setValue('');
  }
  public savepermissions() {
    this.spinner.show();
    let param = new FormData();
    param.append('branch_id', this.permissionForm.get('branch_id').value || '');
    param.append('group_id', this.permissionForm.get('group_id').value || '');
    param.set('users_id', (this.permissionForm.get('users_id').value && this.permissionForm.get('users_id').value != null) ? this.permissionForm.get('users_id').value.toString() : '');
    this.ds.postActionByUrl(param, 'human_resources/savehrpermissions').subscribe((data) => {
        this.spinner.hide();
        if (data.status) {
          this.alert.success(data.msg);
          this.get_permission_mentions();
          this.permissionForm.reset();
          setTimeout(() => {
            this.modalRef.hide();  
          }, 1000);          
        } else {
          this.alert.error(data.error);
        }
      }, (error) => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      }
    );
  }
  public loadpage(page) {
    this.pmpage_no = page;
    this.pmpage_size = this.pmpage_size;
    this.get_permission_mentions();
  }
  public loadpagesize(size) {
    this.pmpage_no = 1;
    this.pmpage_size = size;
    this.get_permission_mentions();
  }
  public load_permission_mention() {
    this.pmpage_no = 1;
    this.pmpage_size = 10;
    this.get_permission_mentions();
  }
  public get_permission_mentions() {
    let formdata = new FormData();
    formdata.append('search[branch_id]', this.searchBranch || '');
    formdata.append('search[group_key]', this.searchGroup || '');
    formdata.append('search[user_id]', this.searchUser || '');
    this.ds.postActionByUrl(formdata, 'human_resources/get_permission_mentions/' + this.pmpage_no + '/' + this.pmpage_size,).subscribe(res => {
      this.spinner.hide();;
      if (res.status) {
        this.pmdata = res;
      } else {
        this.pmdata = [];
        this.lodingdatas = res.error;
      }
    }, error => {
      this.spinner.hide();;
      this.pmdata = [];
      this.lodingdatas = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public checkAllItemsUsr(event) {
    if (event.target.checked) {
      $(".user_permissions .eachItemUsr").prop("checked", true);
      $(".user_permissions .makeback").addClass("bground");
      $(".user_permissions .deleteIconDiv").show();
    } else {
      $(".user_permissions .eachItemUsr").prop("checked", false);
      $(".user_permissions .makeback").removeClass("bground");
      $(".user_permissions .deleteIconDiv").hide();
    }
  }
  public checkEachItemUsr(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".user_permissions .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".user_permissions .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".user_permissions .eachItemUsr:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".user_permissions .eachItemUsr").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".user_permissions .deleteIconDiv").show();
    } else {
      $(".user_permissions .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".user_permissions #allItemsUsr").prop("checked", true);
    } else {
      $(".user_permissions #allItemsUsr").prop("checked", false);
    }
  }
  
  public deleteBulkDataUsr() {
    const checkedtotal = [];
    $(".user_permissions .eachItemUsr:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, "human_resources/delete_permits").subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.get_permission_mentions();
                $(".user_permissions #allItemsUsr").prop("checked", false);
                $(".user_permissions .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
  //Human Resuorces Settings Starts
  // code start for add setting form
  public buildform(edit) {
    this.settingForm = this.fb.group({
      settings_id: [(edit) ? edit.settings_id : ''],
      settings_type: [(edit) ? edit.settings_type : '', Validators.compose([Validators.required])],
      settings_name_english: [(edit) ? edit.settings_name_english : '', Validators.compose([Validators.required])],
      settings_name_arabic: [(edit) ? edit.settings_name_arabic : '', Validators.compose([Validators.required])],
      settings_short_code: [(edit) ? edit.settings_short_code : ''],
      settings_need_licence: [(edit) ? edit.settings_need_licence : ''],
      licenses: this.fb.array([]),
    });
    this.licencesList = this.settingForm.get("licenses") as FormArray;
  }
  public createLicences(t1, t2): FormGroup {
    return this.fb.group({
      licence_title_english: [t1],
      licence_title_arabic: [t2],
    });
  }
  public dynamicLicenceAdd() {
    this.licencesList.push(this.createLicences('', ''));
  }
  public dynamicLicenceRemove(index) {
    this.licencesList.removeAt(index);
  }
  public getLicencesFormGroup(index): FormGroup {
    const formGroup = this.licencesList.controls[index] as FormGroup;
    return formGroup;
  }
  public formfiledsaction(settings_type) {
    if (settings_type && settings_type === "BANK") {
      this.needshortcode = true;
      this.needlicence = false;
      this.licencefileds = false;
    } else if (settings_type && settings_type === "JTIT") {
      this.needlicence = true;
      this.needshortcode = true;
      this.licencefileds = false;
    } else {
      this.needlicence = false;
      this.needshortcode = false;
      this.licencefileds = false;
    }
  }
  public showlicencefields(need_licence) {
    if (need_licence == "1") {
      this.licencefileds = true;
    } else {
      this.licencefileds = false;
    }
  }
  public emptyformFields() {
    this.alert.clear();
    this.buildform(false);
    this.needlicence = false;
    this.needshortcode = false;
    this.licencefileds = false;
  }
  public saveSettings() {
    this.spinner.show();
    let param = this.ds.json2formdata(this.settingForm.value);
    param.append('settings_status', '1');
    this.ds.postActionByUrl(param, 'human_resources/save_settings').subscribe((data) => {
      this.spinner.hide();
      if (data.status) {
        this.searchLoadSettings();
        this.alert.success(data.msg);
        this.settingForm.reset();
        this.needlicence = false;
        this.needshortcode = false;
        this.licencefileds = false;
        setTimeout(() => {
          this.alert.clear();
          this.modalRefC.hide();
        }, 1500);
      } else {
        this.alert.error(data.error);
      }
    }, (error) => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      }
    );
  }
  public updateSetting() {
    this.spinner.show();
    let param = this.ds.json2formdata(this.settingForm.value);
    param.append('settings_status', '1');    
    this.ds.postActionByUrl(param, 'human_resources/update_settings').subscribe( (data) => {
        this.spinner.hide();
        if (data.status) {
          this.searchLoadSettings();
          this.alert.success(data.msg);
          this.settingForm.reset();
          this.needlicence = false;
          this.needshortcode = false;
          this.licencefileds = false;
          setTimeout(() => {
            this.alert.clear();
            this.modalRefU.hide();
          }, 1500);
        } else {
          this.alert.error(data.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      }
    );
  }
  public searchLoadSettings() {
    this.hrpageno = 1;
    this.hrpagesize = 10;
    this.loadSettings();
  }
  public loadSpage(k) {
    this.spinner.show();
    this.hrpageno = k;
    this.loadSettings();
  }
  public loadSpageSize(size) {
    this.spinner.show();
    this.hrpageno = 1;
    this.hrpagesize = size;
    this.loadSettings();
  }
  public loadSettings() {
    const param = new FormData();
    param.append("searchType", this.searchType || '');
    param.append("searchKey", this.searchKey || '');
    this.ds.postActionByUrl(param, 'human_resources/getsettings/' + this.hrpageno + '/' + this.hrpagesize).subscribe( (data) => {
        this.spinner.hide();
        if (data.status) {
          this.allsettings = data;
        } else {
          this.allsettings = [];
          this.slerrorMsg = data.error;
        }
      },
      (error) => {
        this.allsettings = [];
        this.spinner.hide();
        this.slerrorMsg = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
      }
    );
  }
  public checkAllSet(event) {
    if (event.target.checked) {
      $(".human_resources_settings .eachItemSet").prop("checked", true);
      $(".human_resources_settings .makeback").addClass("bground");
      $(".human_resources_settings .deleteIconDiv").show();
    } else {
      $(".human_resources_settings .eachItemSet").prop("checked", false);
      $(".human_resources_settings .makeback").removeClass("bground");
      $(".human_resources_settings .deleteIconDiv").hide();
    }
  }
  public checkEachItemSet(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".human_resources_settings .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".human_resources_settings .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".human_resources_settings .eachItemSet:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".human_resources_settings .eachItemSet").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".human_resources_settings .deleteIconDiv").show();
    } else {
      $(".human_resources_settings .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".human_resources_settings #allItemsSet").prop("checked", true);
    } else {
      $(".human_resources_settings #allItemsSet").prop("checked", false);
    }
  }
  public deleteBulkSet() {
    const checkedtotal = [];
    $(".human_resources_settings .eachItemSet:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, "human_resources/delete_settings").subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.searchLoadSettings();
                $(".human_resources_settings #allItemsSet").prop("checked", false);
                $(".human_resources_settings .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  public loadSettingData(setting_id, viewtemplate) {
    this.spinner.show();
    let formData = new FormData();
    formData.append('setting_id', setting_id);
    this.ds.postActionByUrl(formData, 'human_resources/view_settings').subscribe(response => {
      this.viewstatus = response.status;
      this.spinner.hide();
      if (response.status) {
        this.openModalV(viewtemplate);
        this.viewdata = response.mainData;
        if (response.licenceData.length > 0) {
          this.viewLicenceData = response.licenceData;
        }
      } else {
        this.ds.dialogf('', response.error);
      }
    }, error => {
      this.spinner.hide();
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public openModalU(template: TemplateRef<any>) {
    this.modalRefU = this.modalService.show(template, environment.modelconfig);
  }
  public loadSettingDataForEdit(setting_id, viewtemplate) {
    this.emptyformFields();
    this.spinner.show();
    let formData = new FormData();
    formData.append('setting_id', setting_id);
    this.ds.postActionByUrl(formData, 'human_resources/edit_settings').subscribe(response => {
      this.viewstatus = response.status;
      this.spinner.hide();
      this.openModalU(viewtemplate);
      this.buildform(response.mainData)
      if (response.status) {
        if (response.mainData.settings_type === "BANK" || response.mainData.settings_type === "JTIT") {
          this.needshortcode = true;
        } else {
          this.needshortcode = false;
        }
        if (response.mainData.settings_type === "JTIT") {
          this.needlicence = true;
          if (response.mainData.settings_need_licence === "1") {
            this.licencefileds = true;
          } else {
            this.licencefileds = false;
          }
        } else {
          this.needlicence = false;
        }
        if (response.licenceData.length > 0) {
          response.licenceData.forEach(item => {
            this.licencesList.push(this.createLicences(item.licence_name_english, item.licence_name_arabic));
          });
        }
      } else {
        this.ds.dialogf('', response.error);
      }
    }, error => {
      this.spinner.hide();
      this.openModalU(viewtemplate);
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
}