import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-rstep7',
  templateUrl: './rstep7.component.html',
  styleUrls: ['./rstep7.component.scss']
})
export class Rstep7Component implements OnInit {
  @Input() signupForm: FormGroup;
  @Input() action;
  @Input() files;
  apiurl = environment.SERVER_ORIGIN;
  public fileurl;
  idfilename = this.lang.transform('lang_select_files');
  isFile: boolean;
  lang_key = localStorage.getItem('lang_key') || 'en';
  constructor(private cdRef: ChangeDetectorRef, public fb:FormBuilder ,public ds: DataService, public route:ActivatedRoute ,public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  ngOnInit(): void {}
  uploadEDUFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.isFile = true;
      this.signupForm.get("passportDetails").get("passport_file").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.signupForm.get("passportDetails").get("passport_file").setValue([]);
      this.idfilename = this.lang.transform('lang_select_files');
    }
  }
  removeFile() {
    this.isFile = false;
    this.signupForm.get("passportDetails").get("passport_file").setValue([]);
    this.idfilename = this.lang.transform('lang_select_files');
  }
}
