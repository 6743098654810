import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-sview',
  templateUrl: './sview.component.html',
  styleUrls: ['./sview.component.scss']
})
export class SviewComponent implements OnInit {
  id                    = this.route.snapshot.paramMap.get('id');
  code                  = this.route.snapshot.paramMap.get('code');
  notesdescription      = '';
  attach:any            = [];
  profile_percentage:any= '';
  countdown             = (localStorage.getItem('countdown')) ? localStorage.getItem('countdown') : '00:00:00';
  viewdata: any         = [];
  datastatus            = false;
  notes:any             = [];
  apiurl                = environment.SERVER_ORIGIN;
  attachments: any      = [];
  cerrormessagef        = this.lang.transform('lang_no_data');
  licences: any         = [];
  view_profile_image    = "";
  lang_key              = localStorage.getItem('lang_key') || 'en';
  asearchKey            = "";
  nsearchKey            = "";
  constructor(private cdRef: ChangeDetectorRef, public ds: DataService, public route:ActivatedRoute ,public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  ngOnInit(): void {
    this.spinner.show();
    this.get_my_data();
  }
  get_my_data() {
    this.ds.getActionByNoToken([], 'signup/get_signup_view/' + this.id + '/' + this.code + '/' + this.lang_key).subscribe(data => {
      this.spinner.hide();
      this.datastatus = data.status;
      if (data.status) {
        this.viewdata = data.data;
        this.attachments = data.attachments;
        this.licences = data.licences;
        if (data.profile_percentage) {
          this.profile_percentage = data.profile_percentage.total;
        }
        if (data.data.profile_image) {
          this.ds.getActionByNoToken([], data.data.profile_image).subscribe(data => {
            if (data.status) {
              this.view_profile_image = 'data:' + data['content-type'] + ';base64,' + data.base64;
            }
          });
        }
        this.get_my_notes();
        this.get_my_attach();
      } else {
        this.ds.dialogf('',data.error);
        this.router.navigate(['']);
      }
    }, error => {
        this.spinner.hide();
        this.datastatus = false;
        this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  get_my_notes() {
    let formData = new FormData();
    formData.append('searchKey', this.nsearchKey || '');
    this.ds.postActionByNoToken(formData, 'signup/get_my_notes/' + this.id + '/' + this.code).subscribe(data => {
      if (data.status) {
        this.notes = data.data;
      } else {
        this.notes = [];
      }
    }, error => {
        this.notes = [];
    })
  }
  get_my_attach(){
    let formData = new FormData();
    formData.append('searchKey', this.asearchKey || '');
    this.ds.postActionByNoToken(formData, 'signup/get_my_attach/' + this.id + '/' + this.code + '/' + this.lang_key).subscribe(data => {
      if (data.status) {
        this.attach = data.data;
      } else {
        this.attach = [];
      }
    }, error => {
        this.attach = [];
    })
  }
  viewnotes(text) {
    this.ds.dialogf('',text);
  }
  logoutme() {
    this.spinner.show();
    this.ds.getActionByNoToken([], 'signup/logoutme/' + this.id + '/' + this.code).subscribe(res => {
      this.spinner.hide();
      localStorage.removeItem('employee_id_number');
      localStorage.removeItem('verified_code');
      this.router.navigate(['auth/login']);
    }, error => {
      this.spinner.hide();
      this.ds.dialogf('',this.lang.transform('lang_wrong'));
    });
  }
}