<breadcrumb></breadcrumb>
<div class="themesection" *ngIf="accAction ==='LIST' ">
    <div class="container mb-10">
        <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-12 mar0auto"></div>
            <div class="col-md-3 col-sm-6 col-xs-12 mar0auto">
                <div *ngIf="branch_id =='0' " class="red pad0 text-center">{{'lang_choose_branch' | language }}</div>
                <select name="branch_id" class="form-control almnabrformcontrol" matTooltip="{{'lang_branch' | language }}" [(ngModel)]="branch_id" (change)="spinner.show();selectBrach($event.target.value);">
                    <option value="0">{{'lang_choose_options' | language }}</option>
                    <option *ngFor="let branch of branches" value="{{branch.id}}">{{branch.title}}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12 mar0auto" *ngIf="branch_id && branch_id !='0' ">
                <ng-select 
                    [items]="finances" 
                    [multiple]="false" 
                    bindLabel="label" 
                    bindValue="value" 
                    clearAllText="Clear"
                    appendTo="body" 
                    [(ngModel)]="finance_id"
                    placeholder="{{'lang_financial_years' | language}}"
                    (change)="spinner.show();load_receipts();">
                </ng-select>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12 mar0auto"></div>
        </div>
    </div>
    <section class="panel-expansion matborderno" *ngIf="branch_id && branch_id !='0' ">
        <mat-accordion class="receipt_lists">
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_receipts' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body">
                    <div class="mbrl15">
                        <div class="row">
                            <div class="col-12">
                                <div class="input-group input-group-sm mb-10">
                                    <input type="text" placeholder="{{'lang_search' | language }}" (keyup)="search_receipts();" [(ngModel)]="searchKey" class="form-control almnabrformcontrol" />
                                    <span class="input-group-btn" *ngIf="branch_id && branch_id !='0' ">
                                        <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" type="button" (click)="accAction = 'ADD' " matTooltip="{{'lang_new_receipt_with_transaction' | language }}"></button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive dropdownyes customResponsive">
                                    <table border="1" class="table CustomTable">
                                        <thead>
                                            <tr>
                                                <td>#</td>
                                                <td>{{'lang_receipt_no' | language }}</td>
                                                <td>{{'lang_transaction_number' | language }}</td>
                                                <td>{{'lang_action_mode' | language }}</td>
                                                <td>{{'lang_receipt_from' | language }}</td>
                                                <td>{{'lang_receipt_date' | language }}</td>
                                                <td>{{'lang_receipt_amount' | language }}</td>
                                                <td>{{'lang_writer' | language }}</td>
                                                <td>{{'lang_ondate' | language }}</td>
                                                <td>{{'lang_action' | language }}</td>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="recData.length != '0' " class="AppendList">
                                            <tr *ngFor="let data of recData.records; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{data.payment_receipt_id}}">
                                                <td>{{i+1}}</td>
                                                <td>
                                                    <a matTooltip="{{'lang_view' | language }}" routerLink="/accounts/recview/{{data.branch_id}}/{{data.payment_receipt_id}}/view">{{data.payreceipt_system_code}}</a>
                                                </td>
                                                <td>
                                                    <a matTooltip="{{'lang_view' | language }}" routerLink="/accounts/recview/{{data.branch_id}}/{{data.payment_receipt_id}}/view">{{data.transaction_id}}</a>
                                                </td>
                                                <td *ngIf="data.transaction_request_id =='0' ">----</td>
                                                <td *ngIf="data.transaction_request_id !='0' ">
                                                    <a matTooltip="{{'lang_view' | language }}" routerLink="/accounts/recview/{{data.branch_id}}/{{data.payment_receipt_id}}/view">{{'lang_transactions' | language }}</a>
                                                </td>
                                                
                                                <td><a matTooltip="{{'lang_view' | language }}" routerLink="/accounts/recview/{{data.branch_id}}/{{data.payment_receipt_id}}/view">{{data.payment_receipt_to_from}}</a></td>
                                                <td><a matTooltip="{{'lang_view' | language }}" routerLink="/accounts/recview/{{data.branch_id}}/{{data.payment_receipt_id}}/view">{{data.payment_receipt_date}}</a></td>
                                                <td><a matTooltip="{{'lang_view' | language }}" routerLink="/accounts/recview/{{data.branch_id}}/{{data.payment_receipt_id}}/view">{{ds.addCommas(data.payment_receipt_amount)}}</a></td>
                                                <td>{{data.writer_name}}</td>
                                                <td>{{data.payment_receipt_created_date}}</td>
                                                <td class="dropdown">
                                                    <mat-icon class="mnabricon" [matMenuTriggerFor]="menu">settings</mat-icon>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item *ngIf="data.payment_receipt_attachment" (click)="ds.previewData(data.payment_receipt_attachment,'#pdfPreviewRecPay')"><span>{{'lang_file' | language }}</span></button>
                                                        <button mat-menu-item matTooltip="{{'lang_view' | language }}" routerLink="/accounts/recview/{{data.branch_id}}/{{data.payment_receipt_id}}/view"><span>{{'lang_view' | language }}</span></button>
                                                        <button mat-menu-item *ngIf="recData.permits.edit && recData.financial_year.finance_id == data.finance_id" routerLink="/accounts/recedit/{{data.branch_id}}/{{data.payment_receipt_id}}/edit"><span>{{'lang_edit' | language }}</span></button>
                                                        <button mat-menu-item *ngIf="recData.permits.delete && recData.financial_year.finance_id == data.finance_id" (click)="remove_receipt(data)"><span>{{'lang_delete' | language }}</span></button>

                                                        <button mat-menu-item (click)="ds.export_account_transaction(data.payment_receipt_id, 'REC', 'PDF', branch_id);"><span>{{'lang_export_pdf' | language }}</span></button>
                                                        <button mat-menu-item (click)="ds.export_account_transaction(data.payment_receipt_id, 'REC', 'EXL', branch_id);"><span>{{'lang_export_excel' | language }}</span></button>
                                                        <button mat-menu-item (click)="ds.export_account_transaction(data.payment_receipt_id, 'REC', 'EPDF', branch_id);"><span>{{'lang_export_pdf_to_email' | language }}</span></button>
                                                        <button mat-menu-item (click)="ds.export_account_transaction(data.payment_receipt_id, 'REC', 'EEXL', branch_id);"><span>{{'lang_export_excel_to_email' | language }}</span></button>
                                                    </mat-menu>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="recData.length == '0' " class="AppendList">
                                            <tr class="odd">
                                                <td colspan="10" align="center">{{lodingdatas}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="recData.length != '0' " class="card-footer padb0">
                        <div class="row">
                            <div class="col-lg-3 col-sm-6 col-6 mb-10">
                                {{'lang_total' | language }} : {{recData.paging.total_records}}
                            </div>
                            <div class="col-lg-5 col-sm-6 mb-10">
                                <mat-radio-group>
                                    <mat-radio-button value="10" [checked]="(recpage_size =='10') ? true : false" (click)="loadrecpagesize(10)"> 10 </mat-radio-button>
                                    <mat-radio-button value="20" [checked]="(recpage_size =='20') ? true : false" (click)="loadrecpagesize(20)"> 20 </mat-radio-button>
                                    <mat-radio-button value="50" [checked]="(recpage_size =='50') ? true : false" (click)="loadrecpagesize(50)"> 50 </mat-radio-button>
                                    <mat-radio-button value="100" [checked]="(recpage_size =='100') ? true : false" (click)="loadrecpagesize(100)"> 100 </mat-radio-button>
                                    <mat-radio-button value="500" [checked]="(recpage_size =='500') ? true : false" (click)="loadrecpagesize(500)"> 500 </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                {{'lang_goto' | language }} :
                                <select class="customselect" (change)="loadpagerec($event.target.value)">
                                    <option *ngFor="let ks of ds.getrange(recData.paging.total_pages)" [selected]="(recpage_no == ks) ? true : false" value="{{ks}}">{{ks}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</div>
<div class="modal" id="pdfPreviewRecPay"*ngIf="accAction ==='LIST' ">
    <div class="modal-dialog width70">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{'lang_preview' | language }}</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="pdf-container">
            <iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'lang_close' | language }}</button>
        </div>
      </div>
    </div>
</div>
<app-form-acr-add  *ngIf="accAction !=='LIST' " (actionChanges)="accAction = 'LIST';spinner.show(); search_receipts();" [branch_id]="branch_id"></app-form-acr-add>