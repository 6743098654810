import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ViolationHistoryComponent } from '../../../send-code/violation-history.component';

declare var $:any;
@Component({
  selector: 'violation-step-one-configrations',
  templateUrl: './step-one-configrations.component.html',
  styleUrls: ['./step-one-configrations.component.scss']
})
export class ViolationStepOneConfigrationsComponent implements OnInit {
 
  @Output() violationEvent = new EventEmitter();
  @Input() allData;
  @Input() form_request_id;
  @Input() form_key;
  @Input() id;
 
  users:any         = [];
  direct_anager:any = [];
  form:FormGroup;
  submitted:boolean = false;
  lang_key          = localStorage.getItem('lang_key') || 'en';
  constructor(public ds:DataService, public datepipe : DatePipe ,public dialog: MatDialog,  private snackBar:MatSnackBar, public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) { }


  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  ngOnInit(): void {
    this.spinner.show();
    console.log('allData allData allData',this.allData);
    
    this.users = [{
      label:this.allData?.form_vol1_data?.records?.direct_manager_name,
      value:this.allData?.form_vol1_data?.records?.direct_manager
    }]
    moment.locale('en')
    this.form = this.fb.group({
      violation_id:[this.allData?.form_vol1_data?.records?.violation_id, Validators.required],
      employee_number: [this.allData?.form_vol1_data?.records.employee_number],
      violation_date_en: [moment(this.allData?.form_vol1_data?.records?.violation_date_en,'YYYY-MM-DD').format(), Validators.required],
      violation_date_ar: [this.allData?.form_vol1_data?.records?.violation_date_ar, Validators.required],
      violation_data_en: [this.allData?.form_vol1_data?.records?.violation_data_en, ],
      violation_data_ar: [this.allData?.form_vol1_data?.records?.violation_data_ar,  ],
      violation_punishment_id: [this.allData?.form_vol1_data?.records?.violation_punishment_id, Validators.required],
      violation_punishment: [this.allData?.form_vol1_data?.records?.violation_punishment, Validators.required],
      violation_count: [this.allData?.form_vol1_data?.records?.violation_count, Validators.required],
      violation_statement_en: [this.allData?.form_vol1_data?.records?.violation_statement_en, Validators.required],
      violation_statement_ar: [this.allData?.form_vol1_data?.records?.violation_statement_ar, Validators.required],
      violation_punishment_type: [this.allData?.form_vol1_data?.records?.violation_punishment_type, Validators.required],
      violation_deduct_percentage: [this.allData?.form_vol1_data?.records?.violation_deduct_percentage, Validators.required],
      violation_amount: [this.allData?.form_vol1_data?.records?.violation_amount, Validators.required],
 
      direct_manager: [this.allData?.form_vol1_data?.records?.direct_manager, Validators.required],
   
    });
console.log(this.form);

    this.searchFunc()
    this.spinner.hide();
    console.log(this.form);
   
  }

  searchArray = []
  searchFunc() {
   

  
   

  /*   this.spinner.show(); */
    let formData = new FormData();
    /* formData.append('search',term); */


  
    return  this.ds.post(`form/${this.form_key}/search_violation`, formData).subscribe(
      (response:any) => {
        console.log(response);
      /*   this.spinner.hide(); */
    
      this.searchArray =  response.records.map(re=>{
        return {
          label:this.lang_key == 'en'? re.violation_en : re.violation_ar,
          value:re.violation_id
        }
      })
      })

   
 
  }
  
 
  getViolations(e){
  moment.locale('en')

  /*   this.spinner.show(); */
  let formData = new FormData();
  formData.append('employee_number',this.form.value.employee_number); 
  formData.append('violation_id',e.value); 
  formData.append('violation_date_english',moment(this.form.value.violation_date_en).format('YYYY/MM/DD')); 



  return  this.ds.post(`form/${this.form_key}/check_violation_for_employee`, formData).subscribe(
    (response:any) => {
      console.log(response);
      if(response.status){
        this.form.patchValue({
          ...this.form.value,
          violation_id:response.records.violation_id,
          violation_punishment_id:response.records.violation_punishment_id,
          violation_punishment:response.records.punishment_en,
          violation_count:response.records.alert_time,
          violation_punishment_type:response.records.punishment_type,
          violation_deduct_percentage:response.records.deduct_percentage,
          violation_amount:response.records.deduct_amount,
        })
      }else{

      }
    /*   this.spinner.hide(); */
  
   /*  this.searchArray =  response.records.map(re=>{
      return {
        label:this.lang_key == 'en'? re.violation_en : re.violation_ar,
        value:re.violation_id
      }
    }) */


    })

 

  }
  submitForm(){
    this.submitted = true;
    if(this.form?.status == 'VALID'){

      this.spinner.show();
      let formData = new FormData();
      moment.locale('en')
      formData.append('transaction_request_id',this.allData?.form_vol1_data?.records?.transaction_request_id)
      formData.append('employee_number',this.form.value?.employee_number);
      formData.append('violation_date_en',moment(this.form.value.violation_date_en).format('YYYY/MM/DD'));
      formData.append('violation_date_ar',this.form.value.violation_date_ar);
      formData.append('violation_id',this.form.value.violation_id);
      formData.append('violation_punishment_id',this.form.value.violation_punishment_id);
      formData.append('direct_manager', this.form.value.direct_manager || '');
      formData.append('violation_punishment',this.form.value.violation_punishment);
      formData.append('violation_count',this.form.value.violation_count);
      formData.append('violation_statement_en',this.form.value.violation_statement_en);
      formData.append('violation_statement_ar',this.form.value.violation_statement_ar);
      formData.append('violation_punishment_type',this.form.value.violation_punishment_type);
      this.form.value.violation_deduct_percentage = +this.form.value.violation_deduct_percentage
      formData.append('violation_deduct_percentage',this.form.value.violation_deduct_percentage);
      formData.append('violation_amount',this.form.value.violation_amount);

      this.ds.post(`form/${this.form_key}/et`, formData).subscribe(data => {
        
        console.log('res',data);

        if(data.status){
          this.alert.success(data.msg);
          this.showMsg(data.msg, 'ok', 'success-snackbar');
         /*  this.showMsg(this.lang.transform('lang_updated_successfully'), 'ok', 'success-snackbar');
   */
          this.submitted = false;
          this.form.reset()
          setTimeout(() => {
            location.reload();
          }, 1000);
          this.spinner.hide();
        }else{
          this.alert.error(data.msg);
          /* this.showMsg(this.lang.transform('lang_updated_successfully'), 'ok', 'success-snackbar');
   */
          this.submitted = false;
          
          this.spinner.hide();
        }
        
       
      }, (error) => {
        this.spinner.hide();
          this.alert.error(error);
      })
   
   
      
    }else{
      
      this.showMsg('Please fill all fields first', 'ok', 'error-snackbar');
    }
  }


  showMsg(msg:string, action:string, className:string) {
    this.snackBar.open(msg, action, {
      duration:4000,
      panelClass: [className]
    });
  }

  public search_users(key) {
    if(key){
      this.users = [];
      let param = new FormData();
      param.append('search', key.term);
      param.append('lang_key', this.lang_key);
      param.append('user_type_id', '1');
      this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      })
    }
  
  }
 
  violationsArr=[]
  modalRef: BsModalRef;
  get_employee_violations(){
 
  if(!this.form.value.violation_id){
    this.showMsg(this.lang.transform('lang_please_select_violation_first'), 'ok', 'error-snackbar');
    return 0;
  }

  /*   this.spinner.show(); */
  let formData = new FormData();
  formData.append('employee_number',this.form.value.employee_number); 
  formData.append('violation_id',this.form.value.violation_id); 
  formData.append('lang_key',this.lang_key); 

  this.spinner.show();


  return  this.ds.post(`form/${this.form_key}/get_employee_violations`, formData).subscribe(
    (response:any) => {
     
      this.spinner.hide();
      console.log(response);
      
      if(response.status){
        this.violationsArr = response.records;
        this.openModal()
      }else{
        this.showMsg(response.error, 'ok', 'error-snackbar');
      }
   
    }), (error) => {
      this.spinner.hide();
      this.alert.error(error);
      
    }
 

 

  }
  openModal() {
    const dialogRef = this.dialog.open(ViolationHistoryComponent,{
      disableClose: true,
      width:'70%',
      data: { violationsArr: this.violationsArr}
      
    });
  
  }
  
  get violation_id (){
    return this.form.get('violation_id') as FormControl;
  }
}