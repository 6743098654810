import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { PreviewPdfComponent } from '../preview-pdf/preview-pdf.component';

@Component({
  selector: 'app-transaction-attachments',
  templateUrl: './transaction-attachments.component.html',
  styleUrls: ['./transaction-attachments.component.scss']
})
export class TransactionAttachmentsComponent implements OnInit {

  @Input() All_Attachments;

  constructor(public ds: DataService, private spinner: PreloaderService, public dialog: MatDialog, private sweetAlert: SweetAlertService) { }

  ngOnInit(): void {
  }
  downloadFile(path, title) {
    this.spinner.show();
    this.ds.getActionByUrl([], path).subscribe(data => {
      this.spinner.hide();
			if (data.status) {
        let url = 'data:' + data['content-type'] + ';base64,' + data.base64;
        
        let tagA = document.createElement('a');
        tagA.style.display = 'none';
        tagA.href = url;
        tagA.download = title;
        tagA.click();
			}
		}, error => {
			
		});
  }

  public preview_file(url) {
		this.spinner.show();
		this.ds.getActionByUrl([], url).subscribe((res) => {
			this.spinner.hide();
			if(res.status) {
				if(res.extension == 'pdf') {
					let src = "data:" + res["content-type"] + ";base64," + res.base64
					const dialogRef = this.dialog.open(PreviewPdfComponent, {
						disableClose: false,
						width: "100%",
						data: {
							src:src
						}
					});
				} else {
					this.sweetAlert.errorMessage('The attachment must be a PDF');
				}
				
			}
		})
		
	}

}
