<div class="card apppositions">                                          
    <div class="card-header">{{'lang_human_resources_job_details' | language }} </div>
    <div class="card-body p-0">
      <div class="mbrl15 martb0">
          <div class="row">
              <div class="col-12">
                  <div class="input-group input-group-sm mb-10">
                      <input type="text" class="form-control almnabrformcontrol" (keyup)="search_my_datas()" [(ngModel)]="psearchKey" placeholder="{{'lang_search' | language }}">
                      <span class="input-group-btn" *ngIf="requestfor !== 'profile' && user_data.employee_status == '1'  && able2add ">
                          <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" id="btnSearch" type="button" (click)="spinner.show();openModalA(addNewPosition);emptypositionForm();" matTooltip="{{'lang_add' | language }}"></button>
                      </span>
                      <span class="input-group-btn" *ngIf="attachments.rs0001">
                          <button class="btnSearch h34 btn btn-md btn-gray fa fa-paperclip mnabricon" id="btnSearch" type="button" (click)="ds.previewData(attachments.rs0001);" matTooltip="{{'lang_recent_attachment' | language }} : {{attachments.rs0001_d}}"></button>
                      </span>
                  </div>
              </div>
              <div class="col-12">
                  <div class="TablePart1">
                      <div class="table-responsive dropdownyes customResponsive">
                          <table border="1" class="table CustomTable" *ngIf="wholedata.length != '0'" >
                              <thead>
                                  <tr>
                                      <td>#</td>
                                      <td *ngIf="requestfor !== 'profile' ">
                                          <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                              <input type="checkbox" class="allItemsPos checkbox" id="allItemsPos" (click)="checkAllPos($event)"/>
                                              <label for="checkbox"></label>
                                          </div>
                                      </td>
                                      <td>{{'lang_position' | language }}</td>
                                      <td>{{'lang_description' | language }}</td>
                                      <td>{{'lang_ondate' | language }}</td>
                                      <td>{{'lang_writer' | language }}</td>
                                      <td>{{'lang_action' | language }}</td>
                                  </tr>
                              </thead>
                              <tbody class="AppendList">
                                  <tr *ngFor="let data of wholedata.records; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{data.position_id}}">
                                      <td>{{i+1}}</td>
                                      <td *ngIf="requestfor !== 'profile' ">
                                          <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                              <input type="checkbox"  class="eachItemPos checkbox" value="{{data.position_id}}" (click)="checkEachItemPos($event)"/>
                                              <label for="checkbox"></label>
                                          </div>
                                      </td>
                                      <td>{{data.postition_name}}</td>
                                      <td>{{data.job_descriptions}}</td>
                                      <td>{{data.created_datetime}}</td>
                                      <td *ngIf="data.name">{{data.name}}</td>
                                      <td *ngIf="!data.name">{{data.username}}</td>
                                      <td>
                                          <i *ngIf="data.licencecount !=='0'" (click)="view_my_position(data, 'view', viewPositionModal)" class="fa fa-eye fa-icon mnabricon s2icon" matTooltip="{{'lang_view' | language }}"></i>
                                          <i *ngIf="able2edit && user_data.employee_status == '1' " class="fa fa-pencil-square-o fa-icon mnabricon s2icon" matTooltip="{{'lang_edit' | language }}" (click)="view_my_position(data, 'edit', addNewPosition)" ></i>
                                      </td>
                                  </tr>
                              </tbody>
                             <!--  <tbody *ngIf="wholedata.length == '0'" class="AppendList">
                                  <tr class="odd">
                                      <td colspan="7" align="center">{{nodatamsg}}</td>
                                  </tr>
                              </tbody> -->
                          </table>
                          <no-data style="margin: auto;"  [Opened]="wholedata.length < 1 "></no-data>

                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div *ngIf="wholedata.length != '0'" class="card-footer padb0 bottomfooter">
          <div class="row">
              <div *ngIf="able2delete && requestfor !== 'profile' " class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                  <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataPos()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
              </div>
              <div class="col-lg-2 col-sm-6 col-6  mb-10">
                  {{'lang_total' | language }} : {{wholedata.page.total_records}}
              </div>
              <div class="col-lg-5 col-sm-6 col-12 mb-10">
                  <mat-radio-group aria-label="Select an option">
                      <mat-radio-button value="10" checked (click)="spinner.show();pagesize(10)"> 10 </mat-radio-button>
                      <mat-radio-button value="20" (click)="spinner.show();pagesize(20)"> 20 </mat-radio-button>
                      <mat-radio-button value="50" (click)="spinner.show();pagesize(50)"> 50 </mat-radio-button>
                      <mat-radio-button value="100" (click)="spinner.show();pagesize(100)"> 100 </mat-radio-button>
                      <mat-radio-button value="500" (click)="spinner.show();pagesize(500)"> 500 </mat-radio-button>
                  </mat-radio-group>
              </div>
              <div class="col-lg-4 col-sm-6 col-12 mb-10">
                  {{'lang_goto' | language }} :
                  <select class="customselect" (change)="spinner.show();pagelength($event.target.value)">
                      <option *ngFor="let k of ds.getrange(wholedata.page.total_pages)" value="{{k}}"> {{k}}</option>
                  </select>
              </div>
          </div>
      </div>
    </div>
</div>
<ng-template #addNewPosition>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_position' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefA.hide();">&times;</button>
        </div>
        <form [formGroup]="positionform" (ngSubmit)="create_position()">
            <div class="modal-body">
                <input type="hidden" formControlName="position_id">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="settings_id">{{'lang_position' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <select formControlName="settings_id" #id class="form-control almnabrformcontrol" (change)="get_needed_licences(id.value)" matTooltip="{{'lang_positions' | language }}">
                                <option value="">{{'lang_choose_options' | language }}</option>
                                <option *ngFor="let type of jobLists" value="{{type.value}}">{{type.label}}</option>
                            </select>
                            <mat-error *ngIf="positionform.controls['settings_id'].touched && positionform.controls['settings_id'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="job_descriptions">{{'lang_description' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <textarea formControlName="job_descriptions" class="form-control almnabrformcontrol" placeholder="{{'lang_description' | language }}"></textarea>
                            <mat-error *ngIf="positionform.controls['job_descriptions'].touched && positionform.controls['job_descriptions'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div formArrayName="liclists">
                    <label class="red" *ngIf="licencesstatus">{{'lang_need_licence_details' | language }}</label>
                    <hr *ngIf="licencesstatus">
                    <div *ngFor="let licence of licenceFormGroup.controls; let i = index; let jj = i; let aj = i; let ed = i; let ad = i;">
                        <div class="row mb-10" [formGroupName]="i">
                            <input type="hidden" formControlName="employee_number">
                            <input type="hidden" formControlName="licence_list_id">
                            <input type="hidden" formControlName="settings_id">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label class="labelInput">{{'lang_licence_name' | language }} <i (click)="dynamicLicenceRemove(i)" class="fa fa-minus-square red fa-icon" matTooltip="{{'lang_delete' | language }}"></i></label>
                                <input type="text" class="form-control almnabrformcontrol labelInput" formControlName="licence_name" placeholder="{{'lang_licence_name' | language }}" [readonly]="true">
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_number' | language }}</label>
                                <input type="text" class="form-control almnabrformcontrol" required formControlName="licence_number" placeholder="{{'lang_licence_number' | language }}">
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_idate_english' | language }}: <span class="red">*</span></label>
                                <div class="input-group input-group-sm">
                                    <input [matDatepicker]="licence_issue_date_englishU" formControlName="licence_issue_date_english" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly (dateChange)="ds.setENTOARDate($event, licencesList.controls[i].get('licence_issue_date_arabic'))">
                                    <span class="input-group-btn">
                                        <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="licence_issue_date_englishU"></mat-datepicker-toggle>
                                        <mat-datepicker #licence_issue_date_englishU></mat-datepicker>
                                    </span>
                                    <span class="input-group-btn">
                                        <button (click)="licencesList.controls[i].get('licence_issue_date_english').setValue('');licencesList.controls[i].get('licence_issue_date_arabic').setValue(null);" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_idate_arabic' | language }}: <span class="red">*</span></label>
                                <input type="text" required class="form-control almnabrformcontrol" ngbDatepicker (click)="licdateia.toggle()" #licdateia="ngbDatepicker" (ngModelChange)="ds.setARTOENDate($event, licencesList.controls[i].get('licence_issue_date_english'))" formControlName="licence_issue_date_arabic" placeholder="YYYY/MM/DD" [readonly]="true">
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_edate_english' | language }}: <span class="red">*</span></label>
                                <div class="input-group input-group-sm">
                                    <input [matDatepicker]="licence_expiry_date_englishU" formControlName="licence_expiry_date_english" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly (dateChange)="ds.setENTOARDate($event, licencesList.controls[i].get('licence_expiry_date_arabic'))">
                                    <span class="input-group-btn">
                                        <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="licence_expiry_date_englishU"></mat-datepicker-toggle>
                                        <mat-datepicker #licence_expiry_date_englishU></mat-datepicker>
                                    </span>
                                    <span class="input-group-btn">
                                        <button (click)="licencesList.controls[i].get('licence_expiry_date_english').setValue('');licencesList.controls[i].get('licence_expiry_date_arabic').setValue(null);" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_edate_arabic' | language }}: <span class="red">*</span></label>
                                <input type="text" required class="form-control almnabrformcontrol" ngbDatepicker (click)="licdateea.toggle()" #licdateea="ngbDatepicker" [readonly]="true" (ngModelChange)="ds.setARTOENDate($event, licencesList.controls[i].get('licence_expiry_date_english'))" formControlName="licence_expiry_date_arabic" placeholder="YYYY/MM/DD" [readonly]="true">
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefA.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #viewPositionModal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_human_resources_job_details' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefV.hide();">&times;</button>
        </div>
        <div class="modal-body" *ngIf="viewdata">
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_position' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{viewdata.postition_name}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_description' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{viewdata.job_descriptions}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_need_licence' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngIf="viewdata.settings_need_licence =='1'">{{'lang_yes' | language }}</b>
                        <b class="breakall" *ngIf="viewdata.settings_need_licence =='0'">{{'lang_no' | language }}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_writer' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngIf="viewdata.name">{{viewdata.name}}</b>
                        <b class="breakall" *ngIf="!viewdata.name">{{viewdata.username}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_created_date' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{viewdata.created_datetime}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="licencedata.length !='0'">
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive customResponsive">
                            <table border="1" class="table CustomTable">
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>{{'lang_licence_name' | language }}</td>
                                        <td>{{'lang_licence_number' | language }}</td>
                                        <td>{{'lang_issue_date' | language }}</td>
                                        <td>{{'lang_expiry_date' | language }}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let l of licencedata; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }">
                                        <td>{{i+1}}</td>
                                        <td>{{l.licence_name}}</td>
                                        <td>{{l.licence_number}}</td>
                                        <td>{{l.licence_issue_date_english}} - {{l.licence_issue_date_arabic}}</td>
                                        <td>{{l.licence_expiry_date_english}} - {{l.licence_expiry_date_arabic}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modalRefV.hide();">{{'lang_close' | language }}</button>
        </div>
    </div>
</ng-template>