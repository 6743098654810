import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { DwsrService } from '../../FORM_PS_TR1/services/dwsr.service';
@Component({
  selector: 'app-tview',
  templateUrl: './tview.component.html',
  styleUrls: ['./tview.component.scss']
})
export class TviewComponent implements OnInit, OnChanges {
  //
  public mapaddress;

  public edit;
  public delete;
  public view;
  public zoom: number             = 16;
  public latitude: number         = 52.520008;
  public longitude: number        = 13.404954; 
  public project_profile_id       = this.route.snapshot.paramMap.get("pid");
  public project_supervision_id   = this.route.snapshot.paramMap.get("sid");

  public wholedata: any;
  public template_edit_data: any;
  public filelevels:any;
  //
   // Supervision details start
   public supervision_data: any;
   public super_add: boolean       = false;
   public super_view: boolean      = false;
   public super_edit: boolean      = false;
   public allData:Object;
   // Supervision details end
  form_key          = this.route.snapshot.paramMap.get('form_key');
  form_request_id   = this.route.snapshot.paramMap.get('form_request_id');
  page              = this.route.snapshot.paramMap.get('page');
  constructor(public route : ActivatedRoute,public lang:LanguagePipe,
    public ds: DataService,
    private dwsrService: DwsrService,
    public spinner: PreloaderService,
    public router: Router){
      
    }
  ngOnChanges(changes: SimpleChanges): void {
    this.form_key         = this.route.snapshot.paramMap.get('form_key');
    this.form_request_id  = this.route.snapshot.paramMap.get('form_request_id');
    this.page              = this.route.snapshot.paramMap.get('page');
    this.dwsrService.form_key = this.form_key;
    this.dwsrService.form_request_id = this.form_request_id;
  
  }
  ngOnInit() {
    // this.project_supervision_data();
    this.dwsrService.form_key = this.form_key;
    this.dwsrService.form_request_id = this.form_request_id;
    
  }

  public project_supervision_data() {
    let url = [ "TEd1bgyHSC0GPcq/" + this.project_profile_id, "5mOjlwsszk1lds4/" + this.project_profile_id + "/" + this.project_supervision_id];
    this.ds.forkJoin(url, []).subscribe(
      (res) => {
        this.spinner.hide();
        console.log(res);
        
        if (res[0].status) {
          this.wholedata          = res[0];
          this.edit               = res[0].edit;
          this.view               = res[0].view;
          this.delete             = res[0].delete;
          this.mapaddress         = res[0].data.projects_profile_location;
          if (res[0].data.longitude && res[0].data.latitude) {
            this.latitude         = Number(res[0].data.latitude);
            this.longitude        = Number(res[0].data.longitude);
          }
          this.zoom               = 16;
        }
        if (res[1].status) {
          this.supervision_data   = res[1].records;
          this.super_add          = res[1].super_add;
          this.super_edit         = res[1].super_edit;
        }
      }, (error) => {
        this.spinner.hide();
        this.ds.dialogf("", (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
        this.router.navigate(["projects/BbVUrWitLE"]);
      }
    );
  }

 
}
