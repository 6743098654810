import { HttpHandler, HttpEvent, HttpRequest, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { LocalStorage } from "@core/bootstrap/localstorage.service";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	constructor(private ls:LocalStorage) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		request = request.clone({
			setHeaders: {
				'X-API-KEY': environment.X_API_KEY,
				Authorization: (localStorage.getItem('loggedin') == "1") ? this.ls.getItemString('token') : '',
			}
		});
		return next.handle(request);
    }
}