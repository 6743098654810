import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "./core/core.module";
import { ThemeModule } from "./theme/theme.module";
import { RoutesModule } from "./routes/routes.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";
import { TranslateModule } from "@ngx-translate/core";
import { appInitializerProviders } from "@core/initializers";
import { FormlyConfigModule } from "./formly-config.module";
import { RequestInterceptor } from "@core/interceptors/request.interceptor";
import { ResponseInterceptor } from "@core/interceptors/response.interceptor";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { DefaultModule } from "./routes/default/default.module";
import { TransactionsModule } from "./routes/transactions/transactions.module";
import { HumanresourcesModule } from "./routes/humanresources/humanresources.module";
import { TasksModule } from "./routes/tasks/tasks.module";
import { CustomersModule } from "./routes/customers/customers.module";
import { ContractorsModule } from "./routes/contractors/contractors.module";
import { SuppliersModule } from "./routes/suppliers/suppliers.module";
import { AccountsModule } from "./routes/accounts/accounts.module";
import { ProjectsModule } from "./routes/projects/projects.module";
import { environment } from "@env/environment";
import { AgmCoreModule } from "@agm/core";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { CommunicationModule } from "./routes/communication/communication.module";
import {
	NgbModule,
	NgbPaginationModule,
	NgbAlertModule,
	NgbCalendar,
	NgbCalendarIslamicUmalqura,
	NgbDatepickerI18n,
} from "@ng-bootstrap/ng-bootstrap";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { ColorPickerModule } from "ngx-color-picker";
import { ModalModule } from "ngx-bootstrap/modal";
import { TreeModule } from "@circlon/angular-tree-component";
import { DatePipe } from "@angular/common";
import { JwPaginationModule } from "jw-angular-pagination";
import { NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import {
	CustomAdapter,
	CustomDateParserFormatter,
	IslamicI18n,
} from "@shared/utils/arabicpicker";
import { DpDatePickerModule } from "ng2-date-picker";
import { McalendarModule } from "./routes/mcalendar/mcalendar.module";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { NgStepperModule } from "angular-ng-stepper";
import { AngularFireModule } from "@angular/fire";
import { AngularFireMessagingModule } from "@angular/fire/messaging";

const googleMapsParams = {
	apiKey: environment.GOOGLE_MAPS_API_KEY,
	libraries: ["places"],
	language: "en",
};
@NgModule({
	declarations: [AppComponent],
	imports: [
		NgbModule,

		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		NgxSpinnerModule,
		CoreModule,
		ThemeModule,
		RoutesModule,
		SharedModule,
		DefaultModule,
		TransactionsModule,
		HumanresourcesModule,
		TasksModule,
		CustomersModule,
		ContractorsModule,
		SuppliersModule,
		AccountsModule,
		McalendarModule,
		ProjectsModule,
		CommunicationModule,
		FormlyConfigModule.forRoot(),
		ToastrModule.forRoot(),
		TranslateModule.forRoot(),
		AgmCoreModule.forRoot(googleMapsParams),
		MatGoogleMapsAutocompleteModule,
		AutocompleteLibModule,
		NgSelectModule,
		NgOptionHighlightModule,
		NgxMatSelectSearchModule,
		AngularMultiSelectModule,
		NgbModule,
		NgbPaginationModule,
		NgbAlertModule,
		CKEditorModule,
		ColorPickerModule,
		ModalModule.forRoot(),
		TreeModule,
		JwPaginationModule,
		DpDatePickerModule,
		CdkStepperModule,
		NgStepperModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireMessagingModule,
		
	],
	providers: [
		appInitializerProviders,
		LanguagePipe,
		LocalStorage,
		DatePipe,
		{ provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
		{ provide: NgbDateAdapter, useClass: CustomAdapter },
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
		{ provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
		{ provide: NgbDatepickerI18n, useClass: IslamicI18n },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
