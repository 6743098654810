import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
//added by raja for almnabr system
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import Font from '@ckeditor/ckeditor5-font/src/font';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
export default class ClassicEditor extends ClassicEditorBase {}
ClassicEditor.builtinPlugins = [
    Essentials,
    Autoformat,
    Alignment,
    Bold,
    Italic,
    Underline,
    Strikethrough,
    RemoveFormat,
    Font,
    FontFamily,
    Heading,
    Indent,
    Table,
    TableToolbar,
    TableProperties,
    TableCellProperties,
    TextTransformation
];
const customColorPalette = [{
        color: 'hsl(4, 90%, 58%)',
        label: 'Red'
    },
    {
        color: 'hsl(340, 82%, 52%)',
        label: 'Pink'
    },
    {
        color: 'hsl(291, 64%, 42%)',
        label: 'Purple'
    },
    {
        color: 'hsl(262, 52%, 47%)',
        label: 'Deep Purple'
    },
    {
        color: 'hsl(231, 48%, 48%)',
        label: 'Indigo'
    },
    {
        color: 'hsl(207, 90%, 54%)',
        label: 'Blue'
    },
];
ClassicEditor.defaultConfig = {
    toolbar: {
        items: [
            'alignment',
            'fontSize',
            'fontFamily',
            'bold',
            'italic',
            "underline",
            "removeFormat",
            "strikethrough",
            'indent',
            'outdent',
            'insertTable',
            'undo',
            'redo',
            'tableproperties',
        ]
    },
    fontSize: {
        options: [
            10,
            12,
            14,
            'default',
            16,
            18,
            20,
            22,
            24,
            26,
            28,
            30,
            32,
            72
        ]
    },
    fontFamily: {
        options: [
            'default',
            'Mnabr1'
        ]
    },
    image: {
        toolbar: [
            'imageStyle:full',
            'imageStyle:side',
            'imageTextAlternative'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties',
            'tableCellProperties'
        ],
        tableProperties: {
            borderColors: customColorPalette,
            backgroundColors: customColorPalette
        },
        tableCellProperties: {
            borderColors: customColorPalette,
            backgroundColors: customColorPalette
        }
    },
    language: 'en'
};