import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sub-contractors',
  templateUrl: './sub-contractors.component.html',
  styleUrls: ['./sub-contractors.component.scss']
})
export class SubContractorsComponent implements OnInit {
  modalRef: BsModalRef;
  modalRefU: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  openModalU(template: TemplateRef<any>) {
    this.modalRefU = this.modalService.show(template, {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'col-md-8 col-sm-12 col-xs-12'
    });
  }
  @Input() supervision_data
  @Input() able2add
  @Input() able2edit
  @Input() project_data
  able2delete = JSON.parse(this.ls.getItem('is_admin'));
  contractortype = "SUB";
  pageno = 1;
  pagesize = 10;
  subData:any = [];
  searchKey= "";
  nodata = this.lang.transform('lang_loading');
  form:FormGroup;
  contractors:any = [];
  datastatus:boolean = false;
  contractordata:any = [];
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.form = new FormGroup({
      sub_contractors : new FormControl(null, [Validators.required])
    });
    this.get_contractors();
    this.load_sub_contractors_lists();
  }
  public get_contractors(permit = 'projects_add') {
    this.contractors = [];
    this.form.get('sub_contractors').setValue(null);
    this.spinner.show();
    this.ds.getActionByUrl([], 'Zxohs3VKKY/'+this.supervision_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id+'/'+permit).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.contractors = res.records;
      }
    },error=> {
      this.spinner.hide();
    });
  }
  search_sub_contractors() {
    this.pageno = 1;
    this.pagesize = 10;
    this.load_sub_contractors_lists();
  }
  page_size(size) {
    this.pageno = 1;
    this.pagesize = size;
    this.load_sub_contractors_lists();
  }
  page_load(no) {
    this.pageno = no;
    this.pagesize = this.pagesize;
    this.load_sub_contractors_lists();
  }
  load_sub_contractors_lists() {
    let formdata = new FormData();
    formdata.append('search_key', this.searchKey);
    this.ds.getActionByUrl(this.ds.formData2string(formdata), 'KJs7XjbrRO/'+this.project_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id+'/'+ this.pageno + '/' + this.pagesize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.subData = res;
      } else {
        this.subData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.subData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public empty_form(){
    this.form.reset();
    this.form.get('sub_contractors').setValue(null);
  }
  public submit() {
    this.spinner.show();
    let param = new FormData();
    param.append('projects_profile_id', this.supervision_data.projects_profile_id);
    param.append('projects_supervision_id', this.supervision_data.projects_supervision_id);
    if(this.form.get('sub_contractors').value) {
      this.form.get('sub_contractors').value.forEach((v, k) => {
        param.set("sub_contractors[" + k + "]", v);
      });
    }
    this.ds.postActionByUrl(param, 'dXaFBI0660').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.form.reset();
        this.load_sub_contractors_lists();
        this.alert.success(res.msg);
        setTimeout(() => {
          this.modalRef.hide();
        }, 1000);
      } else {
        this.alert.error(res.error);
      }
    },error=> {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    });
  }
  public viewcontractors(contractor) {
    this.contractordata = contractor;
    this.datastatus = true;
  }
  public remove_contractor(contractor_id) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show();
        this.ds.deleteActionByUrl([], 'LOqY0Cfchs/' + this.supervision_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id+'/'+contractor_id).subscribe(
          (data) => {
            this.spinner.hide();
            if (data.status) {
              this.load_sub_contractors_lists();
              this.ds.dialogf('',data.msg);
            } else {
              this.ds.dialogf('',data.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
          }
        );
      }
    });
  }
}
