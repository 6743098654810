import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
@Component({
  selector: 'app-qrcodepdf',
  templateUrl: './qrcodepdf.component.html',
  styleUrls: ['./qrcodepdf.component.scss'],
  animations:[
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [ style({ opacity: 0 }), animate(600) ]),
      transition(':leave', animate(0, style({ opacity: 0 })))
    ])
  ]
})
export class QrcodepdfComponent implements OnInit {
  public href: string = "";
  form: FormGroup;
  barcode = this.route.snapshot.paramMap.get('barcode');
  code = this.route.snapshot.paramMap.get('code');
  loggedin = JSON.parse(localStorage.getItem('loggedin'));
  lang_key = localStorage.getItem("lang_key") || "en";
  constructor(public ds: DataService, public router: Router, public route: ActivatedRoute, public alert:AlertService,public fb: FormBuilder, public spinner:PreloaderService, public lang:LanguagePipe) {
    this.buildform();
    if(this.barcode && this.code) {
      this.submit();
    }
  }
  ngOnInit(): void {
    
  }
  buildform() {
    this.form = new FormGroup({
      barcode: new FormControl(this.barcode, [Validators.required]),
      code: new FormControl(this.code, [Validators.required]),
    })
  }
  submit() {
    this.ds.postActionByNoToken(this.ds.json2formdata(this.form.value), 'tc/q').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.ds.getActionByFullUrl([], res.url).subscribe(data => {
          if(data.status) {
            this.ds.fileview(data);
          } else {
            this.ds.dialogf('', data.error);
          }
        }, error => {
          this.ds.dialogf('',this.lang.transform('lang_login_must'));
        });
      } else {
        this.ds.dialogf('', res.error);
      }
    }, error => {
      this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      this.spinner.hide();
    });
  }
}
