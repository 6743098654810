import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Step1Component } from '../steps/step1/step1.component';

@Component({
  selector: 'app-eedit',
  templateUrl: './eedit.component.html',
  styleUrls: ['./eedit.component.scss']
})
export class EeditComponent implements OnInit {
  @ViewChild(Step1Component) step1 : Step1Component
  public createHRForm: FormGroup;
  editstatus: boolean = false;
  errorstatus: boolean = false;
  errormsg: string = '';
  licencesList: any;
  action:string = "EDIT";
  id = this.route.snapshot.paramMap.get('id');
  branch_id = this.route.snapshot.paramMap.get('bid');
  key = this.route.snapshot.paramMap.get('key');
  licencesstatus: any;
  listoflicences: any;
  constructor(public router:Router, public ds:DataService,public route :ActivatedRoute, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.buildHrForm();
    this.getemployeedetails();
  }
  buildHrForm() {
    this.createHRForm = new FormGroup({
      idDetails: new FormGroup({
        employee_id_number_old: new FormControl('', [Validators.required]),
        employee_id_number: new FormControl('', [Validators.required]),
        branch_id: new FormControl('', [Validators.required]),
        firstname_english: new FormControl('', [Validators.required]),
        secondname_english: new FormControl('', [Validators.required]),
        thirdname_english: new FormControl(''),
        lastname_english: new FormControl('', [Validators.required]),
        firstname_arabic: new FormControl('', [Validators.required]),
        secondname_arabic: new FormControl('', [Validators.required]),
        thirdname_arabic: new FormControl(''),
        lastname_arabic: new FormControl('', [Validators.required]),
        iqama_expiry_date_english: new FormControl('', [Validators.required]),
        iqama_expiry_date_arabic: new FormControl(null, [Validators.required]),
        copy_number: new FormControl('', [Validators.required]),
        birth_date_english: new FormControl('', [Validators.required,]),
        birth_date_arabic: new FormControl(null, [Validators.required,]),
        gender: new FormControl('', [Validators.required]),
        nationality: new FormControl('', [Validators.required]),
        profile_image: new FormControl(''),
        job_title_iqama: new FormControl('', [Validators.required]),
        marital_status: new FormControl('', [Validators.required]),
        religion: new FormControl('', [Validators.required]),
        work_domain: new FormControl('', [Validators.required]),
        work_location: new FormControl('', [Validators.required]),
        work_type: new FormControl('', [Validators.required]),
        employee_title: new FormControl(''),
      }),
      contactDetails: new FormGroup({
        primary_mobile: new FormControl('', [Validators.required]),
        primary_email: new FormControl('', [Validators.required, Validators.email]),
        primary_address: new FormControl('', [Validators.required]),
      }),
      bankDetails: new FormGroup({
        account_number: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(35)]),
        bank_short_code: new FormControl('', [Validators.required]),
      }),
      educationDetails: new FormGroup({
        primary_graduation_year: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
        primary_education_level: new FormControl('', [Validators.required]),
        membership_number: new FormControl(''),
        membership_expiry_date_english: new FormControl(''),
        membership_expiry_date_arabic: new FormControl(null),
      }),
      passportDetails: new FormGroup({
        passport_number: new FormControl(''),
        passport_issue_date_english: new FormControl(''),
        passport_issue_date_arabic: new FormControl(null),
        passport_expiry_date_english: new FormControl(''),
        passport_expiry_date_arabic: new FormControl(null),
        passport_issue_place: new FormControl(''),
      }),
      insuranceDetails: new FormGroup({
        insurance_number: new FormControl(''),
        insurance_date: new FormControl(''),
        insurance_type_class: new FormControl(''),
      }),
    });
  }
  getemployeedetails() {
    this.ds.getActionByUrl([], 'human_resources/get_employee_details/'+this.id+'/'+this.branch_id).subscribe(data => {
      this.spinner.hide();
      if(data.status){
        this.editstatus = true;
          this.buildHrFormValue(data.data);
        if (data.data.profile_image) {
          setTimeout(() => {
            this.step1.get_profile_image(data.data.profile_image);
          }, 500);
        }
      } else{
        this.editstatus = false;
        this.ds.dialogf('',data.error);
      }
    }, error => {
        this.spinner.hide();
        this.editstatus = false;
        this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  buildHrFormValue(d) {
      this.createHRForm.patchValue({
        idDetails : {
          employee_id_number_old: (d) ? d.employee_id_number : '',
          employee_id_number: (d) ? d.employee_id_number : '',
          branch_id: (d) ? d.branch_id : '',
          firstname_english: (d) ? d.firstname_english : '',
          secondname_english: (d) ? d.secondname_english : '',
          thirdname_english: (d) ? d.thirdname_english : '',
          lastname_english: (d) ? d.lastname_english : '',
          firstname_arabic: (d) ? d.firstname_arabic : '',
          secondname_arabic: (d) ? d.secondname_arabic : '',
          thirdname_arabic: (d) ? d.thirdname_arabic : '',
          lastname_arabic: (d) ? d.lastname_arabic : '',
          iqama_expiry_date_english: (d) ? new Date(d.iqama_expiry_date_english) : '',
          iqama_expiry_date_arabic: (d) ? this.ds.arcon(d.iqama_expiry_date_arabic) : null,
          copy_number: (d) ? d.copy_number : '',
          birth_date_english: (d) ? new Date(d.birth_date_english) : '',
          birth_date_arabic: (d) ? this.ds.arcon(d.birth_date_arabic) : null,
          gender: (d) ? d.gender : '',
          nationality: (d) ? d.nationality : '',
          job_title_iqama: (d) ? d.job_title_iqama : '',
          marital_status: (d) ? d.marital_status : '',
          religion: (d) ? d.religion : '',
          work_domain: (d) ? d.work_domain : '',
          work_location: (d) ? d.work_location : '',
          work_type: (d) ? d.work_type : '',
          employee_title: (d) ? d.employee_title.toString() : '',
        },
        jobDetails : {
          job_title_id: (d) ? d.job_title_id : '',
        },
        contactDetails : {
          primary_mobile: (d) ? d.primary_mobile : '',
          primary_email: (d) ? d.primary_email : '',
          primary_address: (d) ? d.primary_address : '',
        },
        bankDetails : {
          account_number: (d) ? d.account_number : '',
          bank_short_code: (d) ? d.bank_short_code : '',
        },
        educationDetails : {
          primary_graduation_year: (d) ? d.primary_graduation_year : '',
          primary_education_level: (d) ? d.primary_education_level : '',
          membership_number: (d) ? d.membership_number : '',
          membership_expiry_date_english: (d) ? this.ds.encon(d.membership_expiry_date_english) : '',
          membership_expiry_date_arabic: (d) ? this.ds.arcon(d.membership_expiry_date_arabic) : null,
        },
        passportDetails: {
          passport_number: (d) ? d.passport_number : '',
          passport_issue_date_english: (d) ? this.ds.encon(d.passport_issue_date_english) : '',
          passport_issue_date_arabic: (d) ? this.ds.arcon(d.passport_issue_date_arabic) : null,
          passport_expiry_date_english: (d) ? this.ds.encon(d.passport_expiry_date_english) : '',
          passport_expiry_date_arabic: (d) ? this.ds.arcon(d.passport_expiry_date_arabic) : null,
          passport_issue_place: (d) ? d.passport_issue_place : '',
        },
        insuranceDetails : {
          insurance_number: (d) ? d.insurance_number : '',
          insurance_date: (d) ? this.ds.encon(d.insurance_date) : '',
          insurance_type_class: (d) ? d.insurance_type_class : '',
        }
      });
  }
  updateEmployee() {
    let wholeData:any = {};
    let id = this.createHRForm.get('idDetails').value;
    Object.assign(wholeData, id);
    let contact = this.createHRForm.get('contactDetails').value;
    Object.assign(wholeData, contact);
    let bank = this.createHRForm.get('bankDetails').value;
    Object.assign(wholeData, bank);
    let edu = this.createHRForm.get('educationDetails').value;
    Object.assign(wholeData, edu);
    let passport = this.createHRForm.get('passportDetails').value;
    Object.assign(wholeData, passport);
    let ins = this.createHRForm.get('insuranceDetails').value;
    Object.assign(wholeData, ins);
    this.spinner.show();
    this.ds.postActionByUrl(this.ds.json2formdata(wholeData), "human_resources/update_employee_details/" + this.id).subscribe(data => {
      this.spinner.hide();
      this.errorstatus = data.status;
      if (data.status) {
        this.alert.success(data.msg);
        setTimeout(() => {
          this.router.navigate(['human_resources/view/' + this.id + '/view/' + this.branch_id]);
        }, 2000);
      } else {
        this.alert.error(data.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
}
