<section class="panel-expansion">
    <mat-accordion class="appfiletypes" >
        <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="'true'">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_module_files_types' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15 martb0">
                    <div class="row">
                        <div class="col-md-3 colxs-12 mb-10">
                            <select class="form-control almnabrformcontrol" (change)="spinner.show();get_file_types();" [(ngModel)]="module_key">
                                <option value="">{{'lang_all' | language}}</option>
                                <option *ngFor="let module of modules" value="{{module.module_key}}">{{module.module_phrase_key | language}}</option>
                            </select>
                        </div>
                        <div class="col-md-9 colxs-12 mb-10">
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control almnabrformcontrol mb-10" (keyup)="get_file_types();" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                                <span class="input-group-btn">
                                    <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" (click)="openModalA(fileTypeAction);form.reset();form.get('module_name').setValue('');form.get('storage_type').setValue('0');" type="button" matTooltip="{{'lang_add' | language }}"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsFType checkbox" id="allItemsFType" (click)="checkallItemsFType($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_module_name' | language }}</td>
                                            <td>{{'lang_title_english' | language }}</td>
                                            <td>{{'lang_title_arabic' | language }}</td>
                                            <td>{{'lang_unique_key' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="wholeData.length != '0' " class="AppendList">
                                        <tr *ngFor="let row of tabledata; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{row.attach_type_id}}">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemFType checkbox" value="{{row.attach_type_id}}" (click)="checkeachItemFType($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_'+row.module_name | language}}</td>
                                            <td>{{row.title_english}}</td>
                                            <td>{{row.title_arabic}}</td>
                                            <td>{{row.key_code}}</td>
                                            <td>
                                                <i class="fa fa-pencil-square-o s2icon fa-icon mnabricon" matTooltip="{{'lang_edit' | language }}" (click)="openModalA(fileTypeAction);edit_file_type_data(row);"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="wholeData.length == '0' " class="AppendList">
                                        <tr class="odd">
                                            <td colspan="7" align="center">{{nodata}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="wholeData.length != '0' " class="card-footer padb0">
                    <div class="row">
                        <div *ngIf="admin" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataFType();" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-6 mb-10">
                            {{'lang_total' | language }} : {{collectionSize}}
                        </div>
                        <div class="col-lg-5 col-sm-6 mb-10">
                            <ngb-pagination [collectionSize]="collectionSize" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [(page)]="page" [pageSize]="pageSize" (pageChange)="build_table_data()"></ngb-pagination>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <select class="customselect" [(ngModel)]="pageSize" (change)="build_table_data()">
                                <option [ngValue]="10">10</option>
                                <option [ngValue]="20">20</option>
                                <option [ngValue]="50">50</option>
                                <option [ngValue]="100">100</option>
                                <option [ngValue]="250">250</option>
                                <option [ngValue]="500">500</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
<ng-template #fileTypeAction>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_module_files_types' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefA.hide();">&times;</button>
        </div>
        <form [formGroup]="form" (ngSubmit)="submit();">
            <div class="modal-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="module_name">{{'lang_module_name' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <select type="text" class="form-control almnabrformcontrol" formControlName="module_name" matTooltip="{{'lang_module_name' | language }}">
                                <option value="">{{'lang_choose_options' | language}}</option>
                                <option *ngFor="let module of modules" value="{{module.module_key}}">{{module.module_phrase_key | language}}</option>
                            </select>
                            <mat-error *ngIf="form.controls['module_name'].touched && form.controls['module_name'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="key_code">{{'lang_unique_key' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" class="form-control almnabrformcontrol" formControlName="key_code" placeholder="{{'lang_unique_key' | language }}"/>
                            <mat-error *ngIf="form.controls['key_code'].touched && form.controls['key_code'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="title_english">{{'lang_title_english' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" class="form-control almnabrformcontrol" formControlName="title_english" placeholder="{{'lang_title_english' | language }}"/>
                            <mat-error *ngIf="form.controls['title_english'].touched && form.controls['title_english'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="title_arabic">{{'lang_title_arabic' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" class="form-control almnabrformcontrol" formControlName="title_arabic" placeholder="{{'lang_title_arabic' | language }}"/>
                            <mat-error *ngIf="form.controls['title_arabic'].touched && form.controls['title_arabic'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefA.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>