import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-supervision-lists',
  templateUrl: './supervision-lists.component.html',
  styleUrls: ['./supervision-lists.component.scss']
})
export class SupervisionListsComponent implements OnInit {
  modalRefV: BsModalRef;
  @Input() project_data;
  @Input() service_data;
  @Input() filelevels;
  @Input() able2add;
  @Input() able2edit;
  @Input() able2delete;
  @Input() isQuotationExists;
  nodata: string      = this.lang.transform('lang_loading');
  viewdata:any        = [];
  listData:any        = [];
  pageno: any         = localStorage.getItem('M_P_S_P')   || 1;
  pagesize: any       = localStorage.getItem('M_P_S_S')   || 10;
  searchKey           = localStorage.getItem('M_P_S_S_K') || "";
  constructor(public ds: DataService,public router:Router,public route :ActivatedRoute,public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
  ngOnInit(): void {
    this.load_supervision_lists();
  }
  public search_supervision_lists() {
    this.pageno = 1;
    this.pagesize = 10;
    this.load_supervision_lists();
  }
  public load_supervision_lists_page(page) {
    this.spinner.show();
    this.pageno = page;
    this.pagesize = this.pagesize;
    this.load_supervision_lists();
  }
  public load_supervision_lists_pagesize(size) {
    this.spinner.show();
    this.pageno = 1;
    this.pagesize = size;
    this.load_supervision_lists();
  }
  load_supervision_lists() {
    localStorage.setItem('M_P_S_S_K', this.searchKey);
		localStorage.setItem('M_P_S_P', this.pageno);
		localStorage.setItem('M_P_S_S', this.pagesize);
    let formdata = new FormData();
    formdata.append('search_key', this.searchKey || "");
    this.ds.getActionByUrl(this.ds.formData2string(formdata), 'xZLCctvSvZ9DGb8/'+this.project_data.projects_profile_id+'/'+ this.pageno + '/' + this.pagesize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.listData = res;
      } else {
        this.listData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.listData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public openViewModal(template: TemplateRef<any>, data) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
    this.viewdata = data;
  }
}
