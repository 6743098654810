import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-journal-add',
  templateUrl: './journal-add.component.html',
  styleUrls: ['./journal-add.component.scss']
})
export class JournalAddComponent implements OnInit {
  form: FormGroup;
  loading = false;
  branches: any = [];
  accountData: any = [];
  costData: any = [];
  myDate: any = new Date();
  records: FormArray;
  constructor(public ds:DataService,public route : ActivatedRoute, public datePipe: DatePipe,public router : Router, public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.myDate = new Date(this.datePipe.transform(this.myDate, 'yyyy/MM/dd'));
    this.spinner.show();
    this.load_branches();
    this.buildform();
  }
  ngAfterViewInit() {
    for (let index = 0; index < 4 ; index++) {
      this.records.push(this.journal_records());
    }
  }
  public load_branches() {
    this.branches = [];
    this.ds.getActionByUrl([], 'ab/accounts_add').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.branches = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl('', [Validators.required]),
      journal_voucher_date: new FormControl(this.myDate, [Validators.required]),
      journal_voucher_debit_total: new FormControl('', [Validators.required]),
      journal_voucher_credit_total: new FormControl('', [Validators.required]),
      journal_records: this.fb.array([]),
    });
    this.records = this.form.get("journal_records") as FormArray;
  }
  public journal_records_group() {
    return this.form.get("journal_records") as FormArray;
  }
  get validate() {
    return this.form.get("journal_records") as FormArray;
  }
  public journal_records(): FormGroup {
    return this.fb.group({
      account_masters_id: new FormControl(null, [Validators.required]),
      debit_amount: new FormControl(''),
      credit_amount: new FormControl(''),
      transaction_history_description: new FormControl("", [Validators.required]),
      transaction_history_ref_number: new FormControl(""),
      cost_center_id: new FormControl(null),
      transaction_history_notes: new FormControl(""),
    });
  }
  public add_journal_records() {
    if (this.records.length < 25) {
      this.records.push(this.journal_records());
    }
  }
  public removeJournal(index) {
    if (this.records.length > 2) {
      this.records.removeAt(index);
    }
  }
  public calculateTotalDebitCredit() {
    let dtotal: any = 0.00;
    let ctotal: any = 0.00;
    this.validate.value.forEach(x => {
      dtotal += +x.debit_amount;
      ctotal += +x.credit_amount;
    });
    this.form.get('journal_voucher_debit_total').setValue(parseFloat(dtotal).toFixed(2));
    this.form.get('journal_voucher_credit_total').setValue(parseFloat(ctotal).toFixed(2));
  }
  public create_journal() {
    this.spinner.show();
    let param = new FormData();
    param.set('branch_id', this.form.get('branch_id').value);
    param.set('journal_voucher_date', this.form.get('journal_voucher_date').value  || this.myDate);
    param.set('journal_voucher_debit_total', this.form.get('journal_voucher_debit_total').value);
    param.set('journal_voucher_credit_total', this.form.get('journal_voucher_credit_total').value);
    this.validate.value.forEach((v, k) => {
      param.set("journal_records[" + k + "][account_masters_id]", (this.validate.controls[k].get('account_masters_id').value) ? this.validate.controls[k].get('account_masters_id').value : '0');
      param.set("journal_records[" + k + "][cost_center_id]", (this.validate.controls[k].get('cost_center_id').value) ? this.validate.controls[k].get('cost_center_id').value : '0');
      param.set("journal_records[" + k + "][credit_amount]", this.validate.controls[k].get('credit_amount').value);
      param.set("journal_records[" + k + "][debit_amount]", this.validate.controls[k].get('debit_amount').value);
      param.set("journal_records[" + k + "][transaction_history_description]", this.validate.controls[k].get('transaction_history_description').value);
      param.set("journal_records[" + k + "][transaction_history_notes]", this.validate.controls[k].get('transaction_history_notes').value);
      param.set("journal_records[" + k + "][transaction_history_ref_number]", this.validate.controls[k].get('transaction_history_ref_number').value);     
    });
    this.ds.postActionByUrl(param, 'createjournal').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.form.reset();
        this.alert.success(res.msg);
        setTimeout(() => {
          this.router.navigate(['accounts/jview/' + res.branch_id + '/' + res.journal_voucher_id + '/view']); 
        });
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    });
  }
  public searchAccounts(key, control) {
    this.accountData = [];
    let param = new FormData();
    param.append('branch_id', this.form.get('branch_id').value);
    param.append('search_text', key.term);
    if (this.form.get('branch_id').value && key.term) {
      this.ds.postActionByUrl(param, "sam/accounts_add").subscribe(data => {
        if (data.status) {
          this.accountData = data.records;
        }
      });
    }
    this.calculate_equal_debit_credit(control);
  }
  public searchCosts(key, account_master) {
    this.costData = [];
    let param = new FormData();
    param.append('branch_id', this.form.get('branch_id').value);
    param.append('account_id', (account_master) ? account_master : '0');
    param.append('search_text', key.term);
    if (this.form.get('branch_id').value && key.term) {
      this.ds.postActionByUrl(param, 'cctransactions').subscribe(res => {
        if (res.status) {
          this.costData = res.records;
        }
      });
    }
  }
  private calculate_equal_debit_credit(control) {
    let debit_total: any = 0.00;
    let credit_total: any = 0.00;
    this.validate.value.forEach(x => {
      debit_total += +x.debit_amount;
      credit_total += +x.credit_amount;
    });
    if (credit_total !== debit_total) {
      let remaining_amount: any = credit_total - debit_total;
      let type = "";
      if (remaining_amount < 0) {
        type = "CRD"; //credit amount
      } else {
        type = "DBT"; //debit amount
      }
      let debit_field_value = control.get('debit_amount').value;
      let credit_field_value = control.get('credit_amount').value;
      if ((debit_field_value && parseInt(debit_field_value) > 0) || (credit_field_value && parseInt(credit_field_value) > 0)) {
      } else {
        let value = isNaN(remaining_amount) ? '' : Math.abs(parseFloat(remaining_amount)).toFixed(2);
        if (type == "CRD") {
          control.get('credit_amount').setValue(value);
        } else if (type == "DBT") {
          control.get('debit_amount').setValue(value);
        } else {
          // upcoming calculations
        }
      }
    }
    this.calculateTotalDebitCredit();
  }
}
