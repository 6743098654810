<div class="card">
	<div class="card-header">
		{{ "lang_finantial_details" | language }}
	</div>
<!-- 	<div class="card-body">
		<div class="martb0">
			<div class="row">
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="mb-10">{{ "Last Joining Date " }}</div>
					<div class="mb-10">
						<b>{{ viewData?.contract_vacation_info?.joining_date }}</b>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="mb-10">{{ "Vaction Balance" }}</div>
					<div class="mb-10">
						<b>
							{{ viewData?.contract_vacation_info?.vacation_balance }} Days</b
						>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="mb-10">{{ "Vaction used days" }}</div>
					<div class="mb-10">
						<b>
							{{
								viewData?.contract_vacation_info?.used_vacation_total
							}}
							Days</b
						>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="mb-10">{{ "Vaction Unused days" }}</div>
					<div class="mb-10">
						<b>
							{{
								viewData?.contract_vacation_info?.vacation_balance -
									viewData?.contract_vacation_info?.used_vacation_total
							}}
							Days</b
						>
					</div>
				</div>
			</div>
		</div>
	</div> -->

	<div class="p-1">
		<form  class="form" [formGroup]="form" >
			<div class="row">
				<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
					<select formControlName="type" class="form-control almnabrformcontrol" matTooltip="{{'lang_type' | language }}" (change)="spinner.show();get_employee_modules();">
						<option disabled value="0">{{'lang_choose_options' | language }}</option>
						<option *ngFor="let type of typesOfFinance" value="{{type.id}}">{{type.label}}
						</option>
					</select>
				</div>
				<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
					<div class="input-group input-group-sm mb-10">
						<input
							name="account_search"
							id="filter"
							#filter
							formControlName="search_value" (keyup)="search_employee_modules();"
							class="enablecode h34 form-control almnabrformcontrol"
							type="text"
							autocomplete="off"
							placeholder="{{ 'lang_search' | language }}"
						/>
						<span class="input-group-btn">
							<button
								
								id="btnSearch"
								type="button"
								matTooltip="{{ 'lang_add' | language }}"
								class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-plus blue-tooltip mnabricon"
								[matMenuTriggerFor]="menu"
							></button>
			 
							  <mat-menu #menu="matMenu">
								<button (click)="showForm.emit('LNCR')" mat-menu-item>
						 
								  <span>{{'lang_loan'|language}}</span>
								</button>
								<button (click)="showForm.emit('OTCR')" mat-menu-item>
					 
								  <span>{{'lang_overTime'|language}}</span>
								</button>
								<button (click)="showForm.emit('BNCE')" mat-menu-item>
						 
								  <span>{{'lang_bonus'|language}}</span>
								</button>
								<button (click)="showForm.emit('DECR')" mat-menu-item>
				 
									<span>{{'lang_deduction'|language}}</span>
								  </button>
			
								  <button (click)="showForm.emit('VOL1')" mat-menu-item>
					 
									<span>{{'lang_violation'|language}}</span>
								  </button>
							  </mat-menu>
			
						</span>
					</div>
				</div>

 
			</div>
		</form>

	</div>

	<div class="row p-1">
		<div class="col-md-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable" *ngIf="wholedata?.records?.length > '0'" >
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_transaction_type" | language }}</td>
							<td>{{ "lang_date" | language }}</td>
						
							<td>{{ "lang_amount" | language }}</td>
							<td>{{ "lang_status" | language }}</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody class="AppendList">
						<tr
							*ngFor="
								let item of wholedata?.records;
								let i = index;
								let odd = odd;
								let even = even
							"
							[ngClass]="{ odd: odd, even: even }"
						>
						     <td>{{ item.transaction_request_id }}</td> 
							<td>{{ 'lang_'+item.transaction_key|language }}</td>
							<td>{{ item.finincal_date_english }}</td>
							<td>{{ item.finincal_amount }}</td>
							<td>
								<span *ngIf="item.approved_status == 1">{{'lang_approved'|language}}</span>
								<span *ngIf="item.approved_status == 0">{{'lang_rejected'|language}}</span>
							</td>
							<td>
								<!-- <i
									class="fa fa-file-pdf-o mnabricon s2icon"
									style="margin: 0 10px; padding: 0"
									matTooltip="{{ 'lang_preview' | language }}"
								></i> -->
								<i
									class="fa fa-eye mnabricon"
									(click)="ds.PreviewData(item.file_path)"
									matTooltip="{{ 'lang_preview' | language }}"
								></i>
							</td>
						</tr>
					</tbody>
				 
				</table>
				<no-data style="margin: auto;"  [Opened]="wholedata?.records?.length == '0'"></no-data>
			</div>
		</div>
	</div>
</div>
<div *ngIf="wholedata?.records?.length != 0 " class="card-footer padb0 customborder">
	<div class="row">
	   <!--  <div *ngIf="able2delete && requestfor !== 'profile' " class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
			<i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataContr()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
		</div> -->
		<div class="col-lg-3 col-sm-6 col-6  mb-10">
			{{'lang_total' | language }} {{wholedata?.page?.total_records}}
		</div>
		<div class="col-lg-5 col-sm-6 col-12 mb-10">
			<mat-radio-group aria-label="Select an option">
				<mat-radio-button value="10" checked (click)="spinner.show();load_page_size(10)"> 10 </mat-radio-button>
				<mat-radio-button value="20" (click)="spinner.show();load_page_size(20)"> 20 </mat-radio-button>
				<mat-radio-button value="50" (click)="spinner.show();load_page_size(50)"> 50 </mat-radio-button>
				<mat-radio-button value="100" (click)="spinner.show();load_page_size(100)"> 100 </mat-radio-button>
				<mat-radio-button value="500" (click)="spinner.show();load_page_size(500)"> 500 </mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="col-lg-4 col-sm-6 col-12 mb-10">
			{{'lang_goto' | language }}
			<select class="customselect" (change)="spinner.show();page_length($event.target.value)">
				<option *ngFor="let k of ds.getrange(wholedata?.page?.total_pages)" value="{{k}}">{{k}}</option>
			</select>
		</div>
	</div>
</div> 
