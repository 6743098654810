import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sort-employee-card-mob',
  templateUrl: './sort-card-mob.component.html',
  styleUrls: ['./sort-card-mob.component.scss']
})
export class SortEmployeeCardMobComponent implements OnInit {

  sortForm:FormGroup = this.fb.group({
    form_request_id: null,
    template_id: null,
    platform_code_system:null,
    zone: null,
    block: null,
    cluster: null,
    barcode: null
  });;
  formFromLocalStorage:any;
  form_request_id_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ];
  template_id_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ];
  platform_code_system_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ];
  zone_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ]
  block_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ]
  cluster_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ]
  barcode_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ]
  constructor(public dialogRef: MatDialogRef<SortEmployeeCardMobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb:FormBuilder) { }

  ngOnInit(): void {
    this.getSortParams();
    this.buildForm();
  }

  buildForm() {
    this.sortForm = this.fb.group({
      form_request_id: this.formFromLocalStorage?.form_request_id,
      template_id: this.formFromLocalStorage?.template_id,
      platform_code_system:this.formFromLocalStorage?.platform_code_system,
      zone: this.formFromLocalStorage?.zone,
      block: this.formFromLocalStorage?.block,
      cluster: this.formFromLocalStorage?.cluster,
      barcode: this.formFromLocalStorage?.barcode
    });
  }

  getSortParams() {
    this.formFromLocalStorage = localStorage.getItem('sortParams') ? JSON.parse(localStorage.getItem('sortParams')): localStorage.setItem('sortParams',  JSON.stringify(this.sortForm?.value))
  }

  sendSortForm() {
    this.dialogRef.close(this.sortForm?.value);
    localStorage.setItem('sortParams', JSON.stringify(this.sortForm?.value));
  }
  resetSortForm() {
    this.sortForm.reset();
    this.dialogRef.close(this.sortForm?.value);
    localStorage.setItem('sortParams', JSON.stringify(this.sortForm?.value));
  }

}
