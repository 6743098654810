<div class="card appcontracts">                                          
    <div class="card-header">{{'lang_human_resources_contract_details' | language }} </div>
    <div class="card-body p-0">
      <div class="mbrl15 martb0">
          <div class="row">
              <div class="input-group input-group-sm mb-10 col-12">
                  <input type="text" class="form-control almnabrformcontrol" (keyup)="search_employee_contracts();" [(ngModel)]="csearchKey" placeholder="{{'lang_search' | language }}">
                  <span class="input-group-btn" *ngIf="requestfor !== 'profile' && user_data.employee_status == '1'  && able2add ">
                      <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" id="btnSearch" type="button" (click)="showContract.emit('CTRCR');" matTooltip="{{'lang_new_contract' | language }}"></button>
                  </span>
              </div>
          </div>
          <div class="row">
              <div class="table-responsive dropdownyes customResponsive col-12">
                  <table border="1" class="table CustomTable"  *ngIf="wholedata.length != 0 ">
                      <thead>
                          <tr>
                              <td>#</td>
                              <td *ngIf="requestfor !== 'profile' ">
                                  <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                      <input type="checkbox" class="allItemsContr checkbox" id="allItemsContr" (click)="checkAllContr($event)"/>
                                      <label for="checkbox"></label>
                                  </div>
                              </td>
                              <td>{{'lang_subject' | language }}</td>
                              <td>{{'lang_contract_date' | language }}</td>
                              <td>{{'lang_work_domain' | language }}</td>
                              <td>{{'lang_work_location' | language }}</td>
                              <td>{{'lang_approval_date' | language }}</td>
                              <td>{{'lang_status' | language }}</td>
                              <td>{{'lang_writer' | language }}</td>
                              <td>{{'lang_action' | language }}</td>
                          </tr>
                      </thead>
                      <tbody class="AppendList">
                          <tr *ngFor="let con of wholedata.records; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{con.contract_id}}">
                              <td>{{i+1}}</td>
                              <td *ngIf="requestfor !== 'profile' ">
                                  <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                      <input type="checkbox"  class="eachItemContr checkbox" value="{{con.contract_id}}" (click)="checkEachItemContr($event)"/>
                                      <label for="checkbox"></label>
                                  </div>
                              </td>
                              <td>{{con.subject}}</td>
                              <td>{{con.contract_start_date_english}} - {{con.contract_start_date_arabic}}</td>
                              <td>{{con.work_domain}}</td>
                              <td>{{con.work_location}}</td>
                              <td>{{con.contract_approved_date_english}} - {{con.contract_approved_date_arabic}}</td>
                                <td *ngIf="con.contract_status =='1' " matTooltip="{{'lang_active' | language}}">
                                    <i class="fa fa-circle fa-icon green"></i>
                                </td>
                                <td *ngIf="con.contract_status =='2' " matTooltip="{{'lang_completed' | language}}">
                                    <i class="fa fa-circle fa-icon blue"></i>
                                </td>
                                <td *ngIf="con.contract_status =='3' " matTooltip="{{'lang_rejected' | language}}">
                                    <i class="fa fa-circle fa-icon red"></i>
                                </td>
                              <td >{{con.writer}}</td>
                              <td>
                                  <i matTooltip="{{'lang_view' | language }}" class="fa fa-eye fa-icon mnabricon s2icon" (click)="spinner.show();loadContract(con, viewContracts)"></i>
                                  <i *ngIf="con.contract_attachment" class="fa fa-paperclip fa-icon mnabricon s2icon" (click)="ds.previewData(con.contract_attachment);"></i>
                              </td>
                          </tr>
                      </tbody>
 
                  </table>
                  <no-data style="margin: auto;"  [Opened]="wholedata.length < 1 "></no-data>

              </div>
          </div>
      </div>
      <div *ngIf="wholedata.length != 0 " class="card-footer padb0 customborder">
          <div class="row">
              <div *ngIf="able2delete && requestfor !== 'profile' " class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                  <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataContr()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
              </div>
              <div class="col-lg-2 col-sm-6 col-6  mb-10">
                  {{'lang_total' | language }} {{wholedata.page.total_records}}
              </div>
              <div class="col-lg-5 col-sm-6 col-12 mb-10">
                  <mat-radio-group aria-label="Select an option">
                      <mat-radio-button value="10" checked (click)="spinner.show();load_page_size(10)"> 10 </mat-radio-button>
                      <mat-radio-button value="20" (click)="spinner.show();load_page_size(20)"> 20 </mat-radio-button>
                      <mat-radio-button value="50" (click)="spinner.show();load_page_size(50)"> 50 </mat-radio-button>
                      <mat-radio-button value="100" (click)="spinner.show();load_page_size(100)"> 100 </mat-radio-button>
                      <mat-radio-button value="500" (click)="spinner.show();load_page_size(500)"> 500 </mat-radio-button>
                  </mat-radio-group>
              </div>
              <div class="col-lg-4 col-sm-6 col-12 mb-10">
                  {{'lang_goto' | language }}
                  <select class="customselect" (change)="spinner.show();page_length($event.target.value)">
                      <option *ngFor="let k of ds.getrange(wholedata.page.total_pages)" value="{{k}}">{{k}}</option>
                  </select>
              </div>
          </div>
      </div>
    </div>
</div>
<ng-template #viewContracts>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_human_resources_contract_details' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefV.hide();">&times;</button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_subject' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].subject}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_work_domain' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].work_domain}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_work_location' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].work_location}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_work_type' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].work_location}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_joining_date' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].joining_date_english}} -  {{viewdata.records[0].joining_date_arabic}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_propation_period' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].probation_period}} {{'lang_months' | language}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_probation_end_date' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].probation_expiry_date_english}} -  {{viewdata.records[0].probation_expiry_date_arabic}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_contract_start_date' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].contract_start_date_english}} -  {{viewdata.records[0].contract_start_date_arabic}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_contract_period' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].contract_period}} {{'lang_years' | language}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_contract_end_date' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].contract_end_date_english}} -  {{viewdata.records[0].contract_end_date_arabic}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_vacation_paid_days' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].vacation_paid_days}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_custom_vacation_date' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].upcoming_vacation_date_english}} -  {{viewdata.records[0].upcoming_vacation_date_arabic}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_custom_vacation_end_date' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].upcoming_vacation_end_date_english}} - {{viewdata.records[0].upcoming_vacation_end_date_arabic}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_working_days_per_week' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].working_days_per_week}} {{'lang_days' | language}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_working_hours' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{viewdata.records[0].working_hours_per_day}} {{'lang_hours' | language}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_basic_salary' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{ds.addCommas(viewdata.records[0].basic_salary)}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_home_allowance' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{ds.addCommas(viewdata.records[0].home_allowance)}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_net_amount' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall"> : {{ds.addCommas(viewdata.records[0].net_amount)}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_status' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngIf="viewdata.records[0].contract_status == '1' "> : <i class="fa fa-circle fa-icon green"></i> {{'lang_active' | language}}</b>
                        <b class="breakall" *ngIf="viewdata.records[0].contract_status == '2' "> : <i class="fa fa-circle fa-icon blue"></i> {{'lang_completed' | language}}</b>
                        <b class="breakall" *ngIf="viewdata.records[0].contract_status == '3' "> : <i class="fa fa-circle fa-icon red"></i> {{'lang_rejected' | language}}</b>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_writer' | language }}</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">  : {{viewdata.records[0].writer}}</b>
                    </div>
                </div>
                <div class="row" *ngIf="viewdata && viewdata.allowances.length > 0">
                    <p>{{'lang_other_allowances' | language }}</p>
                    <div class="table-responsive customResponsive tablefields">
                        <table border="1" class="table CustomTable" width="100%">
                            <thead>
                                <tr>
                                    <td style="padding: 5px;word-break: break-all;"> # </td>
                                    <td style="padding: 5px;word-break: break-all;"> {{'lang_desc_english' | language}} </td>
                                    <td style="padding: 5px;word-break: break-all;"> {{'lang_invoice_item_amount' | language}} </td>
                                    <td style="padding: 5px;word-break: break-all;"> {{'lang_desc_arabic' | language}} </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let otr of viewdata.allowances; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" style="background: #ffffff;">
                                    <td width="1%" class="verticalalignmid">{{i+1}}</td>
                                    <td style="padding: 5px;word-break: break-all;" style="position: relative;">{{otr.other_allowance_description_en}}</td>
                                    <td style="padding: 5px;word-break: break-all;" style="position: relative;">{{ds.addCommas(otr.other_allowance_amount)}}</td>
                                    <td style="padding: 5px;word-break: break-all;" style="position: relative;">{{otr.other_allowance_description_ar}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal"  (click)="modalRefV.hide();">{{'lang_close' | language }}</button>
        </div>
    </div>
</ng-template>