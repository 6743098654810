<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<div class="actions">
            <div style="font-size: 12px; width: 150px;">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>{{"lang_search" | language}}</mat-label>
                    <input matInput (keyup)="search()" [(ngModel)]="stringToSearch">
                </mat-form-field>
            </div>
            <mat-slider min="0.1" max="4" step="0.4" [(value)]="zoom" style="margin: 0 5px;" title="zoom"></mat-slider>
            <i class="fa fa-undo mnabricon" (click)="rotationPdf()" style="margin: 0 5px;" title="rotate"></i>
            <i class="fa fa-download mnabricon" (click)="downloadPdf()" style="margin: 0 5px;" title="download"></i>
            <i class="fa fa-compress mnabricon" (click)="fitPageVal = 'page-fit'" style="margin: 0 5px;" *ngIf="fitPageVal == 'page-width'" title="page-fit"></i>
            <i class="fa fa-expand mnabricon" (click)="fitPageVal = 'page-width'" style="margin: 0 5px;" *ngIf="fitPageVal == 'page-fit'" title="page-width"></i>

        </div>
		<span (click)="closeModal()"><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography" style="background-color: rgb(0 0 0 / 16%);">
	<pdf-viewer [src]="pdfSrc"
                [render-text]="true"
                [original-size]="false"
                [rotation]="rotationAngle"
                [show-all]="true"
                [fit-to-page]="false"
                [zoom]="zoom"
                [zoom-scale]="fitPageVal"
                [stick-to-page]="false"
                [external-link-target]="'blank'"
                [autoresize]="true"
                [show-borders]="false"
                style="width: 100%; height: 65vh;"
    ></pdf-viewer>
	
</mat-dialog-content>

