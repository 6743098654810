<div class="card supervision_documents">
    <div class="card-header">{{'lang_documents' | language }} </div>
    <div class="card-body pad0">
        <div class="mbrl15">
            <div class="row">
                <div class="col-12">
                    <div class="input-group input-group-sm mb-10">
                        <input type="text" class="form-control almnabrformcontrol h34" [(ngModel)]="documentsearchKey" (keyup)="search_supervision_documents();" placeholder="{{'lang_search' | language }}" />
                        <span class="input-group-btn" *ngIf="able2add">
                            <button id="btnSearch" type="button" matTooltip="{{'lang_upload_attachments' | language }}" class="btnSearch h34 btn btn-md btn-gray fa fa-upload mnabricon" (click)="emptyaddform();openModal(uploadocuments)"></button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                    <select (change)="spinner.show();search_supervision_documents();" [(ngModel)]="documents_file_status" class="form-control almnabrformcontrol">
                        <option value="1" selected>{{'lang_active' | language }}</option>
                        <option value="0">{{'lang_history' | language }}</option>
                        <option value="">{{'lang_all' | language }}</option>
                    </select>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                    <ng-select 
                            [items]="document_types" 
                            [multiple]="true" 
                            bindLabel="label" 
                            bindValue="value" 
                            appendTo="body" 
                            clearAllText="Clear"
                            placeholder="{{'lang_doc_type' | language}}"
                            [(ngModel)]="document_type"
                            (change)="spinner.show();search_supervision_documents();">
                    </ng-select>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                    <ng-select 
                            [items]="sub_document_types" 
                            [multiple]="true" 
                            bindLabel="label" 
                            bindValue="value" 
                            appendTo="body" 
                            clearAllText="Clear"
                            placeholder="{{'lang_item_divisions' | language}}"
                            [(ngModel)]="item_document_type"
                            (change)="spinner.show();search_supervision_documents();"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive dropdownyes customResponsive">
                        <table border="1" class="table CustomTable">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>
                                        <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                            <input type="checkbox" class="allItemsDoc checkbox" id="AllItemsDoc" (click)="checkAllItemsDoc($event)"/>
                                            <label for="checkbox"></label>
                                        </div>
                                    </td>
                                    <td>{{'lang_description' | language }}</td>
                                    <td>{{'lang_doc_type' | language }}</td>
                                    <td>{{'lang_item_divisions' | language }}</td>
                                    <td>{{'lang_file_level' | language }}</td>
                                    <td>{{'lang_status' | language }}</td>
                                    <td>{{'lang_writer' | language }}</td>
                                    <td>{{'lang_ondate' | language }}</td>
                                    <td>{{'lang_action' | language }}</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="docListData.length != '0' " class="AppendList">
                                <tr *ngFor="let list of docListData.records; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{list.documents_id}}">
                                    <td>{{i+1}}</td>
                                    <td>
                                        <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                            <input type="checkbox"  class="eachItemDoc checkbox" value="{{list.documents_id}}" (click)="checkEachItemDoc($event)"/>
                                            <label for="checkbox"></label>
                                        </div>
                                    </td>
                                    <td>{{list.documents_description}}</td>
                                    <td>{{list.document_type}}</td>
                                    <td *ngIf="list.document_sub_type">{{list.document_sub_type}}</td>
                                    <td *ngIf="!list.document_sub_type">----</td>
                                    <td>{{list.level_keys}}</td>
                                    <td *ngIf="list.documents_file_status != '1'">
                                        <i class="fa fa-icon fa-circle red" matTooltip="{{'lang_history' | language }}" aria-hidden="true"></i>
                                    </td>
                                    <td *ngIf="list.documents_file_status == '1'">
                                        <i class="fa fa-icon fa-circle green" matTooltip="{{'lang_active' | language }}" aria-hidden="true"></i>
                                    </td>
                                    <td>{{list.writer}}</td>
                                    <td>{{list.created_datetime}}</td>
                                    <td>
                                        <i class="together-icons">
                                            <i matTooltip="{{'lang_file' | language }}" (click)="ds.previewData(list.documents_file_url,'#pdfPreviewDoc')" class="fa fa-paperclip fa-icon s2icon mnabricon" ></i>
                                            <i *ngIf="able2edit" matTooltip="{{'lang_edit' | language }}" (click)="emptyEditform(list);openModal(updatedocform)" class="fa fa-pencil-square-o fa-icon s2icon mnabricon"></i>
                                        </i>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="docListData.length == '0' " class="AppendList">
                                <tr class="odd">
                                    <td colspan="10" align="center">{{docnodata}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="docListData.length != '0' " class=" padb0">
            <div class="row">
                <div *ngIf="able2delete" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                    <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataDoc()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                </div>
                <div class="col-lg-2 col-sm-6 col-6  mb-10">
                    {{'lang_total' | language }} : {{docListData.page.total_records}}
                </div>
                <!-- <div class="col-lg-5 col-sm-6 mb-10">
                    <mat-radio-group>
                        <mat-radio-button value="10" checked (click)="load_supervision_documents_pagesize(10)"> 10 </mat-radio-button>
                        <mat-radio-button value="20" (click)="load_supervision_documents_pagesize(20)"> 20 </mat-radio-button>
                        <mat-radio-button value="50" (click)="load_supervision_documents_pagesize(50)"> 50 </mat-radio-button>
                        <mat-radio-button value="100" (click)="load_supervision_documents_pagesize(100)"> 100 </mat-radio-button>
                        <mat-radio-button value="500" (click)="load_supervision_documents_pagesize(500)"> 500 </mat-radio-button>
                    </mat-radio-group>
                </div> -->
                <!-- <div class="col-lg-4 col-sm-6">
                    {{'lang_goto' | language }} :
                    <select class="customselect" (change)="load_supervision_documents_page($event.target.value)">
                        <option *ngFor="let ks of ds.getrange(docListData.page.total_pages)" value="{{ks}}">{{ks}}</option>
                    </select>
                </div> -->
            </div>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

        </div>
    </div>
</div>
<ng-template #uploadocuments>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_upload_attachments' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()">&times;</button>
        </div>
        <form [formGroup]="form" (ngSubmit)="submit('S')">
            <div class="modal-body">            
                <div class="form-group">
                    <label for="document_type">{{'lang_doc_type' | language }}:<span class="red">*</span></label>
                    <ng-select 
                        [items]="document_types" 
                        [multiple]="false" 
                        bindLabel="label" 
                        bindValue="value" 
                        appendTo="body" 
                        clearAllText="Clear"
                        placeholder="{{'lang_doc_type' | language}}"
                        formControlName="document_type"
                        (change)="show_sub_division_field($event);">
                    </ng-select>
                    <mat-error *ngIf="form.controls['document_type'].touched && form.controls['document_type'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
                <div class="form-group" *ngIf="showdivision">
                    <label for="documents_parent_id">{{'lang_item_divisions' | language }}: <span class="red">*</span> </label>
                    <ng-select 
                            [items]="sub_document_types" 
                            [multiple]="false" 
                            bindLabel="label" 
                            bindValue="value" 
                            appendTo="body" 
                            clearAllText="Clear"
                            placeholder="{{'lang_item_divisions' | language}}"
                            formControlName="documents_parent_id">
                    </ng-select>
                    <mat-error *ngIf="form.controls['documents_parent_id'].touched && form.controls['documents_parent_id'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
                <div class="form-group">
                    <label for="level_keys">{{'lang_file_secutrity_levels' | language }} : <span class="red">*</span></label>
                    <ng-select 
                        [items]="filelevels" 
                        [multiple]="true" 
                        bindLabel="label" 
                        appendTo="body" 
                        bindValue="value" 
                        clearAllText="Clear"
                        placeholder="{{'lang_file_secutrity_levels' | language}}"
                        formControlName="level_keys">
                    </ng-select>
                    <mat-error *ngIf="form.controls['level_keys'].touched && form.controls['level_keys'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="documents_description">{{'lang_description' | language }}:<span class="red">*</span></label>
                    <input type="text" class="form-control almnabrformcontrol" formControlName="documents_description">                        
                    <mat-error *ngIf="form.controls['documents_description'].touched && form.controls['documents_description'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
                <div class="form-group">
                    <label for="documents_file">{{'lang_attachments' | language }}: <span class="red"> * </span></label>
                    <div class="IDDIV">
                        <input style="display: block;" name="documents_file" type="file" required onclick="this.value = null" (change)="UploadFile($event, form.get('documents_file'))" class="nghide albutton width100">
                        <label id="uploadText" class="albutton">{{filename}} </label>  
                    </div>
                    <mat-error *ngIf="form.controls['documents_file'].touched && form.controls['documents_file'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>            
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" [disabled]="!form.valid" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRef.hide()">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #updatedocform>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_update' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()">&times;</button>
        </div>
        <form [formGroup]="updateform" (ngSubmit)="submit('U')">
            <div class="modal-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="level_keys">{{'lang_file_secutrity_levels' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <ng-select 
                                [items]="filelevels" 
                                [multiple]="true" 
                                bindLabel="label" 
                                appendTo="body" 
                                bindValue="value" 
                                clearAllText="Clear"
                                placeholder="{{'lang_file_secutrity_levels' | language}}"
                                formControlName="level_keys">
                            </ng-select>
                            <mat-error *ngIf="updateform.controls['level_keys'].touched && updateform.controls['level_keys'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="documents_description">{{'lang_description' | language }}:<span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" class="form-control almnabrformcontrol" formControlName="documents_description">
                        </div>
                        <mat-error *ngIf="updateform.controls['documents_description'].touched && updateform.controls['documents_description'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="documents_file">{{'lang_attachments' | language }}: <i *ngIf="isFile" class="mnabricon red fa fa-close" (click)="removeFile();"></i></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="IDDIV">
                                <input style="display: block;" name="documents_file" type="file" onclick="this.value = null" (change)="UploadFile($event, updateform.get('documents_file'))" class="nghide albutton width100">
                                <label id="uploadText" class="albutton">{{filename}} </label>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" [disabled]="!updateform.valid" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRef.hide()">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<div class="modal" id="pdfPreviewDoc">
    <div class="modal-dialog width70">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{'lang_preview' | language }}</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="pdf-container">
            <iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'lang_close' | language }}</button>
        </div>
      </div>
    </div>
</div>