<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno" [@fadeIn]>
    <mat-accordion class="customers" >
        <mat-expansion-panel class="mpanel" [expanded]="true">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_customers' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <select class="form-control almnabrformcontrol" [(ngModel)]="searchBranch" (change)="spinner.show();load_customers()" matTooltip="{{'lang_search_all' | language }}">
                                <option value="">{{'lang_all' | language }}</option>
                                <option *ngFor="let branch of branches" value="{{branch.branch_id}}">{{branch.branch_name}}</option>
                            </select>
                        </div>
                        <div class="col-md-9 col-sm-9 col-xs-12 mb-10">
                            <input type="text" class="form-control almnabrformcontrol searchKey" [(ngModel)]="searchKey" (keyup)="load_customers()" placeholder="{{'lang_search' | language }}" id="searchKey" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsCSmers checkbox" id="allItemsCSmers" (click)="checkAllItemsCSmers($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_branch' | language }}</td>
                                            <td>{{'lang_name' | language }}</td>
                                            <td>{{'lang_email_address' | language }}</td>
                                            <td>{{'lang_mobile' | language }}</td>
                                            <td>{{'lang_vat_tax' | language }}</td>
                                            <td>{{'lang_writer' | language }}</td>
                                            <td>{{'lang_ondate' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="cData.length != '0' " class="AppendList">
                                        <tr *ngFor="let data of cData.records; let i = index;let odd=odd; let even=even"
                                            [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{data.customer_id}}" [attr.note_alert]="data.note_alert">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemCSmers checkbox" value="{{data.customer_id}}" (click)="checkEachItemCSmers($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td> <a matTooltip="{{'lang_view' | language }}" routerLink="/customers/view/{{data.customer_id}}">{{data.branch_name}}</a></td>
                                            <td> <a matTooltip="{{'lang_view' | language }}" routerLink="/customers/view/{{data.customer_id}}">{{data.title}}</a></td>
                                            <td> <a matTooltip="{{'lang_view' | language }}" routerLink="/customers/view/{{data.customer_id}}">{{data.customer_email_address}}</a></td>
                                            <td> <a matTooltip="{{'lang_view' | language }}" routerLink="/customers/view/{{data.customer_id}}">{{data.customer_mobile}}</a></td>
                                            <td> <a matTooltip="{{'lang_view' | language }}" routerLink="/customers/view/{{data.customer_id}}">{{data.customer_tax_vat}}</a></td>
                                            <td>{{data.name}}</td>
                                            <td>{{data.customer_createddatetime}}</td>
                                            <td class="dropdown">
                                                <mat-icon class="mnabricon" [matMenuTriggerFor]="menu">settings</mat-icon>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item routerLink="/customers/view/{{data.customer_id}}"><span>{{'lang_view' | language }}</span></button>
                                                    <button mat-menu-item routerLink="/customers/edit/{{data.customer_id}}" *ngIf="admin"><span>{{'lang_edit' | language }}</span></button>
                                                </mat-menu>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="cData.length == '0' " class="AppendList">
                                        <tr class="odd">
                                            <td colspan="10" align="center">{{lodingdatas}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="cData.length != '0' " class="card-footer padb0">
                    <div class="row">
                        <div *ngIf="admin || ds.allowMe(customers, 'customers_delete')" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataCSmers()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-6 mb-10">
                            {{'lang_total' | language }} : {{cData.page.total_records}}
                        </div>
                        <div class="col-lg-5 col-sm-6 mb-10">
                            <mat-radio-group>
                                <mat-radio-button value="10" [checked]="(page_size =='10') ? true : false" (click)="spinner.show();loadpagesize(10)"> 10 </mat-radio-button>
                                <mat-radio-button value="20" [checked]="(page_size =='20') ? true : false" (click)="spinner.show();loadpagesize(20)"> 20 </mat-radio-button>
                                <mat-radio-button value="50" [checked]="(page_size =='50') ? true : false" (click)="spinner.show();loadpagesize(50)"> 50 </mat-radio-button>
                                <mat-radio-button value="100" [checked]="(page_size =='100') ? true : false" (click)="spinner.show();loadpagesize(100)"> 100 </mat-radio-button>
                                <mat-radio-button value="500" [checked]="(page_size =='500') ? true : false" (click)="spinner.show();loadpagesize(500)"> 500 </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            {{'lang_goto' | language }} :
                            <select class="customselect" (change)="spinner.show();loadpage($event.target.value)">
                                <option *ngFor="let ks of ds.getrange(cData.page.total_pages)" [selected]="(page_no == ks) ? true : false" value="{{ks}}">{{ks}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>