import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $: any;
const setting = {
  singleSelection: true,
  text: "Select",
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  searchPlaceholderText: 'Search.....',
  enableSearchFilter: true,
  labelKey: "title",
  primaryKey: "id"
};
@Component({
  selector: 'app-registration-lists',
  templateUrl: './registration-lists.component.html',
  styleUrls: ['./registration-lists.component.scss']
})
export class RegistrationListsComponent implements OnInit {
  modalRefV: BsModalRef;
  modalRefA: BsModalRef;  
  apiUrl = environment.SERVER_ORIGIN;
  users: any = [];
  settings = setting;
  upsize: any = 10;
  upage: any = 1;
  userdata: any = [];
  groups: any = [];
  userform: FormGroup;
  usererrormsg = this.lang.transform('lang_loading');
  userviewdata: any = [];
  user_error_status: boolean = false;
  isAdmin: boolean = JSON.parse(this.ls.getItem('is_admin'));
  usererrorMsg: string = '';
  viewdata: any = [];
  branches: any = [];
  modules: any = [];
  utypes: any = [];
  privatevalues: any = [];
  searchKey = "";
  searchStatus = ""
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.builduserform();
    this.spinner.show();
    this.list_of_users();
  }
  openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public load_data() {
    this.spinner.show();
    this.load_modules();
    this.load_branches();
    this.load_usertypes();
  }
  public load_modules() {
    this.modules = [];
    this.ds.postActionByUrl([], 'module').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.modules = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public load_branches() {
    this.branches = [];
    this.ds.getActionByUrl([], 'reg/branches').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.branches = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public load_usertypes() {
    this.utypes = [];
    this.ds.getActionByUrl([], 'reg/usertypes').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.utypes = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public load_groups(event) {
    this.groups = [];
    if (event.target.value && event.target.selectedOptions[0].private_key ){
      this.userform.get('private_key').setValue(event.target.selectedOptions[0].private_key);
    }
    this.spinner.show();
    this.ds.getActionByUrl([], 'reg/groups/' + event.target.value).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.groups = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public privateValueSearch(key) {
    this.privatevalues = [];
    let param = new FormData();
    param.append('module_key', this.userform.get('module_key').value);
    param.append('user_type_id', this.userform.get('user_type_id').value);
    param.append('branch_id', this.userform.get('branch_id').value);
    param.append('search_key', key.term || '');
    this.ds.postActionByUrl(param, 'reg/private_values/').subscribe(res => {
      if (res.status) {
        this.privatevalues = res.records;
      }
    })
  }
  //build form
  public builduserform() {
    this.userform = new FormGroup({
      branch_id: new FormControl('', [Validators.required]),
      module_key: new FormControl('', [Validators.required]),
      private_key: new FormControl('', [Validators.required]),
      private_value: new FormControl(null),
      user_type_id: new FormControl('', [Validators.required]),
      group_key: new FormControl('', [Validators.required]),
      user_registration_firstname_en: new FormControl('', [Validators.required]),
      user_registration_lastname_en: new FormControl('', [Validators.required]),
      user_registration_firstname_ar: new FormControl('', [Validators.required]),
      user_registration_lastname_ar: new FormControl('', [Validators.required]),
      user_email_address: new FormControl('', [Validators.required, Validators.email]),
      user_mobile_number: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]),
      personal_id_number: new FormControl(''),
    });
  }
  //listing starts
  public userpage(size) {
    this.upsize = size;
    this.upage = 1;
    this.list_of_users();
  }
  public userlength(page) {
    this.upage = page;
    this.list_of_users();
  }
  public search_users() {
    this.upage = 1;
    this.upsize = 10;
    this.list_of_users();
  }
  public list_of_users() {
    let formData = new FormData();
    formData.append('branch_id', '');
    formData.append('module_key', '');
    formData.append('search_key', this.searchKey);
    formData.append('search_status', this.searchStatus || '');
    this.ds.postActionByUrl(formData, 'reg/a/' + this.upage + '/' + this.upsize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.users = res.records;
        this.userdata = res;
      } else {
        this.users = [];
        this.usererrormsg = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.users = [];
      this.userdata = [];
      this.usererrormsg = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  //listing ends
  public send_signup_link(user) {
    this.spinner.show();
    this.ds.getActionByUrl([], 'reg/link/' + user.user_registration_link).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.ds.dialogf('', data.msg);
      } else {
        this.ds.dialogf('',data.error);
      }
    }, error => {
      $('#angular_loader').fadeOut();
      this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    });
  }
  //view user signup data
  public signup_data_view(viewdata) {
    this.viewdata = viewdata;
  }
  //empty userform
  public emptyUserForm() {
    this.user_error_status = false;
    this.usererrorMsg = '';
    this.userform.reset();
  }
  public user_operations() {
    this.spinner.show();
    let private_value = this.userform.get('private_value').value;
    let param = this.ds.json2formdata(this.userform.value);
    param.append('private_value', private_value || '');
    param.append('permission_key', "");
    param.append('user_registration_type', "D");
    this.ds.postActionByUrl(param, 'reg').subscribe(res => {
      this.user_error_status = res.status;
      this.spinner.hide();
      if (res.status) {
        this.alert.success(res.msg);
        this.list_of_users();
        setTimeout(() => {
          this.modalRefA.hide();
        }, 1500);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public enable_disable_user(user_id, status) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show();
        this.ds.getActionByUrl([], 'reg/ustatus/' + user_id + '/' + status).subscribe(res => {
            this.spinner.hide();
            if (res.status) {
              this.list_of_users();
              this.ds.dialogf('',res.msg);
            } else {
              this.ds.dialogf('',res.error);
            }
          },(error) => {
            this.spinner.hide();
            this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
          }
        );
      }
    });
  }
}
