<breadcrumb></breadcrumb>
<div class="themesection">
    <section class="panel-expansion matborderno">
        <mat-accordion class="create_selling_invoice" >
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_create_purchase_invoices' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body">
                    <form [formGroup]="form" (ngSubmit)="create_invoices()" class="form">
                        <div class="mbrl15">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_branch' | language }} :<span class="red">*</span></label>
                                        <select class="form-control almnabrformcontrol" formControlName="branch_id" #branch_id (change)="load_payment_modes(branch_id.value);load_taxes();load_tax_settings();">
                                            <option value="">{{'lang_choose_options' | language }}</option>
                                            <option *ngFor="let branch of branches" value="{{branch.id}}">{{branch.title}}</option>
                                        </select>
                                        <mat-error *ngIf="form.controls['branch_id'].touched && form.controls['branch_id'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10"
                                    *ngIf="form.get('branch_id').value && form.get('branch_id').value != '0' ">
                                    <div class="form-group">
                                        <label>{{'lang_invoice_date' | language }} :<span class="red">*</span></label>
                                        <div class="input-group input-group-sm">
                                            <input [matDatepicker]="invoice_date" formControlName="invoice_date" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly>
                                            <span class="input-group-btn">
                                                <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="invoice_date"></mat-datepicker-toggle>
                                                <mat-datepicker #invoice_date></mat-datepicker>
                                            </span>
                                            <span class="input-group-btn">
                                                <button (click)="form.get('invoice_date').setValue('') " matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                                            </span>
                                        </div>
                                        <mat-error *ngIf="form.controls['invoice_date'].touched && form.controls['invoice_date'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10" *ngIf="form.get('branch_id').value && form.get('branch_id').value != '0' ">
                                    <div class="form-group">
                                        <label>{{'lang_payment_mode' | language }} :<span class="red">*</span></label>
                                        <div class="row">
                                            <div class="col-6">
                                                <ng-select 
                                                    [items]="modes" 
                                                    [multiple]="false" 
                                                    clearAllText="Clear"
                                                    appendTo="body" 
                                                    formControlName="invoice_payment_mode"
                                                    placeholder="{{'lang_payment_mode' | language}}"
                                                    (change)="payment_mode_options($event);calculate_total_calculation();"
                                                >
                                                </ng-select>
                                                <mat-error *ngIf="form.controls['invoice_payment_mode'].touched && form.controls['invoice_payment_mode'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                            </div>
                                            <div class="col-6">
                                                <input type="text" class="form-control almnabrformcontrol" placeholder="{{'lang_credit_days' | language }}" formControlName="invoice_credit_days" autocomplete="off" (keyup)="add_days($event.target.value)">
                                            </div>
                                        </div>
                                        <mat-error *ngIf="form.controls['invoice_credit_days'].touched && form.controls['invoice_credit_days'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="form.get('branch_id').value && form.get('branch_id').value != '0' ">   
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_credit_date' | language }} :</label>
                                            <input formControlName="invoice_credit_date" value="{{ date | date: 'yyyy/MM/dd' }}" class="form-control almnabrformcontrol" [readonly]="true" placeholder="YYYY/MM/DD" />
                                            <mat-error *ngIf="form.controls['invoice_credit_date'].touched && form.controls['invoice_credit_date'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_supplier_account' | language }} :<span class="red">* ({{'lang_credit' | language }})</span></label>
                                            <ng-select 
                                                [items]="accounts" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="invoice_to_account_id"
                                                placeholder="{{'lang_supplier_account' | language}}"
                                                (search)="searchAccounts($event, form.get('invoice_payment_mode').value, 'customer_accounts')"
                                                (focus)="accounts = []"
                                            >
                                            </ng-select>
                                            <mat-error *ngIf="form.controls['invoice_to_account_id'].touched && form.controls['invoice_to_account_id'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_credit_cost' | language }} :</label>
                                            <ng-select 
                                                [items]="costs" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="invoice_to_cost_center_id"
                                                placeholder="{{'lang_credit_cost' | language}}"
                                                (search)="searchCosts($event, form.get('invoice_to_account_id').value)"
                                                (focus)="costs = []"
                                            >
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_supplier_address' | language }} : </label>
                                            <input type="text" formControlName="invoice_address_to" class="form-control almnabrformcontrol" placeholder="{{'lang_supplier_address' | language }}" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_vat_tax' | language }} :</label>
                                            <input type="text" formControlName="invoice_vat_tax_id" class="form-control almnabrformcontrol" placeholder="{{'lang_vat_tax' | language }}" />
                                            <mat-error *ngIf="form.controls['invoice_vat_tax_id'].touched && form.controls['invoice_vat_tax_id'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_reference_no' | language }} :</label>
                                            <input type="text" formControlName="invoice_ref_number" class="form-control almnabrformcontrol" placeholder="{{'lang_reference_no' | language }}" />
                                            <mat-error *ngIf="form.controls['invoice_ref_number'].touched && form.controls['invoice_ref_number'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_invoice_instruction_remarks' | language }} :</label>
                                            <input type="text" formControlName="invoice_instruction_remarks" class="form-control almnabrformcontrol" placeholder="{{'lang_invoice_instruction_remarks' | language }}" />
                                            <mat-error *ngIf="form.controls['invoice_instruction_remarks'].touched && form.controls['invoice_instruction_remarks'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_writer' | language }} : <span class="red">*</span></label>
                                            <input type="text" value="{{fullname}}" readonly disabled class="form-control almnabrformcontrol" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_process_type' | language }} : <span class="red">*</span></label>
                                            <select class="form-control almnabrformcontrol" formControlName="process_type">
                                                <option value="1"> {{'lang_purchase_order' | language }}</option>
                                                <option value="2"> {{'lang_grr_order' | language }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_tax_exmbed' | language }} : <span class="red">*</span></label>
                                            <select class="form-control almnabrformcontrol" formControlName="invoice_tax_exambed">
                                                <option value="0"> {{'lang_no' | language }}</option>
                                                <option value="1"> {{'lang_yes' | language }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_tax_refund' | language }} : <span class="red">*</span></label>
                                            <select class="form-control almnabrformcontrol" formControlName="invoice_tax_refund">
                                                <option value="0"> {{'lang_no' | language }}</option>
                                                <option value="1"> {{'lang_yes' | language }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_tax_pay_supplier' | language }} : <span class="red">*</span></label>
                                            <select class="form-control almnabrformcontrol" formControlName="invoice_tax_pay_supplier">
                                                <option value="0"> {{'lang_no' | language }}</option>
                                                <option value="1"> {{'lang_yes' | language }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div [ngClass]="{'TableParts' : item_tax || global_tax}">
                                    <div class="table-responsive customResponsive mb-10 tablefields">
                                        <table border="1" class="table CustomTable text-center">
                                            <thead>
                                                <tr>
                                                    <td>#</td>
                                                    <td><i class="fa fa-plus-square fa-icon mnabricon" matTooltip="{{'lang_add' | language }}" (click)="add_items();calculate_total_calculation()"></i></td>
                                                    <td>{{'lang_invoice_item_code' | language }} <i class="red">*</i></td>
                                                    <td>{{'lang_invoice_item_name' | language }} <i class="red">*</i></td>
                                                    <td>{{'lang_invoice_item_unit' | language }} <i class="red">*</i></td>
                                                    <td>{{'lang_invoice_location' | language }}</td>
                                                    <td>{{'lang_invoice_item_quantity' | language }} <i class="red">*</i></td>
                                                    <td>{{'lang_invoice_item_price' | language }} <i class="red">*</i></td>
                                                    <td width="10%" *ngIf="item_tax">{{'lang_tax' | language }}</td>
                                                    <td *ngIf="item_tax">{{'lang_tax_amount' | language }}</td>
                                                    <td>{{'lang_invoice_item_amount' | language }}<i class="red">*</i></td>
                                                    <td *ngIf="item_discount"> {{'lang_invoice_discount_percent' | language }} %</td>
                                                    <td width="10%" *ngIf="item_discount"> {{'lang_invoice_discount_amount' | language }}</td>
                                                    <td>{{'lang_invoice_total_amount' | language }}<i class="red">*</i></td>
                                                    <td>{{'lang_notes' | language }}<i class="red">*</i></td>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="items" class="AppendList">
                                                <tr *ngFor="let item of items_group().controls; let itemIndex = index;let odd=odd; let even=even" [formGroupName]="itemIndex">
                                                    <td class="verticalalignmid pad0">{{itemIndex+1}}</td>
                                                    <td class="verticalalignmid pad0"><i (click)="removeItems(itemIndex);calculate_total_calculation();" class="fa fa-minus-square fa-icon red mnabricon" matTooltip="{{'lang_delete' | language }}"></i></td>
                                                    <td class="pad0"><input type="text" formControlName="invoice_item_code" class="form-control almnabrformcontrol" placeholder="{{'lang_invoice_item_code' | language }}" /></td>
                                                    <td class="pad0"><input type="text" formControlName="invoice_item_name" class="form-control almnabrformcontrol" placeholder="{{'lang_invoice_item_name' | language }}" /></td>
                                                    <td class="pad0"><input type="text" formControlName="invoice_item_unit" class="form-control almnabrformcontrol" placeholder="{{'lang_invoice_item_unit' | language }}" /></td>
                                                    <td class="pad0"><input type="text" formControlName="invoice_location" class="form-control almnabrformcontrol" placeholder="{{'lang_invoice_location' | language }}" /></td>
                                                    <td class="pad0"><input type="text" formControlName="invoice_item_quantity" class="form-control almnabrformcontrol" placeholder="{{'lang_invoice_item_quantity' | language }}" (change)="ds.CheckDecimal($event.target.value, itemvalid.controls[itemIndex].get('invoice_item_quantity'));" (keyup)="calculate_invoice_tax(itemvalid.controls[itemIndex], itemIndex);" /></td>
                                                    <td class="pad0"><input type="text" formControlName="invoice_item_price" class="form-control almnabrformcontrol" placeholder="{{'lang_invoice_item_price' | language }}" (change)="ds.CheckDecimal($event.target.value, itemvalid.controls[itemIndex].get('invoice_item_price'));" (keyup)="calculate_invoice_tax(itemvalid.controls[itemIndex], itemIndex);" /></td>
                                                    <td class="pad0" *ngIf="item_tax">
                                                        <ng-select 
                                                            [items]="taxs" 
                                                            [multiple]="false" 
                                                            clearAllText="Clear"
                                                            appendTo="body" 
                                                            formControlName="invoice_item_tax"
                                                            placeholder="{{'lang_invoice_to' | language}}"
                                                            (change)="calculate_invoice_tax(itemvalid.controls[itemIndex], itemIndex);"
                                                        >
                                                        </ng-select>
                                                    </td>
                                                    <td class="pad0" *ngIf="item_tax"><input type="text" formControlName="invoice_item_tax_amount" class="form-control almnabrformcontrol" placeholder="{{'lang_invoice_item_amount' | language }}" /></td>   
                                                    <td class="pad0"><input type="text" formControlName="invoice_item_amount" class="form-control almnabrformcontrol" placeholder="0.00" /></td>
                                                    <td class="pad0" *ngIf="item_discount"><input type="text" formControlName="invoice_discount_percent" class="form-control almnabrformcontrol" placeholder="{{'lang_invoice_discount_percent' | language }}" (change)="ds.CheckDecimal($event.target.value, itemvalid.controls[itemIndex].get('invoice_discount_percent'));" (keyup)="calculate_invoice_tax(itemvalid.controls[itemIndex], itemIndex);" /></td>
                                                    <td class="pad0" *ngIf="item_discount"><input type="text" formControlName="invoice_discount_percent_amount" class="form-control almnabrformcontrol" placeholder="0.00" /></td>
                                                    <td class="pad0"><input type="text" formControlName="invoice_total_amount" class="form-control almnabrformcontrol" placeholder="{{'lang_invoice_total_amount' | language }}" /></td>
                                                    <td class="pad0"><input type="text" formControlName="invoice_item_description" class="form-control almnabrformcontrol" placeholder="{{'lang_description' | language }}" /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_expanse_accounts' | language }} : <span class="red">* ({{'lang_debit' | language }})</span></label>
                                            <ng-select 
                                                [items]="accounts" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="invoice_income_expanse_account_id"
                                                placeholder="{{'lang_expanse_accounts' | language}}"
                                                (search)="searchAccounts($event, form.get('invoice_payment_mode').value, 'income_accounts')"
                                                (focus)="accounts = []"
                                            >
                                            </ng-select>
                                            <mat-error *ngIf="form.controls['invoice_income_expanse_account_id'].touched && form.controls['invoice_income_expanse_account_id'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_expense_costcenters' | language }} : ({{'lang_debit' | language }})</label>
                                            <ng-select 
                                                [items]="costs" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="invoice_income_expanse_cost_id"
                                                placeholder="{{'lang_expense_costcenters' | language}}"
                                                (search)="searchCosts($event, form.get('invoice_income_expanse_account_id').value)"
                                                (focus)="costs = []"
                                            >
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_grand_total' | language }} : <span class="red">*</span></label>
                                            <input formControlName="invoice_grand_total" class="form-control almnabrformcontrol" placeholder="0.00" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="global_discount">
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_global_discount' | language }} : </label>
                                            <div class="row">
                                                <div class="col-md-6 col-sm-6 col-xs-6">
                                                    <input formControlName="invoice_global_discount_percent" class="form-control almnabrformcontrol" placeholder="0.00" (keyup)="calculate_total_calculation();" (change)="ds.CheckDecimal($event.target.value, form.get('invoice_global_discount_percent'));" />
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-6">
                                                    <input type="text" id="global_tax" formControlName="invoice_global_discount_amount" class="form-control almnabrformcontrol" placeholder="0" autocomplete="off" readonly>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group"
                                            *ngIf="form.get('invoice_cash_advance_amount').value && form.get('invoice_cash_advance_amount').value != '0.00' ">
                                            <label>{{advance_acode_text}} : <span class="red">* ({{'lang_credit' | language }})</span></label>
                                            <ng-select 
                                                [items]="accounts" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="invoice_cash_advance_account_id"
                                                placeholder="{{advance_acode_text}}"
                                                (search)="searchAccounts($event, form.get('invoice_payment_mode').value, 'advance_accounts')"
                                                (focus)="accounts = []"
                                            >
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group"
                                            *ngIf="form.get('invoice_cash_advance_amount').value && form.get('invoice_cash_advance_amount').value != '0.00' ">
                                            <label>{{advance_ccode_text}} : </label>
                                            <ng-select 
                                                [items]="costs" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="invoice_cash_advance_cost_center_id"
                                                placeholder="{{advance_ccode_text}}"
                                                (search)="searchCosts($event, form.get('invoice_cash_advance_account_id').value)"
                                                (focus)="costs = []">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_cash_advance' | language }} : </label>
                                            <input formControlName="invoice_cash_advance_amount" class="form-control almnabrformcontrol" placeholder="0.00" (keyup)="calculate_total_calculation();" (change)="ds.CheckDecimal($event.target.value, form.get('invoice_cash_advance_amount'));" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="item_tax || global_tax">
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group" *ngIf=" (form.get('total_item_tax').value && form.get('total_item_tax').value != '0.00') || (form.get('invoice_global_tax_amount').value && form.get('invoice_global_tax_amount').value != '0.00') ">
                                            <label>{{'lang_tax_accounts' | language }} : <span class="red">* ({{'lang_debit' | language }})</span></label>
                                            <ng-select 
                                                [items]="accounts" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="invoice_tax_account_id"
                                                placeholder="{{'lang_tax_accounts' | language}}"
                                                (search)="searchAccounts($event, form.get('invoice_payment_mode').value, 'tax_accounts')"
                                                (focus)="accounts = []">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group"
                                            *ngIf=" (form.get('total_item_tax').value && form.get('total_item_tax').value != '0.00') || (form.get('invoice_global_tax_amount').value && form.get('invoice_global_tax_amount').value != '0.00') ">
                                            <label>{{'lang_tax_costcenter' | language }} : </label>
                                            <ng-select 
                                                [items]="costs" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="invoice_tax_cost_center_id"
                                                placeholder="{{'lang_tax_costcenter' | language}}"
                                                (search)="searchCosts($event, form.get('invoice_tax_account_id').value)"
                                                (focus)="costs = []"
                                            >
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group" *ngIf="global_tax">
                                            <label>{{'lang_tax_group' | language }} : </label>
                                            <div class="row">
                                                <div class="col-md-6 col-sm-6 col-xs-6">
                                                    <ng-select 
                                                        [items]="taxs" 
                                                        [multiple]="false" 
                                                        clearAllText="Clear"
                                                        appendTo="body" 
                                                        formControlName="invoice_global_tax_id"
                                                        placeholder="{{'lang_tax_group' | language}}"
                                                        (change)="global_tax_age();">
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-6">
                                                    <div class="input-group input-group-sm ">
                                                        <input type="text" id="global_tax1" formControlName="invoice_global_tax_age" class="form-control almnabrformcontrol" placeholder="0" autocomplete="off" readonly>
                                                        <span class="input-group-addon">%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="item_tax || global_tax">
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_total_item_tax' | language }} : </label>
                                            <input formControlName="total_item_tax" class="form-control almnabrformcontrol" placeholder="0.00" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_total_item_discount' | language }} : </label>
                                            <input formControlName="total_item_discount" class="form-control almnabrformcontrol" placeholder="0.00" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group" *ngIf="global_tax">
                                            <label>{{'lang_accounts_global_item_tax' | language }} : </label>
                                            <input formControlName="invoice_global_tax_amount" class="form-control almnabrformcontrol" placeholder="0.00" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10"></div>
                                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                        <div class="form-group">
                                            <label>{{'lang_net_amount' | language }} : <span class="red">*</span> </label>
                                            <input formControlName="invoice_net_total" class="form-control almnabrformcontrol" placeholder="0.00" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer padb0">
                            <div class="row">
                                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                                    <button type="button" class="albutton" routerLink="/accounts/pinvoices">{{'lang_goto_list' | language }}</button>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-12 mb-10">
                                    <app-alert id="default-alert"></app-alert>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                                    <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</div>