import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-quotation-requests',
  templateUrl: './quotation-requests.component.html',
  styleUrls: ['./quotation-requests.component.scss']
})
export class QuotationRequestsComponent implements OnInit {
  @Input() customer_id;
  @Input() type;
  @Input() able2add;
  @Input() able2edit;
  @Input() able2delete;
  @Input() customer_data;
  modalRefA: BsModalRef;
  modalRefV: BsModalRef;
  public quotation_requestform: FormGroup;
  myquotation_requests: any = [];
  ppsize: any = 10;
  ppage: any = 1;
  quotation_requestdata: any = [];
  quotation_requesterrormsg = this.lang.transform('lang_loading');
  quotation_requestviewdata: any = [];
  searchKey = "";
  searchStatus = "";
  constructor(public ds: DataService,public router:Router,public route :ActivatedRoute,public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
  ngOnInit() {
    this.list_of_quotation_requests();
  }
  public quotation_requestpage(size) {
    this.ppsize = size;
    this.ppage = 1;
    this.list_of_quotation_requests();
  }
  public quotation_requestlength(page) {
    this.ppage = page;
    this.list_of_quotation_requests();
  }
  public search_my_quotation_requests() {
    this.ppage = 1;
    this.ppsize = 10;
    this.list_of_quotation_requests();
  }
  public list_of_quotation_requests() {
    let formData = new FormData();
    formData.append('customer_id', this.customer_id);
    formData.append('search_key', this.searchKey);
    this.ds.getActionByUrl(this.ds.formData2string(formData), 'cs/quotation_requests/' + this.ppage + '/' + this.ppsize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.myquotation_requests = res.records;
        this.quotation_requestdata = res;
      } else {
        this.myquotation_requests = [];
        this.quotation_requesterrormsg = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.myquotation_requests = [];
      this.quotation_requestdata = [];
      this.quotation_requesterrormsg = error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
}