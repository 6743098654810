import { trigger, transition, query, style, stagger, animate, state } from '@angular/animations';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
@Component({
  selector: 'app-tlist',
  templateUrl: './tlist.component.html',
  styleUrls: ['./tlist.component.scss'],
  animations: [
    trigger("fadeIn", [
      state(
        "void",
        style({
          opacity: 0
        })
      ),
      transition("void <=> *", animate(1000))
    ])
  ]
})
export class TlistComponent implements OnInit {
  modules: any          = null;
  statusList = [
    {
      label: this.lang.transform('lang_all_pending_need_action'),
      value: 'all_pending_need_action'
    },
    {
      label: this.lang.transform('lang_all_pending'),
      value: 'all_pending'
    },
    {
      label: this.lang.transform('lang_all_complete'),
      value: 'all_complete'
    },
    {
      label: this.lang.transform('lang_all_sent'),
      value: 'all_sent'
    }
  ]
  admin:any             = JSON.parse(this.ls.getItem('is_admin'));
  selectedItems         = [];
  listData: any         = [];
  formlist: any         = [];
  nodata: string        = this.lang.transform('lang_loading');
  searchKey:string      = localStorage.getItem('M_T_S_K') || "";
  filteradmin           = localStorage.getItem('M_T_A')   || "0";
  searchByForm:any      = (localStorage.getItem('M_T_F')) ? localStorage.getItem('M_T_F') : null;
  searchByModule:any    = (localStorage.getItem('M_T_M')) ? localStorage.getItem('M_T_M')?.split(',') : null;
  searchByStatus:any    = localStorage.getItem('M_T_STATUS')   || "all_pending_need_action";
  pageno: any           = localStorage.getItem('M_T_P')   || 1;
  pagesize: any         = localStorage.getItem('M_T_S')   || 10;
  loading               = true;
  constructor(public ds:DataService, public ls:LocalStorage, public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, private sweetAlert:SweetAlertService) { }
  ngOnInit(): void {
    this.spinner.hide();
    this.module_lists();
    this.form_lists();
    this.get_form_lists();
    console.log(this.searchByForm);
    
  }
  public module_lists() {
    this.modules = [];
    this.ds.getActionByUrl([], 'tc/getmodulesmydoclist').subscribe(res => {
      if (res.status) {
        this.modules = res.list;        
      }
    })
  }
  public form_lists() {
    this.formlist = [];
    this.ds.getActionByUrl([], 'tc/gettcmydoclist').subscribe(res => {
      if (res.status) {
        this.formlist = res.list;        
      }
    })
  }
  public search_transactions() {
    this.pageno = 1;
    this.pagesize = 10;
    this.get_form_lists();
  }
  public loadPageFrm(page) {
    this.pageno = page;
    this.pagesize = this.pagesize;
    this.get_form_lists();
  }
  public loadFrmPagesize(size) {
    this.pageno = 1;
    this.pagesize = size;
    this.get_form_lists();
  }
  public get_form_lists() {
    this.listData = [];
    this.loading = true;
    localStorage.setItem('M_T_S_K', this.searchKey || "");
    localStorage.setItem('M_T_A', (this.admin) ? this.filteradmin : '0');
    localStorage.setItem('M_T_F', this.searchByForm || '');    
    localStorage.setItem('M_T_M', (this.searchByModule && this.searchByModule && this.searchByModule.length > 0) ? this.searchByModule : '');
    localStorage.setItem('M_T_P', this.pageno);
    localStorage.setItem('M_T_S', this.pagesize);
    localStorage.setItem('M_T_STATUS', this.searchByStatus || 'all_pending_need_action');
    let param = new FormData();
    param.append('searchKey', this.searchKey || "");
    param.append('searchAdmin', (this.admin) ? this.filteradmin : '0');
    param.append('searchByForm', this.searchByForm || "");    
    param.append('searchByModule', (this.searchByModule && this.searchByModule != null && this.searchByModule.length > 0) ? this.searchByModule.toString() : "");    
    param.append('searchByStatus', this.searchByStatus || "");
    this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/list/' + this.pageno + '/' + this.pagesize).subscribe(res => {
      this.loading = false;
      if (res.status) {
        this.listData = res;
      } else {
        this.listData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.loading = false;
      this.listData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }


  deleteTransaction(list) {
    let body = new HttpParams();
    body.append('transaction_request_id', list?.transaction_request_id);

    this.sweetAlert.confirmMessage('Are you sure?', 'Confirm').then(result => {
      if(result.isConfirmed) {
        let data = new FormData()
        data.append('transaction_request_id', list?.transaction_request_id);
        this.spinner.show();
        if(list?.transaction_key == 'FORM_HRV1' || list?.transaction_key == 'FORM_HRJ1'||
        list?.transaction_key    == 'FORM_OVR1' || list?.transaction_key == 'FORM_VOL1'||
        list?.transaction_key    == 'FORM_BNS1' || list?.transaction_key == 'FORM_DET1'||
        list?.transaction_key    == 'FORM_DSR'  || list?.transaction_key == 'FORM_WIR' ||
        list?.transaction_key    == 'FORM_SQR'  || list?.transaction_key == 'FORM_MSR' || list?.transaction_key == 'FORM_MIR'){
          this.ds.post(`form/${list?.transaction_key}/dr`,data).subscribe(res => {
            this.spinner.hide();
            if(res.status) {
              this.sweetAlert.successMessage(this.lang.transform('lang_transaction_deleted_successfully'));
              this.get_form_lists();
            } else {
              this.sweetAlert.errorMessage(res.error);
            }
          }, (err) => {
            this.spinner.hide();
          })
        }else{
          this.ds.deleteActionById(list?.transaction_request_id, `form/${list?.transaction_key}/dr`).subscribe(res => {
            this.spinner.hide();
            if(res.status) {
              this.sweetAlert.successMessage(this.lang.transform('lang_transaction_deleted_successfully'));
              this.get_form_lists();
            } else {
              this.sweetAlert.errorMessage(res.error);
            }
          }, (err) => {
            this.spinner.hide();
          })
        }

    }});
  }
}
