import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddTicketComponent } from './add-ticket/add-ticket.component';
import { TicketListComponent } from './ticket-list/ticket-list.component';
import { ViewTicketComponent } from './view-ticket/view-ticket.component';

const routes: Routes = [
  {
      path: 'alltasks',
      component: TicketListComponent
  },
  {
      path: 'view/:ticket_id',
      component: ViewTicketComponent,
  },
  {
    path: 'edit/:ticket_id',
    component: ViewTicketComponent,
  },
  {
    path: 'create_ticket',
    component: AddTicketComponent,
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TasksRoutingModule { }
