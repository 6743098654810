<h3 mat-dialog-title>{{ "lang_sorting_order" | language }}</h3>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="sortForm">
		<div class="row">
			<div class="col-12">
				<label for="form_request_id">{{
					"lang_transaction_number" | language
				}}</label>
				<ng-select
					[items]="form_request_id_arr"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					formControlName="form_request_id"
					placeholder="{{ 'lang_transaction_number' | language }}"
				></ng-select>
			</div>
			<div class="col-12 mt-1">
				<label for="template_id">{{ "lang_template_id" | language }}</label>
				<ng-select
					[items]="template_id_arr"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					formControlName="template_id"
					placeholder="{{ 'lang_template_id' | language }}"
				></ng-select>
			</div>

			<div class="col-12 mt-1">
				<label for="platform_code_system">{{
					"lang_platform_code_system" | language
				}}</label>
				<ng-select
					[items]="platform_code_system_arr"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					formControlName="platform_code_system"
					placeholder="{{ 'lang_platform_code_system' | language }}"
				></ng-select>
			</div>
			<div class="col-12 mt-1">
				<label for="zone">{{ "lang_zone" | language }}</label>
				<ng-select
					[items]="zone_arr"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					formControlName="zone"
					placeholder="{{ 'lang_zone' | language }}"
				></ng-select>
			</div>
			<div class="col-12 mt-1">
				<label for="block">{{ "lang_blocks" | language }}</label>
				<ng-select
					[items]="block_arr"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					formControlName="block"
					placeholder="{{ 'lang_blocks' | language }}"
				></ng-select>
			</div>
			<div class="col-12 mt-1">
				<label for="cluster">{{ "lang_clusters" | language }}</label>
				<ng-select
					[items]="cluster_arr"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					formControlName="cluster"
					placeholder="{{ 'lang_clusters' | language }}"
				>
				</ng-select>
			</div>

			<div class="col-12 mt-1">
				<label for="barcode">{{ "lang_barcode" | language }}</label>
				<ng-select
					[items]="barcode_arr"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					appendTo="body"
					clearAllText="Clear"
					formControlName="barcode"
					placeholder="{{ 'lang_barcode' | language }}"
				>
				</ng-select>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="d-flex justify-content-between" style="width: 100%">
		<div style="width: 100px">
			<button type="button" class="albutton" (click)="resetSortForm()">
				{{ "lang_reset" | language }}
			</button>
		</div>

		<div style="width: 100px">
			<button
				mat-button
				class="albutton mnabrbutton mat-accent"
				(click)="sendSortForm()"
			>
				{{ "lang_save" | language }}
			</button>
		</div>
	</div>
</mat-dialog-actions>
