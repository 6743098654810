<div class="card appeducations">                                          
    <div class="card-header">{{'lang_human_resources_education_details' | language }} </div>
    <div class="card-body p-0">
      <div class="mbrl15 martb0">
          <div class="row">
              <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                  <div>{{'lang_human_resources_graduation' | language }}</div>
                  <div>
                      <b *ngIf="user_data.primary_education_level == 'SL'">{{'lang_human_resources_belowsslc' | language }}</b>
                      <b *ngIf="user_data.primary_education_level == 'SS'">{{'lang_human_resources_sslc' | language }}</b>
                      <b *ngIf="user_data.primary_education_level == 'HS'">{{'lang_human_resources_hsc' | language }}</b>
                      <b *ngIf="user_data.primary_education_level == 'DP'">{{'lang_human_resources_diploma' | language }}</b>
                      <b *ngIf="user_data.primary_education_level == 'UG'">{{'lang_human_resources_bachelor' | language }}</b>
                      <b *ngIf="user_data.primary_education_level == 'PG'">{{'lang_human_resources_pg' | language }}</b>
                      <b *ngIf="user_data.primary_education_level == 'DC'">{{'lang_human_resources_doc' | language }}</b>
                  </div>
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                  <div>{{'lang_human_resources_graduation_year' | language }}</div>
                  <div><b>{{user_data.primary_graduation_year}}</b></div>
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                  <div [ngClass]="{'red pad0': !user_data.membership_number}"> {{'lang_membership_number' | language }}</div>
                  <div [ngClass]="{'red pad0': !user_data.membership_number}">
                      <b *ngIf="user_data.membership_number">{{user_data.membership_number}}</b>
                      <b *ngIf="!user_data.membership_number">-</b>
                  </div>
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                  <div [ngClass]="{'red pad0': !user_data.membership_expiry_date_english}"> {{'lang_expiry_date' | language }}</div>
                  <div [ngClass]="{'red pad0': !user_data.membership_expiry_date_english}">
                      <b *ngIf="user_data.membership_expiry_date_english">{{user_data.membership_expiry_date_english}} - {{user_data.membership_expiry_date_arabic}}</b>
                      <b *ngIf="!user_data.membership_expiry_date_english"> - </b>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="input-group input-group-sm mb-10 col-12">
                  <input type="text" class="form-control almnabrformcontrol" (keyup)="search_my_education();" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                  <span class="input-group-btn" *ngIf="(user_data.employee_status == '1' && able2add) || requestfor == 'profile'">
                      <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" id="btnSearch" type="button" (click)="openModalA(educationformCU);emptyeducationform();" matTooltip="{{'lang_add_education' | language }}"></button>
                  </span>
                  <span class="input-group-btn" *ngIf="requestfor !== 'profile' && user_data.employee_status == '1' && able2add">
                      <button class="btnSearch h34 btn btn-md btn-gray fa fa-upload mnabricon" id="btnSearch" type="button" (click)="openModalFile(addNeedUploads);emptyAttachForm('EN0001');" matTooltip="{{'lang_upload_attachments' | language }}"></button>
                  </span>
                  <span class="input-group-btn" *ngIf="attachments.en0001">
                      <button class="btnSearch h34 btn btn-md btn-gray fa fa-paperclip mnabricon" id="btnSearch" type="button" (click)="ds.previewData(attachments.en0001);" matTooltip="{{'lang_recent_attachment' | language }} : {{attachments.en0001_d}}"></button>
                  </span>
              </div>
          </div>
          <div class="row">
              <div class="table-responsive dropdownyes customResponsive col-12">
                  <table border="1" class="table CustomTable" *ngIf="edudata.length != '0'">
                      <thead>
                          <tr>
                              <td>#</td>
                              <td *ngIf="requestfor !== 'profile' ">
                                  <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                      <input type="checkbox" class="allItemsEdu checkbox" id="allItemsEdu" (click)="checkAllEdu($event)"/>
                                      <label for="checkbox"></label>
                                  </div>
                              </td>
                              <td>{{'lang_education_title' | language }}</td>
                              <td>{{'lang_description' | language }}</td>
                              <td>{{'lang_duration' | language }}</td>
                              <td>{{'lang_writer' | language }}</td>
                              <td>{{'lang_ondate' | language }}</td>
                              <td>{{'lang_action' | language }}</td>
                          </tr>
                      </thead>
                      <tbody *ngIf="edudata.length != '0'" class="AppendList">
                          <tr *ngFor="let edu of edudata.records; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{edu.education_id}}">
                              <td>{{i+1}}</td>
                              <td *ngIf="requestfor !== 'profile' ">
                                  <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                      <input type="checkbox"  class="eachItemEdu checkbox" value="{{edu.education_id}}" (click)="checkEachItemEdu($event)"/>
                                      <label for="checkbox"></label>
                                  </div>
                              </td>
                              <td>
                                  <span class="breakall">{{edu.education_title}}</span>
                              </td>
                              <td>
                                  <span class="breakall">{{ds.splitwords(edu.education_descriptions, '3') }}</span>
                              </td>
                              <td *ngIf="edu.education_start_date && edu.education_end_date">
                                  <span class="breakall">{{edu.education_start_date}} {{'lang_to' | language }} {{edu.education_end_date}} </span>
                              </td>
                              <td *ngIf="!edu.education_end_date">
                                  <span class="breakall">{{edu.education_start_date}} - {{'lang_still' | language }} </span>
                              </td>
                              <td *ngIf="!edu.name">{{edu.username}}</td>
                              <td *ngIf="edu.name">{{edu.name}}</td>
                              <td>{{edu.education_createddatetime}}</td>
                              <td>
                                  <i matTooltip="{{'lang_view' | language }}" class="fa fa-eye fa-icon mnabricon s2icon" (click)="openModalV(viewEducationModal);view_my_education(edu)"></i>
                                  <i *ngIf="(able2edit || ownedit) && user_data.employee_status == '1' " matTooltip="{{'lang_edit' | language }}" class="fa fa-pencil-square-o fa-icon mnabricon s2icon" (click)="openModalA(educationformCU);emptyeducationform(); edit_education(edu)"></i>
                                  <i *ngIf="edu.education_certification_file" class="fa fa-paperclip fa-icon mnabricon s2icon" (click)="ds.previewData(edu.education_certification_file);"></i>
                              </td>
                          </tr>
                      </tbody>
                    <!--   <tbody *ngIf="edudata.length == '0'" class="AppendList">
                          <tr class="odd">
                              <td colspan="8" align="center">{{eduerrormsglist}}</td>
                          </tr>
                      </tbody> -->
                  </table>
                  <no-data style="margin: auto;"  [Opened]="edudata.length < 1 "></no-data>

              </div>
          </div>
      </div>
      <div *ngIf="edudata.length != '0'" class="card-footer padb0 bottomfooter">
          <div class="row">
              <div *ngIf="able2delete && requestfor !== 'profile' " class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                  <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataEdu()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
              </div>
              <div class="col-lg-2 col-sm-6 col-6  mb-10">
                  {{'lang_total' | language }} : {{edudata.page.total_records}}
              </div>
              <div class="col-lg-5 col-sm-6 col-12 mb-10">
                  <mat-radio-group aria-label="Select an option">
                      <mat-radio-button value="10" (click)="spinner.show();edupage(10)" checked> 10 </mat-radio-button>
                      <mat-radio-button value="20" (click)="spinner.show();edupage(20)"> 20 </mat-radio-button>
                      <mat-radio-button value="50" (click)="spinner.show();edupage(50)"> 50 </mat-radio-button>
                      <mat-radio-button value="100" (click)="spinner.show();edupage(100)"> 100 </mat-radio-button>
                      <mat-radio-button value="500" (click)="spinner.show();edupage(500)"> 500 </mat-radio-button>
                  </mat-radio-group>
              </div>
              <div class="col-lg-4 col-sm-6 col-12 mb-10">
                  {{'lang_goto' | language }} :
                  <select class="customselect" (change)="spinner.show();edulength($event.target.value)">
                      <option *ngFor="let k of ds.getrange(edudata.page.total_pages)" value="{{k}}">{{k}}
                      </option>
                  </select>
              </div>
          </div>
      </div>
    </div>
</div>
<ng-template #educationformCU>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_human_resources_education_details' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefA.hide();">&times;</button>
        </div>
        <form [formGroup]="educationform" (ngSubmit)="education_operations()">
            <div class="modal-body">
                <div class="form-group">
                    <label for="education_title">{{'lang_education_title' | language }}:<span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_education_title' | language }}" formControlName="education_title" />
                    <mat-error *ngIf="educationform.controls['education_title'].touched && educationform.controls['education_title'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="education_descriptions">{{'lang_description' | language }}:<span class="red">*</span></label>
                    <textarea type="text" class="form-control almnabrformcontrol" placeholder="{{'lang_description' | language }}" formControlName="education_descriptions"></textarea>
                    <mat-error *ngIf="educationform.controls['education_descriptions'].touched && educationform.controls['education_descriptions'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="education_start_date">{{'lang_start_date' | language }}:<span class="red">*</span></label>
                    <div class="input-group input-group-sm">
                        <input [matDatepicker]="education_start_date" formControlName="education_start_date" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly>
                        <span class="input-group-btn">
                            <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="education_start_date"></mat-datepicker-toggle>
                            <mat-datepicker #education_start_date></mat-datepicker>
                        </span>
                        <span class="input-group-btn">
                            <button (click)="educationform.get('education_start_date').setValue('');" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                        </span>
                    </div>
                    <mat-error *ngIf="educationform.controls['education_start_date'].touched && educationform.controls['education_start_date'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="education_end_date">{{'lang_end_date' | language }}:</label>
                    <div class="input-group input-group-sm">
                        <input [matDatepicker]="education_end_date" formControlName="education_end_date" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly>
                        <span class="input-group-btn">
                            <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="education_end_date"></mat-datepicker-toggle>
                            <mat-datepicker #education_end_date></mat-datepicker>
                        </span>
                        <span class="input-group-btn">
                            <button (click)="educationform.get('education_end_date').setValue('');" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                        </span>
                    </div>
                </div>
                <div class="form-group none">
                    <i *ngIf="isFile" aria-hidden="true" matTooltip="{{'lang_delete' | language }}" (click)="removeFile()" class="fa faicon fa-close red"></i>
                    <div class="IDDIV">
                        <input name="education_file" type="file" onclick="this.value = null" (change)="uploadEDUFile($event)" class="nghide albutton width100">
                        <label id="uploadIDButton" class="albutton">{{filename}} </label>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefA.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #viewEducationModal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_human_resources_education_details' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefV.hide();">&times;</button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_education_title' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{eduviewdata.education_title}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_description' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{eduviewdata.education_descriptions}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_start_date' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{eduviewdata.education_start_date}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_end_date' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngIf="eduviewdata.education_end_date">{{eduviewdata.education_end_date}}</b>
                        <b class="breakall" *ngIf="!eduviewdata.education_end_date">{{'lang_still' | language }}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_writer' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngIf="eduviewdata.name">{{eduviewdata.name}}</b>
                        <b class="breakall" *ngIf="!eduviewdata.name">{{eduviewdata.username}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_created_date' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{eduviewdata.education_createddatetime}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_onupdate' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{eduviewdata.education_updateddatetime}}</b>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal"  (click)="modalRefV.hide();">{{'lang_close' | language }}</button>
        </div>
    </div>
</ng-template>
<ng-template #addNeedUploads>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_attachments' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefF.hide();">&times;</button>
        </div>
        <form [formGroup]="attachform" (ngSubmit)="upload_files()">
            <div class="modal-body">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="attachment_descriptions">{{'lang_description' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" formControlName="attachment_descriptions" class="form-control almnabrformcontrol" placeholder="{{'lang_description' | language }}" />
                            <mat-error *ngIf="attachform.controls['attachment_descriptions'].touched && attachform.controls['attachment_descriptions'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="level_keys">{{'lang_file_secutrity_levels' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <ng-select 
                                [items]="filepermissionlevels" 
                                [multiple]="true" 
                                bindLabel="label" 
                                appendTo="body" 
                                bindValue="value" 
                                clearAllText="Clear"
                                placeholder="{{'lang_file_secutrity_levels' | language}}"
                                formControlName="level_keys">
                            </ng-select>
                            <mat-error *ngIf="attachform.controls['level_keys'].touched && attachform.controls['level_keys'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="attachment_link">{{'lang_select_files' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <div class="IDDIV">
                                    <input name="attachment_link" type="file" required onclick="this.value = null" (change)="uploadAttachFile($event)" class="nghide albutton width100">
                                    <label id="uploadIDButton" class="albutton changeFileName">{{idfilename}} </label>
                                    <mat-error *ngIf="attachform.controls['attachment_link'].touched && attachform.controls['attachment_link'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefF.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>