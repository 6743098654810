import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-cs-attachments',
  templateUrl: './cs-attachments.component.html',
  styleUrls: ['./cs-attachments.component.scss']
})
export class CsAttachmentsComponent implements OnInit {
  modalRefA: BsModalRef;
	modalRefE: BsModalRef;
	@Input() customer_id;
	@Input() type;
	@Input() able2add;
	@Input() able2edit;
	@Input() able2delete;
	@Input() customer_data;
	typelists: any = [];
	selectedItems: any = [];
	filterlists: any = [{
			"label": this.lang.transform('lang_communications'),
			"value": 'communications'
		},

		{
			"label": this.lang.transform('lang_subject'),
			"value": 'attachment_descriptions'
		},
		{
			"label": this.lang.transform('lang_organisation'),
			"value": 'organisation'
		},
		{
			"label": this.lang.transform('lang_form_incoming'),
			"value": 'incoming'
		},
		{
			"label": this.lang.transform('lang_form_outgoing'),
			"value": 'outgoing'
		},
		{
			"label": this.lang.transform('lang_document_number'),
			"value": 'barcode'
		}
	];
	apage = 1;
	apsize = 10;
	myattachments: any = [];
	idfilename = this.lang.transform('lang_select_files');
	attacherrormsg: string = this.lang.transform('lang_loading');
	attachdata: any = [];
	attachviewdata: any = []
	attacheditdata: any = []
	updatestatus: any = false;
	updateerror: any = "";
	formstatus: boolean = false;
	formerror: string = "";
	attachform: FormGroup;
	types: any = [];
	searchKey = "";
	searchBy = null;
	attachmentType = null;
	uform: FormGroup;
	filepermissionlevels:any = [];
	constructor(public ds: DataService, public router: Router, public route: ActivatedRoute, public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
	ngOnInit(): void {
		this.attach_form();
		this.file_permission_levels();
		this.uform = new FormGroup({
			customer_id               	: new FormControl('', [Validators.required]),
			file_records_id           	: new FormControl('', [Validators.required]),
			attachment_descriptions   	: new FormControl('', [Validators.required]),
			level_keys   				: new FormControl('', [Validators.required]),
		});
		this.attachment_types();
		this.get_attachments();
	}
  	openModalA(template: TemplateRef < any > ) {
		this.modalRefA = this.modalService.show(template, environment.modelconfig);
	}
  	openModalE(template: TemplateRef < any > ) {
		this.modalRefE = this.modalService.show(template, environment.modelconfig);
	}
	public attachment_types() {
		this.types = [];
		this.ds.getActionByUrl([], "module_attach_types/?module_name=customers").subscribe(res => {
			if (res.status) {
				this.types = res.records;
			}
		});
	}
	public file_permission_levels() {
		this.filepermissionlevels = [];		
		this.ds.getActionByUrl([], "lflevel").subscribe(res => {
			if (res.status) {
				this.filepermissionlevels = res.records;
			}
		});
	}
	public attachpage(size) {
		this.apsize = size;
		this.apage = 1;
		this.get_attachments();
	}
	public attachlength(page) {
		this.apage = page;
		this.get_attachments();
	}
	public search_my_attachments() {
		this.apage = 1;
		this.apsize = 10;
		this.get_attachments();
	}
	public get_attachments(): void {
		let formdata = new FormData();
		formdata.append('customer_id', this.customer_id);
		formdata.append('search_key', this.searchKey);
		formdata.append('attachmentType', (this.attachmentType) ? this.attachmentType.toString() : '');
		formdata.append('searchBy', (this.searchBy) ? this.searchBy.toString() : '');
		this.ds.postActionByUrl(formdata, "cs/aget/" + this.apage + '/' + this.apsize).subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.myattachments    = res.records;
				this.attachdata       = res;
			} else {
				this.myattachments    = [];
				this.attachdata       = [];
				this.attacherrormsg   = res.error;
			}
		}, error => {
			this.spinner.hide();
			this.myattachments      = [];
			this.attachdata         = [];
			this.attacherrormsg     = error.error;
		})
	}
	public view_my_Attach(attach) {
		this.attachviewdata = attach;
	}
	public emptyAttachForm() {
		this.attachform.reset();
		this.attachform.get("customer_id").setValue(this.customer_id);
		this.idfilename = this.lang.transform('lang_select_files');
	}
	public edit_my_Attach(attach) {
		this.attacheditdata   = attach;
		this.uform.patchValue({
			customer_id               	: this.customer_id,
			file_records_id           	: attach.file_records_id,
			level_keys					: attach.level_keys.split(','),
			attachment_descriptions   	: attach.file_name,
		});
	}
	public attach_form() {
		this.attachform = new FormGroup({
			customer_id               	: new FormControl(this.customer_id, [Validators.required]),
			attachment_type           	: new FormControl("", [Validators.required]),
			attachment_descriptions   	: new FormControl("", [Validators.required]),
			attachment_link           	: new FormControl("", [Validators.required]),
			level_keys   				: new FormControl('', [Validators.required]),
		});
	}
	public uploadIDFile(event) {
		if (
			event.target.files[0] &&
			event.target.files[0] !== undefined &&
			event.target.files[0].name
		) {
			const fileid = event.target.files[0];
			this.idfilename = fileid.name;
			this.attachform.get("attachment_link").setValue(fileid, {
				emitEvent: true
			});
		} else {
			this.attachform.get("attachment_link").setValue(null);
			this.idfilename = this.lang.transform('lang_select_files');
		}
	}
	public upload_files() {
		this.spinner.show();
		this.ds.postActionByUrl(this.ds.json2formdata(this.attachform.value), 'cs/aupload').subscribe(data => {
			this.spinner.hide();
			if (data.status) {
				this.attachform.reset();
				this.get_attachments();
				setTimeout(() => {
					this.modalRefA.hide();
				}, 1000);
				this.alert.success(data.msg);
			} else {
				this.alert.error(data.error);
			}
		}, error => {
			this.spinner.hide();
			this.alert.error(error.error + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
		})
	}
	public update_file_name() {
		this.spinner.show();
		this.ds.putActionByUrl(this.ds.formData2string(this.ds.json2formdata(this.uform.value)), 'cs/aupdate').subscribe(data => {
			this.spinner.hide();
			if (data.status) {
				this.get_attachments();
				setTimeout(() => {
					this.modalRefE.hide();
				}, 1000);
				this.alert.success(data.msg);
			} else {
				this.alert.error(data.error);
			}
		}, error => {
			this.spinner.hide();
			this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
		})
	}
	public checkAllItemsAth(event) {
		if (event.target.checked) {
			$(".attach_panel .eachItemAth").prop("checked", true);
			$(".attach_panel .makeback").addClass("bground");
			$(".attach_panel .deleteIconDiv").show();
		} else {
			$(".attach_panel .eachItemAth").prop("checked", false);
			$(".attach_panel .makeback").removeClass("bground");
			$(".attach_panel .deleteIconDiv").hide();
		}
	}
	public checkEachItemAth(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".attach_panel .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".attach_panel .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".attach_panel .eachItemAth:checked").each(function() {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".attach_panel .eachItemAth").each(function() {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".attach_panel .deleteIconDiv").show();
		} else {
			$(".attach_panel .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".attach_panel #allItemsAth").prop("checked", true);
		} else {
			$(".attach_panel #allItemsAth").prop("checked", false);
		}
	}
	public deleteBulkDataAth() {
		const checkedtotal = [];
		$(".attach_panel .eachItemAth:checked").each(function() {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: '400px',
				data: {
					title: this.lang.transform('lang_confirmation'),
					message: this.lang.transform('lang_are_you_sure'),
				}
			});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.spinner.show()
					this.ds.deleteActionByUrl(checkedtotal, 'cs/sdelete/ATTACHMENT/' + this.customer_id).subscribe(
						(data) => {
							this.spinner.hide()
							if (data.status) {
								this.search_my_attachments();
								$(".attach_panel #allItemsAth").prop("checked", false);
								$(".attach_panel .deleteIconDiv").hide();
								this.ds.dialogf('', data.msg);
							} else {
								this.ds.dialogf('', data.error);
							}
						},
						(error) => {
							this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
							this.spinner.hide()
						}
					);
				}
			});
		} else {
			this.ds.dialogf();
		}
	}
}
