import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { FinanceyearComponent } from './financeyear/financeyear.component';
import { InvoiceSettingsComponent } from './invoice-settings/invoice-settings.component';
import { PermitSettingsComponent } from './permit-settings/permit-settings.component';
import { TaxSettingsComponent } from './tax-settings/tax-settings.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild(AccountSettingsComponent) account: AccountSettingsComponent;
  @ViewChild(InvoiceSettingsComponent) invoice: InvoiceSettingsComponent;
  @ViewChild(TaxSettingsComponent) tax: TaxSettingsComponent;
  @ViewChild(PermitSettingsComponent) permit : PermitSettingsComponent;
  @ViewChild(FinanceyearComponent) financial: FinanceyearComponent;
  
  branches:any = [];
  selectedBranch = localStorage.getItem('selectedBranch');
  taxform: FormGroup;
  constructor(public ds:DataService, public spinner:PreloaderService) { }
  ngOnInit(): void {
    this.load_branches();
  }
  public load_branches() {
    this.branches = [];
    this.ds.getActionByUrl([], 'ab/accounts_view').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.branches = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public selectBranch(branch) {
    if (branch && branch != '0') {
      localStorage.setItem('selectedBranch', branch);
      this.selectedBranch = branch;
      this.account.changeBranch(branch);
      this.permit.changeBranch(branch);
      this.tax.changeBranch(branch);
      this.invoice.changeBranch(branch);
      this.financial.changeBranch(branch);
    }
  }
}
