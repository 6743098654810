<h2 mat-dialog-title>{{ "lang_by_phases" | language }}</h2>
<mat-dialog-content class="mat-typography">
	<div class="container" [formGroup]="work_sites_levels_form">
		<div class="row">
			<div class="col-sm-6 col-xs-12 mb-10">
				<label>{{ "lang_zone" | language }} : <span class="red">*</span></label>
				<ng-select
					[items]="zones"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					clearAllText="Clear"
					[searchFn]="ds.customSearchFn"
					appendTo="body"
					(search)="search_required_datas($event, 'Z')"
					formControlName="work_site_zones"
					placeholder="{{ 'lang_zone' | language }}"
					(change)="
						work_sites_levels_form.get('work_site_blocks').setValue(null);
						work_sites_levels_form.get('work_site_clusters').setValue(null);
						work_sites_levels_form.get('work_site_units').setValue(null);
						search_required_datas(null, 'B')
					"
				>
				</ng-select>
				<mat-error
					*ngIf="
						work_sites_levels_form.controls['work_site_zones'].errors?.required
					"
					>{{ "lang_field_required" | language }}</mat-error
				>
			</div>
			<div
				class="col-sm-6 col-xs-12 mb-10"
				*ngIf="
					work_sites_levels_form.get('work_site_zones').value &&
					work_sites_levels_form.get('work_site_zones').value.length > 0
				"
			>
				<label
					>{{ "lang_blocks" | language }} : <span class="red">*</span></label
				>
				<ng-select
					[items]="blocks"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					clearAllText="Clear"
					[searchFn]="ds.customSearchFn"
					appendTo="body"
					(search)="search_required_datas($event, 'B')"
					formControlName="work_site_blocks"
					placeholder="{{ 'lang_blocks' | language }}"
					(change)="
						work_sites_levels_form.get('work_site_clusters').setValue(null);
						work_sites_levels_form.get('work_site_units').setValue(null);
						search_required_datas(null, 'C')
					"
				>
				</ng-select>
				<mat-error
					*ngIf="
						work_sites_levels_form.controls['work_site_blocks'].errors?.required
					"
					>{{ "lang_field_required" | language }}</mat-error
				>
			</div>
			<div
				class="col-sm-6 col-xs-12 mb-10"
				*ngIf="
					work_sites_levels_form.get('work_site_zones').value &&
					work_sites_levels_form.get('work_site_zones').value.length > 0 &&
					work_sites_levels_form.get('work_site_blocks').value &&
					work_sites_levels_form.get('work_site_blocks').value.length > 0
				"
			>
				<label
					>{{ "lang_clusters" | language }} : <span class="red">*</span></label
				>
				<ng-select
					[items]="clusters"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					clearAllText="Clear"
					[searchFn]="ds.customSearchFn"
					appendTo="body"
					(search)="search_required_datas($event, 'C')"
					formControlName="work_site_clusters"
					placeholder="{{ 'lang_clusters' | language }}"
					(change)="
						work_sites_levels_form.get('work_site_units').setValue(null);
						search_required_datas(null, 'U')
					"
				>
				</ng-select>
				<mat-error
					*ngIf="
						work_sites_levels_form.controls['work_site_clusters'].errors
							?.required
					"
					>{{ "lang_field_required" | language }}</mat-error
				>
			</div>
			<div
				class="col-sm-6 col-xs-12 mb-10"
				*ngIf="
					work_sites_levels_form.get('work_site_zones').value &&
					work_sites_levels_form.get('work_site_zones').value.length > 0 &&
					work_sites_levels_form.get('work_site_blocks').value &&
					work_sites_levels_form.get('work_site_blocks').value.length > 0 &&
					work_sites_levels_form.get('work_site_clusters').value &&
					work_sites_levels_form.get('work_site_clusters').value.length > 0
				"
			>
				<label
					>{{ "lang_units" | language }} : <span class="red">*</span></label
				>
				<ng-select
					[items]="units"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					clearAllText="Clear"
					[searchFn]="ds.customSearchFn"
					(search)="search_required_datas($event, 'U')"
					appendTo="body"
					formControlName="work_site_units"
					placeholder="{{ 'lang_units' | language }}"
					(change)="get_work_levels()"
				>
				</ng-select>
				<mat-error
					*ngIf="
						work_sites_levels_form.controls['work_site_units'].errors?.required
					"
					>{{ "lang_field_required" | language }}</mat-error
				>
			</div>
			<div
				class="col-sm-12 col-xs-12 mb-10 mt-2"
				*ngIf="
					work_sites_levels_form.get('work_site_zones').value &&
					work_sites_levels_form.get('work_site_zones').value.length > 0 &&
					work_sites_levels_form.get('work_site_blocks').value &&
					work_sites_levels_form.get('work_site_blocks').value.length > 0 &&
					work_sites_levels_form.get('work_site_clusters').value &&
					work_sites_levels_form.get('work_site_clusters').value.length > 0 &&
					work_sites_levels_form.get('work_site_units').value &&
					work_sites_levels_form.get('work_site_units').value.length > 0
				"
			>
				<label class="mb-10"
					>{{ "lang_fill_worklevels" | language }} :
					<span class="red">*</span></label
				>

				<ng-select
					[items]="work_levels"
					[multiple]="true"
					bindLabel="label"
					bindValue="value"
					(change)="checkValidWorkLevel()"
					clearAllText="Clear"
					[searchFn]="ds.customSearchFn"
					appendTo="body"
					formControlName="work_levels"
					placeholder="{{ 'lang_fill_worklevels' | language }}"
				>
				</ng-select>
				<mat-error
					*ngIf="
						work_sites_levels_form.controls['work_levels'].errors?.required
					"
					>{{ "lang_field_required" | language }}</mat-error
				>
			</div>
			<div class="col-sm-12">
				<app-alert></app-alert>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="d-flex justify-content-between" style="width: 100%">
		<div style="width: 150px">
			<button
				mat-button
				mat-dialog-close
				class="albutton mnabrbutton mat-accent"
			>
				{{ "lang_cancel" | language }}
			</button>
		</div>

		<div style="width: 150px">
			<button
				mat-button
				class="albutton mnabrbutton mat-accent"
				[disabled]="!work_sites_levels_form.valid"
				(click)="onSubmit()"
			>
				{{ "lang_submit" | language }}
			</button>
		</div>
	</div>
</mat-dialog-actions>
