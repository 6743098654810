<section class="panel-expansion matborderno" *ngIf="branch_id && branch_id !='0' ">
    <mat-accordion class="accounts_settings" >
        <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_account_settings' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <select class="form-control almnabrformcontrol search_type" [(ngModel)]="searchType" (change)="spinner.show();load_account_settings();" matTooltip="{{'lang_search_type' | language }}" id="search_type">
                                <option value="">{{'lang_all' | language }}</option>
                                <option *ngFor="let type of accounttypes" value="{{type.value}}">{{type.label}}</option>
                            </select>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 mb-10">
                            <div class="input-group input-group-sm">
                                <input type="text" placeholder="{{'lang_search' | language }}" [(ngModel)]="searchKey" (keyup)="load_account_settings();" class="form-control almnabrformcontrol search_key" id="search_key" />
                                <span class="input-group-btn">
                                    <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" (click)="emptyformdata();openModal(SettingsOperation);form.get('cost_center_required').setValue('');" id="btnSearch" type="button" matTooltip="{{'lang_add_settings' | language }}"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsSet checkbox" id="allItemsSet" (click)="checkAllItemsSet($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_title' | language }}</td>
                                            <td>{{'lang_type' | language }}</td>
                                            <td>{{'lang_status' | language }}</td>
                                            <td>{{'lang_ondate' | language }}</td>
                                            <td>{{'lang_writer' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="asdata.length != '0' " class="AppendList">
                                        <tr *ngFor="let data of asdata.records; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{data.account_setting_id}}">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemSet checkbox" value="{{data.account_setting_id}}" (click)="checkEachItemSet($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{data.title}}</td>
                                            <td *ngIf="data.setting_type_code =='ACC' ">{{'lang_accounts_types' | language }}</td>
                                            <td *ngIf="data.setting_type_code =='BLS' ">
                                                {{'lang_balance_sheets' | language }}
                                            </td>
                                            <td *ngIf="data.setting_type_code =='CUR' ">{{'lang_currencies' | language }}</td>
                                            <td *ngIf="data.setting_type_code =='TAX' ">{{'lang_tax' | language }}</td>
                                            <td *ngIf="data.setting_type_code =='INV' ">{{'lang_invoice' | language }}</td>
                                            <td *ngIf="data.setting_type_code =='PAL' ">{{'lang_profit_loss_acc' | language }}</td>
                                            <td *ngIf="!data.setting_type_code ">----</td>
                                            <td *ngIf="data.setting_status == '0'">
                                                <i class="fa fa-icon fa-circle red" matTooltip="{{'lang_inactive' | language }}" aria-hidden="true"></i>
                                            </td>
                                            <td *ngIf="data.setting_status == '1'">
                                                <i class="fa fa-icon fa-circle green" matTooltip="{{'lang_active' | language }}" aria-hidden="true"></i>
                                            </td>
                                            <td>{{data.setting_created_datetime}}</td>
                                            <td>{{data.name}}</td>
                                            <td>
                                                <i class="fa fa-eye faicon mnabricon s2icon" matTooltip="{{'lang_view' | language }}" (click)="showASData(data);"></i>
                                                <i class="fa fa-pencil-square-o faicon mnabricon s2icon" (click)="editASData(data.account_setting_id);" matTooltip="{{'lang_edit' | language }}"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="asdata.length == '0' " class="AppendList">
                                        <tr class="odd">
                                            <td colspan="8" align="center">{{lodingdatas}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="asdata.length != '0' " class="card-footer padb0">
                    <div class="row">
                        <div *ngIf="able2delete" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataSet()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-6 mb-10">
                            {{'lang_total' | language }} : {{asdata.page.total_records}}
                        </div>
                        <div class="col-lg-5 col-sm-6 mb-10">
                            <mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="10" checked (click)="loadapagesize(10)"> 10 </mat-radio-button>
                                <mat-radio-button value="20" (click)="loadapagesize(20)"> 20 </mat-radio-button>
                                <mat-radio-button value="50" (click)="loadapagesize(50)"> 50 </mat-radio-button>
                                <mat-radio-button value="100" (click)="loadapagesize(100)"> 100 </mat-radio-button>
                                <mat-radio-button value="500" (click)="loadapagesize(500)"> 500 </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            {{'lang_goto' | language }} :
                            <select class="customselect" (change)="loadpageas($event.target.value)">
                                <option *ngFor="let ks of ds.getrange(asdata.page.total_pages)" value="{{ks}}">{{ks}} </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
<ng-template #SettingsOperation>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_account_settings' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRef.hide();">&times;</button>
        </div>
        <form [formGroup]="form" class="from" (ngSubmit)="submit();">
            <div class="modal-body">
                <input type="hidden" formControlName="account_setting_id" />
                <div class="form-group">
                    <label for="setting_type_code">{{'lang_type' | language }}:<span class="red">*</span></label>
                    <select #setting_type_code type="text" required id="setting_type_code" (ngModelChange)="emptyvalue(setting_type_code.value)" class="form-control almnabrformcontrol setting_type_code" matTooltip="{{'lang_type' | language }}" formControlName="setting_type_code">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option *ngFor="let type of accounttypes" value="{{type.value}}">{{type.label}}</option>
                    </select>
                    <mat-error *ngIf="form.controls['setting_type_code'].touched && form.controls['setting_type_code'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group" *ngIf="form.get('setting_type_code').value == 'ACC' ">
                    <label for="cost_center_required">{{'lang_accounts_cost_center_support' | language }}:<span class="red">*</span></label>
                    <select type="text" required class="form-control almnabrformcontrol" matTooltip="{{'lang_accounts_cost_center_support' | language }}" formControlName="cost_center_required">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option value="1">{{'lang_yes' | language }}</option>
                        <option value="0">{{'lang_no' | language }}</option>
                    </select>
                </div>
                <div class="form-group" *ngIf="form.get('setting_type_code').value == 'BLS' ">
                    <label for="setting_parent">{{'lang_parent_account_type' | language }}:<span class="red">*</span></label>
                    <select type="text" #setting_parent required id="setting_parent" class="form-control almnabrformcontrol setting_parent" matTooltip="{{'lang_parent_account_type' | language }}" formControlName="setting_parent">
                        <option *ngFor="let type of types" value="{{type.value}}">{{type.label}}</option>
                    </select>
                    <mat-error *ngIf="form.controls['setting_parent'].touched && form.controls['setting_parent'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="setting_title_english">{{'lang_title_english' | language }}:<span class="red">*</span></label>
                    <input #lang_title_english type="text" required id="lang_title_english" class="form-control almnabrformcontrol setting_title_english" placeholder="{{'lang_title_english' | language }}" formControlName="setting_title_english" />
                    <mat-error *ngIf="form.controls['setting_title_english'].touched && form.controls['setting_title_english'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="setting_title_arabic">{{'lang_title_arabic' | language }}:<span class="red">*</span></label>
                    <input type="text" #setting_title_arabic required id="setting_title_arabic" class="form-control almnabrformcontrol setting_title_arabic" placeholder="{{'lang_title_arabic' | language }}" formControlName="setting_title_arabic" />
                    <mat-error *ngIf="form.controls['setting_title_arabic'].touched && form.controls['setting_title_arabic'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group" *ngIf="form.get('setting_type_code').value == 'TAX' ">
                    <label for="setting_tax_value">{{'lang_tax_amount' | language }}:<span class="red">*</span></label>
                    <input type="text" #setting_tax_value required id="setting_tax_value" class="form-control almnabrformcontrol setting_tax_value" placeholder="{{'lang_tax_amount' | language }}" formControlName="setting_tax_value" />
                    <mat-error *ngIf="form.controls['setting_tax_value'].touched && form.controls['setting_tax_value'].errors?.required">{{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group" *ngIf="form.get('setting_type_code').value == 'TAX' || form.get('setting_type_code').value == 'PAL' ">
                    <label *ngIf="form.get('setting_type_code').value == 'TAX'">
                        {{'lang_tax_accounts' | language }}: <i class="fa fa-question-circle fa-icon mnabricon" matTooltip="{{'lang_accounts_taxaccounts' | language }}" aria-hidden="true"></i>
                    </label>
                    <label *ngIf="form.get('setting_type_code').value == 'PAL'">
                        {{'lang_profit_loss_acc' | language }}: <i class="fa fa-question-circle fa-icon mnabricon" matTooltip="{{'lang_accounts_palaccounts' | language }}" aria-hidden="true"></i>
                    </label>
                    <angular2-multiselect formControlName="account_config" [data]="accounts" [settings]="settings">
                        <c-search>
                            <ng-template>
                                <input type="text" (keyup)="searchAccounts($event)" style="border: none; width: 100%; height: 100%; outline: none;" />
                            </ng-template>
                        </c-search>
                        <c-item>
                            <ng-template let-item="item">
                                <label *ngIf="item.label" style="color: #333; width: 150px;">{{item.label}}</label>
                            </ng-template>
                        </c-item>
                    </angular2-multiselect>
                </div>
                <div class="form-group">
                    <label for="setting_key_code">
                        {{'lang_unique_key' | language }}:<span class="red">*</span>
                        <i class="fa fa-question-circle fa-icon mnabricon" (click)="openModal2(ShowInfoModel)" matTooltip="{{'lang_account_info' | language }}"></i>
                    </label>
                    <input type="text" #setting_key_code required id="setting_key_code" class="form-control almnabrformcontrol setting_key_code" placeholder="{{'lang_unique_key' | language }}" formControlName="setting_key_code" />
                    <mat-error *ngIf="form.controls['setting_key_code'].touched && form.controls['setting_key_code'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
                <div class="form-group">
                    <label for="setting_status">{{'lang_status' | language }}:<span class="red">*</span></label>
                    <select #lang_title_english type="text" required id="setting_status" class="form-control almnabrformcontrol setting_status" matTooltip="{{'lang_status' | language }}" formControlName="setting_status">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option value="1">{{'lang_active' | language }}</option>
                        <option value="0">{{'lang_inactive' | language }}</option>
                    </select>
                    <mat-error *ngIf="form.controls['setting_status'].touched && form.controls['setting_status'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRef.hide()">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #viewsettings>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_account_settings' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRef1.hide();">&times;</button>
        </div>
        <div class="modal-body" *ngIf="asviewdata">
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_type' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b *ngIf="asviewdata.setting_type_code =='ACC' ">{{'lang_accounts_types' | language }}</b>
                        <b *ngIf="asviewdata.setting_type_code =='BLS' ">{{'lang_balance_sheets' | language }}</b>
                        <b *ngIf="asviewdata.setting_type_code =='CUR' ">{{'lang_currencies' | language }}</b>
                        <b *ngIf="asviewdata.setting_type_code =='TAX' ">{{'lang_tax' | language }}</b>
                        <b *ngIf="asviewdata.setting_type_code =='INV' ">{{'lang_invoice' | language }}</b>
                        <b *ngIf="asviewdata.setting_type_code =='PAL' ">{{'lang_profit_loss_acc' | language }}</b>
                        <b *ngIf="!asviewdata.setting_type_code ">----</b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="asviewdata.setting_type_code == 'ACC' ">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_accounts_cost_center_support' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b *ngIf="asviewdata.cost_center_required =='0' ">{{'lang_no' | language}}</b>
                        <b *ngIf="asviewdata.cost_center_required !='0' ">{{'lang_yes' | language}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_title_english' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{asviewdata.setting_title_english}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_title_arabic' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{asviewdata.setting_title_arabic}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="asviewdata.parent_title">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_parent_account_type' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b>{{asviewdata.parent_title}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_unique_key' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{asviewdata.setting_key_code}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="asviewdata.setting_type_code == 'TAX' ">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_tax' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{asviewdata.setting_tax_value}} %</b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="(asviewdata.setting_type_code == 'TAX' || asviewdata.setting_type_code == 'PAL') && asviewdata.setting_accounts_config">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12" *ngIf="asviewdata.setting_type_code == 'TAX' ">{{'lang_tax_accounts' | language }}:</label>
                    <label class="col-md-6 col-sm-6 col-xs-12" *ngIf="asviewdata.setting_type_code == 'PAL' ">{{'lang_profit_loss_acc' | language }}:</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngFor="let cc of asviewdata.setting_accounts_config">{{cc.label}}<br /></b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_status' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngIf="asviewdata.setting_status =='1' ">{{'lang_active' | language }}</b>
                        <b class="breakall" *ngIf="asviewdata.setting_status =='0' ">{{'lang_inactive' | language }}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_writer' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{asviewdata.name}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_created_date' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{asviewdata.setting_created_datetime}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="asviewdata.setting_updated_datetime">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_onupdate' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{asviewdata.setting_updated_datetime}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #ShowInfoModel>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_account_info' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRef2.hide()">&times;</button>
        </div>
        <div class="modal-bod">
            <div class="table-responsive customResponsive width100">
                <table class="table text-center" border="1">
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>{{'lang_title' | language }}</td>
                            <td>{{'lang_unique_key' | language }}</td>
                        </tr>
                    </thead>
                    <tbody id="MakeSmall">
                        <tr>
                            <td>1</td>
                            <td>Assets</td>
                            <td class="textSmall">assets</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Liabilities</td>
                            <td class="textSmall">liabilities</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Expenses</td>
                            <td class="textSmall">expanses</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Income</td>
                            <td class="textSmall">income</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Capital</td>
                            <td class="textSmall">capital</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Stock</td>
                            <td class="textSmall">stock</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Current Assets</td>
                            <td class="">cuass</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>Fixed Assets</td>
                            <td class="">fxass</td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>Intangible Assets</td>
                            <td class="">inass</td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>Current Liabilities</td>
                            <td class="">culi</td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>Long Term Liabilities</td>
                            <td class="">ltli</td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>Cost of goods purchased</td>
                            <td class="">cogps</td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td>Operating Expenses</td>
                            <td class="">opexe</td>
                        </tr>
                        <tr>
                            <td>14</td>
                            <td>Other Expenses</td>
                            <td class="">otexp</td>
                        </tr>
                        <tr>
                            <td>15</td>
                            <td>Investments</td>
                            <td class="">invest</td>
                        </tr>
                        <tr>
                            <td>16</td>
                            <td>Other Revenues</td>
                            <td class="">otrrvn</td>
                        </tr>
                        <tr>
                            <td>17</td>
                            <td>Tax Of Revenues</td>
                            <td class="">txorvn</td>
                        </tr>
                        <tr>
                            <td>18</td>
                            <td>Revenue Of Sales</td>
                            <td class="">rvnfsls</td>
                        </tr>
                        <tr>
                            <td>19</td>
                            <td>Owner's Equity</td>
                            <td class="">onreqy</td>
                        </tr>
                        <tr>
                            <td>20</td>
                            <td>Cost of goods sold</td>
                            <td class="">cogds</td>
                        </tr>
                        <tr>
                            <td>21</td>
                            <td>Extradinory Item</td>
                            <td class="">exotryitm</td>
                        </tr>
                        <tr>
                            <td>22</td>
                            <td>Revenue Of Sales</td>
                            <td class="">rvnfmsls</td>
                        </tr>
                        <tr>
                            <td>23</td>
                            <td>Cost of mechandise Sold</td>
                            <td class="">cstomsld</td>
                        </tr>
                        <tr>
                            <td>24</td>
                            <td>Stockholder's Equity</td>
                            <td class="">sthoeqy</td>
                        </tr>
                        <tr>
                            <td>25</td>
                            <td>Inventory Goods</td>
                            <td class="">invtrygds</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>