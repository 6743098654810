import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})
export class PositionsComponent implements OnInit {
  apiurl = environment.SERVER_ORIGIN;
  @Input() able2edit;
  @Input() able2delete;
  @Input() able2add;
  @Input() id;
  @Input() branch_id;
  @Input() key;
  @Input() types;
  @Input() requestfor;
  @Input() user_data;
  @Input() attachments;
  licencedata: any = [];
  page = 1;
  psize = 10;
  wholedata: any = [];
  nodatamsg: string = this.lang.transform('lang_loading');
  viewdata: any = [];
  editdata: any = [];
  updatestatus: any = false;
  updateerror: any = "";
  formstatus: boolean = false;
  formerror: string = "";
  positionform: FormGroup;
  licenceneed: boolean = false;
  jobLists: any = [];
  licencesList: FormArray;
  licencesstatus: boolean = false;
  modalRefA: BsModalRef;
  modalRefV: BsModalRef;
  lang_key = localStorage.getItem('lang_key');
  psearchKey = "";
  expandstatus = false;
  constructor(public router:Router, public ds:DataService,public route :ActivatedRoute, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit() {
    this.expandstatus = (this.requestfor == 'profile') ? true : false;
    this.position_form();
    this.load_my_positions();
    this.licencesList = this.positionform.get('liclists') as FormArray;
    if (this.requestfor !=='profile'){
      this.jobTitles();
    }
  }
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  get licenceFormGroup() {
    return this.positionform.get("liclists") as FormArray;
  }
  public emptypositionForm(load = true) {
    this.licencesList.controls = [];
    this.formstatus = false;
    this.licencesstatus = false;
    this.formerror = "";
    this.positionform.reset();
    if(load){
      this.jobTitles();
    }
  }
  public get_needed_licences(id) {
    if (id){
      this.spinner.show();
      this.licencesList.controls = [];
      this.licencesstatus = false;
      this.positionform.get('liclists').setValue([]);
      this.ds.getActionByUrl([], 'signup/get_needed_licences/' + id + '/' + this.lang_key).subscribe(data => {
        this.spinner.hide();
        this.licencesstatus = data.status;
        if (data.status) {
          data.licenses.forEach(item => {
            this.licencesList.push(this.createLicences(item));
          });
        }
      }, error => {
        this.spinner.hide();
      });
    }
  }
  public createLicences(item): FormGroup {
    return this.fb.group({
      employee_number: new FormControl(this.id, [Validators.required]),
      licence_list_id: new FormControl(item.licence_list_id, [Validators.required]),
      settings_id: new FormControl(item.settings_id, [Validators.required]),
      licence_name: new FormControl({ value: item.licence_name, disabled: true }),
      licence_number: new FormControl((item.licence_number) ? item.licence_number : "", [Validators.required]),
      licence_issue_date_english: new FormControl((item.licence_issue_date_english) ? this.ds.encon(item.licence_issue_date_english) : "", [Validators.required]),
      licence_issue_date_arabic: new FormControl((item.licence_issue_date_arabic) ? this.ds.arcon(item.licence_issue_date_arabic) : "", [Validators.required]),
      licence_expiry_date_english: new FormControl((item.licence_expiry_date_english) ? this.ds.encon(item.licence_expiry_date_english) : "", [Validators.required]),
      licence_expiry_date_arabic: new FormControl((item.licence_expiry_date_arabic) ? this.ds.arcon(item.licence_expiry_date_arabic) : "", [Validators.required]),
    });
  }
  public jobTitles() {
    this.jobLists = [];
    this.ds.getActionByUrl([], 'human_resources/joblists/').subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.jobLists = data.joblists;
      }
    }, error => {
      this.spinner.hide();
    });
  }
  public position_form() {
    this.positionform = new FormGroup({
      position_id: new FormControl(null),
      settings_id: new FormControl("", [Validators.required]),
      job_descriptions: new FormControl("", [Validators.required]),
      liclists: this.fb.array([]),
    });
  }
  public dynamicLicenceRemove(index) {
    if (index > 0){
      this.licencesList.removeAt(index);
    }
  }
  public create_position() {
    this.spinner.show();
    let formdata = this.ds.json2formdata(this.positionform.value)
    formdata.append('id', this.id);
    formdata.append('branch_id', this.branch_id);
    let url = (this.positionform.get("position_id").value) ? "update_position" : "create_position";
    this.ds.postActionByUrl(formdata, url).subscribe(res =>{
      this.spinner.hide();
      if(res.status) {
        this.emptypositionForm(false);
        this.load_my_positions();
        this.alert.success(res.msg);
        setTimeout(() => {
          this.modalRefA.hide();
        }, 1000);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public pagesize(size) {
    this.psize = size;
    this.page = 1;
    this.load_my_positions();
  }
  public pagelength(page) {
    this.page = page;
    this.load_my_positions();
  }
  public search_my_datas() {
    this.page = 1;
    this.psize = 10;
    this.load_my_positions();
  }
  public load_my_positions() {
    let formdata = new FormData();
    formdata.append('branch_id', this.branch_id);
    formdata.append('id', this.id);
    formdata.append('searchKey', this.psearchKey || '');
    let url = (this.requestfor !== 'profile') ? 'positions' : 'my_positions';
    this.ds.postActionByUrl(formdata, url+'/'+this.page+'/'+this.psize, ).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.wholedata = res;
      } else {
        this.wholedata = [];
        this.nodatamsg = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholedata = [];
      this.nodatamsg = error.error + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public view_my_position(position, type, template) {
    this.spinner.show();
    let formdata = new FormData();
    formdata.append('id', this.id);
    formdata.append('branch_id', this.branch_id);
    formdata.append('position_id', position.position_id);
    let url = (this.requestfor !== 'profile') ? 'positions' : 'my_positions';
    this.ds.postActionByUrl(formdata, url+'/1/1').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        if(type == 'view') { // for view positions
          this.viewdata = res.records[0];
          this.licencedata = res.licences;
          this.openModalV(template);
        } else { //for Edit Positions
          this.emptypositionForm(false);
          this.openModalA(template);
          this.positionform.patchValue({
            position_id: res.records[0].position_id,
            settings_id: res.records[0].settings_id,
            job_descriptions: res.records[0].job_descriptions,
          });
          if (res.licences.length !=0){
            this.licencesstatus = true;
            res.licences.forEach(item => {
              this.licencesList.push(this.createLicences(item));
            });
          } else {
            this.licencesstatus = false;
            this.licencesList.controls = [];
          }
        }
      } else {
          this.viewdata = "";
          this.licencedata = [];
          this.modalRefV.hide();
          this.ds.dialogf('', res.error);
      }
    }, error => {
      this.spinner.hide();
      this.licencedata = [];
      this.viewdata = "";
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public checkAllPos(event) {
    if (event.target.checked) {
        $(".apppositions .eachItemPos").prop("checked", true);
        $(".apppositions .makeback").addClass("bground");
        $(".apppositions .deleteIconDiv").show();
    } else {
        $(".apppositions .eachItemPos").prop("checked", false);
        $(".apppositions .makeback").removeClass("bground");
        $(".apppositions .deleteIconDiv").hide();
    }
  }
  public checkEachItemPos(event) {
      if (event.target.checked) {
          const val = event.target.value;
          $(".apppositions .removerow" + val).addClass("bground");
      } else {
          const val = event.target.value;
          $(".apppositions .removerow" + val).removeClass("bground");
      }
      const checkedtotal = [];
      $(".apppositions .eachItemPos:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      const totalrow = [];
      $(".apppositions .eachItemPos").each(function() {
          totalrow.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          $(".apppositions .deleteIconDiv").show();
      } else {
          $(".apppositions .deleteIconDiv").hide();
      }
      if (checkedtotal.length === totalrow.length) {
          $(".apppositions #allItemsPos").prop("checked", true);
      } else {
          $(".apppositions #allItemsPos").prop("checked", false);
      }
  }
  public deleteBulkDataPos() {
      const checkedtotal = [];
      $(".apppositions .eachItemPos:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          const dialogRef = this.dialog.open(ConfirmationDialog, {
              disableClose: true,
              width: '400px',
              data: {
                  title: this.lang.transform('lang_confirmation'),
                  message: this.lang.transform('lang_are_you_sure'),
              }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                  this.spinner.show()
                  this.ds.deleteActionByUrl(checkedtotal, "01f5086b879a62a05da4094dac203558/POSITION/"+this.id+'/'+this.branch_id).subscribe(
                      (data) => {
                          this.spinner.hide()
                          if (data.status) {
                              this.load_my_positions();
                              $(".apppositions #allItemsPos").prop("checked", false);
                              $(".apppositions .deleteIconDiv").hide();
                              this.ds.dialogf('', data.msg);
                          } else {
                              this.ds.dialogf('', data.error);
                          }
                      },
                      (error) => {
                          this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                          this.spinner.hide()
                      }
                  );
              }
          });
      } else {
          this.ds.dialogf();
      }
  }
}
