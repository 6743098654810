<div class="card supervision_teams">
    <div class="card-header">{{'lang_team_users' | language }} </div>
    <div class="card-body pad0">
        <mat-tab-group (selectedTabChange)="selectedListTabValue($event)">
            <mat-tab label="teams">
                <ng-template mat-tab-label>Users List</ng-template>
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-3 col-sm-4 col-xs-12 mb-10">
                            <ng-select 
                                [items]="positions" 
                                [multiple]="true" 
                                bindLabel="label" 
                                appendTo="body" 
                                bindValue="value" 
                                clearAllText="Clear"
                                [(ngModel)]="positionItems"
                                placeholder="{{'lang_position' | language}}"
                                (change)="spinner.show();search_supervision_teams();">
                            </ng-select>
                        </div>
                        <div class="col-md-9 col-sm-8 col-xs-12 mb-10">
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control almnabrformcontrol" [(ngModel)]="searchKey" (keyup)="search_supervision_teams();" placeholder="{{'lang_search' | language }}" />
                                <span class="input-group-btn" *ngIf="able2add && tListData.length == '0'">
                                    <button id="btnSearch" type="button" matTooltip="{{'lang_add' | language }}" class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" (click)="emptyform();openModal(createupdateteam)"></button>
                                </span>
                                <span class="input-group-btn" *ngIf="able2edit && tListData.length != '0'">
                                    <button id="btnSearch" type="button" matTooltip="{{'lang_edit' | language }}" class="btnSearch h34 btn btn-md btn-gray fa fa-pencil-square-o mnabricon" (click)="emptyFormEdit();openModal(createupdateteam)"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsTeam checkbox" id="allItemsTeam" (click)="checkAllItemsTeam($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_name' | language }}</td>
                                            <td>{{'lang_title' | language }}</td>
                                            <td>{{'lang_position' | language }}</td>
                                            <td>{{'lang_role' | language }}</td>
                                            <td>{{'lang_writer' | language }}</td>
                                            <td>{{'lang_ondate' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="tListData.length != '0' " class="AppendList">
                                        <tr *ngFor="let list of tListData.records; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{list.projects_user_group_id}}">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemTeam checkbox" value="{{list.projects_user_group_id}}" (click)="checkEachItemTeam($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{list.label}}</td>
                                            <td>{{list.teamtitle}}</td>
                                            <td *ngIf="list.position">{{list.position}}</td>
                                            <td *ngIf="!list.position">----</td>
                                            <td><span matTooltip="{{list.teamtitle}}">{{list.project_user_group_mention_key}}</span></td>
                                            <td>{{list.writer}}</td>
                                            <td>{{list.project_user_group_created_datetime}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="tListData.length == '0' " class="AppendList">
                                        <tr class="odd">
                                            <td colspan="9" align="center">{{tnodata}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="tListData.length != '0' " class="padb0 card-footer padb0 ng-star-inserted">
                    <div class="row">
                        <div *ngIf="able2delete" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataTeam()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-2 col-sm-6 col-6  mb-10">
                            {{'lang_total' | language }} : {{tListData.page.total_records}}
                        </div>
                        <div class="col-lg-5 col-sm-6 mb-10">
                            <mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="10" checked (click)="load_supervision_teams_pagesize(10)"> 10 </mat-radio-button>
                                <mat-radio-button value="20" (click)="load_supervision_teams_pagesize(20)"> 20 </mat-radio-button>
                                <mat-radio-button value="50" (click)="load_supervision_teams_pagesize(50)"> 50 </mat-radio-button>
                                <mat-radio-button value="100" (click)="load_supervision_teams_pagesize(100)"> 100 </mat-radio-button>
                                <mat-radio-button value="500" (click)="load_supervision_teams_pagesize(500)"> 500 </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            {{'lang_goto' | language }} :
                            <select class="customselect" (change)="load_supervision_teams_page($event.target.value)">
                                <option *ngFor="let ks of ds.getrange(tListData.page.total_pages)" value="{{ks}}">{{ks}}</option>
                            </select>
                        </div>
                    </div>
                </div> 
            </mat-tab>
            <mat-tab label="form_steps">
                <ng-template mat-tab-label>Form Steps</ng-template>
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-12 col-sm-3">
                            <label for="formName">{{ "lang_form" | language }}</label>
							<ng-select
								[items]="forms"
								[multiple]="false"
								bindLabel="label"
								bindValue="value"
								appendTo="body"
                                [clearable]="false"
								[(ngModel)]="formName"
                                (change)="getFormSteps()"
								placeholder="{{ 'lang_form' | language }}"
							>
							</ng-select>
                        </div>
                        <div class="col-0 col-sm-6"></div>
                        <div class="col-12 col-sm-3">
                            <div style="display: flex;justify-content: end;margin-bottom: 10px;">
                                <div class="table-arrows">
                                    <i class="fa fa-arrow-left" (click)="scrollLeft()"></i>
                                    <i class="fa fa-arrow-right" (click)="scrollRight()"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <div class="table-container" id="table-container">
                                <table class="main-table">
                                    <thead>
                                        <tr>
                                            <th style=" color:#035a99;width:27px;border-left: 1px solid rgba(148, 148, 148, 0.747);" class="sticky">ID</th>
                                            <th style="color:#035a99;left:23px" class="sticky">User Name</th>
                                            <th *ngFor="let item of tableHeaders" colspan="2" style="color:#035a99">{{item}}</th>
                                        </tr>
                                        <tr>
                                            <th colspan="2" class="sticky">
                                                <div style="display:flex; flex-direction:column; justify-content:center; align-items:start">
                                                    <mat-checkbox  labelPosition="after" [(ngModel)]="step_completed">
                                                        {{"lang_completed" | language}}
                                                    </mat-checkbox>
                                                    <mat-checkbox labelPosition="after" [(ngModel)]="step_pending">
                                                        {{"lang_pending" | language}}
                                                    </mat-checkbox>
                                                </div>
                                            </th>
                                            <th *ngFor="let item of tableHeaders" style="padding:0;"  colspan="2">
                                                <table style="width:100%;min-height: 50px;" class="no-border" >
                                                    <tr>
                                                        <th class="no-border" style="border-right:1px solid rgba(148, 148, 148, 0.747); background:none; color: #4c4c4c;" colspan="2" *ngIf="step_completed && !step_pending">{{"lang_completed" | language}}</th>
                                                        <th class="no-border" style="border-right:1px solid rgba(148, 148, 148, 0.747); background:none; color: #4c4c4c;" *ngIf="step_completed && step_pending">{{"lang_completed" | language}}</th>
                                                        <th class="no-border" style="background:none;color: #4c4c4c;" colspan="2" *ngIf="!step_completed && step_pending">{{"lang_pending" | language}}</th>
                                                        <th class="no-border" style="background:none;color: #4c4c4c;" *ngIf="step_completed && step_pending">{{"lang_pending" | language}}</th>
                                                    </tr>  
                                                </table>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of tableContent | slice: (form_steps_pageno-1) * form_steps_pagesize : form_steps_pageno * form_steps_pagesize">
                                            <td class="sticky" style="padding:5px">{{item.userId}}</td>
                                            <td class="sticky" style="left:23px;padding:5px">{{item.userName}}</td>
                                            <td *ngFor="let el of item.steps"  colspan="2" style="padding:0">
                                                <table style="width:100%;" class="no-border" >
                                                    <tr>
                                                        <td class="no-border" style="border-right:1px solid rgba(148, 148, 148, 0.747); padding: 5px;" colspan="2" *ngIf="step_completed && !step_pending">{{el.complete}}</td>
                                                        <td class="no-border" style="border-right:1px solid rgba(148, 148, 148, 0.747); padding: 5px;width: 52%;" *ngIf="step_completed && step_pending">{{el.complete}}</td>
                                                        <td class="no-border" style="padding: 5px;" colspan="2" *ngIf="!step_completed && step_pending">{{el.pending}}</td>
                                                        <td class="no-border" style="padding: 5px;" *ngIf="step_completed && step_pending">{{el.pending}}</td>
                                                    </tr>  
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12" style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap">
                                <p>{{"lang_total" | language}}: {{tableContent.length}}</p>
                                <ngb-pagination
                                    [collectionSize]="tableContent.length"
                                    [(page)]="form_steps_pageno"
                                    [pageSize]="form_steps_pagesize"
                                    [boundaryLinks]="true"
                                    
                                ></ngb-pagination>
                                <!-- (pageChange)="getTestReportListCo($event, concrete_due_dates)" -->
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        
    </div>
</div>
<ng-template #createupdateteam>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_team_users' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()">&times;</button>
        </div>
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                        <label for="director_position_id">{{'lang_director_position' | language }}:<i class="red"> * </i></label>
                        <ng-select 
                            [items]="positions" 
                            [multiple]="false" 
                            bindLabel="label" 
                            bindValue="value" 
                            appendTo="body" 
                            [searchFn]="ds.customSearchFn"
                            clearAllText="Clear"
                            formControlName="director_position_id"
                            placeholder="{{'lang_director_position' | language}}">
                        </ng-select>
                        <mat-error *ngIf="form.controls['director_position_id'].touched && form.controls['director_position_id'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                        <label for="project_supervision_director">{{'lang_project_supervision_director' | language }}:<i class="red"> * </i></label>
                        <ng-select 
                                [items]="users" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                clearAllText="Clear"
                                formControlName="project_supervision_director"
                                placeholder="{{'lang_project_supervision_director' | language}}"
                                (search)="search_users($event)"
                                [searchFn]="ds.customSearchFn"
                                (focus)="users = []">
                        </ng-select>
                        <mat-error *ngIf="form.controls['project_supervision_director'].touched && form.controls['project_supervision_director'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                        <label for="manager_position_id">{{'lang_manager_position' | language }}:<i class="red"> * </i></label>
                        <ng-select 
                            [items]="positions" 
                            [multiple]="false" 
                            bindLabel="label" 
                            bindValue="value" 
                            appendTo="body" 
                            [searchFn]="ds.customSearchFn"
                            clearAllText="Clear"
                            formControlName="manager_position_id"
                            placeholder="{{'lang_director_position' | language}}">
                        </ng-select>
                        <mat-error *ngIf="form.controls['manager_position_id'].touched && form.controls['manager_position_id'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                        <label for="project_supervision_manager">{{'lang_project_supervision_managers' | language }}:<i class="red"> * </i></label>
                        <ng-select 
                                [items]="users" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                clearAllText="Clear"
                                formControlName="project_supervision_manager"
                                placeholder="{{'lang_project_supervision_managers' | language}}"
                                (search)="search_users($event)"
                                (focus)="users = []"
                                [searchFn]="ds.customSearchFn"
                            >
                        </ng-select>
                        <mat-error *ngIf="form.controls['project_supervision_manager'].touched && form.controls['project_supervision_manager'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                    </div>
                </div>
                <div class="itemsdata">
                    <label> {{'lang_team_users' | language }} <i class="red"> * </i></label>
                    <div class="TableParts">
                        <div class="table-responsive customResponsive mb-10 tablefields">
                            <table border="1" class="table CustomTable text-center">
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td><i class="fa fa-plus-square fa-icon" matTooltip="{{'lang_add' | language }}" (click)="add_new_items();"></i></td>
                                        <td>{{'lang_position' | language }}<i class="red"> * </i></td>
                                        <td>{{'lang_team_users' | language }}<i class="red"> * </i></td>
                                    </tr>
                                </thead>
                                <tbody formArrayName="teams" class="AppendList">
                                    <tr *ngFor="let item of items_group().controls; let itemIndex = index;let odd=odd; let even=even" [formGroupName]="itemIndex" style="background: #ffffff;">
                                        <td class="verticalalignmid">{{itemIndex+1}}</td>
                                        <td class="verticalalignmid"><i (click)="removeItems(itemIndex);" class="fa fa-minus-square fa-icon mnabricon red" matTooltip="{{'lang_delete' | language }}"></i></td>
                                        <td width="40%">
                                            <ng-select 
                                                [items]="positions" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                appendTo="body" 
                                                [searchFn]="ds.customSearchFn"
                                                clearAllText="Clear"
                                                formControlName="project_settings_id"
                                                placeholder="{{'lang_position' | language}}">
                                            </ng-select>
                                            <mat-error *ngIf="submitted && itemsgroup.controls[itemIndex].get('project_settings_id').errors?.required">{{'lang_field_required' | language }}</mat-error>
                                        </td>
                                        <td width="40%">
                                            <ng-select 
                                                [items]="users" 
                                                [multiple]="true" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="project_user_group_user_id"
                                                placeholder="{{'lang_team_users' | language}}"
                                                (search)="search_users($event)"
                                                (focus)="users = []"
                                            >
                                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                                <span >{{ item.label}} ({{ item.value}})</span>
                                            </ng-template>
                                            </ng-select>
                                            <mat-error *ngIf="submitted && itemsgroup.controls[itemIndex].get('project_user_group_user_id').errors?.required">{{'lang_field_required' | language }}</mat-error>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal footer -->
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRef.hide()">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>