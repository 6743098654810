<div class="card">
	<div class="card-header">
		{{ "lang_form_version" | language }}
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-12">
				<div class="table-responsive customResponsive">
					<table border="1" class="table CustomTable">
						<thead>
							<tr>
								<td>#</td>
								<td>{{ "lang_transaction_number" | language }}</td>
								<td>{{ "lang_unit" | language }}</td>
								<td>{{ "lang_work_level" | language }}</td>
								<td>{{ "lang_barcode" | language }}</td>
								<td>{{ "lang_evaluation_result" | language }}</td>
								<td>
									{{ "lang_date" | language }}
								</td>
								<td>{{ "lang_action" | language }}</td>
							</tr>
						</thead>
						<tbody *ngIf="versions?.records?.length != 0" class="AppendList">
							<tr
								*ngFor="
									let row of versions?.records;
									let i = index;
									let odd = odd;
									let even = even
								"
								[ngClass]="{ odd: odd, even: even }"
							>
								<td>{{ i + 1 }}</td>
								<td>{{ row.transaction_request_id }}</td>
								<td>{{ template_type == "2" ? "ALL" : row.unit_id }}</td>
								<td>{{ row.levelname }}</td>
								<td *ngIf="row.tbv_barcodeData">
									{{ row.tbv_barcodeData }}
								</td>
								<td *ngIf="!row.tbv_barcodeData">----</td>
								<td
									*ngIf="row.result_code"
									[ngClass]="{
										redstatus: row.color == 'RED',
										greenstatus: row.color == 'GREEN'
									}"
								>
									{{ row.result_code }}
								</td>
								<td *ngIf="!row.result_code">----</td>
								<td>{{ row.transactions_date_datetime }}</td>
								<td *ngIf="row.file_path">
									<i
										class="fa fa-icon mnabricon fa-file-pdf-o"
										(click)="ds.PreviewData(row.file_path)"
									></i>
								</td>
								<td *ngIf="!row.file_path">----</td>
							</tr>
						</tbody>
						<tbody
							*ngIf="versions?.records?.length == 0 || !versions?.records"
							class="AppendList"
						>
							<tr class="odd">
								<td colspan="8" class="nodata" align="center">
									{{ nodata }}
								</td>
							</tr>
						</tbody>
					</table>
					<div
						*ngIf="
							versions?.records?.length != 0 && versions?.records && versions
						"
						class="mb-10 custom-footer"
					>
						<div class="row">
							<div class="col-12 col-sm-4 mb-10">
								{{ "lang_total" | language }} :
								{{
									versions?.page?.total_records
										? versions?.page?.total_records
										: 0
								}}
							</div>
							<div class="col-12 col-sm-6 mb-10">
								<mat-radio-group aria-label="Select an option">
									<mat-radio-button
										value="10"
										[checked]="versions_page_size == '10' ? true : false"
										(click)="load_by_page_size(10, 'versions')"
									>
										10</mat-radio-button
									>
									<mat-radio-button
										value="20"
										[checked]="versions_page_size == '20' ? true : false"
										(click)="load_by_page_size(20, 'versions')"
									>
										20
									</mat-radio-button>
									<mat-radio-button
										value="50"
										[checked]="versions_page_size == '50' ? true : false"
										(click)="load_by_page_size(50, 'versions')"
									>
										50
									</mat-radio-button>
									<mat-radio-button
										value="100"
										[checked]="versions_page_size == '100' ? true : false"
										(click)="load_by_page_size(100, 'versions')"
									>
										100
									</mat-radio-button>
									<mat-radio-button
										value="500"
										[checked]="versions_page_size == '500' ? true : false"
										(click)="load_by_page_size(500, 'versions')"
									>
										500
									</mat-radio-button>
								</mat-radio-group>
							</div>
							<div class="col-12 col-sm-2">
								<mat-select
									[(value)]="versions_page_no"
									(selectionChange)="load_by_page_no($event, 'versions')"
								>
									<mat-option
										*ngFor="let ks of ds.getrange(versions?.page?.total_pages)"
										[selected]="versions_page_no == ks ? true : false"
										value="{{ ks }}"
										>{{ "lang_page" | language }} {{ ks }}</mat-option
									>
								</mat-select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row mt-3">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			routerLink="/transactions/form/{{ dwsrService?.form_key }}/vr/{{
				form_request_id
			}}"
		>
			{{ "lang_close" | language }}
		</button>
	</div>
</div>
