import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-account-master-list',
  templateUrl: './account-master-list.component.html',
  styleUrls: ['./account-master-list.component.scss']
})
export class AccountMasterListComponent implements OnInit {
  options = {useCheckbox: false};
  equalheight = "";
  style: any;
  costcenters: any = [];
  currencies:any = [];
  acctypes:any = [];
  balancesheets:any = [];
  multisettings = environment.multipledropdown
  singlesettings = environment.singledropdown
  nodes: any = [];
  branches: any = [];
  branch_id = localStorage.getItem('selectedBranch'); 
  form_show_status = "ADD";
  add: boolean = false;
  edit: boolean = false;
  delete = false;
  viewstatus: boolean = false;
  viewdata: any = [];
  accountmasterform: FormGroup;
  constructor(public ds:DataService,public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.spinner.show();
    this.load_branches();
    this.build_form();
    if (this.branch_id !== null && this.branch_id !='0' ) {
      this.load_account_tree();
      this.searchCurrencies();
      this.searchAccountTypes();
    }
  }
  public load_branches() {
    this.branches = [];
    this.ds.getActionByUrl([], 'ab/accounts_view').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.branches = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public selectBrach(branch) {
    if (branch && branch != '0') {
      localStorage.setItem('selectedBranch', branch);
      this.accountmasterform.get('branch_id').setValue(branch);
      this.branch_id = branch;
      this.load_account_tree();
      this.searchCurrencies();
      this.searchAccountTypes();
    }
  }
  public build_form() {
    this.accountmasterform = new FormGroup({
      account_masters_id: new FormControl(null),
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      account_master_root: new FormControl(1, [Validators.required]),
      account_masters_code: new FormControl(null),
      account_masters_name_en: new FormControl(null, [Validators.required]),
      account_masters_name_ar: new FormControl(null, [Validators.required]),
      account_masters_currency_id: new FormControl(null, [Validators.required]),
      account_masters_type: new FormControl(null, [Validators.required]),
      account_masters_balance_sheet_id: new FormControl(null, [Validators.required]),
      account_masters_support: new FormControl(0),
      cost_center_support: new FormControl(0),
      account_masters_cost_center_id: new FormControl(null),
      opening_balance: new FormControl(''),
      opening_balance_type: new FormControl(''),
      account_vat_number: new FormControl(''),
    });
  }
  public load_account_tree() {
    this.spinner.show();
    this.ds.getActionByUrl([], 'amlist/' + this.branch_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.nodes = res.records;
      } else {
        this.nodes = [];
      }
    }, error => {
      this.spinner.hide();
      this.nodes = [];
    })
  }
  public clear(tree) {
    tree.collapseAll();
    this.form_show_status = "ADD";
    this.accountmasterform.reset();
    this.accountmasterform.get('account_master_root').setValue('1');
    this.accountmasterform.get('branch_id').setValue(this.branch_id);
    if (tree) {
      tree.clearFilter();
      this.viewstatus = false;
      if (tree.getFocusedNode()?.isActive) {
        tree.getFocusedNode().toggleActivated();
      }
    }
  }
  public toggleAction(tree: any) {
    this.add = this.edit = this.delete = this.viewstatus = false;
    this.form_show_status = "ADD";
    this.accountmasterform.reset();
    this.accountmasterform.get('account_master_root').setValue('1');
    this.accountmasterform.get('account_masters_id').setValue(null);
    this.accountmasterform.get('account_masters_code').setValue(null);
    if (tree.activeNodes.length > 0) {
      this.account_view_data(tree.activeNodes[0].data.account_masters_id);
      this.viewstatus = true;
      this.accountmasterform.get('account_master_root').setValue(0);
      this.accountmasterform.get('account_masters_id').setValue(tree.activeNodes[0].data.account_masters_id);
      this.accountmasterform.get('account_masters_code').setValue(tree.activeNodes[0].data.account_masters_code);
      if (tree.activeNodes[0].data.account_masters_support == "1" && this.ds.allowMe("accounts", "accounts_add")) {// need Add permission and sub account support 1
        this.add = true;
      }
      if (this.ds.allowMe("accounts", "accounts_edit")) { //need edit permission
        this.edit = true;
      }
      if (tree.activeNodes[0].data.account_masters_support == "0" && this.ds.allowMe("accounts", "accounts_delete")) { // need permission and sub account 0
        this.delete = true;
      }
    }
  }
  public treeAction(tree: any, action: any) {
    if (tree.activeNodes.length > 0) {
      let data = tree.activeNodes[0].data;
      if (action == 'view') {
        this.form_show_status = "VIEW";
        this.account_view_data(data.account_masters_id);
      } else if (action == 'add') {
        this.accountmasterform.reset();
        this.accountmasterform.get('account_master_root').setValue(0);
        this.accountmasterform.get('branch_id').setValue(this.branch_id);
        this.accountmasterform.get('account_masters_id').setValue(data.account_masters_id);
        this.accountmasterform.get('account_masters_code').setValue(data.account_masters_code);
        this.accountmasterform.get('account_masters_support').setValue(data.account_masters_support);
        this.accountmasterform.get('cost_center_support').setValue(data.cost_center_support);
        this.accountmasterform.get('account_masters_currency_id').setValue([{
          label: data.currencylabel,
          value: data.currencyvalue
        }]);
        this.accountmasterform.get('account_masters_type').setValue([{
          label: data.accountlabel,
          value: data.accountvalue
        }]);
        this.accountmasterform.get('account_masters_balance_sheet_id').setValue([{
          label: data.balancelabel,
          value: data.balancevalue
        }]);
        this.form_show_status = "ADD";
      } else if (action == 'edit') {
        this.spinner.show();
        this.ds.getActionByUrl([], 'amedit/' + this.branch_id + '/' + data.account_masters_id).subscribe(res => {
          this.spinner.hide();
          if (res.status) {
            this.accountmasterform.patchValue(res.records);
            this.searchBalanceSheets();
          } else {
            this.ds.dialogf('',res.error);
          }
        }, error => {
          this.spinner.hide();
          this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
        });
        this.form_show_status = "EDIT";
      } else if (action == 'delete') {
        const dialogRef = this.dialog.open(ConfirmationDialog,{
          disableClose: true,
          width:'400px',
          data:{
            title: this.lang.transform('lang_confirmation'),
            message: this.lang.transform('lang_are_you_sure'),          
          }
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.spinner.show()
            this.ds.deleteActionByUrl([], 'amdelete/' + this.branch_id + '/' + data.account_masters_id).subscribe(data => {
              this.spinner.hide();
              if (data.status) {
                this.nodes = data.records;
                let element: HTMLElement = document.getElementById('btnSearch') as HTMLElement;
                element.click();
              } else {
                this.ds.dialogf('',data.error);
              }
            },
              (error) => {
                this.spinner.hide();
                this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              }
            );
          }
        });
      }
    } else {
      this.ds.dialogf('',this.lang.transform('lang_select_anyone'));
    }
  }
  public cost_support() {
    this.costcenters = [];
    this.accountmasterform.get('account_masters_cost_center_id').setValue([]);
  }
  public searchCurrencies() {
    this.currencies = [];
    let param = new FormData();
    param.append('branch_id', this.branch_id);
    this.ds.postActionByUrl(param, 'currencies').subscribe(res => {
      if(res.status) {
        this.currencies = res.records;
      }
    });
  }
  public searchAccountTypes() {
    this.acctypes = [];
    let param = new FormData();
    param.append('branch_id', this.branch_id);
    this.ds.postActionByUrl(param, 'actypes').subscribe(res => {
      if (res.status) {
        this.acctypes = res.records;
      }
    });
  }
  public emptyBalanceSheets(item: any, action :any) {
    this.balancesheets = [];
    this.accountmasterform.get('account_masters_balance_sheet_id').setValue(null);
    if (action == 'select') {
      this.searchBalanceSheets();
    }
  }
  public searchBalanceSheets() {
    this.balancesheets = [];
    let param = new FormData();
    param.append('branch_id', this.branch_id);
    if (this.accountmasterform.get('account_masters_type').value) {
      param.append('account_type', this.accountmasterform.get('account_masters_type').value[0].value);
    } else {
      param.append('account_type', "");
    }
    this.ds.postActionByUrl(param, 'bsheets').subscribe(res => {
      if (res.status) {
        this.balancesheets = res.records;
      }
    });
  }
  public searchCostCenter(event) {
    this.costcenters = [];
    let param = new FormData();
    param.append('branch_id', this.branch_id);
    param.append('search_text', event.target.value);
    this.ds.postActionByUrl(param, 'cctransactions').subscribe(res => {
      if (res.status) {
        this.costcenters = res.records;
      }
    });
  }
  public create_account_master(tree) {
    this.alert.clear();
    let data = this.ds.json2formdata(this.accountmasterform.value);
    data.set('branch_id', this.branch_id);
    this.spinner.show();
    this.ds.postActionByUrl(data, 'amcreate').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.nodes = res.records;
        let element: HTMLElement = document.getElementById('btnSearch') as HTMLElement;
        element.click();
        this.alert.success(res.msg);
        //tree.getNodeById('00010001').setActiveAndVisible();
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      this.spinner.hide();
    })
  }
  public account_view_data(account_masters_id) {
    this.viewdata = [];
    this.form_show_status = "VIEW";
    this.spinner.show();
    this.ds.getActionByUrl([], 'amview/' + this.branch_id + '/' + account_masters_id).subscribe(res=>{
      this.spinner.hide();
      if(res.status){
        this.viewdata = res.records;
      } else {
        this.viewdata = [];
      }
    }, error => {
        this.spinner.hide();
        this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public update_account_master(tree) {
    let data = this.ds.json2formdata(this.accountmasterform.value);
    data.set('branch_id', this.branch_id);
    this.spinner.show();
    this.ds.postActionByUrl(data, 'amupdate/' + this.accountmasterform.get('account_masters_id').value).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.nodes = res.records;
        let element: HTMLElement = document.getElementById('btnSearch') as HTMLElement;
        element.click();
        this.alert.success(res.msg);
        //tree.getNodeById(res.active_code).setActiveAndVisible();
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
}
