import { Component, OnInit, ViewChild } from '@angular/core';
import { CollectionDetailsComponent } from './collection-details/collection-details.component';
import { ConsultantSettingsComponent } from './consultant-settings/consultant-settings.component';
import { Wgroup1Component } from './wgroup1/wgroup1.component';
import { Wgroup2Component } from './wgroup2/wgroup2.component';
import { WplatformsComponent } from './wplatforms/wplatforms.component';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild(Wgroup1Component) group1: Wgroup1Component;
  @ViewChild(Wgroup2Component) group2: Wgroup2Component;
  @ViewChild(WplatformsComponent) platforms: WplatformsComponent;
  @ViewChild(CollectionDetailsComponent) collectiondetails: CollectionDetailsComponent;
  ngOnInit(): void {}
  public load_child_data(event) {
    this.group1.ngOnInit();
    this.group2.ngOnInit();
    this.platforms.ngOnInit();
  }
  public load_child_data_collection(event) {
    this.collectiondetails.ngOnInit();
  }
}