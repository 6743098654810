<div class="card mobilization_in_contracts">
    <div class="card-header">{{'lang_contractors' | language }} </div>
    <div class="card-body pad0">
        <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
            <mat-tab label="{{'lang_main_contractors' | language}}">
                <div class="custom-panel-content padding15">
                    <app-main-contractors [supervision_data]="supervision_data" [able2add]="able2add" [able2edit]="able2edit" [project_data]="project_data"></app-main-contractors>
                </div>
            </mat-tab>
            <mat-tab label="{{'lang_sub_contractors' | language}}">
                <div class="custom-panel-content padding15">
                    <app-sub-contractors [supervision_data]="supervision_data" [able2add]="able2add" [able2edit]="able2edit" [project_data]="project_data"></app-sub-contractors>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>