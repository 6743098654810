import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { TRWDialogComponent } from './trw-dialog/trw-dialog.component';
import { TrwViewDialogComponent } from './trw-view-dialog/trw-view-dialog.component';

@Component({
  selector: 'app-supervision-test-report-workarea',
  templateUrl: './supervision-test-report-workarea.component.html',
  styleUrls: ['./supervision-test-report-workarea.component.scss']
})
export class SupervisionTestReportWorkareaComponent implements OnInit {
  @Input() supervision_data
  @Input() able2add
  @Input() able2edit
  @Input() project_data
  @Input() makeAction;
  setting_type = 'soil';
  list_type    = "soil";
  lang_key = localStorage.getItem('lang_key') || 'en';
  loading_files_btns = false;
  history_files = [];
  history_files_page = 1;
  history_files_size = 5;
  report_status_so = 'P';
  report_status_co = 'P';
  report_status_list = [
    {
      label:this.lang.transform('lang_all'),
      value: ''
    },
    {
      label:this.lang.transform('lang_pending'),
      value: 'P'
    },
    {
      label:this.lang.transform('lang_failed'),
      value: 'F'
    },
    {
      label:this.lang.transform('lang_successfull'),
      value: 'S'
    },
    {
      label:this.lang.transform('lang_need_review'),
      value: 'NR'
    },
    {
      label:this.lang.transform('lang_not_correct'),
      value: 'NC'
    }
  ];
  compressive_strength = 'S';
  compressive_strength_list = [
    {
      label:this.lang.transform('lang_all'),
      value: ''
    },
    {
      label: this.lang.transform('lang_7_days'),
      value: 'S'
    },
    {
      label: this.lang.transform('lang_28_days'),
      value: 'T'
    }
  ];
  test_report_list_so = [];
  test_report_pageno_so = 1;
  test_report_pagesize_so = 10;
  test_report_collectionsize_so = 0;
  test_report_list_co = [];
  test_report_pageno_co = 1;
  test_report_pagesize_co = 10;
  test_report_collectionsize_co = 0;
  concrete_due_dates = false;
  constructor(private router: Router,public dialog: MatDialog, private spinner:PreloaderService, public ds: DataService, private sweetAlert: SweetAlertService, private lang:LanguagePipe) { }

  ngOnInit(): void {
    
  }


  selectedTabValue(e) {
    if(e?.tab?.textLabel == 'test_result_list') {
      this.getTestReportListSo();
    }
  }

  selectedListTabValue(e) {
    if(e?.tab?.textLabel == 'soil') {
      this.list_type = 'soil';
      this.getTestReportListSo();
    } else if(e?.tab?.textLabel == 'concrete') {
      this.list_type = 'concrete';
      this.getTestReportListCo(1, this.concrete_due_dates);
    }
  }

  loading_files_btns_func() {
    this.loading_files_btns = true;
    setTimeout(() => {
      this.loading_files_btns = false;
    }, 500);

    this.history_files = [];
  }

  downloadExcel(item) {
    let url = `ptesting/download_soil_concrete_testing_report_config/${this.project_data?.projects_profile_id}/${this.supervision_data?.projects_supervision_id}/${this.setting_type}`;
    if(item) {
      url = item?.file;
    }
    
    this.spinner.show();
    this.ds.get(url).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.sweetAlert.successMessage(res?.msg);
        if(item) {
          this.ds.downloadFile(res, item?.type_code_system + item?.created_datetime + '_file');
        } else  {
          this.ds.downloadFile(res, this.setting_type + '_file');
        }
      } else {
        this.sweetAlert.errorMessage(res?.error);
      }
    }, (err) => {
      this.sweetAlert.errorMessage(err?.error?.error);
      this.spinner.hide();
    })
  }


  uploadExcel(e) {
    if(e && e?.length) {
      let url = 'ptesting/upload_soil_testing_report_config';
      if(this.setting_type == 'concrete') {
        url = 'ptesting/upload_concreate_testing_report_config';
      }
      let formData = new FormData();
      formData.append('projects_profile_id', this.project_data?.projects_profile_id || '');
      formData.append('projects_supervision_id', this.supervision_data?.projects_supervision_id || '');
      if(this.setting_type == 'concrete') {
        formData.append('concreate_testing_report_file', e[0] || '');
      } else {
        formData.append('soil_testing_report_file', e[0] || '');
      }
      
      this.spinner.show();
      this.ds.post(url, formData).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.sweetAlert.successMessage(res?.msg);
        } else {
          this.sweetAlert.errorMessage(res?.error);
        }
      },
      (err) => {
        this.sweetAlert.errorMessage(err?.error?.error);
        this.spinner.hide();
      })
    }
    document.getElementById('uploadExcelInput')['value'] = '';
  }

  soil_concrete_history() {
    let formData = new FormData();
    if(this.setting_type == 'concrete') {
      formData.append('required_type', 'C');
    } else {
      formData.append('required_type', 'S');
    }
    
    this.spinner.show();
    this.ds.post(`s6df54s6df/${this.project_data?.projects_profile_id}/${this.supervision_data?.projects_supervision_id}`, formData).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.history_files = res?.records;
      } else {
        this.history_files = [];
        this.sweetAlert.errorMessage(res?.error);
      }
    }, (err) => {
      this.sweetAlert.errorMessage(err?.error?.error);
      this.spinner.hide();
    })
  }


  getTestReportListSo(e?) {
    if(e) {
      this.test_report_pageno_so = e;
    } else {
      this.test_report_pageno_so = 1;
    }
    this.spinner.show();
    let url = `ptesting/get_soil_testing_reports/${this.test_report_pageno_so}/${this.test_report_pagesize_so}`;
    let formData = new FormData();
    formData.append('projects_profile_id', this.project_data?.projects_profile_id || '');
    formData.append('projects_supervision_id', this.supervision_data?.projects_supervision_id || '');
    formData.append('report_status', this.report_status_so || '');
    this.ds.getActionByUrl(this.ds.formData2string(formData), url).subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.test_report_list_so = res?.records;
        this.test_report_collectionsize_so = res?.page?.total_records;
      } else {
        this.test_report_list_so = [];
      }
    }, err => {
      this.spinner.hide();
      this.test_report_list_so = [];
    })
  }

  getTestReportListCo(page, dueDates) {
    if(page) {
      this.test_report_pageno_co = page;
    } else {
      this.test_report_pageno_co = 1;
    }
    this.spinner.show();
    let formData = new FormData();
    formData.append('projects_profile_id', this.project_data?.projects_profile_id || '');
    formData.append('projects_supervision_id', this.supervision_data?.projects_supervision_id || '');
    formData.append('report_status', this.report_status_co || '');
    formData.append('compressive_strength', this.compressive_strength || '');
    if(dueDates) {
      formData.append('filter_by_due_dates', '1');
      if(this.compressive_strength == 'S') {
        formData.append('seven_days_due', '5');
        formData.append('tfive_days_due', '');
      } else if(this.compressive_strength == 'T') {
        formData.append('seven_days_due', '');
        formData.append('tfive_days_due', '25');
      }
    } else {
      formData.append('filter_by_due_dates', '');
      formData.append('seven_days_due', '');
      formData.append('tfive_days_due', '');
    }
    let url = `ptesting/get_concreate_testing_reports/${this.test_report_pageno_co}/${this.test_report_pagesize_co}`;
    
    this.ds.getActionByUrl(this.ds.formData2string(formData), url).subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.test_report_list_co = res?.records;
        this.test_report_collectionsize_co = res?.page?.total_records;
      } else {
        this.test_report_list_co = [];
      }
    }, err => {
      this.spinner.hide();
      this.test_report_list_co = [];
    })
  }

  viewTestData(item) {
    const dialogRef = this.dialog.open(TrwViewDialogComponent, {
      width:'80%',
      data: {
        item: item
      }
    })
  }

  // open form dialog
  openForm(item) {
    const dialogRef = this.dialog.open(TRWDialogComponent, {
      width:'80%',
      disableClose:true,
      data: {
        projects_supervision_id: this.supervision_data?.projects_supervision_id,
        projects_work_area_id: this.supervision_data?.projects_work_area_id,
        projects_profile_id: this.project_data?.projects_profile_id,
        transaction_request_id: item?.transaction_request_id,
        tbv_barcodeData: item?.tbv_barcodeData,
        tsr_report_id: item?.tsr_report_id,
        list_type: this.list_type
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res == 'soil') {
        this.getTestReportListSo();
      } else if(res == 'concrete') {
        this.getTestReportListCo(1, this.concrete_due_dates);
      }
    })
  }


  viewHistory(id, type) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/projects/Rpq7t/1/1/tsr/history/${type}/${id}`], {
        queryParams: { workarea:  this.supervision_data?.projects_work_area_id }
        
      })
    );
  
    window.open(url, '_blank');
  }

  dueDatesChange(e) {
    this.concrete_due_dates = e?.checked;
    this.getTestReportListCo(1, this.concrete_due_dates);
    
  } 
}
