import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';

import { Location } from '@angular/common';

declare var $:any;
@Component({
  selector: 'app-formhrv1-add',
  templateUrl: './formhrv1-add.component.html',
  styleUrls: ['./formhrv1-add.component.scss']
})
export class Formhrv1AddComponent implements OnInit { 
  
  @Output() vacationEvent = new EventEmitter();
  @Input() employee_data;

  @Input() form_request_id;
  @Input() form_key;
  @Input() id;
  @Input() branch_id;
  vacations:any = [];
  createVactionForm:FormGroup;
  selectedCity: any;
  markstatus: boolean = false;
  reviewstatus: boolean = false;
  attachstatus: boolean = false;
  attachments: FormArray;
  filename = this.lang.transform('lang_select_files');
  isFile: boolean = false;
  users: any = [];
  employee_number ='';
  contract_id     = '';
  vacation_paid_days_from_contract = '';
  showOtherVacation = false;
  showAttachment = false;
  financial_details = []
  lang_key = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
   direct_anager = []
  constructor(public router:Router,private route:ActivatedRoute,private Location:Location, public ds:DataService, public spinner : PreloaderService, public lang:LanguagePipe, private fb:FormBuilder, private alert:AlertService, private snackBar:MatSnackBar) { }
  
    ngOnInit(): void {
      if(!this.employee_data){
        this.id = this.route.snapshot.paramMap.get('id');
        this.branch_id = this.route.snapshot.paramMap.get('bid');
        this.spinner.show();
        this.get_view_details();
        this.initForm();
      }else{
        this.initForm();
        this.getEmployeeInfo();
        this.getVacationType();
      }

      
    }
    get_view_details(load: boolean = false) {
      this.ds.getActionByUrl([], 'human_resources/get_myview_data/'+this.id+'/'+this.branch_id).subscribe(data => {
        this.spinner.hide();
        if (data.status) {
          this.employee_data = data.data;
       
        /*   this.employee_id_number = data.data.employee_id_number; */
        /*   this.attachments = data.attachments; */

   
          this.getEmployeeInfo();
          this.getVacationType();
         /*  this.is_admin = data.is_admin; */
       /*    this.edit = data.edit;
          this.add = data.add;
          this.delete = data.delete; */
         /*  if (data.profile_percentage) {
            this.profile_percentage = data.profile_percentage.total;
          }
          if (this.viewdata.profile_image) {
            this.get_profile_image(this.viewdata.profile_image);
          } */
        } else {
          this.employee_data = "";
          this.ds.dialogf('', data.error);
        }
      }, error => {
        this.spinner.hide();
        this.employee_data = "";
        this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    }
   
    initForm() {
      this.createVactionForm = this.fb.group({
        vacation_type_id                     : [null, Validators.required],
        before_vacation_working_date_english : [null, Validators.required],
        before_vacation_working_date_arabic  : [null, Validators.required],
        after_vacation_working_date_english  : [null, Validators.required],
        after_vacation_working_date_arabic   : [null, Validators.required],
        vacation_other                       : [null, this.showOtherVacation? Validators.required:[]],
        vacation_start_date_english          : [null, Validators.required],
        vacation_start_date_arabic           : [null, Validators.required],
        vacation_end_date_english            : [null, Validators.required],
        vacation_end_date_arabic             : [null, Validators.required],
        contract_id                          : [null, Validators.required],
        vacation_total_days                  : [null, Validators.required],
        vacation_paid_days_from_contract     : [null, Validators.required],
        vacation_total_paid_days             : [null, Validators.required],
        paid_days75                          : [null, Validators.required],
        paid_days100                         : [null, Validators.required],
        paid_days75_amount                   : [null, Validators.required],
        paid_days100_amount                  : [null, Validators.required],
        sick_balance                         : [null, Validators.required],
        vacation_total_unpaid_days           : [null, Validators.required],
        vacation_total_paid_amount           : [null, Validators.required],
        direct_manager           : [{value:null,disabled:this.direct_anager.length > 0}, Validators.required],
        
        needAttach                           : new FormControl('0', [Validators.required]),
        
 
        attachments                          : this.fb.array([], this.showAttachment?[Validators.required]:[])
      });
      this.attachments = this.createVactionForm.get("attachments") as FormArray;
    }
  
  
    get getAttachments (){
      return this.createVactionForm.get('attachments')
    }
    getVacationType() {
      let data = new FormData();
      data.append('employee_number', this.employee_data.employee_number)
      this.ds.post('form/FORM_HRV1/get_vacation_type/', data).subscribe(res => {
        console.log(res);
        if(res.status) {
          this.vacations = res.records;

        }
      })
    }

    back(){
      console.log(this.Location.path())
       if(this.Location.path().includes('profile/create-vacation')) {
         this.Location.back()
       }else{
        this.vacationEvent.emit('EVIEW')
       }

    }
    checkVacationEmployee() {
      console.log(1);
      
      if(this.createVactionForm.get('vacation_type_id').valid &&
      this.createVactionForm.get('before_vacation_working_date_english').valid &&
      this.createVactionForm.get('after_vacation_working_date_english').valid  ) {
        let formData = new FormData();
        formData.append('employee_number', this.employee_data.employee_number);
        formData.append('vacation_type_id', this.createVactionForm.get('vacation_type_id').value);
        formData.append('before_vacation_working_date_english', this.createVactionForm.get('before_vacation_working_date_english').value.toLocaleDateString());
        formData.append('after_vacation_working_date_english', this.createVactionForm.get('after_vacation_working_date_english').value.toLocaleDateString());
        this.ds.post('form/FORM_HRV1/check_vacation_for_employee', formData).subscribe(res => {
          console.log(res);
          if(res.status) {
            if(this.createVactionForm.get('vacation_type_id').value == '3') {
              this.createVactionForm.get('paid_days75').setValue(res?.result?.paid_days75);
            this.createVactionForm.get('paid_days100').setValue(res?.result?.paid_days100);
            this.createVactionForm.get('paid_days75_amount').setValue(res?.result?.paid_days75_amount);
            this.createVactionForm.get('paid_days100_amount').setValue(res?.result?.paid_days100_amount);
            this.createVactionForm.get('sick_balance').setValue(res?.result?.sick_balance);
            this.createVactionForm.get('vacation_total_paid_amount').clearValidators();
            this.createVactionForm.get('vacation_total_paid_amount').updateValueAndValidity();
            this.createVactionForm.get('vacation_total_paid_days').clearValidators();
            this.createVactionForm.get('vacation_total_paid_days').updateValueAndValidity();
            } else {
              this.createVactionForm.get('vacation_total_paid_amount').setValue(res?.result?.paid_amount);
              this.createVactionForm.get('vacation_total_paid_days').setValue(res?.result?.paid_days);
              this.createVactionForm.get('paid_days75').clearValidators();
            this.createVactionForm.get('paid_days100').clearValidators();
            this.createVactionForm.get('paid_days75_amount').clearValidators();
            this.createVactionForm.get('paid_days100_amount').clearValidators();
            this.createVactionForm.get('sick_balance').clearValidators();
            this.createVactionForm.get('paid_days75').updateValueAndValidity();
            this.createVactionForm.get('paid_days100').updateValueAndValidity();
            this.createVactionForm.get('paid_days75_amount').updateValueAndValidity();
            this.createVactionForm.get('paid_days100_amount').updateValueAndValidity();
            this.createVactionForm.get('sick_balance').updateValueAndValidity();
            }
            this.createVactionForm.get('vacation_total_unpaid_days').setValue(res?.result?.unpaid_days);
            this.createVactionForm.get('vacation_total_days').setValue(res?.result?.vacation_days);
            this.createVactionForm.get('vacation_start_date_english').setValue(new Date(res?.result?.vacation_start_date));
            this.createVactionForm.get('vacation_end_date_english').setValue(new Date(res?.result?.vacation_end_date));
            this.ds.setENTOARDate(
              {value:this.createVactionForm.get('vacation_start_date_english').value},
              this.createVactionForm.get('vacation_start_date_arabic')
            );
            this.ds.setENTOARDate(
              {value:this.createVactionForm.get('vacation_end_date_english').value},
              this.createVactionForm.get('vacation_end_date_arabic')
            );
            console.log(this.createVactionForm.value);
            this.financial_details = res?.result?.finance
          }
        }, err => {
          this.createVactionForm.get('vacation_type_id').reset();
          this.createVactionForm.get('before_vacation_working_date_english').reset();
          this.createVactionForm.get('before_vacation_working_date_arabic').reset();
          this.createVactionForm.get('after_vacation_working_date_english').reset();
          this.createVactionForm.get('after_vacation_working_date_arabic').reset();
        })
      }
    }
  
    
    getEmployeeInfo() {
      let data = new FormData();
      data.append('employee_number', this.employee_data.employee_number)
      this.ds.post('form/FORM_HRV1/get_employee_info', data).subscribe(res => {
        if(res.status) {
          console.log(res);
          this.createVactionForm.controls['contract_id'].setValue(res?.result?.contract_vacation_info?.contract_id);
          this.createVactionForm.controls['vacation_paid_days_from_contract'].setValue(res?.result?.contract_vacation_info?.vacation_paid_days_only);
          this.employee_number = res.result?.employee_info?.employee_number;
          this.contract_id = res?.result?.contract_vacation_info?.contract_id;
          this.vacation_paid_days_from_contract = res?.result?.contract_vacation_info?.vacation_paid_days_only;
        }else{
           
          this.showMsg(res.error, 'ok', 'error-snackbar');
          this.back()
        }
        
      })
    }
    get attachmentsARR() {
      return this.createVactionForm.get("attachments") as FormArray;
    }
    checkAttachment() {
      
      let vacation = this.vacations.find((item => {
        return item.value == this.createVactionForm.get('vacation_type_id').value;
      }));
     /*  const creds = this.createVactionForm.controls.attachments['controls']   as FormArray; */
      if(vacation) {
        if(vacation.need_attachment == '1') {
          this.showAttachment = true;
          this.getAttachments.setValidators([Validators.required])
          this.attachments.setValidators(Validators.required);
          
          this.getAttachments.updateValueAndValidity();
          this.attachstatus = false
        this.showAttachment = true;
         this.attachmentsARR.clear()
          console.log('VV',this.createVactionForm.get('attachments'));
          
        } else {
          this.showAttachment = false;
          this.attachstatus = false
         /*  this.showAttachment = true; */
          this.attachments.clearValidators();
          this.getAttachments.reset()
          this.getAttachments.updateValueAndValidity();
          this.attachmentsARR.clear()
          console.log('EE',this.createVactionForm.get('attachments'));
        }
     
      }


      if(vacation.value == '9') {
        this.showOtherVacation = true;
      } else {
        this.showOtherVacation = false;
      }
    }
  
  
  
  
    public enable_field(field) {
 
      if (field == 'attachment') {
        this.attachments.clear();
        this.createVactionForm.get('needAttach').setValue('1');
        this.addfiles();
        this.attachstatus = true;
      }
    }
  
    public files(): FormGroup {
      return this.fb.group({
        attach_title: new FormControl('', (this.createVactionForm.get('needAttach').value == "1") ?[Validators.required] : null),
        file: new FormControl('', (this.createVactionForm.get('needAttach').value == "1") ? [Validators.required] : null)
      });
    }
    public addfiles() {
      if (this.attachments.length < 5) {
        this.attachments.push(this.files());
      }
    }
    public removefiles(index) {
      if (this.attachments.length > 1) {
        this.attachments.removeAt(index);
      }
    }
  
    public getfilesgroup(index): FormGroup {
      const formGroup = this.attachments.controls[index] as FormGroup;
      return formGroup;
    }
  
    public UploadFile(event, index) {
      if (event.target.files[0] && event.target.files[0] !== undefined && event.target.files[0].name) {
        const fileid = event.target.files[0];
        $("#uploadText" + index).text(fileid.name);
        this.attachments.controls[index].get('file').setValue(fileid, { emitEvent: true });
      } else {
        this.attachments.controls[index].get('file').setValue(null);
        $("#uploadText" + index).text(this.lang.transform('lang_select_files'));
      }
    }
    public uploadFile(event) {
      if (
        event.target.files[0] &&
        event.target.files[0] !== undefined &&
        event.target.files[0].name
      ) {
        this.isFile = true;
        const fileid = event.target.files[0];
        this.filename = fileid.name;
        this.createVactionForm.get("upload").setValue(fileid, { emitEvent: true });
      } else {
        this.isFile = false;
        this.createVactionForm.get("upload").setValue(null);
        this.filename = this.lang.transform('lang_select_files');
      }
    }
    public removeFile() {
      this.isFile = false;
      this.createVactionForm.get("upload").setValue(null);
      this.filename = this.lang.transform('lang_select_files');
    }
  
    public disable_field(field) {
   
      if (field == 'attachment') {
        this.attachments.clear();
        this.createVactionForm.get('needAttach').setValue('0');
        this.isFile = false;
        this.attachstatus = false;
      }
      this.createVactionForm.updateValueAndValidity();
    }
    get filesFormGroup() {
      return this.createVactionForm.get("attachments") as FormArray;
    }
  
    public search_users(key) {
      this.users = [];
      let param = new FormData();
      param.append('search', key.term || '');
      param.append('lang_key', this.lang_key);
      param.append('user_type_id', '1');
      this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      })
    }

    onSubmit() {
      console.log(this.createVactionForm);
      if(this.createVactionForm.valid) {
        let formData = new FormData();
        formData.append('employee_number', this.employee_data.employee_number);
        formData.append('vacation_type_id', this.createVactionForm.get('vacation_type_id').value);
        formData.append('vacation_other', this.createVactionForm.get('vacation_other').value);
        formData.append('contract_id', this.createVactionForm.get('contract_id').value);
        formData.append('before_vacation_working_date_english', this.createVactionForm.get('before_vacation_working_date_english').value.toLocaleDateString());
        formData.append('before_vacation_working_date_arabic', this.createVactionForm.get('before_vacation_working_date_arabic').value);
        formData.append('vacation_start_date_english', this.createVactionForm.get('vacation_start_date_english').value.toLocaleDateString());
        formData.append('vacation_start_date_arabic', this.createVactionForm.get('vacation_start_date_arabic').value);
        formData.append('vacation_end_date_english', this.createVactionForm.get('vacation_end_date_english').value.toLocaleDateString());
        formData.append('vacation_end_date_arabic', this.createVactionForm.get('vacation_end_date_arabic').value);
        formData.append('after_vacation_working_date_english', this.createVactionForm.get('after_vacation_working_date_english').value.toLocaleDateString());
        formData.append('after_vacation_working_date_arabic', this.createVactionForm.get('after_vacation_working_date_arabic').value);
        formData.append('vacation_total_days', this.createVactionForm.get('vacation_total_days').value);
        formData.append('vacation_paid_days_from_contract', this.createVactionForm.get('vacation_paid_days_from_contract').value);
        formData.append('direct_manager', this.createVactionForm.get('direct_manager').value);
        formData.append('vacation_total_unpaid_days', this.createVactionForm.get('vacation_total_unpaid_days').value);

    
         formData.append('needAttach', this.createVactionForm.get('needAttach').value);
          if(this.createVactionForm.get('vacation_type_id').value == '3') {
          formData.append('vacation_total_paid_days', (this.createVactionForm.get('paid_days75').value + this.createVactionForm.get('paid_days100').value));
          formData.append('vacation_total_paid_amount', (this.createVactionForm.get('paid_days75_amount').value + this.createVactionForm.get('paid_days100_amount').value));
        } else {
          formData.append('vacation_total_paid_days', this.createVactionForm.get('vacation_total_paid_days').value);
          formData.append('vacation_total_paid_amount', this.createVactionForm.get('vacation_total_paid_amount').value);
        }
        
          this.attachments.controls.forEach((item, index) => {
            formData.append(`attachments[${index+1}][file]`, item.get('file').value);
            formData.append(`attachments[${index+1}][attach_title]`, item.get('attach_title').value);
          });
        
        if(this.showAttachment) {
          if(this.attachments.length) {
            this.spinner.show();
            this.ds.post('form/FORM_HRV1/cr/0', formData).subscribe(res => {
              console.log(res);
              this.spinner.hide();
              if(res.status) {
                this.showMsg(res.msg, 'ok', 'success-snackbar');
                this.resetFields();
                this.financial_details=[]
              } else {
                this.showMsg(res.error, 'ok', 'error-snackbar');
              }
            })
          } else {
            console.log('add attachments');
            this.showMsg('add attachments', 'ok', 'error-snackbar');
            
          }
        } else {
          this.spinner.show();
          this.ds.post('form/FORM_HRV1/cr/0', formData).subscribe(res => {
            console.log(res);
            this.spinner.hide();
            if(res.status) {
              this.showMsg(res.msg, 'ok', 'success-snackbar');
              this.resetFields();
              this.financial_details=[]
            } else {
              this.showMsg(res.error, 'ok', 'error-snackbar');
            }
          })
        }
        
      } else {
        this.showMsg('All Fields are required!!', 'ok', 'error-snackbar');
      }
      
    }

    showMsg(msg:string, action:string, className:string) {
      this.snackBar.open(msg, action, {
        duration:2000,
        panelClass: [className]
      });
    }

    resetFields() {
        this.createVactionForm.reset();
        this.attachstatus = false; 
        this.reviewstatus = false;
        this.markstatus   = false;
     /*    this.createVactionForm.get('needMark').setValue('0');
        this.createVactionForm.get('needReview').setValue('0'); */
        this.createVactionForm.get('needAttach').setValue('0');
        this.createVactionForm.get('contract_id').setValue(this.contract_id);
        this.createVactionForm.get('vacation_paid_days_from_contract').setValue(this.vacation_paid_days_from_contract);
        console.log(this.createVactionForm);
        
    
    }

}

