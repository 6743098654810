import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-msr-step4',
  templateUrl: './msr-step4.component.html',
  styleUrls: ['./msr-step4.component.scss']
})
export class MsrStep4Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  form:FormGroup;
  users:any           = [];
  lang_key:string     = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
  rejectedValidators = [];
  constructor(public ds:DataService, private sweetAlert:SweetAlertService, public datepipe : DatePipe , public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id            : new FormControl(this.form_request_id, [Validators.required]),
      teserve_transaction               : new FormControl(null, [Validators.required]),
      notes                             : new FormControl(null, this.rejectedValidators)
    });
  }
  checkStatus() {
    console.log(this.form)
    if(this.form.get('teserve_transaction').value == '0'){
      this.rejectedValidators=[Validators.required]
    } else {
      this.rejectedValidators=[]
    }
  }
  
  public completeStep4() {
    let teserve_transaction, 
        rollback;
    if(this.form.get('teserve_transaction').value == '1') {
      teserve_transaction = '1';
      rollback = '0';
    } else {
      teserve_transaction = '0';
      rollback = '1';
    }
    if(this.form.valid) {
      let formData = new FormData();
      formData.append('transaction_request_id', this.form.value.transaction_request_id);
      formData.append('teserve_transaction', teserve_transaction);
      formData.append('rollback_to_configuration', rollback);
      if(this.form.get('teserve_transaction').value == '0') {
        formData.append('notes',  this.form.value.notes);
      }
      this.sweetAlert.confirmMessage('Are you sure?', 'Confirm').then(result => {
        if(result.isConfirmed) {
          this.spinner.show();
        this.ds.post(`form/${this.form_key}/Recipient_Verification/0`, formData).subscribe(data => {
          if(data.status) {
            this.spinner.hide();
            this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
            setTimeout(() => {
              this.refreshComponent.emit(true);
            }, 2000);
          } else {
            this.spinner.hide();
            this.alert.error(data.error)
          }
        }, (error) => {
          this.spinner.hide();
            this.alert.error(error);
        })
        }
        
      })
    } else {
      this.alert.error(this.lang.transform('lang_all_fields_required'));
    }
  }
}
