import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit {
  @Input() able2edit;
  @Input() able2delete;
  @Input() able2add;
  @Input() id;
  @Input() branch_id;
  @Input() key;
  @Input() types;
  @Input() requestfor;
  @Input() user_data;
  @Input() attachments;
  modalRefV: BsModalRef;
  searchKey             = "";
  module_filter         = "";
  wholedata: any        = [];
  modules:any           = [];
  expandstatus          = false;
  page                  = 1;
  size                  = 10;
  errormessagef: any    = this.lang.transform('lang_loading');
  viewdata:any          = [];
  moduleusers:any       = [];
  moduledata:any        = [];
  constructor(public router:Router, public ds:DataService,public route :ActivatedRoute, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.expandstatus = (this.requestfor == 'profile') ? true : false;
    this.get_modules();
    this.search_employee_modules();
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  public get_modules() {
    this.modules = [];
    this.ds.postActionByUrl([], 'module').subscribe(res => {
      if(res.status)  {
        this.modules = res.records;
      }
    })
  }
  public load_page_size(pageno) {
    this.spinner.show();
    this.size = pageno;
    this.get_employee_modules();
  }
  public page_length(page) {
    this.spinner.show();
    this.page = page;
    this.get_employee_modules();
  }
  public search_employee_modules() {
    this.size = 10;
    this.page = 1;
    this.get_employee_modules();
  }
  public get_employee_modules() {
    const param = new FormData();
    param.append("search_key", this.searchKey || '');
    param.append("module_name", this.module_filter || '');
    param.append("id", this.id);
    let url = (this.requestfor === "profile") ? 'mymodules' : 'employeemodules';
    this.ds.getActionByUrl(this.ds.formData2string(param), url+'/'+this.page+'/'+this.size).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.wholedata = data;
      } else {
        this.wholedata = [];
        this.errormessagef = data.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholedata = [];
      this.errormessagef = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public get_users_on_this_modules(mod, template) {
    this.moduledata = mod;
    this.spinner.show();
    this.ds.postActionByUrl(this.ds.json2formdata(mod), 'moduleusers').subscribe(res=> {
      this.spinner.hide();
      if(res.status) {
        this.openModalV(template);
        this.moduleusers = res.records;
      } else {
        this.ds.dialogf('', res.error);
        this.moduleusers = [];
      }
    }, error => {
      this.moduleusers = [];
      this.spinner.hide();
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    });
  }




}
