<section class="panel-expansion attach_panel">
    <mat-accordion  class="stylepanels">
      <mat-expansion-panel class="mpanel" [expanded]="false">
        <mat-expansion-panel-header class="mpanel-header">{{'lang_attachments' | language}}</mat-expansion-panel-header>
        <div class="material-panel-body">
            <div class="mbrl15">
                <div class="row">
                    <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                        <ng-select 
                            [items]="types" 
                            [multiple]="true" 
                            bindLabel="title" 
                            bindValue="attach_type_id" 
                            clearAllText="Clear"
                            appendTo="body" 
                            placeholder="{{'lang_attachment_type' | language}}"
                            [(ngModel)]="attachmentType"
                            (change)="spinner.show();search_my_attachments();">
                        </ng-select>
                    </div>
                    <div class="col-md-9 col-sm-9 col-xs-12 mb-10">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control almnabrformcontrol" (keyup)="search_my_attachments()" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                            <span class="input-group-btn" *ngIf="able2add">
                                <button id="btnSearch" type="button" (click)="openModalA(uploadAttachments);emptyAttachForm();" matTooltip="{{'lang_upload_attachments' | language }}" class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"></button>
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="TablePart1">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsAth checkbox" id="allItemsAth" (click)="checkAllItemsAth($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_type' | language }}</td>
                                            <td>{{'lang_subject' | language }}</td>
                                            <td>{{'lang_file_extention' | language }}</td>
                                            <td>{{'lang_file_level' | language }}</td>
                                            <td>{{'lang_writer' | language }}</td>
                                            <td>{{'lang_ondate' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="myattachments.length != '0'" class="AppendList">
                                        <tr *ngFor="let file of myattachments; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{file.file_records_id}}">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemAth checkbox" value="{{file.file_records_id}}" (click)="checkEachItemAth($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td *ngIf="file.type_name">{{file.type_name}}</td>
                                            <td *ngIf="!file.type_name">----</td>
                                            <td class="breakall">{{file.file_name}}</td>
                                            <td>{{file.file_extension}}</td>
                                            <td>{{file.level_keys}}</td>
                                            <td>{{file.writer}}</td>
                                            <td>{{file.created_datetime}}</td>
                                            <td>
                                                <mat-icon class="mnabricon" [matMenuTriggerFor]="menu">settings</mat-icon>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item (click)="ds.previewData(file.attachment_link, '#showAttachPdf')">{{'lang_file' | language }}</button>
                                                    <button mat-menu-item *ngIf="able2edit" (click)="openModalE(EditAttachModal);edit_my_Attach(file)">{{'lang_edit' | language }}</button>
                                                </mat-menu>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="myattachments.length == '0'" class="AppendList">
                                        <tr class="odd">
                                            <td colspan="14" align="center">{{attacherrormsg}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="myattachments.length != '0'" class="card-footer padb0 bottomfooter">
                <div class="row">
                    <div *ngIf="able2delete" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                        <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataAth()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                    </div>
                    <div class="col-lg-2 col-sm-6 col-6  mb-10">
                        {{'lang_total' | language }} : {{attachdata.page.total_records}}
                    </div>
                    <div class="col-lg-5 col-sm-6 col-12 mb-10">
                        <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="10" checked (click)="spinner.show();attachpage(10)"> 10</mat-radio-button>
                            <mat-radio-button value="20" (click)="spinner.show();attachpage(20)"> 20 </mat-radio-button>
                            <mat-radio-button value="50" (click)="spinner.show();attachpage(50)"> 50 </mat-radio-button>
                            <mat-radio-button value="100" (click)="spinner.show();attachpage(100)"> 100 </mat-radio-button>
                            <mat-radio-button value="500" (click)="spinner.show();attachpage(500)"> 500 </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mb-10">
                        {{'lang_goto' | language }} :
                        <select class="customselect" (change)="spinner.show();attachlength($event.target.value)">
                            <option *ngFor="let k of ds.getrange(attachdata.page.total_pages)" value="{{k}}">{{k}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
</section>
<div class="modal" id="showAttachPdf">
    <div class="modal-dialog width70">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{'lang_preview' | language }}</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="pdf-container">
            <iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'lang_close' | language }}</button>
        </div>
      </div>
    </div>
</div>
<ng-template #uploadAttachments>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_upload_attachments' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefA.hide();">&times;</button>
        </div>
        <form [formGroup]="attachform" (ngSubmit)="upload_files()">
            <input type="hidden" formControlName="supplier_id" />
            <div class="modal-body">            
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="attachment_type">{{'lang_attachment_type' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <select formControlName="attachment_type" class="form-control almnabrformcontrol" matTooltip="{{'lang_attachment_type' | language }}">
                                <option value="">{{'lang_choose_options' | language }}</option>
                                <option *ngFor="let type of types" value="{{type.key_code}}">{{type.title}}</option>
                            </select>
                            <mat-error *ngIf="attachform.controls['attachment_type'].touched && attachform.controls['attachment_type'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="level_keys">{{'lang_file_secutrity_levels' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <ng-select 
                                [items]="filepermissionlevels" 
                                [multiple]="true" 
                                bindLabel="label" 
                                appendTo="body" 
                                bindValue="value" 
                                clearAllText="Clear"
                                placeholder="{{'lang_file_secutrity_levels' | language}}"
                                formControlName="level_keys">
                            </ng-select>
                            <mat-error *ngIf="attachform.controls['level_keys'].touched && attachform.controls['level_keys'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="attachment_descriptions">{{'lang_description' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" formControlName="attachment_descriptions" class="form-control almnabrformcontrol" placeholder="{{'lang_description' | language }}" />
                            <mat-error *ngIf="attachform.controls['attachment_descriptions'].touched && attachform.controls['attachment_descriptions'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="attachment_link">{{'lang_select_files' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <div class="IDDIV">
                                    <input name="attachment_link" type="file" required onclick="this.value = null" (change)="uploadIDFile($event)" class="nghide albutton width100">
                                    <label id="uploadIDButton" class="albutton">{{idfilename}} </label>
                                </div>
                            </div>
                            <mat-error *ngIf="attachform.controls['attachment_link'].touched && attachform.controls['attachment_link'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefA.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #EditAttachModal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_attachments' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefE.hide();">&times;</button>
        </div>
        <form [formGroup]="uform" (ngSubmit)="update_file_name()">
            <div class="modal-body">
                <div class="form-group">
                    <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_description' | language }} : <span class="red">*</span></label>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" formControlName="attachment_descriptions" class="form-control almnabrformcontrol" placeholder="{{'lang_description' | language }}"/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="level_keys">{{'lang_file_secutrity_levels' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <ng-select 
                                [items]="filepermissionlevels" 
                                [multiple]="true" 
                                bindLabel="label" 
                                appendTo="body" 
                                bindValue="value" 
                                clearAllText="Clear"
                                placeholder="{{'lang_file_secutrity_levels' | language}}"
                                formControlName="level_keys">
                            </ng-select>
                            <mat-error *ngIf="uform.controls['level_keys'].touched && uform.controls['level_keys'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>          
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefE.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>