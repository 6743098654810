<breadcrumb></breadcrumb>
<div class="themesection">
    <section class="panel-expansion matborderno">
        <mat-accordion class="accounts_masters" >
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_general_ledger' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body">
                    <div class="mbrl15">
                        <form class="form" [formGroup]="form" (ngSubmit)="fetch_statements()">
                            <div class="row">
                                <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                                    <select formControlName="branch_id" class="form-control almnabrformcontrol" matTooltip="{{'lang_branch' | language }}" (change)="spinner.show();selectBrach($event.target.value);">
                                        <option value="0">{{'lang_choose_options' | language }}</option>
                                        <option *ngFor="let branch of branches" value="{{branch.id}}">{{branch.title}}</option>
                                    </select>
                                </div>
                                <div class="col-md-3 col-sm-6 col-xs-12 mb-10" *ngIf="form.get('branch_id').value && form.get('branch_id').value != '0'">
                                    <ng-select 
                                            [items]="finances" 
                                            [multiple]="false" 
                                            bindLabel="label" 
                                            bindValue="value" 
                                            appendTo="body" 
                                            clearAllText="Clear"
                                            formControlName="finance_id"
                                            placeholder="{{'lang_financial_years' | language }}"
                                            (change) = "ds.setDate($event, form);"
                                        >
                                    </ng-select>
                                </div>
                                <div class="col-md-3 col-sm-6 col-xs-12 mb-10"
                                    *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                    <div class="form-group">
                                        <label>{{'lang_accounts_types' | language }} : </label>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-xs-12 mb-10" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                    <mat-radio-group aria-label="Select an option" formControlName="request_type">
                                        <mat-radio-button value="1" > {{'lang_all' | language }} </mat-radio-button>
                                        <mat-radio-button value="0" checked> {{'lang_particular' | language }} </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="row" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_account_from' | language }} : </label><br>
                                        <ng-select 
                                            [items]="fromaccounts" 
                                            [multiple]="false" 
                                            bindLabel="label" 
                                            bindValue="value" 
                                            appendTo="body" 
                                            clearAllText="Clear"
                                            formControlName="account_code_from"
                                            placeholder="{{'lang_account_from' | language }}"
                                        >
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_account_to' | language }} : </label><br>
                                        <ng-select 
                                            [items]="fromaccounts" 
                                            [multiple]="false" 
                                            bindLabel="label" 
                                            bindValue="value" 
                                            appendTo="body" 
                                            clearAllText="Clear"
                                            formControlName="account_code_to"
                                            placeholder="{{'lang_account_to' | language }}"
                                        >
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_period_from' | language }} : <span class="red">*</span> </label><br>
                                        <input formControlName="period_from" id="period_from" class="form-control almnabrformcontrol period_from" placeholder="YYYY/MM/DD" />
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_period_to' | language }} :<span class="red">*</span> </label><br>
                                        <input formControlName="period_to" id="period_to" class="form-control almnabrformcontrol period_to" placeholder="YYYY/MM/DD" />
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                                    <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                                </div>
                            </div>
                        </form>
                        <div class="row" *ngIf="datastatus && form.get('branch_id').value && form.get('branch_id').value != '0'">
                            <div class="col-md-12">
                                <div class="table-responsive dropdownyes customResponsive tablefields">
                                    <table border="1" class="table CustomTable">
                                        <thead>
                                            <tr>
                                                <td>#</td>
                                                <td>{{'lang_accounts_acccode' | language }}</td>
                                                <td>{{'lang_account' | language }}</td>
                                                <td>{{'lang_accounts_opening_balance' | language }}</td>
                                                <td>{{'lang_transaction_debit' | language }}</td>
                                                <td>{{'lang_transaction_credit' | language }}</td>
                                                <td>{{'lang_accounts_closing_balance' | language }}</td>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="data.length != '0'" class="AppendList">
                                            <tr *ngFor="let tran of data; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="{{tran.class}}">
                                                <td>{{i+1}}</td>
                                                <td>{{tran.account_code}}</td>
                                                <td>{{tran.account_name}}</td>
                                                <td>{{ds.addCommas(tran.opening_balance)}}</td>
                                                <td>{{ds.addCommas(tran.transaction_debit)}}</td>
                                                <td>{{ds.addCommas(tran.transaction_credit)}}</td>
                                                <td>{{ds.addCommas(tran.closing_balance)}}</td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="data.length == '0' " class="AppendList">
                                            <tr class="odd">
                                                <td colspan="10" align="center">{{lodingdatas}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</div>