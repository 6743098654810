<form [formGroup]="form" class="form" (ngSubmit)="submit();">
    <section class="panel-expansion ckeditorhidetoolbar">
        <mat-accordion class="stylepanels">
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_bonus_form_new' | language}} ( <span *ngIf="lang_key =='en'"> {{employee_data.firstname_english}} {{employee_data.lastname_english}}</span> <span *ngIf="lang_key =='ar'"> {{employee_data.firstname_arabic}} {{employee_data.lastname_arabic}} </span> ) </mat-expansion-panel-header>
                <div class="mbrl15">

                    <div class="row mb-10">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_employee_no"|language }}</div>
                            <div class="mb-10">
                                <b> {{employee_data.employee_number}} </b>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_name"|language }}</div>
                            <div class="mb-10">
                                <b>
                                    {{employee_data.firstname_english}} </b
                                >
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_salary"|language }}</div>
                            <div class="mb-10">
                                <b>
                                    5000</b
                                >
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_current_salary"|language }}</div>
                            <div class="mb-10">
                                <b>
                                   4500</b
                                >
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_joining_date"|language }}</div>
                            <div class="mb-10">
                                <b>
                                   15/06/2002</b
                                >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_type' | language}} <span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol" readonly placeholder="{{'lang_type' | language}}" formControlName="financial_type">
                            </div>
                        </div>
               
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label for="bonus_date_english">{{'lang_date_bonus' | language }} : <span class="red">*</span></label>
                                <div class="positionrelatiove">
                                    <input formControlName="bonus_date_english" type="text" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event, form.get('bonus_date_arabic'));" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
                                    <mat-datepicker-toggle matSuffix [for]="BDeng" class="posiab"></mat-datepicker-toggle>
                                    <mat-datepicker #BDeng disabled="false"></mat-datepicker>
                                    <mat-error *ngIf="submitted && form.controls['bonus_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_amount' | language}}  <span class="red">*</span> </label>
                                <input type="number" class="form-control almnabrformcontrol" placeholder="{{'lang_amount' | language}}" formControlName="bonus_amount">
                                <mat-error *ngIf="submitted && form.controls['bonus_amount'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-xs-12 col-sm-4 mb-10">
                            <label class="mb-10">
                                {{ "lang_direct_manager" | language }}
                                <span class="red"> * </span> </label>
            
                                <ng-select
                                [items]="users"
                                [multiple]="false"
                                bindLabel="label"
                                bindValue="value"
                                clearAllText="Clear"
                                 
                                appendTo="body"
                                formControlName="direct_manager"
                                placeholder="{{ 'lang_direct_manager' | language }}"
                                (search)="search_users($event)"
                                
                                [searchFn]="ds.customSearchFn"
                                 
                            >
                            </ng-select>
                            <mat-error
                                *ngIf="
                                submitted &&
                                form.controls['direct_manager'].errors?.required
                                "
                            >
                                {{ "lang_field_required" | language }}
                            </mat-error>
                         
                        </div>   
                    </div>
              
                   
                  
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="button" routerLink="/human_resources/employees" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_human_resources_all_emp' | language}}</button>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="button" (click)="loanEvent.emit()" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_human_resources_emp_details' | language}}</button>
                        </div>
                        <div class="col-md-6 col-sm-2 col-xs-12 mb-10">
                            <app-alert id="default-alert"></app-alert>
                        </div>
                        <div class="col-md-1 col-sm-2 col-xs-12 mb-10">
                            
                        </div>
                        <div class="col-md-1 col-sm-2 col-xs-12 mb-10">
                            <input type="submit" value="{{'lang_save' | language}}" class="albutton mnabrbutton mat-accent" mat-button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</form>