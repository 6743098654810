<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_units_levels" | language }}</h4>
		<span (click)="closeModal()"><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div
		class="table-responsive dropdownyes customResponsive tablefields"
		*ngIf="!unit_levels_msg && units_levels?.length"
	>
		<table border="1" class="table CustomTable">
			<thead>
				<tr>
					<th>#</th>
					<th>{{ "lang_units" | language }}</th>
					<th>{{ "lang_work_levels" | language }}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="
						let item of units_levels | slice: (page - 1) * size:page * size;
						let i = index;
						let odd = odd;
						let even = even
					"
				>
					<td>{{ i + 1 }}</td>
					<td>{{ item?.unit_id }}</td>
					<td>{{ item?.work_level_label }}</td>
				</tr>
			</tbody>
		</table>
		<div
			class="mt-2"
			style="
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
			"
		>
			<ngb-pagination
				*ngIf="units_levels.length"
				[collectionSize]="units_levels.length"
				[(page)]="page"
				[maxSize]="size"
				[boundaryLinks]="true"
			></ngb-pagination>
		</div>
	</div>
	<div
		class="mt-3 mb-3 text-center"
		*ngIf="unit_levels_msg && !units_levels?.length"
	>
		<p style="font-size: 18px">{{ unit_levels_msg }}</p>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="closeModal()"
		>
			{{ "lang_close" | language }}
		</button>
	</div>
</mat-dialog-actions>
