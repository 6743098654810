<breadcrumb></breadcrumb>

<form class="form" [formGroup]="form">
    <section class="panel-expansion ckeditorhidetoolbar">
      <mat-accordion class="stylepanels">
        <mat-expansion-panel class="mpanel" [expanded]="true">
          <mat-expansion-panel-header class="mpanel-header">{{
            "lang_add_group" | language
            }}</mat-expansion-panel-header>
          <div class="mbrl15" [ngClass]="{ ' contentrtl': lang_key == 'ar' }">
          
            <div class="container p-0 m-0" style="max-width: 99%;">
                <div class="row">
                  
               

                  <div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                    <mat-form-field appearance="outline" style="width: 100%;">
                      <mat-label>{{ "lang_title_english" | language }}</mat-label>
                      <input matInput formControlName="group_title_english" required />
                      <mat-error *ngIf="
                                                      form.controls['group_title_english'].touched &&
                                                      form.controls['group_title_english'].errors
                                                          ?.required
                                                  ">
                        {{ "lang_field_required" | language }}
                      </mat-error>
                    </mat-form-field>
                  </div>

              
                  <div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                    <mat-form-field appearance="outline" style="width: 100%;">
                      <mat-label>{{ "lang_title_arabic" | language }}</mat-label>
                      <input matInput formControlName="group_title_arabic" required />
                      <mat-error *ngIf="
                                                      form.controls['group_title_arabic'].touched &&
                                                      form.controls['group_title_arabic'].errors
                                                          ?.required
                                                  ">
                        {{ "lang_field_required" | language }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="row col-md-12">
                    <div formArrayName="group_users" class="mb-3 w-100  col-md-6"  *ngFor="let creds of  form.value.group_users; let i = index;trackBy:trackByFnCustom">
                        <div [formGroupName]="i" class="row" [ngClass]="{'borderTop': i != 0}">
                            <div class="col-12 col-sm-6 mb-1">
                                <h5 class="head">
                                    {{'lang_user_no'|language}}. {{i+1}}
                                </h5>
                              </div>
                              <div  class="col-12 col-sm-6 mb-1" style="text-align: right;">
                                <a class="danger" *ngIf="i >0 " (click)="deleteUser(i)"   matTooltip="{{'lang_delete_user'|language}}" >
                                    <i class="fa fa-trash deleteICO font-medium-3"></i>
                                </a>
                            </div>
                        
                         
                              <div class="col-12 col-sm-12 mb-10">
                                  <label class="mb-10">
                                      {{ "lang_user" | language }}
                                      <span class="red"> * </span> </label>
                  
                                      <ng-select
                                      [items]=" form.controls['group_users']['controls'][i]['controls']?.usersArr?.value"
                                      [multiple]="false"
                                      bindLabel="label"
                                      bindValue="value"
                                      clearAllText="Clear"
                                       
                                      appendTo="body"
                                      formControlName="group_users"
                                      placeholder="{{ 'lang_user' | language }}"
                                      (search)="search_users($event,form.controls['group_users']['controls'][i])"
                                      
                                      [searchFn]="ds.customSearchFn"
                                       
                                  >
                                  </ng-select>
                                  <mat-error
                                      *ngIf="
                                      submitted &&
                                      form.controls['group_users']['controls'][i]['controls']?.group_users.errors
                                      "
                                  >
                                      {{ "lang_field_required" | language }}
                                  </mat-error>
                               
                              </div>
      
                        <!--   <div class="row">
                          
                           
                          </div> -->
       <!--                    <hr width="70%" class="m-auto"> -->
                        </div>
                      </div>
                      <a (click)="addUsers()" class="btn customButton mb-4 mx-2">
                        {{'lang_add_user'|language}}
                      </a>
                  </div>
                
               

 
              </div>

          </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                <button type="button" routerLink="/human_resources/attendance" class="albutton mnabrbutton mat-accent"
                  mat-button>
                  {{ "lang_groups" | language }}
                </button>
              </div>
              <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
               <button type="submit" (click)="submitForm()" class="albutton mnabrbutton mat-accent" mat-button>
                  {{ "lang_submit" | language }}
                </button> 
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
  
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </section>
  </form>