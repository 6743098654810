import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AttachmentsComponent } from '../panels/attachments/attachments.component';

@Component({
  selector: 'app-eview',
  templateUrl: './eview.component.html',
  styleUrls: ['./eview.component.scss']
})
export class EviewComponent implements OnInit {
  @ViewChild(AttachmentsComponent) attachcomponent: AttachmentsComponent;
  public profile_percentage: number;
  module: string = 'hr';
  apiurl = environment.SERVER_ORIGIN;
  id = this.route.snapshot.paramMap.get('id');
  branch_id = this.route.snapshot.paramMap.get('bid');
  key = this.route.snapshot.paramMap.get('key');
  delete: boolean = false;
  edit: boolean = false;
  add: boolean = false;
  is_admin: any = false;
  employee_id_number: string;
  viewdata: any;
  attachments: any = [];
  idfilename = this.lang.transform('lang_select_files');
  formstatus: boolean = false;
  formerror: string = "";
  attachform: FormGroup;
  view_profile_image = "";
  modalRefA: BsModalRef;
  actionmethod = "EVIEW"
  filepermissionlevels:any = [];
  selectedIndex = 0;
  @ViewChild('stepper') myStepper: MatStepper;

  constructor(public router:Router, public ds:DataService,public route :ActivatedRoute, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.get_view_details();
    this.file_permission_levels();
    this.attach_form();
  }

  selectionChange(e: any) {
    this.selectedIndex = e.selectedIndex;
  }

  public file_permission_levels() {
		this.filepermissionlevels = [];		
		this.ds.getActionByUrl([], "lflevel").subscribe(res => {
			if (res.status) {
				this.filepermissionlevels = res.records;
			}
		});
	}	
  @ViewChild('stepper') stepper: MatStepper;
  selectedName = 'human_resources_id_details';
  lang_key = localStorage.getItem('lang_key') || 'en';
  customOptions: OwlOptions = {
    loop: false,
    rtl: this.lang_key == 'en'? false: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 6
      },
      1200: {
        items: 6
      }
    },
    nav: false
  }
  moveStepper(name:string) {
    const icons = document?.querySelectorAll('.stepper-header-icon');
    const selectedIcon = document?.querySelector(`.${name}`);
    let index:number=0;
    icons.forEach((item, key) => {
      item?.classList?.remove('stepper-active');
      if(item?.classList?.contains(name)) {
        index = key;
      }
    })
    selectedIcon?.classList?.add('stepper-active');
    this.stepper.selectedIndex = index;
    this.selectedName = name;
  }

  openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  get_view_details(load: boolean = false) {
    this.ds.getActionByUrl([], 'human_resources/get_myview_data/'+this.id+'/'+this.branch_id).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.viewdata = data.data;
        console.log(this.viewdata);
        
        this.employee_id_number = data.data.employee_id_number;
        this.attachments = data.attachments;
        this.is_admin = data.is_admin;
        this.edit = data.edit;
        this.add = data.add;
        this.delete = data.delete;
        if (data.profile_percentage) {
          this.profile_percentage = data.profile_percentage.total;
        }
        if (this.viewdata.profile_image) {
          this.get_profile_image(this.viewdata.profile_image);
        }
      } else {
        this.viewdata = "";
        this.ds.dialogf('', data.error);
      }
    }, error => {
      this.spinner.hide();
      this.viewdata = "";
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  emptyAttachForm(type) {
    this.attachform.reset();
    this.attachform.get("employee_number").setValue(this.id);
    this.attachform.get("attachment_type").setValue(type);
    this.idfilename = this.lang.transform('lang_select_files');
  }
  attach_form() {
    this.attachform = new FormGroup({
      employee_number: new FormControl(this.id, [Validators.required]),
      attachment_type: new FormControl("", [Validators.required]),
      attachment_descriptions: new FormControl("", [Validators.required]),
      attachment_link: new FormControl("", [Validators.required]),
      level_keys: new FormControl("", [Validators.required]),
    });
  }
  uploadIDFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.attachform.get("attachment_link").setValue(fileid, { emitEvent: true });
    } else {
      this.attachform.get("attachment_link").setValue(null);
      this.idfilename = this.lang.transform('lang_select_files');
    }
  }
  load_updated_attachments(event) {
    this.get_view_details(true);
    this.idfilename = this.lang.transform('lang_select_files');
    this.attachcomponent.get_employee_attachments();
  }
  upload_files() {
    this.spinner.show();
    this.ds.postActionByUrl(this.ds.json2formdata(this.attachform.value), 'hr_upload_attachments').subscribe(data => {
      this.spinner.hide();
      this.formstatus = data.status;
      if (data.status) {
        this.get_view_details(true);
        this.load_updated_attachments('');
        setTimeout(() => {
          this.modalRefA.hide();
        }, 2000);
        this.alert.success(data.msg);
      } else {
        this.alert.error(data.error);
      }
    }, error => {
        this.spinner.hide();
        this.alert.error(error.error + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    });
  }
  get_profile_image(url) {
    this.view_profile_image = "";
    this.ds.getActionByUrl([], url).subscribe(data => {
      if (data.status) {
        this.view_profile_image = 'data:' + data['content-type'] + ';base64,' + data.base64;
      }
    });
  }
  //dynamic loading
  create_contract_form(event) {
    this.actionmethod = "CTRCR";
  }
  showEmployeeView(event) {
    this.actionmethod = "EVIEW";
    this.selectedIndex = 0
    this.selectedName = 'human_resources_id_details';
  }
  showMainPage(event) {
    this.actionmethod = "EVIEW";
    this.selectedIndex = 0
    this.selectedName = 'human_resources_id_details';
  }
  create_joining_form(event) {
    this.actionmethod = "JNGCR";
  }
  create_vacation_form(event) {
    this.actionmethod = "V1CR";
  }

  create_loan_form(event) {
    console.log('event',event);
    
    this.actionmethod = event;
  }
}