import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { MaterialExtensionsModule } from '@ng-matero/extensions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgProgressModule } from 'ngx-progressbar';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ErrorCodeComponent } from './components/error-code/error-code.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ToObservablePipe } from './pipes/to-observable.pipe';
import { LanguagePipe } from './pipes/language.pipe';
import { AlertComponent } from './components/alert/alert/alert.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { ConfirmationDialog } from './components/confirmation-dialog/confirmation-dialog.component';
import { FileviewComponent } from './components/fileview/fileview.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { UnitLevelShowComponent } from './components/unit-level-show/unit-level-show.component';
import { ShortenLengthPipe } from './pipes/shorten-length.pipe';
import { TransactionAttachmentsComponent } from './components/transaction-attachments/transaction-attachments.component';
import { VersionsShowComponent } from './components/versions-show/versions-show.component';
import {MatSelectModule} from '@angular/material/select';
import { PersonDetailsComponent } from './components/person-details/person-details.component';
import { TransactionsNotesComponent } from './components/transactions-notes/transactions-notes.component';
import { TransactionsHistoryComponent } from './components/transactions-history/transactions-history.component';
import { PreviewPdfComponent } from './components/preview-pdf/preview-pdf.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {MatSliderModule} from '@angular/material/slider';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Formhrv1AddComponent } from 'app/routes/transactions/FORM_HRV1/formhrv1-add/formhrv1-add.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { AddStepUserComponent } from './components/add-step-user/add-step-user.component';

const MODULES = [
  NgbModule,
  MaterialModule,
  MaterialExtensionsModule,
  FlexLayoutModule,
  NgProgressModule,

  NgSelectModule,
  FormlyModule,
  FormlyMaterialModule,
  ToastrModule,
  TranslateModule,
  PdfViewerModule,
  MatSliderModule,
  VirtualScrollerModule,
  NgxSkeletonLoaderModule
];
const COMPONENTS = [NoDataComponent,Formhrv1AddComponent,BreadcrumbComponent, PageHeaderComponent, TransactionAttachmentsComponent, UnitLevelShowComponent, ErrorCodeComponent, AlertComponent,AlertComponent, 
  ConfirmationDialog, AlertDialogComponent, FileviewComponent, PageTitleComponent, PersonDetailsComponent, VersionsShowComponent, TransactionsNotesComponent, TransactionsHistoryComponent];
const COMPONENTS_DYNAMIC = [];
const DIRECTIVES = [DisableControlDirective];
const PIPES = [SafeUrlPipe, ToObservablePipe,LanguagePipe, ShortenLengthPipe];

@NgModule({
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, ...DIRECTIVES, ...PIPES, PreviewPdfComponent, NotificationsComponent, AddStepUserComponent],
  imports: [CommonModule, 
      CarouselModule, FormsModule, RouterModule, ReactiveFormsModule, ...MODULES],
  exports: [
    CommonModule,
    FormsModule,

    RouterModule,
    ReactiveFormsModule,
    MatSelectModule,
    CarouselModule,
    ...MODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  entryComponents: COMPONENTS_DYNAMIC,
})
export class SharedModule {}
