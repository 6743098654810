

<breadcrumb></breadcrumb>

<section class="panel-expansion" [@fadeIn]>
	<mat-accordion class="stylepanel groups_data">
		<mat-expansion-panel class="mpanel" [expanded]="true">
			<mat-expansion-panel-header class="mpanel-header">{{
				"lang_add_shift" | language
			}}</mat-expansion-panel-header>
			<div class="material-panel-body tlist">
				<div class="mbrl15 fromtransactions">
                    <div class="row">
                        <div class="col-md-12">
                         <!--    <div class="View-title row col-md-12 m-auto">
                                <div class="col-12 col-sm-4 mb-2" >
                                    <button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                                    (viewDateChange)="closeOpenMonthViewDay('previous')"
                                    class="albutton mnabrbutton">
                                  {{'lang_next'|language}}
                                </button>
                                </div>
                                <div class="col-12 col-md-4 text-center">
                                    
                                <h3 style="padding: 5px 60px;">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                              
                                </div>
                    
                                <div class="col-12 col-sm-4 mb-2" >
                                    <button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                                    (viewDateChange)="closeOpenMonthViewDay('next')" class="albutton mnabrbutton">
                                    {{'lang_previos'|language}}
                                </button>
                                </div>
                            </div> -->
                    
                            
                        </div>
                    </div> 
                    
                    
                    
                    
                    <div class="row my-3">
                        <div class="col-12 booking-pages">
                            <div class="Calendar-view" [ngClass]="{'backgorund-calender': activeDayIsOpen}">
                                <mwl-calendar-month-view [viewDate]="viewDate" [events]="form.value.times" [refresh]="refresh"
                                    [openDayEventsTemplate]="monthOpenDayEventsTemplate" [activeDayIsOpen]="activeDayIsOpen"
                                    (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
                                    (eventTimesChanged)="eventTimesChanged($event)" [cellTemplate]="monthCellTemplate">
                                    {{'Month'|translate}}
                                </mwl-calendar-month-view>
                    
                            </div>
                        </div>
                    </div>
                    
                    
                    
                    <ng-template #monthCellTemplate let-day="day" let-locale="locale">
                        <span class="cal-day-number" style="
                        letter-spacing: 0px;
                        color: #5C606B !important;"
                        >{{ day.date | date:'dd' }} </span
                      >
                        <ng-container *ngFor="let item of day.events; let i =index">
                            <ng-container *ngIf="i == 0 || i == 1">
                            
                                
                                <div class="event event-details-month" style="width: 98%;text-align: start;" [ngClass]="[
                                    item?.shift_time_day_off == 1   ? 'event-details__confirmed' :
                                 
                                    (item?.shift_time_day_off ==  0 && item?.shift_start_time)  ? 'event-details__available' :
                                    (item?.shift_time_day_off ==  0 && !item?.shift_start_time) ? 'event-details__cancelled' :
                                     '']"
                                    [contextMenu]="basicMenu" [contextMenuSubject]="item.data"   >
                                    <span>
                                        <span class="calendar-time-details" style="font-weight: bold;
                                       
                                        font-size: 10px;">
                                       <!--  <span *ngIf="item.data.status != 'vacation'"> 
                                            {{item.start | date: "hh:mm a"}}  
                                        </span> -->
                                        
                                            <span style="margin-left:5px;font-weight: bold;
                                            font-family: 'Times New Roman', Times, serif;
                                            font-size: 10px;"  >
                                                
                                                <span  *ngIf="item.shift_start_time && item.shift_end_time">
                                                    {{'lang_from'|translate}}:
                                                   {{item.shift_start_time}} {{'lang_to'|translate}}: {{item.shift_end_time}}
                                                </span>
                    
                    
                                                <span  *ngIf="(!item.shift_start_time || !item.shift_end_time) && !item.shift_time_day_off ">
                                                    {{'lang_click_to_add_shift'|translate}}
                                                    
                                                </span>
                                                
                                                <span  *ngIf="(!item.shift_start_time || !item.shift_end_time) && item.shift_time_day_off ">
                                                    {{'lang_day_off'|translate}}
                                                    
                                                </span>
                                            </span>
                    
                                           <!--  <span style=" margin-left:5px; font-weight: bold;
                                            font-family: 'Times New Roman', Times, serif;
                                            font-size: 13px;" *ngIf="item.data.type != 'booking'">
                                                {{item.title}}
                                            </span> -->
                                        </span>
                                       <!--  <span class="fl-rit" *ngIf="item.data.status === 'available' && tutorId != '0'">
                                            <span (mwlClick)="edit(item)"><i class="far fa-edit custom_fontSize color-edit mr-7"></i></span>                    
                                            <span (mwlClick)="onDeleteSession(item)"><i class="far light-brown fa-trash-alt custom_fontSize mr-5"></i></span>
                                        </span> -->
                                     </span>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-template>
                    
                    
                    
                    <ng-template #monthOpenDayEventsTemplate let-events="events" let-isOpen="isOpen">
                    
                        <div class="cal-open-day-events" *ngIf="isOpen">
                            <h2 class="cal-title">
                                <span class="cal-title-icon" >
                                    <mat-icon style="color: #fff;">calendar_today</mat-icon>
                                </span>
                                <span class="cal-title-text" >
                                    {{viewDate | date:'fullDate' }}
                                </span>
                            </h2>
                    
                            <ng-container *ngFor="let event of events">
                                <div class="cals-events-container" style="text-align: start;" [ngClass]="event?.cssClass">
                                    <div class="cals-event-item w-100 justify-content-spacebetween " style="padding: 10px 10px;" [ngClass]="[
                                    event.data.status === 'confirmed' ? 'event-details__confirmed' :
                                    event.data.status === 'cancelled' ? 'event-details__cancelled' :
                                    event?.shift_time_day_off ===  0   ? 'event-details__pending' :
                                    event.data.status === 'available'   ? 'event-details__available' :
                                    event?.shift_time_day_off ===  1   ? 'event-details__confirmed' :'']">
                     
                                        <span>
                                            <span class="calendar-time-details" style="font-weight: bold;font-size: 10px;">
                                          
                                            
                                              <form [formGroup]="form.controls.times.controls[event.index]">
                                                <div
                                                
                                                [ngClass]="{ borderTop: i != 0 }"
                                            >
                                                <div class="row">
                                                  <!--   <div class="form-group col-md-6 mb-1">
                                                        <h5 class="head">
                                                            {{ "lang_plan_no" | language }}.
                                                            {{ i + 1 }}
                                                        </h5>
                                                    </div>
                                                    <div
                                                        class="form-group col-md-6 mb-1"
                                                        style="text-align: right"
                                                    >
                                                        <a
                                                            class="danger"
                                                            *ngIf="
                                                                i <
                                                                getSmallSteps.project_plan_requirements
                                                                    ?.value.length -
                                                                    plans.length
                                                            "
                                                            (click)="deletePaln(i)"
                                                            matTooltip="{{
                                                                'lang_delete_plan' | language
                                                            }}"
                                                        >
                                                            <i
                                                                class="fa fa-trash deleteICO font-medium-3 m-2"
                                                            ></i>
                                                        </a>
                                                    </div> -->
                                                    <div
                                                        class="col-12 col-sm-3"
                                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
                                                    >
                                                        <mat-form-field
                                                            style="width: 100%"
                                                            appearance="outline"
                                                        >
                                                            <mat-label>{{
                                                                "lang_shift_start_time" | language
                                                            }}</mat-label>
                    
                                                            <input
                                                                matInput
                                                                type="text"
                                                                (keyup)="makekeyword($event, i)"
                                                                formControlName="shift_start_time"
                                                                required
                                                            />
                                                            <mat-error
                                                                *ngIf="
                                                                    form.controls[
                                                                        'times'
                                                                    ]['controls'][event.index]['controls']
                                                                        ?.shift_start_time.touched &&
                                                                        form.controls[
                                                                        'times'
                                                                    ]['controls'][event.index]['controls']
                                                                        ?.shift_start_time.errors
                                                                        ?.required
                                                                "
                                                            >
                                                                {{ "lang_field_required" | language }}
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                    
                                                    <div
                                                        class="col-12 col-sm-3"
                                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
                                                    >
                                                        <mat-form-field
                                                            style="width: 100%"
                                                            appearance="outline"
                                                        >
                                                            <mat-label>{{
                                                                "lang_shift_end_time" | language
                                                            }}</mat-label>
                    
                                                            <input
                                                                matInput
                                                                type="text"
                                                                formControlName="shift_end_time"
                                                                required
                                                            />
                                                            <mat-error
                                                                *ngIf="
                                                                form.controls[
                                                                'times'
                                                            ]['controls'][event.index]['controls']
                                                                ?.shift_end_time.touched &&
                                                                form.controls[
                                                                'times'
                                                            ]['controls'][event.index]['controls']
                                                                ?.shift_end_time.errors?.required
                                                                "
                                                            >
                                                                {{ "lang_field_required" | language }}
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                    
                                                </div>
                                             
                                            </div>
                                              </form>
                         
                                          
                                            </span>
                                           <!--  <span class="fl-rit" *ngIf="item.data.status === 'available' && tutorId != '0'">
                                                <span (mwlClick)="edit(item)"><i class="far fa-edit custom_fontSize color-edit mr-7"></i></span>                    
                                                <span (mwlClick)="onDeleteSession(item)"><i class="far light-brown fa-trash-alt custom_fontSize mr-5"></i></span>
                                            </span> -->
                                         </span>
                                         
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>
                    
				</div>
			 
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</section>

