import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { platformRequirements } from '../../formdata';
import { DwsrService } from '../../services/dwsr.service';
import { ByPhasesComponent } from './by-phases/by-phases.component';
import { GeneralNumbersComponent } from './general-numbers/general-numbers.component';

@Component({
  selector: 'app-t-step1',
  templateUrl: './t-step1.component.html',
  styleUrls: ['./t-step1.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class TStep1Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() form_url;
  @Input() unitsOfTemplate;
  allData:any;
  lang_form : FormGroup;
  form : FormGroup;
  work_sites_form:FormGroup;
  config_attachment_form:FormGroup;
  other_attachments:FormArray;
  selectmultiple                          = false;
  nodata: string                          = this.lang.transform('lang_no_data');
  filename:string                         = this.lang.transform('lang_select_files');
  user_type                               = this.ls.getItem('user_type_id') || 0;
  lang_key                                = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
  general_different_cluster_allowed:any   = "1";
  phases_different_cluster_allowed:any    = "0";
  languages:any                           = [];
  gNumbers:any                            = [];
  work_levels:any                         = [];
  platformData:any                        = [];
  versions:any;
  platformRequirements:any                = platformRequirements;
  submitted:boolean                       = false;
  platform_page_no:any                    = 1;
  platform_page_size:any                  = 10;
  versions_page_no:any                    = 1;
  versions_page_size:any                  = 10;
  generalNumbersData                      = [];
  phasesData                              = [];
  units_for_all:any;
  validUnitsForAll = false;
  work_levels_for_all                     = [];
  work_levels_for_all_control             = new FormControl(null, Validators.required);
  template_id:any;
  @ViewChild('stepper') stepper: MatStepper;
  canMoveStep2 = new FormControl(null, Validators.required);
  canMoveStep3 = new FormControl(null, Validators.required);
  SkipPagePlatform= false;
  NextButtonPlatform = false;
  platform_code_system = '';
  projects_work_area_id= 0;
  @Input() editMode;
  contractor_team_users_list = [];
  constructor(public ls:LocalStorage, private sweetAlert:SweetAlertService, private dwsrService:DwsrService , public ds:DataService, public dialog: MatDialog, public datepipe : DatePipe , public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) {}
  ngOnInit() {
    this.get_required_data('tc/tlanguages', 'lang');
    this.lang_form = new FormGroup({
      lang_key     : new FormControl(this.lang_key, [Validators.required]), 
    });
    
    this.work_sites_form = new FormGroup({
      work_site      : new FormControl('', [Validators.required]),
      separate       : new FormControl('0')
    });

    this.config_attachment_form = new FormGroup({
      attachments                 : this.fb.array([]),
    });
    if(this.user_type == '3' || this.user_type == '4') {
      this.config_attachment_form.addControl('contractor_team_users', new FormControl(null));
      this.config_attachment_form.addControl('contractor_manager_step_require', new FormControl('0'));
      this.get_contractor_team_users_list();
    }
    this.other_attachments = this.config_attachment_form.get("attachments") as FormArray;
    if(this.editMode) {
      this.dwsrService.getData()?.subscribe(data => {
        this.allData = data;        
        this.setEditData();
      });
    }
  }
  public get_required_data(url, key) {
    this.languages = [];
    this.ds.getActionByUrl([], url).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        if (key === 'lang') {
          this.languages = res.records;
        }
      }
    })
  }
  //upload multiple files starts
  get filesFormGroup() {
    return this.config_attachment_form.get("attachments") as FormArray;
  }
  
  setEditData() {
    if(this.allData) {
      this.lang_form.get('lang_key').setValue(this.allData?.view_request?.transactions_request?.records?.lang_key);
      this.platform_code_system = this.allData?.view_request?.form_dsr_data?.records[0]?.platform_code_system;
      this.projects_work_area_id = this.allData?.view_request?.form_dsr_data?.records[0]?.projects_work_area_id;
      if(this.user_type == '3' || this.user_type == '4') {
        this.config_attachment_form.get('contractor_team_users').setValue(this.allData?.view_request?.form_dsr_data?.records[0]?.contractor_team_users);
        this.config_attachment_form.get('contractor_manager_step_require').setValue(this.allData?.view_request?.form_dsr_data?.records[0]?.contractor_manager_step_require);
      }
      this.template_id = this.allData?.view_request?.form_dsr_data?.records[0]?.template_id;
      let units = [];
      let workLevels =[];
      let displayWorklevels= [];
      for(let item of this.allData?.view_request?.project_supervision_form_unit_level?.records) {
        units.push(item?.unit_id);
        workLevels.push(item?.work_level_key);
        displayWorklevels.push(item?.work_level_label)
      }
      workLevels =  workLevels.filter(this.dwsrService.onlyUnique);
      this.work_sites_form.get('work_site').setValue('GN');
      this.generalNumbersData.push({
        work_levels:workLevels.toString(),
        units:units.toString(),
        displayWorklevels: displayWorklevels.toString()
      })
    }
  }
  public files(value?): FormGroup {
    return this.fb.group({
      delete_icon     : new FormControl(value?.attach_title || ''),
      attach_title    : new FormControl({value : (value) ? value?.attach_title : '', disabled : (value?.attach_title) ? true : false}, [Validators.required]),
      file            : new FormControl(null,  [Validators.required]),
    });
  }
  public addfiles(value?) {
    if (this.other_attachments.length < 10) {
      this.other_attachments.push(this.files(value));
      this.other_attachments.setValidators(Validators.required);
      this.other_attachments.updateValueAndValidity();
    }
  }
  public removefiles(index) {
    if(this.other_attachments.length == 1) {
      this.other_attachments.removeAt(index);
      this.other_attachments.clearValidators();
      this.other_attachments.updateValueAndValidity();
    } else {
      this.other_attachments.removeAt(index);
    }
  }
  //Search For Step 2 Starts Work Levels
  public getUnitsForAll() {
      this.validUnitsForAll = false;
      this.spinner.show();
      let param = new FormData();
      param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
      param.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
      param.append('work_site', this.work_sites_form.get('work_site').value || '');
      param.append('transaction_separation', this.work_sites_form.get('separate')?.value);
      param.append('template_id', this.template_data?.template_id || this.template_id);
      param.append('work_site_type', 'ALL');
      param.append('work_levels', this.work_levels_for_all_control?.value?.toString());
      this.ds.getActionByUrl(this.ds.formData2string(param), 'form/'+this.form_url+'/validate_work_levels').subscribe((res) => {
        this.spinner.hide();
        if(res.status) {
          this.validUnitsForAll = true;
          this.units_for_all = res.data;
        }
      }, error => {
        this.spinner.hide();
        this.sweetAlert.errorMessage(this.lang.transform("lang_server_error"));
      })
    
  }

  public set_validation_step1() {
    this.alert.clear();
    this.work_sites_form.updateValueAndValidity();
  }

  public get_work_levels() {
      if(this.work_sites_form.get('work_site').value == 'ALL') {
        this.alert.clear();
        this.spinner.show();
        this.work_levels  = [];
      
      let param = new FormData();
      param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
      param.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
      param.append('transaction_separation', this.work_sites_form.get('separate')?.value);
      param.append('template_id', this.template_data?.template_id || this.template_id);
      param.append('lang_key', this.lang_form.get('lang_key')?.value);
      param.append('work_site', 'ALL');
        this.ds.getActionByUrl(this.ds.formData2string(param), 'form/'+this.form_url+'/get_work_levels_for_transaction').subscribe((res) => {
          this.spinner.hide();
          if(res.status) {
            this.work_levels_for_all = res.records;
            
          } else {
            this.sweetAlert.errorMessage(res.error);
          }
        }, error => {
          this.spinner.hide();
          this.sweetAlert.errorMessage(this.lang.transform("lang_server_error"));
        });
      }
  }

  checkValidation() {
    if((this.work_sites_form.get('work_site').value =="GN" &&
    this.generalNumbersData.length > 0) ||
    (this.work_sites_form.get('work_site').value =="IM" &&
    this.phasesData.length > 0) || (this.work_sites_form.get('work_site').value == 'ALL' && this.work_levels_for_all_control.valid && this.work_levels_for_all?.length && this.validUnitsForAll)) {
      return true;
    } else {
      return false;
    }
  }
  public send_units_and_work_levels(isPagination:boolean) {
    this.alert.clear();
    if(this.checkValidation()) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
      formData.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
      formData.append('lang_key', this.lang_form.get('lang_key')?.value);
      formData.append('transaction_separation', this.work_sites_form.get('separate')?.value);
      formData.append('page_no', this.platform_page_no);
      formData.append('page_size', this.platform_page_size);
      
      formData.append('work_site', this.work_sites_form.get('work_site').value);
      if(this.work_sites_form.get('work_site').value == 'ALL') {
        for(let key in this.units_for_all) {
          formData.append(`units_and_level[${key}]`, this.units_for_all[key]);
        }
      } else if(this.work_sites_form.get('work_site').value == 'GN') {
        
        let units_and_level_data = this.dwsrService.refinementData(this.generalNumbersData);
        for(let key in units_and_level_data) {
          formData.append(`units_and_level[${key}]`, units_and_level_data[key]);
        }
        
      } else if(this.work_sites_form.get('work_site').value == 'IM') {
        let units_and_level_data = this.dwsrService.refinementData(this.phasesData);
        for(let key in units_and_level_data) {
          formData.append(`units_and_level[${key}]`, units_and_level_data[key]);
        }
        
      }
      formData.append('template_id', this.template_data?.template_id || this.template_id);
      
      let stepUrl = '/cr/1/0';
      if(this.editMode) {
        stepUrl = `/cr/1/${this.form_request_id}`;
      }
      this.ds.post('form/'+this.form_url + stepUrl, formData).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.getDefaultAttachments();
          this.platformData = res;
          this.canMoveStep2.setValue(true);
          if(res['SkipPage']) {
            this.canMoveStep3.setValue(true);
            this.SkipPagePlatform = true;
          }
          if(res['NextButton']) {
            this.canMoveStep3.setValue(true);
            this.NextButtonPlatform = true;
          } else {
            this.canMoveStep3.setValue(null);
          }
          if(!isPagination) {
            this.stepper.selectedIndex++;
          }
          
        } else {
          this.sweetAlert.errorMessage(res.error);
        }
        
      }, err => {
        this.spinner.hide();
        this.sweetAlert.errorMessage(err.error);
      })
    } else {
      this.sweetAlert.errorMessage(this.lang.transform("lang_all_fields_are_required"));
    }
  }

  get_form_versions() {
    this.alert.clear();
    if(this.checkValidation()) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
      formData.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
      formData.append('lang_key', this.lang_form.get('lang_key')?.value);
      formData.append('transaction_separation', this.work_sites_form.get('separate')?.value);
      formData.append('page_no', this.versions_page_no);
      formData.append('page_size', this.versions_page_size);
      
      formData.append('work_site', this.work_sites_form.get('work_site').value);
      if(this.work_sites_form.get('work_site').value == 'ALL') {
        for(let key in this.units_for_all) {
          formData.append(`units_and_level[${key}]`, this.units_for_all[key]);
        }
      } else if(this.work_sites_form.get('work_site').value == 'GN') {
        
        let units_and_level_data = this.dwsrService.refinementData(this.generalNumbersData);
        for(let key in units_and_level_data) {
          formData.append(`units_and_level[${key}]`, units_and_level_data[key]);
        }
        
      } else if(this.work_sites_form.get('work_site').value == 'IM') {
        let units_and_level_data = this.dwsrService.refinementData(this.phasesData);
        for(let key in units_and_level_data) {
          formData.append(`units_and_level[${key}]`, units_and_level_data[key]);
        }
        
      }
      formData.append('template_id', this.template_data?.template_id || this.template_id);
      
      let stepUrl = '/cr/3/0';
      if(this.editMode) {
        stepUrl = `/cr/3/${this.form_request_id}`;
      }
      this.ds.post('form/'+this.form_url + stepUrl, formData).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.versions = res;
        }
        
      }, err => {
        this.spinner.hide();
        this.sweetAlert.errorMessage(this.lang.transform('lang_server_error'));
      })
    } else {
      this.sweetAlert.errorMessage(this.lang.transform("lang_all_fields_are_required"));
    }
  }

  get_contractor_team_users_list() {
    this.contractor_team_users_list  = [];
		let param   = new FormData();
		param.append('lang_key', this.lang_key);
		param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
    param.append('user_position', 'CTT01');
    
		this.ds.getActionByUrl(this.ds.formData2string(param), `form/${this.form_url}/contractor_users`).subscribe(res => {
			if (res.status) {
				this.contractor_team_users_list = res?.records;
			} else {
        this.contractor_team_users_list = [];
      }
		});
  }

  moveToPlatform() {
    if(this.canMoveStep3.value) {
      if(this.SkipPagePlatform) {
        this.stepper.selectedIndex++;
      }
    }
  }

  public load_by_page_size(size, tableName:string) {
    if(tableName == 'platform') {
      this.platform_page_size = size;
      this.send_units_and_work_levels(true);
    }
    if(tableName == 'versions') {
      this.versions_page_size = size;
      this.get_form_versions();
    }
    
  }

  public load_by_page_no(page, tableName:string) {
    if(tableName == 'platform') {
      this.platform_page_no = page;
      this.send_units_and_work_levels(true);
    }
    if(tableName == 'versions') {
      this.versions_page_no = page;
      this.get_form_versions();
    }
  }

  getDefaultAttachments() {
    let param = new FormData();
      param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
      param.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
      param.append('lang_key', this.lang_form.get("lang_key").value);
      param.append('work_site', this.work_sites_form.get('work_site').value);
      
      if(this.work_sites_form.get('work_site').value == 'ALL') {
        for(let key in this.units_for_all) {
          param.append(`units_and_level[${key}]`, this.units_for_all[key]);
        }
      } else if(this.work_sites_form.get('work_site').value == 'GN') {
        let units_and_level_data = this.dwsrService.refinementData(this.generalNumbersData);
        for(let key in units_and_level_data) {
          param.append(`units_and_level[${key}]`, units_and_level_data[key]);
        }
      } else if(this.work_sites_form.get('work_site').value == 'IM') {
        let units_and_level_data = this.dwsrService.refinementData(this.phasesData);
        for(let key in units_and_level_data) {
          param.append(`units_and_level[${key}]`, units_and_level_data[key]);
        }
      }
      this.other_attachments.clear();
      if(this.user_type == "1") {
        this.addfiles({attach_title : this.lang.transform('lang_sign_and_stamp_contractor_request')});
        this.addfiles({attach_title : this.lang.transform('lang_stamp_PDF_drawings')});
        this.addfiles({attach_title : this.lang.transform('lang_drawings_files')});
      } else if (this.user_type == "4") {
        this.addfiles({attach_title : this.lang.transform('lang_stamp_PDF_drawings')});
        this.addfiles({attach_title : this.lang.transform('lang_drawings_files')});
      }
      this.spinner.show();
      this.ds.postActionByUrl(param, 'form/'+this.form_url+'/get_default_attachments').subscribe((res) => {
        this.spinner.hide();
        if(res.status) {
          for(let item of res?.records) {
            this.addfiles({attach_title : item?.label, file: item?.file_path});
          }
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
  }

  public send_files_to_step2() {
    this.alert.clear(); 
    if(this.lang_form.valid && this.work_sites_form.valid && this.platformData?.NextButton && this.config_attachment_form.valid) {
      this.spinner.show();
      let param = new FormData();
      param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
      param.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
      param.append('lang_key', this.lang_form.get("lang_key").value);
      param.append('transaction_separation', this.work_sites_form.get('separate').value);
      param.append('template_id', this.template_data?.template_id || this.template_id);
      param.append('work_site', this.work_sites_form.get('work_site').value);
      if(this.user_type == '3' || this.user_type == '4') {
        param.append('contractor_team_users', this.config_attachment_form.get('contractor_team_users')?.value || '');
        param.append('contractor_manager_step_require', this.config_attachment_form.get('contractor_manager_step_require')?.value);
      }
      if(this.work_sites_form.get('work_site').value == 'ALL') {
        for(let key in this.units_for_all) {
          param.append(`units_and_level[${key}]`, this.units_for_all[key]);
        }
      } else if(this.work_sites_form.get('work_site').value == 'GN') {
        
        let units_and_level_data = this.dwsrService.refinementData(this.generalNumbersData);
        for(let key in units_and_level_data) {
          param.append(`units_and_level[${key}]`, units_and_level_data[key]);
        }
        
      } else if(this.work_sites_form.get('work_site').value == 'IM') {
        let units_and_level_data = this.dwsrService.refinementData(this.phasesData);
        for(let key in units_and_level_data) {
          param.append(`units_and_level[${key}]`, units_and_level_data[key]);
        }
        
      }
      this.filesFormGroup.value.forEach((v, k) => {
        param.set("attachments[" + k + "][file]", v.file);
        param.set("attachments[" + k + "][required]", 'Yes');
        param.set("attachments[" + k + "][attach_title]", this.other_attachments.controls[k].get("attach_title").value);        
      });
      let stepUrl = '/cr/2/0';
      if(this.editMode) {
        stepUrl = `/cr/2/${this.form_request_id}`;
      }
      this.ds.postActionByUrl(param, 'form/'+this.form_url+stepUrl).subscribe((res) => {
        this.spinner.hide();
        if(res.status) {
          this.sweetAlert.successToast(this.lang.transform('lang_form_created_successfully'), 400);
          setTimeout(() => {
            if(this.editMode) {
              this.refreshComponent.emit(true);
            } else {
              this.router.navigate(['/transactions/allforms']);
            }
          }, 500);
        } else {
          this.sweetAlert.errorMessage(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.sweetAlert.errorMessage((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
    } else {
      this.sweetAlert.errorMessage(this.lang.transform('lang_fill_required_fields'));
    }
  }

  addByGeneralNumbers() {
    const dialogRef = this.dialog.open(GeneralNumbersComponent, {
      width:'800px',
      data: {
        supervision_data: this.supervision_data,
        form_key: this.platform_code_system || this.form_key,
        form_url: this.form_url,
        work_site: 'GN',
        lang_key: this.lang_form.get('lang_key').value,
        separate: this.work_sites_form.get('separate').value,
        generalNumbersData: this.generalNumbersData,
        projects_work_area_id: this.projects_work_area_id,
        template_id: this.template_data?.template_id || this.template_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result?.form?.work_levels.length && result?.form?.work_site_general_nos?.length) {
        let findData = this.generalNumbersData.find((item) => {
          return item?.work_levels == result.form.work_levels.toString() && item?.units == result.form.work_site_general_nos.toString()
        });
        if(findData) {
          this.sweetAlert.errorMessage(this.lang.transform('lang_do_not_add_duplicated_data'));
        } else {
          this.generalNumbersData.push({
            work_levels:result.form.work_levels.toString(),
            units:result.form.work_site_general_nos.toString(),
            displayWorklevels: result.work_levels.toString()
          })
        }
      
      }
    });
  }

  deleteByGeneralNumbers(index:any) {
    this.generalNumbersData.splice(index, 1);
  }

  addByPhases() {
    const dialogRef = this.dialog.open(ByPhasesComponent, {
      width:'700px',
      data: {
        supervision_data: this.supervision_data,
        form_key: this.platform_code_system || this.form_key,
        form_url: this.form_url,
        work_site: 'IM',
        separate: this.work_sites_form.get('separate').value,
        phasesData: this.phasesData,
        projects_work_area_id: this.projects_work_area_id,
        template_id: this.template_data?.template_id || this.template_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result?.form?.work_levels.length && result?.form?.work_site_units?.length) {
        let findData = this.phasesData.find((item) => {
          return item?.zone == result.form.work_site_zones && item?.block == result.form.work_site_blocks &&
          item?.cluster == result.form.work_site_clusters && item?.units == result.form.work_site_units.toString()
          && item?.work_levels == result.form.work_levels.toString()
        });
        if(findData) {
          this.sweetAlert.errorMessage(this.lang.transform('lang_do_not_add_duplicated_data'));
        } else {
          this.phasesData.push({
            zone: result.form.work_site_zones,
            block: result.form.work_site_blocks,
            cluster: result.form.work_site_clusters,
            units: result.form.work_site_units.toString(),
            work_levels: result.form.work_levels.toString(),
            displayWorklevels: result.work_levels.toString()
          })
        }
        
      }
    });
  }

  deleteByPhases(index:any) {
    this.phasesData.splice(index, 1);
  }
}
