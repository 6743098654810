<div class="theme">
    <div class="row">
        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
            <button *ngIf="actionPage =='A' " type="button" class="albutton mnabrbutton mat-accent" mat-button routerLink="/transactions/nBU2waRF8N">{{'lang_goto_list' | language }}</button>
            <button *ngIf="actionPage !='A' " type="button" class="albutton mnabrbutton mat-accent" mat-button routerLink="/transactions/allforms">{{'lang_goto_list' | language }}</button>
        </div>
        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
            <div class="dropdown" *ngIf="actionbuttons">
                <button type="button" mat-button  [matMenuTriggerFor]="menu" class="col-12 mnabrbutton mat-accent">
                    {{'lang_action' | language }}
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf="actionbuttons.edit" (click)="are_you_sure(form_key, form_request_id)" matTooltip="{{'lang_edit' | language }}">{{'lang_edit' | language }}</button>                   
                    <button mat-menu-item *ngIf="actionbuttons.signature" (click)="showverificationform('signature')" data-toggle="modal" data-target="#verifySMRDA" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_signature' | language }}">{{'lang_signature' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.marks" (click)="showverificationform('marks')" data-method="Markers" data-toggle="modal" data-target="#verifySMRDA" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_mark' | language }}">{{'lang_mark' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.opinion" (click)="showverificationform('opinion')" data-method="opinion" data-toggle="modal" data-target="#verifySMRDA" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_opinion' | language }}"> {{'lang_opinion' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.reviewers" (click)="showverificationform('reviewers')" data-method="Reviewers" data-toggle="modal" data-target="#verifySMRDA" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_review' | language }}"> {{'lang_review' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.doall" (click)="showverificationform('doall')" data-method="doall" data-toggle="modal" data-target="#verifySMRDA" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_do_all' | language }}">{{'lang_do_all' | language }} </button>
                    <button mat-menu-item *ngIf="actionbuttons.preview" (click)="preview_form_data()" matTooltip="{{'lang_preview' | language }}">{{'lang_preview' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.view" (click)="ds.PreviewData(request.view_link)" matTooltip="{{'lang_view' | language }}">{{'lang_view' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.approval" (click)="empty_approve_form();" data-method="approval" data-toggle="modal" data-target="#verifyPassword" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_approve' | language }}">{{'lang_approve' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.delete && admin" (click)="delete_form_request(form_key, form_request_id)" matTooltip="{{'lang_delete' | language }}">{{'lang_delete' | language }}</button>
                  </mat-menu>
            </div>            
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
        <div class="col-md-2 col-sm-2 col-xs-12 mb-10"></div>
    </div>
    <section class="panel-expansion">
        <mat-accordion class="stylepanels">
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_document_details' | language}}</mat-expansion-panel-header>
                <div class="material-panel-body communication">
                <div class="mbrl15">
                    <div class="row mb-10">
                        <div class="col-md-3 mb-10">
                            <h6>{{'lang_request_number' | language }} : <b>{{request.transaction_request_id}}</b></h6>
                        </div>
                        <div class="col-md-3 mb-10">
                            <h6>{{'lang_barcode' | language }} : <b>{{request.tbv_barcodeData}}</b></h6>
                        </div>
                        <div class="col-md-3 mb-10">
                            <mat-icon class="fa-icon mnabricon" *ngIf="actionbuttons.view" (click)="ds.PreviewData(request.view_link)" matTooltip="{{'lang_view' | language }}">picture_as_pdf</mat-icon>
                        </div>
                        <div class="col-md-3 mb-10">
                            <h6>{{'lang_status' | language }} :<b class="orangestatus statusdesign">{{request.transaction_request_status}}</b></h6>
                        </div>
                    </div>
                    <div class="row mb-10">
                        <div class="col-md-3 mb-10">
                            <h6>{{'lang_created_by' | language }} : <b> {{request.created_name}}</b></h6>
                        </div>
                        <div class="col-md-3 mb-10">
                            <h6>{{'lang_created_date' | language }} : <b>{{request.created_date}}</b></h6>
                        </div>
                        <div class="col-md-3 mb-10" *ngIf="submitter">
                            <h6>{{'lang_submitted_by' | language }} : <b>{{request.submitted_name}}</b></h6>
                        </div>
                        <div class="col-md-3 mb-10" *ngIf="submitter">
                            <h6>{{'lang_submitter_date' | language }} : <b>{{request.submitted_date}}</b></h6>
                        </div>
                    </div>
                </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="stylepanels">
            <mat-expansion-panel class="mpanel">
                <mat-expansion-panel-header class="mpanel-header">{{request.transaction_request_description}}</mat-expansion-panel-header>
                <div class="material-panel-body communication">
                    <div class="mbrl15">
                        <fieldset class="cardback fieldset">
                            <div class="row mb-10">
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label> {{'lang_subject' | language }} : </label>
                                        <input type="text" value="{{viewdata.subject}}" class="form-control almnabrformcontrol" disabled>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label> {{'lang_attachments' | language }} : </label>
                                        <input type="text" value="{{viewdata.attachment}}" class="form-control almnabrformcontrol" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label> {{'lang_languages' | language }} : </label>
                                        <input type="text" value="{{request.language_name}}" class="form-control almnabrformcontrol" disabled>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label> {{'lang_from' | language }} : </label>
                                        <input type="text" value="{{viewdata.transaction_from_name}}" class="form-control almnabrformcontrol" disabled>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label> {{'lang_to_org' | language }} : </label>
                                        <input type="text" value="{{viewdata.transaction_to_name}}" class="form-control almnabrformcontrol" disabled>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-10">
                                <div class="col-12">
                                    <label> {{'lang_description' | language }} : </label>
                                    <div class="contentpanel" [ngClass]="{' contentrtl': request.lang_key == 'ar' }" #contentData></div>
                                </div>
                            </div>
                        </fieldset>
                        <br>
                        <div class="row mb-10">
                            <div class="col-12">
                                <fieldset class="cardback fieldset">
                                    <legend>{{'lang_signature_stamp' | language }}</legend>
                                    <div class="row">
                                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                            <div *ngFor="let person of persons">
                                                <label *ngIf="person.transaction_persons_type == 'signature' && person.transactions_persons_val1 == 'A' "> <label> {{'lang_human_resources_job_title_iqama' | language }} : </label> A </label>
                                                <input *ngIf="person.transaction_persons_type == 'signature' && person.transactions_persons_val1 == 'A' " type="text" value="{{person.transactions_persons_val2}}" class="form-control almnabrformcontrol mb-10" disabled>
                                                <label *ngIf="person.transaction_persons_type == 'signature' && person.transactions_persons_val1 == 'A' "> {{'lang_human_resources_employee_name' | language }} A </label>
                                                <input *ngIf="person.transaction_persons_type == 'signature' && person.transactions_persons_val1 == 'A' " type="text" value="{{person.person_name}}" class="form-control almnabrformcontrol" disabled>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                            <div *ngFor="let person of persons">
                                                <label *ngIf="person.transaction_persons_type == 'signature' && person.transactions_persons_val1 == 'B' "> <label> {{'lang_human_resources_job_title_iqama' | language }} : </label> B</label>
                                                <input *ngIf="person.transaction_persons_type == 'signature' && person.transactions_persons_val1 == 'B' " type="text" value="{{person.transactions_persons_val2}}" class="form-control almnabrformcontrol mb-10" disabled>
                                                <label *ngIf="person.transaction_persons_type == 'signature' && person.transactions_persons_val1 == 'B' "> {{'lang_human_resources_employee_name' | language }} B </label>
                                                <input *ngIf="person.transaction_persons_type == 'signature' && person.transactions_persons_val1 == 'B' " type="text" value="{{person.person_name}}" class="form-control almnabrformcontrol" disabled>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-xs-12"></div>
                                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                                            <div *ngFor="let person of persons">
                                                <label *ngIf="person.transaction_persons_type == 'signature' && person.transactions_persons_val1 == 'C' "> <label> {{'lang_human_resources_job_title_iqama' | language }} : </label> C</label>
                                                <input *ngIf="person.transaction_persons_type == 'signature' && person.transactions_persons_val1 == 'C' " type="text" value="{{person.transactions_persons_val2}}" class="form-control almnabrformcontrol mb-10" disabled>
                                                <label *ngIf="person.transaction_persons_type == 'signature' && person.transactions_persons_val1 == 'C' "> {{'lang_human_resources_employee_name' | language }} C </label>
                                                <input *ngIf="person.transaction_persons_type == 'signature' && person.transactions_persons_val1 == 'C' " type="text" value="{{person.person_name}}" class="form-control almnabrformcontrol" disabled>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row mb-10">
                            <div class="col-md-6 col-xs-12 col-sm-6 mb-10">
                                <fieldset class="cardback fieldset" *ngIf="able_to_show('marks')">
                                    <legend>{{'lang_markers' | language }} </legend>
                                    <span *ngFor="let person of persons">
                                        <label class="personmarks" *ngIf="person.transaction_persons_type == 'marks' ">{{person.person_name}}</label>
                                    </span>
                                </fieldset>
                            </div>
                            <div class="col-md-6 col-xs-12 col-sm-6 mb-10">
                                <fieldset class="cardback fieldset"  *ngIf="able_to_show('reviews')">
                                    <legend>{{'lang_reviewers' | language }} </legend>
                                    <span *ngFor="let person of persons">
                                        <label class="personmarks" *ngIf="person.transaction_persons_type == 'reviews' ">{{person.person_name}}</label>
                                    </span>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="stylepanels">
            <mat-expansion-panel class="mpanel">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_person_details' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body communication">
                <div class="mbrl15">
                    <div class="col-md-12 pad0">
                        <div class="table-responsive customResponsive">
                            <table border="1" class="table CustomTable">
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>{{'lang_name' | language }}</td>
                                        <td>{{'lang_type' | language }}</td>
                                        <td>{{'lang_view' | language }}</td>
                                        <td>{{'lang_view_time' | language }}</td>
                                        <td>{{'lang_last_view_time' | language }}</td>
                                        <td>{{'lang_action' | language }}</td>
                                        <td>{{'lang_datetime' | language }}</td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="persons.length > 0" class="AppendList">
                                    <tr *ngFor="let person of persons; let i = index;let odd=odd; let even=even"
                                        [ngClass]="{ odd: odd, even: even }">
                                        <td>{{i+1}}</td>
                                        <td>{{person.person_name}}</td>
                                        <td>{{person.transaction_persons_type}}</td>
                                        <td *ngIf="person.transactions_persons_view == '1' ">
                                            <i class="fa fa-check faicon successgreen" aria-hidden="true"></i>
                                        </td>
                                        <td *ngIf="person.transactions_persons_view == '0' ">
                                            <i class="fa fa-check faicon silver" aria-hidden="true"></i>
                                        </td>
                                        <td>{{person.transactions_persons_view_datetime}}</td>
                                        <td>{{person.transactions_persons_view_datetime_lastupdate}}</td>
                                        <td *ngIf="person.transactions_persons_action_status == 1">
                                            <i class="fa fa-check faicon successgreen" aria-hidden="true"></i>
                                        </td>
                                        <td *ngIf="person.transactions_persons_action_status == 0">
                                            <i class="fa fa-check faicon silver" aria-hidden="true"></i>
                                        </td>
                                        <td>{{person.transactions_persons_action_datetime}}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="persons.length < 1 " class="AppendList">
                                    <tr class="odd">
                                        <td colspan="7" class="nodata" align="center">{{'lang_no_data' | language }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="stylepanels" *ngIf="notes.length > 0">
            <mat-expansion-panel class="mpanel">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_notes' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body communication">
                <div class="mbrl15">
                    <div class="col-md-12 pad0">
                        <div class="table-responsive customResponsive">
                            <table border="1" class="table CustomTable">
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>{{'lang_notes' | language }}</td>
                                        <td>{{'lang_writer' | language }}</td>
                                        <td>{{'lang_ondate' | language }}</td>
                                    </tr>
                                </thead>
                                <tbody
                                    class="AppendList">
                                    <tr *ngFor="let note of notes; let i = index;let odd=odd; let even=even"
                                        [ngClass]="{ odd: odd, even: even }">
                                        <td>{{i+1}}</td>
                                        <td>{{note.transactions_notes_text}}</td>
                                        <td>{{note.transactions_notes_user_name}}</td>
                                        <td>{{note.transactions_notes_datetime}}</td>                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="stylepanels">
            <mat-expansion-panel class="mpanel">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_attachments' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body communication">
                <div class="mbrl15">
                    <div class="col-md-12 pad0">
                        <div class="table-responsive customResponsive">
                            <table border="1" class="table CustomTable">
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>{{'lang_title' | language }}</td>
                                        <td>{{'lang_attach_with_pdf' | language }}</td>
                                        <td>{{'lang_official_paper' | language }}</td>
                                        <td>{{'lang_action' | language }}</td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="files.length > 0 "
                                    class="AppendList">
                                    <tr *ngFor="let file of files; let i = index;let odd=odd; let even=even"
                                        [ngClass]="{ odd: odd, even: even }">
                                        <td>{{i+1}}</td>
                                        <td>{{file.form_c1_file_attach_title}}</td>
                                        <td *ngIf="file.form_c1_file_attach_with_the_document == '1' ">
                                            {{'lang_yes' | language }}</td>
                                        <td *ngIf="file.form_c1_file_attach_with_the_document !=='1' ">
                                            {{'lang_no' | language }}</td>
                                        <td *ngIf="file.form_c1_file_print_official_paper == '1' ">
                                            {{'lang_yes' | language }}</td>
                                        <td *ngIf="file.form_c1_file_print_official_paper !=='1' ">
                                            {{'lang_no' | language }}</td>
                                        <td>
                                            <mat-icon (click)="ds.PreviewData(file.link)" class="mnabricon">link</mat-icon>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="files.length < 1 "
                                    class="AppendList">
                                    <tr class="odd">
                                        <td colspan="5" class="nodata" align="center">{{'lang_no_data' | language }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="stylepanels">
            <mat-expansion-panel class="mpanel">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_history' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body communication">
                <div class="mbrl15">
                    <div class="col-md-12 pad0">
                        <div class="table-responsive customResponsive">
                            <table border="1" class="table CustomTable">
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>{{'lang_name' | language }}</td>
                                        <td>{{'lang_notes' | language }}</td>
                                        <td>{{'lang_ondate' | language }}</td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="history.length > 0" class="AppendList">
                                    <tr *ngFor="let his of history; let i = index;let odd=odd; let even=even"
                                        [ngClass]="{ odd: odd, even: even }">
                                        <td>{{i+1}}</td>
                                        <td>{{his.transactions_records_user_name}}</td>
                                        <td>{{his.transactions_records_note}}</td>
                                        <td>{{his.transactions_records_datetime}}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="history.length < 1 " class="AppendList">
                                    <tr class="odd">
                                        <td colspan="4" class="nodata" align="center">{{'lang_no_data' | language }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-between p-2" *ngIf="history.length > 0">
                                <ngb-pagination [collectionSize]="collectionSize" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                                    [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshLists()"></ngb-pagination>
                                <select class="customselect" [(ngModel)]="pageSize" (change)="refreshLists()">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="20">20</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                    <option [ngValue]="250">250</option>
                                    <option [ngValue]="500">500</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
    <div class="row">
        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
            <button *ngIf="actionPage =='A' " type="button" class="albutton mnabrbutton mat-accent" mat-button routerLink="/transactions/nBU2waRF8N">{{'lang_goto_list' | language }}</button>
            <button *ngIf="actionPage !='A' " type="button" class="albutton mnabrbutton mat-accent" mat-button routerLink="/transactions/allforms">{{'lang_goto_list' | language }}</button>
        </div>
        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
            <div class="dropdown" *ngIf="actionbuttons">
                <button type="button" mat-button  [matMenuTriggerFor]="menu" class="col-12 mnabrbutton mat-accent">
                    {{'lang_action' | language }}
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf="actionbuttons.edit" (click)="are_you_sure(form_key, form_request_id)" matTooltip="{{'lang_edit' | language }}">{{'lang_edit' | language }}</button>                   
                    <button mat-menu-item *ngIf="actionbuttons.signature" (click)="showverificationform('signature')" data-toggle="modal" data-target="#verifySMRDA" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_signature' | language }}">{{'lang_signature' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.marks" (click)="showverificationform('marks')" data-method="Markers" data-toggle="modal" data-target="#verifySMRDA" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_mark' | language }}">{{'lang_mark' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.opinion" (click)="showverificationform('opinion')" data-method="opinion" data-toggle="modal" data-target="#verifySMRDA" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_opinion' | language }}"> {{'lang_opinion' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.reviewers" (click)="showverificationform('reviewers')" data-method="Reviewers" data-toggle="modal" data-target="#verifySMRDA" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_review' | language }}"> {{'lang_review' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.doall" (click)="showverificationform('doall')" data-method="doall" data-toggle="modal" data-target="#verifySMRDA" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_do_all' | language }}">{{'lang_do_all' | language }} </button>
                    <button mat-menu-item *ngIf="actionbuttons.preview" (click)="preview_form_data()" matTooltip="{{'lang_preview' | language }}">{{'lang_preview' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.view" (click)="ds.PreviewData(request.view_link)" matTooltip="{{'lang_view' | language }}">{{'lang_view' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.approval" (click)="empty_approve_form();" data-method="approval" data-toggle="modal" data-target="#verifyPassword" data-backdrop="static" data-keyboard="false" matTooltip="{{'lang_approve' | language }}">{{'lang_approve' | language }}</button>
                    <button mat-menu-item *ngIf="actionbuttons.delete && admin" (click)="delete_form_request(form_key, form_request_id)" matTooltip="{{'lang_delete' | language }}">{{'lang_delete' | language }}</button>
                  </mat-menu>
            </div>            
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
        <div class="col-md-2 col-sm-2 col-xs-12 mb-10"></div>
    </div>
</div>
<div class="modal" id="verifySMRDA">
    <div class="modal-dialog">
        <div class="modal-content">
            <form [formGroup]="form" class="form" (ngSubmit)="confirmVerification()">
                <div class="modal-header" *ngIf="verifylist.length > 0">
                    <h4 class="modal-title">{{'lang_complete_verification' | language }}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body" *ngIf="verifylist.length > 0">
                    <p># {{request.transaction_request_id}} {{request.transaction_request_description}}</p>
                    <app-alert id="default-alert"></app-alert>
                    <input type="hidden" formControlName="transaction_persons_type">
                    <input type="hidden" formControlName="do">
                    <div class="form-group">
                        <label class="mb-10">{{'lang_how_to_send_verification' | language }} : </label>
                        <div *ngFor="let option of verifylist;" class="custom-control custom-radio mb-10">
                            <input type="radio" class="custom-control-input zindex9999" value="{{option.value}}" formControlName="sender_type" name="sender_type">
                            <label class="custom-control-label" *ngIf="option.value == 'phone'">{{'lang_mobile' | language }} <b>( {{option.label}} ) </b></label>
                            <label class="custom-control-label" *ngIf="option.value == 'email'">{{'lang_email' | language }} <b>( {{option.label}} ) </b></label>
                            <label class="custom-control-label" *ngIf="option.value == 'whatsapp'">{{'lang_whatsapp' | language }} <b>( {{option.label}} ) </b></label>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="sendstatus">
                        <label for="verification_code">{{'lang_enter_verification_code' | language }}: </label>
                        <input type="text" required class="form-control almnabrformcontrol" formControlName="transactions_persons_action_code">
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-xs-12">
                            <button type="button" class="albutton mnabrbutton mat-accent" mat-button [disabled]="sendcodestatus" (click)="sendVerificationCode()">{{codemsg}}</button>
                        </div>
                        <div class="col-md-4 col-xs-12">
                            <button type="submit" *ngIf="sendstatus" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_do_verification' | language }}</button>
                        </div>
                        <div class="col-md-4 col-xs-12">
                            <button type="button" class="albutton mnabrbutton mat-accent" mat-button data-dismiss="modal">{{'lang_close' | language }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal" id="verifyPassword">
    <div class="modal-dialog">
        <div class="modal-content">
            <form [formGroup]="approve" class="approve" (ngSubmit)="approve_form()">
                <div class="modal-header">
                    <h4 class="modal-title">{{'lang_approve' | language }}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <p># {{request.transaction_request_id}} {{request.transaction_request_description}}</p>
                    <div class="form-group">
                        <label for="password">{{'lang_password' | language }}: </label>
                        <input type="password" required class="form-control almnabrformcontrol" formControlName="user_pass">
                        <input type="hidden" required class="form-control almnabrformcontrol" formControlName="transaction_request_id">
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-xs-12">
                            <button type="submit" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_approve' | language }}</button>
                        </div>
                        <div class="col-md-4 col-xs-12">
                            <button type="button" class="albutton mnabrbutton mat-accent" mat-button data-dismiss="modal">{{'lang_close' | language }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>