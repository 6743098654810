import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-supervision-test-proofs',
  templateUrl: './supervision-test-proofs.component.html',
  styleUrls: ['./supervision-test-proofs.component.scss']
})
export class SupervisionTestProofsComponent implements OnInit {
  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  @Input() supervision_data
  @Input() able2add
  @Input() able2edit
  @Input() project_data
  @Input() filelevels
  able2delete = JSON.parse(this.ls.getItem('is_admin'));
  users:any = [];
  positionItems:any = [];
  positions:any = [];
  testProofpageno = 1;
  testProofpagesize = 10;
  testProofListData:any = [];
  testProofnodata: string = this.lang.transform('lang_loading');
  error_message:string = "";
  error_status:boolean = false;
  form:FormGroup;
  updateform:FormGroup;
  testing_proofs:FormArray;
  viewdata:any = [];
  public isFile;
  submitted:boolean = false
  filename = this.lang.transform('lang_select_files');
  searchKey = "";
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public spinner:PreloaderService, public alert:AlertService, public modalService: BsModalService, public dialog:MatDialog, public fb:FormBuilder) { }
  ngOnInit(): void {
    this.buildform();
    this.load_supervision_test_proofs();
  }
  emptyform() {
    this.testing_proofs.controls = [];
    this.isFile = false;
    this.filename = this.lang.transform('lang_select_files');
    this.form.reset();
    this.add_new_items();
    this.alert.clear();
  }
  buildform() {
    this.form = new FormGroup({
      projects_profile_id: new FormControl(this.supervision_data.projects_profile_id),
      projects_supervision_id: new FormControl(this.supervision_data.projects_supervision_id),
      testing_proofs : this.fb.array([]), 
    });
    this.testing_proofs = this.form.get("testing_proofs") as FormArray;
    this.updateform = new FormGroup({
      projects_profile_id : new FormControl("", [Validators.required]),
      projects_supervision_id : new FormControl("", [Validators.required]),
      testing_proof_id : new FormControl("", [Validators.required]),
      testing_proof_description : new FormControl("", [Validators.required]),
      testing_proof_attachment_url : new FormControl(""),
      level_keys : new FormControl("", [Validators.required]),
    });    
  }
  public items_group() {
		return this.form.get("testing_proofs") as FormArray;
	}
	get itemsgroup() {
		return this.form.get("testing_proofs") as FormArray;
	}
	public add_new_items() {
		if (this.testing_proofs.length < 6) {
			this.testing_proofs.push(this.item_records());
		}
	}
	public removeItems(index) {
		if (this.testing_proofs.length > 1) {
			this.testing_proofs.removeAt(index);
		}
	}
	public item_records(): FormGroup {
		return this.fb.group({
      testing_proof_description: new FormControl('', [Validators.required]),
      testing_proof_attachment_url: new FormControl('', [Validators.required]),
      level_keys : new FormControl("", [Validators.required]),			
		});
  }
  public UploadFile(event, index) {
    if (event.target.files[0] && event.target.files[0] !== undefined && event.target.files[0].name) {
      $("#uploadText" + index).text(event.target.files[0].name);
      this.testing_proofs.controls[index].get('testing_proof_attachment_url').setValue(event.target.files[0], { emitEvent: true });
    } else {
      this.testing_proofs.controls[index].get('testing_proof_attachment_url').setValue(null);
      $("#uploadText" + index).text(this.lang.transform('lang_select_files'));
    }
  }
  public submit() {
    this.spinner.show();
    let param = new FormData();
    param.set('projects_profile_id', this.supervision_data.projects_profile_id);
    param.set('projects_supervision_id', this.supervision_data.projects_supervision_id);
    this.itemsgroup.value.forEach((v, k) => {
      param.set("testing_proof_description[" + k + "]", v.testing_proof_description);
      param.set("testing_proof_attachment_url[" + k + "]", v.testing_proof_attachment_url);
      param.set("level_keys[" + k + "]", v.level_keys);
    });    
    this.ds.postActionByUrl(param, 'fq6mvmJF3EMrbOL').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.alert.success(res.msg);
        this.form.reset();
        this.load_supervision_test_proofs();
        setTimeout(() => {
          this.modalRef.hide();
        }, 1000);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }  
  public emptyEditform(list) {
    this.isFile     = false;
    this.filename   = this.lang.transform('lang_select_files');
    this.alert.clear();
    this.updateform.reset();
    this.updateform.patchValue({
      projects_profile_id : list.projects_profile_id,
      projects_supervision_id : list.projects_supervision_id,
      testing_proof_id : list.testing_proof_id,
      testing_proof_description : list.testing_proof_description,
      level_keys : (list.level_keys) ? list.level_keys.split(',') : null
    });
  }
  public UpdateFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      this.isFile = true;
      this.filename = event.target.files[0].name;
      this.updateform.get("testing_proof_attachment_url").setValue(event.target.files[0], { emitEvent: true });
    } else {
      this.isFile = false;
      this.updateform.get("testing_proof_attachment_url").setValue(null);
      this.filename = this.lang.transform('lang_select_files');
    }
  }
  public removeFile() {
    this.isFile = false;
    this.updateform.get("testing_proof_attachment_url").setValue(null);
    this.filename = this.lang.transform('lang_select_files');
  }
  public update() {
    this.spinner.show();
    this.ds.postActionByUrl(this.ds.json2formdata(this.updateform.value), 'gJZnVnZ3NKyyPue/'+this.updateform.get('testing_proof_id').value).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.alert.success(res.msg);
        this.updateform.reset();
        this.load_supervision_test_proofs();
        setTimeout(() => {
          this.modalRef.hide();
        }, 1000);
      } else {
        this.alert.error(res.error);
      }
    }, error => {      
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public search_supervision_test_proofs() {
    this.testProofpageno = 1;
    this.testProofpagesize = 10;
    this.load_supervision_test_proofs();
  }
  public load_supervision_testProofs_page(page) {
    this.spinner.show();
    this.testProofpageno = page;
    this.testProofpagesize = this.testProofpagesize;
    this.load_supervision_test_proofs();
  }
  public load_supervision_testProofs_pagesize(size) {
    this.spinner.show();
    this.testProofpageno = 1;
    this.testProofpagesize = size;
    this.load_supervision_test_proofs();
  }
  public load_supervision_test_proofs() {
    let formdata = new FormData();
    formdata.append('search_key', this.searchKey);
    this.ds.getActionByUrl(this.ds.formData2string(formdata), '5dMyiCRCgSCbICh/'+this.project_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id+'/'+ this.testProofpageno + '/' + this.testProofpagesize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.testProofListData = res;
      } else {
        this.testProofListData = [];
        this.testProofnodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.testProofListData = [];
      this.testProofnodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public checkAllItemsTpf(event) {
    if (event.target.checked) {
      $(".testing_proof .eachItemTpf").prop("checked", true);
      $(".testing_proof .makeback").addClass("bground");
      $(".testing_proof .deleteIconDiv").show();
    } else {
      $(".testing_proof .eachItemTpf").prop("checked", false);
      $(".testing_proof .makeback").removeClass("bground");
      $(".testing_proof .deleteIconDiv").hide();
    }
  }
  public checkEachItemTpf(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".testing_proof .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".testing_proof .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".testing_proof .eachItemTpf:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".testing_proof .eachItemTpf").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".testing_proof .deleteIconDiv").show();
    } else {
      $(".testing_proof .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".testing_proof #AllItemsTpf").prop("checked", true);
    } else {
      $(".testing_proof #AllItemsTpf").prop("checked", false);
    }
  }
  public deleteBulkDataTpf() {
    const checkedtotal = [];
    $(".testing_proof .eachItemTpf:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'sb2zY5Ee06HUxU7/' + this.supervision_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id).subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.load_supervision_test_proofs();
                $(".testing_proof #AllItemsTpf").prop("checked", false);
                $(".testing_proof .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
