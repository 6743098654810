import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from '@angular/forms';
import {
	Router
} from '@angular/router';
import {
	PreloaderService
} from '@core';
import {
	DataService
} from '@core/bootstrap/data.service';
import {
	environment
} from '@env/environment';
import {
	AlertService
} from '@shared/components/alert/alert.service';
import {
	LanguagePipe
} from '@shared/pipes/language.pipe';
import * as mnabreditor from '../../../../../../ckeditor5/build/ckeditor';
declare var $: any;
@Component({
	selector: 'app-formc1-edit',
	templateUrl: './formc1-edit.component.html',
	styleUrls: ['./formc1-edit.component.scss']
})
export class Formc1EditComponent implements OnInit {
	@Input() form_key;
	@Input() form_request_id;
	apiurl = environment.SERVER_ORIGIN;
	form: FormGroup;
	fileslist: any = [];
	reuploadstatus: boolean = false;
	public Editor = mnabreditor;
	singleselection = environment.singledropdown;
	multipleselection = environment.multipledropdown;
	multipleselection1 = environment.multipledropdown;
	error_message: string = "";
	error_status: boolean = false;
	stamps: any = [];
	filename = this.lang.transform('lang_select_files');
	isFile: boolean = false;
	markstatus: boolean = false;
	reviewstatus: boolean = false;
	attachstatus: boolean = false;
	stampstatus: boolean = false;
	attachments: FormArray;
	languages: any = [];
	branches: any = [];
	loading = false;
	document_types: any = [];
	users: any = [];
	lang_key = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
	constructor(public ds: DataService, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public fb: FormBuilder, public router: Router) {}
	ngOnInit(): void {
		this.spinner.show();
		this.build_form();
		this.get_required_data('tc/tlanguages', 'lang');
		this.get_required_data('tc/tbranches', 'branch');
		this.get_required_data('tc/doctypes', 'type');
		this.get_data_edit();
	}
	public changedirection(event) {
		this.lang_key = (event != undefined) ? event.value : this.lang_key;
		$("ckeditor .ck.ck-reset.ck-editor, ckeditor .ck.ck-content.ck-editor__editable").attr({
			'style': (this.lang_key == 'ar') ? 'text-align : right !important' : 'text-align : left !important',
			'lang': this.lang_key,
			'dir': (this.lang_key == 'ar') ? 'rtl' : 'ltr',
		});
	}
	public get_data_edit() {
		this.ds.getActionByUrl([], 'form/' + this.form_key + '/vr/' + this.form_request_id).subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.changedirection({
					value: res.transactions_request['lang_key']
				});
				this.fileslist = (res.form_c1_files.status) ? res.form_c1_files.records : [];
				let data = {};
				data['subject'] = res.form_c1_data.records[0]['subject'];
				data['attachmentstitle'] = res.form_c1_data.records[0]['attachment'];
				data['lang_key'] = res.transactions_request['lang_key'];
				data['transaction_from'] = res.form_c1_data.records[0]['transaction_from'];
				data['transaction_to'] = res.form_c1_data.records[0]['transaction_to_name'];
				data['content'] = res.form_c1_data.records[0]['content'];
				data['needMark'] = '0';
				data['needReview'] = '0';
				data['needStamp'] = '0';
				data['needAttach'] = '0';
				if (res.form_c1_files && res.form_c1_files.status) {
					data['needAttach'] = '1'; // need attachment or not
					this.attachstatus = true;
				}
				let defaultusers = [];
				if (res.transactions_persons && res.transactions_persons.status) {
					let markers = [];
					let reviewers = [];
					res.transactions_persons.records.forEach((v, k) => {
						defaultusers.push({
							value: v.user_id,
							label: v.person_name
						});
						//signature users
						if (v.transaction_persons_type === "signature") {
							if (v.transactions_persons_val1 === "A" && v.transactions_persons_val2 && v.user_id && v.person_name) {
								data['signature[A][title]'] = v.transactions_persons_val2;
								data['signature[A][user_id]'] = v.user_id;
							}
							if (v.transactions_persons_val1 === "B" && v.transactions_persons_val2 && v.user_id && v.person_name) {
								data['signature[B][title]'] = v.transactions_persons_val2;
								data['signature[B][user_id]'] = v.user_id;
							}
							if (v.transactions_persons_val1 === "C" && v.transactions_persons_val2 && v.user_id && v.person_name) {
								data['signature[C][title]'] = v.transactions_persons_val2;
								data['signature[C][user_id]'] = v.user_id;
							}
						}
						//markers
						if (v.transaction_persons_type === "marks") {
							this.markstatus = true;
							data['needMark'] = '1'; // need Mark or not
							markers.push(v.user_id);
						}
						//reviewers
						if (v.transaction_persons_type === "reviews") {
							this.reviewstatus = true;
							data['needReview'] = '1'; // need Reviews or not
							reviewers.push(v.user_id);
							this.form.get('reviews').setValidators(Validators.required);
							this.form.get('reviews').updateValueAndValidity();
						}
					});
					if (markers.length > 0) {
						this.form.get('marks').setValidators(Validators.required);
						this.form.get('marks').updateValueAndValidity();
					}
					if (reviewers.length > 0) {
						this.form.get('reviews').setValidators(Validators.required);
						this.form.get('reviews').updateValueAndValidity();
					}
					data['marks'] = markers;
					data['reviews'] = reviewers;
				}
				this.users = defaultusers;
				this.form.patchValue(data);
			} else {
				this.ds.dialogf('', res.error);
				this.router.navigate(['allforms/']);
			}
		}, error => {
			this.spinner.hide();
			this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
		})
	}
	public enable_upload_field() {
		this.attachments.controls = [];
		this.reuploadstatus = true;
		this.attachments.push(this.files());
	}
	public disable_upload_field() {
		this.reuploadstatus = false;
		this.attachments.controls = [];
		this.form.get('attachments').setValue([]);
	}
	public get_required_data(url, key) {
		this.languages = [];
		this.branches = [];
		this.document_types = [];
		this.ds.getActionByUrl([], url).subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				if (key === 'lang') {
					this.languages = res.records;
				} else if (key === 'branch') {
					this.branches = res.records;
				} else if (key === 'type') {
					this.document_types = res.records;
				}
			}
		})
	}
	public search_users(key) {
		this.users = [];
		let param = new FormData();
		param.append('search', key.term || '');
		param.append('lang_key', this.form.get('lang_key').value || this.lang_key);
		param.append('user_type_id', '1');
		this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.users = res.list;
			}
		})
	}
	get filesFormGroup() {
		return this.form.get("attachments") as FormArray;
	}
	public build_form() {
		this.form = new FormGroup({
			lang_key: new FormControl(null, [Validators.required]),
			attachmentstitle: new FormControl(null),
			subject: new FormControl(null, [Validators.required]),
			transaction_from: new FormControl(null, [Validators.required]),
			transaction_to: new FormControl(null, [Validators.required]),
			content: new FormControl(null, [Validators.required]),
			needMark: new FormControl('0', [Validators.required]),
			needReview: new FormControl('0', [Validators.required]),
			needStamp: new FormControl('0', [Validators.required]),
			needAttach: new FormControl('0', [Validators.required]),
			stamp: new FormControl(null),
			'signature[A][title]': new FormControl(null),
			'signature[A][user_id]': new FormControl(null),
			'signature[B][title]': new FormControl(null),
			'signature[B][user_id]': new FormControl(null),
			'signature[C][title]': new FormControl(null),
			'signature[C][user_id]': new FormControl(null),
			marks: new FormControl(null),
			reviews: new FormControl(null),
			attachments: this.fb.array([]),
		});
		this.attachments = this.form.get("attachments") as FormArray;
	}
	public files(): FormGroup {
		return this.fb.group({
			attach_title: new FormControl(null, (this.form.get('needAttach').value == "1" && this.reuploadstatus) ? [Validators.required] : null),
			file: new FormControl(null, (this.form.get('needAttach').value == "1" && this.reuploadstatus) ? [Validators.required] : null),
			attach_with_the_document: new FormControl(0),
			print_official_paper: new FormControl(0)
		});
	}
	public addfiles() {
		if (this.attachments.length < 5) {
			this.attachments.push(this.files());
		}
	}
	public removefiles(index) {
		if (this.attachments.length > 1) {
			this.attachments.removeAt(index);
		}
	}
	public getfilesgroup(index): FormGroup {
		const formGroup = this.attachments.controls[index] as FormGroup;
		return formGroup;
	}
	public UploadFile(event, index) {
		if (event.target.files[0] && event.target.files[0] !== undefined && event.target.files[0].name) {
			const fileid = event.target.files[0];
			$("#uploadText" + index).text(fileid.name);
			this.attachments.controls[index].get('file').setValue(fileid, {
				emitEvent: true
			});
		} else {
			this.attachments.controls[index].get('file').setValue(null);
			$("#uploadText" + index).text(this.lang.transform('lang_select_files'));
		}
	}
	public removeFile() {
		this.isFile = false;
		this.form.get("upload").setValue(null);
		this.filename = this.lang.transform('lang_select_files');
	}
	public enable_field(field) {
		if (field == 'mark') {
			this.form.get('needMark').setValue('1');
			this.form.get('marks').setValidators(Validators.required);
			this.form.get('marks').updateValueAndValidity();
			this.markstatus = true;
		} else if (field == 'review') {
			this.form.get('needReview').setValue('1');
			this.form.get('reviews').setValidators(Validators.required);
			this.form.get('reviews').updateValueAndValidity();
			this.reviewstatus = true;
		} else if (field == 'stamp') {
			this.form.get('needStamp').setValue('1');
			this.form.get('stamp').setValidators(Validators.required);
			this.form.get('stamp').updateValueAndValidity();
			this.stampstatus = true;
		} else if (field == 'attachment') {
			this.attachments.clear();
			this.form.get('needAttach').setValue('1');
			this.addfiles();
			this.attachstatus = true;
		}
	}
	public disable_field(field) {
		if (field == 'mark') {
			this.form.get('needMark').setValue('0');
			this.form.get('marks').setValue(null);
			this.form.get('marks').clearValidators();
			this.form.get('marks').updateValueAndValidity();
			this.markstatus = false;
		} else if (field == 'review') {
			this.form.get('needReview').setValue('0');
			this.form.get('reviews').setValue(null);
			this.form.get('reviews').clearValidators();
			this.form.get('reviews').updateValueAndValidity();
			this.reviewstatus = false;
		} else if (field == 'stamp') {
			this.form.get('needStamp').setValue('0');
			this.form.get('stamp').setValue(null);
			this.form.get('stamp').clearValidators();
			this.form.get('stamp').updateValueAndValidity();
			this.stampstatus = false;
		} else if (field == 'attachment') {
			this.attachments.clear();
			this.form.get('needAttach').setValue('0');
			this.isFile = false;
			this.attachstatus = false;
		}
		this.form.updateValueAndValidity();
	}
	public submitForm() {
		if (this.validstatus()) {
			let param = new FormData();
			param.set('transaction_request_id', this.form_request_id);
			param.set('subject', this.form.get('subject').value || '');
			param.set('attachmentstitle', this.form.get('attachmentstitle').value || '');
			param.set('lang_key', this.form.get('lang_key').value || '');
			param.set('transaction_from', this.form.get('transaction_from').value || '');
			param.set('transaction_to', this.form.get('transaction_to').value || '');
			param.set('content', this.form.get('content').value);
			param.set('needMark', this.form.get('needMark').value);
			if (this.form.get('needMark').value == "1") {
				param.set('marks', this.form.get('marks').value.toString() || '');
			}
			param.set('needReview', this.form.get('needReview').value || '');
			if (this.form.get('needReview').value == "1") {
				param.set('reviews', this.form.get('reviews').value.toString() || '');
			}
			param.set('signature[A][title]', this.form.get('signature[A][title]').value || '');
			param.set('signature[A][user_id]', this.form.get('signature[A][user_id]').value || '');
			param.set('signature[B][title]', this.form.get('signature[B][title]').value || '');
			param.set('signature[B][user_id]', this.form.get('signature[B][user_id]').value || '');
			param.set('signature[C][title]', this.form.get('signature[C][title]').value || '');
			param.set('signature[C][user_id]', this.form.get('signature[C][user_id]').value || '');
			param.set('needAttach', this.ds.select_array_string(this.form, 'needAttach', 'value'));
			param.set('needAttachEdit', (this.reuploadstatus) ? '1' : '0');
			if (this.form.get('needAttach').value == '1' && this.reuploadstatus) {
				this.filesFormGroup.value.forEach((v, k) => {
					param.set("attachments[" + k + "][file]", v.file);
					param.set("attachments[" + k + "][attach_title]", v.attach_title);
					param.set("attachments[" + k + "][attach_with_the_document]", (v.attach_with_the_document) ? '1' : '0');
					param.set("attachments[" + k + "][print_official_paper]", (v.print_official_paper) ? '1' : '0');
				});
			}
			this.spinner.show();
			this.ds.postActionByUrl(param, 'form/' + this.form_key + '/et').subscribe(res => {
				this.spinner.hide();
				if (res.status) {
					this.alert.success(res.msg);
					this.form.reset();
					setTimeout(() => {
						this.router.navigate(['transactions/form/' + this.form_key + '/vr/' + this.form_request_id]);
					}, 1000);
				} else {
					this.alert.error(res.error);
				}
			}, error => {
				this.spinner.hide();
				this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
			})
		}
	}
	public value(key) {
		return this.form.get(key).value;
	}
	public validstatus() {
		if (this.form.get('signature[A][title]').value || this.form.get('signature[A][user_id]').value != null) {
			this.form.get('signature[A][title]').setValidators(Validators.required);
			this.form.get('signature[A][title]').updateValueAndValidity();
			this.form.get('signature[A][user_id]').setValidators(Validators.required);
			this.form.get('signature[A][user_id]').updateValueAndValidity();
		} else {
			this.form.get('signature[A][title]').setValue('');
			this.form.get('signature[A][title]').clearValidators();
			this.form.get('signature[A][title]').updateValueAndValidity();
			this.form.get('signature[A][user_id]').setValue(null);
			this.form.get('signature[A][user_id]').clearValidators();
			this.form.get('signature[A][user_id]').updateValueAndValidity();
		}
		if (this.form.get('signature[B][title]').value || this.form.get('signature[B][user_id]').value != null) {
			this.form.get('signature[B][title]').setValidators(Validators.required);
			this.form.get('signature[B][title]').updateValueAndValidity();
			this.form.get('signature[B][user_id]').setValidators(Validators.required);
			this.form.get('signature[B][user_id]').updateValueAndValidity();
		} else {
			this.form.get('signature[B][title]').setValue('');
			this.form.get('signature[B][title]').clearValidators();
			this.form.get('signature[B][title]').updateValueAndValidity();
			this.form.get('signature[B][user_id]').setValue(null);
			this.form.get('signature[B][user_id]').clearValidators();
			this.form.get('signature[B][user_id]').updateValueAndValidity();
		}
		if (this.form.get('signature[C][title]').value || this.form.get('signature[C][user_id]').value != null) {
			this.form.get('signature[C][title]').setValidators(Validators.required);
			this.form.get('signature[C][title]').updateValueAndValidity();
			this.form.get('signature[C][user_id]').setValidators(Validators.required);
			this.form.get('signature[C][user_id]').updateValueAndValidity();
		} else {
			this.form.get('signature[C][title]').setValue('');
			this.form.get('signature[C][title]').clearValidators();
			this.form.get('signature[C][title]').updateValueAndValidity();
			this.form.get('signature[C][user_id]').setValue(null);
			this.form.get('signature[C][user_id]').clearValidators();
			this.form.get('signature[C][user_id]').updateValueAndValidity();
		}
		if (!this.form.get('signature[A][title]').value && !this.form.get('signature[B][title]').value && !this.form.get('signature[C][title]').value) {
			this.form.get('signature[A][title]').setValidators(Validators.required);
			this.form.get('signature[A][title]').updateValueAndValidity();
			this.form.get('signature[A][user_id]').setValidators(Validators.required);
			this.form.get('signature[A][user_id]').updateValueAndValidity();
		}
		if (this.form.valid) {
			return true;
		} else {
			this.ds.dialogf('', this.lang.transform('lang_need_emp_and_data'));
			return false;
		}
	}
}