import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultRoutingModule } from './default-routing.module';
import { LangphrasesComponent } from './admin/langphrases/langphrases.component';
import { SharedModule } from '@shared/shared.module';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationListsComponent } from './admin/registration-lists/registration-lists.component';
import { ProfileComponent } from './profile/profile.component';
import { HumanresourcesModule } from '../humanresources/humanresources.module';
import { UsersListComponent } from './admin/users-list/users-list.component';
import { CpasswordComponent } from './cpassword/cpassword.component';
import { FileSettingsComponent } from './admin/file-settings/file-settings.component';
import { UserGroupsComponent } from './admin/file-settings/user-groups/user-groups.component';
import { FilesLevelComponent } from './admin/file-settings/files-level/files-level.component';
import { FilesLevelGroupsComponent } from './admin/file-settings/files-level-groups/files-level-groups.component';
import { FileUsersGroupsComponent } from './admin/file-settings/file-users-groups/file-users-groups.component';
import { FilesTypesComponent } from './admin/file-settings/files-types/files-types.component';
import { FilesModuleSettingsComponent } from './admin/file-settings/files-module-settings/files-module-settings.component';
import { AllFilesComponent } from './admin/all-files/all-files.component';
import { OprofileComponent } from './oprofile/oprofile.component';
import { ProfileCreateVacationComponent } from './profileCreateVacation/profileCreateVacation.component';

const COMPONENTS = [ProfileCreateVacationComponent,LangphrasesComponent, RegistrationListsComponent, ProfileComponent, UsersListComponent, CpasswordComponent, FileSettingsComponent];
const COMPONENTS_DYNAMIC = [];
@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    DefaultRoutingModule,
    NgbPaginationModule, 
    NgbModule,
    HumanresourcesModule
  ],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, UserGroupsComponent, FilesLevelComponent, FilesLevelGroupsComponent, FileUsersGroupsComponent, FilesTypesComponent, FilesModuleSettingsComponent, AllFilesComponent, OprofileComponent],
  exports: [...COMPONENTS, ...COMPONENTS_DYNAMIC],
  entryComponents: COMPONENTS_DYNAMIC,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DefaultModule { }