<div class="table-responsive customResponsive">
	<table border="1" class="table CustomTable">
		<thead>
			<tr>
				<td>#</td>
				<td>{{ "lang_name" | language }}</td>
				<td>{{ "lang_notes" | language }}</td>
				<td>{{ "lang_ondate" | language }}</td>
			</tr>
		</thead>
		<tbody *ngIf="history?.length > 0" class="AppendList">
			<tr
				*ngFor="
					let his of history
						| slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize;
					let i = index;
					let odd = odd;
					let even = even
				"
				[ngClass]="{ odd: odd, even: even }"
			>
				<td>{{ i + 1 }}</td>
				<td>{{ his.transactions_records_user_name }}</td>
				<td>{{ his.transactions_records_note }}</td>
				<td>{{ his.transactions_records_datetime }}</td>
			</tr>
		</tbody>
		<tbody *ngIf="history?.length < 1" class="AppendList">
			<tr class="odd">
				<td colspan="4" class="nodata" align="center">
					{{ "lang_no_data" | language }}
				</td>
			</tr>
		</tbody>
	</table>
	<br />
	<div
		class="mt-2"
		style="
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		"
	>
		<ngb-pagination
			*ngIf="history?.length"
			[collectionSize]="history?.length"
			[(page)]="page"
			[pageSize]="pageSize"
			[boundaryLinks]="true"
		></ngb-pagination>
	</div>
</div>
