<form [formGroup]="form" class="form" (ngSubmit)="submitForm()">
    <section class="panel-expansion ckeditorhidetoolbar">
        <mat-accordion class="stylepanels">
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_loan_form_new' | language}} ( <span *ngIf="lang_key =='en'"> {{employee_data.firstname_english}} {{employee_data.lastname_english}}</span> <span *ngIf="lang_key =='ar'"> {{employee_data.firstname_arabic}} {{employee_data.lastname_arabic}} </span> ) </mat-expansion-panel-header>
                <div class="mbrl15">

                    <div class="row mb-10">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_employee_no"|language }}</div>
                            <div class="mb-10">
                                <b> {{employee_data.employee_number}} </b>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_name"|language }}</div>
                            <div class="mb-10">
                                <b>
                                    {{emp_data.employee_name}} </b
                                >
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_salary"|language }}</div>
                            <div class="mb-10">
                                <b>
                                    {{emp_data.basic_salary}}</b
                                >
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_current_salary"|language }}</div>
                            <div class="mb-10">
                                <b>
                                    {{emp_data.net_amount}}</b
                                >
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_joining_date"|language }}</div>
                            <div class="mb-10">
                                <b>
                                    {{emp_data.joining_start_date_english}}</b
                                >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_type' | language}} <span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol" readonly placeholder="{{'lang_type' | language}}" formControlName="financial_type">
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_amount' | language}}  <span class="red">*</span> </label>
                                <input type="number"  (keypress)="isNumber($event);"  class="form-control almnabrformcontrol"  placeholder="{{'lang_amount' | language}}" formControlName="loan_amount">
                                <mat-error *ngIf="submitted && form.controls['loan_amount'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label for="loan_date_english">{{'lang_date' | language }} : <span class="red">*</span></label>
                                <div class="positionrelatiove">
                                    <input formControlName="loan_date_english" type="text" [min]="min" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event, form.get('loan_date_arabic'));getLoanDates($event)" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
                                    <mat-datepicker-toggle matSuffix [for]="BDeng"  class="posiab"></mat-datepicker-toggle>
                                    <mat-datepicker #BDeng disabled="false"></mat-datepicker>
                                    <mat-error *ngIf="submitted && form.controls['loan_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-xs-12 col-sm-4 mb-10">
                            <label class="mb-10">
                                {{ "lang_direct_manager" | language }}
                                <span class="red"> * </span> </label>
            
                                <ng-select
                                [items]="users"
                                [multiple]="false"
                                bindLabel="label"
                                bindValue="value"
                                clearAllText="Clear"
                                 
                                appendTo="body"
                                formControlName="direct_manager"
                                placeholder="{{ 'lang_direct_manager' | language }}"
                                (search)="search_users($event)"
                                
                                [searchFn]="ds.customSearchFn"
                                 
                            >
                            </ng-select>
                            <mat-error
                                *ngIf="
                                submitted &&
                                form.controls['direct_manager'].errors?.required
                                "
                            >
                                {{ "lang_field_required" | language }}
                            </mat-error>
                         
                        </div>

                        
				<div class="col-md-4 col-xs-12 col-sm-4 mb-10">
					<label class="mb-10">
						{{ "lang_reasons" | language }}
						<span class="red"> * </span> </label>
	
						<ng-select
						[items]="reasons"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						clearAllText="Clear"
						 
						appendTo="body"
						formControlName="reasons"
						placeholder="{{ 'lang_reasons' | language }}"
						 
						 
					>
					</ng-select>
					<mat-error
						*ngIf="
						submitted &&
						form.controls['reasons'].errors?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				 
				</div>
 

				<div class="col-md-4 col-xs-12 col-sm-4 mb-10" *ngIf="!attachstatus && showAttachment">
					<button
                            
							class="albutton mnabrbutton mat-accent top26"
							mat-button
							(click)="enable_field('attachment')"
						>
							<i class="fa fa-plus"></i> {{ "lang_need_attachment" | language }}
						</button>
				</div>
                        <!-- <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_joining_type' | language}} <span class="red">*</span></label>
                                <select class="form-control almnabrformcontrol" formControlName="joining_type">
                                    <option value="">{{'lang_joining_type' | language}}</option>
                                    <option *ngFor="let type of joining_type" value="{{type.value}}">{{type.label}}</option>
                                </select>
                                <mat-error *ngIf="submitted && form.controls['joining_type'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>     -->                    
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            
                                          
                                            <td>{{'lang_month' | language }}</td>
                                            <td>{{'lang_amount' | language }}</td>
                                            <td>{{'lang_salary' | language }}</td>
                                          
                                            
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                       <tbody formGroupName="createInstallments" 
                                                           
                                 
                                        class="AppendList Line">
                                        <tr   >
                                           
                                           
                                            <td >
                                           
                                                <div class="col-md-12 col-xs-12 col-sm-12 mb-10 mt-10">
                                                    
                                            
                                                        <ng-select
                                                        [items]="monthesAfterSearch"
                                                        
                                                        bindLabel="label"
                                                        bindValue="value"
                                                        clearAllText="Clear"
                                                        class="SelectCustomStyle almnabrformcontrol"
                                                        appendTo="body"
                                                        formControlName="month"
                                                        placeholder="{{ 'lang_month' | language }}"
                                                      
                                                        (change)="getThisMonthSalary($event)"
                                                    
                                                         
                                                    >
                                                    </ng-select>
                                                    <mat-error
                                                        *ngIf="
                                                        CreateSubmitted &&
                                                        form?.controls?.createInstallments['controls']?.month?.errors?.required
                                                        "
                                                    >
                                                        {{ "lang_field_required" | language }}
                                                    </mat-error>
                                                 
                                                </div>
                                            </td>
                                                      
                                            <td>
                                      

                                                <div class="col-md-12 col-xs-6 col-sm-12 mb-10 mt-10">
                                                
                            
                                                    <span
                                                    matTooltip="{{ form?.controls?.createInstallments['controls']?.error_message?.value | language }}"
                                                    *ngIf="form?.controls?.createInstallments['controls']?.error_message?.value"
                                                    >
                                                    <input
                                                    type="number"
                                                    (keyup)="checkFor50Percent()"
                                                    (keypress)="isNumber($event)"
                                                    class="form-control almnabrformcontrol"

                                                   

                                                    formControlName="amount"
                                                    [ngClass]="{ 'showCustomError': form?.controls?.createInstallments['controls']?.error_message?.value  }"
                                                 />
                                                    </span>

                                                    <span
                                                   
                                                    *ngIf="!form?.controls?.createInstallments['controls']?.error_message?.value"
                                                    >
                                                    <input
                                                    type="number"
                                                    (keyup)="checkFor50Percent()"
                                                    (keypress)="isNumber($event)"
                                                    class="form-control almnabrformcontrol"
                                                    formControlName="amount"
                                                    [ngClass]="{ 'showCustomError': form?.controls?.createInstallments['controls']?.error_message?.value  }"
                                                 />
                                                    </span>
                                                     
                                                    <mat-error
                                                        *ngIf="
                                                        CreateSubmitted &&
                                                        form?.controls?.createInstallments['controls']?.amount?.errors?.required
                                                        "
                                                    >
                                                        {{ "lang_field_required" | language }}
                                                    </mat-error>
                                                 
                                                </div>
                                            </td>
                                            <td>
                                           

                                                <div class="col-md-12 col-xs-12 col-sm-12 mb-10 mt-10">
                                                 
                            
                                                        <input
                                                        type="number"
                                                        readonly
                                                        class="form-control almnabrformcontrol"

                                                        formControlName="month_salary"
                                                    />
                                                    <mat-error
                                                        *ngIf="
                                                        CreateSubmitted &&
                                                        form?.controls?.createInstallments['controls']?.month_salary?.errors?.required
                                                        "
                                                    >
                                                        {{ "lang_field_required" | language }}
                                                    </mat-error>
                                                 
                                                </div>
                                            </td>
                                                    

                                                    
                                            <td class="dropdown">
                                              
                                             
                                                <button
                                                (click)="addInstallments()" 
                                                    [disabled]="form?.controls?.createInstallments.status == 'INVALID'"
                                                    id="btnSearch"
                                                    type="button"
                                                    style="background: unset;"
                                                    matTooltip="{{ 'lang_add' | language }}"
                                                    class=" enablecode h34 btn btn-md btn-gray fa fa-plus blue-tooltip mnabricon"
                                                
                                                ></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody  formArrayName="installments" 
                                   
                                 
                                    class="AppendList">
                                        <tr  *ngFor="let creds of form.controls.installments.value ; let i = index;trackBy:trackByFnCustom" [formGroupName]="i" >
                                           
                                           
                                            <td >
                                           
                                                <div class="col-md-12 col-xs-12 col-sm-12 mb-10 mt-10">
                                                    
                                            
                                                        <!-- <ng-select
                                                        [items]="monthes"
                                                      
                                                        bindLabel="label"
                                                        bindValue="value"
                                                        clearAllText="Clear"
                                                        class="form-control almnabrformcontrol"
                                                        appendTo="body"
                                                        formControlName="month"
                                                        placeholder="{{ 'lang_loan_month' | language }}"
                                                      
                                                        
                                                    
                                                         
                                                    >
                                                    </ng-select> -->
                                                    <input
                                                    type="text"
                                                    disabled
                                                    class="form-control almnabrformcontrol"
                                                    formControlName="month"
                                                    />
                                                    <mat-error
                                                        *ngIf="
                                                        submitted &&
                                                        creds?.controls['controls'].month.errors?.required
                                                        "
                                                    >
                                                        {{ "lang_field_required" | language }}
                                                    </mat-error>
                                                 
                                                </div>
                                            </td>
                                                      
                                            <td>
                                      

                                                <div class="col-md-12 col-xs-6 col-sm-12 mb-10 mt-10">
                                                
                            
                                                        <input
                                                        type="number"
                                                        readonly
                                                        class="form-control almnabrformcontrol"
                                                        formControlName="amount"
                                                    />
                                                    <mat-error
                                                        *ngIf="
                                                        submitted &&
                                                        creds?.controls['controls'].amount.errors?.required
                                                        "
                                                    >
                                                        {{ "lang_field_required" | language }}
                                                    </mat-error>
                                                 
                                                </div>
                                            </td>
                                            <td>
                                           

                                                <div class="col-md-12 col-xs-12 col-sm-12 mb-10 mt-10">
                                                 
                            
                                                        <input
                                                        type="number"
                                                        readonly
                                                        class="form-control almnabrformcontrol"
                                                        formControlName="month_salary"
                                                    />
                                                    <mat-error
                                                        *ngIf="
                                                        submitted &&
                                                        creds?.controls['controls'].month_salary.errors?.required
                                                        "
                                                    >
                                                        {{ "lang_field_required" | language }}
                                                    </mat-error>
                                                 
                                                </div>
                                            </td>
                                                    

                                                    
                                            <td class="dropdown">
                                                <mat-icon
                                                matTooltip="{{ 'lang_delete' | language }}"
                                                style="color: red;"
                                                class="mnabricon" (click)="deleteCreds(i,creds)" >delete_forever</mat-icon>
                                            
                                            </td>
                                        </tr>
                                    </tbody>
                                  <!--   <tbody *ngIf="wholddata.length == '0'" class="AppendList">
                                        <tr class="odd">
                                            <td colspan="8" align="center">{{errormessagef}}</td>
                                        </tr>
                                    </tbody> -->
                                </table>
                            </div>
                        </div>


                        <input type="hidden" formControlName="needAttach" />
                        <div class="row w-100 mt-5" *ngIf="attachstatus">
                            <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                                <fieldset class="cardback fieldset">
                                    <legend>
                                        {{ "lang_attachments" | language }}
                                        <span class="red"
                                            *ngIf="getAttachments?.errors?.required">*
                                            <mat-icon
                                                class="red mnabricon"
                                                (click)="disable_field('attachment')"
                                                >close</mat-icon
                                            ></span
                                        >
                                        <mat-icon
                                            class="mnabricon"
                                            matTooltip="{{ 'lang_add' | language }}"
                                            (click)="addfiles()"
                                            >add_box</mat-icon
                                        >
                                    </legend>
                                    <div class="table-responsive customResponsive">
                                        <table
                                            border="1"
                                            class="table CustomTable"
                                            width="100%"
                                            formArrayName="attachments"
                                        >
                                            <thead>
                                                <tr>
                                                    <td style="padding: 5px; word-break: break-all">#</td>
                                                    <td style="padding: 5px; word-break: break-all">
                                                        {{ "lang_title" | language }}
                                                    </td>
                                                    <td style="padding: 5px; word-break: break-all">
                                                        {{ "lang_file" | language }}
                                                    </td>
        
                                                    <td style="padding: 5px; word-break: break-all">
                                                        {{ "lang_action" | language }}
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="
                                                        let file of filesFormGroup.controls;
                                                        let i = index;
                                                        let odd = odd;
                                                        let even = even
                                                    "
                                                    [ngClass]="{ odd: odd, even: even }"
                                                    [formGroupName]="i"
                                                >
                                                    <td
                                                        class="verticalalignmid"
                                                        style="padding: 5px; word-break: break-all"
                                                    >
                                                        {{ i + 1 }}
                                                    </td>
                                                    <td
                                                        style="
                                                            padding: 5px;
                                                            word-break: break-all;
                                                            position: relative;
                                                        "
                                                    >
                                                        <input
                                                            type="text"
                                                            formControlName="attach_title"
                                                            placeholder="{{ 'lang_description' | language }}"
                                                            autocomplete="off"
                                                            class="form-control almnabrformcontrol"
                                                        />
                                                        <mat-error
                                                            *ngIf="
                                                            submitted.submitted &&
                                                                this.attachments.controls[i].get('attach_title')
                                                                    .errors?.required
                                                            "
                                                            >{{ "lang_field_required" | language }}</mat-error
                                                        >
                                                    </td>
                                                    <td
                                                        class="verticalalignmid"
                                                        style="
                                                            padding: 5px;
                                                            word-break: break-all;
                                                            position: relative;
                                                        "
                                                    >
                                                        <input
                                                            style="display: block"
                                                            name="file"
                                                            type="file"
                                                            required
                                                            onclick="this.value = null"
                                                            (change)="UploadFile($event, i)"
                                                            class="nghide albutton width100"
                                                        />
                                                        <label
                                                            id="uploadText{{ i }}"
                                                            class="albutton mat-accent"
                                                            mat-button
                                                            >{{ filename }}
                                                        </label>
                                                        <mat-error
                                                            *ngIf="
                                                            submitted.submitted &&
                                                                this.attachments.controls[i].get('file').errors
                                                                    ?.required
                                                            "
                                                        >
                                                            {{ "lang_field_required" | language }}</mat-error
                                                        >
                                                    </td>
        
                                                    <td class="verticalalignmid">
                                                        <mat-icon
                                                            (click)="removefiles(i)"
                                                            class="mnabricon red"
                                                            matTooltip="{{ 'lang_delete' | language }}"
                                                            >delete_forever</mat-icon
                                                        >
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                   
             
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="button" routerLink="/human_resources/employees" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_human_resources_all_emp' | language}}</button>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="button" (click)="loanEvent.emit()" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_human_resources_emp_details' | language}}</button>
                        </div>
                        <div class="col-md-6 col-sm-2 col-xs-12 mb-10">
                            <app-alert id="default-alert"></app-alert>
                        </div>
                        <div class="col-md-1 col-sm-2 col-xs-12 mb-10">
                            
                        </div>
                        <div class="col-md-1 col-sm-2 col-xs-12 mb-10">
                            <input type="submit" value="{{'lang_save' | language}}" class="albutton mnabrbutton mat-accent" mat-button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</form>