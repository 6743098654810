import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {
  searchKey = '';
  status_list = [
    {
      label: 'Not Started',
      value: 'not_started'
    },
    {
      label: 'In Progress',
      value: 'in_progress'
    },
    {
      label: 'Completed',
      value: 'completed'
    }
  ];
  priority_list = [
    {
      label: 'Low',
      value: 'low'
    },
    {
      label: 'Medium',
      value: 'medium'
    },
    {
      label: 'High',
      value: 'high'
    }
  ];
  status_filter   = null;
  priority_filter = null;
  constructor() { }

  ngOnInit(): void {
  }

}
