import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-add-step-user',
  templateUrl: './add-step-user.component.html',
  styleUrls: ['./add-step-user.component.scss']
})
export class AddStepUserComponent implements OnInit {
  user = null;
  users = [];
  search_users_list = [];
  form_request_id:any;
  transaction_request_last_step:any;
  user_type_id:any;
  form_key:any;
  constructor(public ds:DataService, public ls:LocalStorage, public lang:LanguagePipe,
    public spinner : PreloaderService, public alert:AlertService, public router:Router, private dialogRef: MatDialogRef<AddStepUserComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data, private sweetAlert:SweetAlertService) {
      this.form_request_id = data?.form_request_id;
      this.form_key = data?.form_key;
      this.getPersons();
    }

  ngOnInit(): void {
  }

  search_users(key) {
    this.search_users_list  = [];
		let formData   = new FormData();
		formData.append('search_key', key.term || '');
    formData.append('user_type_id', this.user_type_id || '');
    
		this.ds.post(`form/${this.form_key}/admin_persons_search_users`, formData).subscribe(res => {
      if (res.status) {
        this.search_users_list = res?.records;
      } else {
        this.search_users_list = [];
      }
    });
  }

  getPersons() {
    this.spinner.show();
    this.ds.getActionByUrl([], `form/${this.form_key}/admin_persons_get/${this.form_request_id}`).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.users = res?.records;
        this.transaction_request_last_step = res?.details?.transaction_request_last_step;
        this.user_type_id = res?.details?.user_type_id;
      } else {
        this.users = [];
      }
    });
  }

  sendUser() {
    if(this.user) {
      this.spinner.show();
      let formData   = new FormData();
      formData.append('transaction_request_id', this.form_request_id || '');
      formData.append('user_type_id', this.user_type_id || '');
      formData.append('user_id', this.user || '');
      formData.append('transaction_request_last_step', this.transaction_request_last_step || '');
      this.ds.post(`form/${this.form_key}/admin_persons_set`, formData).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.sweetAlert.successMessage(res.msg)
          this.dialogRef.close();
        } else {
          this.sweetAlert.errorMessage(res.error)
        }
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

}
