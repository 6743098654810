import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { LocalStorageService } from '@shared/services/storage.service';
import { TokenModel, AuthReferrer } from './interface';
import { Router } from '@angular/router';
import { MenuService } from '@core/bootstrap/menu.service';
import { SettingsService } from '@core/bootstrap/settings.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { DataService } from '@core/bootstrap/data.service';
import { ApiRoutes } from 'app/routes/apiroutes';
import { PreloaderService } from '@core/bootstrap/preloader.service';
const TOKEN_KEY = 'jwt';
@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private change$ = new BehaviorSubject(null);
  get referrer() {
    return this._referrer;
  }
  private _referrer: AuthReferrer = {};
  constructor(private store: LocalStorageService, 
    private router: Router,
    private settings: SettingsService,
    private menu: MenuService,
    private ls : LocalStorage,
    private ds: DataService,
    private spinner : PreloaderService
  ) {}
  set(data: TokenModel): boolean {
    this.change$.next(data);
    return this.store.set(TOKEN_KEY, data);
  }
  get<T extends TokenModel>(type?: new () => T): T {
    const data = this.store.get(TOKEN_KEY);
    return type ? (Object.assign(new type(), data) as T) : (data as T);
  }
  clear() {
    this.store.remove(TOKEN_KEY);
  }
  change(): Observable<TokenModel | null> {
    return this.change$.pipe(share());
  }
  logout() {
    this.spinner.show();
    this.ds.get(ApiRoutes.logout, {}).subscribe(res=> {
      this.logout_redirect();
      this.spinner.hide();
    }, error => {
      this.logout_redirect();
      this.spinner.hide();
    });    
  }
  logout_redirect() {
    this.clear();
    this.settings.removeUser();
    this.ls.clear_custom();
    setTimeout(() => {
      this.router.navigateByUrl('/auth/login');
    }, 100);
  }
}
