import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
interface list {
  id?: number;
  attach_type_id: string,
  module_name: string,
  key_code: string,
  title_english: string,
  title_arabic: string,
  created_datetime:string
}
@Component({
  selector: 'app-files-types',
  templateUrl: './files-types.component.html',
  styleUrls: ['./files-types.component.scss']
})
export class FilesTypesComponent implements OnInit {  
  modalRefA: BsModalRef;
  tabledatas: list[];
  form:FormGroup;
  @Output() actionEvent             = new EventEmitter<any>();
  collectionSize                    = 0;
  page                              = 1;
  pageSize                          = 10;
  searchKey                         = "";
  nodata: string                    = this.lang.transform('lang_loading');
  admin                             = JSON.parse(this.ls.getItem('is_admin'));
  wholeData:any                     = [];
  tabledata:any                     = [];
  modules:any                       = [];
  module_key:any                    = '';
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {    
    this.spinner.show();
    this.get_modules();
    this.get_file_types();
    this.form = new FormGroup({
      attach_type_id          : new FormControl(''),
      attachment_parent_id    : new FormControl(''),
      module_name             : new FormControl('', [Validators.required]),
      key_code                : new FormControl('', [Validators.required]),
      title_english           : new FormControl('', [Validators.required]),
      title_arabic            : new FormControl('', [Validators.required]),
      storage_type            : new FormControl('0'),
    });
  }
  public get_modules() {
    this.modules = [];
    this.ds.postActionByUrl([], 'module').subscribe(res => {
      if(res.status) {
        this.modules = res.records;
      }
    })
  }
  //add, edit model
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public get_file_types() {
    let param = new FormData();
    param.append('search_key', this.searchKey || '');
    param.append('module_name', this.module_key || '');
    this.ds.postActionByUrl(param, 'attachtypes/1/200').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.wholeData      = res.records;
        this.tabledatas     = res.records;
        this.collectionSize = res.records.length;
        this.build_table_data();
      } else {
        this.wholeData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholeData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    }) 
  }
  public build_table_data() {
    this.tabledata = this.tabledatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  public edit_file_type_data(row) {
    this.form.patchValue(row);
  }
  public submit() {
    this.spinner.show();
    if(this.form.get('attach_type_id').value) {
      this.ds.putActionByUrl(this.ds.formData2string(this.ds.json2formdata(this.form.value)), 'update_attach_type/').subscribe(res=> {
        this.spinner.hide();
        if(res.status) {
          this.get_file_types();
          this.form.reset();
          this.actionEvent.emit('');
          this.alert.success(res.msg);
          setTimeout(() => {
            this.modalRefA.hide();
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'create_attach_type').subscribe(res=> {
        this.spinner.hide();
        if(res.status) {
          this.get_file_types();
          this.form.reset();
          this.actionEvent.emit('');
          this.alert.success(res.msg);
          setTimeout(() => {
            this.modalRefA.hide();
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    }
  }
  public checkallItemsFType(event) {
    if (event.target.checked) {
      $(".appfiletypes .eachItemFType").prop("checked", true);
      $(".appfiletypes .makeback").addClass("bground");
      $(".appfiletypes .deleteIconDiv").show();
    } else {
      $(".appfiletypes .eachItemFType").prop("checked", false);
      $(".appfiletypes .makeback").removeClass("bground");
      $(".appfiletypes .deleteIconDiv").hide();
    }
  }
  public checkeachItemFType(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appfiletypes .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appfiletypes .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appfiletypes .eachItemFType:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appfiletypes .eachItemFType").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appfiletypes .deleteIconDiv").show();
    } else {
      $(".appfiletypes .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appfiletypes #allItemsFType").prop("checked", true);
    } else {
      $(".appfiletypes #allItemsFType").prop("checked", false);
    }
  }
  public deleteBulkDataFType() {
    const checkedtotal = [];
    $(".appfiletypes .eachItemFType:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'delete_attach_type').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.actionEvent.emit('');
                this.get_file_types();
                $(".appfiletypes #allItemsFType").prop("checked", false);
                $(".appfiletypes .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
