import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { AlertService } from '../alert/alert.service';
import moment from "moment-timezone/builds/moment-timezone-with-data-2012-2022";
import 'moment/locale/ar';
import { MessagingService } from '@core/bootstrap/messaging.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  lang_key = localStorage.getItem("lang_key") || "en";
  notifications = [];
  notifications_number;
  page = 1;
  loading = false;
  stopVirtual = false;
  isInit = true;

  constructor(public ds:DataService, public ls:LocalStorage, public lang:LanguagePipe,
    public spinner : PreloaderService, public alert:AlertService, public router:Router, private dialogRef: MatDialogRef<NotificationsComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data, private messagingService:MessagingService) { }

  ngOnInit() {
		if(this.lang_key == 'ar') {
			moment.locale('ar_SA');
		} else {
			moment.locale('en');
		}
		this.getInitNotifications();
    this.messagingService.currentMessage.subscribe(res => {
      console.log(res);
      if(res) {
        this.getInitNotifications();
      }
    })
	}
	getTime(date) {
		return moment(date, "YYYY-MM-DD HH:mm:ss").fromNow();
	}
	onNotiClick(noti) {
		this.router.navigate([noti.extra_data?.angular]);
    this.dialogRef.close(this.notifications_number);
	}

	fetchMore(e) {
      if (e.endIndex == this.notifications.length-1 && e.endIndex != -1 && !this.stopVirtual) {
        this.loading = true;
        this.messagingService.getNotifications(this.page).subscribe(res => {
          this.loading = false;
            if(res.status) {
              this.notifications_number = res.total;
              if(res.lists == false) {
                this.stopVirtual = true;
                return;
              } else {
                this.notifications = this.notifications.concat(res.lists);
                this.page = res.page.page_no +1;
                this.stopVirtual = false;
              }
            
            
            }
        }, err => {
          this.loading = false;
        })
      }
    
	}

	deleteNoti(e:any, noti) {
		if (!e) e = window.event;
		e.cancelBubble = true;
		if (e.stopPropagation) e.stopPropagation();
		this.spinner.show();
		let params = new FormData();
		params.append('noty_messages_id', noti?.noty_messages_id);
		this.ds.getActionByUrl(this.ds.formData2string(params), 'notification/delete_noti').subscribe(res => {
			this.spinner.hide();
			if(res.status) {
				this.getInitNotifications();
			}
		}, err => {

		})
	}

  getInitNotifications() {
    this.notifications = [];
    this.page = 1;
    this.loading = true;
        this.messagingService.getNotifications(this.page).subscribe(res => {
          this.loading = false;
            if(res.status) {
              this.notifications_number = res.total;
              if(res.lists == false) {
                this.stopVirtual = true;
                return;
              } else {
                this.notifications = this.notifications.concat(res.lists);
                this.page = res.page.page_no +1;
                this.stopVirtual = false;
              }
            }
        }, err => {
          this.loading = false;
        })
  }

  closeModal() {
    this.dialogRef.close(this.notifications_number);
  }

}
