import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	FormGroup,
	FormControl,
	Validators
} from '@angular/forms';
import {
	MatDialog
} from '@angular/material/dialog';
import {
	Router
} from '@angular/router';
import {
	PreloaderService
} from '@core';
import {
	DataService
} from '@core/bootstrap/data.service';
import {
	LocalStorage
} from '@core/bootstrap/localstorage.service';
import {
	environment
} from '@env/environment';
import {
	AlertService
} from '@shared/components/alert/alert.service';
import {
	ConfirmationDialog
} from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import {
	LanguagePipe
} from '@shared/pipes/language.pipe';
interface list {
	id ? : number;
	transaction_request_id: string,
		transactions_records_datetime: string,
		transactions_records_id: string,
		transactions_records_note: string,
		transactions_records_user_id: string,
		transactions_records_user_name: string
}
@Component({
	selector: 'app-formpq1-view',
	templateUrl: './formpq1-view.component.html',
	styleUrls: ['./formpq1-view.component.scss']
})
export class Formpq1ViewComponent implements OnInit {
	lang_key = localStorage.getItem('lang_key') || 'en';
	@Input() form_key;
	@Input() form_request_id;
	@Input() actionPage;
	apiurl = environment.SERVER_ORIGIN;
	admin = JSON.parse(this.ls.getItem('is_admin'));
	viewdata: any = [];
	files: any = [];
	dates: any = [];
	notes: any = [];
	persons: any = [];
	history: any = [];
	request: any = [];
	teams: any = [];
	custom_language_phrases: any = [];
	form_pq1_quotation_restrictions_fixed_optional: any = [];
	form_pq1_quotation_restrictions_other_request: any = [];
	form_pq1_quotation_restrictions_fixed_required = false;
	@ViewChild('contentData', {
		static: false
	}) ss;
	@ViewChild('contentData1', {
		static: false
	}) ss1: ElementRef;
	barcode;
	submitter;
	actionbuttons: any = [];
	verifylist: any = [];
	//verification
	sendstatus: boolean = false;
	sendlist: any = [];
	verifystatus: boolean = false;
	verifyerrormsg: any = '';
	nodata: string = this.lang.transform('lang_loading');
	sendcodestatus = false;
	codemsg: string = this.lang.transform('lang_send_code');
	form: FormGroup;
	approve: FormGroup;
	listofdatas: list[];
	page = 1;
	pageSize = 10;
	teamshowstatus = false;
	collectionSize;
	constructor(public ds: DataService, public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef) {}
	ngOnInit(): void {
		this.spinner.show();
		this.get_data_view();
		this.buildform();
		this.approveform();
	}
	public get_data_view() {
		this.viewdata = [];
		this.ds.getActionByUrl([], 'form/' + this.form_key + '/vr/' + this.form_request_id).subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.request = (res.transactions_request) ? res.transactions_request : [];
				this.lang_key = this.request.lang_key;
				this.dates = (res.transactions_date.status) ? res.transactions_date.records : [];
				this.notes = (res.transactions_notes.status) ? res.transactions_notes.records : [];
				let his = (res.transactions_records.status) ? res.transactions_records.records : [];
				this.listofdatas = his;
				this.collectionSize = his.length;
				this.refreshLists();
				this.submitter = (res.transactions_submitter.status) ? res.transactions_submitter.records : '';
				this.barcode = (res.transactions_barcode_version.status) ? res.transactions_barcode_version.records : '';
				this.persons = (res.transactions_persons.status) ? res.transactions_persons.records : [];
				this.actionbuttons = (res.transactions_buttons) ? res.transactions_buttons : [];
				this.viewdata = (res.form_pq1_data.status) ? res.form_pq1_data.records : [];
				if (this.viewdata.content) {
					this.ss.nativeElement.innerHTML = this.viewdata.content;
				}
				if (res.form_pq1_quotation_restrictions_fixed_required && res.form_pq1_quotation_restrictions_fixed_required.status) {
					this.form_pq1_quotation_restrictions_fixed_required = true;
					let text = "";
					res.form_pq1_quotation_restrictions_fixed_required.records.forEach((v, k) => {
						text += v.label + '<br>';
					});
					this.changeDetectorRef.detectChanges();
					this.ss1.nativeElement.innerHTML = text;
				}
				this.teams = (res.form_pq1_quotation_teams_request.status) ? res.form_pq1_quotation_teams_request.records : [];
				this.form_pq1_quotation_restrictions_fixed_optional = (res.form_pq1_quotation_restrictions_fixed_optional.status) ? res.form_pq1_quotation_restrictions_fixed_optional.records : [];
				this.form_pq1_quotation_restrictions_other_request = (res.form_pq1_quotation_restrictions_other_request.status) ? res.form_pq1_quotation_restrictions_other_request.records : [];
				this.files = (res.form_pq1_files.status) ? res.form_pq1_files.records : [];
				this.custom_language_phrases = (res.custom_language_phrases && res.custom_language_phrases.status) ? res.custom_language_phrases.records : [];
			} else {
				this.ds.dialogf('', res.error);
				this.router.navigate(['transactions/allforms/']);
			}
		}, error => {
			this.spinner.hide();
			this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
		})
	}
	public buildform() {
		this.form = new FormGroup({
			transaction_request_id: new FormControl(this.form_request_id, [Validators.required]),
			transaction_persons_type: new FormControl('', [Validators.required]),
			sender_type: new FormControl('', [Validators.required]),
			do: new FormControl('', [Validators.required]),
			transactions_persons_action_code: new FormControl('', [Validators.required]),
			transactions_persons_last_step:  new FormControl('')
		})
	}
	public approveform() {
		this.approve = new FormGroup({
			transaction_request_id: new FormControl(this.form_request_id, [Validators.required]),
			user_pass: new FormControl('', [Validators.required])
		})
	}
	public showverificationform(transaction_persons_type) {
		this.spinner.show();
		this.form.reset();
		this.sendstatus = false;
		this.sendcodestatus = false;
		this.verifyerrormsg = "";
		this.form.get('transaction_request_id').setValue(this.form_request_id);
		this.form.get('transaction_persons_type').setValue(transaction_persons_type);
		this.form.get('transactions_persons_last_step').setValue(this.request.transaction_request_last_step);
		if (transaction_persons_type === 'doall') {
			this.form.get('do').setValue('do_all');
			this.form.get('transaction_persons_type').setValue('');
		} else {
			this.form.get('do').setValue('do');
		}
		this.verifylist = [];
		this.ds.getActionByUrl([], 'tc/sender/select').subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.verifylist = res.data;
			} else {
				this.ds.dialogf('', res.error);
			}
		}, error => {
			this.spinner.hide();
			this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
		})
	}
	public sendVerificationCode() {
		this.spinner.show();
		this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'tc/sender/send_code').subscribe(data => {
			this.sendcodestatus = data.status;
			this.spinner.hide();
			if (data.status) {
				this.sendstatus = true;
				this.alert.success(data.msg);
				setTimeout(() => {
					this.sendcodestatus = false;
				}, 180000);
			} else {
				this.alert.error(data.error);
				this.sendstatus = false;
			}
		}, error => {
			this.sendcodestatus = false;
			this.spinner.hide();
			this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
			this.sendstatus = false;
		});
	}
	public confirmVerification() {
		this.spinner.show();
		this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'tc/sender/complete_action').subscribe(data => {
			this.spinner.hide();
			if (data.status) {
				this.alert.success(this.lang.transform('lang_success'));
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			} else {
				this.alert.error(data.error);
				this.verifyerrormsg = data.error;
			}
		}, error => {
			this.spinner.hide();
			this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
		});
	}
	public refreshLists() {
		this.history = this.listofdatas.map(
			(list, i) => ({
				id: i + 1,
				...list
			})).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
	}
	public preview_form_data() {
		this.ds.PreviewData('form/' + this.form_key + '/pr/' + this.form_request_id);
	}
	public empty_approve_form() {
		this.approve.reset();
		this.approve.get('transaction_request_id').setValue(this.form_request_id);
	}
	public approve_form() {
		this.spinner.show();
		this.ds.postActionByUrl(this.ds.json2formdata(this.approve.value), 'form/' + this.form_key + '/cm').subscribe(data => {
			this.spinner.hide();
			if (data.status) {
				this.ds.dialogf('', data.msg);
				this.approve.reset();
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			} else {
				this.ds.dialogf('', data.error);
			}
		}, error => {
			this.spinner.hide();
			this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
		});
	}
	public delete_form_request(form_key, form_request_id) {
		if (form_key && form_request_id && this.admin) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: '400px',
				data: {
					title: this.lang.transform('lang_confirmation'),
					message: this.lang.transform('lang_are_you_sure'),
				}
			});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.spinner.show();
					let param = new FormData();
					param.append('transaction_request_id', form_request_id);
					this.ds.postActionByUrl(param, 'form/' + form_key + '/dr').subscribe(res => {
						this.spinner.hide();
						if (res.status) {
							this.ds.dialogf('', res.msg);
							this.router.navigate(['transactions/allforms']);
						} else {
							this.ds.dialogf('', res.error);
						}
					}, error => {
						this.spinner.hide();
						this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
					});
				}
			});
		}
	}
	public able_to_show(user_type) {
		const returndata = this.persons.find(x => x.transaction_persons_type == user_type && x.transactions_persons_key1 == null && x.transactions_persons_val1 == null);
		if (returndata === undefined) {
			return false;
		} else {
			return true;
		}
	}
	public are_you_sure(form_key, form_request_id, type = '') {
		if (form_key && form_request_id) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: '400px',
				data: {
					title: this.lang.transform('lang_confirmation'),
					message: (type != "edit2") ? this.lang.transform('lang_edit_form_info') : 'Are you sure to edit the cost !?',
				}
			});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.router.navigate(['transactions/form/' + form_key + '/er/' + form_request_id + '/' + type]);
				}
			});
		}
	}
}