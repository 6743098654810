import { DOCUMENT } from "@angular/common";
import { Component, OnInit, AfterViewInit, Inject } from "@angular/core";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { MessagingService } from "@core/bootstrap/messaging.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
	constructor(
		public ds: DataService,
		private preloader: PreloaderService,
		private messagingService: MessagingService,
		private translate: TranslateService,
		@Inject(DOCUMENT) private document: Document,
		private ls: LocalStorage
	) {
		localStorage.setItem("lang_key", localStorage.getItem("lang_key") || "en");
		localStorage.setItem(
			"direction",
			localStorage.getItem("direction") || "ltr"
		);
		const defaultLang = localStorage.getItem("lang_key") || "en";
		this.translate.setDefaultLang(defaultLang);
		this.document.body.dir = localStorage.getItem("direction") || "ltr";
	}
	ngOnInit() {
		// this.ds.reset_whole_pagination();
		this.messagingService.requestPermission();
		this.messagingService.receiveMessage();
	}
}
