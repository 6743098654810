import { trigger, state, style, transition, animate } from '@angular/animations';
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';

import { DashboardService } from './dashboard.srevice';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  styles: [
    `
      .mat-raised-button {
        margin-right: 8px;
        margin-top: 8px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DashboardService],
  animations: [
    trigger("fadeCosts", [
      state(
        "void",
        style({
          opacity: 0
        })
      ),
      transition("void <=> *", animate(500))
    ]),
    trigger("fadeActivities", [
      state(
        "void",
        style({
          opacity: 0
        })
      ),
      transition("void <=> *", animate(1000))
    ]),
    trigger("fadeTraffic", [
      state(
        "void",
        style({
          opacity: 0
        })
      ),
      transition("void <=> *", animate(2000))
    ]),
    trigger("fadeRevenue", [
      state(
        "void",
        style({
          opacity: 0
        })
      ),
      transition("void <=> *", animate(2000))
    ]),
  ]
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  stats = this.dashboardSrv.getStats();
  dataSource = this.dashboardSrv.getData();
  charts = this.dashboardSrv.getCharts();
  chart1 = null;
  chart2 = null;
  constructor(
    private dashboardSrv: DashboardService
  ) {}
  ngOnInit() {}
  ngAfterViewInit() {
    // this.initChart();
  }
  ngOnDestroy() {
    if (this.chart1) {
      this.chart1.destroy();
    }
    if (this.chart2) {
      this.chart2.destroy();
    }
  }
  initChart() {
    this.chart1 = new ApexCharts(document.querySelector('#chart1'), this.charts[0]);
    this.chart1.render();
    this.chart2 = new ApexCharts(document.querySelector('#chart2'), this.charts[1]);
    this.chart2.render();
  }
}
