<section class="panel-expansion">
    <mat-accordion  class="stylepanels mb-0">
      <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
        <mat-expansion-panel-header class="mpanel-header">{{'lang_users' | language}}</mat-expansion-panel-header>
        <div class="material-panel-body">
            <div class="mbrl15 martb0">
                <div class="row">
                    <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                        <select class="form-control almnabrformcontrol" [(ngModel)]="searchStatus" (change)="spinner.show();search_rp_users()" matTooltip="{{'lang_status' | language }}">
                            <option value="">{{'lang_all' | language }}</option>
                            <option value="0">{{'lang_inactive' | language }}</option>
                            <option value="1">{{'lang_active' | language }}</option>
                        </select>
                    </div>
                    <div class="col-md-9 col-sm-6 col-xs-12 mb-10">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control almnabrformcontrol" (keyup)="search_rp_users();" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                            <span class="input-group-btn" *ngIf="able2add">
                                <button id="btnSearch" type="button" (click)="openModalU(signupusers);emptyUserForm();" matTooltip="{{'lang_create_users' | language }}" class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"></button>
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="table-responsive dropdownyes customResponsive">
                            <table border="1" class="table CustomTable">
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>{{'lang_name' | language }}</td>
                                        <td>{{'lang_email_address' | language }}</td>
                                        <td>{{'lang_mobile_number' | language }}</td>
                                        <td>{{'lang_registration_status' | language }}</td>
                                        <td>{{'lang_writer' | language }}</td>
                                        <td>{{'lang_action' | language }}</td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="users.length != '0'" class="AppendList">
                                    <tr *ngFor="let user of users; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{user.user_regsitration_id}} userstatus{{user.user_status}}">
                                        <td>{{i+1}}</td>
                                        <td>{{user.registration_name}}</td>
                                        <td>{{user.user_email_address}}</td>
                                        <td>{{user.user_mobile_number}}</td>
                                        <td *ngIf="user.user_registration_link_status == '0'">
                                            <i matTooltip="{{'lang_inactive' | language }}" class="fa fa-circle red fa-icon pad0"></i>
                                        </td>
                                        <td *ngIf="user.user_registration_link_status == '1'">
                                            <i matTooltip="{{'lang_active' | language }}" class="fa fa-circle green fa-icon pad0"></i>
                                        </td>
                                        <td>{{user.writer}}</td>
                                        <td>
                                            <i class="fa fa-eye mnabricon s2icon" (click)="signup_data_view(user);openModalV(viewUnSignupData);" matTooltip="{{'lang_view' | language }}"></i>
                                            <i class="fa fa-link mnabricon s2icon" *ngIf="user.user_registration_link_status == '0' " (click)="send_signup_link(user)" matTooltip="{{'lang_send_signup_link' | language }}"></i>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="users.length == '0'" class="AppendList">
                                    <tr class="odd">
                                        <td colspan="8" align="center">{{usererrormsg}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="users.length != '0'" class="card-footer padb0 bottomfooter">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-6  mb-10">
                        {{'lang_total' | language }} : {{userdata.page.total_records}}
                    </div>
                    <div class="col-lg-5 col-sm-6 col-12 mb-10">
                        <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="10" checked (click)="userpage(10)"> 10 </mat-radio-button>
                            <mat-radio-button value="20" (click)="userpage(20)"> 20 </mat-radio-button>
                            <mat-radio-button value="50" (click)="userpage(50)"> 50 </mat-radio-button>
                            <mat-radio-button value="100" (click)="userpage(100)"> 100 </mat-radio-button>
                            <mat-radio-button value="500" (click)="userpage(500)"> 500 </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mb-10">
                        {{'lang_goto' | language }} :
                        <select class="customselect" (change)="userlength($event.target.value)">
                            <option *ngFor="let k of ds.getrange(userdata.page.total_pages)" value="{{k}}">{{k}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
</section>
<ng-template #signupusers>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_create_users' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefU.hide()">&times;</button>
        </div>
        <form [formGroup]="userform" (ngSubmit)="user_operations()">
            <div class="modal-body">
                <div class="form-group">
                    <label for="group_key">{{'lang_group' | language }}:<span class="red">*</span></label>
                    <select class="form-control almnabrformcontrol" formControlName="group_key">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option *ngFor="let group of groups" value="{{group.group_id}}"> {{group.group_name}} </option>
                    </select>
                    <mat-error *ngIf="userform.controls['group_key'].touched && userform.controls['group_key'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="user_registration_firstname_en">{{'lang_human_resources_fname_english' | language }}:<span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_human_resources_fname_english' | language }}" formControlName="user_registration_firstname_en"/>
                    <mat-error *ngIf="userform.controls['user_registration_firstname_en'].touched && userform.controls['user_registration_firstname_en'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="user_registration_lastname_en">{{'lang_human_resources_lname_english' | language }}:<span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_human_resources_lname_english' | language }}" formControlName="user_registration_lastname_en" />
                    <mat-error *ngIf="userform.controls['user_registration_lastname_en'].touched && userform.controls['user_registration_lastname_en'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="user_registration_firstname_ar">{{'lang_human_resources_fname_arabic' | language }}:<span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_human_resources_fname_arabic' | language }}" formControlName="user_registration_firstname_ar" />
                    <mat-error *ngIf="userform.controls['user_registration_firstname_ar'].touched && userform.controls['user_registration_firstname_ar'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="user_registration_lastname_ar">{{'lang_human_resources_lname_arabic' | language }}:<span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_human_resources_lname_arabic' | language }}" formControlName="user_registration_lastname_ar" />
                    <mat-error *ngIf="userform.controls['user_registration_lastname_ar'].touched && userform.controls['user_registration_lastname_ar'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="user_email_address">{{'lang_email_address' | language }}:<span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_email_address' | language }}" formControlName="user_email_address" />
                    <mat-error *ngIf="userform.controls['user_email_address'].touched && userform.controls['user_email_address'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                    <mat-error *ngIf="userform.controls['user_email_address'].touched && userform.controls['user_email_address'].errors?.email"> {{'lang_field_valid_email' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="user_mobile_number">{{'lang_mobile_number' | language }}:<span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_mobile_number' | language }}" minlength="12" maxlength="12" formControlName="user_mobile_number" (keypress)="ds.numberOnly($event)"/>
                    <mat-error *ngIf="userform.controls['user_mobile_number'].touched && userform.controls['user_mobile_number'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                    <mat-error *ngIf="userform.controls['user_mobile_number'].errors?.minlength"> {{'lang_minimum' | language }} 12 </mat-error>
                    <mat-error *ngIf="userform.controls['user_mobile_number'].errors?.maxlength"> {{'lang_maximum' | language }} 12 </mat-error>
                </div>
                <div class="form-group">
                    <label for="personal_id_number">{{'lang_human_resources_id_number' | language }}: </label>
                    <input type="text" class="form-control almnabrformcontrol" minlength="10" maxlength="10" placeholder="{{'lang_human_resources_id_number' | language }}" formControlName="personal_id_number" (keypress)="ds.numberOnly($event)" />
                </div>                       
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefU.hide()">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>            
    </div>
</ng-template>
<ng-template #viewUnSignupData>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_view' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefV.hide();">&times;</button>
        </div>
        <div class="modal-body" *ngIf="viewdata">
            <div class="form-group">
                <div class="row" *ngIf="viewdata.username">
                    <div class="col-6">
                        <label>{{'lang_username' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.username}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_name' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.registration_name}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_email_address' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.user_email_address}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_mobile_number' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.user_mobile_number}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.personal_id_number != '0' ">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_human_resources_id_number' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: 
                            <b *ngIf="viewdata.personal_id_number">{{viewdata.personal_id_number}}</b>
                            <b *ngIf="!viewdata.personal_id_number">----</b>
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_user_status' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label *ngIf="viewdata.user_status !='1' ">: <b><i matTooltip="{{'lang_inactive' | language }}" class="fa fa-circle red fa-icon pad0"></i> {{'lang_inactive' | language }} </b></label>
                        <label *ngIf="viewdata.user_status =='1' ">: <b><i matTooltip="{{'lang_active' | language }}" class="fa fa-circle green fa-icon pad0"></i> {{'lang_active' | language }} </b></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_ondate' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.user_createdatetime}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.user_updatedatetime">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_onupdate' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.user_updatedatetime}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_registration_status' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label *ngIf="viewdata.user_registration_link_status =='0' ">: <b><i matTooltip="{{'lang_inactive' | language }}" class="fa fa-circle red fa-icon pad0"></i> {{'lang_inactive' | language }}</b></label>
                        <label *ngIf="viewdata.user_registration_link_status =='1' ">: <b><i matTooltip="{{'lang_active' | language }}" class="fa fa-circle green fa-icon pad0"></i> {{'lang_active' | language }}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_writer' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.writer}}</b></label>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal"  (click)="modalRefV.hide();">{{'lang_close' | language }}</button>
        </div>
    </div>
</ng-template>