<div class="card appjoinings">
	<div class="card-header">
		{{ "lang_communications" | language }}
	</div>
	<div class="card-body p-0">
		<div class="mbrl15 martb0">
			<div class="row">
				<div class="input-group input-group-sm mb-10 col-12">
					<input
						type="text"
						class="form-control almnabrformcontrol"
						(keyup)="get_employee_communications()"
						[(ngModel)]="searchKey"
						placeholder="{{ 'lang_search' | language }}"
					/>
					<span
						class="input-group-btn"
						*ngIf="able2add"
					>
						<button
							class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
							type="button"
							matTooltip="{{ 'lang_incoming' | language }}"
                            (click)="newCommunication('incoming')"
						></button>
                        <button
							class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"
							type="button"
							matTooltip="{{ 'lang_outgoing' | language }}"
                            (click)="newCommunication('outgoing')"
						></button>
					</span>
				</div>
			</div>

			<div class="row p-1">
				<div class="col-md-12">
					<div class="table-responsive dropdownyes customResponsive">
						<table
							border="1"
							class="table CustomTable"
							*ngIf="wholedata?.records?.length > '0'"
						>
							<thead>
								<tr>
									<td>{{ "lang_type" | language }}</td>
									<td>{{ "lang_start_date" | language }}</td>

									<td>{{ "lang_status" | language }}</td>
									<td>{{ "lang_action" | language }}</td>
								</tr>
							</thead>
							<tbody class="AppendList">
								<tr
									*ngFor="
										let item of wholedata?.records;
										let i = index;
										let odd = odd;
										let even = even
									"
									[ngClass]="{ odd: odd, even: even }"
								>
									<td>{{ item.joining_type_value }}</td>
									<td>{{ item.joining_start_date_english }}</td>

									<td>
										<span *ngIf="item.approved_status == 1">{{
											"lang_approved" | language
										}}</span>
										<span *ngIf="item.approved_status == 0">{{
											"lang_rejected" | language
										}}</span>
									</td>
									<td>
										<i
											class="fa fa-eye mnabricon"
											(click)="ds.PreviewData(item.preview_link)"
											matTooltip="{{ 'lang_preview' | language }}"
										></i>
									</td>
								</tr>
							</tbody>
						</table>
						<no-data
							style="margin: auto"
							[Opened]="wholedata?.records?.length == '0'"
						></no-data>
					</div>
				</div>
			</div>
		</div>
		<div
			*ngIf="wholedata?.records?.length != 0"
			class="card-footer padb0 customborder"
		>
			<div class="row">
				<div class="col-lg-3 col-sm-6 col-6 mb-10">
					{{ "lang_total" | language }} {{ wholedata?.page?.total_records }}
				</div>
				<div class="col-lg-5 col-sm-6 col-12 mb-10">
					<mat-radio-group aria-label="Select an option">
						<mat-radio-button
							value="10"
							checked
							(click)="spinner.show(); load_page_size(10)"
						>
							10
						</mat-radio-button>
						<mat-radio-button
							value="20"
							(click)="spinner.show(); load_page_size(20)"
						>
							20
						</mat-radio-button>
						<mat-radio-button
							value="50"
							(click)="spinner.show(); load_page_size(50)"
						>
							50
						</mat-radio-button>
						<mat-radio-button
							value="100"
							(click)="spinner.show(); load_page_size(100)"
						>
							100
						</mat-radio-button>
						<mat-radio-button
							value="500"
							(click)="spinner.show(); load_page_size(500)"
						>
							500
						</mat-radio-button>
					</mat-radio-group>
				</div>
				<div class="col-lg-4 col-sm-6 col-12 mb-10">
					{{ "lang_goto" | language }}
					<select
						class="customselect"
						(change)="spinner.show(); page_length($event.target.value)"
					>
						<option
							*ngFor="let k of ds.getrange(wholedata?.page?.total_pages)"
							value="{{ k }}"
						>
							{{ k }}
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</div>
