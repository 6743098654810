import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';


@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {
	lang_key = localStorage.getItem('lang_key') || 'en';
	form: FormGroup|any;
  users:any =[]
  constructor(	
    private fb: FormBuilder,
		private spinner: PreloaderService,
    public alert:AlertService,
    public router: Router,
		private snackBar:MatSnackBar,
		private ds: DataService) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      
      group_title_english: [null, Validators.required],
      group_title_arabic: [null, Validators.required],
 
      group_users: this.fb.array([
        this.fb.group({
          group_users: ['', Validators.required],
          usersArr: [[], Validators.required],
          trackingId: [this.generateUniqueId()],
           
        })
      ])
    })

  }

  addUsers() {

	 

		const creds = this.form.controls.group_users as FormArray;
 
	 
		creds.push(
			this.fb.group({
        group_users: ['', Validators.required],
        usersArr: [[], Validators.required],
        trackingId: [this.generateUniqueId()],
				 
			})
			
		);
 

	 
	  }
    deleteUser(index){
      const creds = this.form.controls.group_users as FormArray;
      if(this.form.controls.group_users == 1){
      this.showMsg('lang_you_must_keep_one_field', 'ok', 'error-snackbar');
      return 0;
        }
       creds.removeAt(index);
   
      }
      showMsg(msg:string, action:string, className:string) {
        this.snackBar.open(msg, action, {
          duration:4000,
          panelClass: [className]
        });
        }
      

      
public search_users(key,con:FormControl) {
  if(key){
    con.patchValue({
      usersArr:[]
    })
    let param = new FormData();
    param.append('search', key.term);
    param.append('lang_key', this.lang_key);
    param.append('user_type_id', '1');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        con.patchValue({
          usersArr:res.list
        })
      }
    })
  }

}

	trackByFnCustom(index: number, item: any) {
		return item.trackingId;
	  }
	  generateUniqueId() {
		return (
		  Math.random()
			.toString(36)
			.substring(2, 15) +
		  Math.random()
			.toString(36)
			.substring(2, 15)
		);
	  }



    submitForm(){

 
      if(this.form?.status == 'VALID'){
    
      
        
    
    
    
    
        this.spinner.show();
        let formData = new FormData();
    
        formData.append('group_title_english',this.form.value.group_title_english);
        formData.append('group_title_arabic',this.form.value.group_title_arabic);
        for (let index = 0; index < this.form.value.group_users.length; index++) {
          const element = this.form.value.group_users[index];
          formData.append('group_users[]',element.group_users);
        }
        
        
     
        this.ds.post(`at/create_groups`, formData).subscribe(data => {
          console.log(data);
          
           if(data.status) {
            
            this.alert.success(data.msg);
            this.form.reset()
          
         /*  if(this.lang_key == 'ar'){
            this.showMsg('تم انشاء السلفة بنجاح', 'ok', 'success-snackbar');
          }else{
            this.showMsg('Loan Created Successfully', 'ok', 'success-snackbar');
          } */
    
          setTimeout(() => {
            this.router.navigate(['/human_resources/attendance']);
          }, 1000);
    
          
          this.spinner.hide();
         
          } else {
            this.spinner.hide();
            this.alert.error(data.error);
          }  
    
         
        
        }, (error) => {
          this.spinner.hide();
    
            this.alert.error(error);
        })
     
     
        
      }else{
        this.form.controls.createInstallments.enable();
        this.showMsg('Please fill all fields first', 'ok', 'error-snackbar');
      }
    }

}
