import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-supervision-contractors',
  templateUrl: './supervision-contractors.component.html',
  styleUrls: ['./supervision-contractors.component.scss']
})
export class SupervisionContractorsComponent implements OnInit {
  @Input() supervision_data
  @Input() able2add
  @Input() able2edit
  @Input() project_data
  able2delete = JSON.parse(this.ls.getItem('is_admin'));
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
  }
}
