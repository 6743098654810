import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { environment } from '@env/environment';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { ApiRoutes } from 'app/routes/apiroutes';
declare var $:any;
@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
  animations: [
    trigger("fadeIn", [
      state(
        "void",
        style({
          opacity: 0
        })
      ),
      transition("void <=> *", animate(1000))
    ]),
  ]
})
export class ProjectsListComponent implements OnInit {
  branches:any        = [];
  services:any        = [];
  wholeData:any       = [];
  branchItems:any     = (localStorage.getItem('M_P_B') && localStorage.getItem('M_P_B') != 'null') ? localStorage.getItem('M_P_B').split(',') : null;
  serviceItems:any    = (localStorage.getItem('M_P_S_R') && localStorage.getItem('M_P_S_R') != 'null') ? localStorage.getItem('M_P_S_R').split(',') : null;
  searchKey:string    = localStorage.getItem('M_P_S_K') || "";
  pageno:any          = localStorage.getItem('M_P_P') || 1;
  pagesize:any        = localStorage.getItem('M_P_S') || 10;
  isAdmin             = this.ds.is_admin();
  nodata: string      = this.lang.transform('lang_loading');
  constructor(public lang : LanguagePipe, public spinner : PreloaderService, public ds:DataService, public dialog: MatDialog) { }
  ngOnInit(): void {
    this.spinner.show();
    this.get_branches();
    this.get_services();
    this.get_list_of_projects();
  }
  get_branches() {
    this.branches = [];
    this.ds.get('jQUW6F52AgToEsm/projects_view').subscribe(res => {
      if (res.status) {
        this.branches = res.records;
      }
    },error => {
    })
  }
  get_services() {
    this.services = [];
    this.ds.get('aB8Vlmue0xVSv8O/projects_view').subscribe(res => {
      if (res.status) {
        this.services = res.records;
      }
    }, error => {
    })
  }
  search_projects() {
    this.pageno = 1;
    this.pagesize = 10;
    this.get_list_of_projects();
  }
  loadpagesize(pagesize) {
    this.pageno = 1;
    this.pagesize = pagesize;
    this.get_list_of_projects();
  }
  loadpage(pageno) {
    this.pageno = pageno;
    this.pagesize = this.pagesize;
    this.get_list_of_projects();
  }
  get_list_of_projects() {
    localStorage.setItem('M_P_S_K', this.searchKey || "");
		localStorage.setItem('M_P_B', this.branchItems || "");
		localStorage.setItem('M_P_S_R', this.serviceItems || "");
		localStorage.setItem('M_P_P', this.pageno);
		localStorage.setItem('M_P_S', this.pagesize);
    let param = new FormData();
		param.append('branch_id', (this.branchItems && this.branchItems.length > 0) ? this.branchItems.toString() : '');
		param.append('search_services', (this.serviceItems && this.serviceItems.length > 0) ? this.serviceItems.toString() : '');
		param.append('search_key', this.searchKey || '');
    this.ds.getActionByUrl(this.ds.formData2string(param), ApiRoutes.listProjects+'/'+this.pageno+'/'+this.pagesize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.wholeData = res;
      } else {
        this.wholeData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholeData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  checkAllItems(event) {
    if (event.target.checked) {
      $(".projects .eachItem").prop("checked", true);
      $(".projects .makeback").addClass("bground");
      $(".projects .deleteIconDiv").show();
    } else {
      $(".projects .eachItem").prop("checked", false);
      $(".projects .makeback").removeClass("bground");
      $(".projects .deleteIconDiv").hide();
    }
  }
  checkEachItem(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".projects .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".projects .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".projects .eachItem:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".projects .eachItem").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".projects .deleteIconDiv").show();
    } else {
      $(".projects .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".projects #allItems").prop("checked", true);
    } else {
      $(".projects #allItems").prop("checked", false);
    }
  }
  deleteBulkData() {
    const checkedtotal = [];
    $(".projects .eachItem:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'g46s5dg4g/MAIN').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.get_list_of_projects();
                $(".projects #allItems").prop("checked", false);
                $(".deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
