import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
  apiurl = environment.SERVER_ORIGIN;
  @Input() able2edit;
  @Input() able2add;
  @Input() able2delete;
  @Input() id;
  @Input() branch_id;
  @Input() key;
  @Input() types;
  @Input() ownedit;
  @Input() requestfor;
  @Input() attachments;
  @Input() user_data;
  @Input() filepermissionlevels;
  @Output() childToParentAttach = new EventEmitter();
  myeducations: any = [];
  eduerrormsglist: string = this.lang.transform('lang_loading');
  edudata: any = [];
  edpsize: number = 10;
  edpage: number = 1;
  educationform: FormGroup;
  eduviewdata: any = [];
  filename: any = this.lang.transform('lang_select_files');
  isFile: boolean = false;
  @Input() attachform;
  idfilename = this.lang.transform('lang_select_files');
  formstatus: boolean = false;
  formerror: string = "";
  searchKey = "";
  modalRefA: BsModalRef;
  modalRefV: BsModalRef;
  modalRefF: BsModalRef;
  expandstatus = false;
  constructor(public router:Router, public ds:DataService,public route :ActivatedRoute, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.expandstatus = (this.requestfor == 'profile') ? true : false;
    this.education_form();
    this.get_my_educations();
  }
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  public openModalFile(template: TemplateRef<any>) {
    this.modalRefF = this.modalService.show(template, environment.modelconfig);
  }
  //code starts for Other Educations
  public edupage(size) {
    this.edpsize = size;
    this.edpage = 1;
    this.get_my_educations();
  }
  public edulength(page) {
    this.edpage = page;
    this.get_my_educations();
  }
  public search_my_education() {
    this.edpage = 1;
    this.edpsize= 10;
    this.get_my_educations();
  }
  public get_my_educations() {
    let formData = new FormData();
    formData.append('id', this.id);
    formData.append('employee_id_number', this.user_data.employee_id_number);
    formData.append('branch_id', this.branch_id);
    formData.append('searchKey', this.searchKey || '');
    let url = (this.requestfor === "profile") ? 'my_educations' :'get_my_educations';
    this.ds.postActionByUrl(formData, url+'/'+this.edpage+'/'+this.edpsize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.edudata = res;
      } else {
        this.edudata = [];
        this.eduerrormsglist = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.edudata = [];
      this.eduerrormsglist = error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public education_form() {
    this.educationform = new FormGroup({
      education_id: new FormControl(''),
      education_title: new FormControl('', [Validators.required]),
      education_descriptions: new FormControl('', [Validators.required]),
      education_start_date: new FormControl('', [Validators.required]),
      education_end_date: new FormControl(''),
      education_certification_file: new FormControl(''),
    });
  }
  public uploadEDUFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.filename = fileid.name;
      this.isFile = true;
      this.educationform.get("education_certification_file").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.educationform.get("education_certification_file").setValue([]);
      this.filename = this.lang.transform('lang_select_files');
    }
  }
  public removeFile() {
    this.isFile = false;
    this.educationform.get("education_certification_file").setValue([]);
    this.filename = this.lang.transform('lang_select_files');
  }
  public emptyeducationform() {
    this.removeFile();
    this.educationform.reset();
    this.alert.clear();
  }
  public education_operations() {
    this.spinner.show();
    const formData = this.ds.json2formdata(this.educationform.value);
    let url = '';
    if(this.requestfor !== "profile"){
      url = (this.educationform.get('education_id').value) ? 'update_education' : 'create_education';
    } else{
      url = (this.educationform.get('education_id').value) ? 'update_my_education' : 'create_my_education';
    }
    formData.append('branch_id', this.branch_id);
    formData.append('employee_id_number', this.user_data.employee_id_number);
    formData.append('id', this.id);
    formData.append('education_start_date', this.educationform.get('education_start_date').value || '');
    formData.append('education_end_date', this.educationform.get('education_end_date').value || '');
    this.ds.postActionByUrl(formData, url).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.educationform.reset();
        this.alert.success(data.msg);
        this.get_my_educations();
        setTimeout(() => {
          this.modalRefA.hide();
        }, 2000);
      } else {
        this.alert.error(data.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error(error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public view_my_education(edu) {
    this.eduviewdata = edu;
  }
  public edit_education(edu) {
    this.educationform.patchValue({
      education_id: edu.education_id,
      education_title: edu.education_title,
      education_descriptions: edu.education_descriptions,
      education_start_date: this.ds.encon(edu.education_start_date),
      education_end_date: this.ds.encon(edu.education_end_date),
      education_certification_file: null
    });
  }
  public checkAllEdu(event) {
    if (event.target.checked) {
        $(".appeducations .eachItemEdu").prop("checked", true);
        $(".appeducations .makeback").addClass("bground");
        $(".appeducations .deleteIconDiv").show();
    } else {
        $(".appeducations .eachItemEdu").prop("checked", false);
        $(".appeducations .makeback").removeClass("bground");
        $(".appeducations .deleteIconDiv").hide();
    }
  }
  public checkEachItemEdu(event) {
      if (event.target.checked) {
          const val = event.target.value;
          $(".appeducations .removerow" + val).addClass("bground");
      } else {
          const val = event.target.value;
          $(".appeducations .removerow" + val).removeClass("bground");
      }
      const checkedtotal = [];
      $(".appeducations .eachItemEdu:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      const totalrow = [];
      $(".appeducations .eachItemEdu").each(function() {
          totalrow.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          $(".appeducations .deleteIconDiv").show();
      } else {
          $(".appeducations .deleteIconDiv").hide();
      }
      if (checkedtotal.length === totalrow.length) {
          $(".appeducations #allItemsEdu").prop("checked", true);
      } else {
          $(".appeducations #allItemsEdu").prop("checked", false);
      }
  }
  public deleteBulkDataEdu() {
      const checkedtotal = [];
      $(".appeducations .eachItemEdu:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          const dialogRef = this.dialog.open(ConfirmationDialog, {
              disableClose: true,
              width: '400px',
              data: {
                  title: this.lang.transform('lang_confirmation'),
                  message: this.lang.transform('lang_are_you_sure'),
              }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                  this.spinner.show()
                  this.ds.deleteActionByUrl(checkedtotal, "01f5086b879a62a05da4094dac203558/EDUCATION/"+this.id+'/'+this.branch_id).subscribe(
                      (data) => {
                          this.spinner.hide()
                          if (data.status) {
                              this.search_my_education();
                              $(".appeducations #allItemsEdu").prop("checked", false);
                              $(".appeducations .deleteIconDiv").hide();
                              this.ds.dialogf('', data.msg);
                          } else {
                              this.ds.dialogf('', data.error);
                          }
                      },
                      (error) => {
                          this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                          this.spinner.hide()
                      }
                  );
              }
          });
      } else {
          this.ds.dialogf();
      }
  }
  public emptyAttachForm(type) {
    this.formstatus = false;
    this.formerror = "";
    this.attachform.reset();
    this.attachform.get("employee_number").setValue(this.id);
    this.attachform.get("attachment_type").setValue(type);
    this.idfilename = this.lang.transform('lang_select_files');
    this.alert.clear();
  }
  public uploadAttachFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.attachform.get("attachment_link").setValue(fileid, { emitEvent: true });
    } else {
      this.attachform.get("attachment_link").setValue(null);
      this.idfilename = this.lang.transform('lang_select_files');
    }
  }
  public upload_files() {
    this.spinner.show();
    this.ds.postActionByUrl(this.ds.json2formdata(this.attachform.value), 'hr_upload_attachments').subscribe(data => {
      this.spinner.hide();
      this.formstatus = data.status;
      if (data.status) {
        this.childToParentAttach.emit('EDUCATION');
        setTimeout(() => {
          this.modalRefF.hide();
        }, 2000);
        this.alert.success(data.msg);
      } else {
        this.alert.error(data.error);
      }
    }, error => {
        this.spinner.hide();
        this.alert.error(error.error + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    });
  }
}