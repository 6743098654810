<form [formGroup]="form" class="form" (ngSubmit)="submit();">
    <section class="panel-expansion ckeditorhidetoolbar">
        <mat-accordion class="stylepanels">
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_edit_contract' | language}}  ( <span *ngIf="lang_key =='en'"> {{employee_data.firstname_english}} {{employee_data.lastname_english}}</span> <span *ngIf="lang_key =='ar'"> {{employee_data.firstname_arabic}} {{employee_data.lastname_arabic}} </span> )</mat-expansion-panel-header>
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_subject' | language}} <span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol" readonly placeholder="{{'lang_subject' | language}}" formControlName="subject">
                                <mat-error *ngIf="submitted && form.controls['subject'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_from_org' | language}} </label>
                                <input type="text" class="form-control almnabrformcontrol" placeholder="{{'lang_from_org' | language}}" formControlName="transaction_from">
                                <mat-error *ngIf="submitted && form.controls['transaction_from'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_work_domain' | language}} <span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol" placeholder="{{'lang_work_domain' | language}}" formControlName="work_domain">
                                <mat-error *ngIf="submitted && form.controls['work_domain'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>                        
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_work_location' | language}} <span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol" placeholder="{{'lang_work_location' | language}}" formControlName="work_location">
                                <mat-error *ngIf="submitted && form.controls['work_location'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_work_type' | language}} <span class="red">*</span></label>
                                <select formControlName="work_type" type="text" matTooltip="{{'lang_work_type' | language }}" class="form-control almnabrformcontrol">
                                    <option value="">{{'lang_choose_options' | language }}</option>
                                    <option value="1">{{'lang_fulltime' | language}}</option>
                                    <option value="2">{{'lang_parttime' | language}}</option>
                                    <option value="3">{{'lang_contract' | language}}</option>
                                    <option value="4">{{'lang_others' | language}}</option>
                                </select>
                                <mat-error *ngIf="submitted && form.controls['work_type'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label for="joining_date_english">{{'lang_human_resources_joining_date_english' | language }} : <span class="red">*</span></label>
                                <div class="positionrelatiove">
                                    <input formControlName="joining_date_english" type="text" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event, form.get('joining_date_arabic'));probation_enddate();" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
                                    <mat-datepicker-toggle matSuffix [for]="BDeng" class="posiab"></mat-datepicker-toggle>
                                    <mat-datepicker #BDeng disabled="false"></mat-datepicker>
                                    <mat-error *ngIf="submitted && form.controls['joining_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label for="joining_date_arabic">{{'lang_human_resources_joining_date_arabic' | language }} : <span class="red">*</span></label>
                                <input formControlName="joining_date_arabic" (ngModelChange)="ds.setARTOENDate($event, form.get('joining_date_english'));probation_enddate();" ngbDatepicker [readonly]="true" (click)="bdarab.toggle()" #bdarab="ngbDatepicker" type="text" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" />
                                <mat-error *ngIf="submitted && form.controls['joining_date_arabic'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label for="probation_period">{{'lang_human_resources_propation_period' | language }} : <span class="red"> * </span> <span *ngIf="probationenddate" class="red">{{'lang_end_date' | language }} : {{probationenddate}}</span> </label>
                                <ng-select 
                                    [items]="pperiods" 
                                    [multiple]="false" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    appendTo="body" 
                                    formControlName="probation_period"
                                    placeholder="{{'lang_human_resources_propation_period' | language}}"
                                    (change)="probation_enddate();">
                                </ng-select>
                                <mat-error *ngIf="submitted && form.controls['probation_period'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label for="contract_start_date_english">{{'lang_human_resources_contract_start_date' | language }} : <span class="red">*</span></label>
                                <div class="positionrelatiove">
                                    <input formControlName="contract_start_date_english" type="text" class="form-control almnabrformcontrol" (dateChange)="calculate_contract_enddate();vacation_enddate();" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="ctrse" />
                                    <mat-datepicker-toggle matSuffix [for]="ctrse" class="posiab"></mat-datepicker-toggle>
                                    <mat-datepicker #ctrse disabled="false"></mat-datepicker>
                                    <mat-error *ngIf="submitted && form.controls['contract_start_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label for="contract_period">{{'lang_human_resources_contract_period' | language }} : <span class="red">*</span></label>
                                <ng-select 
                                    [items]="cperiods" 
                                    [multiple]="false" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    appendTo="body" 
                                    formControlName="contract_period"
                                    placeholder="{{'lang_human_resources_contract_period' | language}}"
                                    (change)="calculate_contract_enddate();vacation_enddate();">
                                </ng-select>
                                <mat-error *ngIf="submitted && form.controls['contract_period'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label for="contract_end_date_english">{{'lang_human_resources_contract_end_date' | language }} : <span class="red" *ngIf="form.get('contract_period').value && form.get('contract_period').value !='0' ">*</span></label>
                                <div class="positionrelatiove">
                                    <input formControlName="contract_end_date_english" type="text" class="form-control almnabrformcontrol" [readonly]="true" placeholder="YYYY/MM/DD"/>
                                    <mat-error *ngIf="submitted && form.controls['contract_end_date_english'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="row">
                                <div class="col-6 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label for="vacation_paid_days">{{'lang_human_resources_vacation_paid_days' | language }} : <span class="red">*</span> <span class="red" *ngIf="vacation_custom_date">{{vacation_custom_date}}</span></label>
                                        <ng-select 
                                            [items]="vacationdays" 
                                            [multiple]="false" 
                                            bindLabel="label" 
                                            bindValue="value" 
                                            clearAllText="Clear"
                                            appendTo="body" 
                                            formControlName="vacation_paid_days"
                                            placeholder="{{'lang_human_resources_vacation_paid_days' | language}}"
                                            (change)="vacation_enddate();">
                                        </ng-select>
                                        <mat-error *ngIf="submitted && form.controls['vacation_paid_days'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                    </div>
                                </div>
                                <div class="col-6 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label for="vacation_custom_date">{{'lang_human_resources_custom_vacation_date' | language }} : </label>
                                        <div class="input-group input-group-sm">
                                            <input [matDatepicker]="vacation_custom_dateU" formControlName="vacation_custom_date" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly>
                                            <span class="input-group-btn">
                                                <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="vacation_custom_dateU"></mat-datepicker-toggle>
                                                <mat-datepicker #vacation_custom_dateU></mat-datepicker>
                                            </span>
                                            <span class="input-group-btn">
                                                <button (click)="form.get('vacation_custom_date').setValue('');" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label for="working_days_per_week">{{'lang_human_resources_working_days_per_week' | language }} : <span class="red">*</span></label>
                                <ng-select 
                                    [items]="workingdays" 
                                    [multiple]="false" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    appendTo="body" 
                                    formControlName="working_days_per_week"
                                    placeholder="{{'lang_human_resources_working_days_per_week' | language}}">
                                </ng-select>
                                <mat-error *ngIf="submitted && form.controls['working_days_per_week'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label for="working_hours_per_day">{{'lang_human_resources_working_hours' | language }} : <span class="red">*</span></label>
                                <ng-select 
                                    [items]="workinghours"
                                    [multiple]="false"
                                    bindLabel="label"
                                    bindValue="value"
                                    clearAllText="Clear"
                                    appendTo="body" 
                                    formControlName="working_hours_per_day"
                                    placeholder="{{'lang_human_resources_working_hours' | language}}">
                                </ng-select>
                                <mat-error *ngIf="submitted && form.controls['working_hours_per_day'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_basic_salary' | language}} <span class="red">*</span></label>
                                <input type="text" (change)="ds.CheckDecimal($event.target.value, form.get('basic_salary'));calculate_net_amount();" (keyup)="calculate_net_amount();" class="form-control almnabrformcontrol" placeholder="{{'lang_basic_salary' | language}}" formControlName="basic_salary">
                                <mat-error *ngIf="submitted && form.controls['basic_salary'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_home_allowance' | language}} </label>
                                <input type="text" (change)="ds.CheckDecimal($event.target.value, form.get('home_allowance'));calculate_net_amount();" (keyup)="calculate_net_amount();" class="form-control almnabrformcontrol" placeholder="{{'lang_home_allowance' | language}}" formControlName="home_allowance">
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_net_amount' | language}} </label>
                                <input type="text" class="form-control almnabrformcontrol" placeholder="{{'lang_net_amount' | language}}" readonly disabled [ngModelOptions]="{standalone: true}" [(ngModel)]="net_amount">
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <span class="red opacity0">*</span>
                            <button *ngIf="!otherallowances" class="albutton mnabrbutton mat-accent" mat-button (click)="enable_field('allowances');"> <i class="fa fa-plus"></i> {{'lang_other_allowances' | language}}</button>
                        </div>
                    </div>
                    <div class="row mb-10" *ngIf="otherallowances">
                        <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                            <fieldset class="cardback fieldset">
                                <legend>{{'lang_other_allowances' | language}} <span class="red">* <mat-icon class="red mnabricon" (click)="disable_field('allowances');calculate_net_amount();" matTooltip="{{'lang_close' | language }}">close</mat-icon></span>
                                </legend>
                                <div class="table-responsive customResponsive tablefields">
                                    <table border="1" class="table CustomTable" width="100%" formArrayName="other_allowances">
                                        <thead>
                                            <tr>
                                                <td style="padding: 5px;word-break: break-all;"> <mat-icon class="mnabricon" matTooltip="{{'lang_add' | language }}" (click)="add_new_others();calculate_net_amount();">add_box</mat-icon></td>
                                                <td style="padding: 5px;word-break: break-all;"> {{'lang_desc_english' | language}} </td>
                                                <td style="padding: 5px;word-break: break-all;"> {{'lang_invoice_item_amount' | language}} </td>
                                                <td style="padding: 5px;word-break: break-all;"> {{'lang_desc_arabic' | language}} </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let otr of othersgroup.controls; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" [formGroupName]="i" style="background: #ffffff;">
                                                <td width="1%" class="verticalalignmid">
                                                    <mat-icon class="mnabricon red" matTooltip="{{'lang_delete' | language }}" (click)="remove_other_items(i);calculate_net_amount();">close</mat-icon>
                                                </td>
                                                <td style="padding: 5px;word-break: break-all;" style="position: relative;">
                                                    <input type="text" formControlName="other_allowance_description_en" placeholder="{{'lang_description' | language}}" autocomplete="off" class="form-control almnabrformcontrol" />
                                                    <mat-error *ngIf="submitted && otherallowances && other_allownces_datas.controls[i].get('other_allowance_description_en').errors?.required">{{'lang_field_required' | language}} </mat-error>
                                                </td>
                                                <td style="padding: 5px;word-break: break-all;" style="position: relative;">
                                                    <input type="text" formControlName="other_allowance_amount" placeholder="0.00" (change)="ds.CheckDecimal($event.target.value, other_allownces_datas.controls[i].get('other_allowance_amount'));calculate_net_amount();" (keyup)="calculate_net_amount();" autocomplete="off" class="form-control almnabrformcontrol" />
                                                    <mat-error *ngIf="submitted && otherallowances && other_allownces_datas.controls[i].get('other_allowance_amount').errors?.required"> {{'lang_field_required' | language}} </mat-error>
                                                </td>
                                                <td style="padding: 5px;word-break: break-all;" style="position: relative;">
                                                    <input type="text" formControlName="other_allowance_description_ar" placeholder="{{'lang_description' | language}}" autocomplete="off" class="form-control almnabrformcontrol" />
                                                    <mat-error *ngIf="submitted && otherallowances && other_allownces_datas.controls[i].get('other_allowance_description_ar').errors?.required">{{'lang_field_required' | language}} </mat-error>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row mb-10">
                        <div class="col-md-2 col-sm-4 col-xs-12 mb-10" *ngIf="!markstatus">
                            <button type="button" class="albutton mnabrbutton mat-accent" mat-button (click)="enable_field('mark')"> <i class="fa fa-plus"></i> {{'lang_need_mark' | language}}</button>
                        </div>
                        <div class="col-md-2 col-sm-4 col-xs-12 mb-10" *ngIf="!reviewstatus">
                            <button type="button" class="albutton mnabrbutton mat-accent" mat-button (click)="enable_field('review')"> <i class="fa fa-plus"></i> {{'lang_need_review' | language}}</button>
                        </div>
                        <div class="col-md-2 col-sm-4 col-xs-12 mb-10" *ngIf="!otherterms">
                            <button type="button" class="albutton mnabrbutton mat-accent" mat-button (click)="enable_field('terms')"> <i class="fa fa-plus"></i> {{'lang_additional_terms' | language}}</button>
                        </div>
                    </div>
                    <div class="row mb-10" *ngIf="otherterms">
                        <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                            <fieldset class="cardback fieldset">
                                <legend>{{'lang_additional_terms' | language}} <span class="red">* <mat-icon class="red mnabricon" (click)="disable_field('terms')" matTooltip="{{'lang_close' | language }}">close</mat-icon></span>
                                </legend>
                                <div class="table-responsive customResponsive tablefields">
                                    <table border="1" class="table CustomTable" width="100%" formArrayName="other_terms">
                                        <thead>
                                            <tr>
                                                <td style="padding: 5px;word-break: break-all;"> <mat-icon class="mnabricon" matTooltip="{{'lang_add' | language }}" (click)="add_new_terms();">add_box</mat-icon></td>
                                                <td style="padding: 5px;word-break: break-all;"> {{'lang_desc_english' | language}} </td>
                                                <td style="padding: 5px;word-break: break-all;"> {{'lang_desc_arabic' | language}} </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let otr of termsgroup.controls; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" [formGroupName]="i" style="background: #ffffff;">
                                                <td width="1%" class="verticalalignmid">
                                                    <mat-icon class="mnabricon red" matTooltip="{{'lang_delete' | language }}" (click)="remove_terms_items(i);">close</mat-icon>
                                                </td>
                                                <td style="padding: 5px;word-break: break-all;" style="position: relative;">
                                                    <input type="text" formControlName="terms_description_english" placeholder="{{'lang_desc_english' | language}}" autocomplete="off" class="form-control almnabrformcontrol" />
                                                    <mat-error *ngIf="submitted && otherterms && other_terms_datas.controls[i].get('terms_description_english').errors?.required">{{'lang_field_required' | language}} </mat-error>
                                                </td>
                                                <td style="padding: 5px;word-break: break-all;" style="position: relative;">
                                                    <input type="text" formControlName="terms_description_arabic" placeholder="{{'lang_desc_arabic' | language}}" autocomplete="off" class="form-control almnabrformcontrol" />
                                                    <mat-error *ngIf="submitted && otherterms && other_terms_datas.controls[i].get('terms_description_arabic').errors?.required"> {{'lang_field_required' | language}} </mat-error>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row mb-10">
                        <div class="col-12">
                            <fieldset class="cardback fieldset">
                                <legend>{{'lang_signature_stamp' | language}}</legend>
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label> {{'lang_first_party' | language}} <span class="red">*</span></label>
                                            <input type="text" formControlName="signature_a_title" placeholder="{{'lang_first_party' | language}}" class="form-control almnabrformcontrol">
                                            <mat-error *ngIf="submitted && form.controls['signature_a_title'].errors?.required"> {{'lang_field_required' | language}} </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label> {{'lang_human_resources_job_title_iqama' | language}} B </label>
                                            <input type="text" formControlName="signature_b_title" placeholder="{{'lang_human_resources_job_title_iqama' | language}} B" class="form-control almnabrformcontrol">
                                            <mat-error *ngIf="submitted && form.controls['signature_b_title'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label> {{'lang_second_party' | language}} <span class="red">*</span></label>
                                            <input type="text" formControlName="signature_c_title" placeholder="{{'lang_second_party' | language}}" class="form-control almnabrformcontrol">
                                            <mat-error *ngIf="submitted && form.controls['signature_c_title'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label> {{'lang_human_resources_employee_name' | language}} A </label>
                                            <ng-select 
                                                [items]="users" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="signature_a_user_id"
                                                placeholder="{{'lang_human_resources_employee_name' | language}}"
                                                (search)="search_users($event)"
                                                (focus)="users = []">
                                            </ng-select>
                                            <mat-error *ngIf="submitted && form.controls['signature_a_user_id'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label> {{'lang_human_resources_employee_name' | language}} B </label>
                                            <ng-select 
                                                [items]="users" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="signature_b_user_id"
                                                placeholder="{{'lang_human_resources_employee_name' | language}}"
                                                (search)="search_users($event)"
                                                (focus)="users = []">
                                            </ng-select>
                                            <mat-error *ngIf="submitted && form.controls['signature_b_user_id'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label> {{'lang_human_resources_employee_name' | language}} C </label>
                                            <ng-select 
                                                [items]="users" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="signature_c_user_id"
                                                placeholder="{{'lang_human_resources_employee_name' | language}}"
                                                (search)="search_users($event)"
                                                (focus)="users = []">
                                            </ng-select>
                                            <mat-error *ngIf="submitted && form.controls['signature_c_user_id'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row mb-10" *ngIf="markstatus || reviewstatus">
                        <div class="col-md-6 col-xs-12 col-sm-4 mb-10">
                            <fieldset class="cardback fieldset" *ngIf="markstatus">
                                <input type="hidden" formControlName="needMark">
                                <legend>{{'lang_markers' | language}} 
                                    <span class="red"> * 
                                        <mat-icon class="red mnabricon red" matTooltip="{{'lang_close' | language }}" (click)="disable_field('mark')">close</mat-icon>
                                    </span>
                                </legend>
                                <ng-select 
                                    [items]="users" 
                                    [multiple]="true" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body" 
                                    formControlName="marks"
                                    placeholder="{{'lang_markers' | language}}"
                                    (search)="search_users($event)"
                                    (focus)="users = []">
                                </ng-select>
                                <mat-error *ngIf="submitted && form.controls['marks'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </fieldset>
                        </div>
                        <div class="col-md-6 col-xs-12 col-sm-4 mb-10">
                            <fieldset class="cardback fieldset" *ngIf="reviewstatus">
                                <input type="hidden" formControlName="needReview">
                                <legend>{{'lang_reviewers' | language}} 
                                    <span class="red">* 
                                        <mat-icon class="red mnabricon red" matTooltip="{{'lang_close' | language }}" (click)="disable_field('review')">close</mat-icon>
                                    </span>
                                </legend>
                                <ng-select 
                                    [items]="users" 
                                    [multiple]="true" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body" 
                                    formControlName="reviews"
                                    placeholder="{{'lang_reviewers' | language}}"
                                    (search)="search_users($event)"
                                    (focus)="users = []">
                                </ng-select>
                                <mat-error *ngIf="submitted && form.controls['reviews'].errors?.required"> {{'lang_field_required' | language}}</mat-error>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="button" class="albutton mnabrbutton mat-accent" mat-button routerLink="/transactions/allforms">{{'lang_goto_list' | language }}</button>
                        </div>
                        <div class="col-md-8 col-sm-6 col-xs-12 mb-10">
                            <app-alert id="default-alert"></app-alert>
                        </div>
                        <div class="col-md-1 col-sm-2 col-xs-12 mb-10">
                            <input type="button" (click)="preview_form();" value="{{'lang_preview' | language}}" class="albutton mnabrbutton mat-accent" mat-button>
                        </div>
                        <div class="col-md-1 col-sm-2 col-xs-12 mb-10">
                            <input type="submit" value="{{'lang_save' | language}}" class="albutton mnabrbutton mat-accent" mat-button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</form>