import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { SendCodeComponent } from 'app/routes/transactions/FORM_PS_TR1/send-code/send-code.component';

@Component({
  selector: 'app-wri-step2',
  templateUrl: './wri-step2.component.html',
  styleUrls: ['./wri-step2.component.scss']
})
export class WriStep2Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  form_request_id   = this.route.snapshot.paramMap.get('form_request_id');
  form_key2          = this.route.snapshot.paramMap.get('form_key');
  stepName = 'Contractor_Team_Approval'
  rejectedValidators = [];
  form:FormGroup;
  users:any   = [];
  filename    = this.lang.transform('lang_select_files');

  constructor(public ds: DataService, private sweetAlert:SweetAlertService, public router: Router, public route: ActivatedRoute, public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog) {}
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id                   : new FormControl(this.form_request_id, Validators.required),
      contractor_approval_status               : new FormControl('', [Validators.required]),
      contractor_rejected_notes                : new FormControl('', [Validators.required])
    });
  }
  checkStatus() {
    if(this.form.get('contractor_approval_status').value == 'Return'){
      this.rejectedValidators=[Validators.required]
    } else {
      this.rejectedValidators=[]
    }
  }

  checkValidators() {
    if(this.form.get('contractor_approval_status').invalid) {
      this.alert.error(this.lang.transform('lang_contractor_approval_required'));
      return false;
    } else if(this.form.get('contractor_approval_status').value == 'Return' &&
      this.form.get('contractor_rejected_notes').invalid) {
      this.alert.error(this.lang.transform('lang_rejected_notes_required'));
      return false;
    }  else {
      return true;
    }
  }

  completeStep2() {
    if(this.checkValidators()) {
      let formData = new FormData();
      formData.append('transaction_request_id',this.form.value.transaction_request_id);
      formData.append('contractor_approval_status',this.form.value.contractor_approval_status);
      if(this.form.value.contractor_approval_status == "Return") {
        formData.append('contractor_rejected_notes',this.form.value.contractor_rejected_notes);
      }
      this.sweetAlert.confirmMessage('Are you sure?', 'Confirm').then(result => {
        if(result.isConfirmed) {
          this.spinner.show();
          this.ds.post(`form/${this.form_key}/Contractor_Team_Approval/0`, formData).subscribe(data => {
            if(data.status) {
              this.spinner.hide();
              this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
              setTimeout(() => {
                this.refreshComponent.emit(true);
              }, 2000);
            } else {
              this.spinner.hide();
              this.alert.error(data?.error);
            }
          }, (error) => {
            this.spinner.hide();
              this.alert.error(error);
          })
        }
      })
      
    } else {
      this.alert.error(this.lang.transform('lang_all_fields_required'));
    }
  }

  openModal() {
    const dialogRef = this.dialog.open(SendCodeComponent,{
      disableClose: true,
      width:'400px',
      data: {transaction_persons_type: 'signature', allData: this.allData, stepName:this.stepName,title:'lang_signature'}
      
    });
  }
}
