<!-- (ngSubmit)="submit();"  -->
<form class="form" [formGroup]="form">
	<section class="panel-expansion ckeditorhidetoolbar">
		<mat-accordion class="stylepanels">
			<mat-expansion-panel class="mpanel" [expanded]="true">
				<mat-expansion-panel-header class="mpanel-header">{{
					"lang_design_service_quotation" | language
				}}</mat-expansion-panel-header>
				<div class="mbrl15" [ngClass]="{ ' contentrtl': lang_key == 'ar' }">
					<!-- <div class="row mb-10">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_languages' | language}} <span class="red">*</span></label>
                                <ng-select 
                                    [items]="languages" 
                                    [multiple]="false" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    [virtualScroll]="true"
                                    [hideSelected]="true"
                                    clearAllText="Clear"
                                    appendTo="body" 
                                    formControlName="lang_key"
                                    placeholder="{{'lang_languages' | language}}"
                                    (change)="spinner.show();changedirection($event);">
                                </ng-select>
                                <mat-error *ngIf="submitted && form.controls['lang_key'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10" *ngIf="quotation_type_status">
                            <div class="form-group">
                                <label> {{'lang_quotation_type' | language}} <span class="red">*</span></label>
                                <ng-select 
                                    [items]="quottypes" 
                                    [multiple]="false" 
                                    bindLabel="label"
                                    bindValue="value"
                                    clearAllText="Clear"
                                    appendTo="body" 
                                    [virtualScroll]="true"
                                    [hideSelected]="true"
                                    formControlName="quotation_type"
                                    placeholder="{{'lang_quotation_type' | language}}"
                                    (change)="quotation_select($event);">
                                </ng-select>
                                <mat-error *ngIf="submitted && form.controls['quotation_type'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10" *ngIf="quotation_type_status">
                            <div class="form-group">
                                <label> {{'lang_vat' | language}} <span class="red">*</span></label>
                                <select class="form-control almnabrformcontrol" formControlName="vat_key">
                                    <option value="">{{'lang_choose_options' | language }}</option>
                                    <option *ngFor="let v of vat" value="{{v.keyword}}">{{v.label}}</option>
                                </select>
                                <mat-error *ngIf="submitted && form.controls['vat_key'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-10" *ngIf="quotation_type_status">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_subject' | language}} <span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol" formControlName="subject" placeholder="{{'lang_subject' | language}}">
                                <mat-error *ngIf="submitted && form.controls['subject'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_quote_care' | language}}</label>
                                <input type="text" class="form-control almnabrformcontrol" formControlName="care" placeholder="{{'lang_quote_care' | language}}">
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_projects_city' | language}} <span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol" formControlName="city" placeholder="{{'lang_projects_city' | language}}">
                                <mat-error *ngIf="submitted && form.controls['city'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-10" *ngIf="quotation_type_status">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_projects_total_area' | language}} <span class="red">*</span></label>
                                <input type="text" class="form-control almnabrformcontrol" formControlName="total_area_m2" placeholder="{{'lang_projects_total_area' | language}}">
                                <mat-error *ngIf="submitted && form.controls['total_area_m2'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_projects_duration_days' | language}}</label>
                                <input type="text" class="form-control almnabrformcontrol" formControlName="duration_days" placeholder="{{'lang_projects_duration_days' | language}}">
                                <mat-error *ngIf="submitted && form.controls['duration_days'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_projects_occupancies' | language}} <span class="red">*</span></label>
                                <ng-select 
                                    [items]="occupy" 
                                    [multiple]="true" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    [virtualScroll]="true"
                                    [hideSelected]="true"
                                    clearAllText="Clear"
                                    appendTo="body" 
                                    formControlName="main_occupancies"
                                    placeholder="{{'lang_projects_occupancies' | language}}">
                                </ng-select>
                                <mat-error *ngIf="submitted && form.controls['main_occupancies'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-10" *ngIf="quotation_type_status">
                        <div class="col-12">
                            <label> {{'lang_description' | language}} <span class="red">*</span></label>
                            <ckeditor [editor]="Editor" formControlName="description"></ckeditor>
                            <mat-error *ngIf="submitted && form.controls['description'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                        </div>
                    </div>
                    <div class="row mb-10" *ngIf="dynamiccontents && quotation_type_status">
                        <div class="col-12">
                            <fieldset class="cardback fieldset">
                                <legend><b>{{'lang_technical_terms_conditions' | language}}</b></legend>
                                <div class="contentpanel" [ngClass]="{' contentrtl': lang_key == 'ar' }" #contentData></div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row mb-10" *ngIf="dynamiccontents1 && quotation_type_status">
                        <div class="col-12">
                            <fieldset class="cardback fieldset">
                                <legend><b>{{'lang_general_terms_conditions' | language}}</b></legend>
                                <div class="contentpanel" [ngClass]="{' contentrtl': lang_key == 'ar' }" #contentData1></div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="row mb-10" *ngIf="dynamicrestrictions && quotation_type_status">
                        <div class="col-12">
                            <fieldset class="cardback fieldset">
                                <legend><b>{{'lang_options' | language}}</b></legend>
                                <section class="example-section">
                                    <mat-checkbox *ngFor="let d of restrictions_fixed_optional_items" class="inlinecheckbox" [checked]="d.checked" (change)="d.checked = !d.checked">{{ d.label }}</mat-checkbox>
                                </section>
                            </fieldset>  
                        </div>
                    </div> -->

					<mat-horizontal-stepper #stepper>
						<mat-step [stepControl]="form.controls.stepOne">
							<form formGroupName="stepOne">
								<div class="container p-0 m-0" style="max-width: 99%">
									<div class="row">
										<div class="col-12 col-sm-3">
											<h3 style="font-size: 20px">
												{{ "lang_customers" | language }}
											</h3>
											<p
												style="
													font-size: 14px;
													font-weight: 600;
													color: #0e3768;
												"
											>
												{{ project_data?.customer_name }}
											</p>
										</div>
										<div class="d-none d-sm-block col-sm-6"></div>
										<div
											class="col-12 col-sm-3"
											[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
										>
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{ "lang_languages" | language }}</mat-label>
												<mat-select formControlName="lang_key">
													<mat-option
														*ngFor="let language of languages"
														[value]="language.value"
													>
														{{ language.label }}
													</mat-option>
												</mat-select>
												<mat-error
													*ngIf="
														form.controls['stepOne'].get('lang_key').touched &&
														form.controls['stepOne'].get('lang_key').errors
															?.required
													"
												>
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div
											class="col-12 col-sm-6"
											[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
										>
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{ "lang_subject" | language }}</mat-label>
												<input matInput formControlName="subject" required />
												<mat-error
													*ngIf="
														form.controls['stepOne'].get('subject').touched &&
														form.controls['stepOne'].get('subject').errors
															?.required
													"
												>
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div
											class="col-12 col-sm-6"
											[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
										>
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{ "lang_care" | language }}</mat-label>
												<input matInput formControlName="care" required />
												<mat-error
													*ngIf="
														form.controls['stepOne'].get('care').touched &&
														form.controls['stepOne'].get('care').errors
															?.required
													"
												>
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>

										<div
											class="col-12 col-sm-6"
											[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
										>
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{ "lang_city" | language }}</mat-label>
												<input matInput formControlName="city" required />
												<mat-error
													*ngIf="
														form.controls['stepOne'].get('city').touched &&
														form.controls['stepOne'].get('city').errors
															?.required
													"
												>
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div
											class="col-12 col-sm-6"
											[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
										>
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{
													"lang_total_area_m2" | language
												}}</mat-label>
												<input
													matInput
													type="number"
													formControlName="total_area_m2"
													required
												/>
												<mat-error
													*ngIf="
														form.controls['stepOne'].get('total_area_m2')
															.touched &&
														form.controls['stepOne'].get('total_area_m2').errors
															?.required
													"
												>
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div
											class="col-12 col-sm-6"
											[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
										>
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{
													"lang_duration_days" | language
												}}</mat-label>
												<input
													matInput
													type="number"
													formControlName="duration_days"
													required
												/>
												<mat-error
													*ngIf="
														form.controls['stepOne'].get('duration_days')
															.touched &&
														form.controls['stepOne'].get('duration_days').errors
															?.required
													"
												>
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div
											class="col-12 col-sm-6"
											[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
										>
											<mat-form-field style="width: 100%" appearance="outline">
												<mat-label>{{
													"lang_main_occupancies" | translate
												}}</mat-label>
												<mat-select multiple formControlName="main_occupancies">
													<mat-option
														*ngFor="let ut of occupancies_list"
														[value]="ut.value"
													>
														{{ ut.label }}
													</mat-option>
												</mat-select>
												<mat-error
													*ngIf="
														form.controls['stepOne'].get('main_occupancies')
															.touched &&
														form.controls['stepOne'].get('main_occupancies')
															.errors?.required
													"
												>
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div
											class="col-12"
											[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
										>
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{
													"lang_description" | language
												}}</mat-label>
												<textarea
													matInput
													formControlName="description"
													required
													rows="5"
												></textarea>
												<mat-error
													*ngIf="
														form.controls['stepOne'].get('description')
															.touched &&
														form.controls['stepOne'].get('description').errors
															?.required
													"
												>
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>
										<div
											class="col-12 col-sm-6"
											[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
										>
											<mat-form-field appearance="outline" style="width: 100%">
												<mat-label>{{ "lang_vat" | language }}</mat-label>
												<mat-select formControlName="vat_key">
													<mat-option
														*ngFor="let vat of vat_list"
														[value]="vat.value"
													>
														{{ vat.label }}
													</mat-option>
												</mat-select>
												<mat-error
													*ngIf="
														form.controls['stepOne'].get('vat_key').touched &&
														form.controls['stepOne'].get('vat_key').errors
															?.required
													"
												>
													{{ "lang_field_required" | language }}
												</mat-error>
											</mat-form-field>
										</div>

										<div
											class="col-12 col-sm-6 d-flex justify-content-between mt-1"
										>
											<mat-label style="margin: 15px 5px; font-size: 17px"
												>{{ "lang_quotation_type" | language }}
											</mat-label>
											<mat-radio-group
												aria-labelledby="example-radio-group-label"
												formControlName="quotation_type"
											>
												<mat-radio-button
													*ngFor="let quotation_type of quotation_type_list"
													(change)="changeQout($event)"
													[value]="quotation_type.value"
												>
													{{ quotation_type.label }}
												</mat-radio-button>
											</mat-radio-group>
										</div>
									</div>
									<div class="row pt-3">
										<div class="d-none d-sm-block col-sm-9"></div>
										<div class="col-12 col-sm-3 text-end">
											<button
												class="albutton mnabrbutton mat-accent customBTN"
												mat-button
												(click)="MATNext(stepper, 'first')"
											>
												{{ "lang_continue" | language }}
												<i
													class="fa fa-arrow-right"
													style="font-size: 14px"
													*ngIf="lang_key == 'en'"
												></i>
												<i
													class="fa fa-arrow-left"
													style="font-size: 14px"
													*ngIf="lang_key == 'ar'"
												></i>
											</button>
										</div>
									</div>
								</div>
							</form>
						</mat-step>
						<mat-step [stepControl]="form.controls.stepOne">
							<form formGroupName="stepTwo">
								<div class="container p-0 m-0" style="max-width: 99%">
									<!--     <div class="row">
                                         <div
                                        class="col-12 col-sm-6"
                                        [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
                                    >
                                        <mat-form-field appearance="outline" style="width: 100%;">
                                            <mat-label>{{ "lang_project_name" | language }}</mat-label>
                                            <input matInput formControlName="project_name" required />
                                            <mat-error
                                                *ngIf="
                                                    form.controls['stepTwo'].get('project_name').touched &&
                                                    form.controls['stepTwo'].get('project_name').errors
                                                        ?.required
                                                "
                                            >
                                                {{ "lang_field_required" | language }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div> 
                                       
                                      
                                    </div>-->

									<ng-container
										*ngIf="
											form.controls['stepOne'].get('quotation_type').value ==
											'standard'
										"
									>
										<div class="row pt-2">
											<div class="col-12">
												<h4>
													{{ "lang_technical_terms_conditions" | language }}
												</h4>
											</div>
											<div
												class="col-12 col-sm-10"
												[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
											>
												<mat-form-field
													appearance="outline"
													style="width: 100%"
												>
													<mat-label>{{
														"lang_technical_terms_conditions" | language
													}}</mat-label>
													<input
														matInput
														type="text"
														formControlName="add_terms"
														required
													/>
													<mat-error
														*ngIf="
															form.controls['stepTwo'].get('add_terms')
																.touched &&
															form.controls['stepTwo'].get('add_terms').errors
																?.required
														"
													>
														{{ "lang_field_required" | language }}
													</mat-error>
												</mat-form-field>
											</div>

											<div
												class="col-12 col-sm-2"
												[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
											>
												<button
													(click)="addTerms()"
													mat-button
													class="previous-btn addBTN"
												>
													<i
														class="fa fa-arrow-right"
														style="font-size: 14px"
														*ngIf="lang_key == 'ar'"
													></i>

													{{ "lang_add" | language }}
												</button>
											</div>

											<div
												class="col-md-12"
												*ngIf="
													form.controls.stepTwo.value.technical_term_Arr
														.length > 0
												"
											>
												<table class="table table-bordered">
													<thead>
														<tr>
															<th scope="col" class="oneCol">ID#</th>
															<th scope="col" class="twoCol">
																{{ "lang_terms_conditions" | language }}
															</th>
															<th scope="col" class="twoCol">
																{{ "lang_action" | language }}
															</th>
														</tr>
													</thead>
													<tbody>
														<tr
															*ngFor="
																let term of form.controls.stepTwo.value
																	.technical_term_Arr;
																let i = index
															"
														>
															<th scope="row" class="oneCol">{{ i + 1 }}</th>
															<td class="twoCol">{{ term.terms }}</td>
															<td class="oneCol">
																<i
																	class="fa fa-trash"
																	(click)="deleteTerm(i)"
																	style="
																		font-size: 18px;
																		color: red;
																		cursor: pointer;
																	"
																	*ngIf="lang_key == 'en'"
																></i>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>

										<div class="row pt-2">
											<div class="col-12">
												<h4>
													{{ "lang_general_terms_conditions" | language }}
												</h4>
											</div>
											<div
												class="col-12 col-sm-10"
												[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
											>
												<mat-form-field
													appearance="outline"
													style="width: 100%"
												>
													<mat-label>{{
														"lang_general_terms_conditions" | language
													}}</mat-label>
													<input
														matInput
														type="text"
														formControlName="add_general_terms"
														required
													/>
													<mat-error
														*ngIf="
															form.controls['stepTwo'].get('add_general_terms')
																.touched &&
															form.controls['stepTwo'].get('add_general_terms')
																.errors?.required
														"
													>
														{{ "lang_field_required" | language }}
													</mat-error>
												</mat-form-field>
											</div>

											<div
												class="col-12 col-sm-2"
												[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
											>
												<button
													(click)="addGeneralTerms()"
													mat-button
													class="previous-btn addBTN"
												>
													<i
														class="fa fa-arrow-right"
														style="font-size: 14px"
														*ngIf="lang_key == 'ar'"
													></i>

													{{ "lang_add" | language }}
												</button>
											</div>

											<div
												class="col-md-12"
												*ngIf="
													form.controls.stepTwo.value
														.technical_general_terms_Arr.length > 0
												"
											>
												<table class="table table-bordered">
													<thead>
														<tr>
															<th scope="col" class="oneCol">ID#</th>
															<th scope="col" class="twoCol">
																{{ "lang_general_terms_conditions" | language }}
															</th>
															<th scope="col" class="twoCol">
																{{ "lang_action" | language }}
															</th>
														</tr>
													</thead>
													<tbody>
														<tr
															*ngFor="
																let term of form.controls.stepTwo.value
																	.technical_general_terms_Arr;
																let i = index
															"
														>
															<th scope="row" class="oneCol">{{ i + 1 }}</th>
															<td class="twoCol">{{ term.terms }}</td>
															<td class="oneCol">
																<i
																	class="fa fa-trash"
																	(click)="deleteGeneralTerm(i)"
																	style="
																		font-size: 18px;
																		color: red;
																		cursor: pointer;
																	"
																	*ngIf="lang_key == 'en'"
																></i>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>

										<div class="row pt-2">
											<div
												class="col-12 col-sm-6"
												[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
											>
												<mat-form-field
													appearance="outline"
													style="width: 100%"
												>
													<mat-label>{{
														"lang_financial" | language
													}}</mat-label>
													<input
														matInput
														type="text"
														formControlName="financial"
														required
													/>
													<mat-error
														*ngIf="
															form.controls['stepTwo'].get('financial')
																.touched &&
															form.controls['stepTwo'].get('financial').errors
																?.required
														"
													>
														{{ "lang_field_required" | language }}
													</mat-error>
												</mat-form-field>
											</div>
										</div>
									</ng-container>

									<div
										[ngClass]="{
											notShowen:
												form.controls['stepOne'].get('quotation_type').value ==
												'standard'
										}"
										id="stepper1"
										class="bs-stepper"
									>
										<div class="bs-stepper-header mx-5">
											<div class="step" data-target="#test-l-1">
												<div
													class="d-block"
													matTooltip="{{
														'lang_project_plan_requirements' | language
													}}"
												>
													<button class="step-trigger" (click)="setActive(1)">
														<span
															class="bs-stepper-circle"
															[ngClass]="{
																activeNow: currentActiveStep == 1,
																FormValid:
																	getSmallSteps.project_plan_requirements
																		.status == 'VALID' && currentActiveStep != 1
															}"
															>1</span
														>
														<div class="custom">
															<!--  <span class="bs-stepper-label text-center label"
                                                  >  project component </span
                                                > -->
														</div>
													</button>
													<!--   <span class="bs-stepper-label text-center">Email Email Email</span> -->
												</div>
											</div>
											<div
												class="line step-1"
												[ngClass]="{
													activeNow: currentActiveStep == 1,
													FormValid:
														getSmallSteps.project_plan_requirements.status ==
															'VALID' && currentActiveStep != 1
												}"
											></div>
											<div class="step" data-target="#test-l-2">
												<div
													class="d-block"
													matTooltip="{{ 'lang_soil_sensors' | language }}"
												>
													<button
														class="step-trigger"
														(click)="setActive(2)"
														[disabled]="
															getSmallSteps.project_plan_requirements.status ==
															'INVALID'
														"
													>
														<span
															class="bs-stepper-circle"
															[ngClass]="{
																activeNow: currentActiveStep == 2,
																FormValid:
																	getSmallSteps.project_component_soil_sensor
																		.status == 'VALID' && currentActiveStep != 2
															}"
															>2</span
														>
														<div class="custom">
															<!-- <span class="bs-stepper-label text-center label"
                                                    >soil sensors </span
                                                  > -->
														</div>
													</button>
													<!--   <span class="bs-stepper-label text-center">Email Email Email</span> -->
												</div>
											</div>
											<div
												class="line step-2"
												[ngClass]="{
													FormValid:
														getSmallSteps.project_component_soil_sensor
															.status == 'VALID' && currentActiveStep != 2,
													activeNow: currentActiveStep == 2
												}"
											></div>
											<div class="step" data-target="#test-l-3">
												<div
													class="d-block"
													matTooltip="{{
														'lang_plans_quantity_per_level_for_all_building'
															| language
													}}"
												>
													<button
														class="step-trigger"
														(click)="setActive(3)"
														[disabled]="
															getSmallSteps.project_component_soil_sensor
																.status == 'INVALID'
														"
													>
														<span
															class="bs-stepper-circle"
															[ngClass]="{
																activeNow: currentActiveStep == 3,
																FormValid:
																	getSmallSteps.project_analysis_data.status ==
																		'VALID' && currentActiveStep != 3
															}"
															>3</span
														>
														<div class="custom">
															<!-- <span class="bs-stepper-label text-center label"
                                                  style="font-size: 12px;"
                                                    > plans quantity per level for all building</span
                                                  > -->
														</div>
													</button>
													<!--   <span class="bs-stepper-label text-center">Email Email Email</span> -->
												</div>
											</div>
											<div
												class="line step-3"
												[ngClass]="{
													activeNow: currentActiveStep == 3,
													FormValid:
														getSmallSteps.project_analysis_data.status ==
															'VALID' && currentActiveStep != 3
												}"
											></div>
											<div class="step" data-target="#test-l-4">
												<div
													class="d-block"
													matTooltip="{{ 'lang_surveying_costs' | language }}"
												>
													<button
														class="step-trigger"
														(click)="setActive(4)"
														[disabled]="
															getSmallSteps.project_analysis_data.status ==
															'INVALID'
														"
													>
														<span
															class="bs-stepper-circle"
															[ngClass]="{
																activeNow: currentActiveStep == 4,
																FormValid:
																	getSmallSteps.surveying_costs.status ==
																		'VALID' && currentActiveStep != 4
															}"
															>4</span
														>
														<div class="custom">
															<!-- <span class="bs-stepper-label text-center label"
                                                    >surveying costs</span
                                                  > -->
														</div>
													</button>
													<!--   <span class="bs-stepper-label text-center">Email Email Email</span> -->
												</div>
											</div>
											<div
												class="line step-4"
												[ngClass]="{
													activeNow: currentActiveStep == 4,
													FormValid:
														getSmallSteps.surveying_costs.status == 'VALID' &&
														currentActiveStep != 4
												}"
											></div>
											<div class="step" data-target="#test-l-5">
												<div
													class="d-block"
													matTooltip="{{ 'lang_printing_costs' | language }}"
												>
													<button
														class="step-trigger"
														(click)="setActive(5)"
														[disabled]="
															getSmallSteps.surveying_costs.status == 'INVALID'
														"
													>
														<span
															class="bs-stepper-circle"
															[ngClass]="{
																activeNow: currentActiveStep == 5,
																FormValid:
																	getSmallSteps.printing_costs.status ==
																		'VALID' && currentActiveStep != 5
															}"
															>5</span
														>
														<div class="custom">
															<!-- <span class="bs-stepper-label text-center label"
                                                    >printing costs</span
                                                  > -->
														</div>
													</button>
													<!--   <span class="bs-stepper-label text-center">Email Email Email</span> -->
												</div>
											</div>
											<div
												class="line step-5"
												[ngClass]="{
													activeNow: currentActiveStep == 5,
													FormValid:
														getSmallSteps.printing_costs.status == 'VALID' &&
														currentActiveStep != 5
												}"
											></div>
											<div class="step" data-target="#test-l-6">
												<div
													class="d-block"
													matTooltip="{{ 'lang_engineering_costs' | language }}"
												>
													<button
														class="step-trigger"
														(click)="setActive(6)"
														[disabled]="
															getSmallSteps.printing_costs.status == 'INVALID'
														"
													>
														<span
															class="bs-stepper-circle"
															[ngClass]="{
																activeNow: currentActiveStep == 6,
																FormValid:
																	getSmallSteps.engineering_costs.status ==
																		'VALID' && currentActiveStep != 6
															}"
															>6</span
														>
														<div class="custom">
															<!-- <span class="bs-stepper-label text-center label"
                                                    >engineering costs </span
                                                  > -->
														</div>
													</button>
													<!--   <span class="bs-stepper-label text-center">Email Email Email</span> -->
												</div>
											</div>
											<div
												class="line step-6"
												[ngClass]="{
													activeNow: currentActiveStep == 6,
													FormValid:
														getSmallSteps.engineering_costs.status == 'VALID' &&
														currentActiveStep != 6
												}"
											></div>
											<div class="step" data-target="#test-l-7">
												<div
													class="d-block"
													matTooltip="{{ 'lang_government_costs' | language }}"
												>
													<button
														class="step-trigger"
														(click)="setActive(7)"
														[disabled]="
															getSmallSteps.engineering_costs.status ==
															'INVALID'
														"
													>
														<span
															class="bs-stepper-circle"
															[ngClass]="{
																activeNow: currentActiveStep == 7,
																FormValid:
																	getSmallSteps.government_services.status ==
																		'VALID' && currentActiveStep != 7
															}"
															>7</span
														>
														<div class="custom">
															<!--  <span class="bs-stepper-label text-center label"
                                                  >government costs</span
                                                > -->
														</div>
													</button>
													<!--   <span class="bs-stepper-label text-center">Email Email Email</span> -->
												</div>
											</div>
											<div
												class="line step-7"
												[ngClass]="{
													activeNow: currentActiveStep == 7,
													FormValid:
														getSmallSteps.government_services.status ==
															'VALID' && currentActiveStep != 7
												}"
											></div>
											<div class="step" data-target="#test-l-8">
												<div
													class="d-block"
													matTooltip="{{ 'lang_other_costs' | language }}"
												>
													<button
														class="step-trigger"
														(click)="setActive(8)"
														[disabled]="
															getSmallSteps.government_services.status ==
															'INVALID'
														"
													>
														<span
															class="bs-stepper-circle"
															[ngClass]="{
																activeNow: currentActiveStep == 8,
																FormValid:
																	getSmallSteps.other_costs.status == 'VALID' &&
																	currentActiveStep != 8
															}"
															>8</span
														>
														<div class="custom">
															<!-- <span class="bs-stepper-label text-center label"
                                                  > other costs </span
                                                > -->
														</div>
													</button>
													<!--   <span class="bs-stepper-label text-center">Email Email Email</span> -->
												</div>
											</div>
											<div
												class="line step-8"
												[ngClass]="{
													activeNow: currentActiveStep == 8,
													FormValid:
														getSmallSteps.government_services.status ==
															'VALID' && currentActiveStep != 8
												}"
											></div>

											<div class="step" data-target="#test-l-9">
												<div
													class="d-block"
													matTooltip="{{ 'lang_other_costs' | language }}"
												>
													<button
														class="step-trigger"
														(click)="setActive(9)"
														[disabled]="
															getSmallSteps.government_services.status ==
															'INVALID'
														"
													>
														<span
															class="bs-stepper-circle"
															[ngClass]="{
																activeNow: currentActiveStep == 9,
																FormValid:
																	getSmallSteps.other_costs.status == 'VALID' &&
																	currentActiveStep != 9
															}"
															>9</span
														>
														<div class="custom">
															<!-- <span class="bs-stepper-label text-center label"
                                                  > other costs </span
                                                > -->
														</div>
													</button>
													<!--   <span class="bs-stepper-label text-center">Email Email Email</span> -->
												</div>
											</div>
											<div
												class="line step-9"
												[ngClass]="{
													activeNow: currentActiveStep == 9,
													FormValid:
														getSmallSteps.government_services.status ==
															'VALID' && currentActiveStep != 9
												}"
											></div>

											<div class="step" data-target="#test-l-10">
												<div
													class="d-block"
													matTooltip="{{ 'lang_other_costs' | language }}"
												>
													<button
														class="step-trigger"
														(click)="setActive(10)"
														[disabled]="
															getSmallSteps.government_services.status ==
															'INVALID'
														"
													>
														<span
															class="bs-stepper-circle"
															[ngClass]="{
																activeNow: currentActiveStep == 10,
																FormValid:
																	getSmallSteps.other_costs.status == 'VALID' &&
																	currentActiveStep != 10
															}"
															>10</span
														>
														<div class="custom">
															<!-- <span class="bs-stepper-label text-center label"
                                                  > other costs </span
                                                > -->
														</div>
													</button>
													<!--   <span class="bs-stepper-label text-center">Email Email Email</span> -->
												</div>
											</div>
											<div
												class="line step-10"
												[ngClass]="{
													activeNow: currentActiveStep == 10,
													FormValid:
														getSmallSteps.government_services.status ==
															'VALID' && currentActiveStep != 10
												}"
											></div>

											<div class="step" data-target="#test-l-11">
												<div
													class="d-block"
													matTooltip="{{ 'lang_other_costs' | language }}"
												>
													<button
														class="step-trigger"
														(click)="setActive(11)"
														[disabled]="
															getSmallSteps.government_services.status ==
															'INVALID'
														"
													>
														<span
															class="bs-stepper-circle"
															[ngClass]="{
																activeNow: currentActiveStep == 11,
																FormValid:
																	getSmallSteps.other_costs.status == 'VALID' &&
																	currentActiveStep != 11
															}"
															>11</span
														>
														<div class="custom">
															<!-- <span class="bs-stepper-label text-center label"
                                                  > other costs </span
                                                > -->
														</div>
													</button>
													<!--   <span class="bs-stepper-label text-center">Email Email Email</span> -->
												</div>
											</div>
											<div
												class="line step-11"
												[ngClass]="{
													activeNow: currentActiveStep == 11,
													FormValid:
														getSmallSteps.government_services.status ==
															'VALID' && currentActiveStep != 11
												}"
											></div>

											<div class="step" data-target="#test-l-12">
												<div
													class="d-block"
													matTooltip="{{ 'lang_other_costs' | language }}"
												>
													<button
														class="step-trigger"
														(click)="setActive(12)"
														[disabled]="
															getSmallSteps.government_services.status ==
															'INVALID'
														"
													>
														<span
															class="bs-stepper-circle"
															[ngClass]="{
																activeNow: currentActiveStep == 12,
																FormValid:
																	getSmallSteps.other_costs.status == 'VALID' &&
																	currentActiveStep != 12
															}"
															>12</span
														>
														<div class="custom">
															<!-- <span class="bs-stepper-label text-center label"
                                                  > other costs </span
                                                > -->
														</div>
													</button>
													<!--   <span class="bs-stepper-label text-center">Email Email Email</span> -->
												</div>
											</div>
										</div>

										<div class="row width-100 text-start mx-4 mt-3 fontOneRem">
											<div class="col-md-9 p-0">
												<h4 *ngIf="currentActiveStep == 1">
													{{ "lang_project_plan_requirements" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 2">
													{{ "lang_soil_sensors" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 3">
													{{
														"lang_plans_quantity_per_level_for_all_building"
															| language
													}}
												</h4>
												<h4 *ngIf="currentActiveStep == 4">
													{{ "lang_surveying_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 5">
													{{ "lang_printing_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 6">
													{{ "lang_engineering_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 7">
													{{ "lang_government_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 8">
													{{ "lang_other_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 9">
													{{ "lang_other_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 10">
													{{ "lang_other_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 11">
													{{ "lang_other_costs" | language }}
												</h4>
												<h4 *ngIf="currentActiveStep == 12">
													{{ "lang_other_costs" | language }}
												</h4>
											</div>

											<div class="col-md-3 p-0">
												<p class="step_num">
													Step {{ currentActiveStep }} of 12
												</p>
											</div>
										</div>
										<div
											class="width-100 text-center mx-4 d-flex justify-content-between"
										>
											<button
												(click)="back()"
												type="button"
												class="albutton mnabrbutton mat-accent mobileBTN"
												mat-button
											>
												{{ "lang_back" | language }}
											</button>

											<button
												(click)="next()"
												type="button"
												class="albutton mnabrbutton mat-accent mobileBTN"
												mat-button
											>
												{{ "lang_next" | language }}
											</button>
										</div>
										<div class="bs-stepper-content mt-1">
											<div id="test-l-1" class="content">
												<form [formGroup]="getstandard_steps">
													<div class="form-group">
														<a
															(click)="addPlan()"
															class="btn customButton mb-4"
														>
															{{ "lang_add_another_plan" | language }}
														</a>
														<div
															formArrayName="project_plan_requirements"
															class="mb-3"
															*ngFor="
																let creds of getSmallSteps
																	.project_plan_requirements?.value;
																let i = index;
																trackBy: trackByFnCustom
															"
														>
															<div
																[formGroupName]="i"
																[ngClass]="{ borderTop: i != 0 }"
															>
																<div class="row">
																	<div class="form-group col-md-6 mb-1">
																		<h5 class="head">
																			{{ "lang_plan_no" | language }}.
																			{{ i + 1 }}
																		</h5>
																	</div>
																	<div
																		class="form-group col-md-6 mb-1"
																		style="text-align: right"
																	>
																		<a
																			class="danger"
																			*ngIf="
																				i <
																				getSmallSteps.project_plan_requirements
																					?.value.length -
																					plans.length
																			"
																			(click)="deletePaln(i)"
																			matTooltip="{{
																				'lang_delete_plan' | language
																			}}"
																		>
																			<i
																				class="fa fa-trash deleteICO font-medium-3 m-2"
																			></i>
																		</a>
																	</div>
																	<div
																		class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																	>
																		<mat-form-field
																			style="width: 100%"
																			appearance="outline"
																		>
																			<mat-label>{{
																				"lang_plan_title_english" | language
																			}}</mat-label>

																			<input
																				matInput
																				type="text"
																				(keyup)="makekeyword($event, i)"
																				formControlName="plan_title_english"
																				required
																			/>
																			<mat-error
																				*ngIf="
																					getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_title_english.touched &&
																					getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_title_english.errors
																						?.required
																				"
																			>
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>

																	<div
																		class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																	>
																		<mat-form-field
																			style="width: 100%"
																			appearance="outline"
																		>
																			<mat-label>{{
																				"lang_plan_title_arabic" | language
																			}}</mat-label>

																			<input
																				matInput
																				type="text"
																				formControlName="plan_title_arabic"
																				required
																			/>
																			<mat-error
																				*ngIf="
																					getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_title_arabic.touched &&
																					getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_title_arabic.errors?.required
																				"
																			>
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>

																	<div
																		class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																	>
																		<mat-form-field
																			style="width: 100%"
																			appearance="outline"
																		>
																			<mat-label>{{
																				"lang_plan_technical_ids" | language
																			}}</mat-label>
																			<mat-select
																				multiple
																				formControlName="plan_technical_ids"
																			>
																				<mat-option
																					*ngFor="let ut of planreq"
																					[value]="ut.value"
																				>
																					{{ ut.label }}
																				</mat-option>
																			</mat-select>
																			<mat-error
																				*ngIf="
																					getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_technical_ids.touched &&
																					getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_technical_ids.errors
																						?.required
																				"
																			>
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>

																	<div
																		class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																	>
																		<mat-form-field
																			style="width: 100%"
																			appearance="outline"
																		>
																			<mat-label>{{
																				"lang_plan_hour_value" | language
																			}}</mat-label>

																			<input
																				matInput
																				(keypress)="isNumber($event)"
																				type="number"
																				formControlName="plan_hour_value"
																				required
																			/>

																			<mat-error
																				*ngIf="
																					getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_hour_value.touched &&
																					getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_hour_value.errors?.required
																				"
																			>
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>

																	<div
																		class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																	>
																		<mat-form-field
																			style="width: 100%"
																			appearance="outline"
																		>
																			<mat-label>{{
																				"lang_plan_total_hours" | language
																			}}</mat-label>

																			<input
																				matInput
																				(keypress)="isNumber($event)"
																				type="number"
																				formControlName="plan_total_hours"
																				required
																			/>
																			<mat-error
																				*ngIf="
																					getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_total_hours.touched &&
																					getstandard_steps.controls[
																						'project_plan_requirements'
																					]['controls'][i]['controls']
																						?.plan_total_hours.errors?.required
																				"
																			>
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>
																</div>
																<hr width="70%" class="m-auto" />
															</div>
														</div>
													</div>
												</form>

												<!--  <button (click)="next()" class="btn btn-primary">Next</button> -->
											</div>
											<div id="test-l-2" class="content">
												<form
													[formGroup]="
														getSmallSteps.project_component_soil_sensor
													"
												>
													<div class="col-12 col-sm-6 mt-1 mb-3">
														<div class="d-flex justify-content-between">
															<mat-label
																style="margin: 15px 5px; font-size: 17px"
																>{{
																	"lang_project_component_soil_sensor_status"
																		| language
																}}
															</mat-label>
															<mat-radio-group
																aria-labelledby="example-radio-group-label"
																formControlName="project_component_soil_sensor_status"
															>
																<mat-radio-button
																	*ngFor="let soil_sensor of soil_sensor_status"
																	(change)="changeSoilSensor($event)"
																	[value]="soil_sensor.value"
																>
																	{{ soil_sensor.label }}
																</mat-radio-button>
															</mat-radio-group>
														</div>

														<mat-error
															*ngIf="
																getSmallSteps.project_component_soil_sensor.get(
																	'project_component_soil_sensor_status'
																).touched &&
																getSmallSteps.project_component_soil_sensor.get(
																	'project_component_soil_sensor_status'
																).errors?.required
															"
														>
															{{ "lang_field_required" | language }}
														</mat-error>
													</div>

													<div
														class="col-12"
														*ngIf="
															getSmallSteps.project_component_soil_sensor.value
																?.project_component_soil_sensor_status == 0
														"
														[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
													>
														<mat-form-field
															appearance="outline"
															style="width: 100%"
														>
															<mat-label>{{
																"lang_soil_sensor_legal_notes" | language
															}}</mat-label>
															<textarea
																matInput
																formControlName="project_component_soil_sensor_legal_notes"
																required
																rows="5"
															></textarea>
															<mat-error
																*ngIf="
																	getSmallSteps.project_component_soil_sensor.get(
																		'project_component_soil_sensor_legal_notes'
																	).touched &&
																	getSmallSteps.project_component_soil_sensor.get(
																		'project_component_soil_sensor_legal_notes'
																	).errors?.required
																"
															>
																{{ "lang_field_required" | language }}
															</mat-error>
														</mat-form-field>
													</div>
												</form>
											</div>
											<div id="test-l-3" class="content">
												<form [formGroup]="getstandard_steps">
													<div class="form-group">
														<a
															(click)="addProjectAnalysis()"
															class="btn customButton mb-4"
														>
															{{ "lang_add_project_analysis" | language }}
														</a>

														<mat-tab-group
															formArrayName="project_analysis_data"
															[selectedIndex]="selected.value"
															(selectedIndexChange)="selected.setValue($event)"
														>
															<mat-tab
																*ngFor="
																	let creds of getSmallSteps
																		.project_analysis_data?.value;
																	let i = index;
																	trackBy: trackByFnCustom
																"
																label="{{
																	'lang_project_analysis_no' | language
																}}. {{ i + 1 }}"
															>
																<div class="my-3">
																	<div
																		[formGroupName]="i"
																		[ngClass]="{ borderTop: i != 0 }"
																	>
																		<div class="row">
																			<div class="form-group col-md-6 mb-1">
																				<h5 class="head">
																					{{
																						"lang_project_analysis_no"
																							| language
																					}}. {{ i + 1 }}
																				</h5>
																			</div>
																			<div
																				class="form-group col-md-6 mb-1"
																				style="text-align: right"
																			>
																				<a
																					class="danger"
																					(click)="deleteProjectAnalysis(i)"
																					matTooltip="{{
																						'lang_project_analysis' | language
																					}}"
																				>
																					<i
																						class="fa fa-trash deleteICO font-medium-3 m-2"
																					></i>
																				</a>
																			</div>
																			<div
																				class="col-12 col-sm-3"
																				[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																			>
																				<mat-form-field
																					style="width: 100%"
																					appearance="outline"
																				>
																					<mat-label>{{
																						"lang_subject_english" | language
																					}}</mat-label>

																					<input
																						matInput
																						type="text"
																						formControlName="project_component_subject_english"
																						required
																					/>
																					<mat-error
																						*ngIf="
																							getstandard_steps.controls[
																								'project_analysis_data'
																							]['controls'][i]['controls']
																								?.project_component_subject_english
																								.touched &&
																							getstandard_steps.controls[
																								'project_analysis_data'
																							]['controls'][i]['controls']
																								?.project_component_subject_english
																								.errors?.required
																						"
																					>
																						{{
																							"lang_field_required" | language
																						}}
																					</mat-error>
																				</mat-form-field>
																			</div>

																			<div
																				class="col-12 col-sm-3"
																				[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																			>
																				<mat-form-field
																					style="width: 100%"
																					appearance="outline"
																				>
																					<mat-label>{{
																						"lang_subject_arabic" | language
																					}}</mat-label>

																					<input
																						matInput
																						type="text"
																						formControlName="project_component_subject_arabic"
																						required
																					/>
																					<mat-error
																						*ngIf="
																							getstandard_steps.controls[
																								'project_analysis_data'
																							]['controls'][i]['controls']
																								?.project_component_subject_arabic
																								.touched &&
																							getstandard_steps.controls[
																								'project_analysis_data'
																							]['controls'][i]['controls']
																								?.project_component_subject_arabic
																								.errors?.required
																						"
																					>
																						{{
																							"lang_field_required" | language
																						}}
																					</mat-error>
																				</mat-form-field>
																			</div>

																			<!--  <div class="col-12 col-sm-3" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
        
                                            <mat-form-field style="width: 100%;" appearance="outline">
                                              <mat-label>{{'lang_plan_technical_ids'|language}}</mat-label>
                                              <mat-select multiple formControlName="plan_technical_ids">
                                                <mat-option *ngFor="let ut of planreq" [value]="ut.value">
                                                  {{ut.label}}
                                                </mat-option>
                                              </mat-select>
                                              <mat-error *ngIf="
                                              getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.plan_technical_ids.touched &&
                                              getstandard_steps.controls['project_analysis_data']['controls'][i]['controls']?.plan_technical_ids.errors
                                                                      ?.required
                                                              ">
                                                {{ "lang_field_required" | language }}
                                              </mat-error>
                                            </mat-form-field>
                                          </div> -->

																			<div
																				class="col-12 col-sm-3"
																				[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																			>
																				<mat-form-field
																					style="width: 100%"
																					appearance="outline"
																				>
																					<mat-label>{{
																						"lang_space" | language
																					}}</mat-label>

																					<input
																						matInput
																						(keypress)="isNumber($event)"
																						type="number"
																						formControlName="project_component_space"
																						required
																					/>

																					<mat-error
																						*ngIf="
																							getstandard_steps.controls[
																								'project_analysis_data'
																							]['controls'][i]['controls']
																								?.project_component_space
																								.touched &&
																							getstandard_steps.controls[
																								'project_analysis_data'
																							]['controls'][i]['controls']
																								?.project_component_space.errors
																								?.required
																						"
																					>
																						{{
																							"lang_field_required" | language
																						}}
																					</mat-error>
																				</mat-form-field>
																			</div>

																			<div
																				class="col-12 col-sm-3"
																				[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																			>
																				<mat-form-field
																					style="width: 100%"
																					appearance="outline"
																				>
																					<mat-label>{{
																						"lang_building_quantity" | language
																					}}</mat-label>

																					<input
																						matInput
																						(keypress)="isNumber($event)"
																						type="number"
																						formControlName="project_component_building_quantity"
																						required
																					/>

																					<mat-error
																						*ngIf="
																							getstandard_steps.controls[
																								'project_analysis_data'
																							]['controls'][i]['controls']
																								?.project_component_building_quantity
																								.touched &&
																							getstandard_steps.controls[
																								'project_analysis_data'
																							]['controls'][i]['controls']
																								?.project_component_building_quantity
																								.errors?.required
																						"
																					>
																						{{
																							"lang_field_required" | language
																						}}
																					</mat-error>
																				</mat-form-field>
																			</div>

																			<ng-container
																				*ngIf="validationOnSoil == 1"
																			>
																				<div
																					class="col-12 col-sm-3"
																					[dir]="
																						lang_key == 'ar' ? 'rtl' : 'ltr'
																					"
																				>
																					<mat-form-field
																						style="width: 100%"
																						appearance="outline"
																					>
																						<mat-label>{{
																							"lang_project_soil_sensor_building_quantity"
																								| language
																						}}</mat-label>

																						<input
																							matInput
																							(keypress)="isNumber($event)"
																							type="number"
																							formControlName="project_component_soil_sensor_building_quantity"
																							required
																						/>
																						<mat-error
																							*ngIf="
																								getstandard_steps.controls[
																									'project_analysis_data'
																								]['controls'][i]['controls']
																									?.project_component_soil_sensor_building_quantity
																									.touched &&
																								getstandard_steps.controls[
																									'project_analysis_data'
																								]['controls'][i]['controls']
																									?.project_component_soil_sensor_building_quantity
																									.errors?.required
																							"
																						>
																							{{
																								"lang_field_required" | language
																							}}
																						</mat-error>
																					</mat-form-field>
																				</div>

																				<div
																					class="col-12 col-sm-3"
																					[dir]="
																						lang_key == 'ar' ? 'rtl' : 'ltr'
																					"
																				>
																					<mat-form-field
																						style="width: 100%"
																						appearance="outline"
																					>
																						<mat-label>{{
																							"lang_project_soil_sensor_depth_level_quantity"
																								| language
																						}}</mat-label>

																						<input
																							matInput
																							(keypress)="isNumber($event)"
																							type="number"
																							formControlName="project_component_soil_sensor_depth_level_quantity"
																							required
																						/>
																						<mat-error
																							*ngIf="
																								getstandard_steps.controls[
																									'project_analysis_data'
																								]['controls'][i]['controls']
																									?.project_component_soil_sensor_depth_level_quantity
																									.touched &&
																								getstandard_steps.controls[
																									'project_analysis_data'
																								]['controls'][i]['controls']
																									?.project_component_soil_sensor_depth_level_quantity
																									.errors?.required
																							"
																						>
																							{{
																								"lang_field_required" | language
																							}}
																						</mat-error>
																					</mat-form-field>
																				</div>

																				<div
																					class="col-12 col-sm-3"
																					[dir]="
																						lang_key == 'ar' ? 'rtl' : 'ltr'
																					"
																				>
																					<mat-form-field
																						style="width: 100%"
																						appearance="outline"
																					>
																						<mat-label>{{
																							"lang_component_soil_sensor_cost_per_lmeter"
																								| language
																						}}</mat-label>

																						<input
																							matInput
																							(keypress)="isNumber($event)"
																							type="number"
																							formControlName="project_component_level_quantity"
																							required
																						/>
																						<mat-error
																							*ngIf="
																								getstandard_steps.controls[
																									'project_analysis_data'
																								]['controls'][i]['controls']
																									?.project_component_soil_sensor_cost_per_lmeter
																									.touched &&
																								getstandard_steps.controls[
																									'project_analysis_data'
																								]['controls'][i]['controls']
																									?.project_component_soil_sensor_cost_per_lmeter
																									.errors?.required
																							"
																						>
																							{{
																								"lang_field_required" | language
																							}}
																						</mat-error>
																					</mat-form-field>
																				</div>
																			</ng-container>

																			<div
																				class="col-12 w-100 m-auto"
																				style="justify-content: space-between"
																			>
																				<div
																					class="col-md-12 my-3 mx-auto"
																					formArrayName="plan_quantities"
																					*ngFor="
																						let creds3 of getSmallSteps
																							.project_analysis_data?.value[i]
																							.plan_quantities;
																						let ii = index;
																						trackBy: trackByFnCustom
																					"
																				>
																					<div class="row" [formGroupName]="ii">
																						<div
																							class="col-6 col-sm-6 mb-3"
																							[dir]="
																								lang_key == 'ar' ? 'rtl' : 'ltr'
																							"
																						>
																							{{
																								"lang_plan_quantity_no"
																									| language
																							}}. {{ ii + 1 }}
																						</div>

																						<div
																							class="form-group col-md-6 mb-1"
																							style="text-align: right"
																						>
																							<a
																								class="danger"
																								(click)="
																									delete_plan_quantities(i, ii)
																								"
																								*ngIf="ii != 0"
																								matTooltip="{{
																									'lang_delete_plan_quantity'
																										| language
																								}}"
																							>
																								<i
																									class="fa fa-trash deleteICO font-medium-3 m-2"
																								></i>
																							</a>
																						</div>

																						<div
																							class="col-6 col-sm-6"
																							[dir]="
																								lang_key == 'ar' ? 'rtl' : 'ltr'
																							"
																						>
																							<mat-form-field
																								style="width: 100%"
																								appearance="outline"
																							>
																								<mat-label>{{
																									"lang_plan_name" | language
																								}}</mat-label>
																								<mat-select
																									formControlName="plan_keyword"
																								>
																									<mat-option
																										*ngFor="
																											let ut of getstandard_steps
																												.controls[
																												'project_plan_requirements'
																											]['controls']
																										"
																										[value]="
																											ut['controls']
																												?.plan_keyword.value
																										"
																									>
																										{{
																											lang_key == "en"
																												? ut["controls"]
																														?.plan_title_english
																														.value
																												: ut["controls"]
																														?.plan_title_arabic
																														.value
																										}}
																									</mat-option>
																								</mat-select>
																								<mat-error
																									*ngIf="
																										getstandard_steps.controls[
																											'project_analysis_data'
																										]['controls'][i]['controls']
																											?.plan_quantities[
																											'controls'
																										][ii]['controls']
																											?.plan_keyword.touched &&
																										getstandard_steps.controls[
																											'project_analysis_data'
																										]['controls'][i]['controls']
																											?.plan_quantities[
																											'controls'
																										][ii]['controls']
																											?.plan_keyword.errors
																											?.required
																									"
																								>
																									{{
																										"lang_field_required"
																											| language
																									}}
																								</mat-error>
																							</mat-form-field>
																						</div>

																						<div
																							class="col-6 col-sm-6"
																							[dir]="
																								lang_key == 'ar' ? 'rtl' : 'ltr'
																							"
																						>
																							<mat-form-field
																								style="width: 100%"
																								appearance="outline"
																							>
																								<mat-label>{{
																									"lang_plan_quantity_count"
																										| language
																								}}</mat-label>

																								<input
																									matInput
																									(keypress)="isNumber($event)"
																									type="number"
																									formControlName="plan_quantity_count"
																									required
																								/>

																								<mat-error
																									*ngIf="
																										getstandard_steps.controls[
																											'project_analysis_data'
																										]['controls'][i]['controls']
																											?.plan_quantities[
																											'controls'
																										][ii]['controls']
																											?.plan_quantity_count
																											.touched &&
																										getstandard_steps.controls[
																											'project_analysis_data'
																										]['controls'][i]['controls']
																											?.plan_quantities[
																											'controls'
																										][ii]['controls']
																											?.plan_quantity_count
																											.errors?.required
																									"
																								>
																									{{
																										"lang_field_required"
																											| language
																									}}
																								</mat-error>
																							</mat-form-field>
																						</div>
																					</div>
																				</div>
																				<a
																					(click)="add_plan_quantities(i)"
																					class="btn customButton mb-4 mx-3"
																				>
																					{{
																						"lang_add_plan_quantity" | language
																					}}
																				</a>
																			</div>

																			<div
																				class="col-12 col-sm-3"
																				[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																			>
																				<mat-form-field
																					style="width: 100%"
																					appearance="outline"
																				>
																					<mat-label>{{
																						"lang_level_quantity" | language
																					}}</mat-label>

																					<input
																						matInput
																						(keypress)="isNumber($event)"
																						(keyup)="changePlanReq($event, i)"
																						type="number"
																						formControlName="project_component_level_quantity"
																						required
																					/>
																					<mat-error
																						*ngIf="
																							getstandard_steps.controls[
																								'project_analysis_data'
																							]['controls'][i]['controls']
																								?.project_component_level_quantity
																								.touched &&
																							getstandard_steps.controls[
																								'project_analysis_data'
																							]['controls'][i]['controls']
																								?.project_component_level_quantity
																								.errors?.required
																						"
																					>
																						{{
																							"lang_field_required" | language
																						}}
																					</mat-error>
																				</mat-form-field>
																			</div>

																			<div
																				class="row w-100 m-auto"
																				style="justify-content: space-between"
																			>
																				<div
																					class="col-md-6 my-3 mx-auto"
																					[ngClass]="{
																						'col-md-12':
																							getSmallSteps
																								.project_analysis_data?.value[i]
																								.project_plan_requirements
																								.length == 1
																					}"
																					formArrayName="project_plan_requirements"
																					*ngFor="
																						let creds2 of getSmallSteps
																							.project_analysis_data?.value[i]
																							.project_plan_requirements;
																						let ii = index;
																						trackBy: trackByFnCustom
																					"
																				>
																					<div class="card">
																						<div
																							class="card-body"
																							[formGroupName]="ii"
																						>
																							<div class="row">
																								<div
																									class="col-6 col-sm-6 mb-3"
																									[dir]="
																										lang_key == 'ar'
																											? 'rtl'
																											: 'ltr'
																									"
																								>
																									{{
																										"lang_level_no" | language
																									}}. {{ ii + 1 }}
																								</div>

																								<div
																									class="form-group col-md-6 mb-1"
																									style="text-align: right"
																								>
																									<a
																										class="danger"
																										(click)="deleteLevel(i, ii)"
																										*ngIf="ii != 0"
																										matTooltip="{{
																											'lang_delete_level'
																												| language
																										}}"
																									>
																										<i
																											class="fa fa-trash deleteICO font-medium-3 m-2"
																										></i>
																									</a>
																								</div>
																								<div
																									class="col-12 col-sm-12"
																									[dir]="
																										lang_key == 'ar'
																											? 'rtl'
																											: 'ltr'
																									"
																								>
																									<mat-form-field
																										style="width: 100%"
																										appearance="outline"
																									>
																										<mat-label>{{
																											"lang_levels_total"
																												| language
																										}}</mat-label>

																										<input
																											matInput
																											(keypress)="
																												isNumber($event)
																											"
																											type="number"
																											formControlName="levels_total"
																											required
																										/>

																										<mat-error
																											*ngIf="
																												getstandard_steps
																													.controls[
																													'project_analysis_data'
																												]['controls'][i][
																													'controls'
																												]
																													?.project_plan_requirements[
																													'controls'
																												][ii]['controls']
																													?.levels_total
																													.touched &&
																												getstandard_steps
																													.controls[
																													'project_analysis_data'
																												]['controls'][i][
																													'controls'
																												]
																													?.project_plan_requirements[
																													'controls'
																												][ii]['controls']
																													?.levels_total.errors
																													?.required
																											"
																										>
																											{{
																												"lang_field_required"
																													| language
																											}}
																										</mat-error>

																										<mat-error
																											*ngIf="
																												getstandard_steps
																													.controls[
																													'project_analysis_data'
																												]['controls'][i][
																													'controls'
																												]
																													?.project_plan_requirements[
																													'controls'
																												][ii]['controls']
																													?.levels_total
																													.touched &&
																												getstandard_steps
																													.controls[
																													'project_analysis_data'
																												]['controls'][i][
																													'controls'
																												]
																													?.project_plan_requirements[
																													'controls'
																												][ii]['controls']
																													?.levels_total.errors
																													?.notEqual
																											"
																										>
																											{{
																												"lang_sub_fields_must_be_equals"
																													| language
																											}}
																										</mat-error>
																									</mat-form-field>
																								</div>
																								<div
																									class="card w-100"
																									style="
																										width: 100%;
																										box-shadow: unset;
																										border: unset;
																									"
																									formArrayName="levels"
																									*ngFor="
																										let creds2 of getSmallSteps
																											.project_analysis_data
																											?.value[i]
																											.project_plan_requirements[
																											ii
																										].levels;
																										let iii = index;
																										trackBy: trackByFnCustom
																									"
																								>
																									<div
																										class="card-body"
																										style="padding: 0 1.25rem"
																										[formGroupName]="iii"
																									>
																										<div class="row">
																											<div
																												class="col-6 col-sm-6 mb-2"
																												[dir]="
																													lang_key == 'ar'
																														? 'rtl'
																														: 'ltr'
																												"
																											>
																												{{
																													"lang_sub_level_no"
																														| language
																												}}. {{ iii + 1 }}
																											</div>

																											<div
																												class="form-group col-md-6 mb-1"
																												style="
																													text-align: right;
																												"
																											>
																												<a
																													class="danger"
																													(click)="
																														deleteSUbLevel(
																															i,
																															ii,
																															iii
																														)
																													"
																													matTooltip="{{
																														'lang_delete_level'
																															| language
																													}}"
																												>
																													<i
																														class="fa fa-trash deleteICO font-medium-3 m-2"
																													></i>
																												</a>
																											</div>
																											<div
																												class="col-12 col-sm-6"
																												[dir]="
																													lang_key == 'ar'
																														? 'rtl'
																														: 'ltr'
																												"
																											>
																												<mat-form-field
																													style="width: 100%"
																													appearance="outline"
																												>
																													<mat-label>{{
																														"lang_level_value"
																															| language
																													}}</mat-label>

																													<input
																														matInput
																														(keypress)="
																															isNumber($event)
																														"
																														type="number"
																														formControlName="level_value"
																														required
																													/>

																													<mat-error
																														*ngIf="
																															getstandard_steps
																																.controls[
																																'project_analysis_data'
																															]['controls'][i][
																																'controls'
																															]
																																?.project_plan_requirements[
																																'controls'
																															][ii]['controls']
																																?.levels[
																																'controls'
																															][iii]['controls']
																																.level_value
																																.touched &&
																															getstandard_steps
																																.controls[
																																'project_analysis_data'
																															]['controls'][i][
																																'controls'
																															]
																																?.project_plan_requirements[
																																'controls'
																															][ii]['controls']
																																?.levels[
																																'controls'
																															][iii]['controls']
																																.level_value
																																.errors
																																?.required
																														"
																													>
																														{{
																															"lang_field_required"
																																| language
																														}}
																													</mat-error>
																												</mat-form-field>
																											</div>
																											<div
																												class="col-12 col-sm-6"
																												[dir]="
																													lang_key == 'ar'
																														? 'rtl'
																														: 'ltr'
																												"
																											>
																												<mat-form-field
																													style="width: 100%"
																													appearance="outline"
																												>
																													<mat-label>{{
																														"lang_level_occupancy"
																															| language
																													}}</mat-label>

																													<input
																														matInput
																														type="text"
																														formControlName="level_occupancy"
																														required
																													/>

																													<mat-error
																														*ngIf="
																															getstandard_steps
																																.controls[
																																'project_analysis_data'
																															]['controls'][i][
																																'controls'
																															]
																																?.project_plan_requirements[
																																'controls'
																															][ii]['controls']
																																?.levels[
																																'controls'
																															][iii]['controls']
																																.level_occupancy
																																.touched &&
																															getstandard_steps
																																.controls[
																																'project_analysis_data'
																															]['controls'][i][
																																'controls'
																															]
																																?.project_plan_requirements[
																																'controls'
																															][ii]['controls']
																																?.levels[
																																'controls'
																															][iii]['controls']
																																.level_occupancy
																																.errors
																																?.required
																														"
																													>
																														{{
																															"lang_field_required"
																																| language
																														}}
																													</mat-error>
																												</mat-form-field>
																											</div>
																										</div>
																									</div>
																								</div>
																								<a
																									(click)="addLevels(i, ii)"
																									class="btn customButton mb-4 mx-3"
																								>
																									{{
																										"lang_add_levels" | language
																									}}
																								</a>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<hr width="70%" class="m-auto" />
																	</div>
																</div>
															</mat-tab>
														</mat-tab-group>
													</div>
												</form>
											</div>
											<div id="test-l-4" class="content">
												<form [formGroup]="getSmallSteps.surveying_costs">
													<div class="col-12 col-sm-6 mt-1 mb-3">
														<div class="d-flex justify-content-between">
															<mat-label
																style="margin: 15px 5px; font-size: 17px"
																>{{
																	"lang_surveying_business_costs_status"
																		| language
																}}
															</mat-label>
															<mat-radio-group
																aria-labelledby="example-radio-group-label"
																formControlName="surveying_business_costs_status"
															>
																<mat-radio-button
																	*ngFor="let soil_sensor of soil_sensor_status"
																	(change)="surveying_business_costs($event)"
																	[value]="soil_sensor.value"
																>
																	{{ soil_sensor.label }}
																</mat-radio-button>
															</mat-radio-group>
														</div>

														<mat-error
															*ngIf="
																getSmallSteps.surveying_costs.get(
																	'surveying_business_costs_status'
																).touched &&
																getSmallSteps.surveying_costs.get(
																	'surveying_business_costs_status'
																).errors?.required
															"
														>
															{{ "lang_field_required" | language }}
														</mat-error>
													</div>

													<div
														class="col-12"
														*ngIf="
															getSmallSteps.surveying_costs.value
																?.surveying_business_costs_status == 0
														"
														[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
													>
														<mat-form-field
															appearance="outline"
															style="width: 100%"
														>
															<mat-label>{{
																"lang_surveying_business_costs_legal_notes"
																	| language
															}}</mat-label>
															<textarea
																matInput
																formControlName="surveying_business_costs_legal_notes"
																rows="5"
															></textarea>
															<mat-error
																*ngIf="
																	getSmallSteps.surveying_costs.get(
																		'surveying_business_costs_legal_notes'
																	).touched &&
																	getSmallSteps.surveying_costs.get(
																		'surveying_business_costs_legal_notes'
																	).errors?.required
																"
															>
																{{ "lang_field_required" | language }}
															</mat-error>
														</mat-form-field>
													</div>

													<ng-container
														*ngIf="
															getSmallSteps.surveying_costs.value
																?.surveying_business_costs_status == 1
														"
													>
														<div class="form-group">
															<a
																(click)="addSurveyingCosts()"
																class="btn customButton mb-4"
															>
																{{ "lang_add_surveying_cost" | language }}
															</a>
															<div
																formArrayName="surveying_costs"
																class="mb-3"
																*ngFor="
																	let creds of getSmallSteps.surveying_costs
																		?.value.surveying_costs;
																	let i = index;
																	trackBy: trackByFnCustom
																"
															>
																<div
																	[formGroupName]="i"
																	[ngClass]="{ borderTop: i != 0 }"
																>
																	<div class="row">
																		<div class="form-group col-md-6 mb-1">
																			<h5 class="head">
																				{{
																					"lang_surveying_cost_no" | language
																				}}. {{ i + 1 }}
																			</h5>
																		</div>
																		<div
																			class="form-group col-md-6 mb-1"
																			style="text-align: right"
																		>
																			<a
																				class="danger"
																				*ngIf="i != 0"
																				(click)="deleteServayingCost(i)"
																				matTooltip="{{
																					'lang_delete_surveying_cost'
																						| language
																				}}"
																			>
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"
																				></i>
																			</a>
																		</div>
																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_surveying_title_english"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					type="text"
																					(keyup)="makekeyword($event, i)"
																					formControlName="surveying_title_english"
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_title_english
																							.touched &&
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_title_english.errors
																							?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_surveying_title_arabic"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					type="text"
																					formControlName="surveying_title_arabic"
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_title_arabic
																							.touched &&
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_title_arabic.errors
																							?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_surveying_cost_value" | language
																				}}</mat-label>

																				<input
																					matInput
																					(keypress)="isNumber($event)"
																					(keyup)="calcTotalForServaying(i)"
																					type="number"
																					formControlName="surveying_cost_value"
																					required
																				/>

																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_cost_value.touched &&
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_cost_value.errors
																							?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_surveying_cost_total_value"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					(keypress)="isNumber($event)"
																					[disabled]="true"
																					type="number"
																					formControlName="surveying_cost_total_value"
																					disabled
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_cost_total_value
																							.touched &&
																						getstandard_steps.controls[
																							'surveying_costs'
																						]['controls'].surveying_costs[
																							'controls'
																						][i]['controls']
																							?.surveying_cost_total_value
																							.errors?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>
																	</div>
																	<hr width="70%" class="m-auto" />
																</div>
															</div>
														</div>
													</ng-container>
												</form>
											</div>
											<div id="test-l-5" class="content">
												<form [formGroup]="getSmallSteps.printing_costs">
													<div class="col-12 col-sm-6 mt-1 mb-3">
														<div class="d-flex justify-content-between">
															<mat-label
																style="margin: 15px 5px; font-size: 17px"
																>{{ "lang_printing_costs_status" | language }}
															</mat-label>
															<mat-radio-group
																aria-labelledby="example-radio-group-label"
																formControlName="printing_costs_status"
															>
																<mat-radio-button
																	*ngFor="let soil_sensor of soil_sensor_status"
																	(change)="printing_business_costs($event)"
																	[value]="soil_sensor.value"
																>
																	{{ soil_sensor.label }}
																</mat-radio-button>
															</mat-radio-group>
														</div>

														<mat-error
															*ngIf="
																getSmallSteps.printing_costs.get(
																	'printing_costs_status'
																).touched &&
																getSmallSteps.printing_costs.get(
																	'printing_costs_status'
																).errors?.required
															"
														>
															{{ "lang_field_required" | language }}
														</mat-error>
													</div>

													<div
														class="col-12"
														*ngIf="
															getSmallSteps.printing_costs.value
																?.printing_costs_status == 0
														"
														[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
													>
														<mat-form-field
															appearance="outline"
															style="width: 100%"
														>
															<mat-label>{{
																"lang_printing_costs_legal_notes" | language
															}}</mat-label>
															<textarea
																matInput
																formControlName="printing_costs_legal_notes"
																rows="5"
															></textarea>
															<mat-error
																*ngIf="
																	getSmallSteps.printing_costs.get(
																		'printing_costs_legal_notes'
																	).touched &&
																	getSmallSteps.printing_costs.get(
																		'printing_costs_legal_notes'
																	).errors?.required
																"
															>
																{{ "lang_field_required" | language }}
															</mat-error>
														</mat-form-field>
													</div>

													<ng-container
														*ngIf="
															getSmallSteps.printing_costs.value
																?.printing_costs_status == 1
														"
													>
														<div class="form-group">
															<a
																(click)="addPrintingCosts()"
																class="btn customButton mb-4"
															>
																{{ "lang_add_printing_cost" | language }}
															</a>
															<div
																formArrayName="printing_costs"
																class="mb-3"
																*ngFor="
																	let creds of getSmallSteps.printing_costs
																		?.value.printing_costs;
																	let i = index;
																	trackBy: trackByFnCustom
																"
															>
																<div
																	[formGroupName]="i"
																	[ngClass]="{ borderTop: i != 0 }"
																>
																	<div class="row">
																		<div class="form-group col-md-6 mb-1">
																			<h5 class="head">
																				{{
																					"lang_printing_cost_no" | language
																				}}. {{ i + 1 }}
																			</h5>
																		</div>
																		<div
																			class="form-group col-md-6 mb-1"
																			style="text-align: right"
																		>
																			<a
																				class="danger"
																				*ngIf="i != 0"
																				(click)="deletePrintingCost(i)"
																				matTooltip="{{
																					'lang_delete_printing_cost' | language
																				}}"
																			>
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"
																				></i>
																			</a>
																		</div>
																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_printing_title_english"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					type="text"
																					(keyup)="makekeyword($event, i)"
																					formControlName="printing_title_english"
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_title_english
																							.touched &&
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_title_english.errors
																							?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_printing_title_arabic"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					type="text"
																					formControlName="printing_title_arabic"
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_title_arabic.touched &&
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_title_arabic.errors
																							?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_printing_costs_no_of_copies"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="printing_costs_no_of_copies"
																					required
																				/>

																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_costs_no_of_copies
																							.touched &&
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_costs_no_of_copies
																							.errors?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_printing_costs_no_of_panels"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					(keypress)="isNumber($event)"
																					[disabled]="true"
																					type="number"
																					formControlName="printing_costs_no_of_panels"
																					disabled
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_costs_no_of_panels
																							.touched &&
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_costs_no_of_panels
																							.errors?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_printing_costs_ratio_cost"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					(keypress)="isNumber($event)"
																					[disabled]="true"
																					type="number"
																					formControlName="printing_costs_ratio_cost"
																					disabled
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_costs_ratio_cost
																							.touched &&
																						getstandard_steps.controls[
																							'printing_costs'
																						]['controls'].printing_costs[
																							'controls'
																						][i]['controls']
																							?.printing_costs_ratio_cost.errors
																							?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>
																	</div>
																	<hr width="70%" class="m-auto" />
																</div>
															</div>
														</div>
													</ng-container>
												</form>
											</div>
											<div id="test-l-6" class="content">
												<form [formGroup]="getstandard_steps">
													<div class="form-group">
														<a
															(click)="addEngineeringCosts()"
															class="btn customButton mb-4"
														>
															{{ "lang_add_engineering_cost" | language }}
														</a>
														<div
															formArrayName="engineering_costs"
															class="mb-3"
															*ngFor="
																let creds of getSmallSteps.engineering_costs
																	?.value;
																let i = index;
																trackBy: trackByFnCustom
															"
														>
															<div
																[formGroupName]="i"
																[ngClass]="{ borderTop: i != 0 }"
															>
																<div class="row">
																	<div class="form-group col-md-6 mb-1">
																		<h5 class="head">
																			{{ "lang_cost_no" | language }}.
																			{{ i + 1 }}
																		</h5>
																	</div>
																	<div
																		class="form-group col-md-6 mb-1"
																		style="text-align: right"
																	>
																		<a
																			class="danger"
																			*ngIf="i > 0"
																			(click)="deleteEngineeringCosts(i)"
																			matTooltip="{{
																				'lang_delete_engineering_cost'
																					| language
																			}}"
																		>
																			<i
																				class="fa fa-trash deleteICO font-medium-3 m-2"
																			></i>
																		</a>
																	</div>
																	<div
																		class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																	>
																		<mat-form-field
																			style="width: 100%"
																			appearance="outline"
																		>
																			<mat-label>{{
																				"lang_engineering_cost_title_english"
																					| language
																			}}</mat-label>

																			<input
																				matInput
																				type="text"
																				(keyup)="
																					makekeywordForEngineeringCosts(
																						$event,
																						i
																					)
																				"
																				formControlName="engineering_cost_title_english"
																				required
																			/>
																			<mat-error
																				*ngIf="
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.engineering_cost_title_english
																						.touched &&
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.engineering_cost_title_english
																						.errors?.required
																				"
																			>
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>

																	<div
																		class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																	>
																		<mat-form-field
																			style="width: 100%"
																			appearance="outline"
																		>
																			<mat-label>{{
																				"lang_engineering_cost_title_arabic"
																					| language
																			}}</mat-label>

																			<input
																				matInput
																				type="text"
																				formControlName="engineering_cost_title_arabic"
																				required
																			/>
																			<mat-error
																				*ngIf="
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.engineering_cost_title_arabic
																						.touched &&
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.engineering_cost_title_arabic
																						.errors?.required
																				"
																			>
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>

																	<div
																		class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																	>
																		<mat-form-field
																			style="width: 100%"
																			appearance="outline"
																		>
																			<mat-label>{{
																				"lang_quantity" | language
																			}}</mat-label>

																			<input
																				matInput
																				(keypress)="isNumber($event)"
																				type="number"
																				formControlName="quantity"
																				required
																			/>

																			<mat-error
																				*ngIf="
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']?.quantity
																						.touched &&
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']?.quantity
																						.errors?.required
																				"
																			>
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>

																	<div
																		class="col-12 col-sm-3"
																		[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																	>
																		<mat-form-field
																			style="width: 100%"
																			appearance="outline"
																		>
																			<mat-label>{{
																				"lang_cost_per_day" | language
																			}}</mat-label>

																			<input
																				matInput
																				(keypress)="isNumber($event)"
																				type="number"
																				formControlName="cost_per_day"
																				required
																			/>
																			<mat-error
																				*ngIf="
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.cost_per_day.touched &&
																					getstandard_steps.controls[
																						'engineering_costs'
																					]['controls'][i]['controls']
																						?.cost_per_day.errors?.required
																				"
																			>
																				{{ "lang_field_required" | language }}
																			</mat-error>
																		</mat-form-field>
																	</div>
																</div>
																<hr width="70%" class="m-auto" />
															</div>
														</div>
													</div>
												</form>
											</div>
											<div id="test-l-7" class="content">
												<form [formGroup]="getSmallSteps.government_services">
													<div class="col-12 col-sm-6 mt-1 mb-3">
														<div class="d-flex justify-content-between">
															<mat-label
																style="margin: 15px 5px; font-size: 17px"
																>{{
																	"lang_government_services_status" | language
																}}
															</mat-label>
															<mat-radio-group
																aria-labelledby="example-radio-group-label"
																formControlName="government_services_status"
															>
																<mat-radio-button
																	*ngFor="let soil_sensor of soil_sensor_status"
																	(change)="
																		OPEN_CLOSE_GOVENTMENT_SERVICES($event)
																	"
																	[value]="soil_sensor.value"
																>
																	{{ soil_sensor.label }}
																</mat-radio-button>
															</mat-radio-group>
														</div>

														<mat-error
															*ngIf="
																getSmallSteps.government_services.get(
																	'government_services_status'
																).touched &&
																getSmallSteps.government_services.get(
																	'government_services_status'
																).errors?.required
															"
														>
															{{ "lang_field_required" | language }}
														</mat-error>
													</div>

													<ng-container
														*ngIf="
															getSmallSteps.government_services.value
																?.government_services_status == 1
														"
													>
														<div class="form-group">
															<a
																(click)="addGovernmentServices()"
																class="btn customButton mb-4"
															>
																{{ "lang_add_government_services" | language }}
															</a>
															<div
																formArrayName="government_services"
																class="mb-3"
																*ngFor="
																	let creds of getSmallSteps.government_services
																		?.value.government_services;
																	let i = index;
																	trackBy: trackByFnCustom
																"
															>
																<div
																	[formGroupName]="i"
																	[ngClass]="{ borderTop: i != 0 }"
																>
																	<div class="row">
																		<div class="form-group col-md-6 mb-1">
																			<h5 class="head">
																				{{
																					"lang_government_service_no"
																						| language
																				}}. {{ i + 1 }}
																			</h5>
																		</div>
																		<div
																			class="form-group col-md-6 mb-1"
																			style="text-align: right"
																		>
																			<a
																				class="danger"
																				*ngIf="i != 0"
																				(click)="deleteGovCosts(i)"
																				matTooltip="{{
																					'lang_delete_gov_service' | language
																				}}"
																			>
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"
																				></i>
																			</a>
																		</div>
																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_government_services_subject_english"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					type="text"
																					(keyup)="makekeyword($event, i)"
																					formControlName="government_services_subject_english"
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_subject_english
																							.touched &&
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_subject_english
																							.errors?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_government_services_subject_arabic"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					type="text"
																					formControlName="government_services_subject_arabic"
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_subject_arabic
																							.touched &&
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_subject_arabic
																							.errors?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_government_services_quantity"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="government_services_quantity"
																					required
																				/>

																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_quantity
																							.touched &&
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_quantity
																							.errors?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_government_services_ratio_cost"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="government_services_ratio_cost"
																					disabled
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_ratio_cost
																							.touched &&
																						getstandard_steps.controls[
																							'government_services'
																						]['controls'].government_services[
																							'controls'
																						][i]['controls']
																							?.government_services_ratio_cost
																							.errors?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>
																	</div>
																	<hr width="70%" class="m-auto" />
																</div>
															</div>
														</div>
													</ng-container>
												</form>
											</div>
											<div id="test-l-8" class="content text-center">
												<form [formGroup]="getSmallSteps.other_costs">
													<div class="col-12 col-sm-6 mt-1 mb-3">
														<div class="d-flex justify-content-between">
															<mat-label
																style="margin: 15px 5px; font-size: 17px"
																>{{ "lang_other_costs_status" | language }}
															</mat-label>
															<mat-radio-group
																aria-labelledby="example-radio-group-label"
																formControlName="other_costs_status"
															>
																<mat-radio-button
																	*ngFor="let soil_sensor of soil_sensor_status"
																	(change)="OPEN_CLOSE_OTHER_COSTS($event)"
																	[value]="soil_sensor.value"
																>
																	{{ soil_sensor.label }}
																</mat-radio-button>
															</mat-radio-group>
														</div>

														<mat-error
															*ngIf="
																getSmallSteps.other_costs.get(
																	'other_costs_status'
																).touched &&
																getSmallSteps.other_costs.get(
																	'other_costs_status'
																).errors?.required
															"
														>
															{{ "lang_field_required" | language }}
														</mat-error>
													</div>

													<ng-container
														*ngIf="
															getSmallSteps.other_costs.value
																?.other_costs_status == 1
														"
													>
														<div class="form-group">
															<a
																(click)="addOtherCosts()"
																class="btn customButton mb-4"
															>
																{{ "lang_add_other_costs" | language }}
															</a>
															<div
																formArrayName="other_costs"
																class="mb-3"
																*ngFor="
																	let creds of getSmallSteps.other_costs?.value
																		.other_costs;
																	let i = index;
																	trackBy: trackByFnCustom
																"
															>
																<div
																	[formGroupName]="i"
																	[ngClass]="{ borderTop: i != 0 }"
																>
																	<div class="row">
																		<div class="form-group col-md-6 mb-1">
																			<h5 class="head">
																				{{ "lang_other_cost_no" | language }}.
																				{{ i + 1 }}
																			</h5>
																		</div>
																		<div
																			class="form-group col-md-6 mb-1"
																			style="text-align: right"
																		>
																			<a
																				class="danger"
																				*ngIf="i != 0"
																				(click)="deleteOtherCosts(i)"
																				matTooltip="{{
																					'lang_delete_other_cost' | language
																				}}"
																			>
																				<i
																					class="fa fa-trash deleteICO font-medium-3 m-2"
																				></i>
																			</a>
																		</div>
																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_other_costs_subject_english"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					type="text"
																					(keyup)="makekeyword($event, i)"
																					formControlName="other_costs_subject_english"
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_subject_english
																							.touched &&
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_subject_english
																							.errors?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_other_costs_subject_arabic"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					type="text"
																					formControlName="other_costs_subject_arabic"
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_subject_arabic
																							.touched &&
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_subject_arabic
																							.errors?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_other_costs_quantity" | language
																				}}</mat-label>

																				<input
																					matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="other_costs_quantity"
																					required
																				/>

																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_quantity.touched &&
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_quantity.errors
																							?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>

																		<div
																			class="col-12 col-sm-3"
																			[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'"
																		>
																			<mat-form-field
																				style="width: 100%"
																				appearance="outline"
																			>
																				<mat-label>{{
																					"lang_other_costs_ratio_cost"
																						| language
																				}}</mat-label>

																				<input
																					matInput
																					(keypress)="isNumber($event)"
																					type="number"
																					formControlName="other_costs_ratio_cost"
																					disabled
																					required
																				/>
																				<mat-error
																					*ngIf="
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_ratio_cost
																							.touched &&
																						getstandard_steps.controls[
																							'other_costs'
																						]['controls'].other_costs[
																							'controls'
																						][i]['controls']
																							?.other_costs_ratio_cost.errors
																							?.required
																					"
																				>
																					{{ "lang_field_required" | language }}
																				</mat-error>
																			</mat-form-field>
																		</div>
																	</div>
																	<hr width="70%" class="m-auto" />
																</div>
															</div>
														</div>
													</ng-container>
												</form>
											</div>
											<div id="test-l-9" class="content text-center">
												<form [formGroup]="getSmallSteps.main_analysis_costs">
													<div class="row">
														<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
															<mat-form-field
																	style="width: 100%"
																	appearance="outline">
																	<mat-label>{{
																		"lang_main_analysis_total"
																			| language
																	}}</mat-label>

																	<input
																		matInput
																		(keypress)="isNumber($event)"
																		type="number"
																		formControlName="main_analysis_total"
																		disabled
																		required
																	/>
															</mat-form-field>
														</div>
														<div class="col-12 col-sm-6" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
															<mat-form-field
																	style="width: 100%"
																	appearance="outline">
																	<mat-label>{{
																		"lang_total_analysis"
																			| language
																	}}</mat-label>

																	<input
																		matInput
																		(keypress)="isNumber($event)"
																		type="number"
																		formControlName="total_analysis"
																		disabled
																		required
																	/>
															</mat-form-field>
														</div>
													</div>
												</form>
											</div>
											<div id="test-l-10" class="content text-center">
												<form [formGroup]="getSmallSteps.quotation_price">
													<div class="form-group">
														<label for="exampleInputEmail1"
															>Email address</label
														>
														<input
															type="text"
															class="form-control"
															id="exampleInputEmail1"
															formControlName="subject"
															placeholder="Enter email"
														/>
													</div>
												</form>
											</div>
											<div id="test-l-11" class="content text-center">
												<form [formGroup]="getSmallSteps.quotation_price">
													<div class="form-group">
														<label for="exampleInputEmail1"
															>Email address</label
														>
														<input
															type="text"
															class="form-control"
															id="exampleInputEmail1"
															formControlName="subject"
															placeholder="Enter email"
														/>
													</div>
												</form>
											</div>
											<div id="test-l-12" class="content text-center">
												<form [formGroup]="getSmallSteps.quotation_price">
													<div class="form-group">
														<label for="exampleInputEmail1"
															>Email address</label
														>
														<input
															type="text"
															class="form-control"
															id="exampleInputEmail1"
															formControlName="subject"
															placeholder="Enter email"
														/>
													</div>
												</form>
											</div>
										</div>
									</div>

									<div class="row pt-3">
										<div class="col-12 col-sm-3 mb-2 mb-sm-0 text-center-small">
											<button
												mat-button
												matStepperPrevious
												class="previous-btn backBTN"
											>
												<span>
													<i
														class="fa fa-arrow-right"
														style="font-size: 14px"
														*ngIf="lang_key == 'ar'"
													></i>
													<i
														class="fa fa-arrow-left"
														style="font-size: 14px"
														*ngIf="lang_key == 'en'"
													></i>

													{{ "lang_back" | language }}
												</span>
											</button>
										</div>
										<div class="d-none d-sm-block col-sm-6"></div>
										<!--   <div class="col-12 col-sm-3">
                                            <button class="albutton mnabrbutton mat-accent "
                                            mat-button matStepperNext>{{"lang_next" | language}} 
                                            <i class="fa fa-arrow-right" style="font-size: 14px;" *ngIf="lang_key=='en'"></i>
                                            <i class="fa fa-arrow-left" style="font-size: 14px;" *ngIf="lang_key=='ar'"></i>
                                        </button>
                                        </div> -->
										<div class="col-12 col-sm-3 text-end text-center-small">
											<button
												class="albutton mnabrbutton mat-accent customBTN"
												mat-button
												(click)="MATNext(stepper, 'second')"
											>
												{{ "lang_proceed_to_project_analysis" | language }}
												<i
													class="fa fa-arrow-right"
													style="font-size: 14px"
													*ngIf="lang_key == 'en'"
												></i>
												<i
													class="fa fa-arrow-left"
													style="font-size: 14px"
													*ngIf="lang_key == 'ar'"
												></i>
											</button>
										</div>
									</div>
								</div>
							</form>
						</mat-step>
						<mat-step>
							<!-- <ng-template matStepLabel>Done</ng-template>
                          <p>You are now done.</p>
                          <div>
                            <button mat-button matStepperPrevious>Back</button>
                          </div> -->
						</mat-step>
					</mat-horizontal-stepper>
				</div>
				<div class="card-footer">
					<div class="row">
						<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
							<button
								type="button"
								routerLink="/projects/BbVUrWitLE"
								class="albutton mnabrbutton mat-accent"
								mat-button
							>
								{{ "lang_all_projects" | language }}
							</button>
						</div>
						<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
							<button
								type="button"
								(click)="quotEvent.emit()"
								class="albutton mnabrbutton mat-accent"
								mat-button
							>
								{{ "lang_go_to_project_view" | language }}
							</button>
						</div>
						<div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</section>
</form>
