<div class="table-responsive dropdownyes customResponsive">
	<table border="1" class="table CustomTable">
		<thead>
			<tr>
				<td width="5%">#</td>
				<td>{{ "lang_notes" | language }}</td>
				<td>{{ "lang_writer" | language }}</td>
				<td>{{ "lang_ondate" | language }}</td>
				<td>{{ "lang_action" | language }}</td>
			</tr>
		</thead>
		<tbody *ngIf="notes?.length != '0'" class="AppendList">
			<tr
				*ngFor="
					let note of notes
						| slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize;
					let i = index;
					let odd = odd;
					let even = even
				"
				[ngClass]="{ odd: odd, even: even }"
				class="makeback removerow{{ note.note_id }}"
			>
				<td>{{ i + 1 }}</td>
				<td>{{ note?.transactions_notes_text }}</td>
				<td>{{ note?.transactions_notes_user_name }}</td>
				<td>{{ note?.transactions_notes_datetime }}</td>
				<td>----</td>
			</tr>
		</tbody>
		<tbody *ngIf="notes?.length == '0'" class="AppendList">
			<tr class="odd">
				<td colspan="6" align="center">
					{{ "lang_no_data" | language }}
				</td>
			</tr>
		</tbody>
	</table>
	<div
		class="mt-2"
		style="
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		"
	>
		<ngb-pagination
			*ngIf="notes?.length"
			[collectionSize]="notes?.length"
			[(page)]="page"
			[pageSize]="pageSize"
			[boundaryLinks]="true"
		></ngb-pagination>
	</div>
</div>
