import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  animations: [
    trigger("fadeIn", [
      state(
        "void",
        style({
          opacity: 0
        })
      ),
      transition("void <=> *", animate(1000))
    ]),
  ]
})
export class ApplicationsComponent implements OnInit {
  interviewform: FormGroup;
  noteform: FormGroup;
  actionform: FormGroup;
  modalRefA: BsModalRef;
  modalRefB: BsModalRef;
  modalRefC: BsModalRef;
  modalRefD: BsModalRef;


  errormessagef                     = this.lang.transform('lang_loading');
  is_admin                          = JSON.parse(this.ls.getItem('is_admin'));
  noteerrormsg                      = this.lang.transform('lang_loading');
  npage                             = 1;
  npsize                            = 10;
  employee_number: any              = '';
  wholddata: any                    = [];
  applications: any                 = [];
  liststatus: any                   = "";
  interview_form_status: boolean    = false;
  interviewerror: any               = "";
  note_form_status: boolean         = false;
  noteerror: string                 = "";
  notes: any                        = [];
  notesdescription: any             = "";
  needremainderdate: boolean        = false;
  notedata: any                     = [];
  action_form_status: boolean       = false;
  actionerror: any                  = "";
  branches: any                     = [];
  usertypes: any                    = [];
  empstatus: any                    = "";
  nsearchKey                        = "";
  employeename                      = "";

  page:any                          = localStorage.getItem('M_H_R_A_N') || 1;
	size:any                          = localStorage.getItem('M_H_R_A_S') || 10;
  searchKey                         = localStorage.getItem('M_H_R_A_T') || "";
  searchStatus                      = localStorage.getItem('M_H_R_A_S_K') || "ALL";

  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.interviewform = new FormGroup({
      employee_number: new FormControl('', [Validators.required]),
      interview_date_en: new FormControl('', [Validators.required]),
      interview_date_ar: new FormControl('', [Validators.required]),
    });
    this.noteform = new FormGroup({
      note_id: new FormControl(''),
      id: new FormControl('', [Validators.required]),
      note_description: new FormControl('', [Validators.required]),
      link_with_view_list: new FormControl(0),
      note_remainder_status: new FormControl("0", [Validators.required]),
      show_status: new FormControl('', [Validators.required]),
      note_remainder_date: new FormControl(''),
    });
    this.actionform = new FormGroup({
      employee_number: new FormControl('', [Validators.required]),
      employee_status: new FormControl('', [Validators.required]),
      branch_id: new FormControl(""),
      group_id: new FormControl(""),
    });
    this.get_job_applications();
  }
  public openModelA(template: TemplateRef<any>) {
    this.notedata = [];
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public openModelB(template: TemplateRef<any>) {
    this.modalRefB = this.modalService.show(template, environment.modelconfig);
  }
  public openModelC(template: TemplateRef<any>) {
    this.modalRefC = this.modalService.show(template, environment.modelconfig);
  }
  public openModelD(template: TemplateRef<any>) {
    this.modalRefD = this.modalService.show(template, environment.modelconfig);
  }
  public loadbypagesize(size) {
    this.size = size;
    this.page = 1;
    this.get_job_applications();
  }
  public loadbypagelength(page) {
    this.page = page;
    this.get_job_applications();
  }
  public get_job_app() {
    this.page = 1;
    this.get_job_applications();
  }
  public get_job_applications() {
    localStorage.setItem('M_H_R_A_N', this.page);
		localStorage.setItem('M_H_R_A_S', this.size);
    localStorage.setItem('M_H_R_A_S_K', this.searchStatus || 'ALL');  // HR application Type
		localStorage.setItem('M_H_R_A_T', this.searchKey || '');
    const param = new FormData();
    param.append("searchKey", this.searchKey || '');
    param.append("searchStatus", this.searchStatus || '');
    this.ds.postActionByUrl(param, "human_resources/get_job_applications/"+this.page+'/'+this.size).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.wholddata = data;
      } else {
        this.wholddata = [];
        this.errormessagef = data.error;
      }
    }, error => {
      this.wholddata = [];
      this.spinner.hide();
      this.errormessagef = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  //code for interview operations
  public setvalue(id) {
    this.alert.clear();
    this.interviewform.reset();
    this.interviewform.get('employee_number').setValue(id);
  }
  public arrange_interview() {
    this.spinner.show();
    const formData = this.ds.json2formdata(this.interviewform.value);
    this.ds.postActionByUrl(formData, "human_resources/arrange_interview/").subscribe(result => {
      this.spinner.hide();
      if (result.status) {
        this.interviewform.reset();
        this.alert.success(result.msg);
        setTimeout(() => {
          this.modalRefB.hide();
        }, 2000);
      } else {
        this.alert.error(result.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  //end of interview operations
  //code for notes operations
  public setnotevalue(id) {
    this.alert.clear();
    this.resetnoteform();
    this.noteform.get('id').setValue(id);
  }
  public get_my_notes(id) {
    this.notes = [];
    this.alert.clear();
    this.resetnoteform();
    this.noteform.get('id').setValue(id);
    this.employee_number = id;
    this.list_of_notes();
  }
  public notepage(size) {
    this.npsize = size;
    this.npage = 1;
    this.list_of_notes();
  }
  public notelength(page) {
    this.npage = page;
    this.list_of_notes();
  }
  public search_my_notes() {
    this.npage = 1;
    this.npsize = 10;
    this.list_of_notes();
  }
  public list_of_notes() {
    let formData = new FormData();
    formData.append('search_key', this.nsearchKey || '');
    formData.append('id', this.employee_number || '');
    this.ds.postActionByUrl(formData, 'human_resources/get_job_notes/'+this.npage+'/'+this.npsize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.notedata = res;
      } else {
        this.notedata = [];
        this.noteerrormsg = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.notedata = [];
      this.noteerrormsg = error.error;
    });
  }
  public show_remainder_date(option) {
    this.needremainderdate = false;
    this.noteform.get('note_remainder_date').setValue('');
    if (option === "1") {
      this.needremainderdate = true;
    }
  }
  public save_notes() {
    const param = new FormData();
    param.append('note_id', this.noteform.get('note_id').value || '');
    param.append('id', this.noteform.get('id').value || '');
    param.append('note_description', this.noteform.get('note_description').value || '');
    param.append('link_with_view_list', '0');
    param.append('note_remainder_status', this.noteform.get('note_remainder_status').value || '');
    param.append('show_status', this.noteform.get('show_status').value || '');
    param.append('note_remainder_date', this.noteform.get('note_remainder_date').value || '');
    let url = (this.noteform.get('note_id').value) ? 'update_notes' : 'save_notes';
    this.spinner.show();
    this.ds.postActionByUrl(param, 'human_resources/'+url).subscribe(result => {
      this.spinner.hide();
      this.note_form_status = result.status;
      if (result.status) {
        this.list_of_notes();
        this.resetnoteform();
        this.alert.success(result.msg);
        setTimeout(() => {
          this.alert.clear();
        }, 1000);
      } else {
        this.alert.error(result.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public editnotes(note) {
    this.noteform.patchValue({
      note_id: note.note_id,
      employee_number: note.table_value1,
      note_description: note.note_description,
      show_status: note.show_status,
      note_remainder_status: note.note_remainder_status,
      note_remainder_date: (note.note_remainder_date) ? this.ds.encon(note.note_remainder_date) : '',
    });
  }
  public resetnoteform() {
    this.noteform.get('note_id').setValue('');
    this.noteform.get('note_description').setValue('');
    this.noteform.get('note_remainder_status').setValue('0');
    this.noteform.get('show_status').setValue('');
    this.noteform.get('note_remainder_date').setValue('');
  }
  //end of notes operations
  //code for action approve, reject or pending
  public makeAction(id, status) {
    this.actionform.reset();
    this.empstatus = status;
    this.alert.clear();
    this.actionform.get('employee_status').setValue(status);
    this.actionform.get('employee_number').setValue(id);
  }
  public get_data_for_action() {
    this.branches = [];
    this.usertypes = [];
    let status = this.actionform.get('employee_status').value;
    if (status == "1") {
      this.spinner.show();
      this.ds.getActionByUrl([], 'human_resources/branches/human_resources_view').subscribe(data => {
        this.spinner.hide();
        if (data.status) {
          this.branches = data.branches;
          this.usertypes = data.types;
        }
      }, error => {
        this.spinner.hide();
      })
    } else {
      this.actionform.get('branch_id').setValue('');
    }
  }
  public make_action() {
    this.spinner.show();
    let orgstatus = this.actionform.get('employee_status').value;
    if (this.actionform.status === "VALID" && orgstatus !== this.empstatus) {
      let data = this.ds.json2formdata(this.actionform.value);
      this.ds.postActionByUrl(data, 'human_resources/approve_actions').subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.get_job_applications();
          this.actionform.reset();
          this.alert.success(res.msg);
          setTimeout(() => {
            this.modalRefC.hide();
          }, 1000);
        } else {
          this.actionerror = res.error;
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error(error.error + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      this.spinner.hide();
      this.alert.error(this.lang.transform('lang_status_must_be_different'));
    }
  }
  public checkAllApp(event) {
    if (event.target.checked) {
        $(".all_applications .eachItemApp").prop("checked", true);
        $(".all_applications .makeback").addClass("bground");
        $(".all_applications .deleteIconDiv").show();
    } else {
        $(".all_applications .eachItemApp").prop("checked", false);
        $(".all_applications .makeback").removeClass("bground");
        $(".all_applications .deleteIconDiv").hide();
    }
  }
  public checkEachItemApp(event) {
      if (event.target.checked) {
          const val = event.target.value;
          $(".all_applications .removerow" + val).addClass("bground");
      } else {
          const val = event.target.value;
          $(".all_applications .removerow" + val).removeClass("bground");
      }
      const checkedtotal = [];
      $(".all_applications .eachItemApp:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      const totalrow = [];
      $(".all_applications .eachItemApp").each(function() {
          totalrow.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          $(".all_applications .deleteIconDiv").show();
      } else {
          $(".all_applications .deleteIconDiv").hide();
      }
      if (checkedtotal.length === totalrow.length) {
          $(".all_applications #allItemsApp").prop("checked", true);
      } else {
          $(".all_applications #allItemsApp").prop("checked", false);
      }
  }
  public deleteBulkDataApp() {
      const checkedtotal = [];
      $(".all_applications .eachItemApp:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          const dialogRef = this.dialog.open(ConfirmationDialog, {
              disableClose: true,
              width: '400px',
              data: {
                  title: this.lang.transform('lang_confirmation'),
                  message: this.lang.transform('lang_are_you_sure'),
              }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                  this.spinner.show()
                  this.ds.deleteActionByUrl(checkedtotal, "81f5cc8c046c6d1c66fa3424783873db/MAIN").subscribe(
                      (data) => {
                          this.spinner.hide();
                          if (data.status) {
                              this.get_job_applications();
                              $(".all_applications #allItemsApp").prop("checked", false);
                              $(".all_applications .deleteIconDiv").hide();
                              this.ds.dialogf('', data.msg);
                          } else {
                              this.ds.dialogf('', data.error);
                          }
                      },
                      (error) => {
                          this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                          this.spinner.hide()
                      }
                  );
              }
          });
      } else {
          this.ds.dialogf();
      }
  }
}