 
 
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView, collapseAnimation } from 'angular-calendar';
 import { addDays, addHours, endOfDay, endOfMonth, getHours, isSameDay, isSameMonth, startOfDay, subDays, subHours } from 'date-fns';
import moment, * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { parseISO, format } from 'date-fns';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { x64 } from 'crypto-js';
import { MatDialog } from '@angular/material/dialog';
import { AddSingleShiftComponent } from './add-single-shift/add-single-shift.component';
export interface CalendarEvent2<MetaType = any> {
  id?: string | number;
  start: Date;
  end?: Date;
  title: string;
  color?: any;
  actions?: any[];
  allDay?: boolean;
  cssClass?: string;
  resizable?: {
      beforeStart?: boolean;
      afterEnd?: boolean;
  };
  data?:any;
  draggable?: boolean;
  meta?: MetaType;
}

@Component({
  selector: 'app-add-shift',
  templateUrl: './add-shift.component.html',
  styleUrls: ['./add-shift.component.scss']
})
export class AddShifComponent implements OnInit {

  constructor(public dialog: MatDialog,private fb: FormBuilder,) { }
  startDate:any
  endDate:any
  form:FormGroup|any
  lang_key = localStorage.getItem('lang_key') || 'en';
  ngOnInit(): void {
    moment.locale('en')
    Moment.locale('en')
    this.startDate = moment().locale('en').clone().startOf('month').format('DD-MM-YYYY').toString();
    this.endDate = moment().locale('en').clone().endOf('month').format('DD-MM-YYYY').toString();
    this.LoadBookingsForCalendar()

   /*  Array.from(Array(moment(this.startDate).daysInMonth()), (_, i) => i + 1)
    */
   this.form = this.fb.group({
    times:this.fb.array([

     ])
   })

    let monthDays = Array.from({length: moment(moment().locale('en').clone().startOf('month').format('YYYY-MM')).daysInMonth()}, (x, i) => moment().startOf('month').add(i, 'days').format('DD/MM/YYYY'))
    console.log(monthDays);
    for (let index = 0; index < monthDays.length; index++) {
      const element = monthDays[index];
      let times =  this.form.controls.times as FormArray
      times.push(   this.fb.group({
       /*  start: startOfDay(new Date(element)),
        end: startOfDay(new Date(element)), */
      /*   start: new Date( new Date(element).toUTCString().replace("GMT","")), */
      /*   end:  startOfDay(new Date(element)), */
        /* start: startOfDay(new Date(element)),
        end: addDays( startOfDay(new Date(element)),monthDays.length-1), */
        title: 'A 3 day event',
       /*  , */
        data:[{
          status:'requested',
          vio:'حضور متأخر',
          count:3
        },[Validators.required]],
        index:index,
       /*  start: [moment(element,'DD/MM/YYYY').toISOString(),[Validators.required]], */
        start:[parseISO(moment(element,'DD/MM/YYYY').format())],
        end:[parseISO(moment(element,'DD/MM/YYYY').format()),[Validators.required]] ,
        shift_time_day:[element.split('/')[0],[Validators.required]],
        shift_time_date:[element,[Validators.required]],
        shift_time_day_off:['',[Validators.required]],
        shift_total_hours:['',[Validators.required]],
        shift_break_hours:['',[Validators.required]],
        check_in_start:['',[Validators.required]],
        check_in_end:['',[Validators.required]],
        break_out_start:['',[Validators.required]],
        break_out_end:['',[Validators.required]],
        break_in_start:['',[Validators.required]],
        break_in_end:['',[Validators.required]],
        check_out_start:['',[Validators.required]],
        check_out_end:['',[Validators.required]],
        shift_start_time:['',[Validators.required]],
        shift_end_time:['',[Validators.required]],
        trackingId: [this.generateUniqueId()],
      }))
    }
    console.log(this.form);
    
  }


	trackByFnCustom(index: number, item: any) {
		return item.trackingId;
	}
	generateUniqueId() {
		return (
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15)
		);
	}




  refresh: Subject<any> = new Subject();
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
 
  activeDayIsOpen: boolean = false;
  view: CalendarView = CalendarView.Month;

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
  /*   this.modalData = { event: [event], action: action, selectedDate: '', date: new Date().toString() };
    this.modal.open(this.modalContent, { size: 'lg' }); */
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
  
    console.log(date);
    console.log(events);
    this.openModal({ date, events })
      /* if (isSameMonth(date, this.viewDate)) {
        if (
          (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)  
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen =true ;
        }
        this.viewDate = date;
      } */


   
    }
    
  from_to={
    startDate:Moment().locale('en').startOf('month').format('DD-MM-YYYY'),
    endDate:Moment().locale('en').startOf('month').add(30, 'days').format('YYYY-MM-DD'),
  }


     openModal(data) {
    const dialogRef = this.dialog.open(AddSingleShiftComponent,{
      disableClose: true,
      width:'80%',
      data:data
      
    });
    dialogRef.afterClosed().subscribe(data => {
      /* this.sendCodeForm = data?.formValue;
      this.sendCodeStatus = data?.sendstatus; */
    
     console.log('SSSS',data);
      
      if(data.formValue){
        let  daysArr = data.days
        let  formVal = data.formValue
        if(formVal.copy_all_days_names){
          for (let index = 0; index < daysArr.length; index++) {
            const element = daysArr[index];
            for (let i = 0; i < this.form.controls.times.controls.length; i++) {
              const formGroup = this.form.controls.times.controls[i] as FormGroup;
              if(moment(formGroup.value.shift_time_date,'DD/MM/YYYY').isSame(element, 'day')){
               if(formVal.shift_time_day_off){
                formGroup.patchValue({
                  ...formGroup.value,
                  shift_time_day_off:formVal.shift_time_day_off
                })
                
                
               }else{
                formGroup.patchValue({
                  ...formGroup.value,
              
                
                  shift_time_day_off:formVal.shift_time_day_off,
                  shift_total_hours:formVal.shift_total_hours,
                  shift_break_hours:formVal.shift_break_hours,
                  check_in_start:formVal.check_in_start,
                  check_in_end:formVal.check_in_end,
                  break_out_start:formVal.break_out_start,
                  break_out_end:formVal.break_out_end,
                  break_in_start:formVal.break_in_start,
                  break_in_end:formVal.break_in_end,
                  check_out_start:formVal.check_out_start,
                  check_out_end:formVal.check_out_end,
                  shift_start_time:formVal.shift_start_time,
                  shift_end_time:formVal.shift_end_time,
              
                })
               }
               
              } 
            }
            console.log(this.form);
          }
        }/* else if(formVal.copy_all_days_month){
          for (let i = 0; i < this.form.controls.times.controls.length; i++) {
            const formGroup = this.form.controls.times.controls[i] as FormGroup;
            if(formVal.shift_time_day_off){
              formGroup.patchValue({
                ...formGroup.value,
                shift_time_day_off:formVal.shift_time_day_off
              })
              
              
             }else{
              formGroup.patchValue({
                ...formGroup.value,
            
              
                shift_time_day_off:formVal.shift_time_day_off,
                shift_total_hours:formVal.shift_total_hours,
                shift_break_hours:formVal.shift_break_hours,
                check_in_start:formVal.check_in_start,
                check_in_end:formVal.check_in_end,
                break_out_start:formVal.break_out_start,
                break_out_end:formVal.break_out_end,
                break_in_start:formVal.break_in_start,
                break_in_end:formVal.break_in_end,
                check_out_start:formVal.check_out_start,
                check_out_end:formVal.check_out_end,
                shift_start_time:formVal.shift_start_time,
                shift_end_time:formVal.shift_end_time,
            
              })
             }
          }
        } */else{
          let selectedDay = this.form.controls.times.controls[formVal.index] as FormGroup
          selectedDay.patchValue({
         
           shift_time_day_off:formVal.shift_time_day_off,
           shift_total_hours:formVal.shift_total_hours,
           shift_break_hours:formVal.shift_break_hours,
           check_in_start:formVal.check_in_start,
           check_in_end:formVal.check_in_end,
           break_out_start:formVal.break_out_start,
           break_out_end:formVal.break_out_end,
           break_in_start:formVal.break_in_start,
           break_in_end:formVal.break_in_end,
           check_out_start:formVal.check_out_start,
           check_out_end:formVal.check_out_end,
           shift_start_time:formVal.shift_start_time,
           shift_end_time:formVal.shift_end_time,
          })
        }
      }else{
        
      }
   
      
    })
  }
  closeOpenMonthViewDay(status: string) {
    const moment = extendMoment(Moment);
    var start = '';
    var end = '';
    if (status == 'today') {
      this.startDate = moment().locale('en').clone().startOf('month').format('DD-MM-YYYY').toString();
      this.endDate = moment().locale('en').clone().endOf('month').format('DD-MM-YYYY').toString();
      this.from_to={
        startDate:Moment().locale('en').startOf('month').format('DD-MM-YYYY'),
        endDate:Moment().locale('en').startOf('month').add(30, 'd').format('YYYY-MM-DD'),
      }
    }
    if (status == 'next') {
      this.activeDayIsOpen = false;
      console.log(this.from_to);
      
      this.from_to={
        endDate:Moment(this.from_to.endDate).locale('en').add(30,'day').format('YYYY-MM-DD'),
        startDate:Moment(this.from_to.endDate).locale('en').add(30,'day').format('YYYY-MM-DD')
      }
    }
    if (status == 'previous') {
      var date = moment(this.startDate, "DD-MM-YYYY").locale('en').toDate();
      this.activeDayIsOpen = false;
      this.from_to={
        endDate:Moment(this.from_to.endDate).locale('en').add(-30,'days').format('YYYY-MM-DD'),
        startDate:Moment(this.from_to.startDate).locale('en').add(-30,'days').format('YYYY-MM-DD')
      }
    }
    this.LoadBookingsForCalendar()
 
  

  }






  private subscriptions = new Subscription()

  currentUser:any;
  userType = '';
  serverEvents:Event[]|any = [];
  CalendarView = CalendarView;
 

  actions: any[] = [];
 

  LoadBookingsForCalendar( from_to?:any) {
    // if(this.status == 'vacation'){
    //   this.type = 'vacation';
    // }


    this.serverEvents = [
      {
        start: startOfDay(new Date()),
        end: addDays(new Date(), 1),
        title: 'A 3 day event',
        data:{
          status:'requested',
          vio:'حضور متأخر',
          count:3
        },
        actions: this.actions,
        allDay: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      },
      {
        start: startOfDay(new Date()),
        end: addDays(new Date(), 1),
        title: 'An event with no end date',
        data:{
          status:'cancelled',
          vio:'غياب',
          count:3
          
        },
        actions: this.actions,
      },
      
      {
        start: subDays(endOfMonth(new Date()), 3),
        end: addDays(endOfMonth(new Date()), 3),
        title: 'A long event that spans 2 months',
        data:{
          status:'requested',
          vio:'حضور متأخر',
          count:3
        },
        allDay: true,
      },
      {
        start: addHours(startOfDay(new Date()), 2),
        end: addHours(new Date(), 2),
        title: 'A draggable and resizable event',
        data:{
          status:'available',
          vio:'حاضرين في الميعاد',
          count:3
        },
        actions: this.actions,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      },
      {
        start: addHours(startOfDay(new Date()), 4),
        end: addHours(new Date(), 4),
        title: 'A draggable and resizable event',
        data:{
          status:'vacation',
          vio:'حاضرين في الميعاد',
          count:3
        },
        actions: this.actions,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      },
    ];
  
  
    
    this.events = [];
    this.serverEvents.map((x:any)=> {
      console.log( );
        let calEvent:CalendarEvent2 = {
          start: new Date( new Date(x.start).toUTCString().replace("GMT","")),
          end: new Date( new Date(x.end).toUTCString().replace("GMT","")),
          title : '',
          actions:this.actions,
          data: {
            ...x.data
            
          }
        } 
        this.events.push(calEvent);

      });
      console.log(this.events);
       
      this.refresh.next();
    
   /*   this.subscriptions.add(this._EventsService.getAllEvent(this.tutorId!,this.status!,this.type!).subscribe(res => {
   

     }));     */ 
  }

}
