<div class="row all_employees"  *ngIf="EmployeeData.length>0">
  <div class="col-12 col-sm-1 mb-2 center-sm  ">
		<!-- <button
			class="albutton mnabrbutton"
			mat-raised-button
			(click)="checkAllHR(allSelected == false ? true: false)"
		>
		<mat-icon aria-hidden="false" aria-label=""  *ngIf="allSelected == true">close</mat-icon>
		<mat-icon aria-hidden="false" aria-label="" *ngIf="allSelected == false">check</mat-icon>
		 
			{{allSelected == false ? "lang_select_all":'lang_unselect_all' | language }}
		</button> -->
		<div class="checkbox" style="    
		position: relative;
		top: 7px;"  matTooltip="{{'lang_check_all' | language}}">
			<input type="checkbox" class="allItemsHR checkbox" id="allItemsHR" (click)="checkAllHR(allSelected == false ? true: false)"/>
			<label for="checkbox"></label>
		</div>
	</div> 
	<div class="col-12 col-sm-4 mb-2" >
		<button
			class="albutton mnabrbutton"
			mat-raised-button
			(click)="toggleFilter()"
		>
			<i class="fa fa-filter"></i>
			{{ "lang_advanced_filter" | language }}
		</button>
	</div>
	<div class="col-12 col-sm-4 mb-2">
		<button
			class="albutton mnabrbutton"
			mat-raised-button
			(click)="exportExcel()"
			 
		>
			<i class="fa fa-file-excel-o"></i>
			{{ "lang_export_excel" | language }}
		</button>
	</div>
</div>
<div class="row pt-3">
	<div
		[ngClass]="{
			'col-12 col-sm-8': showFilter || showSort,
			'col-12': !showFilter || !showSort
		}"
	>
		<div class="row" *ngIf="EmployeeData.length>0">
			<ng-container *ngFor="let item of EmployeeData; let i = index">
				<div
					[ngClass]="{
						'col-12 col-sm-6 col-md-4': !showFilter || !showSort,
						'col-12 col-sm-6 col-md-6': showFilter || showSort
					}"
				>
					<mat-card
						class="card"
						[style.border-left-color]="
							item?.employee_status == '1'
								? '#28a745 !important'
								: item?.employee_status == '0'
								? '#dc3545 !important'
								: '#fff !important'
						"
					>
						<mat-card-title class="card-title">
							<div class="d-flex">
								<div class="checkbox m-auto"  matTooltip="{{'lang_check' | language}}">
									<input type="checkbox"  class="eachItemHR checkbox" value="{{item.employee_number}}" (click)="checkEachItemHR($event)"/>
									<label for="checkbox"></label>
								</div>
							    <span *ngIf="item?.profile_image" routerLink="/human_resources/view/{{item.employee_number}}/view/{{item.branch_id}}" >
									<span class="result_code"><img src="{{item.profile_image}}" style="border-radius: 50%;width: 100%;height: 100%;width: 47px;height: 47px;" alt=""></span></span
								> 
								<span *ngIf="!item?.profile_image" routerLink="/human_resources/view/{{item.employee_number}}/view/{{item.branch_id}}" >
									<span class="result_code"><img src="../../../../../assets/img/profile (1).png" style="width: 47px;height: 47px;" alt=""></span></span
								>
								<div class="ml-3 mr-3" *ngIf="item?.file_path">
									<i
										class="fa fa-file-pdf-o"
										style="color: #0e3768; font-size: 25px"
										matTooltip="{{ 'lang_preview' | language }}"
										(click)="previewPDF(item?.file_path)"
									></i>
								</div>
							</div>
							<mat-menu
								#menu="matMenu"
								[overlapTrigger]="false"
								xPosition="{{ lang_key == 'en' ? 'before' : 'after' }}"
							>
								<button routerLink="/human_resources/view/{{item.employee_number}}/view/{{item.branch_id}}" mat-menu-item>
									<mat-icon style="color: rgba(0, 0, 0, 0.54) !important">visibility</mat-icon>
							     	<span>{{'lang_view' | language }}</span>	 
								</button>
								<button mat-menu-item  *ngIf="allEmpData.edit.status && item.employee_status == '1'" routerLink="/human_resources/edit/{{item.employee_number}}/edit/{{item.branch_id}}">
									<mat-icon style="color: rgba(0, 0, 0, 0.54) !important">mode_edit</mat-icon>
							     	<span>{{'lang_edit' | language }}</span>	 
								</button>
								<button mat-menu-item *ngIf="item.user_id && allEmpData.is_admin == 1 && item.signature !='0'"  (click)="showsignature(item.employee_id_number,'S',item.user_id)">
									<mat-icon style="color: rgba(0, 0, 0, 0.54) !important">assignment</mat-icon>
							     	<span>{{'lang_view_signature' | language }}</span>	 
								</button>
								<button mat-menu-item *ngIf="item.user_id && allEmpData.is_admin == 1 && item.mark !='0'"  (click)="showsignature(item.employee_id_number,'M',item.user_id)">
									<mat-icon style="color: rgba(0, 0, 0, 0.54) !important">bookmark_border</mat-icon>
							     	<span>{{'lang_view_mark' | language }}</span>	 
								</button>
								<button mat-menu-item  *ngIf="item.user_id && allEmpData.is_admin == 1"  (click)="openModelC(signatureform);signaturefor(item.employee_number, item.branch_id);">
									<mat-icon style="color: rgba(0, 0, 0, 0.54) !important">spellcheck</mat-icon>
							     	<span>{{'lang_upload_signature' | language }}</span>	 
								</button>
								<button mat-menu-item *ngIf="(item.signature!='0' || item.mark !='0') && allEmpData.is_admin == 1"  (click)="remove_signature(item.user_id);" >
									<mat-icon style="color: rgba(0, 0, 0, 0.54) !important">delete_forever</mat-icon>
							     	<span>{{'lang_remove_signature' | language }}</span>	 
								</button>
								<button mat-menu-item *ngIf="!item.user_id"  (click)="send_signup_link(emp)">
									<mat-icon style="color: rgba(0, 0, 0, 0.54) !important">link</mat-icon>
							     	<span>{{'lang_send_signup_link' | language }}</span>	 
								</button>

							</mat-menu>
							<button mat-icon-button [matMenuTriggerFor]="menu">
								<i class="fa fa-ellipsis-v"></i>
							</button>
						</mat-card-title>
						<mat-card-content style="overflow: auto;">
							<div class="row pt-3">
								<div class="col-12">
									<div class="card-body-table">
										<table style="width: 100%">
											<tr  >
												<td
												routerLink="/human_resources/view/{{item.employee_number}}/view/{{item.branch_id}}"
													colspan="2"
													style="
														text-align: center;
														text-transform: uppercase;
														color: #0e3768 !important;
														cursor: pointer;
														font-weight: 600;
													"
												>
												<span  >
													{{ item?.employee_name }}
												</span>
 
												
												</td>
											</tr>

										 
											<tr  *ngIf="item?.employee_number">
												<th>{{ "lang_human_resources_number" | language }}</th>
												<td>
													<span
													 
														 
														>{{ item?.employee_number }}</span
													>
												 
												</td>
											</tr>
											<tr  *ngIf="item?.employee_id_number"> 
												<th>{{ "lang_human_resources_id_number" | language }}</th>
												<td style="direction: ltr">
													<span>{{
														item?.employee_id_number
													}}</span>
												</td>
											</tr>
											<tr  *ngIf="item?.job_title_iqama">
												<th>{{ "lang_human_resources_job_title_iqama" | language }}</th>
												<td>{{ item?.job_title_iqama }}</td>
											</tr>
											<tr  *ngIf="item?.contract_start_date_english">
												<th>{{ "lang_human_resources_contract_start_date" | language }}</th>
												<td>
													{{ item?.contract_start_date_english }}
												</td>
											</tr>
											<tr  *ngIf="item?.contract_end_date_english">
												<th>{{ "lang_human_resources_contract_end_date" | language }}</th>
												<td>{{ item?.contract_end_date_english }}</td>
											</tr>
											<tr  *ngIf="item?.employee_id_number">
												<th>{{ "lang_human_resources_id_details" | language }}</th>
												<td>{{ item?.employee_id_number }}</td>
											</tr>
											<tr  *ngIf="item?.nationality">
												<th>{{ "lang_human_resources_nationality" | language }}</th>
												<td>{{ item?.nationality }}</td>
											</tr>
											<tr *ngIf="item?.passport_id">
												<th>{{ "lang_human_resources_passport_number" | language }}</th>
												<td>{{ item?.passport_id }}</td>
											</tr>
											<tr  *ngIf="item?.project_expiry_date">
												<th>{{ "lang_expiry_date" | language }}</th>
												<td>{{ item?.project_expiry_date }}</td>
											</tr>
											<tr  *ngIf="item?.name ||item?.username ">
												<th>{{ "lang_writer" | language }}</th>
												<td>{{ item?.name ? item?.name : item?.username }}</td>
											</tr>
											<tr> 
												<th>{{ "lang_status" | language }} <i class="fa fa-info-circle red" matTooltip="{{'lang_hrchange_status' | language}}" aria-hidden="true"> </i></th>
												<td> 
													<span *ngIf="item?.employee_status == 1" (click)="performStatus(0,item.employee_number, item.branch_id)" style="color: #28a745;cursor: pointer;">{{'lang_active'|language}}</span> 
													<span *ngIf="item?.employee_status == 0" (click)="performStatus(1,item.employee_number, item.branch_id)" style="color: #dc3545;cursor: pointer;">{{'lang_inactive'|language}}</span> 
												</td>
											</tr>
										</table>
									</div>
								</div>   
							</div>
						</mat-card-content>
					</mat-card>
				</div>
			</ng-container>
			 
		</div>
		<no-data style="margin: auto;"  [Opened]="EmployeeData.length == 0 "></no-data>
		<div class="row pt-2">
			<div
				class="col-12 col-sm-4 d-flex align-items-center"
				*ngIf="EmployeeData.length != 0"
			>
			<div *ngIf="ds.allowMe('human_resources', 'human_resources_delete')" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mr-3">
				<i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataHR()"  matTooltip="{{'lang_delete' | language }}"></i>
			</div>
				<p style="font-size: 16px; margin: 0">
					{{ "lang_total_records" | language }}: {{ collection_size }}
				</p>
			</div>
			<div
				class="col-12 col-sm-6 mt-2 mt-sm-0 d-flex align-items-center justify-content-center"
				*ngIf="EmployeeData.length != 0"
			>
				<ngb-pagination
					[collectionSize]="collection_size"
					[(page)]="page_no"
					[maxSize]="paginationMaxSize"
					[boundaryLinks]="true"
					[rotate]="true"
					(pageChange)="onPageChange(currentPage)"
				></ngb-pagination>
			</div>
			<div
				class="col-7 col-sm-2 mt-2 mt-sm-0 d-flex align-items-center justify-content-end"
				*ngIf="EmployeeData.length != 0"
			>
				<mat-select
					[(value)]="page_size"
					(selectionChange)="load_by_page_size($event)"
				>
					<mat-option [value]="10"
						>10 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option [value]="20"
						>20 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option [value]="50"
						>50 {{ "lang_per_page" | language }}</mat-option
					>
					<mat-option [value]="100"
						>100 {{ "lang_per_page" | language }}</mat-option
					>
				</mat-select>
			</div>
		</div>
	</div>
	<div class="col-4" *ngIf="showFilter && !isMob && !showSort">
		<app-filter-employee-card [projects]="projects" [branches]="branches" (filterParams)="filterParams($event)"></app-filter-employee-card>
	</div>
	<div class="col-4" *ngIf="!showFilter && !isMob && showSort">
		<app-sort-employee-card (sortParams)="sortParams($event)"></app-sort-employee-card>
	</div>
</div>
<ng-template #signatureform>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_upload_signature' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefC.hide();">&times;</button>
        </div>
        <form class="signform" [formGroup]="signform" (ngSubmit)="upload_signature()">
            <div class="modal-body">
                <input type="hidden" formControlName="id" required />
                <input type="hidden" formControlName="branch_id" required />
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="signature">{{'lang_signature' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <div class="IDDIV">
                                    <input name="signature" type="file" required onclick="this.value = null" (change)="uploadfile($event,signform.get('signature'),'sign' )" class="nghide albutton width100" accept=".png">
                                    <label id="uploadIDButton" class="albutton">{{signfilename}} </label>
                                    <mat-error *ngIf="signform.controls['signature'].touched && signform.controls['signature'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="mark">{{'lang_mark' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <div class="IDDIV">
                                    <input name="mark" type="file" required onclick="this.value = null" (change)="uploadfile($event,signform.get('mark'),'mark')" class="nghide albutton width100" accept=".png">
                                    <label id="uploadIDButton" class="albutton">{{markfilename}} </label>
                                    <mat-error *ngIf="signform.controls['mark'].touched && signform.controls['mark'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefC.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>