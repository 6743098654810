import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any
@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {
  @Output() showContract = new EventEmitter();
  @Input() able2edit;
  @Input() able2delete;
  @Input() able2add;
  @Input() id;
  @Input() branch_id;
  @Input() key;
  @Input() types;
  @Input() requestfor;
  @Input() user_data;
  @Input() attachments;
  csearchKey = "";
  wholedata: any = [];
  modalRefV: BsModalRef;
  expandstatus = false;
  page = 1;
  size = 10;
  errormessagef: any = this.lang.transform('lang_loading');
  viewdata:any = [];
  constructor(public router:Router, public ds:DataService,public route :ActivatedRoute, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.expandstatus = (this.requestfor == 'profile') ? true : false;
    this.search_employee_contracts();
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  public load_page_size(pageno) {
    this.size = pageno;
    this.get_employee_contracts();
  }
  public page_length(page) {
    this.page = page;
    this.get_employee_contracts();
  }
  public search_employee_contracts() {
    this.size = 10;
    this.page = 1;
    this.get_employee_contracts();
  }
  public get_employee_contracts() {
    const param = new FormData();
    param.append("search_key", this.csearchKey || '');
    param.append("id", this.id);
    let url = (this.requestfor === "profile") ? 'get_profile_contract_lists' : 'hrcontracts';
    this.ds.getActionByUrl(this.ds.formData2string(param), url+'/'+this.page+'/'+this.size).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.wholedata = data;
      } else {
        this.wholedata = [];
        this.errormessagef = data.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholedata = [];
      this.errormessagef = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public loadContract(data, template) {
    this.viewdata = [];
    const param = new FormData();
    param.append("id", this.id);
    param.append("contract_id", data.contract_id);
    let url = (this.requestfor === "profile") ? 'get_profile_contract_lists' : 'hrcontracts';
    this.ds.getActionByUrl(this.ds.formData2string(param), url+'/1/1').subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.openModalV(template);
        this.viewdata = data;
      } else {
        this.ds.dialogf('', data.error);
      }
    }, error => {
      this.spinner.hide();
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public checkAllContr(event) {
    if (event.target.checked) {
        $(".appcontracts .eachItemContr").prop("checked", true);
        $(".appcontracts .makeback").addClass("bground");
        $(".appcontracts .deleteIconDiv").show();
    } else {
        $(".appcontracts .eachItemContr").prop("checked", false);
        $(".appcontracts .makeback").removeClass("bground");
        $(".appcontracts .deleteIconDiv").hide();
    }
  }
  public checkEachItemContr(event) {
      if (event.target.checked) {
          const val = event.target.value;
          $(".appcontracts .removerow" + val).addClass("bground");
      } else {
          const val = event.target.value;
          $(".appcontracts .removerow" + val).removeClass("bground");
      }
      const checkedtotal = [];
      $(".appcontracts .eachItemContr:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      const totalrow = [];
      $(".appcontracts .eachItemContr").each(function() {
          totalrow.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          $(".appcontracts .deleteIconDiv").show();
      } else {
          $(".appcontracts .deleteIconDiv").hide();
      }
      if (checkedtotal.length === totalrow.length) {
          $(".appcontracts #allItemsContr").prop("checked", true);
      } else {
          $(".appcontracts #allItemsContr").prop("checked", false);
      }
  }
  public deleteBulkDataContr() {
      const checkedtotal = [];
      $(".appcontracts .eachItemContr:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          const dialogRef = this.dialog.open(ConfirmationDialog, {
              disableClose: true,
              width: '400px',
              data: {
                  title: this.lang.transform('lang_confirmation'),
                  message: this.lang.transform('lang_are_you_sure'),
              }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                  this.spinner.show()
                  this.ds.deleteActionByUrl(checkedtotal, "01f5086b879a62a05da4094dac203558/CONTRACT/"+this.id+'/'+this.branch_id).subscribe((data) => {
                          this.spinner.hide()
                          if (data.status) {
                              this.search_employee_contracts();
                              $(".appcontracts #allItemsContr").prop("checked", false);
                              $(".appcontracts .deleteIconDiv").hide();
                              this.ds.dialogf('', data.msg);
                          } else {
                              this.ds.dialogf('', data.error);
                          }
                      }, (error) => {
                          this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                          this.spinner.hide()
                      }
                  );
              }
          });
      } else {
          this.ds.dialogf();
      }
  }
}
