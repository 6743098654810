import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
interface list {
  id?: number;
  class : string,
  transaction_number: string,
  reference_number: string,
  transaction_date: string,
  description: string,
  debit: string,
  credit: string,
  balance: string,
  status: string,
}
@Component({
  selector: 'app-statement-of-accounts',
  templateUrl: './statement-of-accounts.component.html',
  styleUrls: ['./statement-of-accounts.component.scss']
})
export class StatementOfAccountsComponent implements OnInit {
  public account_id = this.route.snapshot.paramMap.get('account_id');
  page = 1;
  pageSize = 20;
  collectionSize;
  listofdatas : list[];
  form:FormGroup;
  branches:any = [];
  finances:any = [];
  lodingdatas = this.lang.transform('lang_loading');
  fromaccounts:any = [];
  finance_id:any = "";
  datastatus = false;
  data:any = [];
  settings = environment.singledropdown;
  branch_id: any = (localStorage.getItem('selectedBranch') != null) ? localStorage.getItem('selectedBranch') : '0';
  constructor(public ds:DataService,public ls:LocalStorage ,public route : ActivatedRoute, public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.buildform();
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != '0') {
      this.load_financial_years();
      this.searchAccounts();
    }
  }
  public selectBrach(branch) {
    this.data = [];
    this.datastatus = false;
    if (branch && branch != '0') {
      localStorage.setItem('selectedBranch', branch);
      this.branch_id = branch;
      this.load_financial_years();
      this.searchAccounts();
    } else {
      this.spinner.hide();
    }
  }
  public selectFinanceYear(financeid) {
    if (financeid && financeid != '0') {
      this.finance_id = financeid;
    }
  }  
  public load_branches() {
    this.ds.getActionByUrl([], 'ab/accounts_view').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.branches = res.records;
      } else {
        this.branches = [];
      }
    }, error => {
      this.spinner.hide();
      this.branches = [];
    })
  }
  public load_financial_years() {
    this.finances = [];
    this.form.get('finance_id').setValue('');
    this.ds.getActionByUrl([], 'financial/'+this.branch_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.finances = res.records;
        this.finances.forEach((v, k) => {
          if (v.finance_status == "1") {
            this.form.get('finance_id').setValue(v.finance_id);
            this.form.get('period_from').setValue(v.finance_start_date);
            this.form.get('period_to').setValue(v.finance_end_date);
            if(this.account_id) {            
              this.fetch_statements();
            }
          }
        });
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      finance_id: new FormControl(null, [Validators.required]),
      request_type: new FormControl('0', [Validators.required]),
      account_code_from: new FormControl((this.account_id) ? this.account_id : null),
      account_code_to: new FormControl((this.account_id) ? this.account_id : null),
      period_from: new FormControl(null, [Validators.required]),
      period_to: new FormControl(null, [Validators.required]),
    })
  }
  public searchAccounts() {
    this.fromaccounts = [];
    let param = new FormData();
    param.append('branch_id', this.branch_id);
    param.append('status', '0');
    if (this.branch_id) {
      this.ds.postActionByUrl(param, "sam/accounts_view").subscribe(data => {
        if (data.status) {
          this.fromaccounts = data.records;
        } else {
          this.fromaccounts = [];
        }
      }, error => {
        this.fromaccounts = [];
      });
    }
  }
  public fetch_statements() {
    if(this.form.valid) {
      this.page       = 1;
      this.pageSize   = 20;
      this.spinner.show();
      let param = new FormData();
      param.set('branch_id', this.form.get('branch_id').value);
      param.set('finance_id', (this.form.get('finance_id').value) ? this.form.get('finance_id').value : '0');
      param.set('request_type', this.form.get('request_type').value);
      if (this.form.get('request_type').value == '0') {
        param.set('account_code_from', (this.form.get('account_code_from').value) ? this.form.get('account_code_from').value : '');
        param.set('account_code_to', (this.form.get('account_code_to').value) ? this.form.get('account_code_to').value : '');
      }
      param.set('period_from', (this.form.get('period_from').value != null) ? this.form.get('period_from').value : '');
      param.set('period_to', (this.form.get('period_to').value != null) ? this.form.get('period_to').value : '');
      this.ds.postActionByUrl(param, 'stmtofacc').subscribe(res => {
        this.datastatus = res.status;
        this.spinner.hide();
        if (res.status && res.records.length > 0) {
          this.listofdatas = res.records;
          this.collectionSize = res.records.length;
          this.refreshLists();
        } else {
          this.ds.dialogf('', res.error);
          this.lodingdatas = res.error;
          this.listofdatas= [];
          this.data = [];
        }
      }, error => {
          this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
          this.lodingdatas = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
          this.datastatus = false;
          this.listofdatas = [];
          this.data = [];
        this.spinner.hide();
      })
    } else {
      this.ds.dialogf('', this.lang.transform('lang_fill_required_fields'))
    }    
  }
  public refreshLists() {
    if(this.listofdatas.length > 0) {
      this.data = this.listofdatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    }
  }
}
