import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import * as moment from 'moment';

declare var $:any;
@Component({
  selector: 'loan-step-one-configrations',
  templateUrl: './step-one-configrations.component.html',
  styleUrls: ['./step-one-configrations.component.scss']
})
export class LoanStepOneConfigrationsComponent implements OnInit {
  @Output() loanEvent = new EventEmitter();
  @Input() employee_data;
  @Input() form_request_id;
  @Input() form_key;
  @Input() allData;
  users:any         = [];
  direct_anager:any = [];
  CreateSubmitted:boolean = false;
  min = new Date();
  form:FormGroup;
  submitted:boolean = false;
  lang_key          = localStorage.getItem('lang_key') || 'en';
  filename = this.lang.transform('lang_select_files');
  isFile: boolean = false;
  reasons:any[] = [
    {
      label:'سلفة لغرض سداد رسوم المرافقين',
      value:'سلفة لغرض سداد رسوم المرافقين'
    },
    {
      label:'سلفة لغرض الزواج',
      value:'سلفة لغرض الزواج',
    },
    {
      label:'سلفة لغرض ترقية فئة التأمين',
      value:'سلفة لغرض ترقية فئة التأمين',
    },
    {
      label:'سلفة لغرض رسوم مدارس',
      value:'سلفة لغرض رسوم مدارس',
    },
    {
      label:'سلفة لغرض خاص',
      value:'سلفة لغرض خاص',
    },
    {
      label:'سلفة لأسباب اخرى',
      value:'سلفة لأسباب اخرى',
    },
];
attachments: FormArray;
attachstatus: boolean = false;
showAttachment = true;
emp_data:any=''


  constructor(public ds:DataService, public datepipe : DatePipe ,
    private snackBar:MatSnackBar,
    public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.spinner.show();
    let data = new FormData();
    
    data.append('employee_number', this.allData?.form_hrln1_data?.records[0]?.employee_number);
 
    this.ds.post(`human_resources/get_emp_details`,data).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.emp_data = res.records
      } else {
  
        this.alert.error(res.error);
      }
    }, (err) => {
      this.spinner.hide();
      /* this.alert.error(res.error); */
    })


    


    this.ds.get(`form/FORM_HRLN1/get_loan_reasons`,data).subscribe(res => {
      this.spinner.hide();
   
   if(res.status) {
      this.reasons =  res.records 
   } else {
        this.alert.error(res.error);
   } 
    }, (err) => {
      this.spinner.hide();
      /* this.alert.error(res.error); */
    })
   
    this.form = this.fb.group({
      financial_type:[this.lang.transform('lang_loan')],
      employee_number: [this.allData?.form_hrln1_data?.records[0]?.employee_number],
      loan_date_english: ['', Validators.required],
      loan_date_arabic: ['', Validators.required],
      loan_amount: ['', Validators.required],
      direct_manager:['', [Validators.required]],
      reasons:['', [Validators.required]],
      needAttach  : new FormControl('0', [Validators.required]), 
      attachments : this.fb.array([]),
      createInstallments: this.fb.group({  
        amount:['', Validators.compose([ Validators.required ])],
        due_month:['', Validators.compose([])],
        due_year:['', Validators.compose([])],
        error_message :[''],
        month:['', Validators.compose([Validators.required])],
        month_salary:['', Validators.compose([])],
       
    } ),
      installments: this.fb.array([])
   
    });

    moment.locale('en')
    this.form.patchValue({
      employee_number:this.allData?.form_hrln1_data?.records[0]?.employee_number,
      loan_date_english:moment(this.allData?.form_hrln1_data?.records[0]?.loan_date_english,'YYYY/MM/DD').format(),
      loan_date_arabic:this.allData?.form_hrln1_data?.records[0]?.loan_date_arabic,
      loan_amount:this.allData?.form_hrln1_data?.records[0]?.loan_amount,
      direct_manager:this.allData?.form_hrln1_data?.records[0]?.direct_manager,
      reasons:+this.allData?.form_hrln1_data?.records[0]?.loan_reason,
    })
    this.users = [{
      label:this.allData?.form_hrln1_data?.records[0]?.direct_manager_name,
      value:this.allData?.form_hrln1_data?.records[0]?.direct_manager}]

      this.getLoanDatesAccual(this.allData?.form_hrln1_data?.records[0]?.loan_date_english)
  
      /* 
      const creds = this.form.get("installments") as FormArray;
      for (let index = 0; index < this.allData?.hrln1_installments.length; index++) {
        const element = this.allData?.hrln1_installments[index];
     

        creds.push(
          this.fb.group({
            amount:[element.amount, Validators.compose([ Validators.required])],
            due_month:[element.due_month, Validators.compose([])],
            due_year:[element.due_year, Validators.compose([])],
            error_message:[''],
            month:[element.month, Validators.compose([Validators.required])],
            month_salary:[element.final_conditional_salary+element.amount , Validators.compose([])],
            trackingId: this.generateUniqueId()
         
        })
        );
      } */
      this.attachments = this.form.get("attachments") as FormArray;
    this.spinner.hide();
    console.log(this.form);
    
  }
  public enable_field(field) {
  
    if (field == 'attachment') {
      this.attachments.clear();
      this.form.get('needAttach').setValue('1');
      this.addfiles();
      this.attachstatus = true;
    }
  }

  public files(): FormGroup {
    return this.fb.group({
      attach_title: new FormControl('', (this.form.get('needAttach').value == "1") ?[Validators.required] : null),
      file: new FormControl('', (this.form.get('needAttach').value == "1") ? [Validators.required] : null)
    });
  }
  public addfiles() {
    if (this.attachments.length < 5) {
      this.attachments.push(this.files());
    }
  }
  public removefiles(index) {
    if (this.attachments.length > 1) {
      this.attachments.removeAt(index);
    }
  }

  public getfilesgroup(index): FormGroup {
    const formGroup = this.attachments.controls[index] as FormGroup;
    return formGroup;
  }

  public UploadFile(event, index) {
    if (event.target.files[0] && event.target.files[0] !== undefined && event.target.files[0].name) {
      const fileid = event.target.files[0];
      $("#uploadText" + index).text(fileid.name);
      this.attachments.controls[index].get('file').setValue(fileid, { emitEvent: true });
    } else {
      this.attachments.controls[index].get('file').setValue(null);
      $("#uploadText" + index).text(this.lang.transform('lang_select_files'));
    }
  }
  public uploadFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      this.isFile = true;
      const fileid = event.target.files[0];
      this.filename = fileid.name;
      this.form.get("upload").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.form.get("upload").setValue(null);
      this.filename = this.lang.transform('lang_select_files');
    }
  }
  public removeFile() {
    this.isFile = false;
    this.form.get("upload").setValue(null);
    this.filename = this.lang.transform('lang_select_files');
  }
  public disable_field(field) {
    /* if (field == 'mark') {
      this.createVactionForm.get('needMark').setValue('0');
      this.createVactionForm.get('marks').setValue(null);
      this.createVactionForm.get('marks').clearValidators();
      this.createVactionForm.get('marks').updateValueAndValidity();
      this.markstatus = false;
    } else if (field == 'review') {
      this.createVactionForm.get('needReview').setValue('0');
      this.createVactionForm.get('reviews').setValue(null);
      this.createVactionForm.get('reviews').clearValidators();
      this.createVactionForm.get('reviews').updateValueAndValidity();
      this.reviewstatus = false;
    } else  */
    if (field == 'attachment') {
      this.attachments.clear();
      this.form.get('needAttach').setValue('0');
      this.isFile = false;
      this.attachstatus = false;
    }
    this.form.updateValueAndValidity();
  }
  get filesFormGroup() {
    return this.form.get("attachments") as FormArray;
  }
  getLoanDates(event){
    console.log(event);
    for (let index = 0; index < this.allData?.hrln1_installments.length; index++) {
      const element = this.allData?.hrln1_installments[index];
      this.fillFormWithData(element)
    }
  }


  getLoanDatesAccual(date){
  
    this.spinner.show();
    let formData = new FormData();
    formData.append('employee_number',this.allData?.form_hrln1_data?.records[0]?.employee_number);
    
    formData.append('loan_date',date);  
   
    
    this.ds.post(`form/${this.form_key}/get_contract_months`, formData).subscribe(data => {
      if(data.status) {
        
        this.alert.success(data.msg);
        this.monthes = data['records'].map(mon=>{
          return {
            label:mon,
            value:mon
          }
        });
        this.monthesAfterSearch = data['records'].map(mon=>{
          return {
            label:mon,
            value:mon
          }
        });

        this.getLoanDates(moment(this.allData?.form_hrln1_data?.records[0]?.loan_date_english,'YYYY/MM/DD'))
      
        this.spinner.hide();
     
      } else {
        this.spinner.hide();
        this.alert.error(data?.error);
      }
    }, (error) => {
      this.spinner.hide();
        this.alert.error(error);
    })

  }

  getThisMonthSalary(event){
    this.spinner.show();
    let formData = new FormData();
    formData.append('employee_number',this.allData?.form_hrln1_data?.records[0]?.employee_number);
   
    
    formData.append('finance_year',moment(event.value,'MM-YYYY').format('YYYY'));  
    formData.append('finance_month',moment(event.value,'MM-YYYY').format('MM'));  
    this.ds.post(`form/${this.form_key}/check_salary_for_month`, formData).subscribe(data => {
      /* if(data.status) {
        
      
     
      } else {
        this.spinner.hide();
        this.alert.error(data?.error);
      } */

      this.alert.success(data.msg);
      this.form.controls.createInstallments.patchValue({
        ...this.form?.controls?.createInstallments.value ,
        month_salary: data.salary
      })
      console.log(this.form);
      
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
        this.alert.error(error);
    })
  }
 
  monthes = []

  monthesAfterSearch = []

  checkFor50Percent(){
    const val = this.form.get("createInstallments") as FormGroup;
    /* if(val.value.amount && val.value.month_salary && +val.value.amount > (+val.value.month_salary) ){
      
     val.patchValue({
       ...val.value,
       amount: Number(val.value.amount.toString().slice(0, -1)) > val.value.month_salary? val.value.month_salary/2 : val.value.amount.toString().slice(0, -1) ,
       error_message:'lang_you_must_not_enter_num_bigger_than_salary_error_message'
     })
     console.log(val);
      
   }else  */if(val.value.amount && val.value.month_salary && +val.value.amount > (+val.value.month_salary/2)){
    val.patchValue({
      ...val.value,
      error_message:'lang_50_percent_error_message'
    })
   }else{
    val.patchValue({
      ...val.value,
      error_message:''
    })
   }
  }

  getControl(index:number){
    
    return this.form.controls.installments['controls'][index] as FormGroup
  }


  getItems(index:any){

   let data = this.form.get("installments") as FormArray;
 
   return this.monthesAfterSearch.push({
      label:data.value[index].month,
      value:data.value[index].month
    })
  }


  fillFormWithData(data) {
 
    const creds = this.form.get("installments") as FormArray;
  

    console.log('data',data);
    
    creds.push(
      this.fb.group({
        amount:[data.amount, Validators.compose([ Validators.required])],
        due_month:['', Validators.compose([])],
        due_year:['', Validators.compose([])],
        error_message:[data.overdyed_half_salary == 1 ?'lang_50_percent_error_message':''],
        month:[data.due_month+'/'+data.due_year, Validators.compose([Validators.required])],
        month_salary:[+data.amount + (+data.final_conditional_salary), Validators.compose([])],
        trackingId: this.generateUniqueId()
     
    })
    );

 
    console.log('monthesAfterSearch',this.monthesAfterSearch);
       
  this.monthesAfterSearch =this.monthesAfterSearch.filter(d=>{
      console.log('d.value',d.value);
      console.log('data.due_year',`${data.due_month}-${data.due_year}`);
      return d.value != `${data.due_month}-${data.due_year}`
    })  
    
 
 
  }
 
  addInstallments() {
    this.CreateSubmitted  = true;
    if(this.form?.controls?.createInstallments.status == 'VALID'){


      if(!this.form?.value.loan_amount){
        this.showMsg('Please enter the loan you want first', 'ok', 'error-snackbar');
        return 0;
      }

      const creds = this.form.get("installments") as FormArray;
     
      const val = this.form.get("createInstallments") as FormGroup;
      
      let totalValuesOfLoanPerMonthes = 0
      for (let index = 0; index < creds.length; index++) {
        const element = creds['controls'][index].value;
        totalValuesOfLoanPerMonthes += Number(element.amount)
      }

      if(this.form?.value.loan_amount && totalValuesOfLoanPerMonthes >this.form?.value.loan_amount ){
        this.showMsg('Total amount values at all monthes must be equal to your loan value', 'ok', 'error-snackbar');
        return 0;
      }



      if(val.value.amount && val.value.month_salary && +val.value.amount > (+val.value.month_salary)){
        this.showMsg('Your amout must not be bigger than your salary!', 'ok', 'error-snackbar');
        return 0;
       }


      creds.push(
        this.fb.group({
          amount:[val.value.amount, Validators.compose([ Validators.required])],
          due_month:[val.value.due_month, Validators.compose([])],
          due_year:[val.value.due_year, Validators.compose([])],
          error_message:[''],
          month:[val.value.month, Validators.compose([Validators.required])],
          month_salary:[val.value.month_salary, Validators.compose([])],
          trackingId: this.generateUniqueId()
       
      })
      );
 
    
     
      this.monthesAfterSearch =this.monthesAfterSearch.filter(data=>{
        return data.value != val.value.month
      })
      val.patchValue({
        month:this.monthesAfterSearch[0] ? this.monthesAfterSearch[0].value:''
      })
      val.reset()
      this.CreateSubmitted  = false;
      console.log(this.form);
      
    }else{
      this.showMsg('Please fill all fields first', 'ok', 'error-snackbar');
    }
 
  }

  
  showMsg(msg:string, action:string, className:string) {
    this.snackBar.open(msg, action, {
      duration:4000,
      panelClass: [className]
    });
  }
  trackByFnCustom(index: number, item: any) {
    return item.trackingId;
  }
  generateUniqueId() {
    return (
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
    );
  }
 
  mon

  deleteCreds(index,cred){
     
   
 
    const creds = this.form.get("installments") as FormArray;
 
       this.monthesAfterSearch  = [...this.monthesAfterSearch,{
        "label":cred.month,
        "value":cred.month
      }]
       console.log('this.monthesAfterSearch',this.monthesAfterSearch);
       creds.removeAt(index);
  
    
         console.log(this.form);
     
  
         this.changeDetectorRef.detectChanges()
     
   
   }
 



   
  public isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if(charCode == 101 ||charCode == 69){
      return false;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}

submitForm(){

  this.form.controls.createInstallments.disable();
  if(this.form?.status == 'VALID'){

 

    const creds = this.form.get("installments") as FormArray;
   
    const val = this.form.get("createInstallments") as FormGroup;
    
    let totalValuesOfLoanPerMonthes = 0
    for (let index = 0; index < creds.length; index++) {
      const element = creds['controls'][index].value;
      totalValuesOfLoanPerMonthes += Number(element.amount)
    }

    if(this.form?.value.loan_amount && totalValuesOfLoanPerMonthes != this.form?.value.loan_amount ){
      this.showMsg('Total amount values at all monthes must be equal to your loan value', 'ok', 'error-snackbar');
      this.form.controls.createInstallments.enable();
      return 0;
    }


    




    this.spinner.show();   
    let formData = new FormData();

    this.form.value.loan_amount = +this.form.value.loan_amount
    formData.append('transaction_request_id',this.allData?.form_hrln1_data?.records[0]?.transaction_request_id);

    formData.append('employee_number',this.allData?.form_hrln1_data?.records[0]?.employee_number);
    formData.append('loan_date_english',moment(this.form.value.loan_date_english,'YYYY/MM/DD').format('YYYY/MM/DD'));
    formData.append('loan_date_arabic',this.form.value.loan_date_arabic);
    formData.append('loan_amount',this.form.value.loan_amount);
    formData.append('direct_manager', this.form.value.direct_manager || '');
    this.form.value.installments.forEach((installment,index) => {
      console.log('installment',installment);
      
      formData.append(`installments[${index+1}][amount]`,installment.amount);
      formData.append(`installments[${index+1}][due_year]`,moment(installment.month,'MM-YYYY').format('YYYY'));  
      formData.append(`installments[${index+1}][due_month]`,moment(installment.month,'MM-YYYY').format('MM'));  

    });
    
    formData.append('loan_reason',this.form.value.reasons);
    this.attachments.controls.forEach((item, index) => {
      formData.append(`attachments[${index+1}][file]`, item.get('file').value);
      formData.append(`attachments[${index+1}][attach_title]`, item.get('attach_title').value);
    });
 
    this.ds.post(`form/${this.form_key}/et`, formData).subscribe(data => {
     if(data.status) {
        
      
      this.showMsg(data.msg, 'ok', 'success-snackbar');
   
      //this.form.reset()
      setTimeout(() => {
        location.reload();
      }, 1000);
      this.spinner.hide();
      
      } else {
        this.spinner.hide();
        this.showMsg(data.error, 'ok', 'error-snackbar');
      }  

    
    }, (error) => {
      this.spinner.hide();
        this.alert.error(error);
    })
 
 
    
  }else{
    this.form.controls.createInstallments.enable();
    this.showMsg('Please fill all fields first', 'ok', 'error-snackbar');
  }
}


public search_users(key) {
  if(key){
    this.users = [];
    let param = new FormData();
    param.append('search', key.term);
    param.append('lang_key', this.lang_key);
    param.append('user_type_id', '1');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.users = res.list;
      }
    })
  }

}

}
