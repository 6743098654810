import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountMasterListComponent } from './account-masters/account-master-list/account-master-list.component';
import { CostCenterListComponent } from './cost-center/cost-center-list/cost-center-list.component';
import { AccountTrialComponent } from './reports/accounts/account-trial/account-trial.component';
import { BalanceSheetsComponent } from './reports/accounts/balance-sheets/balance-sheets.component';
import { GeneralLedgerComponent } from './reports/accounts/general-ledger/general-ledger.component';
import { IncomeStatementComponent } from './reports/accounts/income-statement/income-statement.component';
import { ProfitLossStatementComponent } from './reports/accounts/profit-loss-statement/profit-loss-statement.component';
import { StatementOfAccountsComponent } from './reports/accounts/statement-of-accounts/statement-of-accounts.component';
import { TransactionListingComponent } from './reports/accounts/transaction-listing/transaction-listing.component';
import { TrialBalanceComponent } from './reports/accounts/trial-balance/trial-balance.component';
import { CostSummaryComponent } from './reports/costs/cost-summary/cost-summary.component';
import { StatementOfCostsComponent } from './reports/costs/statement-of-costs/statement-of-costs.component';
import { SettingsComponent } from './settings/settings.component';
import { JournalAddComponent } from './transactions/journal/journal-add/journal-add.component';
import { JournalEditComponent } from './transactions/journal/journal-edit/journal-edit.component';
import { JournalListComponent } from './transactions/journal/journal-list/journal-list.component';
import { JournalViewComponent } from './transactions/journal/journal-view/journal-view.component';
import { PaymentAddComponent } from './transactions/payments/payment-add/payment-add.component';
import { PaymentEditComponent } from './transactions/payments/payment-edit/payment-edit.component';
import { PaymentListComponent } from './transactions/payments/payment-list/payment-list.component';
import { PaymentViewComponent } from './transactions/payments/payment-view/payment-view.component';
import { PinvoiceAddComponent } from './transactions/purchase-invoices/pinvoice-add/pinvoice-add.component';
import { PinvoiceEditComponent } from './transactions/purchase-invoices/pinvoice-edit/pinvoice-edit.component';
import { PinvoiceListComponent } from './transactions/purchase-invoices/pinvoice-list/pinvoice-list.component';
import { PinvoiceViewComponent } from './transactions/purchase-invoices/pinvoice-view/pinvoice-view.component';
import { ReceiptAddComponent } from './transactions/receipts/receipt-add/receipt-add.component';
import { ReceiptEditComponent } from './transactions/receipts/receipt-edit/receipt-edit.component';
import { ReceiptListsComponent } from './transactions/receipts/receipt-lists/receipt-lists.component';
import { ReceiptViewComponent } from './transactions/receipts/receipt-view/receipt-view.component';
import { SinvoiceAddComponent } from './transactions/selling-invoices/sinvoice-add/sinvoice-add.component';
import { SinvoiceEditComponent } from './transactions/selling-invoices/sinvoice-edit/sinvoice-edit.component';
import { SinvoiceListComponent } from './transactions/selling-invoices/sinvoice-list/sinvoice-list.component';
import { SinvoiceViewComponent } from './transactions/selling-invoices/sinvoice-view/sinvoice-view.component';
import { TransactionViewerComponent } from './transactions/transaction-viewer/transaction-viewer.component';
const routes: Routes = [
  {
    path:'asettings',
    component:SettingsComponent,
    
  },
  {
    path: 'aam',
    component: AccountMasterListComponent,
    
  },
  {
    path: 'acc',
    component: CostCenterListComponent,
    
  },
  // Routes For Receipt Starts
  {
    path: 'reclist',
    component: ReceiptListsComponent,
    
  },
  {
    path: 'reccreate',
    component: ReceiptAddComponent,
    
  },
  {
    path: 'recedit/:bid/:rid/:key',
    component: ReceiptEditComponent,
    
  },
  {
    path: 'recview/:bid/:rid/:key',
    component: ReceiptViewComponent,
    
  },
  // Routes For Receipt End
  // Routes For Payment Starts
  {
    path: 'paylist',
    component: PaymentListComponent,
    
  },
  {
    path: 'paycreate',
    component: PaymentAddComponent,
    
  },
  {
    path: 'payedit/:bid/:pid/:key',
    component: PaymentEditComponent,
    
  },
  {
    path: 'payview/:bid/:pid/:key',
    component: PaymentViewComponent,
    
  },
  // Routes For Payment End
  // Routes For Journal Vouchers Starts
  {
    path: 'jlist',
    component: JournalListComponent,
    
  },
  {
    path: 'jcreate',
    component: JournalAddComponent,
    
  },
  {
    path: 'jedit/:bid/:jid/:key',
    component: JournalEditComponent,
    
  },
  {
    path: 'jview/:bid/:jid/:key',
    component: JournalViewComponent,
    
  },
  // Routes For Journal Vouchers End
  // Routes For Selling Invoices Start
    {
      path: 'sinvoices',
      component: SinvoiceListComponent,
      
    },
    {
      path: 'csinvoices',
      component: SinvoiceAddComponent,
      
    },
    {
      path: 'esinvoices/:bid/:iid/:key',
      component: SinvoiceEditComponent,
      
    },
    {
      path: 'vsinvoices/:bid/:iid/:key',
      component: SinvoiceViewComponent,
      
    },
  // Routes For Selling Invoices End
  // Routes For Purchase Invoices Start
    {
      path: 'pinvoices',
      component: PinvoiceListComponent,
      
    },
    {
      path: 'cpinvoices',
      component: PinvoiceAddComponent,
      
    },
    {
      path: 'epinvoices/:bid/:iid/:key',
      component: PinvoiceEditComponent,
      
    },
    {
      path: 'vpinvoices/:bid/:iid/:key',
      component: PinvoiceViewComponent,
      
    },
  // Routes For Purchase Invoices End
  // Transaction Viewer Starts
    {
      path: 'tviewer',
      component: TransactionViewerComponent,
      
    },
    // Reports
    {
      path: 'statement_of_accounts',
      component: StatementOfAccountsComponent,
      
    },
    {
      path: 'statement_of_accounts/:account_id',
      component: StatementOfAccountsComponent,
      
    },
    {
      path: 'trial_balance',
      component: TrialBalanceComponent,
      
    },
    {
      path: 'accounts_trial',
      component: AccountTrialComponent,
      
    },
    {
      path: 'general_ledger',
      component: GeneralLedgerComponent,
      
    },
    {
      path: 'balance_sheets',
      component: BalanceSheetsComponent,
      
    },
    {
      path: 'profit_loss',
      component: ProfitLossStatementComponent,
      
    },
    {
      path: 'income_statements',
      component: IncomeStatementComponent,
      
    },
    {
      path: 'transaction_listing',
      component: TransactionListingComponent,
      
    },
    {
      path: 'statement_of_costs/:account_id',
      component: StatementOfCostsComponent,
      
    },
    {
      path: 'statement_of_costs',
      component: StatementOfCostsComponent,
      
    },
    {
      path: 'cost_summary',
      component: CostSummaryComponent,
      
    },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountsRoutingModule { }
