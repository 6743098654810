import { ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-rstep8',
  templateUrl: './rstep8.component.html',
  styleUrls: ['./rstep8.component.scss']
})
export class Rstep8Component implements OnInit {
  @Input() signupForm: FormGroup;
  @Input() action;
  @Input() files;
  public fileurl;
  apiurl = environment.SERVER_ORIGIN;
  allowsubmit = false;
  idfilename = this.lang.transform('lang_select_files');
  isFile: boolean;
  @ViewChild('sPad', { static: true }) signaturePadElement;
  @ViewChild('mPad', { static: true }) markPadElement;
  @ViewChild('padElement', { static: true }) padElement: any;
  width: any = 900;
  height: any = 450;
  signaturePad: any;
  markPad: any;
  lang_key = localStorage.getItem('lang_key') || 'en';
  constructor(private cdRef: ChangeDetectorRef, public fb:FormBuilder ,public ds: DataService, public route:ActivatedRoute ,public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  get f() {
    return this.signupForm.controls.signatureDetails;
  }
  get mob() {
    return this.signupForm.get('contactDetails').get('primary_mobile').value
  }
  get eml() {
    return this.signupForm.get('contactDetails').get('primary_email').value
  }
  ngOnInit(): void {}
  @HostListener('window:resize', ['$event'])
  canvassize(event?) {
    this.width = this.padElement.nativeElement.offsetWidth;
    this.height = this.padElement.nativeElement.offsetHeight;
    this.cdRef.detectChanges();
  }
  ngAfterViewInit(): void {
    this.canvassize();
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {
      minWidth: 5,
      maxWidth: 8,
      penColor: "rgb(63, 81, 181)",
      backgroundColor: 'rgba(255,255,255, 0)'
    });
    this.markPad = new SignaturePad(this.markPadElement.nativeElement, {
      minWidth: 5,
      maxWidth: 8,
      penColor: "rgb(63, 81, 181)",
      backgroundColor: 'rgba(255,255,255, 0)'
    });
    this.cdRef.detectChanges();
  }
  sendVerificationcode(method) {    
    this.allowsubmit = false;
    if (method){
      if (!this.signaturePad.isEmpty() && !this.markPad.isEmpty()) {
        this.signupForm.get('signatureDetails').get('signature').setValue(this.signaturePad.toDataURL());
        this.signupForm.get('signatureDetails').get('mark').setValue(this.markPad.toDataURL());
        if (this.signupForm.status === "VALID") {
          this.spinner.show();
          let mobile = this.signupForm.get('contactDetails').get('primary_mobile').value;
          let email = this.signupForm.get('contactDetails').get('primary_email').value;
          let id = this.signupForm.get('idDetails').get('employee_id_number').value;
          let value = (method === "email") ? email : mobile;
          let formData = new FormData();
          formData.append('method', method);
          formData.append('value', value);
          formData.append('employee_id_number', id);
          this.ds.postActionByNoToken(formData,'signup/sendcode/').subscribe(data => {
            this.spinner.hide();
            this.allowsubmit = data.status;
            if (data.status) {
            } else {
              this.signupForm.get('signatureDetails').get('verification_method').setValue('');
              this.alert.error(data.error);
            }
          }, error => {
            this.signupForm.get('signatureDetails').get('verification_method').setValue('');
            this.spinner.hide();
            this.allowsubmit = false;
            this.alert.error(error.error);
          })
        } else {
          this.signupForm.get('signatureDetails').get('verification_method').setValue('');
          this.alert.error(this.lang.transform('lang_fill_required_fields'));
        }
      } else {
        this.signupForm.get('signatureDetails').get('verification_method').setValue('');
        this.alert.error(this.lang.transform('lang_fill_required_fields'));
      }
    }
  }
  rstep8Submitted() {
    if(this.action !=="EDIT"){
      this.signupForm.get('signatureDetails').get('signature').markAsTouched();
      this.signupForm.get('signatureDetails').get('signature').updateValueAndValidity();
      this.signupForm.get('signatureDetails').get('mark').markAsTouched();
      this.signupForm.get('signatureDetails').get('mark').updateValueAndValidity();
      this.signupForm.get('signatureDetails').get('terms_conditions').markAsTouched();
      this.signupForm.get('signatureDetails').get('terms_conditions').updateValueAndValidity();
    }
  }
}
