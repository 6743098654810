<section class="panel-expansion quotation_requests_panel">
    <mat-accordion  class="stylepanels mb-0">
      <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
        <mat-expansion-panel-header class="mpanel-header">{{'lang_quotation_requests' | language}}</mat-expansion-panel-header>
        <div class="material-panel-body">
            <div class="mbrl15 martb0">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control almnabrformcontrol" (keyup)="search_my_quotation_requests()" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                            <span class="input-group-btn" *ngIf="able2add">
                                <button id="btnSearch" type="button" matTooltip="{{'lang_create_quotation_requests' | language }}" class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"></button>
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="table-responsive dropdownyes customResponsive">
                            <table border="1" class="table CustomTable">
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>Subject</td>
                                        <td>Type</td>
                                        <td>Title 1</td>
                                        <td>Title 2</td>
                                        <td>{{'lang_ondate' | language }}</td>
                                        <td>{{'lang_writer' | language }}</td>
                                        <td>{{'lang_action' | language }}</td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="myquotation_requests.length != '0'" class="AppendList">
                                    <tr *ngFor="let quotation_request of myquotation_requests; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{quotation_request.quotation_request_id}}">
                                        <td>{{i+1}}</td>
                                        <td class="breakall">{{quotation_request.quotation_requests_profile_id}}</td>
                                        <td class="breakall">{{quotation_request.quotation_request_title}}</td>
                                        <td class="breakall">{{quotation_request.branch_name}}</td>
                                        <td class="breakall">{{quotation_request.customer_type}}</td>
                                        <td class="breakall">{{quotation_request.quotation_requests_profile_created_datetime}}</td>
                                        <td class="breakall">{{quotation_request.writer}}</td>
                                        <td> 
                                            <i class="fa fa-external-link fa-icon mnabricon" matTooltip="{{'lang_go_to_quotation_request_view' | language}}"></i>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="myquotation_requests.length == '0'" class="AppendList">
                                    <tr class="odd">
                                        <td colspan="8" align="center">{{quotation_requesterrormsg}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="myquotation_requests.length != '0'" class="card-footer padb0 bottomfooter">
                <div class="row">
                    <div *ngIf="able2delete" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                        
                    </div>
                    <div class="col-lg-2 col-sm-6 col-6  mb-10">
                        {{'lang_total' | language }} : {{quotation_requestdata.page.total_records}}
                    </div>
                    <div class="col-lg-5 col-sm-6 col-12 mb-10">
                        <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="10" checked (click)="spinner.show();quotation_requestpage(10)"> 10 </mat-radio-button>
                            <mat-radio-button value="20" (click)="spinner.show();quotation_requestpage(20)"> 20 </mat-radio-button>
                            <mat-radio-button value="50" (click)="spinner.show();quotation_requestpage(50)"> 50 </mat-radio-button>
                            <mat-radio-button value="100" (click)="spinner.show();quotation_requestpage(100)"> 100 </mat-radio-button>
                            <mat-radio-button value="500" (click)="spinner.show();quotation_requestpage(500)"> 500 </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mb-10">
                        {{'lang_goto' | language }} :
                        <select class="customselect" (change)="spinner.show();quotation_requestlength($event.target.value)">
                            <option *ngFor="let k of ds.getrange(quotation_requestdata.page.total_pages)" value="{{k}}">{{k}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
</section>