import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { environment } from '@env/environment';
import { FilterEmployeeCardMobComponent } from './filter-card-mob/filter-card-mob.component';
import { SortEmployeeCardMobComponent } from './sort-card-mob/sort-card-mob.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from '@shared/components/alert/alert.service';
declare var $:any;

@Component({
  selector: 'app-AllEmployees',
  templateUrl: './AllEmployees.component.html',
  styleUrls: ['./AllEmployees.component.scss']
})
export class AllEmployeesComponent implements OnInit {

  lang_key = localStorage.getItem('lang_key') ? localStorage.getItem('lang_key') : 'en';
  searchKey = '';
  showFilter = false;
  showSort = false;
  isMob = false;
  EmployeeData = [];
  imageUrl = environment.SERVER_ORIGIN  
  @Input() wholddata:any;
  modalRefC: BsModalRef;
  signform: FormGroup;
  liststatus: boolean     = false;
  errormessagef           = this.lang.transform('lang_loading');
  myemployees: any        = [];
  signchecked: any        = false;
 
  lltotal: any            = "";
  lltotalpage: any        = "";
   
  signfilename            = this.lang.transform('lang_select_files');
  markfilename            = this.lang.transform('lang_select_files');
  signform_status:any     = false;
  signerror: string       = "";
  is_admin                = JSON.parse(this.ls.getItem('is_admin'));
  
  page:any                = localStorage.getItem('M_H_R_N')   || 1;
	size:any                = localStorage.getItem('M_H_R_S')   || 10;
  searchByBranch          = localStorage.getItem('M_H_R_B')   || "";
 
  pages:any= 'pages';
  page_no = 1;
  page_size = 10;
  collection_size = 0;
  paginationMaxSize = 3;
  showExel = false;
  filters_params = {
    nationality_type: null,
    project_id: null,
    branch_id:null,
    national_id:null,
    employee_id:null,
    employee_name:null,
    nationality: null,
    position: null,
    
  };
  sort_params = {
    form_request_id: null,
    template_id: null,
    platform_code_system:null,
    
  };
  constructor(private ds:DataService, public alert:AlertService, private spinner:PreloaderService, public lang:LanguagePipe, public ls:LocalStorage, public dialog:MatDialog, public modalService: BsModalService) { }

  ngOnInit(): void {
    if(window.innerWidth < 600) {
      this.isMob = true;
      this.paginationMaxSize = 1;
    } else {
      this.isMob = false;
    }

    localStorage.setItem('employee_filterParams', JSON.stringify(this.filters_params));
    localStorage.setItem('sortParams', JSON.stringify(this.sort_params));

    /* if(this.filterParams['group_type_id']) {
      this.showExel = true;
    } else {
      this.showExel = false;
    } */
    this.getEmployeeData(this.filters_params, this.sort_params, false);
    this.getAllProjects();
    this.signatureform();
    this.loadbranches();
    

  }




  branches=[]
  public loadbranches() {
    this.branches = [];
    this.ds.getActionByUrl([], 'human_resources/branches/human_resources_view').subscribe(data => {
      if (data.status) {
        this.branches = data.branches;
      }
    });
  }
  exportExcel() {
    this.getExcel(this.filters_params);
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.showSort = false;
    if(this.isMob) {
      const dialogRef = this.dialog.open(FilterEmployeeCardMobComponent, {
        width:'700px',
        data: {
          isMob: this.isMob,
          projects:this.projects,
          branches:this.branches
        }
    });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.filters_params = result;
        this.getEmployeeData(this.filters_params, this.sort_params, false);
        }
      });
    }
  }
  view_profile_image
  public get_profile_image(url) {
    this.view_profile_image = "";
    this.ds.getActionByUrl([], url).subscribe(data => {
      if (data.status) {
       return this.view_profile_image = 'data:' + data['content-type'] + ';base64,' + data.base64;
      }
    });
  }
  toggleSort() {
    this.showSort = !this.showSort;
    this.showFilter = false;
    if(this.isMob) {
      const dialogRef = this.dialog.open(SortEmployeeCardMobComponent, {
        width:'700px',
        data: {
          isMob: this.isMob
        }
    });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.sort_params = result;
        this.getEmployeeData(this.filters_params, this.sort_params, false);
        }
      });
    }
  }

  previewPDF(link:string) {
    this.ds.previewData(link);
  }

  load_by_page_size(e) {
    if(e && e.value) {
      this.getEmployeeData(this.getEmployeeData, this.sortParams, false);
    }
  }

  filterParams(e) {
    this.filters_params = e;
    this.getEmployeeData(this.filters_params, this.sort_params, false);
  }
  sortParams(e) {
    this.sort_params = e;
    this.getEmployeeData(this.filters_params, this.sort_params, false);
  }

  public onPageChange(pageNum: number): void {
    this.getEmployeeData(this.filters_params, this.sort_params, false);
  }
  public openModelC(template: TemplateRef<any>) {
    this.modalRefC = this.modalService.show(template, environment.modelconfig);
  }
  allEmpData = []
  getEmployeeData(filters:any, sort:any, isExcel:boolean) {
    this.spinner.show();
    let formData = new FormData();   
    formData.append('project_id', filters?.project_id || '');
    formData.append('nationality_type', filters?.nationality_type || '');
    formData.append('branch_id', filters?.branch_id || '');
    formData.append('national_id', filters?.national_id || '');
    formData.append('employee_id', filters?.employee_id || '');
    formData.append('employee_name', filters?.employee_name || '');
    formData.append('nationality', filters?.nationality || '');
    formData.append('position', filters?.position || '');
 
    console.log('filters?.branch_id ',filters?.branch_id );
    
    /* if(filters?.group_type_id) {
      this.showExel = true;
    } else {
      this.showExel = false;
    } */
   /*  if(isExcel) {
      this.pages = 'excel';
    } */
    this.ds.postActionByUrl(formData, `human_resources/employees_report/${this.page_no}/${this.page_size}`).subscribe((res) => {
      if(res?.status) {
        this.spinner.hide();
        this.allEmpData = res
        this.EmployeeData = res?.records == ''?[]: res?.records ;
        this.collection_size = res?.page?.total_records;
        /* if(!isExcel) {
         
       
        } else {
          let url = 'data:' + res['content-type'] + ';base64,' + res.base64;
          console.log(url)
          console.log(res.base64)
          let downloadExcel = document.createElement('a');
          downloadExcel.style.display = 'none';
          downloadExcel.href = url;
          downloadExcel.download = 'Almnabr';
          document.body.appendChild(downloadExcel);
          downloadExcel.click();
          document.body.removeChild(downloadExcel);
          this.pages = 'pages';
        } */
      } else {
        this.spinner.hide();
        this.EmployeeData = [];
      }
    }, (err) => {
      this.spinner.hide();
      this.EmployeeData = [];
    })
  }


  getExcel(filters:any) {
    this.spinner.show();
    let formData = new FormData();   
    formData.append('project_id', filters?.project_id || '');
    formData.append('nationality_type', filters?.nationality_type || '');
    formData.append('branch_id', filters?.branch_id || '');
    formData.append('national_id', filters?.national_id || '');
    formData.append('employee_id', filters?.employee_id || '');
    formData.append('employee_name', filters?.employee_name || '');
    formData.append('nationality', filters?.nationality || '');
    formData.append('position', filters?.position || '');
 
    /* if(filters?.group_type_id) {
      this.showExel = true;
    } else {
      this.showExel = false;
    } */
   /*  if(isExcel) {
      this.pages = 'excel';
    } */
    this.ds.postActionByUrl(formData, `human_resources/employees_report_excel`).subscribe((res) => {
      if(res?.status) {
 
        let url = 'data:' + res['content-type'] + ';base64,' + res.base64;
        console.log(url)
        console.log(res.base64)
        this.spinner.hide();
        let downloadExcel = document.createElement('a');
        downloadExcel.style.display = 'none';
        downloadExcel.href = url;
        downloadExcel.download = 'Almnabr Employees';
        document.body.appendChild(downloadExcel);
        downloadExcel.click();
        document.body.removeChild(downloadExcel);
        this.pages = 'pages';
      } else {
        this.spinner.hide();
   /*      this.EmployeeData = []; */
      }
    }, (err) => {
      this.spinner.hide();
 /*      this.EmployeeData = []; */
    })
  }


  projects = []
  getAllProjects() {
    this.spinner.show();
    this.ds.getActionByUrl( [],`human_resources/get_project_subjects`).subscribe((res) => {
      if(res?.status) {
        this.spinner.hide();
        this.projects = res.records
      } else {
        this.spinner.hide();
        this.projects = [];
      }
    }, (err) => {
      this.spinner.hide();
      this.projects = [];
    })
  }


  public showsignature(empid, type, user_id) {
    this.spinner.show();
    this.ds.getActionByUrl([], 'viewsmark/'+empid+type+user_id).subscribe(data => {
      this.spinner.hide();
      if (data.status && data.base64) {
        ($('#showSMPreview') as any).modal({ backdrop: 'static', keyboard: false });
        ($('#showSMPreview') as any).modal('show');
        $("#SMnabrPdf").attr('src', 'data:' + data['content-type'] + ';base64,' + data.base64);
      } else {
        this.ds.dialogf('', data.error);
      }
    }, error => {
      this.spinner.hide();
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }









  public performStatus(status, id, branch_id) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show()
        this.ds.getActionByUrl([], 'human_resources/change_status/'+id+'/'+branch_id+'/'+status).subscribe(data => {
            this.spinner.hide()
            if (data.status) {
              this.getEmployeeData(this.filters_params, this.sort_params, false)
              this.ds.dialogf('', data.msg);
            } else {
              this.ds.dialogf('', data.error);
            }
          },
          (error) => {
            this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
            this.spinner.hide()
          }
        );
      }
    });
  }
  public signaturefor(id, branch_id) {
    this.signform.reset();
    this.signfilename = this.lang.transform('lang_select_files');
    this.markfilename = this.lang.transform('lang_select_files');
    this.signform.patchValue({
      id: id,
      branch_id: branch_id
    })
  }
  public signatureform() {
    this.signform = new FormGroup({
      id: new FormControl("", [Validators.required]),
      branch_id: new FormControl("", [Validators.required]),
      signature: new FormControl("", [Validators.required]),
      mark: new FormControl("", [Validators.required]),
    });
  }
  public uploadfile(event, sform, type) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      if (type == 'mark') {
        this.markfilename = fileid.name;
      } else {
        this.signfilename = fileid.name;
      }
      sform.setValue(fileid, { emitEvent: true });
    } else {
      sform.setValue(null);
      if (type == 'mark') {
        this.markfilename = this.lang.transform('lang_select_files');
      } else {
        this.signfilename = this.lang.transform('lang_select_files');
      }
    }
  }
  public upload_signature() {
    this.spinner.show();
    this.ds.postActionByUrl(this.ds.json2formdata(this.signform.value), 'upload_signature').subscribe(res => {
      this.spinner.hide();
      this.signform_status = res.status;
      if (res.status) {
        this.getEmployeeData(this.filters_params, this.sort_params, false)
        this.alert.success(res.msg);
        this.signform.reset();
        setTimeout(() => {
          this.modalRefC.hide();
        }, 1000);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
        this.spinner.hide();
      this.signform_status = false;
      this.alert.error(error.error + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public remove_signature(user_id) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show()
        this.ds.getActionByUrl([], "remove_signature/" + user_id).subscribe(data => {
            this.spinner.hide()
            if (data.status) {
              this.getEmployeeData(this.filters_params, this.sort_params, false)
              this.ds.dialogf('', data.msg);
            } else {
              this.ds.dialogf('', data.error);
            }
          },
          (error) => {
            this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
            this.spinner.hide()
          }
        );
      }
    });
  }
  public send_signup_link(emp): void {
    this.spinner.show();
    let formdata = new FormData();
    formdata.append('branch_id', emp.branch_id);
    formdata.append('id', emp.employee_number);
    formdata.append('employee_id_number', emp.employee_id_number);
    this.ds.postActionByUrl(formdata, "send_signup_link").subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.getEmployeeData(this.filters_params, this.sort_params, false)
        this.ds.dialogf('',res.msg);
      } else {
        this.ds.dialogf('',res.error);
      }
    }, error => {
        this.spinner.hide();
        this.ds.dialogf('',error.error + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  allSelected = false
  public checkAllHR(checked) {
    this.allSelected  = checked;
    console.log(checked);
    
    if (checked) {
        $(".all_employees .eachItemHR").prop("checked", true);
        $(".all_employees .makeback").addClass("bground");
        $(".all_employees .deleteIconDiv").show();
    } else {
        $(".all_employees .eachItemHR").prop("checked", false);
        $(".all_employees .makeback").removeClass("bground");
        $(".all_employees .deleteIconDiv").hide();
    }
  }

  public checkEachItemHR(event) {
    if (event.target.checked) {
        const val = event.target.value;
        $(".all_employees .removerow" + val).addClass("bground");
    } else {
        const val = event.target.value;
        $(".all_employees .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".all_employees .eachItemHR:checked").each(function() {
        checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".all_employees .eachItemHR").each(function() {
        totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
        $(".all_employees .deleteIconDiv").show();
    } else {
        $(".all_employees .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
        $(".all_employees #allItemsHR").prop("checked", true);
    } else {
        $(".all_employees #allItemsHR").prop("checked", false);
    }
}
  public search_employees() {
    this.page_size = 10;
    this.page_no = 1;
    this.getEmployeeData(this.filters_params, this.sort_params, false)
  }
  public deleteBulkDataHR() {
      const checkedtotal = [];
      $(".all_employees .eachItemHR:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          const dialogRef = this.dialog.open(ConfirmationDialog, {
              disableClose: true,
              width: '400px',
              data: {
                  title: this.lang.transform('lang_confirmation'),
                  message: this.lang.transform('lang_are_you_sure'),
              }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                  this.spinner.show()
                  this.ds.deleteActionByUrl(checkedtotal, "81f5cc8c046c6d1c66fa3424783873db/MAIN").subscribe(
                      (data) => {
                          this.spinner.hide()
                          if (data.status) {
                              this.search_employees();
                              $(".all_employees #allItemsHR").prop("checked", false);
                              $(".all_employees .deleteIconDiv").hide();
                              this.ds.dialogf('', data.msg);
                          } else {
                              this.ds.dialogf('', data.error);
                          }
                      },
                      (error) => {
                          this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                          this.spinner.hide()
                      }
                  );
              }
          });
      } else {
          this.ds.dialogf();
      }
  }
 
}

