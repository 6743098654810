import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { isThisQuarter } from 'date-fns';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-formation-lists',
  templateUrl: './formation-lists.component.html',
  styleUrls: ['./formation-lists.component.scss']
})
export class FormationListsComponent implements OnInit {
  @Input() project_data;
  @Input() supervision_data;
  @Input() able2add;
  @Input() able2edit;
  modalRefV: BsModalRef;
  @Output() templateAction    = new EventEmitter<any>();
  able2delete                 = JSON.parse(this.ls.getItem('is_admin'));
  collections:any             = [];
  worklevels:any              = [];
  lang_key                    = localStorage.getItem('lang_key') || 'en';
  searchKey                   = "";
  collection_id:any           = null;
  template_status             = "";
  wholedata:any               = [];
  page_no:any                 = '1';
  page_size:any               = '10';
  total_records:any;
  nodata: string              = this.lang.transform('lang_loading');
  viewdata:any                = [];
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.get_collections_work_levels();
    this.get_all_templates();
  }
  public openModal(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'width-min-max-90'
    });
  }
  public get_collections_work_levels() {
    this.collections = this.worklevels = [];
    let param = new FormData();
    param.append('lang_key',      this.lang_key);
    let url = ['lpcollection','lpworklevel'];
    this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(res=> {
      this.spinner.hide();
      if(res[0].status) { //collection records
        this.collections = res[0].records;
      }
      if(res[1].status) { // work levels
        this.worklevels = res[1].records;
      }
    },error => {
      this.spinner.hide();
    });
  }
  public page_number(page) {
    this.page_no      = page;
    this.page_size    = this.page_size;
    this.get_all_templates();
  }
  public by_page_size(size) {
    this.page_no      = 1;
    this.page_size    = size.value;
    this.get_all_templates();
  }
  public search_by() {
    this.page_no      = 1;
    this.page_size    = 10;
    this.get_all_templates();
  }
  public get_all_templates() {
    let param = new FormData();
    param.append('search_key',      this.searchKey || '');
    param.append('template_status', this.template_status || '');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'ltemplate/'+this.supervision_data.projects_profile_id+'/'+this.supervision_data.projects_work_area_id+'/'+this.page_no+'/'+this.page_size).subscribe(res=> {
      this.spinner.hide();
      if(res.status) {
        this.wholedata = res;
        this.total_records = res?.page?.total_records;
      } else {
        this.wholedata = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholedata = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    });
  }
  public edit_template_data(list) {
    this.templateAction.emit({mode : 'TADD', data : list, template_id : list.template_id, actionMode : 'EDIT'})
  }
  public checkAllItemsTem(event) {
    if (event.target.checked) {
      $(".apptemplates .eachItemTem").prop("checked", true);
      $(".apptemplates .makeback").addClass("bground");
      $(".apptemplates .deleteIconDiv").show();
    } else {
      $(".apptemplates .eachItemTem").prop("checked", false);
      $(".apptemplates .makeback").removeClass("bground");
      $(".apptemplates .deleteIconDiv").hide();
    }
  }
  public checkEachItemTem(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".apptemplates .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".apptemplates .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".apptemplates .eachItemTem:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".apptemplates .eachItemTem").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".apptemplates .deleteIconDiv").show();
    } else {
      $(".apptemplates .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".apptemplates #AllItemsTem").prop("checked", true);
    } else {
      $(".apptemplates #AllItemsTem").prop("checked", false);
    }
  }
  public deleteBulkDataTem() {
    const checkedtotal = [];
    $(".apptemplates .eachItemTem:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'dtemplate/' + this.supervision_data.projects_work_area_id).subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.search_by();
                $(".apptemplates #AllItemsTem").prop("checked", false);
                $(".apptemplates .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
  public view_template_data(list, template) {
    this.spinner.show();
    this.ds.getActionByUrl([], 'vtemplate/'+this.supervision_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id+'/'+list.template_id).subscribe(res=> {
      this.spinner.hide();
      if(res.status) {
        this.openModal(template);
        this.viewdata = res.data;
      } else {
        this.viewdata = [];
        this.ds.dialogf('', res.error);
      }
    }, error => {
      this.spinner.hide();
      this.viewdata = [];
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    });
  }
  public build_work_level(key, requestfield, type?, platformcode?) {
    if(this.viewdata.work_levels && this.viewdata.work_levels.length > 0) {
      const pallow = (type == "V") ? this.viewdata.work_levels.find(x => x.key === key && x.platformcode == platformcode) : this.viewdata.work_levels.find(x => x.key === key);
			if (pallow !== undefined && pallow[requestfield]) {
				return pallow[requestfield];
			}
    }
    return '---';
  }
  public show_units(units) {
    this.ds.dialogf(this.lang.transform('lang_selected_units'), units, 'S');
  }
  public view_template_data_with_tree(list) {
    console.log('list',list);
    
    this.templateAction.emit({mode : 'TADD', data : list, template_id : list.template_id, actionMode : 'VIEW'});
  }

  changeStatus(template:any, status:any) {
    this.spinner.show();
    let formData = new FormData();
    formData.append('template_id', template?.template_id);
    formData.append('template_status', status);
    this.ds.post('pforms/update_template_status', formData).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.get_all_templates();
      } else {
        
      }
    })
  }
}