<mat-tab-group mat-align-tabs="center" (selectedTabChange)="selectedTabValue($event)">
    <mat-tab label="setting" *ngIf="ds.allowMe('projects', 'projects_template_permission')">
        <ng-template mat-tab-label>Setting</ng-template>
        <div class="p-2">
            <div class="row">
                <div class="col-12 col-md-6 mt-1 ">
                    <mat-label style="margin: 15px 5px; font-size:17px">{{ "lang_setting_type" | language }}</mat-label>
                    <br>
                    <mat-radio-group
                        aria-labelledby="example-radio-group-label"
                        [(ngModel)]="setting_type"
                        (change)="loading_files_btns_func()" 
                        style="display: flex; flex-direction: column;">
                        <mat-radio-button  value="soil">
                            {{"lang_soil" | language}}
                        </mat-radio-button>
                        <mat-radio-button  value="concrete">
                            {{"lang_concrete" | language}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="col-12 col-md-6  mb-2 mt-2 text-center" *ngIf="loading_files_btns">
                    <ngx-skeleton-loader  count="3" [theme]="{ 'border-radius': '3px', height: '40px' }"></ngx-skeleton-loader>
                </div>
                <div class="col-12 col-md-6  mb-2 text-center" *ngIf="!loading_files_btns">
                    <button
                        mat-button
                        style="
                            background-color: rgb(113 166 223 / 42%);
                            width: 100%;
                            margin-top: 10px;
                        "
                        (click)="downloadExcel(false)"
                    >
                        <i
                            class="fa fa-download"
                            style="
                                font-size: 20px;
                                margin: 0 5px;
                                color: rgb(19, 65, 146);
                            "
                        ></i>
                        <span
                            style="
                                text-transform: uppercase;
                                font-weight: 600;
                                color: rgb(19, 65, 146);
                                font-size: 12px;
                            "
                            >{{"lang_download_excel" | language}}</span
                        >
                    </button>
                    
                    <input
                        type="file"
                        #uploadExcelFile
                        id="uploadExcelInput"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        hidden
                        (change)="uploadExcel($event.target.files)"
                    />
                    <button
                        mat-button
                        style="
                            background-color: rgb(136 224 136 / 42%);
                            width: 100%;
                            margin-top: 10px;
                        "
                        (click)="uploadExcelFile.click()"
                    >
                        <i
                            class="fa fa-upload"
                            style="
                                font-size: 20px;
                                margin: 0 5px;
                                color: rgb(19, 146, 19);
                            "
                        ></i>
                        <span
                            style="
                                text-transform: uppercase;
                                font-weight: 600;
                                color: rgb(19, 146, 19);
                                font-size: 12px;
                            "
                            >{{"lang_upload_excel" | language}}</span
                        >
                    </button>
                    <button
                        mat-button
                        style="
                        background-color: rgb(224 136 200 / 42%);
                        width: 100%;
                        margin-top: 10px;
                        "
                        (click)="soil_concrete_history()"
                    >
                        <i
                            class="fa fa-download"
                            style="
                                font-size: 20px;
                                margin: 0 5px;
                                color: rgb(146, 19, 102);
                            "
                        ></i>
                        <span
                            style="
                                text-transform: uppercase;
                                font-weight: 600;
                                color: rgb(146, 19, 102);
                                font-size: 12px;
                            "
                            >{{"lang_history" | language}}</span
                        >
                    </button>
                </div>
                <div class="col-12 mt-2">
                    <div
                        class="table-responsive dropdownyes customResponsive tablefields"
                        *ngIf="history_files?.length"
                        >
                        <table border="1" class="table CustomTable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{ "lang_writer" | language }}</th>
                                    <th>{{ "lang_date" | language }}</th>
                                    <th>{{ "lang_action" | language }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="
                                        let item of history_files | slice: (history_files_page - 1) * history_files_size:history_files_page * history_files_size;
                                        let i = index;
                                        let odd = odd;
                                        let even = even
                                    "
                                >
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ item?.writer }}</td>
                                    <td>{{ item?.created_datetime }}</td>
                                    <td><i class="fa fa-download mnabricon" (click)="downloadExcel(item)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            class="mt-2"
                            style="
                                height: 40px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            "
                        >
                            <ngb-pagination
                                *ngIf="history_files.length"
                                [collectionSize]="history_files.length"
                                [(page)]="history_files_page"
                                [maxSize]="3"
                                [pageSize]="history_files_size"
                                [boundaryLinks]="true"
                            ></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </mat-tab>
    <mat-tab label="test_result_list">
        <ng-template mat-tab-label>
            TESTs result
        </ng-template>
        <div class="p-2">
            <div class="row">
                <div class="col-12">
                    <mat-tab-group  class="test-tabs" (selectedTabChange)="selectedListTabValue($event)">
                        <mat-tab label="soil" class="mat-tab">
                            <ng-template mat-tab-label>Soil</ng-template>
                            <div class="p-2">
                                <div class="row">
                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label>{{'lang_report_status' | language }}</label>
                                            <ng-select 
                                                [items]="report_status_list" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                [(ngModel)]="report_status_so"
                                                placeholder="{{'lang_report_status' | language}}"
                                                (change)="getTestReportListSo()"
                                            >
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="row">
                                            <ng-container *ngFor="let item of test_report_list_so; let i = index">
                                                <div
                                                    class="col-12 col-sm-6 col-md-4"
                                                >
                                                    <mat-card
                                                        class="card"
                                                        [style.border-left-color]="
                                                        item?.report_status == 'S'?'#28a745 !important': item?.report_status == 'F'?'#dc3545 !important': item?.report_status == 'NC'? 'rgb(131 17 8) !important':'#0e3768 !important'
                                                    "
                                                    >
                                                        <mat-card-title class="card-title">
                                                            <div class="d-flex">
                                                                <span *ngIf="item?.report_status">
                                                                    <span class="result_code" [style.background-color]="item?.report_status == 'S'?'#28a745 !important': item?.report_status == 'F'?'#dc3545 !important': item?.report_status == 'NC'? 'rgb(131 17 8) !important':'#0e3768 !important'">{{ item?.report_status }}</span></span
                                                                >
                                                                <div class="ml-3 mr-3" *ngIf="item?.file_path">
                                                                    <i
                                                                        class="fa fa-file-pdf-o"
                                                                        style="color: #0e3768; font-size: 25px"
                                                                        matTooltip="{{ 'lang_preview' | language }}"
                                                                        (click)="ds?.preview_pdf_file(item?.file_path)"
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                            <mat-menu
                                                                #menu="matMenu"
                                                                [overlapTrigger]="false"
                                                                xPosition="{{ lang_key == 'en' ? 'before' : 'after' }}"
                                                            >
                                                            <button
                                                            mat-menu-item
                                                            class="d-flex align-items-center justify-content-between"
                                                            (click)="openForm(item)"
                                                            *ngIf="makeAction"
                                                        >
                                                            <span>Close Request Form</span>
                                                            <i class="fa fa-wpforms mnabricon"></i>
                                                        </button>
                                                        <button
                                                            mat-menu-item
                                                            class="d-flex align-items-center justify-content-between"
                                                            (click)="viewHistory(item?.tsr_report_id, 'soil')"
                                                            *ngIf="item?.report_status != 'P'"
                                                        >
                                                            <span>View History</span>
                                                            <i class="fa fa-wpforms mnabricon"></i>
                                                        </button>
                                                            </mat-menu>
                                                            <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="makeAction || item?.report_status != 'P'">
                                                                <i class="fa fa-ellipsis-v"></i>
                                                            </button>
                                                        </mat-card-title>
                                                        <mat-card-content>
                                                            <div class="row pt-3">
                                                                <div class="col-12">
                                                                    <div class="card-body-table">
                                                                        <table style="width: 100%">
                                                                            <tr *ngIf="item?.group_type_name">
                                                                                <td
                                                                                    colspan="2"
                                                                                    style="
                                                                                        text-align: center;
                                                                                        text-transform: uppercase;
                                                                                        color: #0e3768 !important;
                                                                                        font-weight: 600;
                                                                                    "
                                                                                >
                                                                                    {{ item?.group_type_name }}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_platform_title" | language }}</th>
                                                                                <td>
                                                                                    <span
                                                                                        *ngIf="item?.platform_name?.length >= 30"
                                                                                        matTooltip="{{ item?.platform_name }}"
                                                                                        >{{ item?.platform_name | shortenLength: 30 }}</span
                                                                                    >
                                                                                    <span *ngIf="item?.platform_name?.length <= 30">{{
                                                                                        item?.platformname
                                                                                    }}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_group_one" | language }}</th>
                                                                                <td>{{ item?.group1name }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_work_level" | language }}</th>
                                                                                <td>{{ item?.work_level_label }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_platform_code_system" | language }}</th>
                                                                                <td style="direction: ltr">
                                                                                    <span style="direction: ltr">{{
                                                                                        item?.projects_platform_code_system
                                                                                    }}</span>
                                                                                </td>
                                                                            </tr>
                                                                            
                                                                            <tr>
                                                                                <th>{{ "lang_by_phases" | language }}</th>
                                                                                <td>{{ item?.phase_short_code }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_request_number" | language }}</th>
                                                                                <td>{{ item?.transaction_request_id }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_natural_ground_level" | language }}</th>
                                                                                <td>{{ item?.natural_ground_level }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_foundation_level" | language }}</th>
                                                                                <td>{{ item?.foundation_level }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_expected_level" | language }}</th>
                                                                                <td>{{ item?.expected_level }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_accepted_persent_compaction" | language }}</th>
                                                                                <td>{{ item?.accepted_persent_compaction }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_tsr_id" | language }}</th>
                                                                                <td>{{ item?.tsr_report_id }}</td>
                                                                            </tr>
                                                                            <tr *ngIf="item?.report_result">
                                                                                <th>{{ "lang_report_result" | language }}</th>
                                                                                <td>{{ item?.report_result }}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </mat-card-content>
                                                    </mat-card>
                                                </div>
                                            </ng-container>
                                            <div
                                                class="col-12"
                                                style="
                                                    min-height: 200px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    font-size: 20px;
                                                "
                                                *ngIf="test_report_list_so.length == 0"
                                            >
                                                <p>{{ "lang_no_data" | language }}</p>
                                            </div>
                                            <div
                                                class="col-12"
                                                style="
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                    flex-wrap: wrap;
                                                "
                                                *ngIf="test_report_list_so.length"
                                            >
                                                <p>{{"lang_total" | language}}: {{test_report_collectionsize_so}}</p>
                                                <ngb-pagination
                                                    [collectionSize]="test_report_collectionsize_so"
                                                    [(page)]="test_report_pageno_so"
                                                    [pageSize]="test_report_pagesize_so"
                                                    [maxSize]="3"
                                                    [boundaryLinks]="true"
                                                    (pageChange)="getTestReportListSo($event)"
                                                ></ngb-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="concrete" class="mat-tab">
                            <ng-template mat-tab-label>Concrete</ng-template>
                            <div class="p-2">
                                <div class="row">
                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label>{{'lang_report_status' | language }}</label>
                                            <ng-select 
                                                [items]="report_status_list" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                [(ngModel)]="report_status_co"
                                                placeholder="{{'lang_report_status' | language}}"
                                                (change)="getTestReportListCo(1, concrete_due_dates)"
                                            >
                                            </ng-select>
                                        </div>
                                    </div>
                                    
                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label>{{'lang_compressive_strength' | language }}</label>
                                            <ng-select 
                                                [items]="compressive_strength_list" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                [(ngModel)]="compressive_strength"
                                                placeholder="{{'lang_compressive_strength' | language}}"
                                                (change)="getTestReportListCo(1, concrete_due_dates)"
                                            >
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <label style="display: block;">Due Dates</label>
                                        <mat-slide-toggle [color]="'primary'" (change)="dueDatesChange($event)">
                                            
                                        </mat-slide-toggle>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="row">
                                            <ng-container *ngFor="let item of test_report_list_co; let i = index">
                                                <div
                                                    class="col-12 col-sm-6 col-md-4"
                                                >
                                                    <mat-card
                                                        class="card"
                                                        [style.border-left-color]="
                                                        item?.report_status == 'S'
                                                            ? '#28a745 !important'
                                                            : item?.report_status == 'F'
                                                            ? '#dc3545 !important'
                                                            : item?.report_status == 'NC'? 'rgb(131 17 8) !important': '#0e3768 !important'
                                                    "
                                                    >
                                                        <mat-card-title class="card-title">
                                                            <div class="d-flex">
                                                                <span *ngIf="item?.report_status">
                                                                    <span class="result_code" [style.background-color]="item?.report_status == 'S'
                                                                    ? '#28a745 !important'
                                                                    : item?.report_status == 'F'
                                                                    ? '#dc3545 !important'
                                                                    : item?.report_status == 'NC'? 'rgb(131 17 8) !important': '#0e3768 !important'">{{ item?.report_status }}</span></span
                                                                >
                                                                <div class="ml-3 mr-3" *ngIf="item?.file_path">
                                                                    <i
                                                                        class="fa fa-file-pdf-o"
                                                                        style="color: #0e3768; font-size: 25px"
                                                                        matTooltip="{{ 'lang_preview' | language }}"
                                                                        (click)="ds?.preview_pdf_file(item?.file_path)"
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                            <mat-menu
                                                                #menu="matMenu"
                                                                [overlapTrigger]="false"
                                                                xPosition="{{ lang_key == 'en' ? 'before' : 'after' }}"
                                                            >
                                                            <button
                                                            mat-menu-item
                                                            class="d-flex align-items-center justify-content-between"
                                                            (click)="openForm(item)"
                                                            *ngIf="makeAction"
                                                        >
                                                            <span>Close Request Form</span>
                                                            <i class="fa fa-wpforms mnabricon"></i>
                                                        </button>
                                                        <button
                                                            mat-menu-item
                                                            class="d-flex align-items-center justify-content-between"
                                                            (click)="viewHistory(item?.tsr_report_id, 'concrete')"
                                                            *ngIf="item?.report_status != 'P'"
                                                        >
                                                            <span>View History</span>
                                                            <i class="fa fa-wpforms mnabricon"></i>
                                                        </button>
                                                            </mat-menu>
                                                            <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="makeAction || item?.report_status != 'P'">
                                                                <i class="fa fa-ellipsis-v"></i>
                                                            </button>
                                                        </mat-card-title>
                                                        <mat-card-content>
                                                            <div class="row pt-3">
                                                                <div class="col-12">
                                                                    <div class="card-body-table">
                                                                        <table style="width: 100%">
                                                                            <tr *ngIf="item?.group_type_name">
                                                                                <td
                                                                                    colspan="2"
                                                                                    style="
                                                                                        text-align: center;
                                                                                        text-transform: uppercase;
                                                                                        color: #0e3768 !important;
                                                                                        font-weight: 600;
                                                                                    "
                                                                                >
                                                                                    {{ item?.group_type_name }}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_platform_title" | language }}</th>
                                                                                <td>
                                                                                    <span
                                                                                        *ngIf="item?.platform_name?.length >= 30"
                                                                                        matTooltip="{{ item?.platform_name }}"
                                                                                        >{{ item?.platform_name | shortenLength: 30 }}</span
                                                                                    >
                                                                                    <span *ngIf="item?.platform_name?.length <= 30">{{
                                                                                        item?.platformname
                                                                                    }}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_group_one" | language }}</th>
                                                                                <td>{{ item?.group1name }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_work_level" | language }}</th>
                                                                                <td>{{ item?.work_level_label }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_platform_code_system" | language }}</th>
                                                                                <td style="direction: ltr">
                                                                                    <span style="direction: ltr">{{
                                                                                        item?.projects_platform_code_system
                                                                                    }}</span>
                                                                                </td>
                                                                            </tr>
                                                                            
                                                                            <tr>
                                                                                <th>{{ "lang_by_phases" | language }}</th>
                                                                                <td>{{ item?.phase_short_code }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_request_number" | language }}</th>
                                                                                <td>{{ item?.transaction_request_id }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_concrete_strength_type" | language }}</th>
                                                                                <td>{{ item?.concrete_strength_type == 'S'? '7 days': '28 days' }}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{{ "lang_tsr_id" | language }}</th>
                                                                                <td>{{ item?.tsr_report_id }}</td>
                                                                            </tr>
                                                                            <tr *ngIf="item?.report_result">
                                                                                <th>{{ "lang_report_result" | language }}</th>
                                                                                <td>{{ item?.report_result }}</td>
                                                                            </tr>
                                                                            <tr *ngIf="compressive_strength == 'T' || compressive_strength == ''">
                                                                                <th>{{ "lang_concrete_strength_28days" | language }}</th>
                                                                                <td>{{ item?.concrete_strength_28days }}</td>
                                                                            </tr>
                                                                            <tr *ngIf="compressive_strength == 'S' || compressive_strength == ''">
                                                                                <th>{{ "lang_concrete_strength_7days" | language }}</th>
                                                                                <td>{{ item?.concrete_strength_7days }}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </mat-card-content>
                                                    </mat-card>
                                                </div>
                                            </ng-container>
                                            <div
                                                class="col-12"
                                                style="
                                                    min-height: 200px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    font-size: 20px;
                                                "
                                                *ngIf="test_report_list_co.length == 0"
                                            >
                                                <p>{{ "lang_no_data" | language }}</p>
                                            </div>
                                            <div
                                                class="col-12"
                                                style="
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                    flex-wrap: wrap
                                                "
                                                *ngIf="test_report_list_co.length"
                                            >
                                                <p>{{"lang_total" | language}}: {{test_report_collectionsize_co}}</p>
                                                <ngb-pagination
                                                    [collectionSize]="test_report_collectionsize_co"
                                                    [(page)]="test_report_pageno_co"
                                                    [pageSize]="test_report_pagesize_co"
                                                    [maxSize]="3"
                                                    [boundaryLinks]="true"
                                                    (pageChange)="getTestReportListCo($event, concrete_due_dates)"
                                                ></ngb-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                
            </div>
        </div>
    </mat-tab>
    <mat-tab label="report" *ngIf="false">
        <ng-template mat-tab-label>Report</ng-template>
        <div class="p-2">
            Content 3
        </div>
    </mat-tab>
</mat-tab-group>