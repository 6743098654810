import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { TrwViewDialogComponent } from '../trw-view-dialog/trw-view-dialog.component';

@Component({
  selector: 'app-trw-history',
  templateUrl: './trw-history.component.html',
  styleUrls: ['./trw-history.component.scss']
})
export class TrwHistoryComponent implements OnInit {
  public project_profile_id       = this.route.snapshot.paramMap.get("pid");
  public project_supervision_id   = this.route.snapshot.paramMap.get("sid");
  lang_key                        = localStorage.getItem('lang_key') || 'en';
  test_report_history = [];
  test_report_history_pageno = 1;
  test_report_history_pagesize = 10;
  setting_type = 'soil';
  tsr_report_id = '';
  projects_work_area_id = '';
  action_auto_id = '';
  constructor(private route:ActivatedRoute, public dialog: MatDialog, private spinner:PreloaderService, public ds: DataService) { }

  ngOnInit(): void {
    this.route.url.subscribe(res => {
      console.log(res);
      this.setting_type = res[5]?.path;
      this.tsr_report_id = res[6]?.path;
      this.getTestReportHistory();
    });
    this.projects_work_area_id = this.route.snapshot.queryParamMap.get('workarea');
    
  }


  getTestReportHistory() {
    this.spinner.show();
    let url = `ptesting/list_action_soil_testing_reports/${this?.project_profile_id}/${this?.project_supervision_id}`;
    let formData = new FormData();
    formData.append('page_no', this.test_report_history_pageno+'' || '');
    formData.append('page_size', this.test_report_history_pagesize+'' || '');
    formData.append('tsr_report_id', this.tsr_report_id || '');
    
    if(this.setting_type == 'concrete') {
      url = `ptesting/list_action_concreate_testing_reports/${this.project_profile_id}/${this?.project_supervision_id}`;
    }
    
    this.ds.getActionByUrl(this.ds.formData2string(formData), url).subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.test_report_history = res?.records;
      } else {
        this.test_report_history = [];
      }
    }, err => {
      this.spinner.hide();
      this.test_report_history = [];
    })
  }


  viewForm(item) {
    const dialogRef = this.dialog.open(TrwViewDialogComponent, {
      width:'80%',
      data: {
        projects_supervision_id: this.project_supervision_id,
        projects_work_area_id: this?.projects_work_area_id,
        projects_profile_id: this?.project_profile_id,
        tsr_report_id: item?.tsr_report_id,
        action_auto_id: item?.action_auto_id,
        setting_type: this.setting_type
      }
    })
  }

}
