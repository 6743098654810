<form [formGroup]="form" class="form" (ngSubmit)="submit();">
    <section class="panel-expansion ckeditorhidetoolbar">
        <mat-accordion class="stylepanels">
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_violation_form_new' | language}} ( <span *ngIf="lang_key =='en'"> {{employee_data.firstname_english}} {{employee_data.lastname_english}}</span> <span *ngIf="lang_key =='ar'"> {{employee_data.firstname_arabic}} {{employee_data.lastname_arabic}} </span> ) </mat-expansion-panel-header>
                <div class="mbrl15">

                    <div class="row mb-10">
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_employee_no"|language }}</div>
                            <div class="mb-10">
                                <b> {{employee_data.employee_number}} </b>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_name"|language }}</div>
                            <div class="mb-10">
                                <b>
                                    {{employee_data.firstname_english}} </b
                                >
                            </div>
                        </div>
                        <!-- <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_salary"|language }}</div>
                            <div class="mb-10">
                                <b>
                                    5000</b
                                >
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_current_salary"|language }}</div>
                            <div class="mb-10">
                                <b>
                                   4500</b
                                >
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">{{ "lang_joining_date"|language }}</div>
                            <div class="mb-10">
                                <b>
                                   15/06/2002</b
                                >
                            </div>
                        </div> -->
                    </div>

                    <div class="row">
                  
               
                        <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                            <div class="form-group">
                                <label for="violation_date_en">{{'lang_date' | language }} : <span class="red">*</span></label>
                                <div class="positionrelatiove">
                                    <input formControlName="violation_date_en" type="text" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event, form.get('violation_date_ar'));" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
                                    <mat-datepicker-toggle matSuffix [for]="BDeng" class="posiab"></mat-datepicker-toggle>
                                    <mat-datepicker #BDeng disabled="false"></mat-datepicker>
                                    <mat-error *ngIf="submitted && form.controls['violation_date_en'].errors?.required">{{'lang_field_required' | language}}</mat-error>
                                </div>
                            </div>
                        </div>
                        

                        <div  class="col-md-3 col-sm-3 col-xs-12 mb-10">
 
                              <label class="mb-10">
                                {{ "lang_violation_reason" | language }}
                                <span class="red"> * </span> </label>
                         <!--      <input id="typeahead-http" type="text" class="form-control" formControlName="violation_id" [ngbTypeahead]="search" placeholder="Wikipedia search" />
                               -->
                              <ng-select
                              [items]="searchArray"
                              [multiple]="false"
                              bindLabel="label"
                              bindValue="value"
                              clearAllText="Clear"
                               (change)="getViolations($event)"
                              appendTo="body"
                              formControlName="violation_id"
                              placeholder="{{ 'lang_violation_reason' | language }}"
 
                               
                          >
                          </ng-select>
                          <mat-error
                          *ngIf="
                          submitted &&
                          form.controls['violation_id'].errors?.required
                          "
                      >
                          {{ "lang_field_required" | language }}
                      </mat-error>
                            </div>
                     

                    <div class="col-md-3 col-xs-12 col-sm-3 mb-10">
                        <label class="mb-10">
                            {{ "lang_direct_manager" | language }}
                            <span class="red"> * </span> </label>
        
                            <ng-select
                            [items]="users"
                            [multiple]="false"
                            bindLabel="label"
                            bindValue="value"
                            clearAllText="Clear"
                             
                            appendTo="body"
                            formControlName="direct_manager"
                            placeholder="{{ 'lang_direct_manager' | language }}"
                            (search)="search_users($event)"
                            
                            [searchFn]="ds.customSearchFn"
                             
                        >
                        </ng-select>
                        <mat-error
                            *ngIf="
                            submitted &&
                            form.controls['direct_manager'].errors?.required
                            "
                        >
                            {{ "lang_field_required" | language }}
                        </mat-error>
                     
                    </div>  
                  
                  <div  class="col-md-3 col-sm-3 col-xs-12 mb-10">
                    <button (click)="get_employee_violations()" type="button" class="albutton mnabrbutton mat-accent top26 withOutLabel" mat-button>
                   <!--  <i class="fa fa-plus"></i>  -->{{ "lang_history" | language }}
                  </button>
                  
                </div>
                        
                <div  class="col-md-3 col-sm-3 col-xs-12 mb-10" *ngIf="form.controls['violation_punishment'].value">
 
                    <label class="mb-10" style="display: block;">
                      {{ "lang_punishment" | language }}
                      <span class="red"> * </span> </label>
                      
                      <h3>
                        {{form.controls['violation_punishment'].value}}
                      </h3>
                 <!--    <input id="typeahead-http" type="text" class="form-control" formControlName="violation_id" [ngbTypeahead]="search" placeholder="Wikipedia search" /> -->
              </div>

              
              <div  class="col-md-3 col-sm-3 col-xs-12 mb-10" *ngIf="form.controls['violation_amount'].value">

                <label class="mb-10"  style="display: block;">
                  {{ "lang_deduct_amount" | language }}
                  <span class="red"> * </span> </label>
                  <h3>
                    {{+form.controls['violation_amount'].value| number : '.2-2'}}
                  </h3>
              
              <!--   <input id="typeahead-http" type="text" class="form-control" formControlName="violation_id" [ngbTypeahead]="search" placeholder="Wikipedia search" /> -->
            </div>
              
            <div class="row col-md-12">
                <div  class="col-sm-6 col-xs-6 mb-10">

                    <label class="mb-10">
                    {{ "lang_statement_en" | language }}
                    <span class="red"> * </span> </label>
                 
                    <textarea  id="" cols="30" rows="6" class="form-control almnabrformcontrol"  formControlName="violation_statement_en" ></textarea>
                    <mat-error
                    *ngIf="
                    submitted &&
                    form.controls['violation_statement_en'].errors?.required
                    "
                >
                    {{ "lang_field_required" | language }}
                </mat-error>
                </div>

                <div  class=" col-sm-6 col-xs-6 mb-10">

                    <label class="mb-10">
                    {{ "lang_statement_ar" | language }}
                    <span class="red"> * </span> </label>
                  
                    <textarea  id="" cols="30" rows="6"  class="form-control almnabrformcontrol"  formControlName="violation_statement_ar" ></textarea>
                    <mat-error
                    *ngIf="
                    submitted &&
                    form.controls['violation_statement_ar'].errors?.required
                    "
                >
                    {{ "lang_field_required" | language }}
                </mat-error>
                </div>
                 
            </div>
            
                    </div>
              
                   
                  
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="button" routerLink="/human_resources/employees" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_human_resources_all_emp' | language}}</button>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                            <button type="button" (click)="violationEvent.emit()" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_human_resources_emp_details' | language}}</button>
                        </div>
                        <div class="col-md-6 col-sm-2 col-xs-12 mb-10">
                            <app-alert id="default-alert"></app-alert>
                        </div>
                        <div class="col-md-1 col-sm-2 col-xs-12 mb-10">
                            
                        </div>
                        <div class="col-md-1 col-sm-2 col-xs-12 mb-10">
                            <input type="submit" value="{{'lang_save' | language}}" class="albutton mnabrbutton mat-accent" mat-button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</form>


 