import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

declare var $:any
@Component({
  selector: 'app-joining',
  templateUrl: './joining.component.html',
  styleUrls: ['./joining.component.scss']
})
export class JoiningComponent implements OnInit {
  @Output() showJoining = new EventEmitter();
  @Input() able2edit;
  @Input() able2delete;
  @Input() able2add;
  @Input() id;
  @Input() branch_id;
  @Input() key;
  searchKey = "";
  @Input() types;
  @Input() requestfor;
  @Input() user_data;
  @Input() attachments;
  csearchKey = "";
  wholedata: any = [];
  modalRefV: BsModalRef;
  expandstatus = false;
  page = 1;
  size = 10;
  errormessagef: any = this.lang.transform('lang_loading');
  viewdata:any = [];
  constructor(public router:Router, public ds:DataService,public route :ActivatedRoute, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.expandstatus = (this.requestfor == 'profile') ? true : false;
    /* this.search_employee_joinings(); */
    this.get_employee_joinings()
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  public load_page_size(pageno) {
    this.size = pageno;
    this.get_employee_joinings();
  }
  public page_length(page) {
    this.page = page;
    this.get_employee_joinings();
  }
  public search_employee_joinings() {
    this.size = 10;
    this.page = 1;
    this.get_employee_joinings();
  }
  public get_employee_joinings() {
    this.spinner.show();
    const param = new FormData();
   /*  param.append("search_key", this.csearchKey || ''); */
    param.append("employee_number", this.id);
    let url =  'human_resources/get_employee_joining_history';
    this.ds.post(url+'/'+this.page+'/'+this.size,param).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.wholedata = data;
      } else {
        this.wholedata.records = [];
        this.errormessagef = data.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholedata = [];
      this.errormessagef = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  /* public loadjoining(data, template) {
    this.viewdata = [];
    const param = new FormData();
    param.append("id", this.id);
    param.append("joining_id", data.joining_id);
    let url = 'form/FORM_HRJ1/get_employee_joining_history';
    this.ds.getActionByUrl(this.ds.formData2string(param), url).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
  
        this.viewdata = data.records;
      } 
    }, error => {
      this.spinner.hide();
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  } */
  public checkAllContr(event) {
    if (event.target.checked) {
        $(".appjoinings .eachItemContr").prop("checked", true);
        $(".appjoinings .makeback").addClass("bground");
        $(".appjoinings .deleteIconDiv").show();
    } else {
        $(".appjoinings .eachItemContr").prop("checked", false);
        $(".appjoinings .makeback").removeClass("bground");
        $(".appjoinings .deleteIconDiv").hide();
    }
  }
  public checkEachItemContr(event) {
      if (event.target.checked) {
          const val = event.target.value;
          $(".appjoinings .removerow" + val).addClass("bground");
      } else {
          const val = event.target.value;
          $(".appjoinings .removerow" + val).removeClass("bground");
      }
      const checkedtotal = [];
      $(".appjoinings .eachItemContr:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      const totalrow = [];
      $(".appjoinings .eachItemContr").each(function() {
          totalrow.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          $(".appjoinings .deleteIconDiv").show();
      } else {
          $(".appjoinings .deleteIconDiv").hide();
      }
      if (checkedtotal.length === totalrow.length) {
          $(".appjoinings #allItemsContr").prop("checked", true);
      } else {
          $(".appjoinings #allItemsContr").prop("checked", false);
      }
  }


 /*  getJoiningHistory() {
    let formData = new FormData();
 
    console.log(this.user_data);
    
    formData.append('employee_number', this.user_data?.employee_number);
    formData.append('lang_key', this.key);

    this.ds.post('form/FORM_HRJ1/get_employee_joining_history', formData).subscribe(res => {
      console.log(res);
      if(res.status) {
        this.wholedata = res.records;
      } else {

      }
      
      
    }, err => {
      console.log(err);
      
    })
  } */



  public deleteBulkDataContr() {
      const checkedtotal = [];
      $(".appjoinings .eachItemContr:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          const dialogRef = this.dialog.open(ConfirmationDialog, {
              disableClose: true,
              width: '400px',
              data: {
                  title: this.lang.transform('lang_confirmation'),
                  message: this.lang.transform('lang_are_you_sure'),
              }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                  this.spinner.show()
                  this.ds.deleteActionByUrl(checkedtotal, "01f5086b879a62a05da4094dac203558/joining/"+this.id+'/'+this.branch_id).subscribe((data) => {
                          this.spinner.hide()
                          if (data.status) {
                              this.search_employee_joinings();
                              $(".appjoinings #allItemsContr").prop("checked", false);
                              $(".appjoinings .deleteIconDiv").hide();
                              this.ds.dialogf('', data.msg);
                          } else {
                              this.ds.dialogf('', data.error);
                          }
                      }, (error) => {
                          this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                          this.spinner.hide()
                      }
                  );
              }
          });
      } else {
          this.ds.dialogf();
      }
  }
}
