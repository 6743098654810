<section
	class="card matborderno activeiconstepper customdesignstepper"
	*ngIf="showStatus"
>
	<mat-accordion class="stylepanels">
		<mat-expansion-panel class="mb-2" [expanded]="true">
			<mat-expansion-panel-header
				>{{ "lang_form_details" | language }}
			</mat-expansion-panel-header>
			<div class="material-panel-body">
				<div class="mbrl15 martb0">
					<div class="row">
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_project_title" | language }}</label>
							<div class="">{{ project_data?.project_title }}</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_supervision_id" | language }}</label>
							<div class="">
								{{ supervision_data?.projects_supervision_id }}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{
								"lang_supervision_title" | language
							}}</label>
							<div class="">{{ supervision_data?.quotation_subject }}</div>
						</div>

						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_forms_code" | language }}</label>
							<div class="">
								{{ form_key }}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{ "lang_form_name" | language }}</label>
							<div class="">{{ template_data?.platformname }}</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{
								"lang_form_specifications" | language
							}}</label>
							<div class="">
								{{ template_data?.template_platform_item_specifications }}
							</div>
						</div>
						<div class="col-md-3 col-sm-4 col-xs-12 mb-10 text-center">
							<label class="bold">{{'lang_drawing_file' | language }}</label>
							<div class="" *ngIf="drawing_file">
								<i class="fa fa-file-pdf-o fa-icon" matTooltip="{{'lang_drawing_file' | language }}" (click)="ds?.preview_pdf_file(drawing_file)"></i>
							</div>
							<div *ngIf="!drawing_file"><b>----</b></div>
						</div>
					</div>
					<div
						class="row"
						[ngStyle]="
							lang_key == 'ar'
								? {
										'padding-left': '10px',
										'padding-right': '10px'
								  }
								: null
						"
					>
						<div class="col-xs-12 col-12 pad0">
							<div class="stepper-progress">
								<div class="stepTitle" id="stepTitle" #stepTitleEl>
									<p>{{ stepTitle }}</p>
								</div>
								<p class="step-counter">
									{{ "lang_step" | language }} <b>{{ selectedIndex + 1 }}</b>
									{{ "lang_of" | language }}
									<b>{{ totalStepsCount }}</b>
								</p>
								<mat-progress-bar mode="determinate" [value]="progressValue">
								</mat-progress-bar>
							</div>
							<div class="row pb-3">
								<div class="col-3 col-sm-2">
									<button
										(click)="moveStepper('prev', 'create')"
										[disabled]="true"
										class="carousel-btn-pre albutton mnabrbutton mat-button"
									>
										<i
											class="fa"
											[ngClass]="{
												'fa-chevron-left': lang_key == 'en',
												'fa-chevron-right': lang_key == 'ar'
											}"
										></i>
									</button>
								</div>
								<div class="col-6 col-sm-8"></div>
								<div class="col-3 col-sm-2">
									<button
										(click)="moveStepper('next', 'create')"
										[disabled]="true"
										class="carousel-btn-next albutton mnabrbutton mat-button"
									>
										<i
											class="fa"
											[ngClass]="{
												'fa-chevron-right': lang_key == 'en',
												'fa-chevron-left': lang_key == 'ar'
											}"
										></i>
									</button>
								</div>
							</div>

							<mat-horizontal-stepper
								#stepper
								class="pad0 steps13-stepper"
								[linear]="isLinear"
								[selectedIndex]="currentStep"
								(selectionChange)="selectionChange($event)"
							>
								<mat-step>
									<app-wri-step1
										[form_url]="form_url"
										[form_key]="form_key"
										[form_request_id]="form_request_id"
										[project_data]="project_data"
										[supervision_data]="supervision_data"
										[template_data]="template_data"
										[editMode]="false"
										[unitsOfTemplate]="unitsOfTemplate"
										*ngIf="selectedIndex == 0"
									></app-wri-step1>
								</mat-step>
								<mat-step> </mat-step>
								<mat-step> </mat-step>
								<mat-step> </mat-step>
								<mat-step> </mat-step>
								<mat-step> </mat-step>
								<mat-step> </mat-step>
								<mat-step> </mat-step>
								<mat-step> </mat-step>
								<mat-step> </mat-step>

								<mat-step> </mat-step>
							</mat-horizontal-stepper>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
	<div class="card-footer">
		<div class="row">
			<div class="col-md-2 col-sm-2 col-xs-12 mb-10">
				<button
					type="button"
					routerLink="/projects/BbVUrWitLE"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_all_projects" | language }}
				</button>
			</div>
			<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
				<button
					type="button"
					(click)="showAction.emit({ mode: 'VIEW', data: null })"
					class="albutton mnabrbutton mat-accent"
					mat-button
				>
					{{ "lang_supervision_operations" | language }}
				</button>
			</div>
			<div class="col-md-7 col-sm-2 col-xs-12 mb-10"></div>
		</div>
	</div>
</section>
