import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { TemplateDialogComponent } from './template-dialog/template-dialog.component';
import { ZoneDialogComponent } from './zone-dialog/zone-dialog.component';

@Component({
  selector: 'app-supervision-my-transctions',
  templateUrl: './supervision-my-transctions.component.html',
  styleUrls: ['./supervision-my-transctions.component.scss']
})
export class SupervisionMyTransctionsComponent implements OnInit {

  lang_key = localStorage.getItem('lang_key') ? localStorage.getItem('lang_key') : 'en';
  searchKey = '';
  showFilter = false;
  showSort = false;
  isMob = false;
  TransactionsData = [];
  @Input() projects_work_area_id:any;
  @Input() projects_profile_id:any;
  @Input() projects_supervision_id:any;
  pages:any= 'pages';
  page_no = 1;
  page_size = 10;
  collection_size = 0;
  paginationMaxSize = 3;
  showExel = false;
  filters_params = {
    transaction_start_date:null,
    transaction_end_date:null,
    form_request_id: null,
    template: null,
    group_type: null,
    group1: null,
    group2: null,
    platform_code_system:null,
    zone: null,
    block: null,
    cluster: null,
    phase_short_code: null,
    unit_id: null,
    level_key: null,
    barcode: null,
    result_code: null,
    version: 'final_completed_versions'
  };
  sort_params = {
    form_request_id: null,
    template_id: null,
    platform_code_system:null,
    zone: null,
    block: null,
    cluster: null,
    barcode: null
  };
  levels_arr = [];
  filters_saved:any;
  sort_saved:any;
  @ViewChild('filterSelect', {static: false}) filterSelect:NgSelectComponent;
  template    = [];
  group_type  = [];
  group1      = [];
  group2      = [];
  zone        = [];
  block       = [];
  cluster     = [];
  filterList  = [];
  filter_name:any;
  selectedFilter:any;
  filterForm:FormGroup;
  result_code_arr = [
    {
      label:'A',
      value:'A'
    },
    {
      label:'B',
      value:'B'
    },
    {
      label:'C',
      value:'C'
    },
    {
      label:'D',
      value:'D'
    },
    {
      label:'OPEN',
      value:'OPEN'
    },
    {
      label:'CLOSE',
      value:'CLOSE'
    }
  ];

  status_arr = [
    {
      label:`${this.lang.transform('lang_completed')}`,
      value:'final_completed_versions'
    },
    {
      label:`${this.lang.transform('lang_all_completed_versions')}`,
      value:'all_completed_versions'
    },
    {
      label:`${this.lang.transform('lang_pending')}`,
      value:'all_pending_versions'
    },
    {
      label:`${this.lang.transform('lang_duplicated_versions')}`,
      value:'all_duplicated_versions'
    }
  ];
  //sort
  @ViewChild('filterSelectAtsort', {static: false}) filterSelectAtsort:NgSelectComponent;
  sortForm:FormGroup = this.fb.group({
    form_request_id: null,
    template_id: null,
    platform_code_system:null,
    zone: null,
    block: null,
    cluster: null,
    barcode: null
  });
  form_request_id_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ];
  template_id_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ];
  platform_code_system_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ];
  zone_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ]
  block_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ]
  cluster_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ]
  barcode_arr = [
    {
      label:'ASC',
      value:'ASC'
    },
    {
      label:'DESC',
      value:'DESC'
    }
  ]
  constructor(private dialog:MatDialog, private ds:DataService, private spinner:PreloaderService, private fb: FormBuilder, private lang: LanguagePipe, private sweetAlert: SweetAlertService) { }

  ngOnInit(): void {
    if(window.innerWidth < 600) {
      this.isMob = true;
      this.paginationMaxSize = 1;
    } else {
      this.isMob = false;
    }
    this.filterForm = this.fb.group({
      transaction_start_date:null,
      transaction_end_date:null,
      form_request_id: null,
      platform_code_system:null,
      zone: null,
      block: null,
      cluster: null,
      phase_short_code: null,
      unit_id: null,
      level_key: null,
      barcode: null,
      result_code: null,
      version: 'final_completed_versions'
    });
    this.getWorkLevels();
    this.getFilterList();
    this.getTransactionsData(this.filters_params, this.sort_params, false)
  }

  getWorkLevels() {
    let param  = new FormData();
    param.append('lang_key', this.lang_key);
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpworklevel').subscribe(res => {
      if(res?.status) {
        this.levels_arr = res?.records;
      } else {
        this.levels_arr = [];
      }
    })
  }

  exportExcel() {
    this.getTransactionsData(this.filters_params, this.sort_params, true);
  }

  show_excel_func(filters:any) {
    let filtersValue = Object.values(filters);
    let count = 0;
    for(let item of filtersValue) {
      if(item) {
        count++;
      }
    }
    if(count >= 3) {
      this.showExel = true;
    } else {
      this.showExel = false;
    }
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.showSort = false;
  }

  toggleSort() {
    this.showSort = !this.showSort;
    this.showFilter = false;
  }

  load_by_page_size(e) {
    if(e && e.value) {
      this.getTransactionsData(this.filters_params, this.sort_params, false);
    }
  }

  filterParams(e) {
    this.filters_params = e;
    this.getTransactionsData(this.filters_params, this.sort_params, false);
  }
  sortParams(e) {
    this.sort_params = e;
    this.getTransactionsData({...this.filters_params, transaction_start_date: this.convertDateToString(this.filterForm.get('transaction_start_date').value), transaction_end_date: this.convertDateToString(this.filterForm.get('transaction_end_date').value)}, this.sort_params, false);
  }

  public onPageChange(): void {
    this.getTransactionsData(this.filters_params, this.sort_params, false);
  }

  getTransactionsData(filters:any, sort:any, isExcel:boolean) {
    this.spinner.show();
    let formData = new FormData();
    formData.append(`projects_supervision_id`, this.projects_supervision_id  || '');
    filters?.template?.forEach((element, index) => {
      formData.append(`filter[template][${index}][label]`, element?.label  || '');
      formData.append(`filter[template][${index}][id]`, element?.value  || element?.id || '');
    });
    filters?.group_type?.forEach((element, index) => {
      formData.append(`filter[group_type][${index}][label]`, element?.label  || '');
      formData.append(`filter[group_type][${index}][id]`, element?.id        || '');
    });
    filters?.group1?.forEach((element, index) => {
      formData.append(`filter[group1][${index}][label]`, element?.label  || '');
      formData.append(`filter[group1][${index}][id]`, element?.value  || element?.id   || '');
    });
    filters?.group2?.forEach((element, index) => {
      formData.append(`filter[group2][${index}][label]`, element?.label  || '');
      formData.append(`filter[group2][${index}][id]`, element?.id        || '');
    });
    filters?.zone?.forEach((element, index) => {
      formData.append(`filter[zone][${index}][label]`, element?.label  || '');
      formData.append(`filter[zone][${index}][id]`, element?.value || element?.id || '');
    });
    filters?.block?.forEach((element, index) => {
      formData.append(`filter[block][${index}][label]`, element?.label  || '');
      formData.append(`filter[block][${index}][id]`, element?.value || element?.id  || '');
    });
    filters?.cluster?.forEach((element, index) => {
      formData.append(`filter[cluster][${index}][label]`, element?.label  || '');
      formData.append(`filter[cluster][${index}][id]`, element?.value || element?.id || '');
    });
    formData.append('filter[transaction_start_date]', filters?.transaction_start_date          || '');
    formData.append('filter[transaction_end_date]', filters?.transaction_end_date              || '');
    formData.append('filter[transaction_request_id]', filters?.form_request_id                 || '');
    formData.append('filter[platform_code_system]', filters?.platform_code_system              || '');
    formData.append('filter[phase_short_code]', filters?.phase_short_code                      || '');
    formData.append('filter[unit_id]', filters?.unit_id                                        || '');
    formData.append('filter[level_key]', filters?.level_key                                    || '');
    formData.append('filter[barcode]', filters?.barcode                                        || '');
    formData.append('filter[result_code]', filters?.result_code                                || '');
    formData.append('filter[version]', filters?.version                                        || '');
    formData.append('sort_by[barcode]', sort?.barcode                                          || '');
    formData.append('sort_by[transaction_request_id]', sort?.form_request_id                   || '');
    formData.append('sort_by[template_id]', sort?.template_id                                  || '');
    formData.append('sort_by[zone]', sort?.zone                                                || '');
    formData.append('sort_by[block]', sort?.block                                              || '');
    formData.append('sort_by[cluster]', sort?.cluster                                          || '');
    formData.append('sort_by[platform_code_system]', sort?.platform_code_system                || '');
    this.show_excel_func(
      {...filters, 
        template: filters?.template?.length, 
        group_type: filters?.group_type?.length,
        group1: filters?.group1?.length,
        group2: filters?.group2?.length,
        zone: filters?.zone?.length,
        block: filters?.block?.length,
        cluster: filters?.cluster?.length
      });
    if(isExcel) {
      this.pages = 'excel';
    }
    this.ds.postActionByUrl(formData, `pr/qtp/${this.projects_work_area_id}/${this.pages}/${this.page_no}/${this.page_size}`).subscribe((res) => {
      if(res?.status) {
        this.spinner.hide();
        if(!isExcel) {
          this.TransactionsData = res?.records;
          this.collection_size = res?.page?.total_records;
        } else {
          let url = 'data:' + res['content-type'] + ';base64,' + res.base64;
          let downloadExcel = document.createElement('a');
          downloadExcel.style.display = 'none';
          downloadExcel.href = url;
          downloadExcel.download = 'Almnabr';
          document.body.appendChild(downloadExcel);
          downloadExcel.click();
          document.body.removeChild(downloadExcel);
          this.pages = 'pages';
        }
      } else {
        this.spinner.hide();
        this.TransactionsData = [];
        this.collection_size = 0;
        this.page_no = 1;
      }
    }, (err) => {
      this.spinner.hide();
      this.TransactionsData = [];
      this.collection_size = 0;
      this.page_no = 1;
    })
  }

  saveFilters(filters) {
    this.filters_saved = filters;
    this.saveFiltersSort(this.filters_saved, this.sort_saved)
  }

  saveSorts(sort) {
    this.sort_saved = sort;
    this.saveFiltersSort(this.filters_saved, this.sort_saved)
  }

  saveFiltersSort(filters:any, sort:any) {
    this.spinner.show();
    let formData = new FormData();
    formData.append('projects_work_area_id', this.projects_work_area_id || '');
    formData.append('filter_key', 'FP1');
    formData.append('filter_name', this?.filter_name || '');
    formData.append('filter[transaction_request_id]', filters?.form_request_id || '');
    filters?.template?.forEach((element, index) => {
      formData.append(`filter[template][${index}][label]`, element?.label  || '');
      formData.append(`filter[template][${index}][id]`, element?.value || element?.id || '');
    });
    filters?.group_type?.forEach((element, index) => {
      formData.append(`filter[group_type][${index}][label]`, element?.label  || '');
      formData.append(`filter[group_type][${index}][id]`, element?.id        || '');
    });
    filters?.group1?.forEach((element, index) => {
      formData.append(`filter[group1][${index}][label]`, element?.label  || '');
      formData.append(`filter[group1][${index}][id]`, element?.value || element?.id || '');
    });
    filters?.group2?.forEach((element, index) => {
      formData.append(`filter[group2][${index}][label]`, element?.label  || '');
      formData.append(`filter[group2][${index}][id]`, element?.id        || '');
    });
    filters?.zone?.forEach((element, index) => {
      formData.append(`filter[zone][${index}][label]`, element?.label  || '');
      formData.append(`filter[zone][${index}][id]`, element?.value  || element?.id || '');
    });
    filters?.block?.forEach((element, index) => {
      formData.append(`filter[block][${index}][label]`, element?.label  || '');
      formData.append(`filter[block][${index}][id]`, element?.value  || element?.id || '');
    });
    filters?.cluster?.forEach((element, index) => {
      formData.append(`filter[cluster][${index}][label]`, element?.label  || '');
      formData.append(`filter[cluster][${index}][id]`, element?.value || element?.id || '');
    });
    formData.append('filter[transaction_start_date]', filters?.transaction_start_date          || '');
    formData.append('filter[transaction_end_date]', filters?.transaction_end_date              || '');
    formData.append('filter[platform_code_system]', filters?.platform_code_system              || '');
    formData.append('filter[phase_short_code]', filters?.phase_short_code                      || '');
    formData.append('filter[unit_id]', filters?.unit_id                                        || '');
    formData.append('filter[level_key]', filters?.level_key                                    || '');
    formData.append('filter[barcode]', filters?.barcode                                        || '');
    formData.append('filter[result_code]', filters?.result_code                                || '');
    formData.append('filter[version]', filters?.version                                        || '');
    formData.append('sort_by[barcode]', sort?.barcode                                          || '');
    formData.append('sort_by[transaction_request_id]', sort?.form_request_id                   || '');
    formData.append('sort_by[template_id]', sort?.template_id                                  || '');
    formData.append('sort_by[zone]', sort?.zone                                                || '');
    formData.append('sort_by[block]', sort?.block                                              || '');
    formData.append('sort_by[cluster]', sort?.cluster                                          || '');
    formData.append('sort_by[platform_code_system]', sort?.platform_code_system                || '');
    
    this.ds.postActionByUrl(formData, 'pr/craete_update_filter').subscribe((res) => {
      if(res?.status) {
        this.spinner.hide();
        this.getFilterList();
      } else {
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
    })
  }

  

  getFilterList() {
    let param = new FormData();
    param.append('filter_key', 'FP1');
    param.append('projects_work_area_id', this.projects_work_area_id || '');
    this.spinner.show();
    this.ds.getActionByUrl(this.ds.formData2string(param), 'pr/get_filter_list').subscribe(res => {
      this.spinner.hide();
      if(res?.status) {
        this.filterList = res?.records;
        let selected = this.filterList.find(el => el.fliter_selected == '1');
        if(selected) {
          this.selectedFilter = selected?.filter_id;
          this.getFilterDetails({filter_id:selected?.filter_id});
        }
      } else {
        this.filterList = [];
      }
    }, (err) => {
      this.spinner.hide();
    })
  }

  getFilterDetails(e) {
    if(e) {
      let param = new FormData();
      param.append('filter_id', e?.filter_id);
      this.spinner.show();
      this.ds.getActionByUrl(this.ds.formData2string(param), 'pr/get_filter_details').subscribe(res => {
        this.spinner.hide();
        this.filter_name = res?.records[0]?.filter_name;
        this.filterForm.get('form_request_id').setValue(res?.records[0]?.filter_by?.transaction_request_id);
        if(res?.records[0]?.filter_by?.transaction_start_date) {
          this.filterForm.get('transaction_start_date').setValue(new Date(res?.records[0]?.filter_by?.transaction_start_date));
        } else {
          this.filterForm.get('transaction_start_date').setValue(null);
        }
        if(res?.records[0]?.filter_by?.transaction_start_date) {
          this.filterForm.get('transaction_end_date').setValue(new Date(res?.records[0]?.filter_by?.transaction_end_date));
        } else {
          this.filterForm.get('transaction_end_date').setValue(null);
        }
        this.filterForm.get('platform_code_system').setValue(res?.records[0]?.filter_by?.platform_code_system);
        this.filterForm.get('phase_short_code').setValue(res?.records[0]?.filter_by?.phase_short_code);
        this.filterForm.get('unit_id').setValue(res?.records[0]?.filter_by?.unit_id);
        this.filterForm.get('barcode').setValue(res?.records[0]?.filter_by?.barcode);
        this.filterForm.get('result_code').setValue(res?.records[0]?.filter_by?.result_code);
        this.filterForm.get('version').setValue(res?.records[0]?.filter_by?.version);
        this.filterForm.get('level_key').setValue(res?.records[0]?.filter_by?.level_key);
        this.template   = res?.records[0]?.filter_by?.template || [];
        this.group_type = res?.records[0]?.filter_by?.group_type || [];
        this.group1     = res?.records[0]?.filter_by?.group1 || [];
        this.group2     = res?.records[0]?.filter_by?.group2 || [];
        this.zone       = res?.records[0]?.filter_by?.zone || [];
        this.block      = res?.records[0]?.filter_by?.block || [];
        this.cluster    = res?.records[0]?.filter_by?.cluster || [];
        this.sortForm.get('form_request_id').setValue(res?.records[0]?.sort_by?.transaction_request_id || null);
        this.sortForm.get('template_id').setValue(res?.records[0]?.sort_by?.template_id || null);
        this.sortForm.get('platform_code_system').setValue(res?.records[0]?.sort_by?.platform_code_system || null);
        this.sortForm.get('zone').setValue(res?.records[0]?.sort_by?.zone || null);
        this.sortForm.get('block').setValue(res?.records[0]?.sort_by?.block || null);
        this.sortForm.get('cluster').setValue(res?.records[0]?.sort_by?.block || null);
        this.sortForm.get('barcode').setValue(res?.records[0]?.sort_by?.block || null);
        this.filters_saved = res?.records[0]?.filter_by;
        this.sort_saved = res?.records[0]?.sort_by;
        this.filters_params = {...this.filterForm?.value, template: this.template, group1: this.group1, group_type: this.group_type, group2: this.group2, zone:this.zone, block:this.block, cluster:this.cluster}
        this.sort_params = this.sortForm?.value;
        this.sendFilterForm();
      }, (err) => {
        this.spinner.hide();
      })
    } else {
      this.filter_name = '';
      this.filterForm.get('form_request_id').setValue(null);
        this.filterForm.get('platform_code_system').setValue(null);
        this.filterForm.get('transaction_start_date').setValue(null);
        this.filterForm.get('transaction_end_date').setValue(null);
        this.filterForm.get('phase_short_code').setValue(null);
        this.filterForm.get('unit_id').setValue(null);
        this.filterForm.get('barcode').setValue(null);
        this.filterForm.get('result_code').setValue(null);
        this.filterForm.get('version').setValue(null);
        this.filterForm.get('level_key').setValue(null);
        this.template   =  [];
        this.group_type =  [];
        this.group1     =  [];
        this.group2     =  [];
        this.zone       =  [];
        this.block      =  [];
        this.cluster    =  [];
        this.sortForm.get('form_request_id').setValue(null);
        this.sortForm.get('template_id').setValue(null);
        this.sortForm.get('platform_code_system').setValue(null);
        this.sortForm.get('zone').setValue(null);
        this.sortForm.get('block').setValue(null);
        this.sortForm.get('cluster').setValue(null);
        this.sortForm.get('barcode').setValue(null);
        this.filters_saved = null;
        this.sort_saved = null;
        this.filters_params = null;
        this.sort_params = null;
    }
  }



  removeFilter(event, filter) {
    event?.stopPropagation();
    this.sweetAlert.confirmMessage('are you sure?', 'yes').then((result) => {
      if(result?.isConfirmed) {
        let param = new FormData();
        param.append('filter_id', filter?.filter_id || '');
        this.spinner.show();
        this.ds.getActionByUrl(this.ds.formData2string(param), 'pr/delete_filter').subscribe(res => {
          this.spinner.hide();
          if(res?.status) {
            this.sweetAlert?.successToast('lang_filter_deleted_successfully', 2000);
            this.filterSelect?.close();
            this.filterSelectAtsort?.close();
            this.getFilterList();
          }
        }, (err) => {
          this.spinner.hide();
        })
      }
    })
  }

  sendFilterForm() {
    this.filterParams({...this.filterForm?.value, transaction_start_date: this.convertDateToString(this.filterForm.get('transaction_start_date').value), transaction_end_date: this.convertDateToString(this.filterForm.get('transaction_end_date').value), template: this.template, group1: this.group1, group_type: this.group_type, group2: this.group2, zone:this.zone, block:this.block, cluster:this.cluster});
  }

  resetFilterForm() {
    this.filterForm.reset();
    this.filterForm.get('version').setValue('final_completed_versions');
    this.template   = [];
    this.group1     = [];
    this.group_type = [];
    this.group2     = [];
    this.zone       = [];
    this.block      = [];
    this.cluster    = [];
    this.filter_name = null;
    this.selectedFilter = null;
    this.filterParams({...this.filterForm?.value, template: this.template, group1: this.group1, group_type: this.group_type, group2: this.group2, zone:this.zone, block:this.block, cluster:this.cluster});
  
  }

  public isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if(charCode == 44) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}


  openTemplateFilter() {
    const dialogRef = this.dialog.open(TemplateDialogComponent, {
      width:'100%',
      data: {
        projects_work_area_id : this.projects_work_area_id
      }
  });

  dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let canAddTemplate = this.template?.map(el => el.value)?.includes(result?.template?.value),
        canAddGroup1       = this.group1?.map(el => el.id)?.includes(result?.group1?.id),
        canAddType         = this.group_type?.map(el => el.id)?.includes(result?.group_type?.id),
        canAddGroup2       = this.group2?.map(el => el.value)?.includes(result?.group2?.value);
        
        if(result?.template && !canAddTemplate) {
          this.template.push(result?.template);
        }
        if(result?.group1 && !canAddGroup1) {
          this.group1.push(result?.group1);
        }
        if(result?.group_type && !canAddType) {
          this.group_type.push(result?.group_type);
        }
        if(result?.group2 && !canAddGroup2) {
          this.group2.push(result?.group2);
        }
      }
    });
  }
  
  removeTemplate(index) {
    if(index >= 0) {
      this.template.splice(index);
    }
  }

  removeGroup1(index) {
    if(index >= 0) {
      this.group1.splice(index);
    }
  }

  removeGroupType(index) {
    if(index >= 0) {
      this.group_type.splice(index);
    }
  }

  removeGroup2(index) {
    if(index >= 0) {
      this.group2.splice(index);
    }
  }

  openZoneFilter() {
    const dialogRef = this.dialog.open(ZoneDialogComponent, {
      width:'100%',
      data: {
        projects_work_area_id   : this.projects_work_area_id,
        projects_profile_id     : this.projects_profile_id,
        projects_supervision_id : this.projects_supervision_id
      }
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result) {
      let canAddZone    = this.zone?.map(el => el.value)?.includes(result?.zone?.value),
      canAddBlock       = this.block?.map(el => el.value)?.includes(result?.block?.value),
      canAddCluster     = this.cluster?.map(el => el.value)?.includes(result?.cluster?.value);
      
      if(result?.zone && !canAddZone) {
        this.zone.push(result?.zone);
      }
      if(result?.block && !canAddBlock) {
        this.block.push(result?.block);
      }
      if(result?.cluster && !canAddCluster) {
        this.cluster.push(result?.cluster);
      }
    }
  });
  }

  removeZone(index) {
    if(index >= 0) {
      this.zone.splice(index);
    }
  }

  removeBlock(index) {
    if(index >= 0) {
      this.block.splice(index);
    }
  }

  removeCluster(index) {
    if(index >= 0) {
      this.cluster.splice(index);
    }
  }

  saveFilter() {
    if(this.filter_name) {
      this.saveFilters({...this.filterForm?.value, transaction_start_date: this.convertDateToString(this.filterForm.get('transaction_start_date').value), transaction_end_date: this.convertDateToString(this.filterForm.get('transaction_end_date').value), template: this.template, group1: this.group1, group_type: this.group_type, group2: this.group2, zone:this.zone, block:this.block, cluster:this.cluster, filter_name: this.filter_name})
    } else {
      this.sweetAlert.errorMessage('lang_filter_name_required');
    }
  }

  buildForm() {
    this.sortForm = this.fb.group({
      form_request_id: null,
      template_id: null,
      platform_code_system: null,
      zone: null,
      block: null,
      cluster: null,
      barcode: null
    });
  }

  sendSortForm() {
    this.sortParams(this.sortForm?.value);
  }

  resetSortForm() {
    this.sortForm.reset();
    this.filter_name = null;
    this.selectedFilter = null;
    this.sortParams(this.sortForm?.value);
  }
  saveSortForm() {
    if(this.filter_name) {
      this.saveSorts(this.sortForm?.value)
    } else {
      this.sweetAlert.errorMessage('lang_filter_name_required');
    }
  }

  convertDateToString(date) {
    if(date) {
      var mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("/");
    } else {
      return null;
    }
  }
  
}

