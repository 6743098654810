import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
interface list {
  id?: number;
  user_id: string,
  user_username: string,
  user_email: string,
  user_phone: string,
  user_status: string,
  user_type_name_en: string,
  user_type_name_ar: string,
}
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  modalRefA: BsModalRef;
  tabledatas: list[];
  collectionSize                    = 0;
  page                              = 1;
  pageSize                          = 10;
  platform_group_type_code_system   = null;
  projects_services_ids             = null;
  nodata: string                    = this.lang.transform('lang_loading');
  admin                             = JSON.parse(this.ls.getItem('is_admin'));
  lang_key                          = localStorage.getItem('lang_key');
  wholeData:any                     = [];
  tabledata:any                     = [];
  types:any                         = [];
  searchKey:any                     = "";
  userStatus:any                    = "";
  userType:any                      = null;
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.get_group_types();
    this.get_user_list();
  }
  public get_group_types() {
    this.types = [];
    this.ds.postActionByUrl([], 'users/users_types/1').subscribe( res=> {
      if(res.status) {
        this.types = res.users_list;
      }
    });
  }  
  public get_user_list() {
    let param                              = new FormData();
    param.append('search[user_status]',    this.userStatus || '');
    param.append('search[user_type_id]',   this.userType || '');
    param.append('search[search_key]',     this.searchKey || '');
    this.ds.postActionByUrl(param, 'users/1').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.wholeData      = res.users_list;
        this.tabledatas     = res.users_list;
        this.collectionSize = res.users_list.length;
        this.build_table_data();
      } else {
        this.wholeData  = [];
        this.nodata     = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholeData  = [];
      this.nodata     = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    }) 
  }
  public build_table_data() {
    this.tabledata = this.tabledatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }











  public checkallItemsUsl(event) {
    if (event.target.checked) {
      $(".appuserslists .eachItemUsl").prop("checked", true);
      $(".appuserslists .makeback").addClass("bground");
      $(".appuserslists .deleteIconDiv").show();
    } else {
      $(".appuserslists .eachItemUsl").prop("checked", false);
      $(".appuserslists .makeback").removeClass("bground");
      $(".appuserslists .deleteIconDiv").hide();
    }
  }
  public checkeachItemUsl(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appuserslists .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appuserslists .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appuserslists .eachItemUsl:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appuserslists .eachItemUsl").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appuserslists .deleteIconDiv").show();
    } else {
      $(".appuserslists .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appuserslists #allItemsUsl").prop("checked", true);
    } else {
      $(".appuserslists #allItemsUsl").prop("checked", false);
    }
  }
  public deleteBulkDataUsl() {
    const checkedtotal = [];
    $(".appuserslists .eachItemUsl:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'delete_users').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.get_user_list();
                $(".appuserslists #allItemsUsl").prop("checked", false);
                $(".appuserslists .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
