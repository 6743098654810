import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-csview',
  templateUrl: './csview.component.html',
  styleUrls: ['./csview.component.scss']
})
export class CsviewComponent implements OnInit {
  public id                     = this.route.snapshot.paramMap.get('id');
  public type                   = this.route.snapshot.paramMap.get('type');
  public data_status: boolean   = false;
  public customer_data: any     = [];
  public able2add: boolean      = false;
  public able2edit: boolean     = false;
  public able2delete: boolean   = false;
  public user_type_id:string    = this.ls.getItem('user_type_id');
  constructor(public route  : ActivatedRoute,public ds: DataService, public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
  ngOnInit(): void {
    this.spinner.show();
    this.ds.getActionByUrl([], 'cs/view/' + this.id).subscribe(res => {
      this.spinner.hide();
      this.data_status      = res.status;
      if (res.status) {
        this.customer_data  = res.data;
        this.able2add       = res.add;
        this.able2edit      = res.edit;
        this.able2delete    = res.delete;
      } else {
        this.ds.dialogf('',res.error);
      }
    }, error => {
        this.spinner.hide();
        this.data_status = false;
        this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
}
