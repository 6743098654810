<div class="card">
	<div class="card-header">
		{{ "lang_tsr_history" | language }}
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col-md-12 mt-2">
                <div class="row">
                    <ng-container *ngFor="let item of test_report_history; let i = index">
                        <div
                            class="col-12 col-sm-6 col-md-4"
                        >
                            <mat-card
                                class="card"
                                [style.border-left-color]="
                                item?.action_report_status == 'S'
                                ? '#28a745 !important'
                                : item?.action_report_status == 'F'
                                ? '#dc3545 !important'
                                : item?.action_report_status == 'NC'? 'rgb(131 17 8) !important': '#0e3768 !important'
                                "
                            >
                                <mat-card-title class="card-title">
                                    <div class="d-flex">
                                        <span *ngIf="item?.action_report_status">
                                            <span class="result_code" [style.background-color]="
                                            item?.action_report_status == 'S'
                                                ? '#28a745 !important'
                                                : item?.action_report_status == 'F'
                                                ? '#dc3545 !important'
                                                : item?.action_report_status == 'NC'? 'rgb(131 17 8) !important': '#0e3768 !important'
                                        ">{{ item?.action_report_status }}</span></span
                                        >
                                        <div class="ml-3 mr-3" *ngIf="item?.file_path">
                                            <i
                                                class="fa fa-file-pdf-o"
                                                style="color: #0e3768; font-size: 25px"
                                                matTooltip="{{ 'lang_preview' | language }}"
                                                (click)="ds?.preview_pdf_file(item?.file_path)"
                                            ></i>
                                        </div>
                                    </div>
                                    <mat-menu
                                        #menu="matMenu"
                                        [overlapTrigger]="false"
                                        xPosition="{{ lang_key == 'en' ? 'before' : 'after' }}"
                                    >
                                    <button
                                    mat-menu-item
                                    class="d-flex align-items-center justify-content-between"
                                    (click)="viewForm(item)"
                                >
                                    <span>View Details</span>
                                    <i class="fa fa-wpforms mnabricon"></i>
                                </button>
                                    </mat-menu>
                                    <button mat-icon-button [matMenuTriggerFor]="menu">
                                        <i class="fa fa-ellipsis-v"></i>
                                    </button>
                                </mat-card-title>
                                <mat-card-content>
                                    <div class="row pt-3">
                                        <div class="col-12">
                                            <div class="card-body-table">
                                                <table style="width: 100%">
                                                    <tr *ngIf="item?.group_type_name">
                                                        <td
                                                            colspan="2"
                                                            style="
                                                                text-align: center;
                                                                text-transform: uppercase;
                                                                color: #0e3768 !important;
                                                                font-weight: 600;
                                                            "
                                                        >
                                                            {{ item?.group_type_name }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{ "lang_platform_title" | language }}</th>
                                                        <td>
                                                            <span
                                                                *ngIf="item?.platform_name?.length >= 30"
                                                                matTooltip="{{ item?.platform_name }}"
                                                                >{{ item?.platform_name | shortenLength: 30 }}</span
                                                            >
                                                            <span *ngIf="item?.platform_name?.length <= 30">{{
                                                                item?.platformname
                                                            }}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{ "lang_group_one" | language }}</th>
                                                        <td>{{ item?.group1name }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{ "lang_work_level" | language }}</th>
                                                        <td>{{ item?.work_level_label }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{ "lang_platform_code_system" | language }}</th>
                                                        <td style="direction: ltr">
                                                            <span style="direction: ltr">{{
                                                                item?.projects_platform_code_system
                                                            }}</span>
                                                        </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <th>{{ "lang_by_phases" | language }}</th>
                                                        <td>{{ item?.phase_short_code }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{ "lang_request_number" | language }}</th>
                                                        <td>{{ item?.transaction_request_id }}</td>
                                                    </tr>
                                                    <tr *ngIf="setting_type == 'soil'">
                                                        <th>{{ "lang_natural_ground_level" | language }}</th>
                                                        <td>{{ item?.natural_ground_level }}</td>
                                                    </tr>
                                                    <tr *ngIf="setting_type == 'soil'">
                                                        <th>{{ "lang_foundation_level" | language }}</th>
                                                        <td>{{ item?.foundation_level }}</td>
                                                    </tr>
                                                    <tr *ngIf="setting_type == 'soil'">
                                                        <th>{{ "lang_expected_level" | language }}</th>
                                                        <td>{{ item?.expected_level }}</td>
                                                    </tr>
                                                    <tr *ngIf="setting_type == 'soil'">
                                                        <th>{{ "lang_accepted_persent_compaction" | language }}</th>
                                                        <td>{{ item?.accepted_persent_compaction }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{ "lang_writer" | language }}</th>
                                                        <td>{{ item?.writer }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{ "lang_date" | language }}</th>
                                                        <td>{{ item?.created_date_time }}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </ng-container>
                    <div
                        class="col-12"
                        style="
                            min-height: 200px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 20px;
                        "
                        *ngIf="test_report_history.length == 0"
                    >
                        <p>{{ "lang_no_data" | language }}</p>
                    </div>
                </div>
            </div>
		</div>
	</div>
</div>
