import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { SendCodeComponent } from 'app/routes/transactions/FORM_PS_TR1/send-code/send-code.component';

@Component({
  selector: 'app-ncr-step9',
  templateUrl: './ncr-step9.component.html',
  styleUrls: ['./ncr-step9.component.scss']
})
export class NcrStep9Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  stepName = 'Contractor_Close_NCR'
  @Input() form_key;
  form_request_id   = this.route.snapshot.paramMap.get('form_request_id');
  form_key2          = this.route.snapshot.paramMap.get('form_key');
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  template_id:any;
  projects_work_area_id:any; 
  platform_code_system:any;
  sendCodeForm:any;
  sendCodeStatus:boolean = false;
  rejectedValidators = [];
  verifyerrormsg: any = '';
  form:FormGroup;
  other_attachments: FormArray;
  users:any   = [];
  lang_key:string;
  filename    = this.lang.transform('lang_select_files');
  page_no            = '1';
  page_size          = '10';
  fixing_way_confirm_array = [
    {
      label:this.lang.transform("lang_yes"),
      value: '1'
    },
    {
      label:this.lang.transform("lang_no"),
      value: '0'
    }
  ];
  manager_contractor_required;
  constructor(public ds: DataService, public router: Router, private sweetAlert:SweetAlertService, public route: ActivatedRoute, public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog) {}
  ngOnInit(): void {
    this.form = new FormGroup({
      transaction_request_id           : new FormControl(this.form_request_id, Validators.required),
      note                             : new FormControl(''),
      attachments                      : this.fb.array([]),
      // transactions_persons_action_code : new FormControl('', [Validators.required])
    });
    this.other_attachments = this.form.get("attachments") as FormArray;
    this.manager_contractor_required = this.allData?.view_request?.form_ncr_data?.records[0]?.manager_contractor_required; 
    if(!parseInt(this.manager_contractor_required)) {
      this.addfiles({attach_title: 'Request close NCR PDF'})
    }
  }

  get filesFormGroup() {
    return this.form.get("attachments") as FormArray;
  }
  
  public files(value?): FormGroup {
    return this.fb.group({
      delete_icon     : new FormControl(value?.attach_title || ''),
      attach_title    : new FormControl({value : (value) ? value?.attach_title : '', disabled : (value?.attach_title) ? true : false}, [Validators.required]),
      file            : new FormControl(null,  [Validators.required]),
    });
  }
  public addfiles(value?) {
    if (this.other_attachments.length < 10) {
      this.other_attachments.push(this.files(value));
      this.other_attachments.setValidators(Validators.required);
      this.other_attachments.updateValueAndValidity();
    }
  }
  public removefiles(index) {
    if(this.other_attachments.length == 1) {
      this.other_attachments.removeAt(index);
      this.other_attachments.clearValidators();
      this.other_attachments.updateValueAndValidity();
    } else {
      this.other_attachments.removeAt(index);
    }
  }
  
  checkValidation() {
    if(this.form.get('note').invalid) {
      this.sweetAlert.errorMessage(this.lang.transform('lang_note_required'));
      return false;
    } 
    // else if(this.form.get('transactions_persons_action_code').invalid) {
    //   this.sweetAlert.errorMessage(this.lang.transform('lang_send_code_required'));
    //   return false;
    // } 
    else {
      return true;
    }  
  }

  completeStep() {
    if(this.checkValidation()) {
      this.spinner.show();
      let param = new FormData();
      param.append('transaction_request_id', this.form_request_id)
      param.append('close_ncr_request_notes', this.form.get('note').value);
      // param.append('transactions_persons_action_code', this.form.get('transactions_persons_action_code').value);
      if(this.form.get('attachments')?.value?.length) {
        this.filesFormGroup?.controls?.forEach((v, k) => {
          param.append("attachments[" + k + "][file]", v.get('file').value);
          param.append("attachments[" + k + "][required]", 'Yes');
          param.append("attachments[" + k + "][attach_title]", v.get('attach_title').value);        
        });
      }
      this.ds.post(`/form/${this.form_key}/Contractor_Approval_Step9/0`, param).subscribe(data => {
        if(data.status) {
          this.spinner.hide();
          this.sweetAlert.successToast(this.lang.transform('lang_data_sent_successfully'), 1700);
          setTimeout(() => {
            this.refreshComponent.emit(true);
          }, 2000);
        } else {
          this.spinner.hide();
          this.alert.error(data.error)
        }
      }, (error) => {
        this.spinner.hide();
          this.alert.error(this.lang.transform('lang_server_error'));
      })
    } 
  }

  openModal() {
    const dialogRef = this.dialog.open(SendCodeComponent,{
      disableClose: true,
      width:'400px',
      data: {transaction_persons_type: 'signature', allData: this.allData, stepName:this.stepName,title:'lang_signature'}
      
    });
  }

}
