import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ITreeOptions, TreeComponent, TreeNode } from '@circlon/angular-tree-component';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-supervision-contractor-payments',
  templateUrl: './supervision-contractor-payments.component.html',
  styleUrls: ['./supervision-contractor-payments.component.scss']
})
export class SupervisionContractorPaymentsComponent implements OnInit {
  @Input() supervision_data
  @Input() project_data
  lang_key = localStorage.getItem('lang_key') || 'en';
  nodes: any = [];
  @ViewChild('tree') tree:TreeComponent;
  phase_parent_id:any = "0";
  options: ITreeOptions = {    
    getChildren: (node:TreeNode) => {
      return [
        
      ];
    },
    useCheckbox: false,
    rtl: this.lang_key == 'ar'? true : false
  };  
  filterText      = '';
  treePath        = '';
  hideTreeContent = false;
  hideTreeMenu    = false;
  selectedNode    = null;
  constructor(public ds:DataService, private sweetAlert:SweetAlertService, public lang:LanguagePipe, public ls:LocalStorage, public spinner:PreloaderService, public alert:AlertService, public modalService: BsModalService, public dialog:MatDialog, public fb:FormBuilder) { }

  ngOnInit(): void {
    this.get_implementation_phases();
  }

  downloadExcel() {

  }
  
  uploadExcel(files) {

  }

  getChildren(node: any) {
    if(!node?.data?.children) {
      if(node?.data?.phase_type == 'U') {
        this.sweetAlert.errorMessage('Not Found Data');
      } else {
        this.spinner.show();
        let param = new FormData();
        param.append('phase_parent_id', node.data.phase_id);
        this.ds.getActionByUrl(this.ds.formData2string(param), 'joYF29rbEi/'+this.supervision_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id).subscribe(res => {
          this.spinner.hide();
          if(res.status) {
            node.data.children = res.records;
            this.tree.treeModel.update();
          }
        });
      }
    }
  
  }
  onToggleTree(e) {
    if(e.isExpanded) {
      this.getChildren(e.node);
    }
  
  }
  get_implementation_phases(treeModel?) {
    this.spinner.show();
		let param = new FormData();
    param.append('search_key', '');
    param.append('phase_type', '');
    param.append('phase_parent_id', this.phase_parent_id);
		this.ds.getActionByUrl(this.ds.formData2string(param), 'joYF29rbEi/'+this.supervision_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id).subscribe(res => {
      this.spinner.hide();
			if(res.status) {     
        if(this.phase_parent_id == "0") {
          this.nodes      = res.records;
          console.log(this.tree);
          
        } else {
          treeModel.update();
        }
        // this.phaseData  = res.config;
        
			} else {
        this.nodes = [];
        // this.phaseData = [];
      }
		}, error => {
      this.spinner.hide();
      this.nodes = [];
      // this.phaseData = [];
		})
  }
  public toggleViewAction(tree: any) {
    this.getSelectedPath(tree);
    if (tree?.treeModel?.activeNodes.length <= 0) {
      // this.viewPhaseDataStatus = false;
      this.hideTreeContent = true;
    } else {
      // this.viewPhaseDataStatus = true;
      // this.viewPhaseData = tree.activeNodes[0].data;
      this.hideTreeContent = false;
    }
  }

  getSelectedPath(tree) {
    let ids = tree?.treeModel?.activeNodes[0]?.path;
    let path = '';
    for(let i=0; i < ids.length; i++) {
      if(i < ids.length - 1) {
        path = path + tree?.treeModel?.getNodeById(ids[i])?.data?.name + `<i class="fa fa-chevron-${this.lang_key == 'ar' ? 'left':'right'} path-arrow"></i>`
      } else {
        path = path + tree?.treeModel?.getNodeById(ids[i])?.data?.name;
        this.selectedNode = tree?.treeModel?.getNodeById(ids[i]);
      }
      
    }
    this.treePath = path;
  }
  deactivate(e) {
    if(e?.treeModel?.activeNodes?.length < 1) {
      this.hideTreeContent = true;
    } else {
      this.hideTreeContent = false;
    }
  }
  filterTree(e) {
    this.tree.treeModel.filterNodes(e?.target?.value, true);
  }
  onHideTreeMenu() {
    this.hideTreeMenu = false;
    console.log(this.selectedNode);
    setTimeout(() => {
      this.tree?.treeModel?.getNodeById(this.selectedNode?.data?.id).setActiveAndVisible();
    });
    
  }
}
