import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
interface list {
  id?: number;
  transaction_request_id: string,
  transaction_key: string,
  transactions_cronjob_try_to_submitting: string,
  transactions_cronjob_last_update: string,
  transactions_cronjob_err: string
}
@Component({
  selector: 'app-cron',
  templateUrl: './cron.component.html',
  styleUrls: ['./cron.component.scss'],
  animations: [
    trigger("fadeIn", [
      state(
        "void",
        style({
          opacity: 0
        })
      ),
      transition("void <=> *", animate(1000))
    ]),
  ]
})
export class CronComponent implements OnInit {
  admin = JSON.parse(this.ls.getItem('is_admin'));
  page: number = 1;
  pageSize: number = 10;
  collectionSize;
  listofdatas: list[];
  listData: any = [];
  nodata: string = this.lang.transform('lang_loading');
  constructor(public ds:DataService, public ls:LocalStorage, public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public dialog : MatDialog) { }
  ngOnInit(): void {
    this.spinner.show();
    this.get_cron_lists();
  }
  public get_cron_lists() {
    this.listData = [];
    this.ds.getActionByUrl([], 'tc/cronjoblist').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.listofdatas = res.list.records;
        this.collectionSize = res.total;
        this.refreshLists();
      } else {
        this.listData = [];
        this.nodata = this.lang.transform('lang_no_data');
      }
    }, error => {
        this.spinner.hide();
        this.listData = [];
        this.nodata = this.lang.transform('lang_no_data');
    })
  }
  refreshLists() {
    this.listData = this.listofdatas.map(
      (list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize
    );
  }
  run_cron() {
    this.spinner.show();
    this.ds.getActionByNoToken([], 'cron/processor_all_transactions', null).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.get_cron_lists();
        this.ds.dialogf('',res.msg);
      } else {
        this.ds.dialogf('', res.error);
      }
    }, error => {
        this.spinner.hide();
        this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  deleteCronJob(transaction_request_id) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show()
        this.ds.deleteActionByUrl([], 'tc/djob/'+transaction_request_id).subscribe(
          (data) => {
            this.spinner.hide()
            if (data.status) {
              this.get_cron_lists();
              this.ds.dialogf('', data.msg);
            } else {
              this.ds.dialogf('', data.error);
            }
          },
          (error) => {
            this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
            this.spinner.hide()
          }
        );
      }
    });
  }
}
