<div class="row">
    <div class="col-12 col-md-3 mb-2">
        <section class="left-content">
d
        </section>
    </div>
    <div class="col-12 col-md-6 mb-2">
        <section class="center-content">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="ticket-title-container">
                        <input type="text" class="ticket-title" placeholder="Tab here to add ticket title..">
                    </div>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
                    <button mat-button class="ticket-btn">
                        <i class="fa fa-paperclip"></i>
                        <span>Link Issue</span>
                    </button>
                </div>
                <div  class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
                    <button mat-button class="ticket-btn">
                        <i class="fa fa-link"></i>
                        <span>Attach</span>
                    </button>
                </div>
                <div  class="col-12 col-md-4 col-lg-3 mb-2">
                    <ng-select
                        [items]="priority_list"
                        [multiple]="false"
                        appendTo="body"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                        placeholder="priority"
                        [(ngModel)]="priority_filter"
                        >
                    </ng-select>
                </div>
                <div class="col-12">
                    <hr>
                </div>
                <div class="col-12">
                    <label for="description">Description</label>
                    <textarea 
                        name="description" 
                        id="description" 
                        cols="30" 
                        rows="4" 
                        class="form-control"
                        placeholder="Tab here to add some description for your ticket.."
                        [(ngModel)]="description"
                        >
                    </textarea>
                </div>
            </div>
            
        </section>
    </div>
    <div class="col-12 col-md-3 mb-2">
        <section class="right-content">
d
        </section>
    </div>
</div>