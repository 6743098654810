import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorage } from './localstorage.service';
@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(private ds: DataService, private ls : LocalStorage) { }
  load(): Promise<any> {
    if(this.ls.getItem('is_admin') == "0") {
      return new Promise((resolve, reject) => {
        this.ds.post('permits/user',[]).pipe(
            catchError(res => {
              resolve(null);
              return throwError(res);
            })
          ).subscribe(
            (res: any) => {
              if(res.status) {
                this.ds.permissions = res.records;
              }
            },
            () => {
              reject();
            },
            () => {
              resolve(null);
            }
          );
      });
    } else {
      return null;
    }
  }
}
