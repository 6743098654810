<div class="card supervision_reports">
    <div class="card-header">{{'lang_report' | language }} </div>
    <div class="card-body pad0">
        <div class="mbrl15">
            <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                    <select (change)="spinner.show();search_supervision_reports();" [(ngModel)]="reports_status" class="form-control almnabrformcontrol">
                        <option value="1" selected>{{'lang_active' | language }}</option>
                        <option value="0">{{'lang_inactive' | language }}</option>
                        <option value="">{{'lang_all' | language }}</option>
                    </select>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                    <ng-select 
                        [items]="report_types" 
                        [multiple]="false" 
                        bindLabel="label" 
                        bindValue="value" 
                        appendTo="body" 
                        clearAllText="Clear"
                        placeholder="{{'lang_report_type' | language}}"
                        [(ngModel)]="project_report_type"
                        (change)="spinner.show();search_supervision_reports();">
                    </ng-select>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control almnabrformcontrol h34" [(ngModel)]="reportsearchKey" (keyup)="search_supervision_reports();" placeholder="{{'lang_search' | language }}" />
                        <span class="input-group-btn" *ngIf="able2add">
                            <button id="btnSearch" type="button" matTooltip="{{'lang_upload_reports' | language }}" class="btnSearch h34 btn btn-md btn-gray fa fa-upload mnabricon" (click)="emptyaddform();openModal(uploadreports)"></button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive dropdownyes customResponsive">
                        <table border="1" class="table CustomTable">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>
                                        <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                            <input type="checkbox" class="AllItemsRep checkbox" id="AllItemsRep" (click)="checkAllItemsRep($event)"/>
                                            <label for="checkbox"></label>
                                        </div>
                                    </td>
                                    <td>{{'lang_report_type' | language }}</td>
                                    <td>{{'lang_description' | language }}</td>
                                    <td>{{'lang_file_level' | language }}</td>
                                    <td>{{'lang_date' | language }}</td>
                                    <td>{{'lang_report_status' | language }}</td>
                                    <td>{{'lang_writer' | language }}</td>
                                    <td>{{'lang_ondate' | language }}</td>
                                    <td>{{'lang_action' | language }}</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="repListData.length != '0' " class="AppendList">
                                <tr *ngFor="let list of repListData; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{list.reports_id}}">
                                    <td>{{i+1}}</td>
                                    <td>
                                        <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                            <input type="checkbox"  class="eachItemRep checkbox" value="{{list.reports_id}}" (click)="checkEachItemRep($event)"/>
                                            <label for="checkbox"></label>
                                        </div>
                                    </td>
                                    <td>{{list.report_type}}</td>
                                    <td>{{list.reports_description}}</td>
                                    <td>{{list.level_keys}}</td>
                                    <td>{{list.reports_date}}</td>
                                    <td *ngIf="list.reports_status == '0'">
                                        <i class="fa fa-icon fa-circle red" matTooltip="{{'lang_inactive' | language }}" aria-hidden="true"></i>
                                    </td>
                                    <td *ngIf="list.reports_status == '1'">
                                        <i class="fa fa-icon fa-circle green" matTooltip="{{'lang_active' | language }}" aria-hidden="true"></i>
                                    </td>
                                    <td>{{list.writer}}</td>
                                    <td>{{list.created_datetime}}</td>
                                    <td>
                                        <i class="together-icons">
                                            <i *ngIf="list.reports_attachment_url" matTooltip="{{'lang_file' | language }}" (click)="ds.previewData(list.reports_attachment_url,'#pdfPreviewRep')" class="fa fa-paperclip fa-icon s2icon mnabricon" ></i>
                                            <i *ngIf="able2edit" matTooltip="{{'lang_edit' | language }}" (click)="emptyEditform(list);openModal(uploadreports)" class="fa fa-pencil-square-o fa-icon s2icon mnabricon"></i>
                                        </i>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="repListData.length == '0' " class="AppendList">
                                <tr class="odd">
                                    <td colspan="10" align="center">{{repnodata}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="repListData.length != '0' " class="card-footer padb0">
            <div class="row">
                <div *ngIf="able2delete" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                    <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataRep()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                </div>
                <div class="col-lg-2 col-sm-6 col-6  mb-10">
                    {{'lang_total' | language }} : {{repListData.length}}
                </div>
                <div class="col-lg-5 col-sm-6 mb-10">
                    <ngb-pagination [collectionSize]="collectionSize" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshReports()"></ngb-pagination>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <select class="customselect" [(ngModel)]="pageSize" (change)="refreshReports()">
                        <option [ngValue]="10">10</option>
                        <option [ngValue]="20">20</option>
                        <option [ngValue]="50">50</option>
                        <option [ngValue]="100">100</option>
                        <option [ngValue]="250">250</option>
                        <option [ngValue]="500">500</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #uploadreports>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_report' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()">&times;</button>
        </div>
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="modal-body">            
                <div class="form-group">
                    <label for="project_settings_id">{{'lang_report_type' | language }}:<span class="red">*</span></label>
                    <select type="text" class="form-control almnabrformcontrol" formControlName="project_settings_id">
                        <option value="">{{'lang_choose_options' | language}}</option>
                        <option *ngFor="let type of report_types" value="{{type.value}}">{{type.label}}</option>                        
                    </select>
                    <mat-error *ngIf="form.controls['project_settings_id'].touched && form.controls['project_settings_id'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="reports_date">{{'lang_date' | language }}:<span class="red">*</span></label>                    
                    <div class="input-group input-group-sm">
                        <input [matDatepicker]="reportdate" formControlName="reports_date" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" required readonly>
                        <span class="input-group-btn">
                            <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="reportdate"></mat-datepicker-toggle>
                            <mat-datepicker #reportdate></mat-datepicker>
                        </span>
                        <span class="input-group-btn">
                            <button (click)="form.get('reports_date').setValue('') " matTooltip="{{'lang_empty' | language}}" id="btnSearch" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                        </span>
                    </div>
                    <mat-error *ngIf="form.controls['reports_date'].touched && form.controls['reports_date'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="reports_status">{{'lang_report_status' | language }}:<span class="red">*</span></label>
                    <select type="text" class="form-control almnabrformcontrol" formControlName="reports_status" #reporttypestatus (ngModelChange)="change_report_status(reporttypestatus.value)">
                        <option value="">{{'lang_choose_options' | language}}</option>
                        <option value="1">{{'lang_yes' | language}}</option>
                        <option value="0">{{'lang_no' | language}}</option>
                    </select>
                    <mat-error *ngIf="form.controls['reports_status'].touched && form.controls['reports_status'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
                <div class="form-group">
                    <label for="reports_description" *ngIf="reporttypestatus.value == '1' ">{{'lang_description' | language }}:<span class="red">*</span></label>
                    <label for="reports_description" *ngIf="reporttypestatus.value != '1' ">{{'lang_report_reason' | language }}:<span class="red">*</span></label>
                    <input type="text" class="form-control almnabrformcontrol" formControlName="reports_description">                        
                    <mat-error *ngIf="form.controls['reports_description'].touched && form.controls['reports_description'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
                <div class="form-group" *ngIf="reporttypestatus.value == '1' ">
                    <label for="reports_attachment_url">{{'lang_attachments' | language }}: <span class="red" *ngIf="!form.get('reports_id').value"> * </span></label>
                    <div class="IDDIV">
                        <input style="display: block;" name="reports_attachment_url" type="file" required onclick="this.value = null" (change)="UploadFile($event, form.get('reports_attachment_url'))" class="nghide albutton width100">
                        <label id="uploadText" class="albutton">{{filename}} </label>  
                    </div>
                    <mat-error *ngIf="form.controls['reports_attachment_url'].touched && form.controls['reports_attachment_url'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
                <div class="form-group">
                    <label for="level_keys">{{'lang_file_secutrity_levels' | language }} : </label>
                    <ng-select 
                        [items]="filelevels" 
                        [multiple]="true" 
                        bindLabel="label" 
                        appendTo="body" 
                        bindValue="value" 
                        clearAllText="Clear"
                        placeholder="{{'lang_file_secutrity_levels' | language}}"
                        formControlName="level_keys">
                    </ng-select>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRef.hide()">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<div class="modal" id="pdfPreviewRep">
    <div class="modal-dialog width70">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{'lang_preview' | language }}</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="pdf-container">
            <iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'lang_close' | language }}</button>
        </div>
      </div>
    </div>
</div>