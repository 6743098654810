import { CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { PreloaderService, SettingsService, TokenService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';

@Component({
  selector: 'app-user-panel',
  template: `
    <div class="matero-user-panel" fxLayout="column" fxLayoutAlign="center center">
    <div class="userActiveStatus">
      <i class="fa s2icon userStatusIcon" [ngClass]="{ 
        'fa-check-circle red' : status == '0',
        'fa-check-circle-o green' : status == '1',
        'fa-clock-o yellowcolor' : status == '2',
        'fa-minus-circle red' : status == '3',
        'fa-circle graycolor' : status == '4'
      }" matTooltip="{{statustooltip}}" [matMenuTriggerFor]="activeMenu"></i>
      <img class="matero-user-panel-avatar avatarimage" [src]="avatar" alt="avatar" width="64"/>
    </div>
    <mat-menu #activeMenu="matMenu">
      <button mat-menu-item (click)="change_user_active_status('1');"><span>{{'lang_active' | language}}</span></button>
      <button mat-menu-item (click)="change_user_active_status('2');"><span>{{'lang_away' | language}}</span></button>
      <button mat-menu-item (click)="change_user_active_status('3');"><span>{{'lang_dont_distrub' | language}}</span></button>
      <button mat-menu-item (click)="change_user_active_status('4');"><span>{{'lang_invisible' | language}}</span></button>
    </mat-menu>
      <span class="mb-10"><i class="fa fa-user mr-2 ml-2 mnabricon"></i> {{ name }}</span>
      <span *ngIf="mobile" class="m-0"><i class="fa fa-phone mr-2 ml-2 mnabricon"></i> <a href="skype:+{{mobile}}">+{{ mobile }}</a></span>
      <p *ngIf="email"><i class="fa fa-envelope mr-2 ml-2 mnabricon"></i> <a href="mailto:{{email}}">{{ email }}</a></p>
      <div class="matero-user-panel-icons">
        <a routerLink="{{profileurl}}" matTooltip="{{'lang_myprofile' | language}}" mat-icon-button>
          <mat-icon>account_circle</mat-icon>
        </a>
        <a href="javascript:void(0);" [matMenuTriggerFor]="uMenu" matTooltip="{{'lang_settings' | language}}" mat-icon-button>
          <mat-icon>settings</mat-icon>
        </a>
        <mat-menu #uMenu="matMenu">
          <button mat-menu-item routerLink="/default/cpassword"><span>{{ 'lang_change_password' | language }}</span></button>
        </mat-menu>
        <a href="javascript:void(0);" (click)="openPanel($event)" matTooltip="{{'lang_change_theme' | language}}" mat-icon-button>
          <mat-icon>invert_colors</mat-icon>
        </a>
        <a (click)="logout()" matTooltip="{{'lang_logout' | language}}" mat-icon-button>
          <mat-icon>exit_to_app</mat-icon>
        </a>
      </div>
    </div>
    <div [ngClass]="{ 'customizer-panel': true, 'mat-elevation-z12 opened': opened }">
    <br>
    <label class="f-w-400">Basic Theme</label>
    <hr>
    <div class="m-l-8">
      <mat-radio-group [(ngModel)]="options.theme" (change)="sendOptions()">
        <mat-radio-button class="m-r-8" [value]="'light'">Light</mat-radio-button>
        <mat-radio-button class="m-r-8" [value]="'dark'">Dark</mat-radio-button>
      </mat-radio-group>
    </div>
    <app-theme></app-theme>
    </div>
    <div [ngClass]="{ 'customizer-panel-overlay': true, 'opened': opened }" (click)="closePanel()"></div>
  `,
  styleUrls: ['./user-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserPanelComponent {
  name:string;
  email:string;
  mobile:string;
  avatar:string;
  options = this.settings.getOptions();
  opened = false;
  dragging = false;
  @Output() optionsChange = new EventEmitter<object>();
  status:any = '0';
  statustooltip = "";
  profileurl = "";
  constructor(
    public spinner:PreloaderService,
    private ls:LocalStorage,private token : TokenService, private settings: SettingsService, public lang:LanguagePipe, public ds : DataService) {
      if(this.ls.getItem('user_type_id') == "1") {
        this.profileurl = "/default/profile";
      } else {
        this.profileurl = "/default/oprofile";
      }
      this.name = this.ls.getItemString('user_username') || '';
      this.email = this.ls.getItemString('user_email') || '';
      this.mobile = this.ls.getItemString('user_phone') || '';
      this.status = this.ls.getItemString('user_active_status') || '0';
      this.avatar = './assets/images/avatar.png';
      if(this.status == "1") {
        this.statustooltip = this.lang.transform('lang_active');
      } else if(this.status == "2") {
        this.statustooltip = this.lang.transform('lang_away');
      } else if(this.status == "3") {
        this.statustooltip = this.lang.transform('lang_dont_distrub');
      } else {
        this.statustooltip = this.lang.transform('lang_invisible');
      }
      this.getUserAvatar();
  }
  logout() {
    this.token.logout();
  }

  handleDragStart(event: CdkDragStart): void {
    this.dragging = true;
  }
  openPanel(event: MouseEvent) {
    if (this.dragging) {
      this.dragging = false;
      return;
    }
    this.opened = true;
  }
  closePanel() {
    this.opened = false;
  }
  togglePanel() {
    this.opened = !this.opened;
  }
  sendOptions() {
    this.optionsChange.emit(this.options);
    localStorage.setItem('theme', this.options.theme);
    window.location.reload();
  }
  public change_user_active_status(status) {
    this.spinner.show();
    this.ds.getActionByUrl([], 'cuastatus/'+status).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.ls.getsetdata('user_active_status', status);
        this.status = status;
        if(this.status == "1") {
          this.statustooltip = this.lang.transform('lang_active');
        } else if(this.status == "2") {
          this.statustooltip = this.lang.transform('lang_away');
        } else if(this.status == "3") {
          this.statustooltip = this.lang.transform('lang_dont_distrub');
        } else {
          this.statustooltip = this.lang.transform('lang_invisible');
        }
      } else {
        this.ds.dialogf('', res.error);
      }
    }, error => {
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      this.spinner.hide();
    })
  }

  getUserAvatar() {
    let user = localStorage.getItem('usr')? JSON.parse(localStorage.getItem('usr')): {};
    let params = {
      user_id:user['id'],
      user_username:user['username']
    }
    this.ds.getActionByNoTokenFullUrl(params, 'user/avatar').subscribe(res => {
      if(res?.avatar) {
        this.avatar = res?.avatar;
      } else {
        this.avatar = './assets/images/avatar.png';
      }
    })
  }
}