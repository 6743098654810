import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-ticket',
  templateUrl: './add-ticket.component.html',
  styleUrls: ['./add-ticket.component.scss']
})
export class AddTicketComponent implements OnInit {
  priority_list = [
    {
      label: 'Low',
      value: 'low'
    },
    {
      label: 'Medium',
      value: 'medium'
    },
    {
      label: 'High',
      value: 'high'
    }
  ];
  priority_filter = null;
  description     = null;
  constructor() { }

  ngOnInit(): void {
  }

}
