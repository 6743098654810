<!-- <div class="w-full h-full" fxLayout="row wrap" [@fade]="'in'">
  <mat-card class="mat-elevation-z4 m-auto" style="max-width: 380px;">
    <br>
    <div class="imageContainer">
      <img *ngIf="!profileimage" class="img-responsive avatarimage" src="./assets/images/avatar.png">
      <img *ngIf="profileimage" class="img-responsive avatarimage" src="{{profileimage}}">
    </div>
  <app-alert id="default-alert"></app-alert>
  <form class="form-field-full" [formGroup]="form" (ngSubmit)="login()">
      <mat-form-field>
        <mat-label>{{ 'lang_username' | language }}:</mat-label>
        <input matInput formControlName="username" required (keyup)="getProfileImg($event)">
      </mat-form-field>
      <mat-form-field >
        <mat-label>{{'lang_password' | language}}:</mat-label>
        <input type="password" matInput formControlName="password" required>
      </mat-form-field>      
      <div class="m-y-16" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-checkbox>{{'lang_remember_me' | language}}</mat-checkbox>
      </div>
      <input type="submit" class="mnabrbutton mat-accent" value="{{'lang_login' | language}}">
    </form>
    <br>
    <button type="button" mat-button  [matMenuTriggerFor]="menu" class="col-12 mnabrbutton mat-accent"> {{ 'lang_languages' | language }}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let lang of languages" (click)="switchlanguage(lang)">
        <span>{{ lang.lang_name | language }}</span>
      </button>
    </mat-menu>
    <br>
    <br>
    <div class="row text-center mb-10">
      <div class="col-md-6 pad0">
        <a routerLink="/status">{{'lang_check_app_status' | language}}</a>
      </div>
      <div class="col-md-6 pad0">
        <a routerLink="/signup">{{'lang_join_almnabr' | language}}</a>
      </div>
    </div>
    <div class="text-center">
      <hr>
      <a routerLink="/forget">{{'lang_forgot_password' | language}}</a>
      <hr>
      <a routerLink="/reset">{{'lang_reset_login' | language}}</a>
    </div>   
  </mat-card>
</div> -->


<!-- <div style="padding: 0 10px; background: linear-gradient(135deg,  #293280 0%, #393D79 100%) !important;">
  <div class="row"  [attr.style]="lang_key == 'en'? 'height: 100vh; margin-left: -15px !important': 'height: 100vh; margin-right:-15px !important'">
    <div class="d-none d-lg-block col-lg-4 p-0">
      <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig2">
        <div ngxSlickItem *ngFor="let item of slides" style="height: 100%; cursor: grab;">
          <div class="position-relative bg-plum-plate" style="height: 100%;">
            <div style="background: url({{item.img}}) no-repeat; background-size: cover ;height: 100vh;" >
              <div class="slider-content" style="height: 100vh">
                
              </div>
            </div>
            
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
    <div class="col-lg-8">
      <div class="login-form">
        <form [formGroup]="form" (ngSubmit)="login()" style="padding: 60px 30px; background: #fff; border-radius: 10px;">
          <div class="row">
            <div class="col-12 col-sm-6 mb-2" style="display: flex; align-items: center; justify-content: center;">
              <div>
                <img src="./assets/images/logo.svg" alt="logo" width="60px" class="logoImg">
                <img src="./assets/images/logonname.svg" alt="logonname" width="150px" style="margin: 0 20px;" class="logoNameImg">
              </div>
            </div>
            <div class="col-12 col-sm-6 mb-2">
              <div class="imageContainer">
                <img *ngIf="!profileimage" class="img-responsive avatarimage" src="./assets/images/avatar.png">
                <img *ngIf="profileimage" class="img-responsive avatarimage" src="{{profileimage}}">
              </div>
            </div>
            <div class="col-12 col-sm-6" [dir]="lang_key == 'en' ? 'ltr':'rtl'">
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>{{ 'lang_username' | language }}</mat-label>
                <input matInput formControlName="username" required (focusout)="getProfileImg($event)">
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6" [dir]="lang_key == 'en' ? 'ltr':'rtl'">
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>{{'lang_password' | language}}</mat-label>
                <input matInput type="password" formControlName="password" required>
              </mat-form-field>
            </div>
            <div class="col-12 pb-3" style="text-align: end;">
              <mat-slide-toggle>{{'lang_remember_me' | language}}!</mat-slide-toggle>
            </div>
            <div class="col-12 col-sm-6 mb-2">
              <input type="submit" class="mnabrbutton mat-accent" value="{{'lang_login' | language}}">
            </div>
            <div class="col-12 col-sm-6">
              <button type="button" mat-button  [matMenuTriggerFor]="menu" class="col-12 mnabrbutton mat-accent"> {{ 'lang_languages' | language }}
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item *ngFor="let lang of languages" (click)="switchlanguage(lang)">
                  <span>{{ lang.lang_name | language }}</span>
                </button>
              </mat-menu>
            </div>
            <div class="col-12">
              <hr>
            </div>
            <div class="col-12 col-sm-6 d-flex justify-content-between text-center" style="flex-wrap: wrap; flex-direction: column;">
              <a routerLink="/status">{{'lang_check_app_status' | language}}</a>
              <a routerLink="/forget">{{'lang_forgot_password' | language}}</a>
            </div>
            <div class="col-12 col-sm-6 d-flex justify-content-between text-center" style="flex-wrap: wrap; flex-direction: column;">
              <a routerLink="/signup">{{'lang_join_almnabr' | language}}</a>
              <a routerLink="/reset">{{'lang_reset_login' | language}}</a>
            </div>
            <div class="col-12 mt-2">
              <app-alert id="default-alert"></app-alert>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->
<form [formGroup]="form" (ngSubmit)="login()">
  <div class="login-bg">
    <div class="login-overlay">
      <div class="login-header">
        <div class="languages">
          <mat-form-field appearance="standard" style="width: 100%;">
            <mat-select [(value)]="lang_key">
              <mat-option [value]="lang.lang_key" *ngFor="let lang of languages" (click)="switchlanguage(lang)">{{ lang.lang_name | language }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="logo">
          <img src="assets/images/login-logo.svg" alt="">
        </div>
      </div>
      <div class="login-body">
        <h3>{{"lang_welcome" | language}}</h3>
        <input type="text" class="mnabr-login-input" formControlName="username" required placeholder="{{ 'lang_username' | language }}" [attr.style]="lang_key == 'ar'? 'border-right: 10px solid #0e3768a3;border-left:none;':'border-left: 10px solid #0e3768a3;border-right:none'">
        <input type="password" class="mnabr-login-input" formControlName="password" required placeholder="{{ 'lang_password' | language }}" [attr.style]="lang_key == 'ar'? 'border-right: 10px solid #0e3768a3;border-left:none;margin-top:20px':'border-left: 10px solid #0e3768a3;border-right:none;margin-top:20px'">
        <div class="remember-me pt-2">
          <div>
            <mat-checkbox 
                [color]="'primary'">
                {{'lang_remember_me' | language}}
          </mat-checkbox>
          </div>
          <div>
            <a routerLink="/forget">{{'lang_forgot_password' | language}}</a>
          </div>
        </div>
        <input type="submit" class="mat-accent submit-btn" value="{{'lang_login' | language}}">
        <p style="color: #fff;margin-top: 20px;"><a routerLink="/signup" style="color: #fff;text-decoration: underline;">{{'lang_join_almnabr' | language}}</a></p>
        <div class="remember-me pt-2">
          <div>
            <a routerLink="/status">{{'lang_check_app_status' | language}}</a>
          </div>
          <div>
            <a routerLink="/reset">{{'lang_reset_login' | language}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
