<div class="mbrl15 supervision_lists">
	<div class="row">
		<div class="col-12">
			<div class="form-group">
				<input
					type="text"
					class="form-control almnabrformcontrol searchKey"
					[(ngModel)]="searchKey"
					id="searchKey"
					(keyup)="load_supervision_lists()"
					placeholder="{{ 'lang_search' | language }}"
				/>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable">
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_project_number" | language }}</td>
							<td *ngIf="isQuotationExists">{{ "lang_quotation_no" | language }}</td>
							<td>{{ "lang_subject" | language }}</td>
							<td *ngIf="isQuotationExists">{{ "lang_grand_total" | language }}</td>
							<td *ngIf="isQuotationExists">{{ "lang_tax_amount" | language }}</td>
							<td *ngIf="isQuotationExists">{{ "lang_net_amount" | language }}</td>
							<td *ngIf="isQuotationExists">{{ "lang_approval_date" | language }}</td>
							<td *ngIf="isQuotationExists">{{ "lang_writer" | language }}</td>
							<td *ngIf="isQuotationExists">{{ "lang_ondate" | language }}</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody *ngIf="listData.length != '0'" class="AppendList">
						<tr
							*ngFor="
								let list of listData.records;
								let i = index;
								let odd = odd;
								let even = even
							"
							[ngClass]="{ odd: odd, even: even }"
							class="{{ list.row_color }}{{ list.row_color_flash }}"
						>
							<td>{{ i + 1 }}</td>
							<td>
								<a
									routerLink="/projects/psmaindashboard/{{
										project_data.projects_profile_id
									}}/{{ list.projects_supervision_id }}"
								>
									{{ list.projects_supervision_id }}
								</a>
							</td>
							<td *ngIf="isQuotationExists">
								<a
									routerLink="/projects/psmaindashboard/{{
										project_data.projects_profile_id
									}}/{{ list.projects_supervision_id }}"
								>
									{{ list.projects_quotation_id }}
								</a>
							</td>
							<td>
								<a
									routerLink="/projects/psmaindashboard/{{
										project_data.projects_profile_id
									}}/{{ list.projects_supervision_id }}"
								>
									{{ list.quotation_subject }}
								</a>
							</td>
							<td *ngIf="isQuotationExists">
								{{
									list.quotation_grand_total
										? ds.addCommas(list.quotation_grand_total)
										: "--"
								}}
							</td>
							<td *ngIf="isQuotationExists">
								{{
									list.quotation_tax_amount
										? ds.addCommas(list.quotation_tax_amount)
										: "--"
								}}
							</td>
							<td *ngIf="isQuotationExists">
								{{
									list.quotation_net_amount
										? ds.addCommas(list.quotation_net_amount)
										: "--"
								}}
							</td>
							<td *ngIf="isQuotationExists">{{ list.quotation_approved_date }}</td>
							<td *ngIf="isQuotationExists">{{ list.writer }}</td>
							<td *ngIf="isQuotationExists">{{ list.projects_supervision_created_datetime }}</td>
							<td>
								<mat-icon
									class="mnabricon"
									[matMenuTriggerFor]="menu"
									matTooltip="{{ 'lang_settings' | language }}"
									>settings</mat-icon
								>
								<mat-menu #menu="matMenu">
									<button
										mat-menu-item
										routerLink="/projects/psmaindashboard/{{
											project_data.projects_profile_id
										}}/{{ list.projects_supervision_id }}"
									>
										<span>{{ "lang_supervision_dashboard" | language }}</span>
									</button>
									<button
										mat-menu-item
										*ngIf="list.projects_quotation_pdf_file && isQuotationExists"
										(click)="
											ds.previewData(
												list.projects_quotation_pdf_file,
												'#pdfPreviewSuperVision'
											)
										"
									>
										<span>{{ "lang_quotation_attachment" | language }}</span>
									</button>
									<button
										mat-menu-item
										*ngIf="list.quotation_approval_receipt && isQuotationExists"
										(click)="
											ds.previewData(
												list.quotation_approval_receipt,
												'#pdfPreviewSuperVision'
											)
										"
									>
										<span>{{
											"lang_quotation_approved_receipt" | language
										}}</span>
									</button>
								</mat-menu>
							</td>
						</tr>
					</tbody>
					<tbody *ngIf="listData.length == '0'" class="AppendList">
						<tr class="odd">
							<td colspan="11" class="nodata" align="center">{{ nodata }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
<div *ngIf="listData.length != '0'" class="card-footer padb0">
	<div class="row">
		<div class="col-lg-3 col-sm-6 col-6 mb-10">
			{{ "lang_total" | language }} : {{ listData.page.total_records }}
		</div>
		<div class="col-lg-5 col-sm-6 mb-10">
			<mat-radio-group aria-label="Select an option">
				<mat-radio-button
					value="10"
					[checked]="pagesize == '10' ? true : false"
					(click)="load_supervision_lists_pagesize(10)"
				>
					10</mat-radio-button
				>
				<mat-radio-button
					value="20"
					[checked]="pagesize == '20' ? true : false"
					(click)="load_supervision_lists_pagesize(20)"
				>
					20
				</mat-radio-button>
				<mat-radio-button
					value="50"
					[checked]="pagesize == '50' ? true : false"
					(click)="load_supervision_lists_pagesize(50)"
				>
					50
				</mat-radio-button>
				<mat-radio-button
					value="100"
					[checked]="pagesize == '100' ? true : false"
					(click)="load_supervision_lists_pagesize(100)"
				>
					100
				</mat-radio-button>
				<mat-radio-button
					value="500"
					[checked]="pagesize == '500' ? true : false"
					(click)="load_supervision_lists_pagesize(500)"
				>
					500
				</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="col-lg-3 col-sm-6">
			{{ "lang_goto" | language }} :
			<select
				class="customselect"
				(change)="load_supervision_lists_page($event.target.value)"
			>
				<option
					*ngFor="let ks of ds.getrange(listData.page.total_pages)"
					[selected]="pageno == ks ? true : false"
					value="{{ ks }}"
				>
					{{ ks }}
				</option>
			</select>
		</div>
	</div>
</div>
<div class="modal" id="pdfPreviewSuperVision">
	<div class="modal-dialog width70">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{ "lang_preview" | language }}</h4>
				<button type="button" class="close" data-dismiss="modal">
					&times;
				</button>
			</div>
			<div class="modal-body">
				<div class="pdf-container">
					<iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
				</div>
			</div>
			<!-- Modal footer -->
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">
					{{ "lang_close" | language }}
				</button>
			</div>
		</div>
	</div>
</div>
