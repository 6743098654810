import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
interface list {
  id?: number;
  class: string,
  account_code: string,
  account_name: string,
  amount: string
}
@Component({
  selector: 'app-profit-loss-statement',
  templateUrl: './profit-loss-statement.component.html',
  styleUrls: ['./profit-loss-statement.component.scss']
})
export class ProfitLossStatementComponent implements OnInit {
  form: FormGroup;
  branches: any = [];
  finances: any = [];
  lodingdatas = this.lang.transform('lang_loading');
  finance_id: any = "";
  datastatus = false;
  //pageing 1 for Previous Year Income Statements
  pipage = 1;
  pipageSize = 10;
  picollectionSize;
  pilistofdata: list[];
  pidata: any = [];
  pitotals: any = [];
  //pageing 2 for Previous Year Expense Statements
  pepage = 1;
  pepageSize = 10;
  pecollectionSize;
  pelistofdata: list[];
  pedata: any = [];
  petotals: any = [];
  //pageing 3 for Previous Year Income Statements
  cipage = 1;
  cipageSize = 10;
  cicollectionSize;
  cilistofdata: list[];
  cidata: any = [];
  citotals: any = [];
  //pageing 4 for Previous Year Expense Statements
  cepage = 1;
  cepageSize = 10;
  cecollectionSize;
  celistofdata: list[];
  cedata: any = [];
  cetotals: any = [];
  settings = environment.singledropdown;
  branch_id: any = (localStorage.getItem('selectedBranch') != null) ? localStorage.getItem('selectedBranch') : '0';
  constructor(public ds:DataService,public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.buildform();
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != '0') {
      this.load_financial_years();
    }
  }
  public selectBrach(branch) {
    this.datastatus = false;
    if (branch && branch != '0') {
      localStorage.setItem('selectedBranch', branch);
      this.branch_id = branch;
      this.load_financial_years();
    } else {
      this.spinner.hide();
    }
  }
  public selectFinanceYear(financeid) {
    if (financeid && financeid != '0') {
      this.finance_id = financeid;
    }
  }
  public load_branches() {
    this.branches = [];
    this.ds.getActionByUrl([], 'ab/accounts_view').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.branches = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public load_financial_years() {
    this.finances = [];
    this.form.get('finance_id').setValue('');
    this.ds.getActionByUrl([], 'financial/' + this.branch_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.finances = res.records;
        this.finances.forEach((v, k) => {
          if (v.finance_status == "1") {
            this.form.get('finance_id').setValue(v.finance_id);
            this.form.get('period_from').setValue(v.finance_start_date);
            this.form.get('period_to').setValue(v.finance_end_date);
          }
        });
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      finance_id: new FormControl("", [Validators.required]),
      report_type: new FormControl('GLEDGER', [Validators.required]),
      period_from: new FormControl("", [Validators.required]),
      period_to: new FormControl("", [Validators.required]),
      account_level: new FormControl(""),
      compare_previous_year: new FormControl(""),
    });
  }
  public fetch_statements() {
    if (this.form.valid) {
      this.spinner.show();
      let param = new FormData();
      param.set('branch_id', this.form.get('branch_id').value);
      let finance_id = this.form.get('finance_id').value;
      param.set('finance_id', (this.form.get('finance_id').value) ? this.form.get('finance_id').value : '0');
      param.set('report_type', this.form.get('report_type').value);
      if (this.form.get('report_type').value == 'ALEVEL') {
        param.set('account_level', (this.form.get('account_level').value) ? this.form.get('account_level').value : '1');
      } else {
        param.set('account_level', '');
      }
      param.set('compare_previous_year', (this.form.get('compare_previous_year').value == true) ? '1' : '0');
      param.set('period_from', (this.form.get('period_from').value != null) ? this.form.get('period_from').value : '');
      param.set('period_to', (this.form.get('period_to').value != null) ? this.form.get('period_to').value : '');
      this.ds.postActionByUrl(param, 'profit_loss').subscribe(res => {
        this.datastatus = true;
        this.spinner.hide();
        if (res.status) {
          if (this.form.get('compare_previous_year').value == true && res.previous_year_records.income_records.length > 0) {
            this.pilistofdata = res.previous_year_records.income_records;
            this.picollectionSize = res.previous_year_records.income_records.length;
            this.pitotals = res.previous_year_records.income_records.length;
            this.piRefreshLists();
          }
          if (this.form.get('compare_previous_year').value == true && res.previous_year_records.expenses_records.length > 0) {
            this.pelistofdata = res.previous_year_records.expenses_records;
            this.pecollectionSize = res.previous_year_records.expenses_records.length;
            this.petotals = res.previous_year_records.expenses_records.length;
            this.peRefreshLists();
          }
          if (res.current_year_records.income_records.length > 0) {
            this.cilistofdata = res.current_year_records.income_records;
            this.cicollectionSize = res.current_year_records.income_records.length;
            this.citotals = res.current_year_records.income_records.length;
            this.ciRefreshLists();
          }
          if (res.current_year_records.expenses_records.length > 0) {
            this.celistofdata = res.current_year_records.expenses_records;
            this.cecollectionSize = res.current_year_records.expenses_records.length;
            this.cetotals = res.current_year_records.expenses_records.length;
            this.ceRefreshLists();
          }
        } else {
          this.datastatus = false;
          this.ds.dialogf('', res.error);
        }
      }, error => {
        this.spinner.hide();
        this.datastatus = false;
        this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      this.ds.dialogf('', this.lang.transform('lang_fill_required_fields'))
    }
  }
  public piRefreshLists() {
    this.pidata = this.pilistofdata.map((pilist, i) => ({ id: i + 1, ...pilist })).slice((this.pipage - 1) * this.pipageSize, (this.pipage - 1) * this.pipageSize + this.pipageSize);
  }
  public peRefreshLists() {
    this.pedata = this.pelistofdata.map((pelist, i) => ({ id: i + 1, ...pelist })).slice((this.pepage - 1) * this.pepageSize, (this.pepage - 1) * this.pepageSize + this.pepageSize);
  }
  public ciRefreshLists() {
    this.cidata = this.cilistofdata.map((cilist, i) => ({ id: i + 1, ...cilist })).slice((this.cipage - 1) * this.cipageSize, (this.cipage - 1) * this.cipageSize + this.cipageSize);
  }
  public ceRefreshLists() {
    this.cedata = this.celistofdata.map((celist, i) => ({ id: i + 1, ...celist })).slice((this.cepage - 1) * this.cepageSize, (this.cepage - 1) * this.cepageSize + this.cepageSize);
  }
}
