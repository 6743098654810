import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AccountsRoutingModule } from './accounts-routing.module';
//Settings
import { SettingsComponent } from './settings/settings.component';
import { AccountSettingsComponent } from './settings/account-settings/account-settings.component';
import { FinanceyearComponent } from './settings/financeyear/financeyear.component';
import { InvoiceSettingsComponent } from './settings/invoice-settings/invoice-settings.component';
import { PermitSettingsComponent } from './settings/permit-settings/permit-settings.component';
import { TaxSettingsComponent } from './settings/tax-settings/tax-settings.component';
//Account Masters
import { AccountMasterListComponent } from './account-masters/account-master-list/account-master-list.component';
//Cost Centers
import { CostCenterListComponent } from './cost-center/cost-center-list/cost-center-list.component';
import { CostCenterViewComponent } from './cost-center/cost-center-view/cost-center-view.component';
//Transactions
import { JournalAddComponent } from './transactions/journal/journal-add/journal-add.component';
import { JournalEditComponent } from './transactions/journal/journal-edit/journal-edit.component';
import { JournalListComponent } from './transactions/journal/journal-list/journal-list.component';
import { JournalViewComponent } from './transactions/journal/journal-view/journal-view.component';
import { PaymentAddComponent } from './transactions/payments/payment-add/payment-add.component';
import { PaymentEditComponent } from './transactions/payments/payment-edit/payment-edit.component';
import { PaymentListComponent } from './transactions/payments/payment-list/payment-list.component';
import { PaymentViewComponent } from './transactions/payments/payment-view/payment-view.component';
import { PinvoiceAddComponent } from './transactions/purchase-invoices/pinvoice-add/pinvoice-add.component';
import { PinvoiceEditComponent } from './transactions/purchase-invoices/pinvoice-edit/pinvoice-edit.component';
import { PinvoiceListComponent } from './transactions/purchase-invoices/pinvoice-list/pinvoice-list.component';
import { PinvoiceViewComponent } from './transactions/purchase-invoices/pinvoice-view/pinvoice-view.component';
import { ReceiptAddComponent } from './transactions/receipts/receipt-add/receipt-add.component';
import { ReceiptEditComponent } from './transactions/receipts/receipt-edit/receipt-edit.component';
import { ReceiptListsComponent } from './transactions/receipts/receipt-lists/receipt-lists.component';
import { ReceiptViewComponent } from './transactions/receipts/receipt-view/receipt-view.component';
import { SinvoiceAddComponent } from './transactions/selling-invoices/sinvoice-add/sinvoice-add.component';
import { SinvoiceEditComponent } from './transactions/selling-invoices/sinvoice-edit/sinvoice-edit.component';
import { SinvoiceListComponent } from './transactions/selling-invoices/sinvoice-list/sinvoice-list.component';
import { SinvoiceViewComponent } from './transactions/selling-invoices/sinvoice-view/sinvoice-view.component';
import { TransactionViewerComponent } from './transactions/transaction-viewer/transaction-viewer.component';
//Reports
import { BalanceSheetsComponent } from './reports/accounts/balance-sheets/balance-sheets.component';
import { GeneralLedgerComponent } from './reports/accounts/general-ledger/general-ledger.component';
import { IncomeStatementComponent } from './reports/accounts/income-statement/income-statement.component';
import { ProfitLossStatementComponent } from './reports/accounts/profit-loss-statement/profit-loss-statement.component';
import { StatementOfAccountsComponent } from './reports/accounts/statement-of-accounts/statement-of-accounts.component';
import { TransactionListingComponent } from './reports/accounts/transaction-listing/transaction-listing.component';
import { TrialBalanceComponent } from './reports/accounts/trial-balance/trial-balance.component';
import { CostSummaryComponent } from './reports/costs/cost-summary/cost-summary.component';
import { StatementOfCostsComponent } from './reports/costs/statement-of-costs/statement-of-costs.component';
import { SharedModule } from '@shared/shared.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TreeModule } from '@circlon/angular-tree-component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/custom-material-config';
import { TransactionsModule } from '../transactions/transactions.module';
import { AccountTrialComponent } from './reports/accounts/account-trial/account-trial.component';






const COMPONENTS = [AccountMasterListComponent, CostCenterListComponent, CostCenterViewComponent, BalanceSheetsComponent, GeneralLedgerComponent, IncomeStatementComponent, ProfitLossStatementComponent, StatementOfAccountsComponent, TransactionListingComponent, TrialBalanceComponent, CostSummaryComponent, StatementOfCostsComponent, SettingsComponent, AccountSettingsComponent, FinanceyearComponent, InvoiceSettingsComponent, PermitSettingsComponent, TaxSettingsComponent, JournalAddComponent, JournalEditComponent, JournalListComponent, JournalViewComponent, PaymentAddComponent, PaymentEditComponent, PaymentListComponent, PaymentViewComponent, PinvoiceAddComponent, PinvoiceEditComponent, PinvoiceListComponent, PinvoiceViewComponent, ReceiptAddComponent, ReceiptEditComponent, ReceiptListsComponent, ReceiptViewComponent, SinvoiceAddComponent, SinvoiceEditComponent, SinvoiceListComponent, SinvoiceViewComponent, TransactionViewerComponent];
const COMPONENTS_DYNAMIC = [];
@NgModule({
  imports: [
    SharedModule,
    AccountsRoutingModule,
    AngularMultiSelectModule,
    NgbPaginationModule, 
    NgbModule,
    TreeModule,
    JwPaginationModule,
    TransactionsModule
  ],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, AccountTrialComponent],
  exports: [...COMPONENTS, ...COMPONENTS_DYNAMIC],
  entryComponents: COMPONENTS_DYNAMIC,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers : [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ]
})
export class AccountsModule { }
