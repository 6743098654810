import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";

import { AdminLayoutComponent } from "./admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./auth-layout/auth-layout.component";

import { SidebarComponent } from "./sidebar/sidebar.component";
import { UserPanelComponent } from "./sidebar/user-panel.component";
import { SidemenuComponent } from "./sidemenu/sidemenu.component";
import { AccordionDirective } from "./sidemenu/accordion.directive";
import { AccordionItemDirective } from "./sidemenu/accordionItem.directive";
import { AccordionAnchorDirective } from "./sidemenu/accordionanchor.directive";
import { SidebarNoticeComponent } from "./sidebar-notice/sidebar-notice.component";

import { TopmenuComponent } from "./topmenu/topmenu.component";

import { HeaderComponent } from "./header/header.component";
import { BrandingComponent } from "./header/widgets/branding.component";
import { GithubButtonComponent } from "./header/widgets/github.component";
import { NotificationComponent } from "./header/widgets/notification.component";
import { TranslateComponent } from "./header/widgets/translate.component";
import { UserComponent } from "./header/widgets/user.component";
import { CustomizerComponent } from "./customizer/customizer.component";
import { ThemeComponent } from "./theme/theme.component";
import { ColorPickerModule } from "ngx-color-picker";
import { DynamicFontsizeComponent } from "./header/widgets/dynamic-fontsize.component";
import { FooterComponent } from "./footer/footer.component";
import { MatBadgeModule } from "@angular/material/badge";
import { VirtualScrollerModule } from 'ngx-virtual-scroller';


@NgModule({
	declarations: [
		AdminLayoutComponent,
		AuthLayoutComponent,
		SidebarComponent,
		UserPanelComponent,
		SidemenuComponent,
		AccordionDirective,
		AccordionItemDirective,
		AccordionAnchorDirective,
		SidebarNoticeComponent,
		TopmenuComponent,
		HeaderComponent,
		BrandingComponent,
		GithubButtonComponent,
		NotificationComponent,
		TranslateComponent,
		UserComponent,
		CustomizerComponent,
		ThemeComponent,
		DynamicFontsizeComponent,
		FooterComponent,
	],
	imports: [SharedModule, ColorPickerModule, MatBadgeModule, VirtualScrollerModule],
	exports: [TranslateComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ThemeModule {}
