export const environment = {
	SERVER_ORIGIN: "https://erp.almnabr.com/backend/",
	frontUrl: "https://erp.almnabr.com/",
	X_API_KEY: "PCGYdyKBJFya8LMaFP6baRrraRpSFc",

	encryptionPassword: "5465465464654sd65f4g6sd54gs6d5g4s65dg4",
	GOOGLE_MAPS_API_KEY: "AIzaSyAcCkMwqbGOz2CoPgagwkv-Hl4mwJDaTk0",
	firebaseConfig: {
		// apiKey: "AIzaSyBpoRH78xTSnOy8NZfOHdS2lg6SoH8pP2I",
		// authDomain: "test-noti-fba98.firebaseapp.com",
		// projectId: "test-noti-fba98",
		// storageBucket: "test-noti-fba98.appspot.com",
		// messagingSenderId: "439179924488",
		// appId: "1:439179924488:web:cd51d2b50438a8733dedbd",
		// measurementId: "G-8HVYXXS6MD",
		apiKey: "AIzaSyD3XPveWXH0TaPYHmO8_KSvmJWk9dgGeCE",
		authDomain: "erp-almnabr-nahidh-2021.firebaseapp.com",
		projectId: "erp-almnabr-nahidh-2021",
		storageBucket: "erp-almnabr-nahidh-2021.appspot.com",
		messagingSenderId: "877163579009",
		appId: "1:877163579009:web:0e9c00888767120be02f80",
		measurementId: "G-4MSLHFNBZ2",
	},
	production: true,
	useHash: true,
	hmr: false,
	singledropdown: {
		singleSelection: true,
		text: "Select",
		searchPlaceholderText: "Search",
		enableSearchFilter: true,
		labelKey: "label",
		primaryKey: "value",
	},
	multipledropdown: {
		text: "Select",
		selectAllText: "Select All",
		unSelectAllText: "UnSelect All",
		searchPlaceholderText: "Search",
		enableSearchFilter: true,
		labelKey: "label",
		primaryKey: "value",
		badgeShowLimit: 2,
	},
	theme: {
		acolor: "#1992bc",
		bcolor: "#000000",
		ccolor: "#555555",
		dcolor: "#ffffff",
	},
	modelconfig: {
		animated: true,
		keyboard: true,
		backdrop: true,
		ignoreBackdropClick: true,
	},
};
