<breadcrumb></breadcrumb>
<section class="panel-expansion" [@fadeIn]>
    <mat-accordion class="stylepanel">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{'lang_approve_transactions' | language}}</mat-expansion-panel-header>
        <div class="material-panel-body communication">
            <div class="mbrl15 fromapprovetransactions">
                <div class="row">
                    <div class="col-lg-12 col-sm-12 mb-10">
                        <input type="text" class="form-control almnabrformcontrol searchKey" id="searchKey" [(ngModel)]="searchKey" (keyup)="search_transactions();" placeholder="{{'lang_search' | language}}" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-10 col-xs-12 mb-10">
                        <ng-select 
                            [items]="formlist" 
                            [multiple]="false" 
                            bindLabel="label" 
                            appendTo="body" 
                            bindValue="value" 
                            clearAllText="Clear"
                            placeholder="{{'lang_search_form' | language}}"
                            [(ngModel)]="searchByForm"
                            (change)="spinner.show();search_transactions()">
                        </ng-select>
                    </div>
                    <div class="col-md-4 mb-10 col-xs-12 mb-10">
                        <select class="form-control almnabrformcontrol" [(ngModel)]="searchByTypeOfApproval" (change)="spinner.show();search_transactions();">
                            <option value="">{{'lang_all' | language}}</option>
                            <option value="manual">{{'lang_manual' | language}}</option>
                            <option value="auto">{{'lang_auto' | language}}</option>
                        </select>
                    </div>
                    <div class="col-md-4 mb-10 col-xs-12 mb-10">
                        <ng-select 
                            [items]="modules" 
                            appendTo="body" 
                            [multiple]="true" 
                            bindLabel="label" 
                            bindValue="value" 
                            clearAllText="Clear"
                            placeholder="{{'lang_search_modules' | language}}"
                            [(ngModel)]="searchByModule"
                            (change)="spinner.show();search_transactions()">
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive customResponsive">
                            <table border="1" class="table CustomTable">
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>{{'lang_request_number' | language}}</td>
                                        <td>{{'lang_description' | language}}</td>
                                        <td>{{'lang_from' | language}}</td>
                                        <td>{{'lang_to' | language}}</td>
                                        <td>{{'lang_type' | language}}</td>
                                        <td>{{'lang_module_name' | language}}</td>
                                        <td>{{'lang_forms' | language}}</td>
                                        <td>{{'lang_writer' | language}}</td>
                                        <td>{{'lang_transaction_date' | language}}</td>
                                        <td>{{'lang_approve_type' | language}}</td>
                                        <td>{{'lang_action' | language}}</td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="listData.length != '0' " class="AppendList">
                                    <tr *ngFor="let list of listData.list.records; let i = index;let odd=odd; let even=even"
                                        [ngClass]="{ odd: odd, even: even }"
                                        class="{{list.row_color}}{{list.row_color_flash}}">
                                        <td>{{i+1}}</td>
                                        <td>{{list.transaction_request_id}}</td>
                                        <td>{{list.transaction_request_description}}</td>
                                        <td>{{list.transaction_from_name}}</td>
                                        <td>{{list.transaction_to_name}}</td>
                                        <td>{{list.transactions_type_name}}</td>
                                        <td *ngIf="list.module_name">{{list.module_name}}</td>
                                        <td *ngIf="!list.module_name">{{list.module_key}}</td>
                                        <td>{{list.transactions_name}}</td>
                                        <td>{{list.transaction_request_user_name_writer}}</td>
                                        <td>{{list.create_datetime}}</td>
                                        <td>{{list.transaction_request_type_of_approval}}</td>
                                        <td>
                                            <mat-icon routerLink="/transactions/{{list.url}}/A" matTooltip="{{'lang_view' | language }}" class="mnabricon">remove_red_eye</mat-icon>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="listData.length == '0' " class="AppendList">
                                    <tr class="odd">
                                        <td colspan="14" class="nodata" align="center">{{nodata}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="listData.length != '0' " class="card-footer padb0">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-6 mb-10">
                        {{'lang_total' | language}} : {{listData.page.total_records}}
                    </div>
                    <div class="col-lg-5 col-sm-6 mb-10">
                        <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="10" [checked]="(pagesize =='10') ? true : false" (click)="loadFrmPagesize(10)"> 10</mat-radio-button>
                            <mat-radio-button value="20" [checked]="(pagesize =='20') ? true : false" (click)="loadFrmPagesize(20)"> 20 </mat-radio-button>
                            <mat-radio-button value="50" [checked]="(pagesize =='50') ? true : false" (click)="loadFrmPagesize(50)"> 50 </mat-radio-button>
                            <mat-radio-button value="100" [checked]="(pagesize =='100') ? true : false" (click)="loadFrmPagesize(100)"> 100 </mat-radio-button>
                            <mat-radio-button value="500" [checked]="(pagesize =='500') ? true : false" (click)="loadFrmPagesize(500)"> 500 </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        {{'lang_goto' | language}} :
                        <select class="customselect" (change)="loadPageFrm($event.target.value)">
                            <option *ngFor="let ks of ds.getrange(listData.page.total_pages)" [selected]="(pageno == ks) ? true : false" value="{{ks}}">{{ks}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
</section>