import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import * as Countries from '../../../../../shared/utils/countries_en'
 
@Component({
  selector: 'app-filter-employee-card-mob',
  templateUrl: './filter-card-mob.component.html',
  styleUrls: ['./filter-card-mob.component.scss']
})
export class FilterEmployeeCardMobComponent implements OnInit {
  projects = []
  branches = []
  filterForm:FormGroup = this.fb.group({
    nationality_type: 'all',
    project_id:null,
    branch_id:null,
    national_id:null,
    employee_id:null,
    employee_name:null,
    nationality: null,
    position: null,
     
  });
  countries = Countries.countries
  formFromLocalStorage:any;
  nationality_type = [
    {
      label:this.lang.transform('lang_all'),
      value:'all'
    },
    {
      label:this.lang.transform('lang_saudi'),
      value:'saudi'
    },
    {
      label:this.lang.transform('lang_non_saudi'),
      value:'nonsaudi'
    },
    
  ];
  
   
  constructor(public dialogRef: MatDialogRef<FilterEmployeeCardMobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb:FormBuilder, private lang:LanguagePipe) { }
  
    ngOnInit(): void {
      this.getFilterParams();
      this.buildForm();
      this.projects = this.data.projects;
      this.branches = this.data.branches;
    } 
  
    buildForm() {
      this.filterForm = this.fb.group({
        nationality_type: this.formFromLocalStorage?.nationality_type,
        project_id: this.formFromLocalStorage?.project_id,
        branch_id: this.formFromLocalStorage?.branch_id,
        national_id: this.formFromLocalStorage?.national_id,
        employee_name: this.formFromLocalStorage?.employee_name,
        employee_id: this.formFromLocalStorage?.employee_id,
        nationality: this.formFromLocalStorage?.nationality,
        position: this.formFromLocalStorage?.position,
       
      });
    }
  
    getFilterParams() {
      this.formFromLocalStorage = localStorage.getItem('employee_filterParams') ? JSON.parse(localStorage.getItem('employee_filterParams')): localStorage.setItem('employee_filterParams',  JSON.stringify(this.filterForm?.value))
    }
  
    sendFilterForm() {
      this.dialogRef.close(this.filterForm?.value);
      localStorage.setItem('employee_filterParams', JSON.stringify(this.filterForm?.value));
    }
    resetFilterForm() {
      this.filterForm.reset();
/*       this.filterForm.get('version').setValue('final_completed_versions');
 */      this.dialogRef.close(this.filterForm?.value);
      localStorage.setItem('employee_filterParams', JSON.stringify(this.filterForm?.value));
    }
  

    public isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if(charCode == 44) {
        return true;
      }
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
      }
      return true;
  }

}
