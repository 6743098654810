import { NgModule } from '@angular/core';
import { TasksRoutingModule } from './tasks-routing.module';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/custom-material-config';
import { SharedModule } from '@shared';
import { AddTaskComponent } from './add-task/add-task.component';
import { ChatTaskComponent } from './chat-task/chat-task.component';
import { EditTaskComponent } from './edit-task/edit-task.component';
import { TaskComponent } from './list/task.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ChatService } from './chat.service';
import { TicketListComponent } from './ticket-list/ticket-list.component';
import { AddTicketComponent } from './add-ticket/add-ticket.component';
import { ViewTicketComponent } from './view-ticket/view-ticket.component';
import { EditTicketComponent } from './edit-ticket/edit-ticket.component';


const COMPONENTS = [];
const COMPONENTS_DYNAMIC = [];
@NgModule({
  imports: [SharedModule, TasksRoutingModule, InfiniteScrollModule, AngularMultiSelectModule],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, AddTaskComponent, ChatTaskComponent, EditTaskComponent, TaskComponent, TicketListComponent, AddTicketComponent, ViewTicketComponent, EditTicketComponent],
  entryComponents: COMPONENTS_DYNAMIC,
  providers : [ChatService,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ]
})
export class TasksModule { }
