 
 
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView, collapseAnimation } from 'angular-calendar';
 import { addDays, addHours, endOfDay, endOfMonth, getHours, isSameDay, isSameMonth, startOfDay, subDays, subHours } from 'date-fns';
import moment, * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
 
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { x64 } from 'crypto-js';
export interface CalendarEvent2<MetaType = any> {
  id?: string | number;
  start: Date;
  end?: Date;
  title: string;
  color?: any;
  actions?: any[];
  allDay?: boolean;
  cssClass?: string;
  resizable?: {
      beforeStart?: boolean;
      afterEnd?: boolean;
  };
  data?:any;
  draggable?: boolean;
  meta?: MetaType;
}

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {

  constructor() { }
  startDate:any
  endDate:any
  ngOnInit(): void {
    moment.locale('en')
    Moment.locale('en')
    this.startDate = moment().locale('en').clone().startOf('month').format('DD-MM-YYYY').toString();
    this.endDate = moment().locale('en').clone().endOf('month').format('DD-MM-YYYY').toString();
    this.LoadBookingsForCalendar()
  }






  refresh: Subject<any> = new Subject();
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
 
  activeDayIsOpen: boolean = false;
  view: CalendarView = CalendarView.Month;

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
  /*   this.modalData = { event: [event], action: action, selectedDate: '', date: new Date().toString() };
    this.modal.open(this.modalContent, { size: 'lg' }); */
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
  
    console.log(date);
    console.log(events);

      if (isSameMonth(date, this.viewDate)) {
        if (
          (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)  
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen =true ;
        }
        this.viewDate = date;
      }
   
    }
    
  from_to={
    startDate:Moment().locale('en').startOf('month').format('DD-MM-YYYY'),
    endDate:Moment().locale('en').startOf('month').add(30, 'days').format('YYYY-MM-DD'),
  }


    
  closeOpenMonthViewDay(status: string) {
    const moment = extendMoment(Moment);
    var start = '';
    var end = '';
    if (status == 'today') {
      this.startDate = moment().locale('en').clone().startOf('month').format('DD-MM-YYYY').toString();
      this.endDate = moment().locale('en').clone().endOf('month').format('DD-MM-YYYY').toString();
      this.from_to={
        startDate:Moment().locale('en').startOf('month').format('DD-MM-YYYY'),
        endDate:Moment().locale('en').startOf('month').add(30, 'd').format('YYYY-MM-DD'),
      }
    }
    if (status == 'next') {
      this.activeDayIsOpen = false;
      console.log(this.from_to);
      
      this.from_to={
        endDate:Moment(this.from_to.endDate).locale('en').add(30,'day').format('YYYY-MM-DD'),
        startDate:Moment(this.from_to.endDate).locale('en').add(30,'day').format('YYYY-MM-DD')
      }
    }
    if (status == 'previous') {
      var date = moment(this.startDate, "DD-MM-YYYY").locale('en').toDate();
      this.activeDayIsOpen = false;
      this.from_to={
        endDate:Moment(this.from_to.endDate).locale('en').add(-30,'days').format('YYYY-MM-DD'),
        startDate:Moment(this.from_to.startDate).locale('en').add(-30,'days').format('YYYY-MM-DD')
      }
    }
    this.LoadBookingsForCalendar()
 
  

  }






  private subscriptions = new Subscription()

  currentUser:any;
  userType = '';
  serverEvents:Event[]|any = [];
  CalendarView = CalendarView;
 

  actions: any[] = [];
 

  LoadBookingsForCalendar( from_to?:any) {
    // if(this.status == 'vacation'){
    //   this.type = 'vacation';
    // }


    this.serverEvents = [
      {
        start: startOfDay(new Date()),
        end: addDays(new Date(), 1),
        title: 'A 3 day event',
        data:{
          status:'requested',
          vio:'حضور متأخر',
          count:3
        },
        actions: this.actions,
        allDay: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      },
      {
        start: startOfDay(new Date()),
        end: addDays(new Date(), 1),
        title: 'An event with no end date',
        data:{
          status:'cancelled',
          vio:'غياب',
          count:3
          
        },
        actions: this.actions,
      },
      
      {
        start: subDays(endOfMonth(new Date()), 3),
        end: addDays(endOfMonth(new Date()), 3),
        title: 'A long event that spans 2 months',
        data:{
          status:'requested',
          vio:'حضور متأخر',
          count:3
        },
        allDay: true,
      },
      {
        start: addHours(startOfDay(new Date()), 2),
        end: addHours(new Date(), 2),
        title: 'A draggable and resizable event',
        data:{
          status:'available',
          vio:'حاضرين في الميعاد',
          count:3
        },
        actions: this.actions,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      },
      {
        start: addHours(startOfDay(new Date()), 4),
        end: addHours(new Date(), 4),
        title: 'A draggable and resizable event',
        data:{
          status:'vacation',
          vio:'حاضرين في الميعاد',
          count:3
        },
        actions: this.actions,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        draggable: true,
      },
    ];
  
  
    
    this.events = [];
    this.serverEvents.map((x:any)=> {
      console.log( );
        let calEvent:CalendarEvent2 = {
          start: new Date( new Date(x.start).toUTCString().replace("GMT","")),
          end: new Date( new Date(x.end).toUTCString().replace("GMT","")),
          title : '',
          actions:this.actions,
          data: {
            ...x.data
            
          }
        } 
        this.events.push(calEvent);

      });
      console.log(this.events);
       
      this.refresh.next();
    
   /*   this.subscriptions.add(this._EventsService.getAllEvent(this.tutorId!,this.status!,this.type!).subscribe(res => {
   

     }));     */ 
  }

}
