<breadcrumb></breadcrumb>
<div class="themesection" *ngIf="viewdata_status">
    <section class="panel-expansion matborderno">
        <div class="card-footer footertop padb0">
            <div class="row">
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                    <button type="button" class="albutton" routerLink="/accounts/pinvoices">{{'lang_goto_list' | language }}</button>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                    <button *ngIf="InvData.permits.add" type="button" class="albutton" routerLink="/accounts/cpinvoices">{{'lang_add' | language }}</button>
                </div>
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                    <button *ngIf="InvData.permits.edit && InvData.financial_year.finance_id == viewdata.finance_id " type="button" class="albutton" routerLink="/accounts/epinvoices/{{branch_id}}/{{invoice_id}}/edit">{{'lang_edit' | language }}</button>
                </div>
            </div>
        </div>
        <mat-accordion class="view_purchase_invoice">
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_purchase_invoices' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body">
                    <div class="mbrl15">
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_invoice_no' | language }} : <b>{{viewdata.invoice_system_code}}</b></label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_transaction_number' | language }} : <b>{{viewdata.transaction_id}}</b></label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_branch' | language }} : <b>{{viewdata.branch_name}}</b></label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_process_type' | language }} : 
                                        <b *ngIf="viewdata.process_type == '1'">{{'lang_purchase_order' | language }}</b>
                                        <b *ngIf="viewdata.process_type == '2'">{{'lang_grr_order' | language }}</b>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_tax_exmbed' | language }} : 
                                        <b *ngIf="viewdata.invoice_tax_exambed == '0'">{{'lang_no' | language }}</b>
                                        <b *ngIf="viewdata.invoice_tax_exambed == '1'">{{'lang_yes' | language }}</b>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_tax_refund' | language }} : 
                                        <b *ngIf="viewdata.invoice_tax_refund == '0'">{{'lang_no' | language }}</b>
                                        <b *ngIf="viewdata.invoice_tax_refund == '1'">{{'lang_yes' | language }}</b>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_tax_pay_supplier' | language }} :
                                        <b *ngIf="viewdata.invoice_tax_pay_supplier == '0'">{{'lang_no' | language }}</b>
                                        <b *ngIf="viewdata.invoice_tax_pay_supplier == '1'">{{'lang_yes' | language }}</b>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_invoice_date' | language }} :<b>{{viewdata.invoice_date}}</b></label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_payment_mode' | language }} : <b>{{viewdata.payment_name}}</b></label>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_credit_days' | language }} : <b>{{viewdata.invoice_credit_days}}</b></label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label
                                        *ngIf="viewdata.invoice_credit_days != '0' ">{{'lang_credit_date' | language }}:<b>{{viewdata.invoice_credit_date}}</b></label>
                                    <label
                                        *ngIf="viewdata.invoice_credit_days == '0' ">{{'lang_credit_date' | language }}:----</label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_supplier_account' | language }} : <b>{{viewdata.customer_account}}</b></label>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_credit_cost' | language }} : <b>{{viewdata.customer_cost}}</b></label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_supplier_address' | language }}
                                        :<b>{{viewdata.invoice_address_to}}</b></label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_vat_tax' | language }} :
                                        <b>{{viewdata.invoice_vat_tax_id}}</b></label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_reference_no' | language }} :
                                        <b>{{viewdata.invoice_ref_number}}</b></label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_invoice_instruction_remarks' | language }}
                                        :<b>{{viewdata.invoice_instruction_remarks}}</b></label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_writer' | language }} : <b>{{viewdata.writer_name}}</b></label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_financial_date' | language }} : <b>{{viewdata.finance_start_date}} -
                                            {{viewdata.finance_end_date}}</b></label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_ondate' | language }} :
                                        <b>{{viewdata.invoice_created_date}}</b></label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10" *ngIf="viewdata.invoice_update_date">
                                <div class="form-group">
                                    <label>{{'lang_onupdate' | language }} :
                                        <b>{{viewdata.invoice_update_date}}</b></label>
                                </div>
                            </div>
                        </div>
                        <div class="TablePart">
                            <div class="table-responsive customResponsive mb-10 tablefields">
                                <div class="red pad0"><b>{{'lang_items' | language }} : </b></div>
                                <table border="1" class="table CustomTable text-center">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>{{'lang_invoice_item_code' | language }} </td>
                                            <td>{{'lang_invoice_item_name' | language }} </td>
                                            <td>{{'lang_invoice_item_unit' | language }} </td>
                                            <td>{{'lang_invoice_location' | language }} </td>
                                            <td>{{'lang_invoice_item_quantity' | language }} </td>
                                            <td>{{'lang_invoice_item_price' | language }} </td>
                                            <td>{{'lang_tax' | language }}</td>
                                            <td>{{'lang_tax_amount' | language }}</td>
                                            <td>{{'lang_invoice_item_amount' | language }}</td>
                                            <td>{{'lang_invoice_discount_percent' | language }} %</td>
                                            <td>{{'lang_invoice_discount_amount' | language }}</td>
                                            <td>{{'lang_invoice_total_amount' | language }}</td>
                                            <td>{{'lang_notes' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody class="AppendList">
                                        <tr *ngFor="let item of items; let itemIndex = index;let odd=odd; let even=even">
                                            <td class="verticalalignmid">{{itemIndex+1}}</td>
                                            <td>{{item.invoice_item_code}}</td>
                                            <td>{{item.invoice_item_name}}</td>
                                            <td>{{item.invoice_item_unit}}</td>
                                            <td>{{item.invoice_location}}</td>
                                            <td>{{item.invoice_item_quantity | number:'.2-2'}}</td>
                                            <td>{{item.invoice_item_price | number:'.2-2'}}</td>
                                            <td>{{item.label}}</td>
                                            <td>{{(( item.tax_value / 100) * (item.invoice_item_quantity * item.invoice_item_price)) | number:'.2-2'}}
                                            </td>
                                            <td>{{ ((item.invoice_item_quantity * item.invoice_item_price) + ( item.tax_value / 100) * (item.invoice_item_quantity * item.invoice_item_price)) | number:'.2-2'}}
                                            </td>
                                            <td>{{item.invoice_discount_percent | number:'.2-2'}}</td>
                                            <td>{{ ( (item.invoice_discount_percent / 100) * ((item.invoice_item_quantity * item.invoice_item_price) + ( item.tax_value / 100) * (item.invoice_item_quantity * item.invoice_item_price))) | number: '.2-2' }}
                                            </td>
                                            <td>{{(((item.invoice_item_quantity * item.invoice_item_price) + ( item.tax_value / 100) * (item.invoice_item_quantity * item.invoice_item_price)) - (item.invoice_discount_percent / 100) * ((item.invoice_item_quantity * item.invoice_item_price) + ( item.tax_value / 100) * (item.invoice_item_quantity * item.invoice_item_price))) | number:'.2-2'}}
                                            </td>
                                            <td>{{item.invoice_item_description}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-8 col-sm-8 col-xs-12 mb-10"></div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="row">
                                    <div class="col-6">
                                        <b><label>{{'lang_grand_total' | language }}</label></b>
                                    </div>
                                    <div class="col-6">
                                        <label> : {{viewdata.invoice_grand_total}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 col-sm-8 col-xs-12 mb-10"></div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="row">
                                    <div class="col-6 mb-10">
                                        <b><label>{{'lang_total_item_discount' | language }}</label></b>
                                    </div>
                                    <div class="col-6 mb-10">
                                        <label> : {{viewdata.invoice_item_discount_amount}}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <b><label>{{'lang_global_discount' | language }}</label></b>
                                    </div>
                                    <div class="col-6">
                                        <label> : {{viewdata.invoice_global_discount_amount}}
                                            ({{viewdata.invoice_global_discount_percent}} %)</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 col-sm-8 col-xs-12 mb-10"></div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="row">
                                    <div class="col-6">
                                        <b><label>{{'lang_cash_advance' | language }}</label></b>
                                    </div>
                                    <div class="col-6">
                                        <label> : {{viewdata.invoice_cash_advance_amount}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 col-sm-8 col-xs-12 mb-10"></div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="row">
                                    <div class="col-6 mb-10">
                                        <b><label>{{'lang_total_item_tax' | language }}</label></b>
                                    </div>
                                    <div class="col-6 mb-10">
                                        <label> : {{viewdata.invoice_item_tax_amount}}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <b><label>{{'lang_tax_group' | language }}</label></b>
                                    </div>
                                    <div class="col-6">
                                        <label *ngIf="viewdata.global_tax_name"> : {{viewdata.invoice_global_tax_amount}}
                                            ({{viewdata.global_tax_name}})</label>
                                        <label *ngIf="!viewdata.global_tax_name"> : 0.00</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 col-sm-8 col-xs-12 mb-10"></div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="row">
                                    <div class="col-6">
                                        <b><label>{{'lang_net_amount' | language }}</label></b>
                                    </div>
                                    <div class="col-6">
                                        <label> : {{viewdata.invoice_net_total}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="transactions.length > 0">
                            <div class="col-md-12">
                                <div class="table-responsive dropdownyes customResponsive">
                                    <div class="red pad0"><b>{{'lang_transactions' | language }} : </b></div>
                                    <table border="1" class="table CustomTable">
                                        <thead>
                                            <tr>
                                                <td>#</td>
                                                <td>{{'lang_accounts_acccode' | language }} </td>
                                                <td>{{'lang_debit_amount' | language }} </td>
                                                <td>{{'lang_credit_amount' | language }} </td>
                                                <td>{{'lang_description' | language }} </td>
                                                <td>{{'lang_cost_center_code' | language }}</td>
                                            </tr>
                                        </thead>
                                        <tbody class="AppendList">
                                            <tr *ngFor="let data of transactions; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }">
                                                <td>{{i+1}}</td>
                                                <td>
                                                    {{data.account_name}}
                                                    <i class="fa fa-exchange mnabricon s2icon" routerLink="/accounts/statement_of_accounts/{{data.account_masters_id}}/" matTooltip="{{'lang_view_transactions' | language}}"></i>
                                                </td>                                                
                                                <td>{{ds.addCommas(data.debit_amount)}}</td>
                                                <td>{{ds.addCommas(data.credit_amount)}}</td>
                                                <td>{{data.transaction_history_description}}</td>
                                                <td *ngIf="data.cost_name">{{data.cost_name}}
                                                    <i class="fa fa-exchange mnabricon s2icon" routerLink="/accounts/statement_of_costs/{{data.cost_center_id}}/" matTooltip="{{'lang_view_transactions' | language}}"></i>
                                                </td>
                                                <td *ngIf="!data.cost_name">----</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer padb0">
                        <div class="row">
                            <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                                <button type="button" class="albutton" routerLink="/accounts/pinvoices">{{'lang_goto_list' | language }}</button>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12 mb-10"></div>
                            <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                                <button *ngIf="InvData.permits.add" type="button" class="albutton" routerLink="/accounts/cpinvoices">{{'lang_add' | language }}</button>
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                                <button *ngIf="InvData.permits.edit && InvData.financial_year.finance_id == viewdata.finance_id " type="button" class="albutton" routerLink="/accounts/epinvoices/{{branch_id}}/{{invoice_id}}/edit">{{'lang_edit' | language }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</div>