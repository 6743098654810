<div class="card">
	<div class="card-header">
		{{ "lang_vacation_form" | language }}
	</div>
	<div class="card-body">
		<div class="martb0">
			<div class="row">
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="mb-10">{{ "Last Joining Date " }}</div>
					<div class="mb-10">
						<b>{{ wholedata?.joining_date||0 }}</b>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10" >
					<div class="mb-10">{{ "Vaction Balance" }}</div>
					<div class="mb-10">
						<b>
							{{ wholedata?.vacation_balance||0 }} Days</b
						>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="mb-10">{{ "Vaction used days" }}</div>
					<div class="mb-10">
						<b>
							{{
								wholedata?.used_vacation_total||0
							}}
							Days</b
						>
					</div>
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="mb-10">{{ "Vaction Unused days" }}</div>
					<div class="mb-10">
						<b>
							{{
								(+wholedata?.vacation_balance||0) -
									(+viewData?.contract_vacation_info?.used_vacation_total||0)
							}}
							Days</b
						>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="p-1">
		<div class="input-group input-group-sm mb-10">
			<input
				name="account_search"
				id="filter"
				#filter
				[(ngModel)]="searchKey" (keyup)="get_employee_modules();"
				class="enablecode h34 form-control almnabrformcontrol"
				type="text"
				autocomplete="off"
				placeholder="{{ 'lang_search' | language }}"
			/>
			<span class="input-group-btn">
				<button
				*ngIf="!openFromProfile"
					(click)="showVacation.emit('V1CR')"
					id="btnSearch"
					type="button"
					matTooltip="{{ 'lang_add' | language }}"
					class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-plus blue-tooltip mnabricon"
				></button>
				<button
				*ngIf="openFromProfile"
					 [routerLink]="['/default/profile/create-vacation',id,branch_id]"
					id="btnSearch"
					type="button"
					matTooltip="{{ 'lang_add' | language }}"
					class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-plus blue-tooltip mnabricon"
				></button>
			</span>
		</div>
	</div>

	<div class="row p-1">
		<div class="col-md-12">
			<div class="table-responsive dropdownyes customResponsive">
				<table border="1" class="table CustomTable" *ngIf="records?.length >0">
					<thead>
						<tr>
							<td>#</td>
							<td>{{ "lang_vacation_type" | language }}</td>
							<td>{{ "lang_start_date" | language }}</td>
							<td>{{ "lang_end_date" | language }}</td>
							<td>{{ "lang_total_days" | language }}</td>
							<td>{{ "lang_status" | language }}</td>
							<td>{{ "lang_action" | language }}</td>
						</tr>
					</thead>
					<tbody class="AppendList">
						<tr
							*ngFor="
								let item of records;
								let i = index;
								let odd = odd;
								let even = even
							"
							[ngClass]="{ odd: odd, even: even }"
						>
					     	<td>{{ item.vacation_id }}</td>
							<td>{{ item.vacation_type_name }}</td>
							<td>{{ item.vacation_start_date_english }}</td>
							<td>{{ item.vacation_end_date_english }}</td>
							<td>{{ item.vacation_total_days }}</td>
							<td>
								<span *ngIf="item.approved_status == 1">{{'lang_approved'|language}}</span>
								<span *ngIf="item.approved_status == 0">{{'lang_rejected'|language}}</span>
							</td>
							<td>
								<!-- <i
									class="fa fa-file-pdf-o mnabricon s2icon"
									style="margin: 0 10px; padding: 0"
									matTooltip="{{ 'lang_preview' | language }}"
								></i> -->
								<i
									class="fa fa-eye mnabricon"
									(click)="ds.PreviewData(item.preview_link)"
									matTooltip="{{ 'lang_preview' | language }}"
								></i>
							</td>
						</tr>
					</tbody>
					<!-- <tbody *ngIf="records?.length == '0'" class="AppendList">
						<tr class="odd">
							<td colspan="7" align="center">
							 
							
							</td>
						</tr>
					</tbody> -->
				</table>

				<no-data style="margin: auto;"  [Opened]="records?.length == '0'"></no-data>
			</div>
		</div>
	</div>
</div>
<div *ngIf="records?.length != 0 " class="card-footer padb0 customborder">
	<div class="row">
	   <!--  <div *ngIf="able2delete && requestfor !== 'profile' " class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
			<i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataContr()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
		</div> -->
		<div class="col-lg-3 col-sm-6 col-6  mb-10">
			{{'lang_total' | language }} {{wholedata?.page?.total_records}}
		</div>
		<div class="col-lg-5 col-sm-6 col-12 mb-10">
			<mat-radio-group aria-label="Select an option">
				<mat-radio-button value="10" checked (click)="spinner.show();load_page_size(10)"> 10 </mat-radio-button>
				<mat-radio-button value="20" (click)="spinner.show();load_page_size(20)"> 20 </mat-radio-button>
				<mat-radio-button value="50" (click)="spinner.show();load_page_size(50)"> 50 </mat-radio-button>
				<mat-radio-button value="100" (click)="spinner.show();load_page_size(100)"> 100 </mat-radio-button>
				<mat-radio-button value="500" (click)="spinner.show();load_page_size(500)"> 500 </mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="col-lg-4 col-sm-6 col-12 mb-10">
			{{'lang_goto' | language }}
			<select class="customselect" (change)="spinner.show();page_length($event.target.value)">
				<option *ngFor="let k of ds.getrange(wholedata?.page?.total_pages)" value="{{k}}">{{k}}</option>
			</select>
		</div>
	</div>
</div> 