import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';



import * as mnabreditor from '../../../../../../ckeditor5/build/ckeditor';
@Component({
  selector: 'app-formhrj1-edit',
  templateUrl: './formhrj1-edit.component.html',
  styleUrls: ['./formhrj1-edit.component.scss']
})
export class Formhrj1EditComponent implements OnInit {
  public Editor = mnabreditor;
  @Output() joiningEvent = new EventEmitter();
  @Input() form_request_id;
  @Input() form_key;
  @Input() id;
  @Input() branch_id;
  form:FormGroup;
  lang_key          = localStorage.getItem('lang_key') || 'en';
  submitted         = false;
  joining_type:any  = [];
  users:any         = [];
  markstatus        = false;
  reviewstatus      = false;
  showmarkdelete    = true;
  showreviewdelete  = true;



  usersA: any       = [];
  usersB: any       = [];
  usersC: any       = [];
  usersM: any       = [];
  usersR: any       = [];
  employee_data:any = [];
  constructor(public ds:DataService, public datepipe : DatePipe , public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.spinner.show();
    this.load_required_datas();
    this.get_data_edit();
    this.form = new FormGroup({
      subject                           : new FormControl('', [Validators.required]),
      transaction_from                  : new FormControl({value : '', disabled : true}, [Validators.required]),
      employee_number                   : new FormControl('',[Validators.required]),
      joining_type                      : new FormControl('', [Validators.required]),
      joining_start_date_english        : new FormControl('', [Validators.required]),
      joining_start_date_arabic         : new FormControl(null, [Validators.required]),
      joining_description               : new FormControl(null, [Validators.required]),     
      signature_a_title                 : new FormControl('', [Validators.required]),
      signature_a_user_id               : new FormControl(null, [Validators.required]),
      signature_b_title                 : new FormControl(''),
      signature_b_user_id               : new FormControl(null),
      signature_c_title                 : new FormControl({value:'', disabled:true}),
      signature_c_user_id               : new FormControl({value:null, disabled:true}),
      needMark                          : new FormControl('0', [Validators.required]),
      marks                             : new FormControl(null),
      needReview                        : new FormControl('0', [Validators.required]),
      reviews                          : new FormControl(null)
    });
  }
  public load_required_datas() {
    let param = new FormData();
    param.append('lang_key', this.lang_key);
    //param.append('employee_number', this.employee_data.employee_number);
    let url = ['form/FORM_HRJ1/joining_type'];
    this.ds.forkJoin(url, this.ds.formData2string(param)).subscribe(res => {
      this.spinner.hide();
      if(res[0].status) {
        this.joining_type = res[0].records;
      }
    }, error => {
      this.spinner.hide();
    });
  }
  public get_data_edit() {
    this.usersM = [];
    this.usersR = [];
    this.ds.getActionByUrl([], 'form/' + this.form_key + '/vr/' + this.form_request_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        let data = {};
        this.employee_data                      = res.form_hrj1_data.records;
        data['subject']                         = res.form_hrj1_data.records['subject'];
        data['employee_number']                    = res.form_hrj1_data.records['employee_number'];
        data['joining_type']                    = res.form_hrj1_data.records['tr_joining_type'];
        data['joining_start_date_english']      = this.ds.encon(res.form_hrj1_data.records['joining_start_date_english']);
        data['joining_start_date_arabic']       = this.ds.arcon(res.form_hrj1_data.records['joining_start_date_arabic']);
        data['lang_key']                        = res.transactions_request['lang_key'];
        data['transaction_to']                  = res.form_hrj1_data.records['transaction_from_name'];
        data['transaction_from']                = res.form_hrj1_data.records['transaction_from_name'];
        data['joining_description']             = res.form_hrj1_data.records['tr_joining_description'];
        let defaultusers = [];
				if (res.transactions_persons && res.transactions_persons.status) {
					let markers = [];
					let reviewers = [];
					res.transactions_persons.records.forEach((v, k) => {
						defaultusers.push({
							value: v.user_id,
							label: v.person_name
						});
						//signature users
						if (v.transaction_persons_type === "signature") {
							if (v.transactions_persons_val1 === "A" && v.transactions_persons_val2 && v.user_id && v.person_name) {
                this.form.get('signature_a_title').setValue(v.transactions_persons_val2);
                this.form.get('signature_a_user_id').setValue(v.user_id);
							}
							if (v.transactions_persons_val1 === "B" && v.transactions_persons_val2 && v.user_id && v.person_name) {
                this.form.get('signature_b_title').setValue(v.transactions_persons_val2);
                this.form.get('signature_b_user_id').setValue(v.user_id);
							}
							if (v.transactions_persons_val1 === "C" && v.transactions_persons_val2 && v.user_id && v.person_name) {
                this.form.get('signature_c_title').setValue(v.transactions_persons_val2);
                this.form.get('signature_c_user_id').setValue(v.user_id);
							}
						}
						//markers
						if (v.transaction_persons_type === "marks") {
							this.markstatus = true;
              this.form.get('needMark').setValue('1');
							markers.push(v.user_id);
						}
						//reviewers
						if (v.transaction_persons_type === "reviews") {
              this.reviewstatus = true;
              this.form.get('needReview').setValue('1');
							reviewers.push(v.user_id);
						}
					});
					if (markers.length > 0) {
						this.form.get('marks').setValidators(Validators.required);
						this.form.get('marks').updateValueAndValidity();
					}
					if (reviewers.length > 0) {
						this.form.get('reviews').setValidators(Validators.required);
						this.form.get('reviews').updateValueAndValidity();
          }
          this.form.get('marks').setValue(markers);
          this.form.get('reviews').setValue(reviewers);
				}
				this.users = defaultusers;
        this.form.patchValue(data);
      } else {
        this.ds.dialogf('',res.error);
        this.router.navigate(['transactions/allforms/']);
      }
    }, error => {
        this.spinner.hide();
      this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  //Other Terms & Conditions End
  public search_users(key) {
		this.users = [];
		let param = new FormData();
		param.append('search', key.term);
		param.append('lang_key', this.lang_key);
		param.append('user_type_id', '1');
		this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.users = res.list;
			}
		})
	}
  public enable_field(field) {
		if (field == 'mark') {
      this.form.get('needMark').setValue('1');
		  this.form.get('marks').setValidators(Validators.required);
		  this.form.get('marks').updateValueAndValidity();
		  this.markstatus = true;
		} else if (field == 'review') {
      this.form.get('needReview').setValue('1');
		  this.form.get('reviews').setValidators(Validators.required);
		  this.form.get('reviews').updateValueAndValidity();
		  this.reviewstatus = true;
		}
	}
	public disable_field(field) {
		if (field == 'mark') {
      this.form.get('needMark').setValue('0');
			this.form.get('marks').setValue(null);
			this.form.get('marks').clearValidators();
			this.form.get('marks').updateValueAndValidity();
			this.markstatus = false;
		} else if (field == 'review') {
      this.form.get('needReview').setValue('0');
			this.form.get('reviews').setValue(null);
			this.form.get('reviews').clearValidators();
			this.form.get('reviews').updateValueAndValidity();
			this.reviewstatus = false;
		}
		this.form.updateValueAndValidity();
  }
  public submit() {
    this.alert.clear();
    this.submitted = true;
    if(this.form.valid) { this.submitted = false; }
    this.spinner.show();
    let param = new FormData();
    param.set('transaction_request_id', this.form_request_id);
    param.set('subject', this.form.get('subject').value || '');
    param.set('employee_number', this.form.get('employee_number').value || '');
    param.set('transaction_from', this.form.get('transaction_from').value || '');
    param.set('joining_type', this.form.get('joining_type').value || '');
    param.set('joining_start_date_english', this.form.get('joining_start_date_english').value || '');
    param.set('joining_start_date_arabic', this.form.get('joining_start_date_arabic').value || '');
    param.set('joining_description', this.form.get('joining_description').value || '');
    param.set('signature_a_title', this.form.get('signature_a_title').value || '');
    param.set('signature_a_user_id', this.form.get('signature_a_user_id').value || '');
    param.set('signature_b_title', this.form.get('signature_b_title').value || '');
    param.set('signature_b_user_id', this.form.get('signature_b_user_id').value || '');
    param.set('signature_c_title', this.form.get('signature_c_title').value || '');
    param.set('signature_c_user_id', this.form.get('signature_c_user_id').value || '');
    param.set('needMark', this.form.get('needMark').value);
    if (this.form.get('needMark').value == "1") {
      param.set('marks', this.form.get('marks').value.toString() || '');
    }
    param.set('needReview', this.form.get('needReview').value || '');
    if (this.form.get('needReview').value == "1") {
      param.set('reviews', this.form.get('reviews').value.toString() || '');
    }
    this.ds.postActionByUrl(param,'form/FORM_HRJ1/et/'+this.form_request_id).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
          this.alert.success(this.lang.transform('lang_success'));
          this.form.reset();
          setTimeout(() => {
            this.router.navigate(['transactions/form/' + this.form_key + '/vr/' + this.form_request_id]);
          }, 1000);
      } else {
          this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
          this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
}
