import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { NgbCalendar, NgbCalendarIslamicUmalqura, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { IslamicI18n } from '@shared/utils/arabicpicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Rstep2Component } from '../steps/rstep2/rstep2.component';

@Component({
  selector: 'app-sedit',
  templateUrl: './sedit.component.html',
  styleUrls: ['./sedit.component.scss'],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n }
  ]
})
export class SeditComponent implements OnInit {
  @ViewChild(Rstep2Component) rstep2: Rstep2Component
  id = this.route.snapshot.paramMap.get('id');
  code = this.route.snapshot.paramMap.get('code');
  datastatus: any;
  public RegistrationForm: FormGroup;
  errorstatus: boolean = false;
  errormsg: string = '';
  licencesList: any;
  action: string = "EDIT";
  viewdata: any;
  attachments: any;
  lang_key              = localStorage.getItem('lang_key') || 'en';
  licences: any;
  constructor(private cdRef: ChangeDetectorRef, public fb : FormBuilder ,public ds: DataService, public route:ActivatedRoute ,public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  ngOnInit(): void {
    this.spinner.show();
    this.buildHrForm();
    this.get_my_data();
  }
  buildHrForm() {
    this.RegistrationForm = new FormGroup({
      loginDetails: new FormGroup({
        username: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirm_password: new FormControl('', [Validators.required]),
      }, { validators: this.passwordConfirming }),
      idDetails: new FormGroup({
        employee_number: new FormControl('', [Validators.required]),
        employee_id_number: new FormControl('', [Validators.required]),
        firstname_english: new FormControl('', [Validators.required]),
        secondname_english: new FormControl('', [Validators.required]),
        thirdname_english: new FormControl(''),
        lastname_english: new FormControl('', [Validators.required]),
        firstname_arabic: new FormControl('', [Validators.required]),
        secondname_arabic: new FormControl('', [Validators.required]),
        thirdname_arabic: new FormControl(''),
        lastname_arabic: new FormControl('', [Validators.required]),
        iqama_expiry_date_english: new FormControl('', [Validators.required]),
        iqama_expiry_date_arabic: new FormControl(null, [Validators.required]),
        copy_number: new FormControl('', [Validators.required]),
        birth_date_english: new FormControl('', [Validators.required,]),
        birth_date_arabic: new FormControl(null, [Validators.required,]),
        gender: new FormControl('', [Validators.required]),
        marital_status: new FormControl('', [Validators.required]),
        religion: new FormControl('', [Validators.required]),
        nationality: new FormControl(null, [Validators.required]),
        profile_image: new FormControl(''),
        job_title_iqama: new FormControl('', [Validators.required]),
        employee_title: new FormControl(null),
        id_filename: new FormControl(''),
        idFileUpload: new FormControl(''),
      }),
      jobDetails: new FormGroup({
        job_title_id: new FormControl('', [Validators.required]),
        job_descriptions: new FormControl('', [Validators.required]),
        liclists: this.fb.array([]),
        resume_title: new FormControl(''),
        resume_file: new FormControl('')
      }),
      contactDetails: new FormGroup({
        primary_mobile: new FormControl('', [Validators.required]),
        primary_email: new FormControl('', [Validators.required, Validators.email]),
        primary_address: new FormControl('', [Validators.required]),
        contact_file_name: new FormControl(''),
        address_file: new FormControl(''),
      }),
      bankDetails: new FormGroup({
        account_number: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(35)]),
        bank_short_code: new FormControl(null, [Validators.required]),
        bank_file_name: new FormControl(''),
        bank_file: new FormControl(''),
      }),
      educationDetails: new FormGroup({
        primary_graduation_year: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
        primary_education_level: new FormControl('', [Validators.required]),
        edu_file_name: new FormControl(''),
        education_file: new FormControl(''),
      }),
      passportDetails: new FormGroup({
        passport_number: new FormControl(''),
        passport_issue_date_english: new FormControl(''),
        passport_issue_date_arabic: new FormControl(null),
        passport_expiry_date_english: new FormControl(''),
        passport_expiry_date_arabic: new FormControl(null),
        passport_issue_place: new FormControl(''),
        passport_file_name: new FormControl(''),
        passport_file: new FormControl(''),
      }),
      insuranceDetails: new FormGroup({
        insurance_number: new FormControl(''),
        insurance_date: new FormControl(''),
        insurance_type_class: new FormControl(''),
      }),
      signatureDetails: new FormGroup({
        signature: new FormControl(''),
        mark: new FormControl(''),
        verification_method: new FormControl('', [Validators.required]),
        verification_code: new FormControl(''),
      }),
    });
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirm_password').value) {
      return { invalid: true };
    }
  }
  get_my_data(){
    this.ds.getActionByNoToken([], 'signup/get_signup_view/' + this.id + '/' + this.code + '/' + this.lang_key).subscribe(data => {
      this.spinner.hide();
      this.datastatus = data.status;
      if (data.status) {
        this.viewdata = data.data;
        this.attachments = [];//data.attachments;
        this.build_my_data(data.data);
        if (data.data.profile_image) {
          setTimeout(() => {
            this.rstep2.get_profile_image(data.data.profile_image);
          }, 500);
        }
      } else {
        this.attachments = [];
        this.ds.dialogf('', data.error);
        this.router.navigate(['']);
      }
    }, error => {
        this.spinner.hide();
        this.attachments = [];
      this.datastatus = false;
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  build_my_data(value) {
    this.RegistrationForm.patchValue({
      loginDetails: {
        username: value.username,
        password: '',
        confirm_password: '',
      },
      idDetails: {
        employee_number: value.employee_number,
        employee_id_number: value.employee_id_number,
        firstname_english: value.firstname_english,
        secondname_english: value.secondname_english,
        thirdname_english: value.thirdname_english,
        lastname_english: value.lastname_english,
        firstname_arabic: value.firstname_arabic,
        secondname_arabic: value.secondname_arabic,
        thirdname_arabic: value.thirdname_arabic,
        lastname_arabic: value.lastname_arabic,
        iqama_expiry_date_english: this.ds.encon(value.iqama_expiry_date_english),
        iqama_expiry_date_arabic: this.ds.arcon(value.iqama_expiry_date_arabic),
        copy_number: value.copy_number,
        marital_status: value.marital_status,
        religion: value.religion,
        birth_date_english: this.ds.encon(value.birth_date_english),
        birth_date_arabic: this.ds.arcon(value.birth_date_arabic),
        gender: value.gender,
        nationality: (value) ? value.nationality : '',
        job_title_iqama: value.job_title_iqama,
        employee_title:value.employee_title,
      },
      jobDetails: {
        job_title_id: (value) ? value.job_title_id : '',
        job_descriptions: value.job_descriptions
      },
      contactDetails: {
        primary_mobile: value.primary_mobile,
        primary_email: value.primary_email,
        primary_address: value.primary_address,
      },
      bankDetails: {
        account_number: value.account_number,
        bank_short_code: (value) ? value.bank_short_code : '',
      },
      educationDetails: {
        primary_graduation_year: value.primary_graduation_year,
        primary_education_level: value.primary_education_level,
      },
      passportDetails:{
        passport_number: value.passport_number,
        passport_issue_date_english: this.ds.encon(value.passport_issue_date_english),
        passport_issue_date_arabic: this.ds.arcon(value.passport_issue_date_arabic),
        passport_expiry_date_english: this.ds.encon(value.passport_expiry_date_english),
        passport_expiry_date_arabic: this.ds.arcon(value.passport_expiry_date_arabic) ,
        passport_issue_place: value.passport_issue_place,
      },
      insuranceDetails: {
        insurance_number: value.insurance_number,
        insurance_date: this.ds.encon(value.insurance_date),
        insurance_type_class: value.insurance_type_class,
      }
    });
  }
  register() {
    this.alert.clear();
    let wholeData:any = {};
    let login = this.RegistrationForm.get('loginDetails').value;
    Object.assign(wholeData, login);
    let id = this.RegistrationForm.get('idDetails').value;
    Object.assign(wholeData, id);
    let job = this.RegistrationForm.get('jobDetails').value;
    Object.assign(wholeData, job);
    let contact = this.RegistrationForm.get('contactDetails').value;
    Object.assign(wholeData, contact);
    let bank = this.RegistrationForm.get('bankDetails').value;
    Object.assign(wholeData, bank);
    let edu = this.RegistrationForm.get('educationDetails').value;
    Object.assign(wholeData, edu);
    let passport = this.RegistrationForm.get('passportDetails').value;
    Object.assign(wholeData, passport);
    let ins = this.RegistrationForm.get('insuranceDetails').value;
    Object.assign(wholeData, ins);
    let sig = this.RegistrationForm.get('signatureDetails').value;
    Object.assign(wholeData, sig);
    this.spinner.show();
    if (this.RegistrationForm.status === "VALID") {
      const formData = this.ds.json2formdata(wholeData);
      this.ds.postActionByNoToken(formData, 'signup/update_signup_data/' + this.id + '/' + this.code).subscribe(data => {
        this.spinner.hide();
        this.errorstatus = data.status;
        if (data.status) {
          this.alert.success(data.msg);
          this.RegistrationForm.reset();
          setTimeout(() => {
            this.router.navigate(['viewdata/' + this.id + '/' + this.code]);
          }, 700);
        } else {
          this.alert.error(data.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      this.spinner.hide();
      this.alert.error(this.lang.transform('lang_fill_required_fields'));
    }
  }
  logoutme() {
    this.spinner.show();
    this.ds.getActionByNoToken([], 'signup/logoutme/' + this.id + '/' + this.code).subscribe(res => {
      this.spinner.hide();
      localStorage.removeItem('employee_id_number');
      localStorage.removeItem('verified_code');
      this.router.navigate(['auth/login']);
    }, error => {
      this.spinner.hide();
      this.ds.dialogf('',this.lang.transform('lang_wrong'));
    });
  }
}
