<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_implementation_phases" | language }}</h4>
		<span (click)="closeModal()"><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<div class="row">
        <div class="col-12 col-md-4">
            <p>{{"lang_unit_opening_balance" | language}}</p>
                <input
                type="file"
                #uploadIPhasesInput
                id="uploadIPhasesInput"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
                (change)="upload_i_phases($event.target.files)"
            />
            <button
                mat-button
                style="
                    background-color: rgb(136 224 136 / 42%);
                    width: 100%;margin-bottom: 5px;
                "
                (click)="uploadIPhasesInput.click()"
            >
                <i
                    class="fa fa-upload"
                    style="
                        font-size: 20px;
                        margin: 0 5px;
                        color: rgb(19, 146, 19);
                    "
                ></i>
                <span
                    style="
                        text-transform: uppercase;
                        font-weight: 600;
                        color: rgb(19, 146, 19);
                        font-size: 12px;
                    "
                    >{{ "lang_upload" | language }}</span
                >
            </button>
            <button
                mat-button
                style="
                    background-color: rgb(224 136 200 / 42%);
                    width: 100%;margin-bottom: 5px;
                "
                (click)="download_i_phases()"
            >
                <i
                    class="fa fa-download"
                    style="
                        font-size: 20px;
                        margin: 0 5px;
                        color: rgb(146, 19, 102);
                    "
                ></i>
                <span
                    style="
                        text-transform: uppercase;
                        font-weight: 600;
                        color: rgb(146, 19, 102);
                        font-size: 12px;
                    "
                    >{{ "lang_download" | language }}</span
                >
            </button>
            <button
                    mat-button
                    style="
                        background-color: rgb(113 166 223 / 42%);
                        width: 100%;margin-bottom: 5px;
                    "
                    (click)="get_i_phases_history()"
                >
                    <i
                        class="fa fa-download"
                        style="
                            font-size: 20px;
                            margin: 0 5px;
                            color: rgb(19, 65, 146);
                        "
                    ></i>
                    <span
                        style="
                            text-transform: uppercase;
                            font-weight: 600;
                            color: rgb(19, 65, 146);
                            font-size: 12px;
                        "
                        >{{ "lang_history" | language }}</span
                    >
                </button>
        </div>
        <div class="col-12 col-md-4">
            <p>{{'lang_units_file_configuration' | language}}</p>
            
            <input
                type="file"
                #uploadPlatformCodes
                id="uploadPlatformCodes"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
                (change)="upload_platform_codes($event.target.files)"
            />
            <button
            mat-button
            style="
                background-color: rgb(136 224 136 / 42%);
                width: 100%;margin-bottom: 5px;
            "
            (click)="uploadPlatformCodes.click()"
                >
                    <i
                        class="fa fa-upload"
                        style="
                            font-size: 20px;
                            margin: 0 5px;
                            color: rgb(19, 146, 19);
                        "
                    ></i>
                    <span
                        style="
                            text-transform: uppercase;
                            font-weight: 600;
                            color: rgb(19, 146, 19);
                            font-size: 12px;
                        "
                        >{{ "lang_upload" | language }}</span
                    >
                </button>
                <button
            mat-button
            style="
                background-color: rgb(224 136 200 / 42%);
                width: 100%;margin-bottom: 5px;
            "
            (click)="download_units_file_configuration()"
            >
            <i
                class="fa fa-download"
                style="
                    font-size: 20px;
                    margin: 0 5px;
                    color: rgb(146, 19, 102);
                "
            ></i>
            <span
                style="
                    text-transform: uppercase;
                    font-weight: 600;
                    color: rgb(146, 19, 102);
                    font-size: 12px;
                "
                >{{ "lang_download" | language }}</span
            >
            </button>
            <button
            mat-button
            style="
                background-color: rgb(113 166 223 / 42%);
                width: 100%;margin-bottom: 5px;
            "
            (click)="download_worklevels_file_configuration()"
            >
            <i
                class="fa fa-download"
                style="
                    font-size: 20px;
                    margin: 0 5px;
                    color: rgb(19, 65, 146);
                "
            ></i>
            <span
                style="
                    text-transform: uppercase;
                    font-weight: 600;
                    color: rgb(19, 65, 146);
                    font-size: 12px;
                "
                >{{ "lang_download_work_levels" | language }}</span
            >
            </button>
        </div>
        
        <div class="col-12 col-md-4">
            <p>{{'lang_phases_extra_data' | language}}</p>
            
            <input
                type="file"
                #uploadExtraData
                id="uploadExtraData"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
                (change)="upload_extra_data($event.target.files)"
            />
            <button
            mat-button
            style="
                background-color: rgb(136 224 136 / 42%);
                width: 100%;margin-bottom: 5px;
            "
            (click)="uploadExtraData.click()"
                >
                    <i
                        class="fa fa-upload"
                        style="
                            font-size: 20px;
                            margin: 0 5px;
                            color: rgb(19, 146, 19);
                        "
                    ></i>
                    <span
                        style="
                            text-transform: uppercase;
                            font-weight: 600;
                            color: rgb(19, 146, 19);
                            font-size: 12px;
                        "
                        >{{ "lang_upload" | language }}</span
                    >
                </button>
                <button
            mat-button
            style="
                background-color: rgb(224 136 200 / 42%);
                width: 100%;margin-bottom: 5px;
            "
            (click)="download_extra_data()"
            >
            <i
                class="fa fa-download"
                style="
                    font-size: 20px;
                    margin: 0 5px;
                    color: rgb(146, 19, 102);
                "
            ></i>
            <span
                style="
                    text-transform: uppercase;
                    font-weight: 600;
                    color: rgb(146, 19, 102);
                    font-size: 12px;
                "
                >{{ "lang_download" | language }}</span
            >
            </button>
            <button
                    mat-button
                    style="
                        background-color: rgb(113 166 223 / 42%);
                        width: 100%;margin-bottom: 5px;
                    "
                    (click)="get_extra_data_history()"
                >
                    <i
                        class="fa fa-download"
                        style="
                            font-size: 20px;
                            margin: 0 5px;
                            color: rgb(19, 65, 146);
                        "
                    ></i>
                    <span
                        style="
                            text-transform: uppercase;
                            font-weight: 600;
                            color: rgb(19, 65, 146);
                            font-size: 12px;
                        "
                        >{{ "lang_history" | language }}</span
                    >
                </button>
        </div>
    </div>

    <div class="row">
        <div class="col-12 mt-3">
            <ngx-skeleton-loader *ngIf="loading_attachments" count="5" [theme]="{ 'border-radius': '3px', height: '40px' }"></ngx-skeleton-loader>
            <div
                class="table-responsive dropdownyes customResponsive tablefields"
                *ngIf="history_files?.length && !loading_attachments"
            >
                <table border="1" class="table CustomTable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{{'lang_type_code_system' | language}}</th>
                            <th>{{'lang_writer' | language}}</th>
                            <th>{{'lang_created_date' | language}}</th>
                            <th>{{'lang_action' | language}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="
                                let item of history_files | slice: (page - 1) * size:page * size;
                                let i = index;
                                let odd = odd;
                                let even = even
                            "
                        >
                            <td>{{ i + 1 }}</td>
                            <td>{{item?.type_code_system}}</td>
                            <td>{{item?.writer}}</td>
                            <td>{{item?.created_datetime}}</td>
                            <td>
                                <i
                                class="fa fa-download"
                                style="
                                    font-size: 20px;
                                    margin: 0 5px;
                                    color: rgb(19, 65, 146);
                                "
                                (click)="download_i_phases_history(item)" 
                                ></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    class="mt-2"
                    style="
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    "
                >
                    <ngb-pagination
                        *ngIf="history_files.length"
                        [collectionSize]="history_files.length"
                        [(page)]="page"
                        [maxSize]="size"
                        [boundaryLinks]="true"
                    ></ngb-pagination>
                </div>
	        </div>

            <div
                class="table-responsive dropdownyes customResponsive tablefields"
                *ngIf="history_files_ED?.length && !loading_attachments"
            >
                <table border="1" class="table CustomTable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{{'lang_type_code_system' | language}}</th>
                            <th>{{'lang_writer' | language}}</th>
                            <th>{{'lang_created_date' | language}}</th>
                            <th>{{'lang_action' | language}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="
                                let item of history_files_ED | slice: (page - 1) * size:page * size;
                                let i = index;
                                let odd = odd;
                                let even = even
                            "
                        >
                            <td>{{ i + 1 }}</td>
                            <td>{{item?.type_code_system}}</td>
                            <td>{{item?.writer}}</td>
                            <td>{{item?.created_datetime}}</td>
                            <td>
                                <i
                                class="fa fa-download"
                                style="
                                    font-size: 20px;
                                    margin: 0 5px;
                                    color: rgb(19, 65, 146);
                                "
                                (click)="download_ED_history(item)" 
                                ></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    class="mt-2"
                    style="
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    "
                >
                    <ngb-pagination
                        *ngIf="history_files_ED.length"
                        [collectionSize]="history_files_ED.length"
                        [(page)]="page"
                        [maxSize]="size"
                        [boundaryLinks]="true"
                    ></ngb-pagination>
                </div>
	        </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="closeModal()"
		>
			{{ "lang_close" | language }}
		</button>
	</div>
</mat-dialog-actions>
