import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-send-code',
  templateUrl: './send-code.component.html',
  styleUrls: ['./send-code.component.scss']
})
export class SendCodeComponent implements OnInit {
  modalRefA: BsModalRef;
  form: FormGroup;
  form_request_id:any;
  request: any            = [];
  allData:any;
  stepName:string;
  verifylist:any = [];
  sendCodeType:string = 'signature';
  title:string = 'lang_signature';
  //verification
  sendstatus: boolean = false;
  sendlist: any = [];
  verifystatus: boolean = false;
  verifyerrormsg: any = '';
  sendcodestatus = false;
  codemsg: string = this.lang.transform('lang_send_code');
  
  constructor(public ds:DataService, public ls:LocalStorage, public lang:LanguagePipe,
    public spinner : PreloaderService, public alert:AlertService, public router:Router, private dialogRef: MatDialogRef<SendCodeComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data) {
      this.buildform();
      this.allData = this.data.allData;
      this.request   = this.data.allData?.view_request? this.data.allData?.view_request?.transactions_request : this.data.allData?.transactions_request ;
      console.log(this.request);
      console.log('*******',this.data.allData);
      if(this.data.allData?.view_request){
        this.form_request_id = this.data.allData?.view_request?.transactions_request?.records?.transaction_request_id;
      }else {
        this.form_request_id = this.data.allData?.transactions_request?.transaction_request_id;
      }
      this.stepName = this.data.stepName;
      this.sendCodeType = this.data.transaction_persons_type;
      this.title = this.data.title;
      this.showverificationform(this.data.transaction_persons_type);
    }

  ngOnInit(): void {
    
  }
  buildform() {
    this.form = new FormGroup({
      transaction_request_id: new FormControl(this.form_request_id, [Validators.required]),
      transaction_persons_type: new FormControl(''),
      sender_type: new FormControl('', [Validators.required]),
      do: new FormControl('', [Validators.required]),
      transactions_persons_last_step: new FormControl('', [Validators.required]),
    })
  }

  showverificationform(transaction_persons_type) {
    this.spinner.show();
    this.form.reset();
    this.sendstatus = false;
    this.sendcodestatus = false;
    this.verifyerrormsg = "";
    this.form.get('transaction_request_id').setValue(this.form_request_id);
    if(transaction_persons_type == 'signature') {
      this.form.get('transaction_persons_type').setValue(transaction_persons_type);
    }
    this.form.get('transactions_persons_last_step').setValue(this.stepName);
    if (transaction_persons_type === 'doall') {
      this.form.get('do').setValue('do_all');
      this.form.get('transaction_persons_type').setValue('');
    } else {
      this.form.get('do').setValue('do');
    }
    this.verifylist = [];
    this.ds.getActionByUrl([], 'tc/sender/select').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.verifylist = res.data;
      } else {
        this.ds.dialogf('',res.error);  
      }
    }, error => {
        this.spinner.hide();
      this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  sendVerificationCode() {
    this.spinner.show();
    this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'tc/sender/send_code').subscribe(data => {
      this.sendcodestatus = data.status;
      this.spinner.hide();
      if (data.status) {
        this.sendstatus = true;
        this.alert.success(data.msg);
        setTimeout(() => {
          this.dialogRef.close({formValue:this.form.value, sendstatus:this.sendstatus});
        }, 2000);
        
      } else {
        this.alert.error(data.error);
        this.sendstatus = false;
      }
    }, error => {
      this.sendcodestatus = false;
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      
      this.sendstatus = false;
    });
  }
  

  closeModal() {
    this.dialogRef.close({formValue:this.form.value, sendstatus:this.sendstatus});
  }
}
