import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';

declare var $:any;
@Component({
  selector: 'step-one-configrations',
  templateUrl: './step-one-configrations.component.html',
  styleUrls: ['./step-one-configrations.component.scss']
})
export class StepOneConfigrationsComponent implements OnInit {

  submitForm:boolean= false;
  @Output() vacationEvent = new EventEmitter();
  @Input() form_request_id;
  @Input() form_key;
  @Input() id;
  @Input() branch_id;
  vacations:any = [];
  editVacationForm:FormGroup;
  selectedCity: any;
  
 
  attachstatus: boolean = false;
  attachments: FormArray;
  filename = this.lang.transform('lang_select_files');
  isFile: boolean = false;
  users: any = [];
  employee_number ='';
  contract_id     = '';
  vacation_paid_days_from_contract = '';
  showOtherVacation = false;
  showAttachment = false;
  financial_details = []
  lang_key = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
    constructor(public ds:DataService, private router:Router, public spinner : PreloaderService, public lang:LanguagePipe, private fb:FormBuilder, private alert:AlertService, private snackBar:MatSnackBar) { }
  
    ngOnInit(): void {
      this.initForm();
      this.getVacationDetails();
      
    }
  
  
    initForm() {
      this.editVacationForm = this.fb.group({
        vacation_type_id                     : [null, Validators.required],
        before_vacation_working_date_english : [null, Validators.required],
        before_vacation_working_date_arabic  : [null, Validators.required],
        after_vacation_working_date_english  : [null, Validators.required],
        after_vacation_working_date_arabic   : [null, Validators.required],
        vacation_other                       : [null, this.showOtherVacation? Validators.required:[]],
        vacation_start_date_english          : [null, Validators.required],
        vacation_start_date_arabic           : [null, Validators.required],
        vacation_end_date_english            : [null, Validators.required],
        vacation_end_date_arabic             : [null, Validators.required],
        contract_id                          : [null, Validators.required],
        vacation_total_days                  : [null, Validators.required],
        vacation_paid_days_from_contract     : [null, Validators.required],
        vacation_total_paid_days             : [null, Validators.required],
        paid_days75                          : [null, Validators.required],
        paid_days100                         : [null, Validators.required],
        paid_days75_amount                   : [null, Validators.required],
        paid_days100_amount                  : [null, Validators.required],
        sick_balance                         : [null, Validators.required],
        direct_manager                       : [null, Validators.required],
        vacation_total_unpaid_days           : [null, Validators.required],
        vacation_total_paid_amount           : [null, Validators.required],
 
        needAttach                           : new FormControl('0', [Validators.required]),
 
        attachments                          : this.fb.array([], this.showAttachment?[Validators.required]:[])
      });
      this.attachments = this.editVacationForm.get("attachments") as FormArray;
    }
  
  
    getVacationType(employee_number:any) {
      let data = new FormData();
      data.append('employee_number', employee_number)
      this.ds.post('form/FORM_HRV1/get_vacation_type', data).subscribe(res => {
        console.log(res);
        if(res.status) {
          this.vacations = res.records;
          this.checkAttachment();
        }
      })

    }
    public enable_field(field) {
      /* if (field == 'mark') {
        this.createVactionForm.get('needMark').setValue('1');
        this.createVactionForm.get('marks').setValidators(Validators.required);
        this.createVactionForm.get('marks').updateValueAndValidity();
        this.markstatus = true;
      } else if (field == 'review') {
        this.createVactionForm.get('needReview').setValue('1');
        this.createVactionForm.get('reviews').setValidators(Validators.required);
        this.createVactionForm.get('reviews').updateValueAndValidity();
        this.reviewstatus = true;
      } else */ 
      if (field == 'attachment') {
        this.attachments.clear();
        this.editVacationForm.get('needAttach').setValue('1');
        this.addfiles();
        this.attachstatus = true;
      }
    }
  
    checkVacationEmployee() {
      console.log(1);
      
      if(this.editVacationForm.get('vacation_type_id').valid &&
      this.editVacationForm.get('before_vacation_working_date_english').valid &&
      this.editVacationForm.get('after_vacation_working_date_english').valid  ) {
        let formData = new FormData();
        formData.append('employee_number', this.employee_number);
        formData.append('vacation_type_id', this.editVacationForm.get('vacation_type_id').value);
        console.log('before_vacation_working_date_english',this.editVacationForm.get('before_vacation_working_date_english'));
        console.log('after_vacation_working_date_english', this.editVacationForm.get('after_vacation_working_date_english'));
        
        formData.append('before_vacation_working_date_english', this.editVacationForm.get('before_vacation_working_date_english').value.toLocaleDateString());
        formData.append('after_vacation_working_date_english', this.editVacationForm.get('after_vacation_working_date_english').value.toLocaleDateString());
        this.ds.post('form/FORM_HRV1/check_vacation_for_employee', formData).subscribe(res => {
          console.log(res);
          if(res.status) {
            if(this.editVacationForm.get('vacation_type_id').value == '3') {
              this.editVacationForm.get('paid_days75').setValue(res?.result?.paid_days75);
            this.editVacationForm.get('paid_days100').setValue(res?.result?.paid_days100);
            this.editVacationForm.get('paid_days75_amount').setValue(res?.result?.paid_days75_amount);
            this.editVacationForm.get('paid_days100_amount').setValue(res?.result?.paid_days100_amount);
            this.editVacationForm.get('sick_balance').setValue(res?.result?.sick_balance);
            this.editVacationForm.get('vacation_total_paid_amount').clearValidators();
            this.editVacationForm.get('vacation_total_paid_amount').updateValueAndValidity();
            this.editVacationForm.get('vacation_total_paid_days').clearValidators();
            this.editVacationForm.get('vacation_total_paid_days').updateValueAndValidity();
            } else {
              this.editVacationForm.get('vacation_total_paid_amount').setValue(res?.result?.paid_amount);
              this.editVacationForm.get('vacation_total_paid_days').setValue(res?.result?.paid_days);
              this.editVacationForm.get('paid_days75').clearValidators();
            this.editVacationForm.get('paid_days100').clearValidators();
            this.editVacationForm.get('paid_days75_amount').clearValidators();
            this.editVacationForm.get('paid_days100_amount').clearValidators();
            this.editVacationForm.get('sick_balance').clearValidators();
            this.editVacationForm.get('paid_days75').updateValueAndValidity();
            this.editVacationForm.get('paid_days100').updateValueAndValidity();
            this.editVacationForm.get('paid_days75_amount').updateValueAndValidity();
            this.editVacationForm.get('paid_days100_amount').updateValueAndValidity();
            this.editVacationForm.get('sick_balance').updateValueAndValidity();
            }
            this.editVacationForm.get('vacation_total_unpaid_days').setValue(res?.result?.unpaid_days);
            this.editVacationForm.get('vacation_total_days').setValue(res?.result?.vacation_days);
            this.editVacationForm.get('vacation_start_date_english').setValue(new Date(res?.result?.vacation_start_date));
            this.editVacationForm.get('vacation_end_date_english').setValue(new Date(res?.result?.vacation_end_date));
            this.ds.setENTOARDate(
              {value:this.editVacationForm.get('vacation_start_date_english').value},
              this.editVacationForm.get('vacation_start_date_arabic')
            );
            this.ds.setENTOARDate(
              {value:this.editVacationForm.get('vacation_end_date_english').value},
              this.editVacationForm.get('vacation_end_date_arabic')
            );
            console.log(this.editVacationForm.value);
            this.financial_details = res?.result?.finance
          }
        }, err => {
          this.editVacationForm.get('vacation_type_id').reset();
          this.editVacationForm.get('before_vacation_working_date_english').reset();
          this.editVacationForm.get('before_vacation_working_date_arabic').reset();
          this.editVacationForm.get('after_vacation_working_date_english').reset();
          this.editVacationForm.get('after_vacation_working_date_arabic').reset();
        })
      }
    }
  
    
    getEmployeeInfo(employee_number:any) {
      let data = new FormData();
      data.append('employee_number', employee_number)
      this.ds.post('form/FORM_HRV1/get_employee_info', data).subscribe(res => {
        if(res.status) {
          console.log(res);
          this.editVacationForm.controls['contract_id'].setValue(res?.result?.contract_vacation_info?.contract_id);
          this.editVacationForm.controls['vacation_paid_days_from_contract'].setValue(res?.result?.contract_vacation_info?.vacation_paid_days_only);
          this.employee_number = res.result?.employee_info?.employee_number;
          this.contract_id = res?.result?.contract_vacation_info?.contract_id;
          this.vacation_paid_days_from_contract = res?.result?.contract_vacation_info?.vacation_paid_days_only;
        }
        
      })
    }

    getVacationDetails() {
      this.spinner.show();
      this.ds.get(`form/FORM_HRV1/vr/${this.form_request_id}`).subscribe(res => {
        if(res.status) {
          console.log(res);
          this.spinner.hide();
          this.employee_number = res?.form_hrv1_vacation_data?.records?.employee_number;
          this.getEmployeeInfo(res?.form_hrv1_vacation_data?.records?.employee_number);
          this.getVacationType(res?.form_hrv1_vacation_data?.records?.employee_number);
          this.editVacationForm.get('direct_manager').setValue(res?.form_hrv1_vacation_data?.records?.direct_manager);
          if(res?.form_hrv1_vacation_data?.records?.direct_manager_name && res?.form_hrv1_vacation_data?.records?.direct_manager){
            this.users = [{
              label: res?.form_hrv1_vacation_data?.records?.direct_manager_name,
              value:res?.form_hrv1_vacation_data?.records?.direct_manager
            }]


          }

          this.editVacationForm.get('vacation_type_id').setValue(res?.form_hrv1_vacation_data?.records?.vacation_type_id);
          this.editVacationForm.get('before_vacation_working_date_english').setValue(new Date(res?.form_hrv1_vacation_data?.records?.before_vacation_working_date_english));
          this.editVacationForm.get('after_vacation_working_date_english').setValue(new Date(res?.form_hrv1_vacation_data?.records?.after_vacation_working_date_english));
          this.ds.setENTOARDate(
            {value:this.editVacationForm.get('before_vacation_working_date_english').value},
            this.editVacationForm.get(
              'before_vacation_working_date_arabic'
            )
          );
          this.ds.setENTOARDate(
            {value:this.editVacationForm.get('after_vacation_working_date_english').value},
            this.editVacationForm.get(
              'after_vacation_working_date_arabic'
            )
          );
          
          this.checkVacationEmployee();
          
          let defaultusers = [];
          if (res?.transactions_persons && res?.transactions_persons?.status) {
            let markers = [];
            let reviewers = [];
            res?.transactions_persons?.records.forEach((v, k) => {
              defaultusers.push({
                value: v.user_id,
                label: v.person_name
              });
          
            
            });
         
          }
    
          if(res?.form_hrv1_attachments && res?.form_hrv1_attachments?.status) {

          }
          
        }
        
      }, err => {
        this.spinner.hide();
      })
    }
  
    checkAttachment() {
      
      let vacation = this.vacations.find((item => {
        return item.value == this.editVacationForm.get('vacation_type_id').value;
      }));
      
      if(vacation) {
        if(vacation.need_attachment == '1') {
          this.showAttachment = true;
          this.attachments.setValidators(Validators.required);
          console.log(this.editVacationForm.get('attachments'));
          
        } else {
          this.showAttachment = false;
          this.attachments.clearValidators();
        }
      }


      if(vacation.value == '9') {
        this.showOtherVacation = true;
      } else {
        this.showOtherVacation = false;
      }
    }
  
 
  
    public files(attach_title?:string,file?:any): FormGroup {
      return this.fb.group({
        attach_title: new FormControl(attach_title ? attach_title:'', (this.editVacationForm.get('needAttach').value == "1") ?[Validators.required] : null),
        file: new FormControl(file ? file:'', (this.editVacationForm.get('needAttach').value == "1") ? [Validators.required] : null)
      });
    }
    addAttachedFiles(name,file){
      this.attachments.push(this.files(name, file))
    }
    public addfiles() {
      debugger;
      if (this.attachments.length < 5) {
        this.attachments.push(this.files());
      }
    }
    public removefiles(index) {
      if (this.attachments.length > 1) {
        this.attachments.removeAt(index);
      }
    }
  
    public getfilesgroup(index): FormGroup {
      const formGroup = this.attachments.controls[index] as FormGroup;
      return formGroup;
    }
  
    public UploadFile(event, index) {
      if (event.target.files[0] && event.target.files[0] !== undefined && event.target.files[0].name) {
        const fileid = event.target.files[0];
        $("#uploadText" + index).text(fileid.name);
        this.attachments.controls[index].get('file').setValue(fileid, { emitEvent: true });
      } else {
        this.attachments.controls[index].get('file').setValue(null);
        $("#uploadText" + index).text(this.lang.transform('lang_select_files'));
      }
    }
    public uploadFile(event) {
      if (
        event.target.files[0] &&
        event.target.files[0] !== undefined &&
        event.target.files[0].name
      ) {
        this.isFile = true;
        const fileid = event.target.files[0];
        this.filename = fileid.name;
        this.editVacationForm.get("upload").setValue(fileid, { emitEvent: true });
      } else {
        this.isFile = false;
        this.editVacationForm.get("upload").setValue(null);
        this.filename = this.lang.transform('lang_select_files');
      }
    }
    public removeFile() {
      this.isFile = false;
      this.editVacationForm.get("upload").setValue(null);
      this.filename = this.lang.transform('lang_select_files');
    }
  
 
    get filesFormGroup() {
      return this.editVacationForm.get("attachments") as FormArray;
    }
  
    public search_users(key) {
      this.users = [];
      let param = new FormData();
      param.append('search', key.term || '');
      param.append('lang_key', this.lang_key);
      param.append('user_type_id', '1');
      this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      })
    }

    onSubmit() {
      console.log(this.editVacationForm);
      this.submitForm = true;
      if(this.editVacationForm.valid) {
        let formData = new FormData();
        formData.append('employee_number', this.employee_number);
        formData.append('vacation_type_id', this.editVacationForm.get('vacation_type_id').value);
        formData.append('vacation_other', this.editVacationForm.get('vacation_other').value);
        formData.append('contract_id', this.editVacationForm.get('contract_id').value);
        formData.append('before_vacation_working_date_english', this.editVacationForm.get('before_vacation_working_date_english').value.toLocaleDateString());
        formData.append('before_vacation_working_date_arabic', this.editVacationForm.get('before_vacation_working_date_arabic').value);
        formData.append('vacation_start_date_english', this.editVacationForm.get('vacation_start_date_english').value.toLocaleDateString());
        formData.append('vacation_start_date_arabic', this.editVacationForm.get('vacation_start_date_arabic').value);
        formData.append('vacation_end_date_english', this.editVacationForm.get('vacation_end_date_english').value.toLocaleDateString());
        formData.append('vacation_end_date_arabic', this.editVacationForm.get('vacation_end_date_arabic').value);
        formData.append('after_vacation_working_date_english', this.editVacationForm.get('after_vacation_working_date_english').value.toLocaleDateString());
        formData.append('after_vacation_working_date_arabic', this.editVacationForm.get('after_vacation_working_date_arabic').value);
        formData.append('vacation_total_days', this.editVacationForm.get('vacation_total_days').value);
        formData.append('vacation_paid_days_from_contract', this.editVacationForm.get('vacation_paid_days_from_contract').value);
        formData.append('direct_manager', this.editVacationForm.get('direct_manager').value);
 
        /* formData.append('signature_a_user_id', this.editVacationForm.get('signature_a_user_id').value);
        formData.append('signature_a_title', this.editVacationForm.get('signature_a_title').value);
        formData.append('signature_b_user_id', this.editVacationForm.get('signature_b_user_id').value);
        formData.append('signature_b_title', this.editVacationForm.get('signature_b_title').value); */
        formData.append('needAttach', this.editVacationForm.get('needAttach').value);
       
       
        formData.append('vacation_total_unpaid_days', this.editVacationForm.get('vacation_total_unpaid_days').value);
        if(this.editVacationForm.get('vacation_type_id').value == '3') {
          formData.append('vacation_total_paid_days', (this.editVacationForm.get('paid_days75').value + this.editVacationForm.get('paid_days100').value));
          formData.append('vacation_total_paid_amount', (this.editVacationForm.get('paid_days75_amount').value + this.editVacationForm.get('paid_days100_amount').value));
        } else {
          formData.append('vacation_total_paid_days', this.editVacationForm.get('vacation_total_paid_days').value);
          formData.append('vacation_total_paid_amount', this.editVacationForm.get('vacation_total_paid_amount').value);
        }
        
          this.attachments.controls.forEach((item, index) => {
            formData.append(`attachments[${index+1}][file]`, item.get('file').value);
            formData.append(`attachments[${index+1}][attach_title]`, item.get('attach_title').value);
          });
        formData.append('transaction_request_id', this.form_request_id);

        if(this.showAttachment) {
          if(this.attachments.length) {
            this.spinner.show();
            this.ds.post(`form/FORM_HRV1/et`, formData).subscribe(res => {
              console.log(res);
              this.spinner.hide();
              if(res.status) {
                this.showMsg(res.msg, 'ok', 'success-snackbar');
                
                this.submitForm = false;
                setTimeout(() => {
                  location.reload();
                }, 2000);
              } else {
                this.showMsg(res.error, 'ok', 'error-snackbar');
              }
            })
          } else {
            console.log('add attachments');
            this.showMsg('add attachments', 'ok', 'error-snackbar');
            
          }
        } else {
          this.spinner.show();
          this.ds.post(`form/FORM_HRV1/et`, formData).subscribe(res => {
            console.log(res);
            this.spinner.hide();
            if(res.status) {
              this.showMsg(res.msg, 'ok', 'success-snackbar');
              this.submitForm = false;
              setTimeout(() => {
                location.reload();
              }, 2000);
            } else {
              this.showMsg(res.error, 'ok', 'error-snackbar');
            }
          })
        }
        
      } else {
        this.showMsg('All Fields are required!!', 'ok', 'error-snackbar');
      }
      
    }

    showMsg(msg:string, action:string, className:string) {
      this.snackBar.open(msg, action, {
        duration:2000,
        panelClass: [className]
      });
    }

   

}
