import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HumanresourcesRoutingModule } from './humanresources-routing.module';
import { EmployeesComponent } from './employees/employees.component';
import { ApplicationsComponent } from './applications/applications.component';
import { SharedModule } from '@shared/shared.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SettingsComponent } from './settings/settings.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CreateComponent } from './create/create.component';
import { Step1Component } from './steps/step1/step1.component';
import { Step2Component } from './steps/step2/step2.component';
import { Step3Component } from './steps/step3/step3.component';
import { Step4Component } from './steps/step4/step4.component';
import { Step5Component } from './steps/step5/step5.component';
import { Step6Component } from './steps/step6/step6.component';
import { Step7Component } from './steps/step7/step7.component';
import { EeditComponent } from './eedit/eedit.component';
import { EviewComponent } from './eview/eview.component';
import { PositionsComponent } from './panels/positions/positions.component';
import { AttachmentsComponent } from './panels/attachments/attachments.component';
import { ContactsComponent } from './panels/contacts/contacts.component';
import { ContractsComponent } from './panels/contracts/contracts.component';
import { EducationComponent } from './panels/education/education.component';
import { InsuranceComponent } from './panels/insurance/insurance.component';
import { NotesComponent } from './panels/notes/notes.component';
import { ApplicationviewComponent } from './applicationview/applicationview.component';
import { TransactionsModule } from '../transactions/transactions.module';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/custom-material-config';
import { ModulesComponent } from './panels/modules/modules.component';
import { JoiningComponent } from './panels/joining/joining.component';
import { VactionComponent } from './panels/vaction/vaction.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ViewVactionComponent } from './panels/vaction/view-vaction/view-vaction.component';
import { FinanialDetialsComponent } from './panels/finanial-details/finanial-details.component';
import { AllEmployeesComponent } from './employees/all-employees/AllEmployees.component';
import { FilterEmployeeCardComponent } from './employees/all-employees/filter-card/filter-card.component';
import { FilterEmployeeCardMobComponent } from './employees/all-employees/filter-card-mob/filter-card-mob.component';
import { SortEmployeeCardMobComponent } from './employees/all-employees/sort-card-mob/sort-card-mob.component';
import { CommunicationComponent } from './panels/communication/communication.component';
import { CalenderComponent } from './employees/calender/calender.component';
import { CalendarModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AttendanceComponent } from './employees/all-employees/attendance/attendance.component';
import { AddGroupComponent } from './employees/all-employees/attendance/add-group/add-group.component';
import { AddShifComponent } from './employees/all-employees/attendance/shifts/add-shift/add-shift.component';
import { AllShiftsComponent } from './employees/all-employees/attendance/shifts/all-shifts/all-shifts.component';
import { AddSingleShiftComponent } from './employees/all-employees/attendance/shifts/add-shift/add-single-shift/add-single-shift.component';

const COMPONENTS = [AddSingleShiftComponent,AddShifComponent,SortEmployeeCardMobComponent,FilterEmployeeCardMobComponent,FilterEmployeeCardComponent,AllEmployeesComponent,FinanialDetialsComponent,EmployeesComponent, ApplicationsComponent,SettingsComponent,NotificationsComponent, CreateComponent, Step1Component, Step2Component, Step3Component, Step4Component, Step5Component, Step6Component, Step7Component, PositionsComponent, EeditComponent, EviewComponent, AttachmentsComponent, ContactsComponent, ContractsComponent, EducationComponent, InsuranceComponent, NotesComponent, ApplicationviewComponent,ModulesComponent, JoiningComponent, VactionComponent];
const COMPONENTS_DYNAMIC = [];
@NgModule({
  imports: [
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    HumanresourcesRoutingModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ModalModule.forRoot(),
    TransactionsModule
  ],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, ViewVactionComponent, CommunicationComponent, CalenderComponent, AttendanceComponent, AddGroupComponent, AllShiftsComponent],
  entryComponents: COMPONENTS_DYNAMIC,
  exports: [...COMPONENTS, ...COMPONENTS_DYNAMIC, MatFormFieldModule,MatInputModule],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers : [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ]
})
export class HumanresourcesModule { }
