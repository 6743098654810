<div class="ticket-header">
    <div class="row">
        <div class="col-12 col-sm-3 mb-2 mb-sm-0">
            <div class="search-input">
                <input
                    type="text"
                    class="form-control almnabrformcontrol"
                    [(ngModel)]="searchKey"
                    placeholder="{{ 'lang_search' | language }}"
				    />
                <i class="search-icon fa fa-search mnabricon"></i>
            </div>
        </div>
        <div class="col-12 col-sm-2 mb-2 mb-sm-0">
            <ng-select
                [items]="status_list"
                [multiple]="false"
                appendTo="body"
                bindLabel="label"
                bindValue="value"
                clearAllText="Clear"
                placeholder="status"
                [(ngModel)]="status_filter"
                >
            </ng-select>
        </div>
        <div class="col-12 col-sm-2 mb-2 mb-sm-0">
            <ng-select
                [items]="priority_list"
                [multiple]="false"
                appendTo="body"
                bindLabel="label"
                bindValue="value"
                clearAllText="Clear"
                placeholder="priority"
                [(ngModel)]="priority_filter"
                >
            </ng-select>
        </div>
        <div class="col-xs-0 col-sm-3 mb-2 mb-sm-0"></div>
        <div class="col-12 col-sm-2">
            <button class="albutton mnabrbutton" mat-button routerLink="/tasks/create_ticket">
                <i class="fa fa-plus" style="font-size: 17px; margin: 0 5px;"></i>
                <span>New Ticket</span>
            </button>
        </div>
    </div>
</div>
<div class="tickets-list">
    <div class="row">
        <div class="col-12">
            <div class="table-responsive customResponsive">
                <table border="1" class="table CustomTable">
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>Ticket Name</td>
                            <td>Assigned To</td>
                            <td>Priority</td>
                            <td>Status</td>
                            <td>Created At</td>
                            <td>Actions</td>
                            <td>Export</td>
                        </tr>
                    </thead>
                    <tbody class="AppendList">
                        <ng-container *ngFor="let item of [1,2,3,4]">
                            <tr>
                                <td colspan="8">
                                    <div class="projec-name-tr">
                                        <span class="project-name">Project Name</span>
                                        <a routerLink="/">
                                            <i class="fa fa-plus" style="font-size: 14px; margin: 0 5px;"></i>
                                            <span>New Task</span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngFor="let item of [1,2,3]">
                                <td>1</td>
                                <td>ticket name</td>
                                <td>
                                    <div class="assigned_user">
                                        <img src="assets/images/avatar.png" alt="assigned_user">
                                        <span>Abdullah1</span>
                                    </div>
                                </td>
                                <td>Low</td>
                                <td>Not Started</td>
                                <td>1/1/2022 03:40 PM</td>
                                <td>
                                    <div class="action-btns">
                                        <button mat-button>
                                            <i class="fa fa-eye"></i>
                                        </button>
                                        <button mat-button>
                                            <i class="fa fa-pencil mnabricon"></i>
                                        </button>
                                        <button mat-button>
                                            <i class="fa fa-trash mnabricon"></i>
                                        </button>
                                    </div>
                                </td>
                                <td>Low</td>
                            </tr>
                        </ng-container>
                        
                    </tbody>
                    <!-- <tbody *ngIf="loading">
                        <tr>
                            <td colspan="14" class="nodata" align="center">
                                <ngx-skeleton-loader  count="5" [theme]="{ 'border-radius': '3px', height: '50px' }"></ngx-skeleton-loader>
                            </td>
                        </tr>
                    </tbody> -->
                    <!-- <tbody *ngIf="listData.length == '0' && !loading" class="AppendList">
                        <tr class="odd">
                            <td colspan="14" class="nodata" align="center">
                                {{ nodata }}
                            </td>
                        </tr>
                    </tbody> -->
                </table>
            </div>
        </div>
    </div>
</div>
