<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_form" | language }}</h4>
		<span (click)="closeModal()"><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="form">
		<div class="row">
			<div
				class="col-12 col-sm-3"
				[dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
				<mat-form-field appearance="outline" style="width: 100%;">
					<mat-label>{{ "lang_report_number" | language }}</mat-label>
					<input matInput formControlName="report_number" required />
					<mat-error
						*ngIf="
							form.get('report_number').touched &&
							form.get('report_number').errors
								?.required
						"
					>
						{{ "lang_field_required" | language }}
					</mat-error>
				</mat-form-field>
			</div>
			<div class="col-md-12 mt-2">
				<div
					class="table-responsive customResponsive tablefields quotationitemtable"
				>
					<table
						border="1"
						class="table CustomTable"
						width="100%"
						formArrayName="data"
					>
						<thead>
							<tr>
								<td style="padding: 5px; width: 5%" class="text-center">
									#
								</td>
								<td style="padding: 5px; width: 5%" class="text-center">
									<mat-icon
										class="mnabricon"
										matTooltip="{{ 'lang_add' | language }}"
										(click)="add_new_data()"
										>add_box</mat-icon
									>
								</td>
								<td style="padding: 5px;width: 15%" class="text-center">
									{{ "lang_sample_number" | language }} <span class="red">*</span>
								</td>
								<td style="padding: 5px;" class="text-center" *ngIf="list_type == 'concrete'">
									{{ "lang_strength_result" | language }} <span class="red">*</span>
								</td>
								<td style="padding: 5px;width: 15%" class="text-center" *ngIf="list_type == 'soil'">
									{{ "lang_layer_number" | language }} <span class="red">*</span>
								</td>
								<td style="padding: 5px;width: 15%" class="text-center" *ngIf="list_type == 'soil'">
									{{ "lang_depth_number" | language }} <span class="red">*</span>
								</td>
								<td style="padding: 5px;width: 25%" class="text-center" *ngIf="list_type == 'soil'">
									{{ "lang_material_description" | language }} <span class="red">*</span>
								</td>
								<td style="padding: 5px;width: 25%" class="text-center" *ngIf="list_type == 'soil'">
									{{ "lang_percent_compaction" | language }} <span class="red">*</span>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let item of dataList.controls;
									let i = index;
									let odd = odd;
									let even = even
								"
								[formGroupName]="i"
							>
								<td
									class="verticalalignmid text-center"
									style="padding: 5px; word-break: break-all"
								>
									{{ i + 1 }}
								</td>
								<td class="verticalalignmid text-center">
									<mat-icon
										(click)="remove_data(i)"
										class="mnabricon red"
										matTooltip="{{ 'lang_delete' | language }}"
										>delete_forever</mat-icon
									>
								</td>
								<td
									style="padding: 5px; position: relative"
									class="text-center"
								>
									<input
										type="number"
										formControlName="sample_number"
										placeholder="{{ 'lang_sample_number' | language }}"
										autocomplete="off"
										class="form-control almnabrformcontrol"
									/>
								</td>
								<td
									style="padding: 5px; position: relative"
									class="text-center"
									*ngIf="list_type == 'soil'"
								>
									<input
										type="number"
										formControlName="layer_number"
										placeholder="{{ 'lang_layer_number' | language }}"
										autocomplete="off"
										class="form-control almnabrformcontrol"
									/>
								</td>
								<td
									style="padding: 5px; position: relative"
									class="text-center"
									*ngIf="list_type == 'concrete'"
								>
									<input
										type="number"
										formControlName="strength_result"
										placeholder="{{ 'lang_strength_result' | language }}"
										autocomplete="off"
										class="form-control almnabrformcontrol"
									/>
								</td>
								<td
									style="padding: 5px; position: relative"
									class="text-center"
									*ngIf="list_type == 'soil'"
								>
									<input
										type="number"
										formControlName="depth_number"
										placeholder="{{ 'lang_depth_number' | language }}"
										autocomplete="off"
										class="form-control almnabrformcontrol"
									/>
								</td>
								<td
									style="padding: 5px; position: relative"
									class="text-center"
									*ngIf="list_type == 'soil'"
								>
									<input
										type="text"
										formControlName="material_description"
										placeholder="{{ 'lang_material_description' | language }}"
										autocomplete="off"
										class="form-control almnabrformcontrol"
									/>
								</td>
								<td
									style="padding: 5px; position: relative"
									class="text-center"
									*ngIf="list_type == 'soil'"
								>
									<input
										type="number"
										min="0"
										max="100"
										formControlName="percent_compaction"
										placeholder="{{ 'lang_percent_compaction' | language }}"
										autocomplete="off"
										class="form-control almnabrformcontrol"
									/>
								</td>
								
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="col-12 mt-3">
				<p class="mb-10">
					{{ "lang_attachments" | language }}
					<span class="red" *ngIf="other_attachments?.length">*</span>
				</p>
				<div
					class="table-responsive customResponsive tablefields quotationitemtable"
				>
					<table
						border="1"
						class="table CustomTable"
						style="width: 99% !important;"
						formArrayName="attachments"
					>
						<thead>
							<tr>
								<td
									width="2%"
									style="padding: 5px; word-break: break-all"
								>
									#
								</td>
								<td
									width="2%"
									style="padding: 5px; word-break: break-all"
								>
									<mat-icon
										class="mnabricon"
										matTooltip="{{ 'lang_add' | language }}"
										(click)="addfiles()"
										>add_box</mat-icon
									>
								</td>
								<td
									width="48%"
									style="padding: 5px; word-break: break-all"
								>
									{{ "lang_title" | language }}
								</td>
								<td
									width="48%"
									style="padding: 5px; word-break: break-all"
								>
									{{ "lang_file" | language }}
								</td>
							</tr>
						</thead>
						<tbody>
							<tr
								*ngFor="
									let file of filesFormGroup.controls;
									let i = index;
									let odd = odd;
									let even = even
								"
								[formGroupName]="i"
							>
								<td
									class="verticalalignmid"
									style="padding: 5px; word-break: break-all"
								>
									{{ i + 1 }}
								</td>
								<td class="verticalalignmid">
									<mat-icon
										*ngIf="
											!other_attachments.controls[i].get('delete_icon')
												.value
										"
										(click)="removefiles(i)"
										class="mnabricon red"
										matTooltip="{{ 'lang_delete' | language }}"
										>delete_forever</mat-icon
									>
								</td>
								<td
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									<input
										type="text"
										formControlName="attach_title"
										placeholder="{{ 'lang_description' | language }}"
										autocomplete="off"
										class="form-control almnabrformcontrol"
									/>
								</td>
								<td
									class="verticalalignmid"
									style="
										padding: 5px;
										word-break: break-all;
										position: relative;
									"
								>
									<input
										name="file"
										type="file"
										required
										onclick="this.value = null"
										(change)="
											ds.uploadSingleFile(
												$event,
												other_attachments.controls[i].get('file'),
												'file' + i,
												filename
											)
										"
										class="nghide albutton width100"
									/>
									<label id="file{{ i }}" class="albutton">{{
										filename
									}}</label>
								</td>
								
							</tr>
						</tbody>
					</table>
					
				</div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="col-md-4 col-xs-12 mb-2 mb-md-0">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="closeModal()"
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-0 col-md-4"></div>
	<div class="col-md-4 col-xs-12 mb-2 mb-md-0">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="submit()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
