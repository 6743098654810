<div class="card">
    <div class="width100">
        <i *ngIf="files && files.res" matTooltip="{{'lang_recent_attachment' | language }} : {{files.res_d}}" class="fa fa-paperclip s2icon mnabricon" (click)="ds.previewData(files.res);"></i>
        <div class="card-header"> {{'lang_human_resources_job_applied_for' | language }} </div>
        <div class="card-body pad0" [formGroup]="signupForm">
            <div class="mbrl15" formGroupName="jobDetails">
                <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                        <div class="form-groups">
                            <label for="job_title_id">{{'lang_human_resources_job_title_iqama' | language }} : <span class="red">*</span></label>
                            <ng-select 
                                [items]="jobLists" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                appendTo="body" 
                                clearAllText="Clear"
                                placeholder="{{'lang_human_resources_job_title_iqama' | language}}"
                                formControlName="job_title_id"
                                (change)="get_needed_licences($event)">
                            </ng-select>
                            <mat-error *ngIf="signupForm.get('jobDetails')['controls']['job_title_id'].touched && signupForm.get('jobDetails')['controls']['job_title_id'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                        <div class="form-group">
                            <label for="resume_title">{{'lang_resume_title' | language }} : <span class="red" *ngIf="action !=='EDIT'">* </span> </label>
                            <input formControlName="resume_title" id="resume_title" type="text" placeholder="{{'lang_resume_title' | language }}" class="form-control almnabrformcontrol resume_title" />
                            <mat-error *ngIf="signupForm.get('jobDetails')['controls']['resume_title'].touched && signupForm.get('jobDetails')['controls']['resume_title'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                        <div class="form-group">
                            <div *ngIf="!isFile" for="resume_file"> <i aria-hidden="true" class="red" *ngIf="action !=='EDIT'">*</i> </div>
                            <div class="opacity0" *ngIf="action =='EDIT'"> <i aria-hidden="true" class="red">*</i> </div>
                            <div *ngIf="isFile" for="resume_file"> <i aria-hidden="true" (click)="removeFile()" class="fa faicon fa-close red"></i></div>
                            <div class="IDDIV">
                                <input name="resume_file" type="file" onclick="this.value = null" (change)="uploadIDFile($event)" class="nghide width100 albutton" accept=".pdf">
                                <label id="uploadIDButton" class="albutton">{{idfilename}} </label>
                            </div>
                            <mat-error *ngIf="signupForm.get('jobDetails')['controls']['resume_file'].touched && signupForm.get('jobDetails')['controls']['resume_file'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-10">
                        <div class="form-group">
                            <label for="job_descriptions">{{'lang_job_descriptions' | language }} : <span class="red">*</span></label>
                            <textarea formControlName="job_descriptions" id="job_descriptions" type="text" placeholder="{{'lang_job_descriptions' | language }}" class="form-control almnabrformcontrol job_descriptions"></textarea>
                            <mat-error *ngIf="signupForm.get('jobDetails')['controls']['job_descriptions'].touched && signupForm.get('jobDetails')['controls']['job_descriptions'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                        </div>
                    </div>
                </div>               
                <div formArrayName="liclists">
                    <label class="red" *ngIf="licencesstatus">{{'lang_need_licence_details' | language }}</label>
                    <hr *ngIf="licencesstatus">
                    <div *ngFor="let licence of licenceFormGroup.controls; let i = index; let jj = i; let aj = i; let ed = i; let ad = i;">
                        <div class="row mb-10" [formGroupName]="i">
                            <input type="hidden" formControlName="licence_list_id">
                            <input type="hidden" formControlName="settings_id">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label class="labelInput">{{'lang_licence_name' | language }}</label>
                                <input type="text" class="form-control almnabrformcontrol labelInput" formControlName="licence_name" placeholder="{{'lang_licence_name' | language }}" [readonly]="true">
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_number' | language }}</label>
                                <input type="text" class="form-control almnabrformcontrol" formControlName="licence_number" placeholder="{{'lang_licence_number' | language }}">
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_idate_english' | language }}:</label>
                                <div class="positionrelatiove">
                                    <input type="text" class="form-control almnabrformcontrol" [matDatepicker]="licisdatee" formControlName="licence_issue_date_english" placeholder="YYYY/MM/DD" [readonly]="true" (dateChange)="ds.setENTOARDate($event, licencesList.controls[i].get('licence_issue_date_arabic'))">
                                    <mat-datepicker-toggle matSuffix [for]="licisdatee" class="posiab"></mat-datepicker-toggle>
                                    <mat-datepicker #licisdatee disabled="false"></mat-datepicker>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_idate_arabic' | language }}:</label>
                                <input type="text" class="form-control almnabrformcontrol" ngbDatepicker (click)="iiiiixp.toggle()" #iiiiixp="ngbDatepicker" (ngModelChange)="ds.setARTOENDate($event, licencesList.controls[i].get('licence_issue_date_english'))" formControlName="licence_issue_date_arabic" placeholder="YYYY/MM/DD" [readonly]="true">
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_edate_english' | language }}:</label>
                                <div class="positionrelatiove">
                                    <mat-datepicker-toggle matSuffix [for]="licisdateeee" class="posiab"></mat-datepicker-toggle>
                                    <input type="text" class="form-control almnabrformcontrol" [matDatepicker]="licisdateeee" (dateChange)="ds.setENTOARDate($event, licencesList.controls[i].get('licence_expiry_date_arabic'))" formControlName="licence_expiry_date_english" placeholder="YYYY/MM/DD" [readonly]="true">
                                    <mat-datepicker #licisdateeee disabled="false"></mat-datepicker>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <label>{{'lang_licence_edate_arabic' | language }}:</label>
                                <input type="text" class="form-control almnabrformcontrol" ngbDatepicker (click)="eeeeexp.toggle()" #eeeeexp="ngbDatepicker" [readonly]="true" (ngModelChange)="ds.setARTOENDate($event, licencesList.controls[i].get('licence_expiry_date_english'))" formControlName="licence_expiry_date_arabic" placeholder="YYYY/MM/DD" [readonly]="true">
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="card-footer padb0">
                <div class="row">
                    <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
                        <button type="button" class="albutton" matStepperPrevious>{{'lang_previous' | language }}</button>
                    </div>
                    <div class="col-md-8 col-sm-6 col-xs-12 mb-10"></div>
                    <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
                        <button type="button" class="albutton" matStepperNext (click)="rstep3Submitted()">{{'lang_next' | language }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>