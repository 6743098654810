import { trigger, transition, query, style, stagger, animate, state } from '@angular/animations';
import { Component, HostBinding, OnInit } from '@angular/core';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
@Component({
  selector: 'app-communcation-lists',
  templateUrl: './communcation-lists.component.html',
  styleUrls: ['./communcation-lists.component.scss'],
  animations: [
    trigger("fadeIn", [
      state(
        "void",
        style({
          opacity: 0
        })
      ),
      transition("void <=> *", animate(1000))
    ]),
  ]
})
export class CommuncationListsComponent implements OnInit {
  searchKey:string = "";
  modules: any;
  pageno: number = 1;
  pagesize: number = 10;
  admin:any = false;
  moduleItems = [];
  formItems = [];
  branchItems = [];
  typeItems = [];
  settings = environment.multipledropdown;
  searchByForm:string = "";
  listData: any = [];
  nodata: string = this.lang.transform('lang_loading');
  modulelist: any = [];
  formlist: any = [];
  branchlists:any = [];
  commtypes:any= [];
  constructor(public ds:DataService, public spinner : PreloaderService, public lang:LanguagePipe, public ls:LocalStorage, public alert : AlertService) { }
  ngOnInit() {
    this.admin = JSON.parse(this.ls.getItem('is_admin'));
    this.spinner.show();
    this.module_lists();
    this.form_lists();
    this.comm_branches();
    this.comm_types();
    this.get_form_lists();
  }
  public module_lists() {
    this.modulelist = [];
    this.ds.getActionByUrl([], 'comm/sml').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.modulelist = res.records;
      }
    })
  }
  public form_lists() {
    this.formlist = [];
    this.ds.getActionByUrl([], 'comm/sfl').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.formlist = res.records;
        
      }
    })
  }
  public comm_branches() {
    this.branchlists = [];
    this.ds.getActionByUrl([], 'comm/sbl').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.branchlists = res.records;
      }
    })
  }
  public comm_types() {
    this.commtypes = [];
    this.ds.getActionByUrl([], 'comm/sctl').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.commtypes = res.records;
        
      }
    })
  }
  public search_transactions() {
    this.pageno = 1;
    this.pagesize = 10;
    this.get_form_lists();
  }
  public loadPageFrm(page) {
    this.spinner.show();
    this.pageno = page;
    this.pagesize = this.pagesize;
    this.get_form_lists();
  }
  public loadFrmPagesize(size) {
    this.spinner.show();
    this.pageno = 1;
    this.pagesize = size;
    this.get_form_lists();
  }
  selectItems(item) {
    this.spinner.show();
    this.searchByForm = item.value;
    this.pageno = 1;
    this.pagesize = 10;
    this.get_form_lists();
  }
  deSelectedItems() {
    this.spinner.show();
    this.searchByForm = "";
    this.pageno = 1;
    this.pagesize = 10;
    this.get_form_lists();
  }
  selectall(type) {
    if(type == 'modules'){
      this.moduleItems = this.modulelist.map(item => item.value);
    } else if(type == 'forms'){
      this.formItems = this.formlist.map(item => item.value);
    } else if(type == 'branches'){
      this.branchItems = this.branchlists.map(item => item.value);
    } else if(type == 'types'){
      this.typeItems = this.commtypes.map(item => item.value);
    }
    this.get_form_lists();
  }
  public get_form_lists() {
    let formdata = new FormData();
    formdata.append('searchKey', this.searchKey);
    formdata.append('module_key', this.moduleItems.toString());
    formdata.append('transaction_key', this.formItems.toString());
    formdata.append('branch_id', this.branchItems.toString());
    formdata.append('communication_types_id', this.typeItems.toString());
    this.ds.getActionByUrl(this.ds.formData2string(formdata), 'comm/list/' + this.pageno + '/' + this.pagesize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.listData = res;
      } else {
        this.listData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.listData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
}
