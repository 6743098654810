<mat-tab-group>
	<mat-tab label="setting">
		<ng-template mat-tab-label>Setting</ng-template>
		<div class="p-2">
            <div class="row">
                <div class="col-12 col-md-6 mb-2 text-center">
                    <h4>{{"lang_form_code_work_level" | language}}</h4>
                    <button mat-button style="background-color: rgb(113 166 223 / 42%);width: 100%;margin-top: 10px;" (click)="downloadExcel()">
                        <i class="fa fa-download" style="font-size: 20px; margin: 0 5px; color: rgb(19, 65, 146)"></i>
                        <span style="text-transform: uppercase;font-weight: 600;color: rgb(19, 65, 146);font-size: 12px;">
                            {{ "lang_download_excel" | language }}
                        </span>
                    </button>
                    <input
                        type="file"
                        #uploadExcelFile
                        id="uploadExcelInput"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        hidden
                        (change)="uploadExcel($event.target.files)"
                    />
                    <button mat-button style="background-color: rgb(136 224 136 / 42%);width: 100%;margin-top: 10px;" (click)="uploadExcelFile.click()">
                        <i class="fa fa-upload" style="font-size: 20px; margin: 0 5px; color: rgb(19, 146, 19)"></i>
                        <span style="text-transform: uppercase;font-weight: 600;color: rgb(19, 146, 19);font-size: 12px;">
                            {{ "lang_upload_excel" | language }}
                        </span>
                    </button>
                    <button mat-button style="background-color: rgb(224 136 200 / 42%);width: 100%;margin-top: 10px;" >
                        <i class="fa fa-download" style="font-size: 20px; margin: 0 5px; color: rgb(146, 19, 102)"></i>
                        <span style="text-transform: uppercase;font-weight: 600;color: rgb(146, 19, 102);font-size: 12px;">
                            {{ "lang_history" | language }}
                        </span>
                    </button>
                </div>
                <div class="col-12 col-md-6 mb-2 text-center">
                    <h4>{{"lang_units" | language}}</h4>
                    <button mat-button style="background-color: rgb(113 166 223 / 42%);width: 100%;margin-top: 10px;" (click)="downloadExcel()">
                        <i class="fa fa-download" style="font-size: 20px; margin: 0 5px; color: rgb(19, 65, 146)"></i>
                        <span style="text-transform: uppercase;font-weight: 600;color: rgb(19, 65, 146);font-size: 12px;">
                            {{ "lang_download_excel" | language }}
                        </span>
                    </button>
                    <input
                        type="file"
                        #uploadExcelFile
                        id="uploadExcelInput"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        hidden
                        (change)="uploadExcel($event.target.files)"
                    />
                    <button mat-button style="background-color: rgb(136 224 136 / 42%);width: 100%;margin-top: 10px;" (click)="uploadExcelFile.click()">
                        <i class="fa fa-upload" style="font-size: 20px; margin: 0 5px; color: rgb(19, 146, 19)"></i>
                        <span style="text-transform: uppercase;font-weight: 600;color: rgb(19, 146, 19);font-size: 12px;">
                            {{ "lang_upload_excel" | language }}
                        </span>
                    </button>
                    <button mat-button style="background-color: rgb(224 136 200 / 42%);width: 100%;margin-top: 10px;" >
                        <i class="fa fa-download" style="font-size: 20px; margin: 0 5px; color: rgb(146, 19, 102)"></i>
                        <span style="text-transform: uppercase;font-weight: 600;color: rgb(146, 19, 102);font-size: 12px;">
                            {{ "lang_history" | language }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
	</mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>tree view</ng-template>
        <div class="p-2">
            <div class="row">
                <div class="col-12 col-md-3" *ngIf="!hideTreeMenu">
                    <div>
                        <div class="tree-title">
                            <span>Tree Menu</span>
                            <i class="fa fa-close" (click)="hideTreeMenu = true"></i>
                        </div>
                        <div class="tree-body">
                            <div class="mb-2">
                                <input mat-input type="text" class="form-control" placeholder="Filter" [(ngModel)]="filterText" (keyup)="filterTree($event)">
                            </div>
                            <div class="contractor-tree">
                                <tree-root
                                #tree
                                [options]="options"
                                [nodes]="nodes"
                                (toggleExpanded)="onToggleTree($event)"
                                (activate)="toggleViewAction(tree)"
                                (deactivate)="deactivate($event)"
                            ></tree-root>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=""[ngClass]="{
                    'col-12 col-md-9': !hideTreeMenu,
                    'col-12 col-md-12': hideTreeMenu
                }" *ngIf="!hideTreeContent">
                    <div style="display: flex; align-items: center;">
                        <span class="show-tree-menu" *ngIf="hideTreeMenu">
                            <i class="fa fa-expand" (click)="onHideTreeMenu()"></i>
                        </span>
                        <p innerHTML="{{treePath}}" *ngIf="treePath" class="tree-path"></p>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
