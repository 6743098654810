import { trigger, state, style, transition, animate } from '@angular/animations';
import { AfterViewInit, Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
import * as echarts from 'echarts';
import { findFlagUrlByIso2Code,findFlagUrlByIso3Code } from "country-flags-svg";

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
  animations: [
    trigger("fadeIn", [
      state(
        "void",
        style({
          opacity: 0
        })
      ),
      transition("void <=> *", animate(1000))
    ]),
  ]
})
export class EmployeesComponent implements OnInit, AfterViewInit {
  modalRefC: BsModalRef;
  signform: FormGroup;
  liststatus: boolean     = false;
  errormessagef           = this.lang.transform('lang_loading');
  myemployees: any        = [];
  signchecked: any        = false;
  branches: any           = [];
  lltotal: any            = "";
  lltotalpage: any        = "";
  wholddata: any          = [];  
  signfilename            = this.lang.transform('lang_select_files');
  markfilename            = this.lang.transform('lang_select_files');
  signform_status:any     = false;
  signerror: string       = "";
  is_admin                = JSON.parse(this.ls.getItem('is_admin'));
  
  page:any                = localStorage.getItem('M_H_R_N')   || 1;
	size:any                = localStorage.getItem('M_H_R_S')   || 10;
  searchByBranch          = localStorage.getItem('M_H_R_B')   || "";
  searchKey               = localStorage.getItem('M_H_R_S_K') || "";
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.loadbranches();
    this.loadmyemployees();
    this.signatureform();
    /* this.loadReports(); */
    this.get_reports()
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.initFormsChart()
    window.onresize = () => {
      this.formsChart.resize();
     
      
    }
  }

  formsChart:any;
  formsChartEmployees:any;
  option2:echarts.EChartsOption = {}
  optionnEmp: echarts.EChartsOption;
  myChartEmp:any;
  chartDomEmpJops:any;
  optionnEmpJops: echarts.EChartsOption;
  myChartEmpJops:any
  initFormsChart() {



  }


  updateFormsChart() {
     
    const option = {
      legend: {
        top: 'bottom'
      },
      toolbox: {
        show: true,
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },
      series: [
        {
          name: 'Nightingale Chart',
          type: 'pie',
          radius: [50, 250],
          center: ['50%', '50%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 8
          },
          data: [
            { value: 40, name: 'EG - 10% - 40' },
            { value: 38, name: 'USA - 10% - 38' },
            { value: 32, name: 'SU - 10% - 32' },
            { value: 30, name: 'IN - 10% - 30' },
            { value: 28, name: 'UK - 10% - 28' }
          ]
        }
      ]
    };
    this.formsChart.setOption(option);
    this.formsChart.hideLoading();
  }


  public openModelC(template: TemplateRef<any>) {
    this.modalRefC = this.modalService.show(template, environment.modelconfig);
  }
  public loadbranches() {
    this.branches = [];
    this.ds.getActionByUrl([], 'human_resources/branches/human_resources_view').subscribe(data => {
      if (data.status) {
        this.branches = data.branches;
      }
    });
  }


  public get_reports() {
 
    var chartDom = document.getElementById('forms-chart-empoyees')!;
    var myChart = echarts.init(chartDom);
 


    
    
    var chartDomEmp = document.getElementById('forms-jops')!;
    this.myChartEmp = echarts.init(chartDomEmp);
     
    
    
    
    
    this.chartDomEmpJops = document.getElementById('forms-jops-empoyees')!;
    this.myChartEmpJops = echarts.init(this.chartDomEmpJops);


    this.myChartEmpJops.showLoading();
    this.myChartEmp.showLoading();
    myChart.showLoading();

        //forms chart
        this.formsChart = echarts.init(document.getElementById('forms-chart2'));
        this.formsChart.showLoading();
      /*   this.updateFormsChart() */
        const option = {
          legend: {
            top: 'bottom',
            
          },
          tooltip: {
            trigger: 'item',
            axisPointer: {
              type: 'shadow'
            },
            formatter:  (params) => {
            
              
              return `<img width="25px" src="${findFlagUrlByIso2Code(params.name)}" alt="${params.name}"> - ${params.name}: ${params?.value} `
            },
          },
    
    
    
    
          toolbox: {
            show: true,
            feature: {
              mark: { show: false },
              dataView: { show: false, readOnly: false },
              restore: { show: false },
              saveAsImage: { show: true }
            }
          },
          series: [
            {
              
      
              name: 'Nightingale Chart',
              type: 'pie',
              radius: [50, 250],
              center: ['50%', '50%'],
              roseType: 'area',
              itemStyle: {
                borderRadius: 8
              },
              data: [
                { value: 40, name: 'EG' },
                { value: 38, name: 'US' },
                { value: 32, name: 'FR' },
                { value: 30, name: 'IN' },
                { value: 28, name: 'GB' }
              ]
            }
          ]
        };
        this.formsChart.setOption(option);
        this.formsChart.hideLoading();
     
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    var chartDomContractsThree = document.getElementById('forms-contracts-three')!;
    var myChartContractsThree = echarts.init(chartDomContractsThree);
    var optionnContractsThree: echarts.EChartsOption;
  
    
    
    var chartDomContractsOne = document.getElementById('forms-contracts-one')!;
    var myChartContractsOne = echarts.init(chartDomContractsOne);
    var optionnContractsOne: echarts.EChartsOption;
  
    
    
    

    this.ds.postActionByUrl([], 'human_resources/get_reports').subscribe(data => {
      if (data.status) {
     
      }
      console.log(data);



      const option = {
        legend: {
          top: 'bottom',
          
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'shadow'
          },
          formatter:  (params) => {
          
            
            return `<img width="25px" src="${findFlagUrlByIso2Code(params.name)}" alt="${params.name}"> - ${params.name}: ${params?.value} `
          },
        },
  
  
  
  
        toolbox: {
          show: true,
          feature: {
            mark: { show: false },
            dataView: { show: false, readOnly: false },
            restore: { show: false },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            
    
            name: 'Nightingale Chart',
            type: 'pie',
            radius: [50, 250],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: data.chart_1
          }
        ]
      };
      this.formsChart.setOption(option);








      // Chart 2



      



      var optionn: echarts.EChartsOption;
      let dataAxis = data.chart_2.labels;
      // prettier-ignore
      

      let yMax = 500;
      let dataShadow = [];

      for (let i = 0; i < data.length; i++) {
        dataShadow.push(yMax);
      }

      optionn = {
        /* title: {
          text: '特性示例：渐变色 阴影 点击缩放',
          subtext: 'Feature Sample: Gradient Color, Shadow, Click Zoom'
        }, */
        xAxis: {
          data: data.chart_2.labels,
          axisLabel: {
            inside: true,
            color: '#fff'
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          z: 10
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'shadow'
          },
          formatter:  (params) => {
          
            
            return `<img width="25px" src="${findFlagUrlByIso2Code(params.name)}" alt="${params.name}"> - ${params.name}: ${params?.value} `
          },
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#999'
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'bar',
            showBackground: true,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' }
                ])
              }
            },
            data: data.chart_2.values
          }
        ]
      };

      // Enable data zoom when user click bar.
      const zoomSize = 6;
      myChart.on('click', function (params) {
        console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
        myChart.dispatchAction({
          type: 'dataZoom',
          startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
          endValue:
            dataAxis[Math.min(params.dataIndex + zoomSize / 2, data.length - 1)]
        });
      });

      optionn && myChart.setOption(optionn);
      myChart.hideLoading();



      // Chart 3 



      this.optionnEmp = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
           /*  name: 'Access From', */
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'normal'
              }
            },
            labelLine: {
              show: false
            },
            data: data.chart_3
          }
        ]
      };

      this.myChartEmp.setOption(this.optionnEmp);
      this.myChartEmp.hideLoading();
      // Chart 4




      this.optionnEmpJops = {
        dataset: [
          {
            dimensions: ['name', 'score'],
            source: data.chart_4.values
          },
          {
            transform: {
              type: 'sort',
              config: { dimension: 'score', order: 'desc' }
            }
          }
        ],
        xAxis: {
          type: 'category',
          axisLabel: { interval: 0, rotate: 30 }
        },
        yAxis: {},
        series: {
          type: 'bar',
          encode: { x: 'name', y: 'score' },
          datasetIndex: 1
        }
      };

      this.myChartEmpJops.setOption(this.optionnEmpJops)

      this.myChartEmpJops.hideLoading();







      // Chart 5



      optionnContractsThree = {
        title: {
          text: this.lang.transform('lang_contract_three_mon')
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: data.chart_4.labels
        },
        series: [
         /*  {
            name: '2011',
            type: 'bar',
            data: [18203, 23489, 29034, 104970, 131744, 630230]
          }, */
          {
            name: '2012',
            type: 'bar',
            data: data.chart_4.values
          }
        ]
        
      };
      myChartContractsThree.setOption(optionnContractsThree)




      // Chart 6 


      optionnContractsOne = {
        title: {
          text:  this.lang.transform('lang_contract_one_mon')
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: data.chart_4.labels
        },
        series: [
         /*  {
            name: '2011',
            type: 'bar',
            data: [18203, 23489, 29034, 104970, 131744, 630230]
          }, */
          {
            name: '2012',
            type: 'bar',
            data: data.chart_4.values
          }
        ]
      };
      myChartContractsOne.setOption(optionnContractsOne)
    });
  }

  /* nationalities_report:any = ''
  public loadReports() {
     let formData = new FormData()
     formData.append('nationality_type','saudi')
    this.ds.post('human_resources/nationalities_report',formData).subscribe(data => {
      if (data.status) {
        this.nationalities_report = data.data;
      }
    });
  } */
  public search_employees() {
    this.size = 10;
    this.page = 1;
    this.loadmyemployees();
  }
  public loadbypagesize(size) {
    this.spinner.show();
    this.size = size;
    this.page = 1;
    this.loadmyemployees();
  }
  public loadbypagelength(page) {
    this.spinner.show();
    this.page = page;
    this.loadmyemployees();
  }
  public loadmyemployees() {
    localStorage.setItem('M_H_R_N', this.page);
		localStorage.setItem('M_H_R_S', this.size);
    localStorage.setItem('M_H_R_B', this.searchByBranch || '');
    localStorage.setItem('M_H_R_S_K', this.searchKey || '');
    const param = new FormData();
    param.append("branch_id", this.searchByBranch || '');
    param.append("searchKey", this.searchKey || '');
    this.ds.postActionByUrl(param, "human_resources/get_my_employees/" +this.page+'/'+this.size).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.wholddata = data;
      } else {
        this.wholddata = [];
        this.errormessagef = data.error;
      }
    }, error => {
        this.spinner.hide();
        this.wholddata = [];
        this.errormessagef = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public performStatus(status, id, branch_id) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show()
        this.ds.getActionByUrl([], 'human_resources/change_status/'+id+'/'+branch_id+'/'+status).subscribe(data => {
            this.spinner.hide()
            if (data.status) {
              this.loadmyemployees();
              this.ds.dialogf('', data.msg);
            } else {
              this.ds.dialogf('', data.error);
            }
          },
          (error) => {
            this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
            this.spinner.hide()
          }
        );
      }
    });
  }
  public signaturefor(id, branch_id) {
    this.signform.reset();
    this.signfilename = this.lang.transform('lang_select_files');
    this.markfilename = this.lang.transform('lang_select_files');
    this.signform.patchValue({
      id: id,
      branch_id: branch_id
    })
  }
  public signatureform() {
    this.signform = new FormGroup({
      id: new FormControl("", [Validators.required]),
      branch_id: new FormControl("", [Validators.required]),
      signature: new FormControl("", [Validators.required]),
      mark: new FormControl("", [Validators.required]),
    });
  }
  public uploadfile(event, sform, type) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      if (type == 'mark') {
        this.markfilename = fileid.name;
      } else {
        this.signfilename = fileid.name;
      }
      sform.setValue(fileid, { emitEvent: true });
    } else {
      sform.setValue(null);
      if (type == 'mark') {
        this.markfilename = this.lang.transform('lang_select_files');
      } else {
        this.signfilename = this.lang.transform('lang_select_files');
      }
    }
  }
  public upload_signature() {
    this.spinner.show();
    this.ds.postActionByUrl(this.ds.json2formdata(this.signform.value), 'upload_signature').subscribe(res => {
      this.spinner.hide();
      this.signform_status = res.status;
      if (res.status) {
        this.loadmyemployees();
        this.alert.success(res.msg);
        this.signform.reset();
        setTimeout(() => {
          this.modalRefC.hide();
        }, 1000);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
        this.spinner.hide();
      this.signform_status = false;
      this.alert.error(error.error + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public remove_signature(user_id) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show()
        this.ds.getActionByUrl([], "remove_signature/" + user_id).subscribe(data => {
            this.spinner.hide()
            if (data.status) {
              this.loadmyemployees();
              this.ds.dialogf('', data.msg);
            } else {
              this.ds.dialogf('', data.error);
            }
          },
          (error) => {
            this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
            this.spinner.hide()
          }
        );
      }
    });
  }
  public send_signup_link(emp): void {
    this.spinner.show();
    let formdata = new FormData();
    formdata.append('branch_id', emp.branch_id);
    formdata.append('id', emp.employee_number);
    formdata.append('employee_id_number', emp.employee_id_number);
    this.ds.postActionByUrl(formdata, "send_signup_link").subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.loadmyemployees();
        this.ds.dialogf('',res.msg);
      } else {
        this.ds.dialogf('',res.error);
      }
    }, error => {
        this.spinner.hide();
        this.ds.dialogf('',error.error + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public checkAllHR(event) {
    if (event.target.checked) {
        $(".all_employees .eachItemHR").prop("checked", true);
        $(".all_employees .makeback").addClass("bground");
        $(".all_employees .deleteIconDiv").show();
    } else {
        $(".all_employees .eachItemHR").prop("checked", false);
        $(".all_employees .makeback").removeClass("bground");
        $(".all_employees .deleteIconDiv").hide();
    }
  }
  public checkEachItemHR(event) {
      if (event.target.checked) {
          const val = event.target.value;
          $(".all_employees .removerow" + val).addClass("bground");
      } else {
          const val = event.target.value;
          $(".all_employees .removerow" + val).removeClass("bground");
      }
      const checkedtotal = [];
      $(".all_employees .eachItemHR:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      const totalrow = [];
      $(".all_employees .eachItemHR").each(function() {
          totalrow.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          $(".all_employees .deleteIconDiv").show();
      } else {
          $(".all_employees .deleteIconDiv").hide();
      }
      if (checkedtotal.length === totalrow.length) {
          $(".all_employees #allItemsHR").prop("checked", true);
      } else {
          $(".all_employees #allItemsHR").prop("checked", false);
      }
  }
  public deleteBulkDataHR() {
      const checkedtotal = [];
      $(".all_employees .eachItemHR:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          const dialogRef = this.dialog.open(ConfirmationDialog, {
              disableClose: true,
              width: '400px',
              data: {
                  title: this.lang.transform('lang_confirmation'),
                  message: this.lang.transform('lang_are_you_sure'),
              }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                  this.spinner.show()
                  this.ds.deleteActionByUrl(checkedtotal, "81f5cc8c046c6d1c66fa3424783873db/MAIN").subscribe(
                      (data) => {
                          this.spinner.hide()
                          if (data.status) {
                              this.search_employees();
                              $(".all_employees #allItemsHR").prop("checked", false);
                              $(".all_employees .deleteIconDiv").hide();
                              this.ds.dialogf('', data.msg);
                          } else {
                              this.ds.dialogf('', data.error);
                          }
                      },
                      (error) => {
                          this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                          this.spinner.hide()
                      }
                  );
              }
          });
      } else {
          this.ds.dialogf();
      }
  }
  public showsignature(empid, type, user_id) {
    this.spinner.show();
    this.ds.getActionByUrl([], 'viewsmark/'+empid+type+user_id).subscribe(data => {
      this.spinner.hide();
      if (data.status && data.base64) {
        ($('#showSMPreview') as any).modal({ backdrop: 'static', keyboard: false });
        ($('#showSMPreview') as any).modal('show');
        $("#SMnabrPdf").attr('src', 'data:' + data['content-type'] + ';base64,' + data.base64);
      } else {
        this.ds.dialogf('', data.error);
      }
    }, error => {
      this.spinner.hide();
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
}