<breadcrumb></breadcrumb>
<div class="themesection">
    <section class="panel-expansion matborderno">
        <mat-accordion class="accounts_masters" >
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_transaction_listing' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body">
                    <div class="mbrl15">
                        <form class="form" [formGroup]="form" (ngSubmit)="fetch_statements()">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                    <select formControlName="branch_id" class="form-control almnabrformcontrol" matTooltip="{{'lang_branch' | language }}" (change)="spinner.show();selectBrach($event.target.value);">
                                        <option value="0">{{'lang_choose_options' | language }}</option>
                                        <option *ngFor="let branch of branches" value="{{branch.id}}">{{branch.title}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10" *ngIf="form.get('branch_id').value && form.get('branch_id').value != '0'">
                                    <ng-select 
                                            [items]="finances" 
                                            [multiple]="false" 
                                            bindLabel="label" 
                                            bindValue="value" 
                                            appendTo="body" 
                                            clearAllText="Clear"
                                            formControlName="finance_id"
                                            placeholder="{{'lang_financial_years' | language }}"
                                            (change) = "ds.setDate($event, form);"
                                        >
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_account_from' | language }} : </label><br>
                                        <ng-select 
                                            [items]="fromaccounts" 
                                            [multiple]="false" 
                                            bindLabel="label" 
                                            bindValue="value" 
                                            appendTo="body" 
                                            clearAllText="Clear"
                                            formControlName="account_code_from"
                                            placeholder="{{'lang_account_from' | language }}"
                                        >
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_account_to' | language }} : </label><br>
                                        <ng-select 
                                            [items]="fromaccounts" 
                                            [multiple]="false" 
                                            bindLabel="label" 
                                            bindValue="value" 
                                            appendTo="body" 
                                            clearAllText="Clear"
                                            formControlName="account_code_to"
                                            placeholder="{{'lang_account_to' | language }}"
                                        >
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_period_from' | language }} : <span class="red">*</span> </label><br>
                                        <input formControlName="period_from" id="period_from" class="form-control almnabrformcontrol period_from" placeholder="YYYY/MM/DD" />
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_period_to' | language }} :<span class="red">*</span> </label><br>
                                        <input formControlName="period_to" id="period_to" class="form-control almnabrformcontrol period_to" placeholder="YYYY/MM/DD" />
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_transaction_type' | language }} :</label><br>
                                        <select formControlName="transaction_listing" class="form-control almnabrformcontrol transaction_listing">
                                            <option value="">{{'lang_all' | language }}</option>
                                            <option value="REC">{{'lang_receipts' | language }}</option>
                                            <option value="PAY">{{'lang_payments' | language }}</option>
                                            <option value="JRN">{{'lang_journal_voucher' | language }}</option>
                                            <option value="SIV">{{'lang_selling_invoices' | language }}</option>
                                            <option value="PIV">{{'lang_purchase_invoice' | language }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="form.get('finance_id').value && form.get('branch_id').value && form.get('branch_id').value != '0'">
                                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                                    <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                                </div>
                            </div>
                        </form>
                        <div class="row" *ngIf="datastatus && form.get('branch_id').value && form.get('branch_id').value != '0'">
                            <div class="col-md-12 TablePart">
                                <div class="table-responsive dropdownyes customResponsive tablefields">
                                    <table border="1" class="table CustomTable">
                                        <thead>
                                            <tr>
                                                <td>#</td>
                                                <td>{{'lang_transaction_number' | language }}</td>
                                                <td>{{'lang_reference_no' | language }}</td>
                                                <td>{{'lang_transaction_type' | language }}</td>
                                                <td>{{'lang_transaction_date' | language }}</td>
                                                <td>{{'lang_accounts_acccode' | language }}</td>
                                                <td>{{'lang_account' | language }}</td>
                                                <td>{{'lang_debit' | language }}</td>
                                                <td>{{'lang_credit' | language }}</td>
                                                <td>{{'lang_description' | language }}</td>
                                                <td>{{'lang_action' | language }}</td>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="data.length != '0'" class="AppendList">
                                            <tr *ngFor="let tran of data; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }">
                                                <td>{{i+1}}</td>
                                                <td>{{tran.transaction_id}}</td>
                                                <td>
                                                    <a href="javascript:void(0)" (click)="ds.openTransaction(tran.transaction_request_type, tran.branch_id,tran.request_id);" matTooltip="{{'lang_view' | language}}">{{tran.reference_number}}</a>
                                                </td>
                                                <td *ngIf="tran.transaction_request_type == 'REC' ">{{'lang_receipts' | language }}</td>
                                                <td *ngIf="tran.transaction_request_type == 'PAY' ">{{'lang_payments' | language }}</td>
                                                <td *ngIf="tran.transaction_request_type == 'JRN' ">{{'lang_journal_voucher' | language }}</td>
                                                <td *ngIf="tran.transaction_request_type == 'SIV' ">{{'lang_selling_invoices' | language }}</td>
                                                <td *ngIf="tran.transaction_request_type == 'PIV' ">{{'lang_purchase_invoice' | language }}</td>
                                                <td>{{tran.transaction_history_date}}</td>
                                                <td>{{tran.account_code}}</td>
                                                <td>{{tran.account_name}}</td>
                                                <td>{{ds.addCommas(tran.debit_amount)}}</td>                             
                                                <td>{{ds.addCommas(tran.credit_amount)}}</td>
                                                <td>{{tran.transaction_history_description}}</td>
                                                <td>
                                                    <i class="fa fa-eye mnabricon s2icon" (click)="ds.openTransaction(tran.transaction_request_type, tran.branch_id,tran.request_id);" matTooltip="{{'lang_view' | language}}"></i>
                                                    <a target="_blank" *ngIf="tran.transaction_allow == '0'" class="fa fa-exchange mnabricon s2icon" routerLink="/accounts/statement_of_accounts/{{tran.account_cost_id}}/" matTooltip="{{'lang_view_transactions' | language}}"></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="data.length == '0' " class="AppendList">
                                            <tr class="odd">
                                                <td colspan="11" align="center">{{lodingdatas}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-between p-2" *ngIf="datastatus">
                                        <ngb-pagination [collectionSize]="collectionSize" [rotate]="true" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshLists()"></ngb-pagination>
                                        <label>{{'lang_total' | language }} : {{collectionSize}}</label>
                                        <select class="customselect" [(ngModel)]="pageSize" (change)="refreshLists()">
                                            <option [ngValue]="10">10</option>
                                            <option [ngValue]="20">20</option>
                                            <option [ngValue]="50">50</option>
                                            <option [ngValue]="100">100</option>
                                            <option [ngValue]="250">250</option>
                                            <option [ngValue]="500">500</option>
                                            <option [ngValue]="1000">1000</option>
                                            <option [ngValue]="2000">2000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</div>