<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno">
    <!-- <mat-accordion class="all_employees" >
        <mat-expansion-panel class="mpanel" [expanded]="true">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_human_resources_employee_details' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-3 col-sm-4 col-xs-12 mb10">
                            <div class="form-group">
                                <select (change)="spinner.show();search_employees();" class="form-control almnabrformcontrol" [(ngModel)]="searchByBranch" matTooltip="{{'lang_branch' | language }}">
                                    <option value="">{{'lang_all' | language }}</option>
                                    <option *ngFor="let branch of branches" value="{{branch.value}}"> {{branch.label}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-9 col-sm-8 col-xs-12 mb10">
                            <div class="form-group">
                                <input type="text" class="form-control almnabrformcontrol" (keyup)="search_employees();" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsHR checkbox" id="allItemsHR" (click)="checkAllHR($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_human_resources_employee_name' | language }}</td>
                                            <td>{{'lang_human_resources_id_number' | language }}</td>
                                            <td>{{'lang_human_resources_number' | language }}</td>
                                            <td>{{'lang_status' | language }} <i class="fa fa-info-circle red" matTooltip="{{'lang_hrchange_status' | language}}" aria-hidden="true"> </i></td>
                                            <td>{{'lang_writer' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="wholddata.length != '0'" class="AppendList">
                                        <tr *ngFor="let emp of wholddata.records; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="status{{emp.employee_status}} makeback removerow{{emp.employee_number}}" [attr.note_alert]="emp.note_alert">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemHR checkbox" value="{{emp.employee_number}}" (click)="checkEachItemHR($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td><a matTooltip="{{'lang_view' | language }}" routerLink="/human_resources/view/{{emp.employee_number}}/view/{{emp.branch_id}}">{{emp.employee_name}}</a>
                                            </td>
                                            <td><a matTooltip="{{'lang_view' | language }}" routerLink="/human_resources/view/{{emp.employee_number}}/view/{{emp.branch_id}}">{{emp.employee_id_number}}</a>
                                            </td>
                                            <td><a matTooltip="{{'lang_view' | language }}" routerLink="/human_resources/view/{{emp.employee_number}}/view/{{emp.branch_id}}">{{emp.employee_number}}</a>
                                            </td>
                                            <td *ngIf="emp.employee_status == '0'">
                                                <i class="fa fa-icon fa-circle red" (click)="performStatus(1,emp.employee_number, emp.branch_id)" matTooltip="{{'lang_inactive' | language }}" ></i>
                                            </td>
                                            <td *ngIf="emp.employee_status == '1'">
                                                <i class="fa fa-icon fa-circle green" (click)="performStatus(0,emp.employee_number, emp.branch_id)" matTooltip="{{'lang_active' | language }}" ></i>
                                            </td>
                                            <td *ngIf="!emp.name">{{emp.username}}</td>
                                            <td *ngIf="emp.name">{{emp.name}}</td>                                           
                                            <td class="dropdown">
                                                <mat-icon class="mnabricon" [matMenuTriggerFor]="menu">settings</mat-icon>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item routerLink="/human_resources/view/{{emp.employee_number}}/view/{{emp.branch_id}}"><span>{{'lang_view' | language }}</span></button>
                                                    <button mat-menu-item *ngIf="ds.editMe(wholddata.edit,emp.employee_number,emp.branch_id) && emp.employee_status == '1'" routerLink="/human_resources/edit/{{emp.employee_number}}/edit/{{emp.branch_id}}"><span>{{'lang_edit' | language }}</span></button>
                                                    <button mat-menu-item *ngIf="emp.user_id && wholddata.is_admin && emp.signature !='0'" (click)="showsignature(emp.employee_id_number,'S',emp.user_id)"><span>{{'lang_view_signature' | language }}</span></button>
                                                    <button mat-menu-item *ngIf="emp.user_id && wholddata.is_admin && emp.mark !='0'" (click)="showsignature(emp.employee_id_number,'M',emp.user_id)"><span>{{'lang_view_mark' | language }}</span></button>
                                                    <button mat-menu-item *ngIf="emp.user_id && wholddata.is_admin" (click)="openModelC(signatureform);signaturefor(emp.employee_number, emp.branch_id);"><span>{{'lang_upload_signature' | language }}</span></button>
                                                    <button mat-menu-item *ngIf="(emp.signature!='0' || emp.mark !='0') && wholddata.is_admin" (click)="remove_signature(emp.user_id);"><span>{{'lang_remove_signature' | language }}</span></button>
                                                    <button mat-menu-item *ngIf="!emp.user_id" (click)="send_signup_link(emp)"><span>{{'lang_send_signup_link' | language }}</span></button>
                                                </mat-menu>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="wholddata.length == '0'" class="AppendList">
                                        <tr class="odd">
                                            <td colspan="8" align="center">{{errormessagef}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="wholddata.length != '0' " class="card-footer padb0">
                    <div class="row">
                        <div *ngIf="ds.allowMe('human_resources', 'human_resources_delete')" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataHR()"  matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-6 mb-10">
                            {{'lang_total' | language }} : {{wholddata.page.total_records}}
                        </div>
                        <div class="col-lg-5 col-sm-6 mb-10">
                            <mat-radio-group>
                                <mat-radio-button value="10" [checked]="(size =='10') ? true : false" (click)="loadbypagesize(10)"> 10 </mat-radio-button>
                                <mat-radio-button value="20" [checked]="(size =='20') ? true : false" (click)="loadbypagesize(20)"> 20 </mat-radio-button>
                                <mat-radio-button value="50" [checked]="(size =='50') ? true : false" (click)="loadbypagesize(50)"> 50 </mat-radio-button>
                                <mat-radio-button value="100" [checked]="(size =='100') ? true : false" (click)="loadbypagesize(100)"> 100 </mat-radio-button>
                                <mat-radio-button value="500" [checked]="(size =='500') ? true : false" (click)="loadbypagesize(500)"> 500 </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            {{'lang_goto' | language }} :
                            <select class="customselect" (change)="loadbypagelength($event.target.value)">
                                <option *ngFor="let ks of ds.getrange(wholddata.page.total_pages)" [selected]="(page == ks) ? true : false" value="{{ks}}">{{ks}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>


    </mat-accordion> -->
  
    <div class="width-100 attendence">
        <div class="col-md-3">
            <button routerLink="/human_resources/attendance" mat-menu-item>
                <mat-icon style="color: rgba(0, 0, 0, 0.54) !important">fingerprint</mat-icon>
                 <span>{{'lang_groups' | language }}</span>	 
            </button>
        </div>
      
    </div>

    <mat-accordion class="all_employees">
        <mat-expansion-panel class="mt-2" [expanded]="false">
            <mat-expansion-panel-header>{{
                "lang_human_resources_nationalitites_reports" | language
                }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15 martb0">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="forms-chart" id="forms-chart2"></div>
                        </div>

                        <div class="col-md-6">
                            <div class="forms-chart" id="forms-chart-empoyees"></div>
                        </div>
                    </div>
                
     
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>


    <mat-accordion class="all_employees">
        <mat-expansion-panel class="mt-2" [expanded]="false">
            <mat-expansion-panel-header>{{
                "lang_human_resources_jops_reports" | language
                }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15 martb0">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="forms-chart" id="forms-jops"></div>
                        </div>

                        <div class="col-md-6">
                            <div class="forms-chart" id="forms-jops-empoyees"></div>
                        </div>
                    </div>
                
     
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="all_employees">
        <mat-expansion-panel class="mt-2" [expanded]="false">
            <mat-expansion-panel-header>{{
                "lang_human_resources_contracts_reports" | language
                }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15 martb0">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="forms-chart" id="forms-contracts-three"></div>
                        </div>

                        <div class="col-md-6">
                            <div class="forms-chart" id="forms-contracts-one"></div>
                        </div>
                    </div>
                
     
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="all_employees">
        <mat-expansion-panel class="mt-2" [expanded]="false">
            <mat-expansion-panel-header>{{
                "lang_human_resources_employee_details" | language
                }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15 martb0">
                  
                
    
                    <hr>
                    <app-AllEmployees [wholddata]="wholddata" [signatureform]="signatureform"></app-AllEmployees>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>




    <mat-accordion class="all_employees">
        <mat-expansion-panel class="mt-2" [expanded]="false">
            <mat-expansion-panel-header>{{
                "lang_human_calender" | language
                }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15 martb0">
                  
                
    
                    <hr>
                    <app-calender></app-calender>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

</section>

<ng-template #signatureform>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_upload_signature' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefC.hide();">&times;</button>
        </div>
        <form class="signform" [formGroup]="signform" (ngSubmit)="upload_signature()">
            <div class="modal-body">
                <input type="hidden" formControlName="id" required />
                <input type="hidden" formControlName="branch_id" required />
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="signature">{{'lang_signature' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <div class="IDDIV">
                                    <input name="signature" type="file" required onclick="this.value = null"
                                        (change)="uploadfile($event,signform.get('signature'),'sign' )"
                                        class="nghide albutton width100" accept=".png">
                                    <label id="uploadIDButton" class="albutton">{{signfilename}} </label>
                                    <mat-error
                                        *ngIf="signform.controls['signature'].touched && signform.controls['signature'].errors?.required">
                                        {{'lang_field_required' | language }}</mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <label for="mark">{{'lang_mark' | language }} : <span class="red">*</span></label>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <div class="IDDIV">
                                    <input name="mark" type="file" required onclick="this.value = null"
                                        (change)="uploadfile($event,signform.get('mark'),'mark')"
                                        class="nghide albutton width100" accept=".png">
                                    <label id="uploadIDButton" class="albutton">{{markfilename}} </label>
                                    <mat-error
                                        *ngIf="signform.controls['mark'].touched && signform.controls['mark'].errors?.required">
                                        {{'lang_field_required' | language }} </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal"
                            (click)="modalRefC.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>