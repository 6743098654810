<div class="table-responsive customResponsive">
	<table border="1" class="table CustomTable">
		<thead>
			<tr>
				<td>#</td>
				<td>{{ "lang_name" | language }}</td>
				<td>{{ "lang_type" | language }}</td>
				<td>{{ "lang_view" | language }}</td>
				<td>{{ "lang_view_time" | language }}</td>
				<td>{{ "lang_last_view_time" | language }}</td>
				<td>{{ "lang_action" | language }}</td>
				<td>{{ "lang_step" | language }}</td>
				<td>{{ "lang_datetime" | language }}</td>
			</tr>
		</thead>
		<tbody *ngIf="persons.length > 0" class="AppendList">
			<tr
				*ngFor="
					let person of persons
						| slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize;
					let i = index;
					let odd = odd;
					let even = even
				"
				[ngClass]="{ odd: odd, even: even }"
			>
				<td>{{ i + 1 }}</td>
				<td>{{ person.first_name }} {{ person.last_name }}</td>
				<td>{{ person.transaction_persons_type }}</td>
				<td *ngIf="person.transactions_persons_view == '1'">
					<i class="fa fa-check faicon successgreen" aria-hidden="true"></i>
				</td>
				<td *ngIf="person.transactions_persons_view == '0'">
					<i class="fa fa-check faicon silver" aria-hidden="true"></i>
				</td>
				<td>{{ person.transactions_persons_view_datetime }}</td>
				<td>
					{{ person.transactions_persons_view_datetime_lastupdate }}
				</td>
				<td *ngIf="person.transactions_persons_action_status == 1">
					<i class="fa fa-check faicon successgreen" aria-hidden="true"></i>
				</td>
				<td *ngIf="person.transactions_persons_action_status == 0">
					<i class="fa fa-check faicon silver" aria-hidden="true"></i>
				</td>
				<td>{{ person.transactions_persons_last_step }}</td>
				<td>{{ person.transactions_persons_action_datetime }}</td>
			</tr>
		</tbody>
		<tbody *ngIf="persons.length < 1" class="AppendList">
			<tr class="odd">
				<td colspan="8" class="nodata" align="center">
					{{ "lang_no_data" | language }}
				</td>
			</tr>
		</tbody>
	</table>
	<div
		class="mt-2"
		style="
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		"
	>
		<ngb-pagination
			*ngIf="persons?.length"
			[collectionSize]="persons?.length"
			[(page)]="page"
			[pageSize]="pageSize"
			[boundaryLinks]="true"
		></ngb-pagination>
	</div>
</div>
