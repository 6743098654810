import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-single-shift',
  templateUrl: './add-single-shift.component.html',
  styleUrls: ['./add-single-shift.component.scss']
})
export class AddSingleShiftComponent implements OnInit {
  modalRefA: BsModalRef;
  form: FormGroup;
  form_request_id:any;
  request: any            = [];
  allData:any;
  lang_key = localStorage.getItem('lang_key') || 'en';
  dayInfo = ''
  
  constructor(public ds:DataService, public ls:LocalStorage, public lang:LanguagePipe,private fb: FormBuilder,
    public spinner : PreloaderService, public alert:AlertService, public router:Router, private dialogRef: MatDialogRef<AddSingleShiftComponent>,
    public dialog : MatDialog, @Inject(MAT_DIALOG_DATA) public data) {
      this.buildform(this.data);
      console.log(this.data);
      this.dayInfo = this.data.date
     /*  this.allData = this.data.allData;
      this.request   = this.data.allData?.view_request? this.data.allData?.view_request?.transactions_request : this.data.allData?.transactions_request ;
    
    */
 
    }

  ngOnInit(): void {
    
  }

  dayOff(e){
    if(e.checked){
      this.form.controls.start.disable()
      this.form.controls.end.disable()
      this.form.controls.shift_time_day.disable()
      this.form.controls.shift_time_date.disable()
  
      this.form.controls.shift_total_hours.disable()
      this.form.controls.shift_break_hours.disable()
      this.form.controls.check_in_start.disable()
      this.form.controls.check_in_end.disable()
      this.form.controls.break_out_start.disable()
      this.form.controls.break_out_end.disable()
      this.form.controls.break_in_start.disable()
      this.form.controls.break_in_end.disable()
      this.form.controls.check_out_start.disable()
      this.form.controls.check_out_end.disable()
      this.form.controls.shift_start_time.disable()
      this.form.controls.shift_end_time.disable()
      this.form.controls.check_out_end.disable()
    }else{
 

      this.form.controls.start.enable()
      this.form.controls.end.enable()
      this.form.controls.shift_time_day.enable()
      this.form.controls.shift_time_date.enable()
 
      this.form.controls.shift_total_hours.enable()
      this.form.controls.shift_break_hours.enable()
      this.form.controls.check_in_start.enable()
      this.form.controls.check_in_end.enable()
      this.form.controls.break_out_start.enable()
      this.form.controls.break_out_end.enable()
      this.form.controls.break_in_start.enable()
      this.form.controls.break_in_end.enable()
      this.form.controls.check_out_start.enable()
      this.form.controls.check_out_end.enable()
      this.form.controls.shift_start_time.enable()
      this.form.controls.shift_end_time.enable()
      this.form.controls.check_out_end.enable()
    }
  }
  allDays :any = []
  getAllDaysByNameiNMoth(e){
    if(e.checked){
      this.allDays=[]
      let day_name = moment(this.dayInfo).format('dddd')
      var day = moment()
      .startOf('month')
      .day(day_name);
      if (day.date() > 7) day.add(7,'d');
      var month = day.month();
      while(month === day.month()){
          /* document.body.innerHTML += "<p>"+day.toString()+"</p>"; */
          this.allDays.push(day.toString())
          day.add(7,'d');
      }
  
      console.log('allDays',this.allDays);
    }else{
      this.allDays=[]
    
   
    }
  
    
  }
  buildform(data) {
    data = data.events[0]
  
    this.form =this.fb.group({
      title: 'A 3 day event',
      /*  , */
       data:[data.data],
       index:data.index,
      /*  start: [moment(element,'DD/MM/YYYY').toISOString(),[Validators.required]], */
       start:[data.start,[Validators.required]],
       end:[data.end,[Validators.required]] ,
       shift_time_day:[data.shift_time_day,[Validators.required]],
       shift_time_date:[data.shift_time_date,[Validators.required]],
       shift_time_day_off:[data.shift_time_day_off||false,[Validators.required]],
       shift_total_hours:[data.shift_total_hours,[Validators.required]],
       shift_break_hours:[data.shift_break_hours,[Validators.required]],
       check_in_start:[data.check_in_start,[Validators.required]],
       check_in_end:[data.check_in_end,[Validators.required]],
       break_out_start:[data.break_out_start,[Validators.required]],
       break_out_end:[data.break_out_end,[Validators.required]],
       break_in_start:[data.break_in_start,[Validators.required]],
       break_in_end:[data.break_in_end,[Validators.required]],
       check_out_start:[data.check_out_start,[Validators.required]],
       check_out_end:[data.check_out_end,[Validators.required]],
       shift_start_time:[data.shift_start_time,[Validators.required]],
       shift_end_time:[data.shift_end_time,[Validators.required]],
       copy_all_days_month:[false],
       copy_all_days_names:[false],
    })

    if(data.shift_time_day_off){
      this.dayOff({checked:data.shift_time_day_off})
    }
  }

  save(){
    console.log(this.form);
    this.dialogRef.close({formValue:this.form.value,days:this.allDays});
  }

  closeModal() {
    this.dialogRef.close({formValue:null,days:null});
  }
}
