<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno" >
    <mat-accordion class="user_permissions" *ngIf="mention_allow || admin">
        <mat-expansion-panel class="mpanel" [expanded]="true">
            <i class="together-icon">
                <i (click)="emptypermissionfields();openModal(createPermitGroups);" class="fa fa-plus s2icon mnabricon"  matTooltip="{{'lang_permit_mentions' | language}}"></i>
            </i>
            <mat-expansion-panel-header class="mpanel-header">{{'lang_permit_mentions' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <ng-select 
                                [items]="branches" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                clearAllText="Clear"
                                appendTo="body" 
                                [(ngModel)]="searchBranch"
                                placeholder="{{'lang_search_branch' | language}}"
                                (change)="spinner.show();load_permission_mention();">
                            </ng-select>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <ng-select 
                                [items]="groups" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                clearAllText="Clear"
                                appendTo="body" 
                                [(ngModel)]="searchGroup"
                                placeholder="{{'lang_group' | language}}"
                                (change)="spinner.show();load_permission_mention();">
                            </ng-select>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <ng-select 
                                [items]="users" 
                                [multiple]="false" 
                                bindLabel="label" 
                                bindValue="value" 
                                clearAllText="Clear"
                                appendTo="body" 
                                [(ngModel)]="searchUser"
                                placeholder="{{'lang_users' | language}}"
                                (change)="spinner.show();load_permission_mention();">
                            </ng-select>
                        </div>                
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dropdownyes customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsUsr checkbox" id="allItemsUsr" (click)="checkAllItemsUsr($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_username' | language }}</td>
                                            <td>{{'lang_branch' | language }}</td>
                                            <td>{{'lang_group' | language }}</td>
                                            <td>{{'lang_permission' | language }}</td>
                                            <td>{{'lang_writer' | language }}</td>
                                            <td>{{'lang_ondate' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="pmdata.length != '0' " class="AppendList">
                                        <tr *ngFor="let list of pmdata.records; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{list.mention_id}}">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemUsr checkbox" value="{{list.mention_id}}" (click)="checkEachItemUsr($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td *ngIf="list.mention_name">{{list.mention_name}}</td>
                                            <td *ngIf="!list.mention_name">null</td>
                                            <td>{{list.branch_name}}</td>
                                            <td>{{list.group_name}}</td>
                                            <td *ngIf="list.private_value == 'all' ">{{list.permitname}} ({{'lang_all' | language }})</td>
                                            <td *ngIf="list.private_value != 'all' ">{{list.permitname}} (ID - {{list.private_value}})</td>
                                            <td *ngIf="list.creator_name">{{list.creator_name}}</td>
                                            <td *ngIf="!list.creator_name">null</td>
                                            <td>{{list.create_date}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="pmdata.length == '0' " class="AppendList">
                                        <tr class="odd">
                                            <td colspan="8" align="center">{{lodingdatas}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="pmdata.length != '0' " class="card-footer padb0">
                    <div class="row">
                        <div *ngIf="able2delete" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataUsr()" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-6 mb-10">
                            {{'lang_total' | language }} : {{pmdata.page.total_records}}
                        </div>
                        <div class="col-lg-5 col-sm-6 mb-10">
                            <mat-radio-group>
                                <mat-radio-button value="10" checked (click)="loadpagesize(10)"> 10 </mat-radio-button>
                                <mat-radio-button value="20" (click)="loadpagesize(20)"> 20 </mat-radio-button>
                                <mat-radio-button value="50" (click)="loadpagesize(50)"> 50 </mat-radio-button>
                                <mat-radio-button value="100" (click)="loadpagesize(100)"> 100 </mat-radio-button>
                                <mat-radio-button value="500" (click)="loadpagesize(500)"> 500 </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            {{'lang_goto' | language }} :
                            <select class="customselect" (change)="loadpage($event.target.value)">
                                <option *ngFor="let ks of ds.getrange(pmdata.page.total_pages)" value="{{ks}}">{{ks}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="human_resources_settings">
        <mat-expansion-panel class="mpanel" [expanded]="false">
            <mat-expansion-panel-header class="mpanel-header">{{'lang_settings' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-lg-3 col-sm-3 mb-10">
                            <select class="form-control almnabrformcontrol" (change)="spinner.show();searchLoadSettings();" matTooltip="{{'lang_search_type' | language }}" [(ngModel)]="searchType">
                                <option value="ALL">{{'lang_all' | language }}</option>
                                <option value="BANK">{{'lang_bank' | language }}</option>
                                <option value="ETIT">{{'lang_human_resources_emptitle' | language }}</option>
                                <option value="JTIT">{{'lang_job_position' | language }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-9 col-sm-9 mb-10">
                            <div class="input-group input-group-sm mb-10">
                                <input type="text" class="form-control almnabrformcontrol" (keyup)="searchLoadSettings();" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}" />
                                <span class="input-group-btn" *ngIf="ds.allowMe('human_resources','human_resources_add')">
                                    <button id="btnSearch" type="button" matTooltip="{{'lang_add_settings' | language }}" class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" (click)="emptyformFields(); openModelC(createSettings);"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>
                                                <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                                    <input type="checkbox" class="allItemsSet checkbox" id="allItemsSet" (click)="checkAllSet($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td>{{'lang_human_resources_settings_type' | language }}</td>
                                            <td>{{'lang_title_english' | language }}</td>
                                            <td>{{'lang_title_arabic' | language }}</td>
                                            <td>{{'lang_status' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="allsettings.length != '0'" class="AppendList">
                                        <tr *ngFor="let setting of allsettings.records; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{setting.settings_id}}">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                                    <input type="checkbox"  class="eachItemSet checkbox" value="{{setting.settings_id}}" (click)="checkEachItemSet($event)"/>
                                                    <label for="checkbox"></label>
                                                </div>
                                            </td>
                                            <td *ngIf="setting.settings_type == 'BANK'"> {{'lang_bank' | language }}</td>
                                            <td *ngIf="setting.settings_type == 'JTIT'">{{'lang_job_position' | language }}</td>
                                            <td *ngIf="setting.settings_type == 'ETIT'">{{'lang_human_resources_emptitle' | language }}</td>
                                            <td>{{setting.settings_name_english}}</td>
                                            <td>{{setting.settings_name_arabic}}</td>
                                            <td *ngIf="setting.settings_status == 1">
                                                <i class="fa fa-circle faicon green" matTooltip="{{'lang_active' | language }}"></i>
                                            </td>
                                            <td *ngIf="setting.settings_status == 0">
                                                <i class="fa fa-circle faicon red" matTooltip="{{'lang_inactive' | language }}"></i>
                                            </td>
                                            <td>
                                                <i class="fa fa-eye faicon s2icon mnabricon" matTooltip="{{'lang_view' | language }}" (click)="loadSettingData(setting.settings_id, viewSettings)"></i>
                                                <i *ngIf="ds.allowMe('human_resources','human_resources_edit')" class="fa fa-pencil-square-o s2icon mnabricon faicon" matTooltip="{{'lang_edit' | language }}" (click)="loadSettingDataForEdit(setting.settings_id, updateSettings)"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="allsettings.length == '0'" class="AppendList">
                                        <tr class="odd">
                                            <td colspan="7" align="center">{{slerrorMsg}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="allsettings.length != '0'" class="card-footer padb0">
                    <div class="row">
                        <div *ngIf="ds.allowMe('human_resources','human_resources_delete')" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                            <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkSet()" matTooltip="{{'lang_delete' | language }}"></i>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-6 mb-10">
                            {{'lang_total' | language }} : {{allsettings.page.total_records}}
                        </div>
                        <div class="col-lg-5 col-sm-6 mb-10">
                            <mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="10" checked (click)="loadSpageSize(10);"> 10 </mat-radio-button>
                                <mat-radio-button value="20" (click)="loadSpageSize(20)"> 20 </mat-radio-button>
                                <mat-radio-button value="50" (click)="loadSpageSize(50)"> 50 </mat-radio-button>
                                <mat-radio-button value="100" (click)="loadSpageSize(100)"> 100 </mat-radio-button>
                                <mat-radio-button value="500" (click)="loadSpageSize(500)"> 500 </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="col-lg-3 col-sm-6"> {{'lang_goto' | language }} :
                            <select class="customselect" (change)="loadSpage($event.target.value)">
                                <option *ngFor="let ks of ds.getrange(allsettings.page.total_pages)" value="{{ks}}">{{ks}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
<ng-template #createPermitGroups>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_permit_mentions' | language}}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRef.hide();">&times;</button>
        </div>
        <form [formGroup]="permissionForm" (ngSubmit)="savepermissions()">
            <div class="modal-body">
                <input type="hidden" formControlName="branch_id" />
                <div class="form-group">
                    <label>{{'lang_branch' | language}} :<span class="red">*</span></label>
                    <select class="form-control almnabrformcontrol" formControlName="branch_id">
                        <option value="">{{'lang_choose_options' | language}}</option>
                        <option *ngFor="let branch of branches" value="{{branch.value}}">{{branch.label}}</option>
                    </select>
                    <mat-error *ngIf="permissionForm.controls['branch_id'].touched && permissionForm.controls['branch_id'].errors?.required"> {{'lang_field_required' | language}} </mat-error>
                </div>
                <div class="form-group">
                    <label>{{'lang_group' | language}} :<span class="red">*</span></label>
                    <select class="form-control almnabrformcontrol" formControlName="group_id">
                        <option value="">{{'lang_choose_options' | language}}</option>
                        <option *ngFor="let group of groups" value="{{group.value}}">{{group.label}}</option>
                    </select>
                    <mat-error *ngIf="permissionForm.controls['group_id'].touched && permissionForm.controls['group_id'].errors?.required"> {{'lang_field_required' | language}} </mat-error>
                </div>
                <div class="form-group">
                    <label>{{'lang_users' | language}}:</label>
                    <ng-select 
                            [items]="users" 
                            [multiple]="true" 
                            bindLabel="label" 
                            bindValue="value" 
                            clearAllText="Clear"
                            appendTo="body" 
                            formControlName="users_id"
                            placeholder="{{'lang_users' | language}}"
                        >
                    </ng-select>                    
                    <mat-error *ngIf="permissionForm.controls['users_id'].touched && permissionForm.controls['users_id'].errors?.required"> {{'lang_field_required' | language}} </mat-error>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRef.hide()">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #viewSettings>
    <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_view' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefV.hide();">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_settings_type' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b *ngIf="viewdata.settings_type == 'BANK'">{{'lang_bank' | language }}</b>
                        <b *ngIf="viewdata.settings_type == 'JTIT'">{{'lang_job_position' | language }}</b>
                        <b *ngIf="viewdata.settings_type == 'ETIT'">{{'lang_human_resources_emptitle' | language }}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_title_english' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12"><b>{{viewdata.settings_name_english}}</b></div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_title_arabic' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12"><b>{{viewdata.settings_name_arabic}}</b></div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_status' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b *ngIf="viewdata.settings_status == 1">{{'lang_active' | language }}</b>
                        <b *ngIf="viewdata.settings_status == 0">{{'lang_inactive' | language }}</b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.settings_type == 'BANK' || viewdata.settings_type == 'JTIT' ">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_bank_shortcode' | language }}:</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b>{{viewdata.settings_short_code}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.settings_type == 'JTIT'">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_human_resources_need_licence' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b *ngIf="viewdata.settings_need_licence == 1">{{'lang_yes' | language }}</b>
                        <b *ngIf="viewdata.settings_need_licence == 0">{{'lang_no' | language }}</b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.settings_type == 'JTIT' && viewdata.settings_need_licence == 1 && viewLicenceData.length != '0' ">
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive customResponsive">
                            <table border="1" class="table CustomTable">
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>{{'lang_human_resources_name_english' | language }}</td>
                                        <td>{{'lang_human_resources_name_arabic' | language }}</td>
                                        <td>{{'lang_ondate' | language }}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let l of viewLicenceData; let i = index let odd=odd; let even=even"
                                        [ngClass]="{ odd: odd, even: even }">
                                        <td>{{i+1}}</td>
                                        <td>{{l.licence_name_english}}</td>
                                        <td>{{l.licence_name_arabic}}</td>
                                        <td>{{l.licence_created_datetime}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="modalRefV.hide();">{{'lang_close' | language }}</button>
        </div>
    </div>
</ng-template>
<ng-template #updateSettings>
    <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_human_resources_edit_settings' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefU.hide();">&times;</button>
        </div>
        <form [formGroup]="settingForm" (ngSubmit)="updateSetting()">
            <div class="modal-body">
                <input type="hidden" class="form-control almnabrformcontrol" formControlName="settings_id">
                <div class="form-group">
                    <label>{{'lang_human_resources_settings_type' | language }}:<span class="red">*</span></label>
                    <select formControlName="settings_type" #type (change)="formfiledsaction(type.value)" class="form-control almnabrformcontrol settings_type" matTooltip="{{'lang_human_resources_settings_type' | language }}">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option value="ETIT">{{'lang_human_resources_emptitle' | language }}</option>
                        <option value="BANK">{{'lang_bank' | language }}</option>
                        <option value="JTIT">{{'lang_job_position' | language }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="settings_name_english">{{'lang_title_english' | language }}:<span class="red">*</span></label>
                    <input type="text" class="form-control almnabrformcontrol" placeholder="{{'lang_title_english' | language }}" formControlName="settings_name_english">
                </div>
                <div class="form-group">
                    <label for="settings_name_arabic">{{'lang_title_arabic' | language }}:<span class="red">*</span></label>
                    <input type="text" class="form-control almnabrformcontrol" placeholder="{{'lang_title_arabic' | language }}" formControlName="settings_name_arabic">
                </div>
                <div class="form-group" *ngIf="needshortcode">
                    <label for="settings_short_code">{{'lang_human_resources_bank_shortcode' | language }}:<span class="red">*</span></label>
                    <input type="text" class="form-control almnabrformcontrol" placeholder="{{'lang_human_resources_bank_shortcode' | language }}" formControlName="settings_short_code">
                </div>
                <div *ngIf="needlicence">
                    <div class="form-group">
                        <label for="settings_need_licence">{{'lang_human_resources_need_licence' | language }}:<span class="red">*</span></label>
                        <select formControlName="settings_need_licence" #settings_need_licence (change)="showlicencefields(settings_need_licence.value)" class="form-control almnabrformcontrol settings_need_licence" matTooltip="{{'lang_human_resources_need_licence' | language }}">
                            <option value="">{{'lang_choose_options' | language }}</option>
                            <option value="1">{{'lang_yes' | language }}</option>
                            <option value="0">{{'lang_no' | language }}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="licencefileds">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-2 col-sm-2 col-xs-12">
                                <i class="fa fa-plus-square mnabricon fa-icon" matTooltip="{{'lang_add' | language }}" (click)="dynamicLicenceAdd()"></i>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" formArrayName="licenses">
                        <div *ngFor="let licence of licenceFormGroup.controls; let i = index;">
                            <div class="row mb-10" [formGroupName]="i">
                                <div class="col-md-5 col-sm-5 col-xs-12">
                                    <label>{{'lang_human_resources_name_english' | language }}:<span class="red">*</span></label>
                                    <input type="text" placeholder="{{'lang_human_resources_name_english' | language }}" formControlName="licence_title_english" class="form-control almnabrformcontrol licence_title_english">
                                </div>
                                <div class="col-md-5 col-sm-5 col-xs-12">
                                    <label for="licence_title_arabic">{{'lang_human_resources_name_arabic' | language }}:<span class="red">*</span></label>
                                    <input type="text" placeholder="{{'lang_human_resources_name_arabic' | language }}" formControlName="licence_title_arabic" class="form-control almnabrformcontrol licence_title_arabic">
                                </div>
                                <div class="col-12 col-sm-2 col-xs-12 mt-30">
                                    <i (click)="dynamicLicenceRemove(i)" class="fa fa-minus-square red mnabricon fa-icon" matTooltip="{{'lang_delete' | language }}"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefU.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #createSettings>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_add_settings' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal"  (click)="modalRefC.hide();">&times;</button>
        </div>
        <form [formGroup]="settingForm" (ngSubmit)="saveSettings()">
            <div class="modal-body">            
                <div class="form-group">
                    <label>{{'lang_human_resources_settings_type' | language }}:<span class="red">*</span></label>
                    <select formControlName="settings_type" #settings_type
                        (change)="formfiledsaction(settings_type.value)" class="form-control almnabrformcontrol settings_type"
                        matTooltip="{{'lang_human_resources_settings_type' | language }}" id="settings_type">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option value="ETIT">{{'lang_human_resources_emptitle' | language }}</option>
                        <option value="BANK">{{'lang_bank' | language }}</option>
                        <option value="JTIT">{{'lang_job_position' | language }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="settings_name_english">{{'lang_title_english' | language }}:<span
                            class="red">*</span></label>
                    <input type="text" class="form-control almnabrformcontrol" id="settings_name_english"
                        placeholder="{{'lang_title_english' | language }}" formControlName="settings_name_english">
                </div>
                <div class="form-group">
                    <label for="settings_name_arabic">{{'lang_title_arabic' | language }}:<span
                            class="red">*</span></label>
                    <input type="text" class="form-control almnabrformcontrol" id="settings_name_arabic"
                        placeholder="{{'lang_title_arabic' | language }}" formControlName="settings_name_arabic">
                </div>
                <div class="form-group" *ngIf="needshortcode">
                    <label for="settings_short_code">{{'lang_human_resources_bank_shortcode' | language }}:<span
                            class="red">*</span></label>
                    <input type="text" class="form-control almnabrformcontrol" id="settings_short_code"
                        placeholder="{{'lang_human_resources_bank_shortcode' | language }}"
                        formControlName="settings_short_code">
                </div>
                <div *ngIf="needlicence">
                    <div class="form-group">
                        <label
                            for="settings_need_licence">{{'lang_human_resources_need_licence' | language }}:<span
                                class="red">*</span></label>
                        <select formControlName="settings_need_licence" #settings_need_licence
                            (change)="showlicencefields(settings_need_licence.value)"
                            class="form-control almnabrformcontrol settings_need_licence"
                            matTooltip="{{'lang_human_resources_need_licence' | language }}"
                            id="settings_need_licence">
                            <option value="">{{'lang_choose_options' | language }}</option>
                            <option value="1">{{'lang_yes' | language }}</option>
                            <option value="0">{{'lang_no' | language }}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="licencefileds">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-2 col-sm-2 col-xs-12">
                                <i class="fa fa-plus-square fa-icon mnabricon" matTooltip="{{'lang_add' | language }}" (click)="dynamicLicenceAdd()"></i>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" formArrayName="licenses">
                        <div *ngFor="let licence of licenceFormGroup.controls; let i = index;">
                            <div class="row mb-10" [formGroupName]="i">
                                <div class="col-md-5 col-sm-5 col-xs-12">
                                    <label>{{'lang_human_resources_name_english' | language }}:<span class="red">*</span></label>
                                    <input type="text" placeholder="{{'lang_human_resources_name_english' | language }}" formControlName="licence_title_english" class="form-control almnabrformcontrol licence_title_english">
                                </div>
                                <div class="col-md-5 col-sm-5 col-xs-12">
                                    <label for="licence_title_arabic">{{'lang_human_resources_name_arabic' | language }}:<span class="red">*</span></label>
                                    <input type="text" placeholder="{{'lang_human_resources_name_arabic' | language }}" formControlName="licence_title_arabic" class="form-control almnabrformcontrol licence_title_arabic">
                                </div>
                                <div class="col-12 col-sm-2 col-xs-12 mt-30">
                                    <i (click)="dynamicLicenceRemove(i)" class="fa fa-minus-square red fa-icon" matTooltip="{{'lang_delete' | language }}"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefC.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>