 
	<div class="form-wrapper">
		<h3 class="mb-2">{{'lang_edit_violation'|language}}</h3>
		<mat-divider></mat-divider>

		<form [formGroup]="form" class="form" (ngSubmit)="submitForm()">
			<div class="row mb-10">
				<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_employee_no"|language }}</div>
					<div class="mb-10">
						<b> {{allData?.form_vol1_data?.records?.employee_number}} </b>
					</div>
				</div>
				<div class="col-md-6 col-sm-6 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_name"|language }}</div>
					<div class="mb-10">
						<b>
							{{allData?.form_vol1_data?.records?.firstname_english}} </b
						>
					</div>
				</div>
				<!-- <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_salary"|language }}</div>
					<div class="mb-10">
						<b>
							5000</b
						>
					</div>
				</div>
				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_current_salary"|language }}</div>
					<div class="mb-10">
						<b>
						   4500</b
						>
					</div>
				</div>

				<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
					<div class="mb-10">{{ "lang_joining_date"|language }}</div>
					<div class="mb-10">
						<b>
						   15/06/2002</b
						>
					</div>
				</div> -->
			</div>

			<div class="row">
                  
               
				<div class="col-md-3 col-sm-3 col-xs-12 mb-10">
					<div class="form-group">
						<label for="violation_date_en">{{'lang_date' | language }} : <span class="red">*</span></label>
						<div class="positionrelatiove">
							<input formControlName="violation_date_en" type="text" class="form-control almnabrformcontrol" (dateChange)="ds.setENTOARDate($event, form.get('violation_date_ar'));" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
							<mat-datepicker-toggle matSuffix [for]="BDeng" class="posiab"></mat-datepicker-toggle>
							<mat-datepicker #BDeng disabled="false"></mat-datepicker>
							<mat-error *ngIf="submitted && form.controls['violation_date_en'].errors?.required">{{'lang_field_required' | language}}</mat-error>
						</div>
					</div>
				</div>
				

				<div  class="col-md-3 col-sm-3 col-xs-12 mb-10">

					  <label class="mb-10">
						{{ "lang_violation_reason" | language }}
						<span class="red"> * </span> </label>
				 <!--      <input id="typeahead-http" type="text" class="form-control" formControlName="violation_id" [ngbTypeahead]="search" placeholder="Wikipedia search" />
					   -->
					  <ng-select
					  [items]="searchArray"
					  [multiple]="false"
					  bindLabel="label"
					  bindValue="value"
					  clearAllText="Clear"
					   (change)="getViolations($event)"
					  appendTo="body"
					  formControlName="violation_id"
					  placeholder="{{ 'lang_violation_reason' | language }}"

					   
				  >
				  </ng-select>
				  <mat-error
				  *ngIf="
				  submitted &&
				  form.controls['violation_id'].errors?.required
				  "
			  >
				  {{ "lang_field_required" | language }}
			  </mat-error>
					</div>
			 

			<div class="col-md-3 col-xs-12 col-sm-3 mb-10">
				<label class="mb-10">
					{{ "lang_direct_manager" | language }}
					<span class="red"> * </span> </label>

					<ng-select
					[items]="users"
					[multiple]="false"
					bindLabel="label"
					bindValue="value"
					clearAllText="Clear"
					 
					appendTo="body"
					formControlName="direct_manager"
					placeholder="{{ 'lang_direct_manager' | language }}"
					(search)="search_users($event)"
					
					[searchFn]="ds.customSearchFn"
					 
				>
				</ng-select>
				<mat-error
					*ngIf="
					submitted &&
					form.controls['direct_manager'].errors?.required
					"
				>
					{{ "lang_field_required" | language }}
				</mat-error>
			 
			</div>  
		  
		  <div  class="col-md-3 col-sm-3 col-xs-12 mb-10">
			<button (click)="get_employee_violations()" type="button" class="albutton mnabrbutton mat-accent top26 withOutLabel" mat-button>
				<!--  <i class="fa fa-plus"></i>  -->{{ "lang_history" | language }}
			   </button>
		  
		</div>
				
		<div  class="col-md-3 col-sm-3 col-xs-12 mb-10" *ngIf="form.controls['violation_punishment'].value">

			<label class="mb-10" style="display: block;">
			  {{ "lang_punishment" | language }}
			  <span class="red"> * </span> </label>
			  
			  <h3>
				{{form.controls['violation_punishment'].value}}
			  </h3>
		 <!--    <input id="typeahead-http" type="text" class="form-control" formControlName="violation_id" [ngbTypeahead]="search" placeholder="Wikipedia search" /> -->
	  </div>

	  
	  <div  class="col-md-3 col-sm-3 col-xs-12 mb-10" *ngIf="form.controls['violation_amount'].value">

		<label class="mb-10"  style="display: block;">
		  {{ "lang_deduct_amount" | language }}
		  <span class="red"> * </span> </label>
		  <h3>
			{{+form.controls['violation_amount'].value| number : '.2-2'}}
		  </h3>
	  
	  <!--   <input id="typeahead-http" type="text" class="form-control" formControlName="violation_id" [ngbTypeahead]="search" placeholder="Wikipedia search" /> -->
	</div>
	  
	<div class="row col-md-12">
		<div  class="col-sm-6 col-xs-6 mb-10">

			<label class="mb-10">
			{{ "lang_statement_en" | language }}
			<span class="red"> * </span> </label>
		 
			<textarea  id="" cols="30" rows="6" class="form-control almnabrformcontrol"  formControlName="violation_statement_en" ></textarea>
			<mat-error
			*ngIf="
			submitted &&
			form.controls['violation_statement_en'].errors?.required
			"
		>
			{{ "lang_field_required" | language }}
		</mat-error>
		</div>

		<div  class=" col-sm-6 col-xs-6 mb-10">

			<label class="mb-10">
			{{ "lang_statement_ar" | language }}
			<span class="red"> * </span> </label>
		  
			<textarea  id="" cols="30" rows="6"  class="form-control almnabrformcontrol"  formControlName="violation_statement_ar" ></textarea>
			<mat-error
			*ngIf="
			submitted &&
			form.controls['violation_statement_ar'].errors?.required
			"
		>
			{{ "lang_field_required" | language }}
		</mat-error>
		</div>
		 
	</div>
	
			</div>
	  
			<div class="row pb-3 pt-3">
				<div class="col-sm-8 col-12"></div>
				<div class="col-sm-4 col-12">
					<button mat-button type="submit" class="mnabrbutton mat-accent test" >
						{{'lang_submit'|language}}
					</button>
				</div>
			</div>
		</form>
	</div>
 



 