<div class="card pstr-step1">
	<div class="card-header">{{ "lang_configurations" | language }}</div>
	<div class="card-body pad0">
		<div class="mbrl15">
			<mat-horizontal-stepper #stepper [linear]="true" class="pad0">
				<mat-step [stepControl]="lang_form">
					<form [formGroup]="lang_form">
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-building fa-icon"></i>
								</div>
							</div>
							<div>{{ "lang_languages" | language }}</div>
						</ng-template>
						<div class="row mb-10">
							<div class="col-md-4 col-sm-4 col-xs-12 mb-10">
								<label>
									{{ "lang_languages" | language }}
									<span class="red">*</span></label
								>
								<select
									class="form-control almnabrformcontrol"
									formControlName="lang_key"
								>
									<option value="">
										{{ "lang_choose_options" | language }}
									</option>
									<option
										*ngFor="let lang of languages"
										value="{{ lang.value }}"
									>
										{{ lang.label }}
									</option>
								</select>
								<mat-error
									*ngIf="
										submitted && lang_form.controls['lang_key'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10 col-sm-8 col-xs-12 mb-10"></div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperNext
								>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>
				<mat-step [stepControl]="canMoveStep2">
					<form [formGroup]="work_sites_form">
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-building fa-icon"></i>
								</div>
							</div>
							<div>{{ "lang_work_site_levels" | language }}</div>
						</ng-template>
						<div class="row p-1">
							<div class="col-sm-12">
								<label class="mb-10"
									>{{ "lang_separate" | language }} :
									<span class="red">*</span>
									<i
										style="padding: 0 10px"
										class="fa fa-info-circle orangestatus-color"
										matTooltip="{{ 'lang_separate_tooltip' | language }}"
									></i>
								</label>
								<div class="col-sm-12">
									<mat-radio-group
										formControlName="separate"
										(change)="
											work_sites_form.controls['work_site'].setValue('')
										"
									>
										<mat-radio-button value="1">{{
											"lang_yes" | language
										}}</mat-radio-button>
										<mat-radio-button value="0">{{
											"lang_no" | language
										}}</mat-radio-button>
									</mat-radio-group>
								</div>
							</div>
						</div>
						<div class="row mb-10 p-1">
							<div class="col-sm-12 col-xs-12 mb-10">
								<label class="mb-10"
									>{{ "lang_work_site" | language }} :
									<span class="red">*</span>
									<i
										style="padding: 0 10px"
										class="fa fa-info-circle orangestatus-color"
										matTooltip="{{ 'lang_work_site_tooltip' | language }}"
									></i>
									<span style="margin: 0 20px" *ngIf="unitsOfTemplate">
										{{ "lang_available_units" | language }} :
										<span
											class="bold"
											matTooltip="{{ unitsOfTemplate }}"
											*ngIf="unitsOfTemplate?.length > 10"
											>{{ unitsOfTemplate | shortenLength: 10 }}</span
										>
										<span class="bold" *ngIf="unitsOfTemplate?.length < 10">{{
											unitsOfTemplate | shortenLength: 10
										}}</span>
									</span>
								</label>
								<div class="">
									<mat-radio-group
										formControlName="work_site"
										(change)="
											get_work_levels();
											set_validation_step1();
											generalNumbersData = [];
											phasesData = []
										"
									>
										<mat-radio-button
											value="ALL"
											*ngIf="work_sites_form.get('separate').value == '0'"
											>{{ "lang_for_all_units" | language }}</mat-radio-button
										>
										<mat-radio-button value="GN">{{
											"lang_by_general_number" | language
										}}</mat-radio-button>
										<mat-radio-button value="IM">{{
											"lang_by_phases" | language
										}}</mat-radio-button>
									</mat-radio-group>
								</div>
								<mat-error
									*ngIf="
										submitted &&
										work_sites_form.controls['work_site'].errors?.required
									"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div
								class="col-sm-6 col-xs-12 mb-10 mt-2"
								*ngIf="work_sites_form.get('work_site').value == 'ALL'"
							>
								<label class="mb-10"
									>{{ "lang_fill_worklevels" | language }} :
									<span class="red">*</span></label
								>

								<ng-select
									[items]="work_levels_for_all"
									[multiple]="true"
									bindLabel="label"
									bindValue="value"
									clearAllText="Clear"
									[searchFn]="ds.customSearchFn"
									appendTo="body"
									[formControl]="work_levels_for_all_control"
									(change)="getUnitsForAll()"
									placeholder="{{ 'lang_fill_worklevels' | language }}"
								>
								</ng-select>
								<mat-error
									*ngIf="work_levels_for_all_control.errors?.required"
									>{{ "lang_field_required" | language }}</mat-error
								>
							</div>
							<div
								class="col-sm-12 mb-2"
								*ngIf="work_sites_form.get('work_site').value == 'GN'"
							>
								<div
									class="table-responsive dropdownyes customResponsive tablefields"
								>
									<table border="1" class="table CustomTable">
										<thead>
											<tr>
												<th>#</th>
												<th>
													<i
														class="fa fa-plus green"
														style="font-size: 15px"
														(click)="addByGeneralNumbers()"
														#tooltip="matTooltip"
														matTooltip="Add"
													></i>
												</th>
												<th>{{ "lang_units" | language }}</th>
												<th>{{ "lang_work_level" | language }}</th>
											</tr>
										</thead>
										<tbody *ngIf="generalNumbersData.length">
											<tr
												*ngFor="
													let item of generalNumbersData;
													let i = index;
													let odd = odd;
													let even = even
												"
											>
												<td>{{ i + 1 }}</td>
												<td>
													<i
														class="fa fa-trash red"
														style="font-size: 15px"
														(click)="deleteByGeneralNumbers(i)"
														#tooltip="matTooltip"
														matTooltip="Delete"
													></i>
												</td>
												<td>{{ item.units }}</td>
												<td>{{ item.displayWorklevels }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<div class="row pb-5">
							<div
								class="col-sm-12 mb-2"
								*ngIf="work_sites_form.get('work_site').value == 'IM'"
							>
								<div
									class="table-responsive dropdownyes customResponsive tablefields"
								>
									<table border="1" class="table CustomTable">
										<thead>
											<tr>
												<th>#</th>
												<th>
													<i
														class="fa fa-plus green"
														style="font-size: 15px"
														(click)="addByPhases()"
														#tooltip="matTooltip"
														matTooltip="Add"
													></i>
												</th>
												<th>{{ "lang_zones" | language }}</th>
												<th>{{ "lang_blocks" | language }}</th>
												<th>{{ "lang_clusters" | language }}</th>
												<th>{{ "lang_units" | language }}</th>
												<th>{{ "lang_work_level" | language }}</th>
											</tr>
										</thead>
										<tbody *ngIf="phasesData.length">
											<tr
												*ngFor="
													let item of phasesData;
													let i = index;
													let odd = odd;
													let even = even
												"
											>
												<td>{{ i + 1 }}</td>
												<td>
													<i
														class="fa fa-trash red"
														style="font-size: 15px"
														(click)="deleteByPhases(i)"
														#tooltip="matTooltip"
														matTooltip="Delete"
													></i>
												</td>
												<td>{{ item.zone }}</td>
												<td>{{ item.block }}</td>
												<td>{{ item.cluster }}</td>
												<td>{{ item.units }}</td>
												<td>{{ item.displayWorklevels }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperPrevious
								>
									{{ "lang_previous" | language }}
								</button>
							</div>
							<div class="col-md-8 col-sm-4 col-xs-12 mb-10"></div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									(click)="send_units_and_work_levels(false)"
								>
									{{ "lang_next" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>

				<mat-step [stepControl]="ncr_form">
					<form [formGroup]="ncr_form">
						<ng-template matStepLabel>
							<div class="step">
								<div class="st">
									<i class="icon-5x fa fa-paperclip fa-icon"></i>
								</div>
							</div>
							<div>{{ "lang_ncr_point" | language }}</div>
						</ng-template>

						<div class="row p-3">
							<div class="col-sm-6 mb-2">
								<label for="work_name">{{ "lang_work_name" | language }}</label>
								<ng-select
									[items]="work_name_array"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									clearAllText="Clear"
									[searchFn]="ds.customSearchFn"
									appendTo="body"
									formControlName="work_name"
									placeholder="{{ 'lang_work_name' | language }}"
								>
								</ng-select>
							</div>

							<div class="col-sm-6 mb-2">
								<label for="issue"
									>{{ "lang_issue" | language }}
									<i
										class="fa fa-info-circle orangestatus-color"
										matTooltip="{{ 'lang_issue_description' | language }}"
									></i
								></label>
								<ng-select
									[items]="issues"
									[multiple]="false"
									bindLabel="label"
									bindValue="value"
									clearAllText="Clear"
									[searchFn]="ds.customSearchFn"
									appendTo="body"
									formControlName="issue"
									placeholder="{{ 'lang_issue' | language }}"
								>
								</ng-select>
							</div>
							<div
								class="col-sm-6 mb-2"
								*ngIf="
									ncr_form.get('issue').value == null ||
									ncr_form.get('issue').value == ''
								"
							>
								<label for="new_issue"
									>{{ "lang_add" | language }} {{ "lang_new" | language }}
									{{ "lang_issue" | language }}
									<i
										class="fa fa-info-circle orangestatus-color"
										matTooltip="{{ 'lang_optional_field' | language }}, {{
											'lang_add' | language
										}} {{ 'lang_new' | language }}
										{{ 'lang_issue' | language }}"
									></i
								></label>

								<textarea
									name="new_issue"
									id="new_issue"
									style="max-height: 200px; min-height: 100px"
									formControlName="new_issue"
									placeholder="{{ 'lang_add' | language }} {{
										'lang_new' | language
									}} {{ 'lang_issue' | language }}"
									autocomplete="off"
									class="form-control almnabrformcontrol"
								>
								</textarea>
							</div>
						</div>
						<div class="row p-3 pb-4">
							<div class="col-md-12 TableParts">
								<p class="mb-10">
									{{ "lang_attachments" | language }}
								</p>
								<div
									class="table-responsive customResponsive tablefields quotationitemtable"
								>
									<table
										border="1"
										class="table CustomTable"
										width="100%"
										formArrayName="attachments"
									>
										<thead>
											<tr>
												<td
													width="2%"
													style="padding: 5px; word-break: break-all"
												>
													#
												</td>
												<td
													width="2%"
													style="padding: 5px; word-break: break-all"
												>
													<mat-icon
														class="mnabricon"
														matTooltip="{{ 'lang_add' | language }}"
														(click)="addfiles()"
														>add_box</mat-icon
													>
												</td>
												<td
													width="48%"
													style="padding: 5px; word-break: break-all"
												>
													{{ "lang_title" | language }}
												</td>
												<td
													width="48%"
													style="padding: 5px; word-break: break-all"
												>
													{{ "lang_file" | language }}
												</td>
											</tr>
										</thead>
										<tbody>
											<tr
												*ngFor="
													let file of filesFormGroup.controls;
													let i = index;
													let odd = odd;
													let even = even
												"
												[formGroupName]="i"
											>
												<td
													class="verticalalignmid"
													style="padding: 5px; word-break: break-all"
												>
													{{ i + 1 }}
												</td>
												<td class="verticalalignmid">
													<mat-icon
														*ngIf="
															!other_attachments.controls[i].get('delete_icon')
																.value
														"
														(click)="removefiles(i)"
														class="mnabricon red"
														matTooltip="{{ 'lang_delete' | language }}"
														>delete_forever</mat-icon
													>
												</td>
												<td
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<input
														type="text"
														formControlName="attach_title"
														placeholder="{{ 'lang_description' | language }}"
														autocomplete="off"
														class="form-control almnabrformcontrol"
													/>
												</td>
												<td
													class="verticalalignmid"
													style="
														padding: 5px;
														word-break: break-all;
														position: relative;
													"
												>
													<input
														name="file"
														type="file"
														required
														onclick="this.value = null"
														(change)="
															ds.uploadSingleFile(
																$event,
																other_attachments.controls[i].get('file'),
																'file' + i,
																filename
															)
														"
														class="nghide albutton width100"
													/>
													<label id="file{{ i }}" class="albutton">{{
														filename
													}}</label>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									matStepperPrevious
								>
									{{ "lang_previous" | language }}
								</button>
							</div>
							<div class="col-md-8 col-sm-4 col-xs-12 mb-10">
								<app-alert id="default-alert"></app-alert>
							</div>
							<div class="col-md-2 col-sm-4 col-xs-12 mb-10">
								<button
									class="albutton mnabrbutton mat-accent"
									mat-button
									(click)="send_files_to_step2()"
								>
									{{ "lang_submit" | language }}
								</button>
							</div>
						</div>
					</form>
				</mat-step>
			</mat-horizontal-stepper>
		</div>
	</div>
</div>
