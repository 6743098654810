<div class="row">
    <div class="col-md-12">
        <div class="View-title row col-md-12 m-auto">
            <div class="col-12 col-sm-4 mb-2" >
                <button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay('previous')"
                class="albutton mnabrbutton">
              {{'lang_next'|language}}
            </button>
            </div>
            <div class="col-12 col-md-4 text-center">
                
            <h3 style="padding: 5px 60px;">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
          
            </div>

            <div class="col-12 col-sm-4 mb-2" >
                <button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay('next')" class="albutton mnabrbutton">
                {{'lang_previos'|language}}
            </button>
            </div>
        </div>

        
    </div>
</div> 




<div class="row my-3">
    <div class="col-12 booking-pages">
        <div class="Calendar-view" [ngClass]="{'backgorund-calender': activeDayIsOpen}">
            <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                [openDayEventsTemplate]="monthOpenDayEventsTemplate" [activeDayIsOpen]="activeDayIsOpen"
                (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)" [cellTemplate]="monthCellTemplate">
                {{'Month'|translate}}
            </mwl-calendar-month-view>

        </div>
    </div>
</div>



<ng-template #monthCellTemplate let-day="day" let-locale="locale">
    <span class="cal-day-number" style="
    letter-spacing: 0px;
    color: #5C606B !important;"
    >{{ day.date | date:'dd' }} </span
  >
    <ng-container *ngFor="let item of day.events; let i =index">
        <ng-container *ngIf="i == 0 || i == 1">
            <div class="event event-details-month" style="width: 98%;text-align: start;" [ngClass]="[
                item.data.status === 'confirmed' ? 'event-details__confirmed' :
                item.data.status === 'cancelled' ? 'event-details__cancelled' :
                item.data.status === 'requested'  ? 'event-details__pending' :
                item.data.status === 'available'   ? 'event-details__available' :
                item.data.status === 'vacation'   ? 'event-details__vacation' :'']"
                [contextMenu]="basicMenu" [contextMenuSubject]="item.data"   >
                <span>
                    <span class="calendar-time-details" style="font-weight: bold;
                   
                    font-size: 10px;">
                   <!--  <span *ngIf="item.data.status != 'vacation'"> 
                        {{item.start | date: "hh:mm a"}}  
                    </span> -->
                    
                        <span style="margin-left:5px;font-weight: bold;
                        font-family: 'Times New Roman', Times, serif;
                        font-size: 10px;"  >
                            <span *ngIf="item.data.count > 1">
                          ({{item.data.count}}) 
                            </span>
                            <span  >
                               {{item.data.vio}}
                            </span>
                        </span>

                       <!--  <span style=" margin-left:5px; font-weight: bold;
                        font-family: 'Times New Roman', Times, serif;
                        font-size: 13px;" *ngIf="item.data.type != 'booking'">
                            {{item.title}}
                        </span> -->
                    </span>
                   <!--  <span class="fl-rit" *ngIf="item.data.status === 'available' && tutorId != '0'">
                        <span (mwlClick)="edit(item)"><i class="far fa-edit custom_fontSize color-edit mr-7"></i></span>                    
                        <span (mwlClick)="onDeleteSession(item)"><i class="far light-brown fa-trash-alt custom_fontSize mr-5"></i></span>
                    </span> -->
                 </span>
            </div>
        </ng-container>
    </ng-container>
</ng-template>



<ng-template #monthOpenDayEventsTemplate let-events="events" let-isOpen="isOpen">

    <div class="cal-open-day-events" *ngIf="isOpen">
        <h2 class="cal-title">
            <span class="cal-title-icon" >
                <mat-icon style="color: #fff;">calendar_today</mat-icon>
            </span>
            <span class="cal-title-text" >
                {{viewDate | date:'fullDate' }}
            </span>
        </h2>

        <ng-container *ngFor="let event of events">
            <div class="cals-events-container" style="text-align: start;" [ngClass]="event?.cssClass">
                <div class="cals-event-item w-100 justify-content-spacebetween " style="padding: 10px 10px;" [ngClass]="[
                event.data.status === 'confirmed' ? 'event-details__confirmed' :
                event.data.status === 'cancelled' ? 'event-details__cancelled' :
                event.data.status === 'requested'  ? 'event-details__pending' :
                event.data.status === 'available'   ? 'event-details__available' :
                event.data.status === 'vacation'   ? 'event-details__vacation' :'']">
                 <!--    <div class="first">
                      
                    </div> -->
                    <span>
                        <span class="calendar-time-details" style="font-weight: bold;
             
                        font-size: 10px;">
                       <!--  <span *ngIf="event?.data?.status != 'vacation'"> 
                            {{event?.start | date: "hh:mm a"}}  
                        </span> -->
                        
                          
    

                            <mat-accordion class="all_employees_vio">
                                <mat-expansion-panel class="mt-2" [expanded]="false">
                                    <mat-expansion-panel-header>
                                        <span style="margin-left:5px;font-weight: bold;
                                        font-family: 'Times New Roman', Times, serif;
                                        font-size: 10px;"  >
                                            <span *ngIf="event?.data?.count > 1">
                                          ({{event?.data?.count}}) 
                                            </span>
                                            <span  >
                                               {{event?.data?.vio}}
                                            </span>
                                        </span>
                                    </mat-expansion-panel-header>
                                    <table class="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">First</th>
                                            <th scope="col">Last</th>
                                            <th scope="col">Handle</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <th scope="row">1</th>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                          </tr>
                                          <tr>
                                            <th scope="row">2</th>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>@fat</td>
                                          </tr>
                                          <tr>
                                            <th scope="row">3</th>
                                            <td>Larry</td>
                                            <td>the Bird</td>
                                            <td>@twitter</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                </mat-expansion-panel>
                            </mat-accordion>
                      
                        </span>
                       <!--  <span class="fl-rit" *ngIf="item.data.status === 'available' && tutorId != '0'">
                            <span (mwlClick)="edit(item)"><i class="far fa-edit custom_fontSize color-edit mr-7"></i></span>                    
                            <span (mwlClick)="onDeleteSession(item)"><i class="far light-brown fa-trash-alt custom_fontSize mr-5"></i></span>
                        </span> -->
                     </span>
                     
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

