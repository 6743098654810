import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-langphrases',
  templateUrl: './langphrases.component.html',
  styleUrls: ['./langphrases.component.scss']
})
export class LangphrasesComponent implements OnInit {
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  modalRef: BsModalRef;
  modules = [];
  ppage = 1;
  psize = 10;
  language_Key =null;
  searchKey = "";
  module_key = null;
  form:FormGroup;
  phrasedata:any = [];
  isAdmin = this.ds.is_admin();
  phrasenodata: string = this.lang.transform('lang_loading');
  langnodata: string = this.lang.transform('lang_loading');
  languages:any = [];
  constructor(public ds:DataService,public route : ActivatedRoute, public datePipe: DatePipe,public router : Router, public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.getmodules();
    this.getallphrases();
    this.getalllanguages();
    this.form = new FormGroup({
      phrase_id  :new FormControl(''),
      lang_key  :new FormControl('', [Validators.required]),
      module_key  :new FormControl('', [Validators.required]),
      phrase_key  :new FormControl('', [Validators.required]),
      phrase_val  :new FormControl('', [Validators.required]),
    });
  }
  public getmodules(){
    this.modules = [];
    this.ds.postActionByUrl([], 'module').subscribe(data => {
      if (data.status) {
        this.modules = data.records;
      }
    });
  }
  public getalllanguages() {
    this.ds.getActionByUrl([], 'getalllanguages/1/200').subscribe(data => {
      if (data.status) {
        this.languages = data.records;
      } else {
        this.langnodata = data.error;
      }
    }, error => {
      this.langnodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error') + ' & ' + error.statusText;
    });
  }
  /* code for phrases starts*/
  public emptyform(list = null) {
    this.form.reset();
    this.alert.clear();
    this.form.get('lang_key').setValue("");
    this.form.get('module_key').setValue("");
    if(list) {
      this.form.get('phrase_id').setValue(list.phrase_id);
      this.form.get('lang_key').setValue(list.lang_key);
      this.form.get('module_key').setValue(list.module_key);
      this.form.get('phrase_key').setValue(list.phrase_key);
      this.form.get('phrase_val').setValue(list.phrase_val);
    }
  }
  public submit() {
    this.spinner.show();
    let param = new FormData();
    if(this.form.get('phrase_id').value) {
      param.append('phrase_id', this.form.get('phrase_id').value);
      param.append('module_key', this.form.get('module_key').value);
      param.append('uphrase_key', this.form.get('phrase_key').value);
      param.append('uphrase_name', this.form.get('phrase_val').value);
      this.ds.putActionByUrl(this.ds.formData2string(param), 'updatephrases').subscribe(res => {
        this.spinner.hide();
        if(res.status ) {
          this.form.reset();
          this.alert.success(res.msg);
          this.getallphrases();
          setTimeout(() => {
            this.modalRef.hide();
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      }, err => {
        this.spinner.hide();
        this.alert.error(err.error.error);
      })
    } else {
      param.append('lang_key', this.form.get('lang_key').value);
      param.append('module_key', this.form.get('module_key').value);
      param.append('phrase_key', this.form.get('phrase_key').value);
      param.append('phrase_val', this.form.get('phrase_val').value);
      this.ds.postActionByUrl(param, 'savephrases').subscribe(res => {
        this.spinner.hide();
        if(res.status ) {
          this.form.reset();
          this.alert.success(res.msg);
          this.getallphrases();
          setTimeout(() => {
            this.modalRef.hide();
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      }, err => {
        this.spinner.hide();
        this.alert.error(err.error.error);
      })
    }
  }
  public search_phrases() {
    this.ppage = 1;
    this.psize = 10;
    this.getallphrases();
  }
  public loadpagesize(size) {
    this.ppage = 1;
    this.psize = size;
    this.getallphrases();
  }
  public loadpage(page) {
    this.ppage = page;
    this.psize = this.psize;
    this.getallphrases();
  }
  public getallphrases() {
    const param = new FormData();
    param.append('searchType', this.language_Key || '');
    param.append('moduleKey', this.module_key || '');
    param.append('searchKey', this.searchKey);
    this.ds.postActionByUrl(param,'getphrases/'+this.ppage+'/'+this.psize).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.phrasedata = data;
      } else {
        this.phrasedata = [];
        this.phrasenodata = data.error;
      }
    }, error => {
      this.phrasedata = [];
      this.spinner.hide();
      this.phrasenodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    });
  }
  public checkAllItems(event) {
    if (event.target.checked) {
      $(".phrases_list .eachItem").prop("checked", true);
      $(".phrases_list .makeback").addClass("bground");
      $(".phrases_list .deleteIconDiv").show();
    } else {
      $(".phrases_list .eachItem").prop("checked", false);
      $(".phrases_list .makeback").removeClass("bground");
      $(".phrases_list .deleteIconDiv").hide();
    }
  }
  public checkEachItem(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".phrases_list .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".phrases_list .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".phrases_list .eachItem:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".phrases_list .eachItem").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".phrases_list .deleteIconDiv").show();
    } else {
      $(".phrases_list .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".phrases_list #allItems").prop("checked", true);
    } else {
      $(".phrases_list #allItems").prop("checked", false);
    }
  }
  public deleteBulkData() {
    const checkedtotal = [];
    $(".phrases_list .eachItem:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'deletephrases').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.getallphrases();
                $(".phrases_list #allItems").prop("checked", false);
                $(".deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
