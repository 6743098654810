<form class="incoming" [formGroup]="form" (ngSubmit)="submitForm()">
    <breadcrumb></breadcrumb>
    <section class="panel-expansion">
        <mat-accordion class="stylepanels">
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_insert_incoming' | language}}</mat-expansion-panel-header>
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_languages' | language}} <span class="red">*</span></label>
                                <ng-select 
                                    [items]="languages" 
                                    [multiple]="false" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    appendTo="body" 
                                    formControlName="lang_key"
                                    placeholder="{{'lang_languages' | language}}">
                                </ng-select>
                                <mat-error *ngIf="submitted && form.controls['lang_key'].errors?.required">
                                    {{'lang_field_required' | language}}
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_subject' | language}} <span class="red">*</span></label>
                                <input type="text" formControlName="subject" placeholder="{{'lang_subject' | language}}" class="form-control almnabrformcontrol">
                                <mat-error
                                    *ngIf="submitted && form.controls['subject'].errors?.required">
                                    {{'lang_field_required' | language}}
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_from' | language}} <span class="red">*</span></label>
                                <input type="text" formControlName="transaction_from" placeholder="{{'lang_from' | language}}" class="form-control almnabrformcontrol">
                                <mat-error *ngIf="submitted  && form.controls['transaction_from'].errors?.required">
                                    {{'lang_field_required' | language}}
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_to_organization' | language}} <span class="red">*</span></label>
                                <ng-select 
                                    [items]="branches" 
                                    [multiple]="false" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    appendTo="body" 
                                    formControlName="transaction_to"
                                    placeholder="{{'lang_to_organization' | language}}">
                                </ng-select>
                                <mat-error
                                    *ngIf="submitted && form.controls['transaction_to'].errors?.required">
                                    {{'lang_field_required' | language}}
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_issued_date_english' | language}} <span class="red">*</span></label>
                                <input formControlName="issued_date_m" type="text" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD"/>
                                <mat-error *ngIf="submitted && form.controls['issued_date_m'].errors?.required"> {{'lang_field_required' | language}} </mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                            <div class="form-group">
                                <label> {{'lang_issued_date_arabic' | language}} <span class="red">*</span></label>
                                <input formControlName="issued_date_h" type="text" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD"/>
                                <mat-error *ngIf="submitted && form.controls['issued_date_h'].errors?.required"> {{'lang_field_required' | language}} </mat-error>
                            </div>
                        </div>                        
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_issued_number' | language}} <span class="red">*</span></label>
                                <input type="text" formControlName="issued_number" placeholder="{{'lang_issued_number' | language}}" class="form-control almnabrformcontrol">
                                <mat-error
                                    *ngIf="submitted && form.controls['issued_number'].errors?.required">
                                    {{'lang_field_required' | language}}
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_attachments' | language}} </label>
                                <input type="text" formControlName="attachmentstitle" placeholder="{{'lang_attachments' | language}}"
                                    class="form-control almnabrformcontrol">
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <label> {{'lang_communication_recipient' | language}} <span class="red">*</span> </label>
                                <ng-select 
                                    [items]="users" 
                                    [multiple]="false" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body" 
                                    formControlName="signature[C][user_id]"
                                    placeholder="{{'lang_communication_recipient' | language}}"
                                    (search)="search_users($event)"
                                    (focus)="users = []">
                                </ng-select>
                                <mat-error
                                    *ngIf="submitted && form.controls['signature[C][user_id]'].errors?.required">
                                    {{'lang_field_required' | language}}
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label> {{'lang_description' | language}} <span class="red">*</span></label>
                            <textarea type="text" formControlName="content" class="form-control almnabrformcontrol" rows="10"></textarea>
                            <mat-error
                                *ngIf="submitted && form.controls['content'].errors?.required">
                                {{'lang_field_required' | language}}
                            </mat-error>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-xs-12 col-sm-6 mb-10">
                            <fieldset class="cardback fieldset">
                                <legend>{{'lang_markers' | language}} </legend>
                                <ng-select 
                                    [items]="users" 
                                    [multiple]="true" 
                                    bindLabel="label" 
                                    bindValue="value" 
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body" 
                                    formControlName="marks"
                                    placeholder="{{'lang_markers' | language}}"
                                    (search)="search_users($event)"
                                    (focus)="users = []">
                                </ng-select>
                            </fieldset>
                        </div>
                        <div class="col-md-6 col-xs-12 col-sm-6 mb-10">
                            <fieldset class="cardback fieldset">
                                <legend>{{'lang_attachments' | language}} 
                                    <span *ngIf="!reuploadstatus">
                                        <mat-icon class="mnabricon" (click)="enable_upload_field()" matTooltip="{{'lang_upload_attachments' | language}}">cloud_upload</mat-icon>
                                    </span>
                                    <span *ngIf="reuploadstatus">
                                        <mat-icon class="mnabricon red" (click)="disable_upload_field()" matTooltip="{{'lang_clear' | language}}">block</mat-icon>
                                    </span>
                                </legend>
                                <div class="" *ngIf="!reuploadstatus">
                                    <div class="col-md-12 pad0">
                                        <div class="table-responsive customResponsive">
                                            <table border="1" class="table CustomTable">
                                                <thead>
                                                    <tr>
                                                        <td>#</td>
                                                        <td>{{'lang_title' | language}}</td>
                                                        <td>{{'lang_action' | language}}</td>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="fileslist.length > 0 " class="AppendList">
                                                    <tr *ngFor="let file of fileslist; let i = index;let odd=odd; let even=even"
                                                        [ngClass]="{ odd: odd, even: even }">
                                                        <td>{{i+1}}</td>
                                                        <td>{{file.form_c2_file_attach_title}}</td>
                                                        <td>
                                                            <mat-icon class="mnabricon" (click)="ds.PreviewData(file.link)">link</mat-icon>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="fileslist.length < 1 " class="AppendList">
                                                    <tr class="odd">
                                                        <td colspan="5" align="center">{{'lang_no_data' | language}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="attach" *ngIf="reuploadstatus">
                                    <div class="table-responsive customResponsive">
                                        <table border="1" class="table CustomTable" width="100%" formArrayName="attachments">
                                            <thead>
                                                <tr>
                                                    <td width="5%" style="padding: 5px;word-break: break-all;"> # </td>
                                                    <td width="5%" style="padding: 5px;word-break: break-all;">
                                                        <mat-icon class="mnabricon" matTooltip="{{'lang_add' | language }}" (click)="addfiles()">add_box</mat-icon>
                                                    </td>
                                                    <td width="45%" style="padding: 5px;word-break: break-all;">{{'lang_file' | language}} </td>
                                                    <td width="45%" style="padding: 5px;word-break: break-all;">{{'lang_title' | language}} </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let file of filesFormGroup.controls; let i = index;let odd=odd; let even=even"
                                                    [ngClass]="{ odd: odd, even: even }" [formGroupName]="i">
                                                    <td class="verticalalignmid" style="padding: 5px;word-break: break-all;">{{i+1}}</td>
                                                    <td class="verticalalignmid" style="padding: 5px;word-break: break-all;">
                                                        <mat-icon (click)="removefiles(i)" class="mnabricon red" matTooltip="{{'lang_delete' | language }}">delete_forever</mat-icon>
                                                    </td>
                                                    <td class="verticalalignmid" style="padding: 5px;word-break: break-all;" style="position: relative;">
                                                        <input style="display: block;" name="file" type="file" required onclick="this.value = null" (change)="UploadFile($event, i)" class="nghide albutton width100">
                                                        <label id="uploadText{{i}}" class="albutton mnabrbutton mat-accent" mat-button>{{filename}} </label>
                                                        <mat-error
                                                            *ngIf="this.attachments.controls[i].get('file').touched && this.attachments.controls[i].get('file').errors?.required">
                                                            {{'lang_field_required' | language}}
                                                        </mat-error>
                                                    </td>
                                                    <td style="padding: 5px;word-break: break-all;" style="position: relative;">
                                                        <input type="text" formControlName="attach_title"
                                                            placeholder="{{'lang_description' | language}}" autocomplete="off"
                                                            class="form-control almnabrformcontrol" />
                                                        <mat-error
                                                            *ngIf="this.attachments.controls[i].get('attach_title').touched && this.attachments.controls[i].get('attach_title').errors?.required">
                                                            {{'lang_field_required' | language}}
                                                        </mat-error>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
                            <button type="button" class="albutton mnabrbutton mat-accent" mat-button routerLink="/transactions/allforms">{{'lang_goto_list' | language }}</button>
                        </div>
                        <div class="col-md-8 col-sm-6 col-xs-12 mb-10">
                            <app-alert id="default-alert"></app-alert>
                        </div>
                        <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
                            <button type="submit" class="albutton mnabrbutton mat-accent" mat-button>{{'lang_submit' | language }}</button>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</form>