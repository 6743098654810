import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
interface list {
  id?: number;
  setting_id: string,
  settings_key: string,
  settings_val: string,
  module_name_key: string,
  settings_lang_key: string,
}
@Component({
  selector: 'app-files-module-settings',
  templateUrl: './files-module-settings.component.html',
  styleUrls: ['./files-module-settings.component.scss']
})
export class FilesModuleSettingsComponent implements OnInit { 
  modalRefA: BsModalRef;
  tabledatas: list[];
  form:FormGroup;
  @Output() actionEvent             = new EventEmitter<any>();
  collectionSize                    = 0;
  page                              = 1;
  pageSize                          = 10;
  searchKey                         = "";
  nodata: string                    = this.lang.transform('lang_loading');
  admin                             = JSON.parse(this.ls.getItem('is_admin'));
  wholeData:any                     = [];
  tabledata:any                     = [];
  modules:any                       = [];
  module_key:any                    = '';
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {    
    this.spinner.show();
    this.get_modules();
    this.get_file_settings();
    this.form = new FormGroup({
      setting_id            : new FormControl(''),
      settings_key          : new FormControl('', [Validators.required]),
      settings_val          : new FormControl('', [Validators.required]),
      module_name_key       : new FormControl('', [Validators.required]),
      settings_lang_key     : new FormControl('', [Validators.required]),
    });
  }
  public get_modules() {
    this.modules = [];
    this.ds.postActionByUrl([], 'module').subscribe(res => {
      if(res.status) {
        this.modules = res.records;
      }
    })
  }
  //add, edit model
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public get_file_settings() {
    let param = new FormData();
    param.append('search_key', this.searchKey || '');
    param.append('module_key', this.module_key || '');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'fsetting/list').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.wholeData      = res.records;
        this.tabledatas     = res.records;
        this.collectionSize = res.records.length;
        this.build_table_data();
      } else {
        this.wholeData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholeData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    }) 
  }
  public build_table_data() {
    this.tabledata = this.tabledatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  public edit_file_setting_data(row) {
    this.form.patchValue(row);
  }
  public submit() {
    this.spinner.show();
    if(this.form.get('setting_id').value) {
      this.ds.putActionByUrl(this.ds.formData2string(this.ds.json2formdata(this.form.value)), 'fsetting/update/'+this.form.get('setting_id').value).subscribe(res=> {
        this.spinner.hide();
        if(res.status) {
          this.get_file_settings();
          this.form.reset();
          this.alert.success(res.msg);
          setTimeout(() => {
            this.modalRefA.hide();
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'fsetting/create').subscribe(res=> {
        this.spinner.hide();
        if(res.status) {
          this.get_file_settings();
          this.form.reset();          
          this.alert.success(res.msg);
          setTimeout(() => {
            this.modalRefA.hide();
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    }
  }
  public checkallItemsFSetting(event) {
    if (event.target.checked) {
      $(".appfilesettings .eachItemFSetting").prop("checked", true);
      $(".appfilesettings .makeback").addClass("bground");
      $(".appfilesettings .deleteIconDiv").show();
    } else {
      $(".appfilesettings .eachItemFSetting").prop("checked", false);
      $(".appfilesettings .makeback").removeClass("bground");
      $(".appfilesettings .deleteIconDiv").hide();
    }
  }
  public checkeachItemFSetting(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appfilesettings .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appfilesettings .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appfilesettings .eachItemFSetting:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appfilesettings .eachItemFSetting").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appfilesettings .deleteIconDiv").show();
    } else {
      $(".appfilesettings .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appfilesettings #allItemsFSetting").prop("checked", true);
    } else {
      $(".appfilesettings #allItemsFSetting").prop("checked", false);
    }
  }
  public deleteBulkDataFSetting() {
    const checkedtotal = [];
    $(".appfilesettings .eachItemFSetting:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'fsetting/delete').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                
                this.get_file_settings();
                $(".appfilesettings #allItemsFSetting").prop("checked", false);
                $(".appfilesettings .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
