import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from './data.service';
import { PreloaderService } from './preloader.service';
@Injectable({
  providedIn: 'root'
})
export class PhrasesService {constructor(
  private ds: DataService,
  private spinner : PreloaderService
) {}
load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      const defaultLang = (!localStorage.getItem('lang_key') && localStorage.getItem('lang_key') == undefined) ? "en" : localStorage.getItem('lang_key');
      this.ds.get('lang/'+defaultLang+'.json').pipe(
          catchError(res => {
            resolve(null);
            return throwError(res);
          })
        ).subscribe((res: any) => {
            this.ds.phrases = {...res};
            this.spinner.hide();
          },() => {
            this.spinner.hide();
            reject();
          },() => {
            this.spinner.hide();
            resolve(null);
          }
        );
    });
  }
}