<div class="card appmodules">                                          
    <div class="card-header">{{'lang_modules' | language }} </div>
    <div class="card-body p-0">
      <div class="mbrl15 martb0">
          <div class="row">
              <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                <select class="form-control almnabrformcontrol" (change)="spinner.show(); search_employee_modules();" [(ngModel)]="module_filter" matTooltip="{{'lang_modules' | language }}">
                    <option *ngFor="let module of modules" value="{{module.module_key}}">{{module.module_phrase_key | language }}</option>
                    <option value="" selected>{{'lang_modules' | language }} - {{'lang_all' | language }}</option>
                </select>
              </div>
              <div class="col-md-9 col-sm-6 col-xs-12 mb-10">
                <input type="text" class="form-control almnabrformcontrol" (keyup)="search_employee_modules();" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
              </div>
          </div>
          <div class="row">
              <div class="table-responsive dropdownyes customResponsive col-12">
                  <table border="1" class="table CustomTable">
                      <thead>
                          <tr>
                              <td>#</td>
                              <td>{{'lang_module_name' | language }}</td>
                              <td>{{'lang_module_ids' | language }}</td>
                              <td>{{'lang_writer' | language }}</td>
                              <td>{{'lang_ondate' | language }}</td>
                              <td>{{'lang_action' | language }}</td>
                          </tr>
                      </thead>
                      <tbody *ngIf="wholedata.length != 0 " class="AppendList">
                          <tr *ngFor="let mod of wholedata.records; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{mod.contract_id}}">
                              <td>{{i+1}}</td>
                              <td>{{mod.modulename}}</td>
                              <td>{{mod.private_value}}</td>
                              <td >{{mod.writer}}</td>
                              <td>{{mod.create_date}}</td>
                              <td *ngIf="requestfor != 'PROFILE' ">
                                  <i  matTooltip="{{'lang_view' | language }}" class="fa fa-eye fa-icon mnabricon s2icon" (click)="get_users_on_this_modules(mod, ModuleUsers);"></i>
                              </td>
                              <td *ngIf="requestfor == 'PROFILE' ">
                                ----
                            </td>
                          </tr>
                      </tbody>
                      <tbody *ngIf="wholedata.length == 0 " class="AppendList">
                          <tr class="odd">
                              <td colspan="6" align="center">{{errormessagef}}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
      <div *ngIf="wholedata.length != 0 " class="card-footer pb-0 customborder">
          <div class="row">
              <div class="col-lg-3 col-sm-6 col-12  mb-10">
                  {{'lang_total' | language }} {{wholedata.page.total_records}}
              </div>
              <div class="col-lg-5 col-sm-6 col-12 mb-10">
                  <mat-radio-group aria-label="Select an option">
                      <mat-radio-button value="10" checked (click)="spinner.show();load_page_size(10)"> 10 </mat-radio-button>
                      <mat-radio-button value="20" (click)="spinner.show();load_page_size(20)"> 20 </mat-radio-button>
                      <mat-radio-button value="50" (click)="spinner.show();load_page_size(50)"> 50 </mat-radio-button>
                      <mat-radio-button value="100" (click)="spinner.show();load_page_size(100)"> 100 </mat-radio-button>
                      <mat-radio-button value="500" (click)="spinner.show();load_page_size(500)"> 500 </mat-radio-button>
                  </mat-radio-group>
              </div>
              <div class="col-lg-4 col-sm-6 col-12 mb-10">
                  {{'lang_goto' | language }}
                  <select class="customselect" (change)="spinner.show();page_length($event.target.value)">
                      <option *ngFor="let k of ds.getrange(wholedata.page.total_pages)" value="{{k}}">{{k}}</option>
                  </select>
              </div>
          </div>
      </div>
    </div>
</div>
<ng-template #ModuleUsers>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_users' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefV.hide()">&times;</button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="table-responsive dropdownyes customResponsive col-12">
                    <table border="1" class="table CustomTable">
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>{{'lang_name' | language }}</td>
                                <td>{{'lang_title' | language }}</td>
                                <td>{{'lang_position' | language }}</td>
                                <td *ngIf="moduledata && moduledata.module_key =='projects' ">{{'lang_service_position' | language }}</td>
                                <td *ngIf="moduledata && moduledata.module_key =='projects' ">{{'lang_service_project_name' | language }}</td>
                            </tr>
                        </thead>
                        <tbody *ngIf="moduleusers.length != 0 " class="AppendList">
                            <tr *ngFor="let mod of moduleusers; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }">
                                <td>{{i+1}}</td>
                                <td>{{mod.name}}</td>
                                <td *ngIf="mod.private_value =='all' ">{{'lang_all' | language}}</td>
                                <td *ngIf="mod.private_value !='all' && mod.title ">{{mod.title}}</td>
                                <td *ngIf="mod.private_value !='all' && !mod.title ">----</td>
                                <td>{{mod.groupname}}</td>
                                <td *ngIf="moduledata && moduledata.module_key =='projects' && mod.project_group_name ">{{mod.project_group_name}}</td>
                                <td *ngIf="moduledata && moduledata.module_key =='projects' && !mod.project_group_name ">----</td>
                                <td *ngIf="moduledata && moduledata.module_key =='projects' && mod.quotation_subject">{{mod.quotation_subject}}</td>
                                <td *ngIf="moduledata && moduledata.module_key =='projects' && !mod.quotation_subject">----</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="card-footer pad15">
            <div class="row">
                <div class="col-md-9 col-xs-12 mb-10"></div>
                <div class="col-md-3 col-xs-12 mb-10">
                    <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefV.hide()">{{'lang_close' | language }}</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
