import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '@env/environment';
import crypto from 'crypto-js';
@Injectable()
export class LocalStorage {
    constructor(@Inject(PLATFORM_ID) private platformId: Object) { }
    public clear(): void {
        localStorage.clear();
    }
    public clear_custom(): void {
        localStorage.removeItem('is_admin');
        localStorage.removeItem('loggedin');
        localStorage.removeItem('token');
        localStorage.removeItem('user_email');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_phone');
        localStorage.removeItem('user_type_id');
        localStorage.removeItem('user_username');
    }   
    public getItemString(key: string): string {
        return this.secureData('decrypt', localStorage.getItem(key));
    }
    public getItem(key: string) {
            return this.secureData('decrypt', localStorage.getItem(key));
    }
    public removeItem(key: string): void {
        localStorage.removeItem(key);
    }
    public setItem(key: string, data: string): void {
        localStorage.setItem(key, this.secureData("encrypt", data));
    }
    public getsetdata(key, value) {
        localStorage.setItem(key, this.secureData("encrypt", value));
        return localStorage.getItem(key);
    }
    private secureData(conversion: string, value: any) {
        if (value && value != null) {
            if (conversion == "encrypt") {
                return crypto.AES.encrypt(value, environment.encryptionPassword).toString();
            } else {
                return crypto.AES.decrypt(value, environment.encryptionPassword).toString(crypto.enc.Utf8);
            }
        }
    }
}