import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-rstep9',
  templateUrl: './rstep9.component.html',
  styleUrls: ['./rstep9.component.scss']
})
export class Rstep9Component implements OnInit {
  @Input() signupForm: FormGroup;
  @Input() action;
  @Input() files;
  lang_key = localStorage.getItem('lang_key') || 'en';
  constructor(private cdRef: ChangeDetectorRef, public fb:FormBuilder ,public ds: DataService, public route:ActivatedRoute ,public ls: LocalStorage, public lang: LanguagePipe, public spinner: PreloaderService, public alert: AlertService, public router: Router, public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,  public modalService: BsModalService) {}
  ngOnInit(): void {
  }
}
