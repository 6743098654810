<div class="card appnotes">
    <div class="card-header">{{'lang_notes' | language }} </div>
    <div class="card-body p-0">
      <div class="mbrl15 martb0">
          <div class="row">
              <div class="col-md-3 col-sm-6 col-xs-12 mb-10" *ngIf="requestfor !== 'profile' ">
                  <select class="form-control almnabrformcontrol searchStatus" (change)="spinner.show();search_my_notes()" matTooltip="{{'lang_status' | language }}" [(ngModel)]="searchStatus">
                      <option value="">{{'lang_all' | language }}</option>
                      <option value="1">{{'lang_public' | language }}</option>
                      <option value="0">{{'lang_private' | language }}</option>
                  </select>
              </div>
              <div [ngClass]="{'col-md-9 col-sm-6 col-xs-12 mb-10': requestfor !== 'profile', 'col-12 mb-10': requestfor === 'profile'}">
                  <div class="input-group input-group-sm">
                      <input type="text" class="form-control almnabrformcontrol" (keyup)="search_my_notes()" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                      <span class="input-group-btn" *ngIf="requestfor !== 'profile' && user_data.employee_status == '1'  && able2add ">
                          <button class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" id="btnSearch" type="button"  (click)="openModalA(addNewNotes);emptyNoteForm();" matTooltip="{{'lang_add_notes' | language }}"></button>
                      </span>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="table-responsive dropdownyes customResponsive col-12">
                  <table border="1" class="table CustomTable">
                      <thead>
                          <tr>
                              <td>#</td>
                              <td *ngIf="requestfor !== 'profile' ">
                                  <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                      <input type="checkbox" class="allItemsNote checkbox" id="allItemsNote" (click)="checkAllNote($event)"/>
                                      <label for="checkbox"></label>
                                  </div>
                              </td>
                              <td>{{'lang_description' | language }}</td>
                              <td>{{'lang_remainder_date' | language }}</td>
                              <td>{{'lang_status' | language }}</td>
                              <td>{{'lang_writer' | language }}</td>
                              <td>{{'lang_action' | language }}</td>
                          </tr>
                      </thead>
                      <tbody *ngIf="notedata.length != '0'" class="AppendList">
                          <tr *ngFor="let note of notedata.records; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{note.note_id}}">
                              <td>{{i+1}}</td>
                              <td *ngIf="requestfor !== 'profile' ">
                                      <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                          <input type="checkbox"  class="eachItemNote checkbox" value="{{note.note_id}}" (click)="checkEachItemNote($event)"/>
                                          <label for="checkbox"></label>
                                      </div>
                              </td>
                              <td>
                                  <span matTooltip="{{note.note_description}}" class="breakall">{{note.note_description}}</span>
                              </td>
                              <td *ngIf="note.note_remainder_status == '1'">{{note.note_remainder_date}}</td>
                              <td *ngIf="note.note_remainder_status == '0'">----</td>
                              <td *ngIf="note.show_status == '1'">
                                  <i matTooltip="{{'lang_public' | language }}" class="fa fa-circle green fa-icon pad0"></i>
                              </td>
                              <td *ngIf="note.show_status == '0'">
                                  <i matTooltip="{{'lang_private' | language }}" class="fa fa-circle red fa-icon pad0"></i>
                              </td>
                              <td *ngIf="!note.name">{{note.username}}</td>
                              <td *ngIf="note.name">{{note.name}}</td>
                              <td>
                                  <i matTooltip="{{'lang_view' | language }}" class="fa fa-eye fa-icon mnabricon s2icon" (click)="openModalV(viewNoteModal);view_my_notes(note)"></i>
                                  <i *ngIf="able2edit && user_data.employee_status == '1' "  matTooltip="{{'lang_edit' | language }}" class="fa fa-pencil-square-o fa-icon mnabricon s2icon" (click)="openModalA(addNewNotes);get_notes_for_edit(note);"></i>
                              </td>
                          </tr>
                      </tbody>
                      <tbody *ngIf="notedata.length == '0'" class="AppendList">
                          <tr class="odd">
                              <td colspan="7" align="center">{{noteerrormsg}}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
      <div *ngIf="notedata.length != '0'" class="card-footer padb0 bottomfooter">
          <div class="row">
              <div *ngIf="able2delete && requestfor !== 'profile' " class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                  <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataNote()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
              </div>
              <div class="col-lg-2 col-sm-6 col-6  mb-10">
                  {{'lang_total' | language }} : {{notedata.page.total_records}}
              </div>
              <div class="col-lg-5 col-sm-6 col-12 mb-10">
                  <mat-radio-group aria-label="Select an option">
                      <mat-radio-button value="10" checked (click)="spinner.show();notepage(10)"> 10 </mat-radio-button>
                      <mat-radio-button value="20" (click)="spinner.show();notepage(20)"> 20 </mat-radio-button>
                      <mat-radio-button value="50" (click)="spinner.show();notepage(50)"> 50 </mat-radio-button>
                      <mat-radio-button value="100" (click)="spinner.show();notepage(100)"> 100 </mat-radio-button>
                      <mat-radio-button value="500" (click)="spinner.show();notepage(500)"> 500 </mat-radio-button>
                  </mat-radio-group>
              </div>
              <div class="col-lg-4 col-sm-6 col-12 mb-10">
                  {{'lang_goto' | language }} :
                  <select class="customselect" (change)="spinner.show();notelength($event.target.value)">
                      <option *ngFor="let k of ds.getrange(notedata.page.total_pages)" value="{{k}}"> {{k}}</option>
                  </select>
              </div>
          </div>
      </div>
    </div>
</div>
<ng-template #addNewNotes>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_add_notes' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefA.hide();">&times;</button>
        </div>
        <form [formGroup]="noteform" (ngSubmit)="note_operations()">
            <div class="modal-body">            
                <input type="hidden" id="note_id" formControlName="note_id">
                <div class="form-group">
                    <label for="note_description">{{'lang_description' | language }}:<span class="red">*</span></label>
                    <textarea type="text" required id="note_description" class="form-control almnabrformcontrol note_description" placeholder="{{'lang_description' | language }}" formControlName="note_description"></textarea>
                    <mat-error *ngIf="noteform.controls['note_description'].touched && noteform.controls['note_description'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="note_remainder_status">{{'lang_remainder_status' | language }}:<span class="red">*</span></label>
                    <select required id="note_remainder_status" class="form-control almnabrformcontrol note_remainder_status" matTooltip="{{'lang_remainder_status' | language }}" formControlName="note_remainder_status">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option value="1">{{'lang_yes' | language }}</option>
                        <option value="0">{{'lang_no' | language }}</option>
                    </select>
                    <mat-error *ngIf="noteform.controls['note_remainder_status'].touched && noteform.controls['note_remainder_status'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group" *ngIf="noteform.get('note_remainder_status').value == '1'">
                    <label for="note_remainder_date">{{'lang_remainder_date' | language }} : <span class="red">*</span></label>
                    <div class="input-group input-group-sm">
                        <input [matDatepicker]="note_remainder_dateU" formControlName="note_remainder_date" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly>
                        <span class="input-group-btn">
                            <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="note_remainder_dateU"></mat-datepicker-toggle>
                            <mat-datepicker #note_remainder_dateU></mat-datepicker>
                        </span>
                        <span class="input-group-btn">
                            <button (click)="noteform.get('note_remainder_date').setValue('');" matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                        </span>
                    </div>
                    <mat-error *ngIf="noteform.controls['note_remainder_date'].touched && noteform.controls['note_remainder_date'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="show_status">{{'lang_status' | language }}:<span class="red">*</span></label>
                    <select required id="show_status" class="form-control almnabrformcontrol show_status" matTooltip="{{'lang_status' | language }}" formControlName="show_status">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option value="1">{{'lang_public' | language }}</option>
                        <option value="0">{{'lang_private' | language }}</option>
                    </select>
                    <mat-error *ngIf="noteform.controls['show_status'].touched && noteform.controls['show_status'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="show_status">{{'lang_link_for_lists' | language }}:<span class="red">*</span></label>
                    <select required class="form-control almnabrformcontrol link_with_view_list" matTooltip="{{'lang_status' | language }}" formControlName="link_with_view_list">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option value="1">{{'lang_yes' | language }}</option>
                        <option value="0">{{'lang_no' | language }}</option>
                    </select>
                    <mat-error *ngIf="noteform.controls['link_with_view_list'].touched && noteform.controls['link_with_view_list'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefA.hide();">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #viewNoteModal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_notes' | language }}</h4>
            <button type="button" class="close" (click)="modalRefV.hide();">&times;</button>
        </div>
        <div class="modal-body" *ngIf="noteviewdata">
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_description' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{noteviewdata.note_description}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_remainder_date' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngIf="noteviewdata.note_remainder_date">{{noteviewdata.note_remainder_date}}</b>
                        <b *ngIf="!noteviewdata.note_remainder_date">----</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_status' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngIf="noteviewdata.show_status =='0'" class="red">{{'lang_private' | language }}</b>
                        <b class="breakall" *ngIf="noteviewdata.show_status =='1'" class="green">{{'lang_public' | language }}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_link_for_lists' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngIf="noteviewdata.link_with_view_list =='1'">{{'lang_yes' | language }}</b>
                        <b class="breakall" *ngIf="noteviewdata.link_with_view_list =='0'">{{'lang_no' | language }}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_writer' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngIf="noteviewdata.name">{{noteviewdata.name}}</b>
                        <b class="breakall" *ngIf="!noteviewdata.name">{{noteviewdata.username}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_created_date' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{noteviewdata.note_created_date}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_onupdate' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{noteviewdata.note_updated_date}}</b>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modalRefV.hide();">{{'lang_close' | language }}</button>
        </div>
    </div>
</ng-template>