import { ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { ToggleMenuService } from '@core/bootstrap/toggle-menu.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { DashboardService } from 'app/routes/dashboard/dashboard.srevice';
import * as echarts from 'echarts';
import { CompeletionRateFiltersComponent } from './compeletion-rate-filters/compeletion-rate-filters.component';
import { PriceFiltersComponent } from './price-filters/price-filters.component';

@Component({
  selector: 'app-supervision-main-dashboard',
  templateUrl: './supervision-main-dashboard.component.html',
  styleUrls: ['./supervision-main-dashboard.component.scss'],
  styles: [
    `
      .mat-raised-button {
        margin-right: 8px;
        margin-top: 8px;
      }
    `,
  ],
  
  providers: [DashboardService],
})
export class SupervisionMainDashboardComponent implements OnInit {
  public view;
  public project_profile_id       = this.route.snapshot.paramMap.get("pid");
  public project_supervision_id   = this.route.snapshot.paramMap.get("sid");
  public wholedata: any           = [];
  public supervision_data:any     = [];
  project_setting_data;
  IPhasesCountData;
  count_data_loading = true;
  project_total_ratio;
  total_opening;
  total_weight_result;
  from_date_compeletion;
  to_date_compeletion;
  average_division_data;
  average_zone_data;
  price_division_data;
  price_zone_data;
  lang_key = localStorage.getItem('lang_key') || 'en';
  formsChart:any;
  CRByDivisionChart:any;
  averageZoneChart1:any;
  averageZoneChart2:any;
  averageDivisionChart1:any;
  averageDivisionChart2:any;
  zonePriceChart:any;
  divisionPriceChart:any;
  projectTimeChart:any;
  implementationPhasesChart:any;
  axis3Chart:any;
  wir_data = {};
  total_construction_cost:any;
  total_project_price:any;
  template_price:any;
  is_price_allow    = false;
  completion_filters = {
    from_date: null,
    to_date:null,
    phase_zone_no:null,
    platform_group1_code_system:null,
    with_balance:null
  }
  constructor(
    public dashboardSrv: DashboardService,
    public alert : AlertService,
    public lang:LanguagePipe,
    public ds: DataService,
    public route: ActivatedRoute,
    public spinner: PreloaderService,
    public router: Router,
    private dialog:MatDialog,
    private sweetAlert: SweetAlertService,
    private toggleMenu:ToggleMenuService
  ) {
    this.project_supervision_data();
  }
  ngOnInit(): void {
    
  }


  ngAfterViewInit(): void {
    this.initFormsChart();
    this.initAverageZoneChart1();
    this.initAverageZoneChart2();
    this.initAverageDivisionChart1();
    this.initAverageDivisionChart2();
    this.initProjectTimeChart();
    this.initAxis3Chart();

    window.onresize = () => {
      this.formsChart.resize();
      this.averageZoneChart1.resize();
      this.averageZoneChart2.resize();
      this.averageDivisionChart1.resize();
      this.averageDivisionChart2.resize();
      this.projectTimeChart.resize();
      this.axis3Chart.resize();
      if(this.is_price_allow) {
        this.zonePriceChart.resize();
        this.divisionPriceChart.resize();
      }
      
    }
    this.toggleMenu.isMob.subscribe(res => {
      setTimeout(() => {
        this.formsChart.resize();
        this.averageZoneChart1.resize();
        this.averageZoneChart2.resize();
        this.averageDivisionChart1.resize();
        this.averageDivisionChart2.resize();
        this.projectTimeChart.resize();
        this.axis3Chart.resize();
        if(this.is_price_allow) {
          this.zonePriceChart.resize();
          this.divisionPriceChart.resize();
        }
      }, 201);
    })
    
  }
  public project_supervision_data() {
    let url = [ 
      "TEd1bgyHSC0GPcq/" + this.project_profile_id, 
      "5mOjlwsszk1lds4/" + this.project_profile_id + "/" + this.project_supervision_id
    ];
    this.spinner.show();
    this.ds.forkJoin(url, []).subscribe( (res) => {
        this.spinner.hide();
        if (res[0].status && res[1].status) {
          this.wholedata          = res[0];
          this.view               = res[0].view;
          this.supervision_data   = res[1].records;
          this.getCountData();
          this.getMonthlyData();
          this.getAverageTotalData();
          this.getAverageZonesData();
          this.getAverageDivisionsData();
          this.getPriceTotalData();
          this.getPriceZonesData();
          this.getPriceDivisionsData();
          
        }
      }, (error) => {
        this.spinner.hide();
        this.ds.dialogf("", (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
        this.router.navigate(["projects/BbVUrWitLE"]);
      }
    );
  }

  getCountData() {
    let formData = new FormData();
    formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id ||'');
    this.count_data_loading = true;
    this.ds.post(`pr/dashboard_counts/${this.project_profile_id}/${this.project_supervision_id}`, formData).subscribe(res => {
      this.count_data_loading = false;
      if(res?.status) {
        this.project_setting_data = res?.result?.project_setting;
        this.total_construction_cost = res?.result?.project_setting?.total_construction_cost;
        this.wir_data             = res?.result?.wir_data;
        this.updateFormsChart({WIR: this.wir_data});
        
        this.updateProjectTimeChart({
          date_from        : this.project_setting_data?.supervision_start_date, 
          date_to          : this.project_setting_data?.supervision_expiry_date,
          done_days        : this.customizeFraction(this.project_setting_data?.done_days),
          left_days        : this.customizeFraction(this.project_setting_data?.left_days),
          total_days       : this.customizeFraction(this.project_setting_data?.total_days),
          late_days        : this.customizeFraction(this.project_setting_data?.late_days),
          average_done_days: this.customizeFraction(this.project_setting_data?.average_done_days),
          average_left_days: this.customizeFraction(this.project_setting_data?.average_left_days)
        }); 
      }
    })
  }

  getMonthlyData() {
    let formData = new FormData();
    formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id ||'');
    this.ds.post(`pr/plan_results/${this.project_profile_id}/${this.project_supervision_id}`, formData).subscribe(res => {
      if(res?.status) {
        let customData = res?.result?.map((el, index) => {
          let actual_data = res?.result?.slice(0, index + 1);
          let actual_numbers = actual_data?.map(item => {
            if(item?.monthly_actual_total) {
              return parseFloat(item?.monthly_actual_total);
            } else {
              return 0.00;
            }
          });
          
          
          let sum_actual_total = actual_numbers.reduce((accumulator, a) => {
              return accumulator + a;
          }, 0);
          return {
            month_plan_setting       : el?.month_plan_setting,
            monthly_total_plan       : el?.monthly_total_plan ? this.customizeFraction(el?.monthly_total_plan) : 0.00,
            sum_total_plan           : el?.sum_total_plan ? this.customizeFraction(el?.sum_total_plan) : 0.00,
            monthly_actual_total     : el?.monthly_actual_total ? this.customizeFraction(el?.monthly_actual_total) : 0.00,
            monthly_sum_actual_total : sum_actual_total ? this.customizeFraction(sum_actual_total + '') : 0.00
          }
        })
        this.updateAxis3Chart(customData);
      }
    })
  }


  getAverageTotalData(data?, filter?) {
    let formData = new FormData(); 
    formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id ||'');
    formData.append('from_date', data?.from_date || '');
    formData.append('to_date', data?.to_date || '');
    formData.append('phase_zone_no', data?.phase_zone_no  || '');
    formData.append('platform_group1_code_system', data?.platform_group1_code_system || '');
    this.ds.post(`pr/dashboard_average/${this.project_profile_id}/${this.project_supervision_id}`, formData).subscribe(res => {
      if(res?.status) {
        this.from_date_compeletion = res?.result?.dates?.from_date;
        this.to_date_compeletion = res?.result?.dates?.to_date;
        let total_no = res?.result?.avarage_complete_work?.project_total_ratio?.split('.');
        if(total_no) {
          if(total_no?.length > 1) {
            if(total_no[1]?.length > 1) {
              this.project_total_ratio = total_no[0] + '.' + total_no[1][0] + total_no[1][1];
            } else {
              this.project_total_ratio = total_no[0] + '.' + total_no[1][0]
            }
          } else {
            this.project_total_ratio = total_no[0]
          }
        } else {
          this.project_total_ratio = 0;
        }
        let total_opening_no = res?.result?.avarage_complete_work?.total_opening?.split('.');
        if(total_opening_no) {
          if(total_opening_no?.length > 1) {
            if(total_opening_no[1]?.length > 1) {
              this.total_opening = total_opening_no[0] + '.' + total_opening_no[1][0] + total_opening_no[1][1];
            } else {
              this.total_opening = total_opening_no[0] + '.' + total_opening_no[1][0]
            }
          } else {
            this.total_opening = total_opening_no[0]
          }
        } else {
          this.total_opening = 0
        }
        let total_weight_no = res?.result?.avarage_complete_work?.total_weight_result?.split('.');
        if(total_weight_no) {
          if(total_weight_no?.length > 1) {
            if(total_weight_no[1]?.length > 1) {
              this.total_weight_result = total_weight_no[0] + '.' + total_weight_no[1][0] + total_weight_no[1][1];
            } else {
              this.total_weight_result = total_weight_no[0] + '.' + total_weight_no[1][0]
            }
          } else {
            this.total_weight_result = total_weight_no[0]
          }
        } else {
          this.total_weight_result = 0
        }
      } else {
        this.project_total_ratio = 0;
      }
    })
  }
  getAverageZonesData(data?, filter?) {
    let formData = new FormData(); 
    formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id ||'');
    formData.append('from_date', data?.from_date || '');
    formData.append('to_date', data?.to_date || '');
    formData.append('phase_zone_no', data?.phase_zone_no  || '');
    formData.append('platform_group1_code_system', data?.platform_group1_code_system || '');
    this.ds.post(`pr/dashboard_average_zones/${this.project_profile_id}/${this.project_supervision_id}`, formData).subscribe(res => {
      if(res?.status) {
        this.from_date_compeletion = res?.result?.dates?.from_date;
        this.to_date_compeletion = res?.result?.dates?.to_date;
        this.average_zone_data     = res?.result?.zone_result;
        let customZones1 = this.average_zone_data?.map(el => {
          let zone_ratio = '0';
          if(el?.ratio) {
            let numbers = el?.ratio?.split('.');
            if(numbers.length > 1) {
              if(numbers[1].length >= 2) {
                zone_ratio = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                zone_ratio = numbers[0] + '.' + numbers[1][0]
              }
            } else {
              zone_ratio = numbers[0]
            }
          }
          let zone_opening_balance = '0';
          if(el?.zone_opnening_blanance) {
            let numbers = el?.zone_opnening_blanance?.split('.');
            if(numbers.length > 1) {
              if(numbers[1].length >= 2) {
                zone_opening_balance = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                zone_opening_balance = numbers[0] + '.' + numbers[1][0]
              }
            } else {
              zone_opening_balance = numbers[0]
            }
          }
          let zone_weight_result = '0';
          if(el?.zone_weight_result) {
            let numbers = el?.zone_weight_result?.split('.');
            if(numbers.length > 1) {
              if(numbers[1].length >= 2) {
                zone_weight_result = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                zone_weight_result = numbers[0] + '.' + numbers[1][0]
              }
            } else {
              zone_weight_result = numbers[0]
            }
          }
          let zone_left_ratio = '0';
          if(el?.left_ratio) {
            let numbers = el?.left_ratio?.split('.');
            if(numbers.length > 1) {
              if(numbers[1].length >= 2) {
                zone_left_ratio = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                zone_left_ratio = numbers[0] + '.' + numbers[1][0]
              }
            } else {
              zone_left_ratio = numbers[0]
            }
          }
          return {
            zone_no                 : el?.phase_zone_no,
            zone_opening_balance    : zone_opening_balance,
            zone_weight_result      : zone_weight_result,
            zone_ratio              : zone_ratio,
            zone_left_ratio         : zone_left_ratio
          }
        });
        let customZones2 = this.average_zone_data?.map(el => {
          let zone_per_project = '0';
          if(el?.zone_per_project) {
            let numbers = el?.zone_per_project?.split('.');
            if(numbers.length > 1) {
              if(numbers[1]?.length > 2) {
                zone_per_project = numbers[0] + '.' + numbers[1][0] + numbers[1][1] + numbers[1][2];
              } else if(numbers[1]?.length > 1) {
                zone_per_project = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                zone_per_project = numbers[0] + '.' + numbers[1][0]
              }
            } else {
              zone_per_project = numbers[0]
            }
            
          }
          let total_per_project = '0';
          if(el?.total_average_zone_per_project) {
            let numbers = el?.total_average_zone_per_project?.split('.');
            if(numbers?.length > 1) {
              if(numbers[1]?.length > 2) {
                total_per_project = numbers[0] + '.' + numbers[1][0] + numbers[1][1] + numbers[1][2];
              } else if(numbers[1]?.length > 1) {
                total_per_project = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                total_per_project = numbers[0] + '.' + numbers[1][0];
              }
            } else {
              total_per_project = numbers[0]
            }
          }
          let zone_opnening_per_project = '0';
          if(el?.zone_opening_per_project) {
            let numbers = el?.zone_opening_per_project?.split('.');
            if(numbers?.length > 1) {
              if(numbers[1]?.length > 2) {
                zone_opnening_per_project = numbers[0] + '.' + numbers[1][0] + numbers[1][1] + numbers[1][2];
              } else if(numbers[1]?.length > 1) {
                zone_opnening_per_project = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                zone_opnening_per_project = numbers[0] + '.' + numbers[1][0];
              }
            } else {
              zone_opnening_per_project = numbers[0]
            }
          }
          let zone_weight_per_project = '0';
          if(el?.zone_result_per_project) {
            let numbers = el?.zone_result_per_project?.split('.');
            if(numbers?.length > 1) {
              if(numbers[1]?.length > 2) {
                zone_weight_per_project = numbers[0] + '.' + numbers[1][0] + numbers[1][1] + numbers[1][2];
              } else if(numbers[1]?.length > 1) {
                zone_weight_per_project = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                zone_weight_per_project = numbers[0] + '.' + numbers[1][0];
              }
            } else {
              zone_weight_per_project = numbers[0]
            }
          }
          return {
            zone_no                   : el?.phase_zone_no,
            zone_per_project          : zone_per_project,
            total_per_project         : total_per_project,
            zone_opnening_per_project : zone_opnening_per_project,
            zone_weight_per_project   : zone_weight_per_project
          }
        });
        this.updateAverageZoneChart1(customZones1, filter);
        this.updateAverageZoneChart2(customZones2, filter);
      } else {
        this.average_zone_data     = [];
      }
    })
  }
  getAverageDivisionsData(data?, filter?) {
    let formData = new FormData(); 
    formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id ||'');
    formData.append('from_date', data?.from_date || '');
    formData.append('to_date', data?.to_date || '');
    formData.append('phase_zone_no', data?.phase_zone_no  || '');
    formData.append('platform_group1_code_system', data?.platform_group1_code_system || '');
    this.ds.post(`pr/dashboard_average_divisions/${this.project_profile_id}/${this.project_supervision_id}`, formData).subscribe(res => {
      if(res?.status) {
        this.from_date_compeletion = res?.result?.dates?.from_date;
        this.to_date_compeletion = res?.result?.dates?.to_date;
        this.average_division_data = res?.result?.division_result;
        let customDivision1 = this.average_division_data?.map(el => {
          let average_per_division = '0';
          if(el?.average_per_division) {
            let numbers = el?.average_per_division?.split('.');
            if(numbers?.length > 1) {
              if(numbers[1]?.length > 1) {
                average_per_division = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                average_per_division = numbers[0] + '.' + numbers[1][0]
              }
            } else {
              average_per_division = numbers[0]
            }
            
          }
          
          let left_average_per_division = '0';
          if(el?.left_average_per_division) {
            let numbers = el?.left_average_per_division?.split('.');
            if(numbers?.length > 1) {
              if(numbers[1]?.length > 1) {
                left_average_per_division = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                left_average_per_division = numbers[0] + '.' + numbers[1][0]
              }
            } else {
              left_average_per_division = numbers[0]
            }
          }
          
          return {
            division_name: this.lang_key == 'ar' ? el?.platform_group1_title_arabic : el?.platform_group1_title_english,
            average_per_division             : average_per_division,
            left_average_per_division        : left_average_per_division
          }
        });
        let customDivision2 = this.average_division_data?.map(el => {
          let ratio_per_division = '0';
          if(el?.division_ratio_per_project) {
            let numbers = el?.division_ratio_per_project?.split('.');
            if(numbers?.length > 1) {
              if(numbers[1]?.length > 2) { 
                ratio_per_division = numbers[0] + '.' + numbers[1][0] + numbers[1][1] + numbers[1][2];
              } else if(numbers[1]?.length > 1) {
                ratio_per_division = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                ratio_per_division = numbers[0] + '.' + numbers[1][0]
              }
            } else {
              ratio_per_division = numbers[0]
            }
            
          }
          
          let total_ratio_per_division = '0';
          if(el?.total_division_ratio_per_project) {
            let numbers = el?.total_division_ratio_per_project?.split('.');
            if(numbers?.length > 1) {
              if(numbers[1]?.length > 2) {
                total_ratio_per_division = numbers[0] + '.' + numbers[1][0] + numbers[1][1] + numbers[1][2];
              } else if(numbers[1]?.length > 1) {
                total_ratio_per_division = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                total_ratio_per_division = numbers[0] + '.' + numbers[1][0]
              }
            } else {
              total_ratio_per_division = numbers[0] 
            }
          }
          
          return {
            division_name: this.lang_key == 'ar' ? el?.platform_group1_title_arabic : el?.platform_group1_title_english,
            ratio_per_division             : ratio_per_division,
            total_ratio_per_division       : total_ratio_per_division
          }
        });
        this.updateAverageDivisionChart1(customDivision1);
        this.updateAverageDivisionChart2(customDivision2);
      } else {
        this.average_division_data = [];
      }
    })
  }

  getPriceTotalData(data?) {
    let formData = new FormData();
    formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id ||'');
    formData.append('from_date', data?.from_date || '');
    formData.append('to_date', data?.to_date || '');
    formData.append('phase_zone_no', data?.phase_zone_no  || '');
    formData.append('platform_group1_code_system', data?.platform_group1_code_system || '');
    this.ds.post(`pr/dashboard_price/${this.project_profile_id}/${this.project_supervision_id}`, formData).subscribe(res => {
      if(res?.status) {
        this.is_price_allow = true;
        this.total_project_price     = res?.result?.avarage_complete_work?.total_project_price;
        this.template_price          =  res?.result?.total_template;
      } else {
        this.is_price_allow = false;
      }
    })
  }

  getPriceZonesData(data?) {
    let formData = new FormData();
    formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id ||'');
    formData.append('from_date', data?.from_date || '');
    formData.append('to_date', data?.to_date || '');
    formData.append('phase_zone_no', data?.phase_zone_no  || '');
    formData.append('platform_group1_code_system', data?.platform_group1_code_system || '');
    this.ds.post(`pr/dashboard_price_zones/${this.project_profile_id}/${this.project_supervision_id}`, formData).subscribe(res => {
      if(res?.status) {
        this.price_zone_data         = res?.result?.zone_result;
        let customZonePrice          = this.price_zone_data?.map(el => {
          return {
            zone            : el?.phase_zone_no,
            zone_price      : el?.total_price,
            total_zone_price: el?.total_zone_price
          }
        });
        setTimeout(() => {
          this.initPriceZoneChart();
          this.updateZonePriceChart(customZonePrice);
        }, 500);
      } else {
        this.price_zone_data     = [];
      }
    })
  }
  getPriceDivisionsData(data?) {
    let formData = new FormData();
    formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id ||'');
    formData.append('from_date', data?.from_date || '');
    formData.append('to_date', data?.to_date || '');
    formData.append('phase_zone_no', data?.phase_zone_no  || '');
    formData.append('platform_group1_code_system', data?.platform_group1_code_system || '');
    this.ds.post(`pr/dashboard_price_divisions/${this.project_profile_id}/${this.project_supervision_id}`, formData).subscribe(res => {
      if(res?.status) {
        this.price_division_data     = res?.result?.division_result;
        let customDivisionPrice  = this.price_division_data?.map(el => {
          let total_division_approved_price = '0';
          if(el?.total_division_approved_price) {
            let numbers = el?.total_division_approved_price?.split('.');
            if(numbers?.length > 1) {
              if(numbers[1]?.length > 1) {
                total_division_approved_price = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                total_division_approved_price = numbers[0] + '.' + numbers[1][0];
              }
            } else {
              total_division_approved_price = numbers[0]
            }
            
          } 
          let total_division_project_price = '0';
          if(el?.total_division_project_price) {
            let numbers = el?.total_division_project_price?.split('.');
            if(numbers?.length > 1) {
              if(numbers[1]?.length > 1) {
                total_division_project_price = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
              } else {
                total_division_project_price = numbers[0] + '.' + numbers[1][0]
              }
            } else {
              total_division_project_price = numbers[0]
            }
            
          } 
          return {
            division_name                 : this.lang_key == 'ar' ? el?.platform_group1_title_arabic : el?.platform_group1_title_english,
            total_division_approved_price : total_division_approved_price,
            total_division_project_price  : total_division_project_price
          }
        });
        setTimeout(() => {
          this.initPriceDivisionChart();
          this.updateDivisionPriceChart(customDivisionPrice);
        }, 500);
      } else {
        this.price_division_data = [];
      }
    })
  }

  initFormsChart() {
    //forms chart
    this.formsChart = echarts.init(document.getElementById('forms-chart'));
    this.formsChart.showLoading();
  }

  initAverageZoneChart1() {
    //AverageZone chart
    this.averageZoneChart1 = echarts.init(document.getElementById('zone-result-chart1'));
    this.averageZoneChart1.showLoading();
  }
  initAverageZoneChart2() {
    //AverageZone chart
    this.averageZoneChart2 = echarts.init(document.getElementById('zone-result-chart2'));
    this.averageZoneChart2.showLoading();
  }

  initAverageDivisionChart1() {
    //AverageDivision chart
    this.averageDivisionChart1 = echarts.init(document.getElementById('division-result-chart1'));
    this.averageDivisionChart1.showLoading();
  }
  initAverageDivisionChart2() {
    //AverageDivision chart
    this.averageDivisionChart2 = echarts.init(document.getElementById('division-result-chart2'));
    this.averageDivisionChart2.showLoading();
  }

  initPriceZoneChart() {
    //PriceZone chart
    this.zonePriceChart = echarts.init(document.getElementById('zone-price-chart'));
    this.zonePriceChart.showLoading();
  }

  initPriceDivisionChart() {
    //PriceDivision chart
    this.divisionPriceChart = echarts.init(document.getElementById('division-price-chart'));
    this.divisionPriceChart.showLoading();
  }

  initProjectTimeChart() {
    //ProjectTime chart
    this.projectTimeChart = echarts.init(document.getElementById('project-time-chart'));
    this.projectTimeChart.showLoading();
  }

  initAxis3Chart() {
    //ProjectTime chart
    this.axis3Chart = echarts.init(document.getElementById('axis3-chart'));
    this.axis3Chart.showLoading();
  }

  updateAverageZoneChart1(data:Array<any>, filter) {
    let zone_numbers             = [],
        zone_opening_balance     = [],
        zone_weight_result       = [],
        zone_ratio               = [],
        zone_left_ratio          = [];

    for(let item of data) {
      zone_numbers.push(item?.zone_no);
      zone_opening_balance.push({value: item?.zone_opening_balance, ratio: item?.zone_ratio});
      zone_weight_result.push({value: item?.zone_weight_result, ratio: item?.zone_ratio});
      zone_ratio.push(item?.zone_ratio);
      zone_left_ratio.push(item?.zone_left_ratio);
    }
    let notSelected = {};
    notSelected[this.lang.transform('lang_opening_balance')] = false;
    notSelected[this.lang.transform('lang_zone_weight')] = false;
    let newOption = {
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        show: true, 
        type:'scroll', 
        selected: notSelected
      },
      grid: { containLabel: true },
      xAxis: { 
        type: 'category', 
        name: this.lang.transform('lang_zone'),
        data: zone_numbers
      },
      yAxis: { 
        name: this.lang.transform('lang_average'), 
        max:100,
        axisLabel : {
          formatter: '{value} %',
          align: 'center' 
        } 
      },
      series: [
        {
          name: this.lang.transform('lang_zone_weight'),
          label: {
            position: 'top',
            show: true
          },
          showBackground: true,
          type: 'bar',
          data: zone_weight_result,
          stack: 'ow',
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data?.value}</span> of ${params?.data?.ratio}`
            }
          }
        },
        {
          name: this.lang.transform('lang_opening_balance'),
          label: {
            position: 'top',
            show: true
          },
          showBackground: true,
          type: 'bar',
          data: zone_opening_balance,
          stack: 'ow',
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data?.value}</span> of ${params?.data?.ratio}`
            }
          }
        },
        {
          name: this.lang.transform('lang_zone_ratio'),
          label: {
            position: 'top',
            show: true
          },
          showBackground: true,
          type: 'bar',
          stack: 'r_l',
          data: zone_ratio,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          itemStyle: {
            color: '#91ca8c'
          },
        },
        {
          name: this.lang.transform('lang_left_ratio'),
          label: {
            position: 'top',
            show: true
          },
          showBackground: true,
          type: 'bar',
          stack: 'r_l',
          data: zone_left_ratio,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          itemStyle: {
            color: '#FFDB5C'
          },
        }
      ]
    };
    this.averageZoneChart1.setOption(newOption);
    this.averageZoneChart1.hideLoading();
  }

  updateAverageZoneChart2(data:Array<any>, filter) {
    let zone_numbers              = [],
        zone_per_project          = [],
        total_per_project         = [],
        zone_opnening_per_project = [],
        zone_weight_per_project   = [];

    for(let item of data) {
      zone_numbers.push(item?.zone_no);
      zone_per_project.push(item?.zone_per_project);
      total_per_project.push(item?.total_per_project);
      zone_opnening_per_project.push(item?.zone_opnening_per_project);
      zone_weight_per_project.push(item?.zone_weight_per_project);
    }
    let notSelected = {};
    notSelected[this.lang.transform('lang_opening_per_project')] = false;
    notSelected[this.lang.transform('lang_weight_per_project')] = false;
    let newOption = {
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        show: true, 
        right:'20px', 
        type:'scroll', 
        selected: notSelected
      },
      grid: { containLabel: true },
      xAxis: { 
        type: 'category', 
        name: this.lang.transform('lang_zone'),
        data: zone_numbers
      },
      yAxis: {
        name: this.lang.transform('lang_average'), 
        axisLabel : {
          formatter: '{value} %',
          align: 'center' 
        }
      },
      series: [
        {
          name: this.lang.transform('lang_opening_per_project'),
          label: {
            position: 'top',
            show: true
          },
          showBackground: true,
          type: 'bar',
          data: zone_opnening_per_project,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          stack: 'ow'
        },
        {
          name: this.lang.transform('lang_weight_per_project'),
          label: {
            position: 'top',
            show: true
          },
          showBackground: true,
          type: 'bar',
          data: zone_weight_per_project,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          stack: 'ow'
        },
        {
          name: this.lang.transform('lang_zone_per_project'),
          label: {
            position: 'top',
            show: true
          },
          showBackground: true,
          type: 'bar',
          data: zone_per_project,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          itemStyle: {
            color: '#53b17d'
          }
        },
        {
          name: this.lang.transform('lang_total_per_project'),
          label: {
            position: 'top',
            show: true
          },
          showBackground: true,
          type: 'bar',
          data: total_per_project,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          itemStyle: {
            color: '#517eb7'
          }
        }
      ]
    };
    this.averageZoneChart2.setOption(newOption);
    this.averageZoneChart2.hideLoading();
  }

  updateAverageDivisionChart1(data:Array<any>) {
    let division_names                   = [],
        average_per_division             = [],
        left_average_per_division        = [];

    for(let item of data) {
      if(parseFloat(item?.average_per_division)  || 
      parseFloat(item?.left_average_per_division)) {
        division_names.push(item?.division_name);
        average_per_division.push(item?.average_per_division);
        left_average_per_division.push(item?.left_average_per_division);
      }
    }
    
    let newOption = {
      
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {show: true, left:'20px',  type:'scroll'},
      grid: { containLabel: true },
      yAxis: { 
        name: this.lang.transform('lang_average'),
        max:100, 
        axisLabel : {
          formatter: '{value} % ',
          align: 'center',
        }
      },
      xAxis: { 
        type: 'category', 
        name: this.lang.transform('lang_group_one'), 
        data: division_names,
        axisLabel : {
          interval: 0,
          rotate: 70,
          fontSize: 10
        }
      },
      series: [
        {
          name: this.lang.transform('lang_average_per_division'),
          label: {
            position: 'top',
            show: true
          },
          showBackground: true,
          type: 'bar',
          data: average_per_division,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          stack: 'apd_la',
          itemStyle: {
            color: '#91ca8c'
          },
        },
        {
          name: this.lang.transform('lang_left_average_per_division'),
          label: {
            position: 'top',
            show: true
          },
          showBackground: true,
          type: 'bar',
          data: left_average_per_division,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          stack: 'apd_la',
          itemStyle: {
            color: '#FFDB5C'
          },
        }
      ]
    };
    this.averageDivisionChart1.setOption(newOption);
    this.averageDivisionChart1.hideLoading();
  }

  updateAverageDivisionChart2(data:Array<any>) {
    let division_names                 = [],
        ratio_per_division             = [],
        total_ratio_per_division       = [];
    
    for(let item of data) {
      if(parseFloat(item?.ratio_per_division)  || 
      parseFloat(item?.total_ratio_per_division)) {
        division_names.push(item?.division_name);
        ratio_per_division.push(item?.ratio_per_division);
        total_ratio_per_division.push(item?.total_ratio_per_division);
      }
    }
    
    let option = {
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {show: true, right:'20px',  type:'scroll'},
      grid: { containLabel: true },
      xAxis: {
        name: this.lang.transform('lang_group_one'),
        type: 'category',
        data: division_names,
        axisLabel : {
          interval: 0,
          rotate: 70,
          fontSize: 10
        }
      },
      yAxis: {
        name: this.lang.transform('lang_average'),
        type: 'value'
      },
      series: [
        {
          name: this.lang.transform('lang_ratio_per_division'),
          data: ratio_per_division,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          type: 'bar',
          label: {
            position: 'top',
            show: true
          },
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          },
          itemStyle: {
            color: '#53b17d'
          }
        },
        {
          name: this.lang.transform('lang_total_ratio_per_division'),
          data: total_ratio_per_division,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          type: 'bar',
          label: {
            position: 'top',
            show: true
          },
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          },
          itemStyle: {
            color: '#517eb7'
          }
        }
      ]
    };
    this.averageDivisionChart2.setOption(option);
    this.averageDivisionChart2.hideLoading();
  }

  updateZonePriceChart(data:any) {
    let zone_numbers     = [],
        zone_price       = [],
        total_zone_price = [];
    
    for(let item of data) {
      if(parseFloat(item?.zone_price)  || 
        parseFloat(item?.total_zone_price)) {
        zone_numbers.push(item?.zone);
        zone_price.push(item?.zone_price);
        total_zone_price.push(item?.total_zone_price);
      }
    }
    let newOption = {
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        },
        formatter:  (params) => {
          return `${params?.seriesName}<br/>${params?.marker} ${params?.name} : <span style='font-weight:600'>${this.convertNumToCurrency(params?.data)}</span>`
        },
      },
      legend: {
        show: true, 
        type:"scroll"
      },
      grid: {
        top: '20%',
        containLabel: true
      },
      xAxis: { 
        name: this.lang.transform('lang_price'), 
        axisLabel : {
          formatter:  (params) => {
            return this.formatPrice(params);
          },
          align: 'center' 
        } 
      },
      yAxis: { type: 'category', name: this.lang.transform('lang_zone'), data: zone_numbers },
      series: [
        {
          name: this.lang.transform('lang_zone_price'),
          type: 'bar',
          label: {
            position: 'right',
            formatter:  (params) => {
              return this.convertNumToCurrency(params?.data)
            },
            show: true
          },
          showBackground: true,
          data: zone_price,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${this.convertNumToCurrency(params?.data)}</span>`
            }
          }
        },
        {
          name: this.lang.transform('lang_total_zone_price'),
          type: 'bar',
          label: {
            position: 'right',
            formatter:  (params) => {
              return this.convertNumToCurrency(params?.data)
            },
            show: true
          },
          showBackground: true,
          data: total_zone_price,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${this.convertNumToCurrency(params?.data)}</span>`
            }
          }
        }
      ]
    };
    this.zonePriceChart.setOption(newOption);
    this.zonePriceChart.hideLoading();
  }
  
  updateDivisionPriceChart(data:Array<any>) {
    let division_names                = [],
        total_division_approved_price = [],
        total_division_project_price  = [];

    for(let item of data) {
      if(parseFloat(item?.total_division_approved_price) || parseFloat(item?.total_division_project_price)) {
        division_names.push(item?.division_name);
        total_division_approved_price.push(item?.total_division_approved_price);
        total_division_project_price.push(item?.total_division_project_price);
      }
    }
    let newOption = {
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        },
      },
      legend: {show: true},
      grid: {
        containLabel: true
      },
      yAxis: { 
        name: this.lang.transform('lang_price'), 
        axisLabel : {
          formatter:  (params) => {
            return this.formatPrice(params);
          },
          align: 'center' 
        } 
      },
      xAxis: { 
        type: 'category', 
        name: this.lang.transform('lang_group_one'),
        data: division_names,
        axisLabel : {
          interval: 0,
          rotate: 70,
          fontSize: 10
        }
      },
      series: [
        {
          name: this.lang.transform('lang_total_division_approved_price'),
          type: 'bar',
          label: {
            position: 'top',
            formatter:  (params) => {
              return this.convertNumToCurrency(params?.data)
            },
            show: true
          },
          showBackground: true,
          data: total_division_approved_price,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${this.convertNumToCurrency(params?.data)}</span>`
            }
          }
        },
        {
          name: this.lang.transform('lang_total_division_project_price'),
          type: 'bar',
          label: {
            position: 'top',
            formatter:  (params) => {
              return this.convertNumToCurrency(params?.data)
            },
            show: true
          },
          showBackground: true,
          data: total_division_project_price,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${this.convertNumToCurrency(params?.data)}</span>`
            }
          }
        }
      ]
    };
    this.divisionPriceChart.setOption(newOption);
    this.divisionPriceChart.hideLoading();
  }
  updateFormsChart(data:any) {
    let formNames          = [],
    pending_data           = [],
    completed_data         = [],
    accepted_data          = [],
    rejected_data          = [],
    acceptedA_data         = [],
    acceptedB_data         = [],
    rejectedC_data         = [],
    rejectedD_data         = [],
    accepted_requests_data = [],
    rejected_requests_data = [];
    formNames = Object.keys(data);
    for(let form of Object.values(data)) {
      pending_data.push(form['total_pending_request']);
      completed_data.push(form['total_complete_requests']);
      accepted_data.push(form['total_accepted_result']);
      rejected_data.push(form['total_rejected_result']);
      acceptedA_data.push(form['total_accepted_result_A']);
      acceptedB_data.push(form['total_accepted_result_B']);
      rejectedC_data.push(form['total_rejected_result_C']);
      rejectedD_data.push(form['total_rejected_result_D']);
      accepted_requests_data.push({value:form['total_accepted_requests'], completed_value: form['total_complete_requests']});
      rejected_requests_data.push({value:form['total_rejected_requests'], completed_value: form['total_complete_requests']});
    }
    formNames.push('DSR');
    formNames.push('MSR');
    formNames.push('TSR');
    formNames.push('SQR');
    const newOption = {
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {show: true, type:'scroll'},
      grid: {
        
        containLabel: true
      },
      dataZoom: [
        {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'filter'
        },
        {
            id: 'dataZoomY',
            type: 'slider',
            yAxisIndex: [0],
            filterMode: 'empty'
        }
      ],
      xAxis: [
        {
          type: 'category',
          data: formNames
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: this.lang.transform('lang_accepted_requests'),
          type: 'bar',
          label: {
            show: true
          },
          itemStyle: {
            color: '#91ca8c'
          },
          stack: 'P_C',
          emphasis: {
            focus: 'series'
          },
          data: accepted_requests_data,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data?.value}</span> of ${params?.data?.completed_value}`
            }
          }
        },
        {
          name: this.lang.transform('lang_rejected_requests'),
          type: 'bar',
          label: {
            show: true
          },
          itemStyle: {
            color: '#b93939'
          },
          stack: 'P_C',
          emphasis: {
            focus: 'series'
          },
          data: rejected_requests_data,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data?.value}</span> of ${params?.data?.completed_value}`
            }
          }
        },
        {
          name: this.lang.transform('lang_pending'),
          type: 'bar',
          label: {
            show: true
          },
          stack: 'P_C',
          itemStyle: {
            color: '#FFDB5C'
          },
          emphasis: {
            focus: 'series'
          },
          data: pending_data,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          }
        },
        {
          name: this.lang.transform('lang_accepted_result'),
          type: 'bar',
          label: {
            show: true
          },
          itemStyle: {
            color: '#91ca8c'
          },
          stack: 'R_A',
          emphasis: {
            focus: 'series'
          },
          data: accepted_data,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          }
        },
        {
          name: this.lang.transform('lang_rejected_result'),
          type: 'bar',
          label: {
            show: true
          },
          itemStyle: {
            color: '#b93939'
          },
          stack: 'R_A',
          emphasis: {
            focus: 'series'
          },
          data: rejected_data,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          }
        },
        
        {
          name: this.lang.transform('lang_accepted_a'),
          type: 'bar',
          label: {
            show: true
          },
          data: acceptedA_data,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          itemStyle: {
            color: '#8dc1a9'
          },
          stack: 'A_B_C_D',
          emphasis: {
            focus: 'series'
          }
        },
        {
          name: this.lang.transform('lang_accepted_b'),
          type: 'bar',
          label: {
            show: true
          },
          data: acceptedB_data,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          itemStyle: {
            color: '#759aa0'
          },
          stack: 'A_B_C_D',
          emphasis: {
            focus: 'series'
          }
        },
        {
          name: this.lang.transform('lang_rejected_c'),
          type: 'bar',
          label: {
            show: true
          },
          itemStyle: {
            color: '#c55a5a'
          },
          data: rejectedC_data,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          stack: 'A_B_C_D',
          emphasis: {
            focus: 'series'
          }
        },
        {
          name: this.lang.transform('lang_rejected_d'),
          type: 'bar',
          label: {
            show: true
          },
          data: rejectedD_data,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          itemStyle: {
            color: '#f76f6f'
          },
          stack: 'A_B_C_D',
          emphasis: {
            focus: 'series'
          }
        }
      ]
    };
    this.formsChart.setOption(newOption);
    this.formsChart.hideLoading();
  }

  updateProjectTimeChart(data:any) {
    let option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        type: 'scroll'
      },
      clockwise: true,
      series: [
        {
          name: this.lang.transform('lang_project_date'),
          type: 'pie',
          radius: '50%',
          label: {
            position: 'inside',
            formatter: (params) => {
              return `${params?.name} (${params?.data?.value} %)`
            },
            fontWeight: 'bold'
          },
          data: [ 
            { 
              value: data?.average_done_days,  
              name: this.lang.transform('lang_done_days'), 
              label: {show: true}, 
              itemStyle:{
                color: '#91ca8c'
              } 
            },
            { 
              value: data?.average_left_days,  
              label: {show: true},
              name: this.lang.transform('lang_left_days'),
              itemStyle: {
                color: '#517eb7'
              }
            },
            { 
              value: data?.late_days,  
              label: {show: true},
              name: this.lang.transform('lang_late_days'),
              itemStyle: {
                color: '#bd3c4e'
              }
            }
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    this.projectTimeChart.setOption(option);
    this.projectTimeChart.hideLoading();
  }

  updateAxis3Chart(data:any) {
    let xAxisData                 = [],
        monthly_actual_total      = [],
        monthly_total_plan        = [],
        sum_total_plan            = [],
        monthly_sum_actual_total  = [];

    let currentDate = new Date();
    let currentMonth = ("0" + (currentDate.getMonth() + 1)).slice(-2) + '/' + currentDate.getFullYear();
    for(let item of data) {
      xAxisData.push(item?.month_plan_setting);
      monthly_actual_total.push(item?.monthly_actual_total);
      monthly_total_plan.push(item?.monthly_total_plan);
      monthly_sum_actual_total.push(item?.monthly_sum_actual_total);
      sum_total_plan.push(item?.sum_total_plan);
    }
    monthly_sum_actual_total = monthly_sum_actual_total.slice(0, xAxisData.indexOf(currentMonth) + 1);
    let newOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#283b56'
          }
        }
      },
      legend: {},
      dataZoom: [
        {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'filter'
        },
        {
            id: 'dataZoomY',
            type: 'slider',
            yAxisIndex: [0, 1],
            filterMode: 'empty'
        }
      ],
      xAxis: [
        {
          type: 'category',
          boundaryGap: true,
          data: xAxisData,
          axisLabel: {
            hideOverlap: false,
            fontSize: 1
          }
          
        }
      ],
      yAxis: [
        {
          type: 'value',
          scale: true,
          max: 15,
          min: 0,
          boundaryGap: [0.2, 0.2]
        },
        {
          type: 'value',
          scale: true,
          max: 100,
          min: 0,
          boundaryGap: [0.2, 0.2]
        }
      ],
      series: [
        {
          name: this.lang.transform('lang_monthly_total_plan'),
          type: 'bar',
          yAxisIndex: 0,
          data: monthly_total_plan,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          }
        },
        {
          name: this.lang.transform('lang_actual_total'),
          type: 'bar',
          yAxisIndex: 0,
          data: monthly_actual_total,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          }
        },
        {
          name: this.lang.transform('lang_sum_total_plan'),
          type: 'line',
          yAxisIndex: 1,
          data: sum_total_plan,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          }
        },
        {
          name: this.lang.transform('lang_sum_actual_total'),
          type: 'line',
          yAxisIndex: 1,
          data: monthly_sum_actual_total,
          tooltip: {
            formatter: (params) => {
              return `${params?.seriesName}<br/>${params?.marker} ${params?.name}: <span style='font-weight:600'>${params?.data}</span>`
            }
          },
          itemStyle: {
            color: '#56ab59'
          }
        }
      ]
    };
    this.axis3Chart.setOption(newOption);
    this.axis3Chart.hideLoading();
  }

  openAverageFilters() {
    const dialogRef = this.dialog.open(CompeletionRateFiltersComponent, {
      width:'80%',
      autoFocus: false,
      data: {
        projects_profile_id: this.project_profile_id,
        projects_supervision_id: this.supervision_data?.projects_supervision_id,
        projects_work_area_id: this.supervision_data?.projects_work_area_id,
        completion_filters: this.completion_filters
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.averageZoneChart1.showLoading();
        this.averageZoneChart2.showLoading();
        this.averageDivisionChart1.showLoading();
        this.averageDivisionChart2.showLoading();
        this.completion_filters = result;
        this.getAverageTotalData(result, true);
        this.getAverageZonesData(result, true);
        this.getAverageDivisionsData(result, true);
      }
    });
  }

  openPriceFilters() {
    const dialogRef = this.dialog.open(PriceFiltersComponent, {
      width:'80%',
      autoFocus: false,
      data: {
        projects_profile_id: this.project_profile_id,
        projects_supervision_id: this.supervision_data?.projects_supervision_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.zonePriceChart.showLoading();
        this.divisionPriceChart.showLoading();
        this.getPriceTotalData(result);
        this.getPriceZonesData(result);
        this.getPriceDivisionsData(result);
      }
    });
  }

  //helpers
  formatPrice(n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  }

  convertNumToCurrency(number) {
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 3, minimumFractionDigits: 3 }).format(number);
  }

  customizeFraction(number) {
    let customNumber = '0';
    if(number) {
      let numbers = number?.split('.');
      if(numbers.length > 1) {
        if(numbers[1]?.length > 2) {
          return customNumber = numbers[0] + '.' + numbers[1][0] + numbers[1][1] + numbers[1][2];
        } else if(numbers[1].length > 1) {
          return customNumber = numbers[0] + '.' + numbers[1][0] + numbers[1][1];
        } else {
          return customNumber = numbers[0] + '.' + numbers[1][0]
        }
      } else {
        return customNumber = numbers[0]
      }
      
    } else {
      return customNumber;
    }
  }
}