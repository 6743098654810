import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import * as Countries from '../../../../../shared/utils/countries_en'
 
@Component({
  selector: 'app-filter-employee-card',
  templateUrl: './filter-card.component.html',
  styleUrls: ['./filter-card.component.scss']
})
export class FilterEmployeeCardComponent implements OnInit {
  @Output() filterParams = new EventEmitter<any>();
  @Input() projects :any =[];
  @Input() branches :any =[];
  filterForm:FormGroup = this.fb.group({
    nationality_type: 'all',
    project_id:null,
    branch_id:null,
    national_id:null,
    employee_id:null,
    employee_name:null,
    nationality: null,
    position: null,
     
  });
  countries = Countries.countries
  formFromLocalStorage:any;
  nationality_type = [
    {
      label:this.lang.transform('lang_all'),
      value:'all'
    },
    {
      label:this.lang.transform('lang_saudi'),
      value:'saudi'
    },
    {
      label:this.lang.transform('lang_non_saudi'),
      value:'nonsaudi'
    },
    
  ];

 
  constructor(private fb: FormBuilder, private lang:LanguagePipe) { }

  ngOnInit(): void {
    this.getFilterParams();
    this.buildForm();
    
  }

  buildForm() {
    this.filterForm = this.fb.group({
      nationality_type: this.formFromLocalStorage?.nationality_type,
      project_id: this.formFromLocalStorage?.project_id,
      branch_id: this.formFromLocalStorage?.branch_id,
      national_id: this.formFromLocalStorage?.national_id,
      employee_name: this.formFromLocalStorage?.employee_name,
      employee_id: this.formFromLocalStorage?.employee_id,
      nationality: this.formFromLocalStorage?.nationality,
      position: this.formFromLocalStorage?.position,
     
    });
  }

  changeVal(e){
    console.log(this.filterForm);
    console.log(this.branches);
    console.log(e);
  }
  getFilterParams() {
    this.formFromLocalStorage = localStorage.getItem('employee_filterParams') ? JSON.parse(localStorage.getItem('employee_filterParams')): localStorage.setItem('employee_filterParams',  JSON.stringify(this.filterForm?.value))
  }

  sendFilterForm() {
    this.filterParams.emit(this.filterForm?.value);
    localStorage.setItem('employee_filterParams', JSON.stringify(this.filterForm?.value));
  }

  resetFilterForm() {
    this.filterForm.reset();
 /*    this.filterForm.get('version').setValue('final_completed_versions'); */
    this.filterParams.emit(this.filterForm?.value);
    localStorage.setItem('employee_filterParams', JSON.stringify(this.filterForm?.value));
  }

  public isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if(charCode == 44) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
}
