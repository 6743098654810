import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-ctlist',
  templateUrl: './ctlist.component.html',
  styleUrls: ['./ctlist.component.scss'],
  animations: [
    trigger("fadeIn", [
      state(
        "void",
        style({
          opacity: 0
        })
      ),
      transition("void <=> *", animate(1000))
    ]),
  ]
})
export class CtlistComponent implements OnInit {
  branches: any 		= [];
	types: any 			  = [];
	cData: any 			  = [];
	lodingdatas 		  = this.lang.transform('lang_loading');
	admin 				    = JSON.parse(this.ls.getItem('is_admin'));
	page_no:any 		  = localStorage.getItem('M_C_T_N') 	|| 1;
	page_size:any 		= localStorage.getItem('M_C_T_S') 	|| 10;
	searchBranch 		  = localStorage.getItem('M_C_T_B') 	|| "";
	searchType 			  = localStorage.getItem('M_C_T_T') 	|| "";
	searchKey 			  = localStorage.getItem('M_C_T_S_K') || "";
  constructor(public ds: DataService, public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
	ngOnInit() {
		this.spinner.show();
		this.get_contractors();
		this.get_branches();
	}
	public get_branches() {
		this.branches = [];
		this.ds.getActionByUrl([], 'ct/b/contractors_view').subscribe(res => {
			if (res.status) {
				this.branches = res.records;
			}
		});
	}
	public loadpage(page) {
		this.page_no = page;
		this.page_size = this.page_size;
		this.get_contractors();
	}
	public loadpagesize(size) {
		this.page_no = 1;
		this.page_size = size;
		this.get_contractors();
	}
	public load_contractors() {
		this.page_no = 1;
		this.page_size = 10;
		this.get_contractors();
	}
	public get_contractors() {
		localStorage.setItem('M_C_T_N', this.page_no);
		localStorage.setItem('M_C_T_S', this.page_size);
		localStorage.setItem('M_C_T_B', this.searchBranch || ''); // contractors Branch
		localStorage.setItem('M_C_T_S_K', this.searchKey || ''); // contractors Search Key
		let param = new FormData();
		param.append('searchBranch', this.searchBranch || '');
		param.append('searchBy', this.searchType || '');
		param.append('searchKey', this.searchKey || '');
		this.ds.postActionByUrl(param, 'ct/get/' + this.page_no + '/' + this.page_size).subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.cData = res;
			} else {
				this.lodingdatas = res.error;
				this.cData = [];
			}
		}, error => {
			this.cData = [];
			this.spinner.hide();
			this.lodingdatas = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
		})
	}
	public checkAllItemsCTors(event) {
		if (event.target.checked) {
			$(".contractors .eachItemCTors").prop("checked", true);
			$(".contractors .makeback").addClass("bground");
			$(".contractors .deleteIconDiv").show();
		} else {
			$(".contractors .eachItemCTors").prop("checked", false);
			$(".contractors .makeback").removeClass("bground");
			$(".contractors .deleteIconDiv").hide();
		}
	}
	public checkEachItemCTors(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".contractors .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".contractors .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".contractors .eachItemCTors:checked").each(function() {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".contractors .eachItemCTors").each(function() {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".contractors .deleteIconDiv").show();
		} else {
			$(".contractors .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".contractors #allItemsCTors").prop("checked", true);
		} else {
			$(".contractors #allItemsCTors").prop("checked", false);
		}
	}
	public deleteBulkDataCTors() {
		const checkedtotal = [];
		$(".contractors .eachItemCTors:checked").each(function() {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: '400px',
				data: {
					title: this.lang.transform('lang_confirmation'),
					message: this.lang.transform('lang_are_you_sure'),
				}
			});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.spinner.show()
					this.ds.deleteActionByUrl(checkedtotal, "ct/delete").subscribe(
						(data) => {
							this.spinner.hide()
							if (data.status) {
								this.load_contractors();
								$(".contractors #allItemsCTors").prop("checked", false);
								$(".contractors .deleteIconDiv").hide();
								this.ds.dialogf('', data.msg);
							} else {
								this.ds.dialogf('', data.error);
							}
						},
						(error) => {
							this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
							this.spinner.hide()
						}
					);
				}
			});
		} else {
			this.ds.dialogf();
		}
	}
}
