import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { __param } from 'tslib';
@Component({
  selector: 'app-contractor-users',
  templateUrl: './contractor-users.component.html',
  styleUrls: ['./contractor-users.component.scss']
})
export class ContractorUsersComponent implements OnInit {
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  openModalR(template: TemplateRef<any>) {
    this.modalRefR = this.modalService.show(template, environment.modelconfig);
  }
  openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  openModalU(template: TemplateRef<any>) {
    this.modalRefU = this.modalService.show(template, environment.modelconfig);
  }
  
  modalRef: BsModalRef;
  modalRefR: BsModalRef;
  modalRefV: BsModalRef;
  modalRefU: BsModalRef;
  @Input() supervision_data
  @Input() able2add
  @Input() able2edit
  @Input() project_data
  @Input() contractor_id;
  able2delete = JSON.parse(this.ls.getItem('is_admin'));
  @Input() contractortype = "MAIN";
  positions:any     = [];
  userData:any = [];
  pageno = 1;
  pagesize = 10;
  searchKey= "";
  nodata = this.lang.transform('lang_loading');
  userform: FormGroup;
  updateform:FormGroup;
  registrationform:FormGroup;
  users:any = [];
  lang_key = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
  viewdata:any = [];
  //UnSigned In Users List
  UnSignupData:any = [];
  unSearchKey= "";
  Unnodata = this.lang.transform('lang_loading');
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.get_positions();
    this.load_contractor_users();
    this.load_unsigedin_contractor_users();
    this.registrationform = new FormGroup({
      user_registration_firstname_en : new FormControl('', [Validators.required]),
      user_registration_lastname_en : new FormControl('', [Validators.required]),
      user_registration_firstname_ar : new FormControl('', [Validators.required]),
      user_registration_lastname_ar : new FormControl('', [Validators.required]),
      user_email_address : new FormControl('', [Validators.required]),
      user_mobile_number : new FormControl('', [Validators.required]),
      personal_id_number : new FormControl(''),
    });
    this.userform = new FormGroup({
      position_id       : new FormControl(null, [Validators.required]),
      contractor_users  : new FormControl(null, [Validators.required])
    });
    this.updateform = new FormGroup({
      position_id             : new FormControl(null, [Validators.required]),
      projects_user_group_id  : new FormControl('', [Validators.required])
    });
  }
  get_positions() {
		this.positions = [];
		this.ds.getActionByUrl([], '366484fd45').subscribe(res => {
			if(res.status) {
				this.positions = res.records;
			}
		})
  }
  //Signed In Users List Operations
  public search_contractor_users() {
    this.pageno = 1;
    this.pagesize = 10;
    this.load_contractor_users();
  }
  public page_no(pageno) {
    this.spinner.show();
    this.pageno = pageno;
    this.pagesize = this.pagesize;
    this.load_contractor_users();
  }
  public page_size(pagesize) {
    this.spinner.show();
    this.pageno = 1;
    this.pagesize = pagesize;
    this.load_contractor_users();
  }
  public load_contractor_users() {
    let formdata = new FormData();
    formdata.append('search_key', this.searchKey);
    let url = (this.contractortype == "MAIN") ? 'MC6b4rJw1j' : 'ycdmPNpsnt';
    this.ds.getActionByUrl(this.ds.formData2string(formdata), url+'/'+this.project_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id+'/'+this.contractor_id+'/'+ this.pageno + '/' + this.pagesize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.userData = res;
      } else {
        this.userData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.userData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public empty_exsiting_users() {
    this.userform.reset();
    this.registrationform.reset();
    this.userform.get('contractor_users').setValue(null);
  }
  public search_users(key) {
    this.users = [];
    if(key.term) {
      let param = new FormData();
      param.append('user_type_id', '4');
      param.append('branch_id', this.project_data.branch_id);
      param.append('search', key.term);
      param.append('lang_key', this.lang_key);
      this.ds.getActionByUrl(this.ds.formData2string(param), 'sZMVmoFEATU9YlN/projects_add/').subscribe(res => {
        if (res.status) {
          this.users = res.records;
        }
      });
    }    
	}
  //create
  public submit() {
    this.spinner.show();
    let param = new FormData();
    param.append('projects_profile_id', this.supervision_data.projects_profile_id);
    param.append('projects_supervision_id', this.supervision_data.projects_supervision_id);
    param.append('contractor_id', this.contractor_id);
    param.append('contractor_users', this.userform.get('contractor_users').value || '');
    param.append('position_id', this.userform.get('position_id').value || '');
    this.ds.postActionByUrl(param, 'E1sBNVN0AP').subscribe(res=> {
      this.spinner.hide();
      if(res.status) {
        this.search_contractor_users();
        this.alert.success(res.msg);
        this.userform.reset();
        setTimeout(() => {
          this.modalRef.hide();
        }, 1000);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public updateFormData(user) {
    this.alert.clear();
    this.updateform.get("position_id").setValue(user.project_settings_id);
    this.updateform.get("projects_user_group_id").setValue(user.projects_user_group_id);
  }
  //update
  public update() {
    this.spinner.show();
    let param = new FormData();
    param.append('projects_profile_id', this.supervision_data.projects_profile_id);
    param.append('projects_supervision_id', this.supervision_data.projects_supervision_id);
    param.append('projects_user_group_id', this.updateform.get('projects_user_group_id').value || '');
    param.append('position_id', this.updateform.get('position_id').value || '');
    this.ds.postActionByUrl(param, 'E1sBNVN0UW').subscribe(res=> {
      this.spinner.hide();
      if(res.status) {
        this.search_contractor_users();
        this.alert.success(res.msg);
        this.updateform.reset();
        setTimeout(() => {
          this.modalRefU.hide();
        }, 500);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }  
  public signup() {
    this.spinner.show();
    let param = this.ds.json2formdata(this.registrationform.value);
    param.append('branch_id', this.project_data.branch_id);
    param.append('module_key', 'projects');
    param.append('permission_key', 'projects_add');
    param.append('private_key', 'projects_profile_id');
    param.append('private_value', this.project_data.projects_profile_id);
    param.append('group_key', 'PTV');
    param.append('user_type_id', '4');
    param.append('user_registration_type', 'S');
    param.append('co_module_key', 'contractors');
    param.append('co_private_key', 'contractor_id');
    param.append('co_private_value', this.contractor_id);
    param.append('projects_work_area_id', this.supervision_data.projects_work_area_id);
    param.append('project_work_area_group_key', 'PSMC');
    this.ds.postActionByUrl(param, 'reg').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.search_contractor_users();
        this.alert.success(res.msg);
        this.registrationform.reset();
        setTimeout(() => {
          this.modalRefR.hide();
        }, 1000);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public remove_contract_users(user) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show();
        this.ds.deleteActionByUrl([user.user_id], 'goKx4gcFfF/' + this.supervision_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id+'/'+user.contractor_group_id).subscribe(
          (data) => {
            this.spinner.hide();
            if (data.status) {
              this.search_contractor_users();
              this.ds.dialogf('',data.msg);
            } else {
              this.ds.dialogf('',data.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf('',(error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
          }
        );
      }
    });
  }
  //Unsigned Users List Operations
  public load_unsigedin_contractor_users() {
    let formdata = new FormData();
    formdata.append('search_key', this.unSearchKey);
    let url = (this.contractortype == "MAIN") ? 'SC6b45s6df' : 'abcde12345';
    this.ds.getActionByUrl(this.ds.formData2string(formdata), url+'/'+this.project_data.projects_profile_id+'/'+this.supervision_data.projects_supervision_id+'/'+this.contractor_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.UnSignupData = res;
      } else {
        this.UnSignupData = [];
        this.Unnodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.UnSignupData = [];
      this.Unnodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public signup_data_view(viewdata) {
    this.viewdata = viewdata;
  }
  public send_signup_link(user) {
    this.spinner.show();
    this.ds.getActionByUrl([], 'reg/link/' + user.user_registration_link).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.ds.dialogf('',data.msg);
      } else {
        this.ds.dialogf('',data.error);
      }
    },error => {
      this.spinner.hide();
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    });
  }
}