<breadcrumb></breadcrumb>
<section class="panel-expansion" *ngIf="isAdmin">
    <mat-accordion  class="stylepanels">
      <mat-expansion-panel class="mpanel phrases_list" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{'lang_registrations' | language}}</mat-expansion-panel-header>
        <div class="material-panel-body">
            <div class="mbrl15">
                <div class="row">
                    <div class="col-md-3 col-sm-6 col-xs-12 mb-10">
                        <select class="form-control almnabrformcontrol" [(ngModel)]="searchStatus" (change)="search_users();spinner.show();" matTooltip="{{'lang_status' | language }}">
                            <option value="">{{'lang_all' | language }}</option>
                            <option value="0">{{'lang_inactive' | language }}</option>
                            <option value="1">{{'lang_active' | language }}</option>
                        </select>
                    </div>
                    <div class="col-md-9 col-sm-6 col-xs-12 mb-10">
                        <div class="input-group input-group-sm mb-10">
                            <input type="text" class="form-control almnabrformcontrol" (keyup)="search_users()" id="searchKey" [(ngModel)]="searchKey" placeholder="{{'lang_search' | language }}">
                            <span class="input-group-btn">
                                <button id="btnSearch" type="button" (click)="spinner.show();openModalA(signupusers);load_data();" matTooltip="{{'lang_add' | language }}" class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon"></button>
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="table-responsive dropdownyes customResponsive">
                            <table border="1" class="table CustomTable">
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>{{'lang_module_name' | language }}</td>
                                        <td>{{'lang_name' | language }}</td>
                                        <td>{{'lang_email_address' | language }}</td>
                                        <td>{{'lang_mobile_number' | language }}</td>
                                        <td>{{'lang_registration_status' | language }}</td>
                                        <td>{{'lang_user_status' | language }}</td>
                                        <td>{{'lang_writer' | language }}</td>
                                        <td>{{'lang_action' | language }}</td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="users.length != '0'" class="AppendList">
                                    <tr *ngFor="let user of users; let i = index let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{user.user_regsitration_id}} status{{user.user_status}}">
                                        <td>{{i+1}}</td>
                                        <td>{{user.module_phrase_key | language}}</td>
                                        <td>{{user.registration_name}}</td>
                                        <td>{{user.user_email_address}}</td>
                                        <td>{{user.user_mobile_number}}</td>
                                        <td *ngIf="user.user_registration_link_status == '0'">
                                            <i matTooltip="{{'lang_pending' | language }}" class="fa fa-circle yellowcolor fa-icon pad0"></i>
                                        </td>
                                        <td *ngIf="user.user_registration_link_status == '1'">
                                            <i matTooltip="{{'lang_active' | language }}" class="fa fa-circle green fa-icon pad0"></i>
                                        </td>
                                        <td *ngIf="!user.user_id && user.user_status != '1'">
                                            <i matTooltip="{{'lang_pending' | language }}" class="fa fa-circle yellowcolor fa-icon pad0"></i>
                                        </td>
                                        <td *ngIf="user.user_id && user.user_status != '1'">
                                            <i matTooltip="{{'lang_inactive' | language }}" class="fa fa-circle red fa-icon pad0"></i>
                                        </td>
                                        <td *ngIf="user.user_status == '1'">
                                            <i matTooltip="{{'lang_active' | language }}" class="fa fa-circle green fa-icon pad0"></i>
                                        </td>
                                        <td>{{user.writer}}</td>
                                        <td>
                                            <mat-icon class="mnabricon" [matMenuTriggerFor]="menu">settings</mat-icon>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item (click)="signup_data_view(user);openModalV(viewUserData);"><span>{{'lang_view' | language }}</span></button>
                                                <button mat-menu-item *ngIf="user.user_registration_link_status == '0' " (click)="send_signup_link(user)"><span>{{'lang_send_signup_link' | language }}</span></button>
                                                <button mat-menu-item *ngIf="user.user_status == '0' &&  user.user_registration_link_status == '1' " (click)="enable_disable_user(user.user_id, '1')"><span>{{'lang_active' | language }}</span></button>
                                                <button mat-menu-item *ngIf="user.user_status == '1' &&  user.user_registration_link_status == '1' " (click)="enable_disable_user(user.user_id, '0')"><span>{{'lang_inactive' | language }}</span></button>
                                            </mat-menu>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="users.length == '0'" class="AppendList">
                                    <tr class="odd">
                                        <td colspan="9" align="center">{{usererrormsg}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="users.length != '0' " class="card-footer padb0">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-6  mb-10">
                        {{'lang_total' | language }} : {{userdata.page.total_records}}
                    </div>
                    <div class="col-lg-5 col-sm-6 col-12 mb-10">
                        <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="10" checked (click)="spinner.show();userpage(10)"> 10 </mat-radio-button>
                            <mat-radio-button value="20" (click)="spinner.show();userpage(20)"> 20 </mat-radio-button>
                            <mat-radio-button value="50" (click)="spinner.show();userpage(50)"> 50 </mat-radio-button>
                            <mat-radio-button value="100" (click)="spinner.show();userpage(100)"> 100 </mat-radio-button>
                            <mat-radio-button value="500" (click)="spinner.show();userpage(500)"> 500 </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-lg-4 col-sm-12 col-12 mb-10">
                        {{'lang_goto' | language }} :
                        <select class="customselect" (change)="spinner.show();userlength($event.target.value)">
                            <option *ngFor="let ks of ds.getrange(userdata.page.total_pages)" value="{{ks}}">{{ks}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
</section>

<ng-template #signupusers>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_users' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefA.hide();">&times;</button>
        </div>
        <form [formGroup]="userform" (ngSubmit)="user_operations();">
            <div class="modal-body">
                <div class="form-group">
                    <label for="branch_id">{{'lang_branch' | language }} : <span class="red">*</span></label>
                    <select class="form-control almnabrformcontrol" formControlName="branch_id">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option *ngFor="let branch of branches" value="{{branch.id}}"> {{branch.title}}</option>
                    </select>
                    <mat-error *ngIf="userform.controls['branch_id'].touched && userform.controls['branch_id'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="user_type_id">{{'lang_user_type' | language }} : <span class="red">*</span></label>
                    <select class="form-control almnabrformcontrol" formControlName="user_type_id">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option *ngFor="let type of utypes" value="{{type.id}}"> {{type.title}}</option>
                    </select>
                    <mat-error *ngIf="userform.controls['user_type_id'].touched && userform.controls['user_type_id'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="module_key">{{'lang_module_name' | language }} : <span class="red">*</span></label>
                    <select class="form-control almnabrformcontrol" formControlName="module_key" id="module_key" (change)="load_groups($event);">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option *ngFor="let module of modules" value="{{module.module_key}}" [private_key]="module.module_primary_tabke_key"> {{module.module_phrase_val}}</option>
                    </select>
                    <mat-error *ngIf="userform.controls['module_key'].touched && userform.controls['module_key'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="group_key">{{'lang_group' | language }} : <span class="red">*</span></label>
                    <select class="form-control almnabrformcontrol" formControlName="group_key">
                        <option value="">{{'lang_choose_options' | language }}</option>
                        <option *ngFor="let group of groups" value="{{group.group_key}}"> {{group.title}}</option>
                    </select>
                    <mat-error *ngIf="userform.controls['group_key'].touched && userform.controls['group_key'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <input type="hidden" formControlName="private_key">
                <div class="form-group">
                    <label for="private_value">{{'lang_private_value' | language }} : </label>
                    <ng-select 
                        [items]="privatevalues" 
                        [multiple]="false" 
                        bindLabel="title" 
                        bindValue="id" 
                        clearAllText="Clear"
                        appendTo="body" 
                        formControlName="private_value"
                        placeholder="{{'lang_private_value' | language}}"
                        (search)="privateValueSearch($event)"
                        [searchFn]="ds.customSearchFn">
                    </ng-select>
                </div>
                <div class="form-group">
                    <label for="user_registration_firstname_en">{{'lang_human_resources_fname_english' | language }} : <span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_human_resources_fname_english' | language }}" formControlName="user_registration_firstname_en" />
                    <mat-error *ngIf="userform.controls['user_registration_firstname_en'].touched && userform.controls['user_registration_firstname_en'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="user_registration_lastname_en">{{'lang_human_resources_lname_english' | language }} : <span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_human_resources_lname_english' | language }}" formControlName="user_registration_lastname_en" />
                    <mat-error *ngIf="userform.controls['user_registration_lastname_en'].touched && userform.controls['user_registration_lastname_en'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="user_registration_firstname_ar">{{'lang_human_resources_fname_arabic' | language }} : <span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_human_resources_fname_arabic' | language }}" formControlName="user_registration_firstname_ar" />
                    <mat-error *ngIf="userform.controls['user_registration_firstname_ar'].touched && userform.controls['user_registration_firstname_ar'].errors?.required"> {{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="user_registration_lastname_ar">{{'lang_human_resources_lname_arabic' | language }} : <span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_human_resources_lname_arabic' | language }}" formControlName="user_registration_lastname_ar" />
                    <mat-error *ngIf="userform.controls['user_registration_lastname_ar'].touched && userform.controls['user_registration_lastname_ar'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                </div>
                <div class="form-group">
                    <label for="user_email_address">{{'lang_email_address' | language }} : <span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_email_address' | language }}" formControlName="user_email_address" />
                    <mat-error *ngIf="userform.controls['user_email_address'].touched && userform.controls['user_email_address'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                    <mat-error *ngIf="userform.controls['user_email_address'].touched && userform.controls['user_email_address'].errors?.email"> {{'lang_field_valid_email' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="user_mobile_number">{{'lang_mobile_number' | language }} : <span class="red">*</span></label>
                    <input type="text" required class="form-control almnabrformcontrol" placeholder="{{'lang_mobile_number' | language }}" minlength="12" maxlength="12" formControlName="user_mobile_number" (keypress)="ds.numberOnly($event)" />
                    <mat-error *ngIf="userform.controls['user_mobile_number'].touched && userform.controls['user_mobile_number'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                    <mat-error *ngIf="userform.controls['user_mobile_number'].errors?.minlength"> {{'lang_minimum' | language }} 12 </mat-error>
                    <mat-error *ngIf="userform.controls['user_mobile_number'].errors?.maxlength"> {{'lang_maximum' | language }} 12 </mat-error>
                </div>
                <div class="form-group">
                    <label for="personal_id_number">{{'lang_human_resources_id_number' | language }}: </label>
                    <input type="text" class="form-control almnabrformcontrol" minlength="10" maxlength="10" placeholder="{{'lang_human_resources_id_number' | language }}" formControlName="personal_id_number"/>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRefA.hide()">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #viewUserData>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_view' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRefV.hide();">&times;</button>
        </div>
        <div class="modal-body" *ngIf="viewdata">
            <div class="form-group">
                <div class="row" *ngIf="viewdata.username">
                    <div class="col-6">
                        <label>{{'lang_username' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.username}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_name' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.registration_name}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_email_address' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.user_email_address}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_mobile_number' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.user_mobile_number}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.personal_id_number != '0' ">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_human_resources_id_number' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.personal_id_number}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_ondate' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.user_createdatetime}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.user_updatedatetime">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_onupdate' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.user_updatedatetime}}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_registration_status' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label *ngIf="viewdata.user_registration_link_status =='0' ">: <b><i matTooltip="{{'lang_inactive' | language }}" class="fa fa-circle red fa-icon pad0"></i> {{'lang_inactive' | language }}</b></label>
                        <label *ngIf="viewdata.user_registration_link_status =='1' ">: <b><i matTooltip="{{'lang_active' | language }}" class="fa fa-circle green fa-icon pad0"></i> {{'lang_active' | language }}</b></label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label>{{'lang_writer' | language }}</label>
                    </div>
                    <div class="col-6">
                        <label>: <b>{{viewdata.writer}}</b></label>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal"  (click)="modalRefV.hide();">{{'lang_close' | language }}</button>
        </div>
    </div>
</ng-template>