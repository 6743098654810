<breadcrumb></breadcrumb>
<div class="themesection">
    <section class="panel-expansion matborderno">
        <mat-accordion class="accounts_masters" >
            <mat-expansion-panel class="mpanel" [expanded]="true">
                <mat-expansion-panel-header class="mpanel-header">{{'lang_edit_receipt' | language }}</mat-expansion-panel-header>
                <div class="material-panel-body">
                    <form [formGroup]="form" (ngSubmit)="update_receipt()" class="form">
                        <input type="hidden" formControlName="branch_id">
                        <div class="mbrl15">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_receipt_date' | language }} :<span class="red">*</span></label>
                                        <div class="input-group input-group-sm">
                                            <input [matDatepicker]="prdate" formControlName="payment_receipt_date" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly>
                                            <span class="input-group-btn">
                                                <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="prdate"></mat-datepicker-toggle>
                                                <mat-datepicker #prdate></mat-datepicker>
                                            </span>
                                            <span class="input-group-btn">
                                                <button (click)="form.get('payment_receipt_date').setValue('') " matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                                            </span>
                                        </div>
                                        <mat-error *ngIf="form.controls['payment_receipt_date'].touched && form.controls['payment_receipt_date'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_receipt_from' | language }} :<span class="red">*</span></label>
                                        <input class="form-control almnabrformcontrol" formControlName="payment_receipt_to_from" placeholder="{{'lang_receipt_from' | language }}" />
                                        <mat-error *ngIf="form.controls['payment_receipt_to_from'].touched && form.controls['payment_receipt_to_from'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label class="mb-10">{{'lang_receipt_mode' | language }} :<span class="red">*</span></label><br>
                                        <mat-radio-group aria-label="Select an option" #payment_receipt_mode formControlName="payment_receipt_mode">
                                            <mat-radio-button value="cash"> {{'lang_cash' | language }} </mat-radio-button>
                                            <mat-radio-button value="cheque"> {{'lang_cheque' | language }}</mat-radio-button>
                                            <mat-radio-button value="deposit"> {{'lang_deposit' | language }}</mat-radio-button>
                                            <mat-radio-button value="etransfer"> {{'lang_etransfer' | language }}</mat-radio-button>
                                        </mat-radio-group>
                                        <mat-error *ngIf="form.controls['payment_receipt_mode'].touched && form.controls['payment_receipt_mode'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">                            
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_debit_account' | language }} :<span class="red">*</span></label>
                                        <ng-select 
                                                [items]="accountData" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="payment_receipt_debit_account_id"
                                                placeholder="{{'lang_debit_account' | language}}"
                                                (search)="searchAccounts($event)"
                                                (focus)="accountData = []"
                                            >
                                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                                    <span >{{ item.label}} ({{ item.value}})</span>
                                                </ng-template>
                                            </ng-select>
                                        <mat-error
                                            *ngIf="form.controls['payment_receipt_debit_account_id'].touched && form.controls['payment_receipt_debit_account_id'].errors?.required">
                                            {{'lang_field_required' | language }}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_debit_cost' | language }} : </label><br>
                                        <ng-select 
                                                [items]="costData" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="payment_receipt_debit_cost_id"
                                                placeholder="{{'lang_debit_cost' | language}}"
                                                (search)="searchCosts($event)"
                                                (focus)="costData = []"
                                            >
                                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                                    <span >{{ item.label}} ({{ item.value}})</span>
                                                </ng-template>
                                            </ng-select>
                                        <mat-error
                                            *ngIf="form.controls['payment_receipt_debit_cost_id'].touched && form.controls['payment_receipt_debit_cost_id'].errors?.required">
                                            {{'lang_field_required' | language }}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_receipt_amount' | language }} :<span class="red">*</span></label>
                                        <input class="form-control almnabrformcontrol" 
                                        (change)="ds.CheckDecimal($event.target.value, form.get('payment_receipt_amount'));"
                                        formControlName="payment_receipt_amount" placeholder="{{'lang_receipt_amount' | language }}" />
                                        <mat-error
                                            *ngIf="form.controls['payment_receipt_amount'].touched && form.controls['payment_receipt_amount'].errors?.required">
                                            {{'lang_field_required' | language }}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_credit_account' | language }} :<span class="red">*</span></label>
                                        <ng-select 
                                                [items]="accountData" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="payment_receipt_credit_account_id"
                                                placeholder="{{'lang_credit_account' | language}}"
                                                (search)="searchAccounts($event)"
                                                (focus)="accountData = []"
                                            >
                                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                                    <span >{{ item.label}} ({{ item.value}})</span>
                                                </ng-template>
                                            </ng-select>
                                        <mat-error
                                            *ngIf="form.controls['payment_receipt_credit_account_id'].touched && form.controls['payment_receipt_credit_account_id'].errors?.required">
                                            {{'lang_field_required' | language }}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_credit_cost' | language }} : </label><br>
                                        <ng-select 
                                                [items]="costData" 
                                                [multiple]="false" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                clearAllText="Clear"
                                                [searchFn]="ds.customSearchFn"
                                                appendTo="body" 
                                                formControlName="payment_receipt_credit_cost_id"
                                                placeholder="{{'lang_credit_cost' | language}}"
                                                (search)="searchCosts($event)"
                                                (focus)="costData = []"
                                            >
                                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                                    <span >{{ item.label}} ({{ item.value}})</span>
                                                </ng-template>
                                            </ng-select>
                                        <mat-error
                                            *ngIf="form.controls['payment_receipt_credit_cost_id'].touched && form.controls['payment_receipt_credit_cost_id'].errors?.required">
                                            {{'lang_field_required' | language }}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_description' | language }} : <span class="red">*</span></label>
                                        <input class="form-control almnabrformcontrol" formControlName="payment_receipt_description" placeholder="{{'lang_description' | language }}" />
                                        <mat-error *ngIf="form.controls['payment_receipt_description'].touched && form.controls['payment_receipt_description'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label>{{'lang_notes' | language }} : </label>
                                        <input class="form-control almnabrformcontrol" formControlName="payment_receipt_notes" placeholder="{{'lang_notes' | language }}" />
                                        <mat-error *ngIf="form.controls['payment_receipt_notes'].touched && form.controls['payment_receipt_notes'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10" *ngIf="showfield">
                                    <div class="form-group">
                                        <label>{{'lang_document_number' | language }} :<span class="red">*</span></label>
                                        <input class="form-control almnabrformcontrol" formControlName="payment_receipt_document_number" placeholder="{{'lang_document_number' | language }}" />
                                        <mat-error *ngIf="form.controls['payment_receipt_document_number'].touched && form.controls['payment_receipt_document_number'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10" *ngIf="showfield">
                                    <div class="form-group">
                                        <label>{{'lang_document_date' | language }} :<span class="red">*</span></label>
                                        <div class="input-group input-group-sm">
                                            <input [matDatepicker]="docd" formControlName="payment_receipt_document_date" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" readonly>
                                            <span class="input-group-btn">
                                                <mat-datepicker-toggle class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0" matSuffix [for]="docd"></mat-datepicker-toggle>
                                                <mat-datepicker #docd></mat-datepicker>
                                            </span>
                                            <span class="input-group-btn">
                                                <button (click)="form.get('payment_receipt_document_date').setValue('') " matTooltip="{{'lang_empty' | language}}" type="button" class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"></button>
                                            </span>
                                        </div>
                                        <mat-error *ngIf="form.controls['payment_receipt_document_date'].touched && form.controls['payment_receipt_document_date'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10" *ngIf="showfield">
                                    <div class="form-group">
                                        <label>{{'lang_bank' | language }} :<span class="red">*</span></label>
                                        <input class="form-control almnabrformcontrol" formControlName="payment_receipt_bank_name" placeholder="{{'lang_bank' | language }}" />
                                        <mat-error *ngIf="form.controls['payment_receipt_bank_name'].touched && form.controls['payment_receipt_bank_name'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-12 mb-10" *ngIf="showfield">
                                    <div class="form-group">
                                        <label>{{'lang_attachments' | language }} : <i aria-hidden="true" *ngIf="isFile" (click)="removeFile()" class="fa faicon fa-close red"></i></label>
                                        <div class="IDDIV">
                                            <input name="payment_receipt_attachment" type="file" onclick="this.value = null" (change)="uploadFile($event)" class="nghide albutton width100" accept=".pdf">
                                            <label id="uploadButton" class="albutton">{{filename}} </label>
                                            <mat-error *ngIf="form.controls['payment_receipt_attachment'].touched && form.controls['payment_receipt_attachment'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div class="card-footer padb0">
                            <div class="row">
                                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                                    <button type="button" class="albutton" routerLink="/accounts/reclist">{{'lang_goto_list' | language }}</button>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                                    <button type="button" class="albutton" routerLink="/accounts/recview/{{branch_id}}/{{payment_receipt_id}}/view">{{'lang_view' | language }}</button>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                                    <app-alert id="default-alert"></app-alert>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                                    <button type="submit" class="albutton" >{{'lang_submit' | language }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</div>