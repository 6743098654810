<breadcrumb></breadcrumb>
<!-- <mat-card class="pad0" [@fadeCosts]>
      <mat-card-title [ngClass]="['text-black']" class="f-s-16 f-w-100 p-2 m-0 bg-aP shadow-d">{{stat.title}}</mat-card-title>
      <mat-card-content class="p-2" [ngClass]="['text-black']">
        <p class="mt-2 f-s-20">{{stat.amount}}</p>
      </mat-card-content>
    </mat-card> -->
<div class="row mt-4" fxLayout="row wrap" *ngIf="false">
	<div class="col-sm-4 mb-2">
		<div class="dashboard-card">
			<h3>{{ stats[0].amount }}</h3>
			<p>{{ stats[0].title }}</p>
			<i class="fa fa-users"></i>
		</div>
	</div>
	<div class="col-sm-4 mb-2">
		<div class="dashboard-card" style="background: rgb(70, 183, 218)">
			<h3>{{ stats[1].amount }}</h3>
			<p>{{ stats[1].title }}</p>
			<i class="fa fa-id-card"></i>
		</div>
	</div>
	<div class="col-sm-4 mb-2">
		<div class="dashboard-card" style="background: #f9588f">
			<h3>{{ stats[2].amount }}</h3>
			<p>{{ stats[2].title }}</p>
			<i class="fa fa-briefcase"></i>
		</div>
	</div>
	<div class="col-sm-4 mb-2">
		<div class="dashboard-card" style="background: #7086ff">
			<h3>{{ stats[3].amount }}</h3>
			<p>{{ stats[3].title }}</p>
			<i class="fa fa-file"></i>
		</div>
	</div>
	<div class="col-sm-4 mb-2">
		<div class="dashboard-card" style="background: #f28c84">
			<h3>{{ stats[4].amount }}</h3>
			<p>{{ stats[4].title }}</p>
			<i class="fa fa-address-book"></i>
		</div>
	</div>
	<div class="col-sm-4 mb-2">
		<div class="dashboard-card" style="background: #dcc56f">
			<h3>{{ stats[5].amount }}</h3>
			<p>{{ stats[5].title }}</p>
			<i class="fa fa-life-ring"></i>
		</div>
	</div>
</div>
<!-- Calendar -->
<div class="matero-row mt-4" fxLayout="row wrap" *ngIf="false">
	<div class="matero-col" fxFlex="100">
		<mat-card>
			<mat-card-title>Activities</mat-card-title>
			<mat-card-content class="p-2 mnabrcalendar" [ngClass]="['text-black']">
				<app-calendar [@fadeActivities]></app-calendar>
			</mat-card-content>
		</mat-card>
	</div>
</div>
<!-- Charts -->
<div class="matero-row mt-4" fxLayout="row wrap" *ngIf="false">
	<div class="matero-col" fxFlex="100" fxFlex.gt-sm="50">
		<mat-card>
			<mat-card-title>Traffic</mat-card-title>
			<div id="chart1" [@fadeTraffic]></div>
		</mat-card>
	</div>
	<div class="matero-col" fxFlex="100" fxFlex.gt-sm="50">
		<mat-card>
			<mat-card-title>Weekly Revenue</mat-card-title>
			<div id="chart2" [@fadeRevenue]></div>
		</mat-card>
	</div>
</div>

<div class="coming-soon">
	<div class="imgs">
		<img
			src="../../../assets/images/logo.svg"
			alt=""
			width="150px"
			style="margin: 0 10px"
		/>
		<img src="../../../assets/images/logonname.svg" alt="" width="300px" />
	</div>
	<p>this page will opening soon</p>
</div>
