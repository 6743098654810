import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
interface list {
  id?: number;
  level_id: string,
  level_key: string,
  level_name_en: string,
  level_name_ar: string,
}
@Component({
  selector: 'app-files-level',
  templateUrl: './files-level.component.html',
  styleUrls: ['./files-level.component.scss']
})
export class FilesLevelComponent implements OnInit {
  modalRefA: BsModalRef;
  tabledatas: list[];
  form:FormGroup;
  @Output() actionEvent             = new EventEmitter<any>();
  collectionSize                    = 0;
  page                              = 1;
  pageSize                          = 10;
  searchKey                         = "";
  nodata: string                    = this.lang.transform('lang_loading');
  admin                             = JSON.parse(this.ls.getItem('is_admin'));
  wholeData:any                     = [];
  tabledata:any                     = [];
  modules:any                       = [];
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.get_file_levels();
    this.form = new FormGroup({
      level_id            : new FormControl(''),
      level_key           : new FormControl('', [Validators.required]),
      level_name_en       : new FormControl('', [Validators.required]),
      level_name_ar       : new FormControl('', [Validators.required]),
    });
  }
  //add, edit model
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public get_file_levels() {
    let param = new FormData();
    param.append('search_key', this.searchKey);
    param.append('request', 'list');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lflevel').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.wholeData      = res.records;
        this.tabledatas     = res.records;
        this.collectionSize = res.records.length;
        this.build_table_data();
      } else {
        this.wholeData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholeData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    }) 
  }
  public build_table_data() {
    this.tabledata = this.tabledatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  public edit_file_level_data(row) {
    this.alert.clear();
    this.form.patchValue(row);
  }
  public submit() {
    this.spinner.show();
    if(this.form.get('level_id').value) {
      this.ds.putActionByUrl(this.ds.formData2string(this.ds.json2formdata(this.form.value)), 'uflevel/' + this.form.get('level_id').value).subscribe(res=> {
        this.spinner.hide();
        if(res.status) {
          this.get_file_levels();
          this.form.reset();
          this.actionEvent.emit('');
          this.alert.success(res.msg);
          setTimeout(() => {
            this.modalRefA.hide();
          }, 500);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'cflevel').subscribe(res=> {
        this.spinner.hide();
        if(res.status) {
          this.get_file_levels();
          this.form.reset();
          this.actionEvent.emit('');
          this.alert.success(res.msg);
          setTimeout(() => {
            this.modalRefA.hide();
          }, 1000);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    }
  }
  public checkallItemsfLevl(event) {
    if (event.target.checked) {
      $(".appfilelevels .eachItemfLevl").prop("checked", true);
      $(".appfilelevels .makeback").addClass("bground");
      $(".appfilelevels .deleteIconDiv").show();
    } else {
      $(".appfilelevels .eachItemfLevl").prop("checked", false);
      $(".appfilelevels .makeback").removeClass("bground");
      $(".appfilelevels .deleteIconDiv").hide();
    }
  }
  public checkeachItemfLevl(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appfilelevels .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appfilelevels .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appfilelevels .eachItemfLevl:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appfilelevels .eachItemfLevl").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appfilelevels .deleteIconDiv").show();
    } else {
      $(".appfilelevels .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appfilelevels #allItemsfLevl").prop("checked", true);
    } else {
      $(".appfilelevels #allItemsfLevl").prop("checked", false);
    }
  }
  public deleteBulkDatafLevl() {
    const checkedtotal = [];
    $(".appfilelevels .eachItemfLevl:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'dflevel').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.actionEvent.emit('');
                this.get_file_levels();
                $(".appfilelevels #allItemsfLevl").prop("checked", false);
                $(".appfilelevels .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
