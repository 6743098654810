import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-transaction-viewer',
  templateUrl: './transaction-viewer.component.html',
  styleUrls: ['./transaction-viewer.component.scss']
})
export class TransactionViewerComponent implements OnInit {
  branches: any = [];
  page = 1;
  data: any = [];
  transaction: any = [];
  paging: any = [];
  length:any = [];
  branch_id: any = (localStorage.getItem('selectedBranch') != null) ? localStorage.getItem('selectedBranch') : '0';
  page_no = 1;
  activePage: number = 0;
  lodingdatas = this.lang.transform('lang_loading');
  finances: any = [];
  finance_id: any = "";
  searchKey:any = "";
  constructor(public ds:DataService,public route : ActivatedRoute, public datePipe: DatePipe,public router : Router, public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != '0') {
      this.spinner.show();
      this.load_financial_years();
    }
  }
  public selectBrach(branch) {
    if (branch && branch != '0') {
      this.spinner.show();
      localStorage.setItem('selectedBranch', branch);
      this.branch_id = branch;
      this.load_financial_years();
    }
  }
  public load_branches() {
    this.branches = [];
    this.ds.getActionByUrl([], 'ab/accounts_view').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.branches = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public load_financial_years() {
    this.finances = [];
    this.finance_id = '';
    this.ds.getActionByUrl([], 'financial/' + this.branch_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.finances = res.records;
        this.finances.forEach((v, k) => {
          if (v.finance_status == "1") {
            this.finance_id = v.finance_id;
            this.load_transaction_viewers();
          }
        });
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public loadPageTrans(page_no) {    
    this.page_no = page_no;
    this.load_transaction_viewers();
  }
  public loadtransaction(pagearray) {
    this.page_no = 1;
    if (pagearray && pagearray[0] > 0) {
      this.page_no = pagearray[0];
      this.spinner.show();
      this.load_transaction_viewers();
    }
  }
  public load_transaction_viewers() {
    if (this.branch_id && this.branch_id != '0' ) {
      let formdata = new FormData();
      formdata.append('search_key', this.searchKey);
      formdata.append('finance_id', this.finance_id);
      this.ds.getActionByUrl(this.ds.formData2string(formdata), 'tviewer/' + this.branch_id + '/' + this.page_no).subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.data = res.records;
          this.transaction = res.transactions
          this.paging = res.paging;
          if (this.length.length === 0) {
            let page = [];
            for (let index = 0; index < res.paging.total_pages; index++) {
              page.push(index + 1);
            }
            this.length = page;
          }
        } else {
          this.data = [];
          this.transaction = [];
          this.paging = [];
          this.lodingdatas = res.error;
          this.length = [];
        }
      }, error => {
        this.spinner.hide();
        this.data = [];
        this.transaction = [];
        this.paging = [];
        this.lodingdatas = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
      })
    }
  }
}
