import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
interface list {
  id?: number;
  transaction_request_type: string,
  transaction_id: string,
  request_id: string,
  transaction_history_date: string,
  debit_amount: string,
  credit_amount: string,
  transaction_history_description: string,
  account_code: string,
  account_name: string,
}
@Component({
  selector: 'app-transaction-listing',
  templateUrl: './transaction-listing.component.html',
  styleUrls: ['./transaction-listing.component.scss']
})
export class TransactionListingComponent implements OnInit {
  page = 1;
  pageSize = 20;
  collectionSize;
  listofdatas: list[];
  form: FormGroup;
  branches: any = [];
  finances: any = [];
  finance_id: any = "";
  lodingdatas = this.lang.transform('lang_loading');
  fromaccounts: any = [];
  datastatus = false;
  data: any = [];
  settings = environment.singledropdown;
  branch_id: any = (localStorage.getItem('selectedBranch') != null) ? localStorage.getItem('selectedBranch') : '0';
  constructor(public ds:DataService,public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.buildform();
    this.spinner.show();
    this.load_branches();
    if (this.branch_id && this.branch_id != '0') {
      this.load_financial_years();
      this.searchAccounts();
    }
  }
  public selectBrach(branch) {
    this.data = [];
    this.datastatus = false;
    if (branch && branch != '0') {
      localStorage.setItem('selectedBranch', branch);
      this.branch_id = branch;
      this.searchAccounts();
      this.load_financial_years();
    } else {
      this.spinner.hide();
    }
  }
  public load_branches() {
    this.branches = [];
    this.ds.getActionByUrl([], 'ab/accounts_view').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.branches = res.records;
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public load_financial_years() {
    this.finances = [];
    this.form.get('finance_id').setValue('')
    this.ds.getActionByUrl([], 'financial/' + this.branch_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.finances = res.records;
        this.finances.forEach((v, k) => {
          if (v.finance_status == "1") {
            this.form.get('finance_id').setValue(v.finance_id);
            this.form.get('period_from').setValue(v.finance_start_date);
            this.form.get('period_to').setValue(v.finance_end_date);
          }
        });
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public selectFinanceYear(financeid) {
    if (financeid && financeid != '0') {
      this.finance_id = financeid;
    }
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      finance_id: new FormControl(null, [Validators.required]),
      transaction_listing: new FormControl(''),
      account_code_from: new FormControl(null),
      account_code_to: new FormControl(null),
      period_from: new FormControl(null, [Validators.required]),
      period_to: new FormControl(null, [Validators.required]),
    })
  }
  public searchAccounts() {
    this.fromaccounts = [];
    let param = new FormData();
    param.append('branch_id', this.branch_id);
    param.append('status', '0');
    if (this.branch_id) {
      this.ds.postActionByUrl(param, "sam/accounts_view").subscribe(data => {
        if (data.status) {
          this.fromaccounts = data.records;
        }
      });
    }
  }
  public fetch_statements() {
    if (this.form.valid) {
      this.spinner.show();
      let param = new FormData();
      param.set('branch_id', this.form.get('branch_id').value);
      param.set('transaction_listing', (this.form.get('transaction_listing').value) ? this.form.get('transaction_listing').value : '');
      param.set('account_code_from', (this.form.get('account_code_from').value) ? this.form.get('account_code_from').value : '');
      param.set('account_code_to', (this.form.get('account_code_to').value) ? this.form.get('account_code_to').value : '');
      param.set('period_from', (this.form.get('period_from').value != null) ? this.form.get('period_from').value : '');
      param.set('period_to', (this.form.get('period_to').value != null) ? this.form.get('period_to').value : '');
      this.ds.postActionByUrl(param, 'transactionlistings').subscribe(res => {
        this.datastatus = res.status;
        this.spinner.hide();
        if (res.status && res.records.length > 0) {
          this.listofdatas = res.records;
          this.collectionSize = res.records.length;
          this.refreshLists();
        } else {
          this.lodingdatas = res.error;
          this.listofdatas = [];
          this.data = [];
          this.ds.dialogf('', res.error);
        }
      }, error => {
        this.lodingdatas = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
        this.datastatus = false;
        this.listofdatas = [];
        this.data = [];
        this.spinner.hide();
        this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      this.ds.dialogf('', this.lang.transform('lang_fill_required_fields'));
    }
  }
  public refreshLists() {
    this.data = this.listofdatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
}
