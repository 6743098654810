import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { NgbCalendar, NgbCalendarIslamicUmalqura, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { IslamicI18n } from '@shared/utils/arabicpicker';
import { BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  providers:[
    { provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n }
  ]
})
export class Step1Component implements OnInit {
  @Input() regForm: FormGroup;
  @Input() action;
  public fileurl;
  apiurl = environment.SERVER_ORIGIN;
  idfilename = this.lang.transform('lang_select_files');
  branches:any = [];
  etitles:any = [];
  types: any = [];
  countries:any = [];
  view_profile_image = "./assets/images/avatar.png ";
  constructor(public router:Router, public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.branchlists();
  }
  get f() {
    return this.regForm.controls.idDetails;
  }
  public onFileChange(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      var reader = new FileReader();
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        if (event) {
          this.fileurl = (<FileReader>event.target).result;
        }
      };
      this.regForm.get("idDetails").get("profile_image").setValue(file, { emitEvent: true });
    } else {
      this.regForm.get("idDetails").get("profile_image").setValue('');
      this.fileurl = "";
    }
  }
  public removeProfile() {
    this.regForm.get("idDetails").get("profile_image").setValue([]);
    this.fileurl = "";
  }
  public branchlists() {
    this.branches = [];
    this.types = [];
    this.etitles = [];
    this.countries = [];
    let permit = (this.action === "EDIT") ? 'human_resources_edit' : 'human_resources_add';
    this.ds.getActionByUrl([],'human_resources/branches/'+permit).subscribe(data => {
      if(data.status) {
        this.branches = data.branches;
        this.etitles = data.titles;
        this.countries = data.countries;
        this.types = data.types
      }
    });
  }
  public employeeExists(idnumber) {
    if (this.action !== "EDIT" && idnumber.length == 10) {
      let formData = new FormData();
      formData.append('employee_id_number', idnumber);
      this.ds.postActionByUrl(formData, 'human_resources/employeeexists').subscribe(data => {
        if (!data.status) {
          this.f.get('employee_id_number').setValue('');
          this.ds.dialogf('', data.error);
        }
      }, error => {
        this.f.get('employee_id_number').setValue('');
        this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    }
  }
  public uploadIDFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.regForm.get("idDetails").get("idFileUpload").setValue(fileid, { emitEvent: true });
    } else {
      this.regForm.get("idDetails").get("idFileUpload").setValue('');
      this.idfilename = this.lang.transform('lang_select_files');
    }
  }
  public step1Submitted() {
    this.regForm.get("idDetails").get("employee_id_number").markAsTouched();
    this.regForm.get("idDetails").get("employee_id_number").updateValueAndValidity();
    this.regForm.get("idDetails").get("branch_id").markAsTouched();
    this.regForm.get("idDetails").get("branch_id").updateValueAndValidity();
    this.regForm.get("idDetails").get("firstname_english").markAsTouched();
    this.regForm.get("idDetails").get("firstname_english").updateValueAndValidity();
    this.regForm.get("idDetails").get("secondname_english").markAsTouched();
    this.regForm.get("idDetails").get("secondname_english").updateValueAndValidity();
    this.regForm.get("idDetails").get("lastname_english").markAsTouched();
    this.regForm.get("idDetails").get("lastname_english").updateValueAndValidity();
    this.regForm.get("idDetails").get("firstname_arabic").markAsTouched();
    this.regForm.get("idDetails").get("firstname_arabic").updateValueAndValidity();
    this.regForm.get("idDetails").get("secondname_arabic").markAsTouched();
    this.regForm.get("idDetails").get("secondname_arabic").updateValueAndValidity();
    this.regForm.get("idDetails").get("lastname_arabic").markAsTouched();
    this.regForm.get("idDetails").get("lastname_arabic").updateValueAndValidity();
    this.regForm.get("idDetails").get("iqama_expiry_date_english").markAsTouched();
    this.regForm.get("idDetails").get("iqama_expiry_date_english").updateValueAndValidity();
    this.regForm.get("idDetails").get("copy_number").markAsTouched();
    this.regForm.get("idDetails").get("copy_number").updateValueAndValidity();
    this.regForm.get("idDetails").get("birth_date_english").markAsTouched();
    this.regForm.get("idDetails").get("birth_date_english").updateValueAndValidity();
    this.regForm.get("idDetails").get("gender").markAsTouched();
    this.regForm.get("idDetails").get("gender").updateValueAndValidity();
    this.regForm.get("idDetails").get("nationality").markAsTouched();
    this.regForm.get("idDetails").get("nationality").updateValueAndValidity();
    this.regForm.get("idDetails").get("job_title_iqama").markAsTouched();
    this.regForm.get("idDetails").get("job_title_iqama").updateValueAndValidity();
    this.regForm.get("idDetails").get("marital_status").markAsTouched();
    this.regForm.get("idDetails").get("marital_status").updateValueAndValidity();
    this.regForm.get("idDetails").get("religion").markAsTouched();
    this.regForm.get("idDetails").get("religion").updateValueAndValidity();
    this.regForm.get("idDetails").get("work_domain").markAsTouched();
    this.regForm.get("idDetails").get("work_domain").updateValueAndValidity();
    this.regForm.get("idDetails").get("work_location").markAsTouched();
    this.regForm.get("idDetails").get("work_location").updateValueAndValidity();
    this.regForm.get("idDetails").get("work_type").markAsTouched();
    this.regForm.get("idDetails").get("work_type").updateValueAndValidity();    
    if(this.action !=="EDIT") {
      this.regForm.get("idDetails").get("id_filename").markAsTouched();
      this.regForm.get("idDetails").get("id_filename").updateValueAndValidity();
      this.regForm.get("idDetails").get("idFileUpload").markAsTouched();
      this.regForm.get("idDetails").get("idFileUpload").updateValueAndValidity();
    }
  }
  public get_profile_image(url) {
    this.ds.getActionByUrl([], url).subscribe(data => {
      if (data.status) {
        this.view_profile_image = 'data:' + data['content-type'] + ';base64,' + data.base64;
      } else {
        this.view_profile_image = "./assets/images/default1.png ";
      }
    }, error => {
        this.view_profile_image = "./assets/images/default1.png ";
    });
  }
}
