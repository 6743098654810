import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { platformRequirements } from 'app/routes/transactions/FORM_PS_TR1/formdata';
import { DwsrService } from 'app/routes/transactions/FORM_PS_TR1/services/dwsr.service';

@Component({
  selector: 'app-mir-step1',
  templateUrl: './mir-step1.component.html',
  styleUrls: ['./mir-step1.component.scss']
})
export class MirStep1Component implements OnInit {
  @Output() refreshComponent = new EventEmitter<boolean>();
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() form_url;
  @Input() unitsOfTemplate;
  allData:any;
  lang_form : FormGroup;
  form : FormGroup;
  work_sites_form:FormGroup;
  requirement_form:FormGroup;
  platform_form:FormGroup;
  config_attachment_form:FormGroup;
  other_attachments:FormArray;
  nodata: string                          = this.lang.transform('lang_no_data');
  filename:string                         = this.lang.transform('lang_select_files');
  user_type                               = this.ls.getItem('user_type_id') || 0;
  lang_key                                = (localStorage.getItem('lang_key') == null) ? 'en' : localStorage.getItem('lang_key');
  general_different_cluster_allowed:any   = "1";
  phases_different_cluster_allowed:any    = "0";
  languages:any                           = [];
  work_levels:any                         = [];
  platformData:any                        = [];
  platformRequirements:any                = platformRequirements;
  submitted:boolean                       = false;
  versions:any;
  page_no                                 = '1';
  page_size                               = '10';
  platform_page_no:any                    = '1';
  platform_page_size:any                  = '10';
  versions_page_no:any                    = '1';
  versions_page_size:any                  = '10';
  bill_quantity_page_no:any               = '1';
  bill_quantity_page_size:any             = '10';
  generalNumbersData                      = [];
  phasesData                              = [];
  materialList                            = [];
  supplierList                            = [];
  units_for_all:Object;
  validUnitsForAll = false;
  work_levels_for_all                     = [];
  work_levels_for_all_control             = new FormControl(null, Validators.required);
  @ViewChild('stepper') stepper: MatStepper;
  canMoveStep2 = new FormControl(null, Validators.required);
  canMoveStep3 = new FormControl(null, Validators.required);
  canMoveStep4 = new FormControl(null, Validators.required);
  SkipPagePlatform= false;
  NextButtonPlatform = false;
  platform_code_system = '';
  projects_work_area_id= 0;
  template_id:any;
  @Input() editMode;
  changeAttach = new FormControl(false);
  contractor_team_users_list = [];
  constructor(public ls:LocalStorage, private sweetAlert:SweetAlertService, private dwsrService:DwsrService , public ds:DataService, public dialog: MatDialog, public datepipe : DatePipe , public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) {

  }
  ngOnInit() {
    this.get_required_data('tc/tlanguages', 'lang');
    this.lang_form = new FormGroup({
      lang_key     : new FormControl(this.lang_key, [Validators.required]), 
    });
    
    this.work_sites_form = new FormGroup({
      work_site      : new FormControl('', [Validators.required]),
      separate       : new FormControl('0')
    });
    this.requirement_form = new FormGroup({
      material_notes       : new FormControl(null, [Validators.required]),
      material_code        : new FormControl(null, [Validators.required]),
      supplier_code        : new FormControl(null, [Validators.required]),
      storage_area         : new FormControl(null, [Validators.required]),
      supply_date_to_site  : new FormControl(null, [Validators.required]),
    })

    this.platform_form = new FormGroup({
      platforms      : new FormControl(null),  
    });
    this.config_attachment_form = new FormGroup({
      attachments    : this.fb.array([]),
    });
    if(this.user_type == '3' || this.user_type == '4') {
      this.config_attachment_form.addControl('contractor_team_users', new FormControl(null));
      this.config_attachment_form.addControl('contractor_manager_step_require', new FormControl('0'));
      this.get_contractor_team_users_list();
    }
    this.other_attachments = this.config_attachment_form.get("attachments") as FormArray; 

    if(this.editMode) {
      this.dwsrService.getData()?.subscribe(data => {
        this.allData = data;
        this.setEditData();
        this.get_work_levels();
        this.search_required_datas();
        
      });
    } else {
      this.get_work_levels();
      this.search_required_datas();
    }
  }
  public get_required_data(url, key) {
    this.languages = [];
    this.ds.getActionByUrl([], url).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        if (key === 'lang') {
          this.languages = res.records;
        }
      }
    })
  }

  public search_required_datas() {
    let param = new FormData();
    param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
    param.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
    param.append('template_id', this.template_data?.template_id || this.template_id);
    param.append('lang_key', this.lang_form.get("lang_key").value);
    this.ds.getActionByUrl(this.ds.formData2string(param), 'form/'+this.form_url+'/search_units_by_phases_general_no').subscribe((res) => {
      this.spinner.hide();
      if(res.status) {
        this.units_for_all = res?.records?.split(',');
      }
    }, error => {
      this.spinner.hide();
    })
  }

  getMaterialSupplierLists(listName:string) {
    let params = new FormData();
    params.append('lang_key', this.lang_form.get('lang_key').value);
    params.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
    params.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
    params.append('page_no', this.page_no);
    params.append('page_size', this.page_size);
    params.append('template_id', this.template_data?.template_id || this.template_id);
    params.append('request', listName);

    this.ds.getActionByUrl(this.ds.formData2string(params), 'form/'+this.form_url+'/material_suppliers').subscribe(res => {
      if(res.status) {
        if(listName == 'SUPPLIER') {
          this.supplierList = res.records;
        } else {
          this.materialList = res.records;
        }
      }
    })
  }

  get_form_versions() {
    this.alert.clear();
    this.spinner.show();
    let formData = new FormData();
    formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
    formData.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
    formData.append('lang_key', this.lang_form.get('lang_key')?.value);
    formData.append('page_no', this.versions_page_no);
    formData.append('page_size', this.versions_page_size);
    formData.append('template_id', this.template_data?.template_id || this.template_id);
    
    let stepUrl = '/cr/4/0';
    if(this.editMode) {
      stepUrl = `/cr/4/${this.form_request_id}`;
    }
    this.ds.post('form/'+this.form_url + stepUrl, formData).subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.versions = res;
      }
      
    }, err => {
      this.spinner.hide();
      this.sweetAlert.errorMessage(this.lang.transform('lang_server_error'));
    })
  }

  moveToPlatform() {
    if(this.canMoveStep3.value) {
      if(this.SkipPagePlatform) {
        this.stepper.selectedIndex++;
      }
    }
  }

  checkStepMaterialValidation() {
    if(this.requirement_form.get('material_notes').invalid) {
      this.alert.error(this.lang.transform('lang_material_notes_required'));
      return false;
    } else if(this.requirement_form.get('material_code').invalid) {
      this.alert.error(this.lang.transform('lang_material_code_required'));
      return false;
    } else if(this.requirement_form.get('supplier_code').invalid) {
      this.alert.error(this.lang.transform('lang_supplier_list_required'));
      return false;
    } else if(this.requirement_form.get('storage_area').invalid) {
      this.alert.error(this.lang.transform('lang_storage_area_required'));
      return false;
    } else if(this.requirement_form.get('supply_date_to_site').invalid) {
      this.alert.error(this.lang.transform('lang_supply_date_to_site_required'));
      return false;
    } else {
      return true;
    }
  }

  submitMaterialStep() {
    if(this.checkStepMaterialValidation()) {
      let params = new FormData();
      params.append('lang_key', this.lang_form.get('lang_key').value);
      params.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
      params.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
      params.append('supplier_code', this.requirement_form.get('supplier_code').value || '');
      params.append('material_notes', this.requirement_form.get('material_notes').value || '');
      params.append('material_code', this.requirement_form.get('material_code').value || '');
      params.append('storage_area', this.requirement_form.get('storage_area').value || '');
      params.append('supply_date_to_site', this.requirement_form.get('supply_date_to_site').value || '');

      let material = this.materialList.find((el) => {
        return el.value == this.requirement_form.get('material_code').value;
      })
      params.append('material_code_transaction_request_id', material?.transaction_request_id || '');
      let supplier = this.supplierList.find((el) => {
        return el.value == this.requirement_form.get('supplier_code').value;
      })
      params.append('supplier_code_transaction_request_id', supplier?.transaction_request_id || '');
      params.append('template_id', this.template_data?.template_id || this.template_id);
      let stepUrl = '/cr/2/0';
        if(this.editMode) {
          stepUrl = `/cr/2/${this.form_request_id}`;
        }
      this.ds.post('form/'+this.form_url + stepUrl, params).subscribe(res => {
        this.canMoveStep4.setValue(true);
          this.stepper.selectedIndex++;
      });
    }
  }
  
  get filesFormGroup() {
    return this.config_attachment_form.get("attachments") as FormArray;
  }
  
  setEditData() {
    if(this.allData) {
      this.lang_form.get('lang_key').setValue(this.allData?.view_request?.transactions_request?.records?.lang_key);
      this.platform_code_system = this.allData?.view_request?.form_msr_data?.records[0]?.platform_code_system;
      this.projects_work_area_id = this.allData?.view_request?.form_msr_data?.records[0]?.projects_work_area_id;
      this.template_id = this.allData?.view_request?.form_msr_data?.records[0]?.template_id;
      if(this.user_type == '3' || this.user_type == '4') {
        this.config_attachment_form.get('contractor_team_users').setValue(this.allData?.view_request?.form_dsr_data?.records[0]?.contractor_team_users);
        this.config_attachment_form.get('contractor_manager_step_require').setValue(this.allData?.view_request?.form_dsr_data?.records[0]?.contractor_manager_step_require);
      }
    }
  }
  public files(value?, deleteIcon?): FormGroup {
    return this.fb.group({
      delete_icon     : new FormControl(deleteIcon),
      attach_title    : new FormControl({value : (value) ? value?.attach_title : '', disabled : (value?.attach_title) ? true : false}, [Validators.required]),
      file            : new FormControl(null,  [Validators.required]),
    });
  }
  public addfiles(value?, deleteIcon?) {
    if (this.other_attachments.length < 10) {
      this.other_attachments.push(this.files(value, deleteIcon));
      this.other_attachments.setValidators(Validators.required);
      this.other_attachments.updateValueAndValidity();
    }
  }
  public removefiles(index) {
    if(this.other_attachments.length == 1) {
      this.other_attachments.removeAt(index);
      this.other_attachments.clearValidators();
      this.other_attachments.updateValueAndValidity();
    } else {
      this.other_attachments.removeAt(index);
    }
  }
  
  public getUnitsForAll() {
    this.validUnitsForAll = false;
    this.spinner.show();
      let param = new FormData();
      param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
      param.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
      param.append('work_site', this.work_sites_form.get('work_site').value || '');
      param.append('transaction_separation', this.work_sites_form.get('separate')?.value);
      param.append('work_site_type', 'ALL');
      param.append('template_id', this.template_data?.template_id || this.template_id);
      param.append('work_levels', this.work_levels_for_all_control?.value?.toString());
      this.ds.getActionByUrl(this.ds.formData2string(param), 'form/'+this.form_url+'/validate_work_levels').subscribe((res) => {
        this.spinner.hide();
        if(res.status) {
          this.validUnitsForAll = true;
          this.units_for_all = res.data;
        }
      }, error => {
        this.spinner.hide();
        this.alert.error(error);
      })
  }

  public set_validation_step1() {
    this.alert.clear();
    this.work_sites_form.updateValueAndValidity();
  }

  public get_work_levels() {
    
    this.alert.clear();
    this.spinner.show();
    this.work_levels  = [];
  
  let param = new FormData();
  param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
  param.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
  param.append('template_id', this.template_data?.template_id || this.template_id);
  param.append('lang_key', this.lang_form.get('lang_key')?.value);
    this.ds.getActionByUrl(this.ds.formData2string(param), 'form/'+this.form_url+'/get_work_levels_for_transaction').subscribe((res) => {
      this.spinner.hide();
      if(res.status) {
        this.work_levels_for_all = res.records.map((item)=> {
          return item['label'];
        });
        
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
    });
  }

  checkValidation() {
    if((this.work_sites_form.get('work_site').value =="GN" &&
    this.generalNumbersData.length > 0) ||
    (this.work_sites_form.get('work_site').value =="IM" &&
    this.phasesData.length > 0) || (this.work_sites_form.get('work_site').value == 'ALL' && this.work_levels_for_all_control.valid && this.work_levels_for_all?.length && this.validUnitsForAll)) {
      return true;
    } else {
      return false;
    }
  }        
  public send_units_and_work_levels(isPagination:boolean, isHeaderClick:boolean) {
    this.alert.clear();
    this.spinner.show();
      let formData = new FormData();
      formData.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
      formData.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
      formData.append('lang_key', this.lang_form.get('lang_key')?.value);
      formData.append('page_no', this.platform_page_no);
      formData.append('page_size', this.platform_page_size);
      formData.append('template_id', this.template_data?.template_id || this.template_id);

      let stepUrl = '/cr/1/0';
      if(this.editMode) {
        stepUrl = `/cr/1/${this.form_request_id}`;
      }
      this.ds.post('form/'+this.form_url + stepUrl, formData).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.getDefaultAttachments();
          this.platformData = res;
          this.getMaterialSupplierLists('MATERIAL');
          this.getMaterialSupplierLists('SUPPLIER');
          this.canMoveStep2.setValue(true);
          if(res['SkipPage']) {
            this.canMoveStep3.setValue(true);
            this.SkipPagePlatform = true;
          }
          if(res['NextButton']) {
            this.canMoveStep3.setValue(true);
            this.NextButtonPlatform = true;
          } else {
            this.canMoveStep3.setValue(null);
          }
          if(!isPagination || !isHeaderClick) {
            this.stepper.selectedIndex++;
          }
          
        } else {
          this.sweetAlert.errorMessage(res.error);
        }
        
      }, err => {
        this.spinner.hide();
        this.alert.error(err.error);
      })
  }

 //load data by page Size
  public load_by_page_size(size, tableName:string) {
    if(tableName == 'platform') {
      this.platform_page_size = size;
      this.send_units_and_work_levels(true, true);
    }
    if(tableName == 'versions') {
      this.versions_page_size = size;
      this.get_form_versions();
    }
  }
  // load data by page size
  public load_by_page_no(page, tableName:string) {
    //this.platformData = [];
    if(tableName == 'platform') {
      this.platform_page_no = page;
    }
    if(tableName == 'versions') {
      this.versions_page_no = page;
      this.get_form_versions();
    }
  }
  get_contractor_team_users_list() {
    this.contractor_team_users_list  = [];
    let param   = new FormData();
    param.append('lang_key', this.lang_key);
    param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
    param.append('user_position', 'CTT01');
    
    this.ds.getActionByUrl(this.ds.formData2string(param), `form/${this.form_url}/contractor_users`).subscribe(res => {
      if (res.status) {
        this.contractor_team_users_list = res?.records;
      } else {
        this.contractor_team_users_list = [];
      }
    });
  }

  getDefaultAttachments() {
    let param = new FormData();
    param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
    param.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
    param.append('lang_key', this.lang_form.get("lang_key").value);
    param.append('work_site', this.work_sites_form.get('work_site').value);
    
    if(this.work_sites_form.get('work_site').value == 'ALL') {
      for(let key in this.units_for_all) {
        param.append(`units_and_level[${key}]`, this.units_for_all[key]);
      }
    } else if(this.work_sites_form.get('work_site').value == 'GN') {
      let units_and_level_data = this.dwsrService.refinementData(this.generalNumbersData);
      for(let key in units_and_level_data) {
        param.append(`units_and_level[${key}]`, units_and_level_data[key]);
      }
    } else if(this.work_sites_form.get('work_site').value == 'IM') {
      let units_and_level_data = this.dwsrService.refinementData(this.phasesData);
      for(let key in units_and_level_data) {
        param.append(`units_and_level[${key}]`, units_and_level_data[key]);
      }
    }
    this.other_attachments.clear();
    if(this.user_type == "1") {
      this.addfiles({attach_title : 'lang_sign_and_stamp_contractor_request'}, false);
    }
    this.addfiles({attach_title : this.lang.transform('lang_material_specification')}, false);
    
    this.spinner.show();
    this.ds.postActionByUrl(param, 'form/'+this.form_url+'/get_default_attachments').subscribe((res) => {
      this.spinner.hide();
      if(res.status) {
        for(let item of res?.records) {
          this.addfiles({attach_title : item?.label, file: item?.file_path});
        }
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    });
  }

  public send_files_to_step2() {
    this.alert.clear();
    if(this.lang_form.valid && this.canMoveStep4.valid &&this.platformData?.NextButton && this.config_attachment_form.valid) {
      this.spinner.show();
      let param = new FormData();
      param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id || this.projects_work_area_id);
      param.append('platform_code_system', this.template_data?.template_platform_code_system || this.platform_code_system);
      param.append('lang_key', this.lang_form.get("lang_key").value);
      param.append('template_id', this.template_data?.template_id || this.template_id);
      param.append('supplier_code', this.requirement_form.get('supplier_code').value || '');
      param.append('material_notes', this.requirement_form.get('material_notes').value || '');
      param.append('material_code', this.requirement_form.get('material_code').value || '');
      param.append('storage_area', this.requirement_form.get('storage_area').value || '');
      param.append('supply_date_to_site', this.requirement_form.get('supply_date_to_site').value || '');
      if(this.user_type == '3' || this.user_type == '4') {
        param.append('contractor_team_users', this.config_attachment_form.get('contractor_team_users')?.value || '');
        param.append('contractor_manager_step_require', this.config_attachment_form.get('contractor_manager_step_require')?.value);
      }
      let material = this.materialList.find((el) => {
        return el.value == this.requirement_form.get('material_code').value;
      })
      param.append('material_code_transaction_request_id', material?.transaction_request_id || '');
      let supplier = this.supplierList.find((el) => {
        return el.value == this.requirement_form.get('supplier_code').value;
      })
      param.append('supplier_code_transaction_request_id', supplier?.transaction_request_id || '');
      this.filesFormGroup.value.forEach((v, k) => {
        param.set("attachments[" + k + "][file]", v.file);
        param.set("attachments[" + k + "][required]", 'Yes');
        param.set("attachments[" + k + "][attach_title]", this.other_attachments.controls[k].get("attach_title").value);        
      });
      let stepUrl = '/cr/3/0';
      if(this.editMode) {
        stepUrl = `/cr/3/${this.form_request_id}`;
      }
      this.ds.postActionByUrl(param, 'form/'+this.form_url+stepUrl).subscribe((res) => {
        this.spinner.hide();
        if(res.status) {
          this.sweetAlert.successToast(this.lang.transform('lang_form_created_successfully'), 400);
          setTimeout(() => {
            if(this.editMode) {
              this.refreshComponent.emit(true);
            } else {
              this.router.navigate(['/transactions/allforms']);
            }
          }, 500);
        } else {
          this.alert.error(res.error);
        }
      }, error => {
        this.spinner.hide();
        this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      });
    } else {
      this.alert.error(this.lang.transform('lang_fill_required_fields'));
    }
  }
}
