import { NgModule } from '@angular/core';
import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { ProjectsCreateComponent } from './projects-create/projects-create.component';
import { ProjectsEditComponent } from './projects-edit/projects-edit.component';
import { ProjectsViewComponent } from './projects-view/projects-view.component';
import { SharedModule } from '@shared/shared.module';
import { environment } from '@env/environment';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { QuotationsListsComponent } from './services/supervision/quotations-lists/quotations-lists.component';
import { SupervisionListsComponent } from './services/supervision/supervision-lists/supervision-lists.component';
import { TransactionsModule } from '../transactions/transactions.module';
import { SupervisionAlertsComponent } from './services/supervision/supervision-alerts/supervision-alerts.component';
import { SupervisionBillQuantitiesComponent } from './services/supervision/supervision-bill-quantities/supervision-bill-quantities.component';
import { SupervisionContactsComponent } from './services/supervision/supervision-contacts/supervision-contacts.component';
import { SupervisionContractorPaymentsComponent } from './services/supervision/supervision-contractor-payments/supervision-contractor-payments.component';
import { SupervisionContractorsComponent } from './services/supervision/supervision-contractors/supervision-contractors.component';
import { SupervisionDashboardComponent } from './services/supervision/supervision-dashboard/supervision-dashboard.component';
import { SupervisionDocumentsComponent } from './services/supervision/supervision-documents/supervision-documents.component';
import { SupervisionDrawingsComponent } from './services/supervision/supervision-drawings/supervision-drawings.component';
import { SupervisionIPhasesComponent } from './services/supervision/supervision-i-phases/supervision-i-phases.component';
import { SupervisionMobilizationsComponent } from './services/supervision/supervision-mobilizations/supervision-mobilizations.component';
import { SupervisionProjectCompletionComponent } from './services/supervision/supervision-project-completion/supervision-project-completion.component';
import { SupervisionReportsComponent } from './services/supervision/supervision-reports/supervision-reports.component';
import { SupervisionSchedulesComponent } from './services/supervision/supervision-schedules/supervision-schedules.component';
import { SupervisionTeamsComponent } from './services/supervision/supervision-teams/supervision-teams.component';
import { SupervisionTestProofsComponent } from './services/supervision/supervision-test-proofs/supervision-test-proofs.component';
import { PhasesAddComponent } from './services/supervision/supervision-i-phases/phases-add/phases-add.component';
import { PhasesEditComponent } from './services/supervision/supervision-i-phases/phases-edit/phases-edit.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { SiteHandleComponent } from './services/supervision/supervision-mobilizations/site-handle/site-handle.component';
import { MobilizationContractComponent } from './services/supervision/supervision-mobilizations/mobilization-contract/mobilization-contract.component';
import { SupervisionHistoryComponent } from './services/supervision/supervision-history/supervision-history.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { MainContractorsComponent } from './services/supervision/supervision-contractors/main-contractors/main-contractors.component';
import { SubContractorsComponent } from './services/supervision/supervision-contractors/sub-contractors/sub-contractors.component';
import { ContractorUsersComponent } from './services/supervision/supervision-contractors/contractor-users/contractor-users.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SettingsComponent } from './settings/settings.component';
import { CollectionComponent } from './settings/collection/collection.component';
import { CollectionDetailsComponent } from './settings/collection-details/collection-details.component';
import { CdetailsAddComponent } from './settings/collection-details/cdetails-add/cdetails-add.component';
import { CdetailsEditComponent } from './settings/collection-details/cdetails-edit/cdetails-edit.component';
import { FormationAddComponent } from './settings/formation/formation-add/formation-add.component';
import { FormationEditComponent } from './settings/formation/formation-edit/formation-edit.component';
import { FormationViewComponent } from './settings/formation/formation-view/formation-view.component';
import { FormationListsComponent } from './settings/formation/formation-lists/formation-lists.component';
import { Group2Component } from './settings/formation/dynamic/group2/group2.component';
import { PlatformsComponent } from './settings/formation/dynamic/platforms/platforms.component';
import { TypesComponent } from './settings/formation/dynamic/types/types.component';
import { WorklevelsComponent } from './settings/worklevels/worklevels.component';
import { WunitsComponent } from './settings/wunits/wunits.component';
import { WtypesComponent } from './settings/wtypes/wtypes.component';
import { Wgroup1Component } from './settings/wgroup1/wgroup1.component';
import { Wgroup2Component } from './settings/wgroup2/wgroup2.component';
import { WplatformsComponent } from './settings/wplatforms/wplatforms.component';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/custom-material-config';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ConsultantSettingsComponent } from './settings/consultant-settings/consultant-settings.component';
import { CaddEditComponent } from './settings/consultant-settings/cadd-edit/cadd-edit.component';
import { MfilesComponent } from './settings/formation/dynamic/platforms/mfiles/mfiles.component';
import { ProjectWorkareaFormsComponent } from './settings/project-workarea-forms/project-workarea-forms.component';
import { PermissionMentionsComponent } from './settings/permission-mentions/permission-mentions.component';
import { SupervisionMainDashboardComponent } from './services/supervision/supervision-main-dashboard/supervision-main-dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SupervisionCustomersComponent } from './services/supervision/supervision-customers/supervision-customers.component';
import { SupervisionMyTransctionsComponent } from './services/supervision/supervision-my-transctions/supervision-my-transctions.component';
import { PlatformDetailsComponent } from './settings/formation/dynamic/platforms/platform-details/platform-details.component';
import { UplaodsHistoryComponent } from './settings/formation/dynamic/types/uploads-history/uploads-history.component';
import {MatStepperModule} from '@angular/material/stepper';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SupervisionTestReportWorkareaComponent } from './services/supervision/supervision-test-report-workarea/supervision-test-report-workarea.component';
import { TRWDialogComponent } from './services/supervision/supervision-test-report-workarea/trw-dialog/trw-dialog.component';
import { IphasesDialogComponent } from './services/supervision/supervision-i-phases/iphases-dialog/iphases-dialog.component';
import { TrwViewDialogComponent } from './services/supervision/supervision-test-report-workarea/trw-view-dialog/trw-view-dialog.component';
import { TrwHistoryComponent } from './services/supervision/supervision-test-report-workarea/trw-history/trw-history.component';
import {MatChipsModule} from '@angular/material/chips';
import { TemplateDialogComponent } from './services/supervision/supervision-my-transctions/template-dialog/template-dialog.component';
import { ZoneDialogComponent } from './services/supervision/supervision-my-transctions/zone-dialog/zone-dialog.component';
import { CompeletionRateFiltersComponent } from './services/supervision/supervision-main-dashboard/compeletion-rate-filters/compeletion-rate-filters.component';
import { PriceFiltersComponent } from './services/supervision/supervision-main-dashboard/price-filters/price-filters.component';
import { CommunicationComponent } from './services/supervision/communication/communication.component';
import { CompeletionFiltersComponent } from './services/supervision/supervision-i-phases/compeletion-filters/compeletion-filters.component';

const googleMapsParams = {
  apiKey: environment.GOOGLE_MAPS_API_KEY,
  libraries: ['places'],
  language: 'en',
};
const COMPONENTS = [UplaodsHistoryComponent,ProjectsListComponent, ProjectsCreateComponent, ProjectsEditComponent, ProjectsViewComponent, QuotationsListsComponent, SupervisionListsComponent, SupervisionAlertsComponent, SupervisionBillQuantitiesComponent, SupervisionContactsComponent, SupervisionContractorPaymentsComponent, SupervisionContractorsComponent, SupervisionDashboardComponent, SupervisionDocumentsComponent, SupervisionDrawingsComponent, SupervisionIPhasesComponent, SupervisionMobilizationsComponent, SupervisionProjectCompletionComponent, SupervisionReportsComponent, SupervisionSchedulesComponent, SupervisionTeamsComponent, SupervisionTestProofsComponent,PhasesAddComponent, PhasesEditComponent, SiteHandleComponent, MobilizationContractComponent, SupervisionHistoryComponent, MainContractorsComponent, SubContractorsComponent, ContractorUsersComponent,SettingsComponent, CollectionComponent, CollectionDetailsComponent, CdetailsAddComponent, CdetailsEditComponent, FormationAddComponent, FormationEditComponent, FormationViewComponent, FormationListsComponent,Group2Component, PlatformsComponent, TypesComponent, WorklevelsComponent, WunitsComponent, WtypesComponent, Wgroup1Component, Wgroup2Component, WplatformsComponent, ConsultantSettingsComponent, CaddEditComponent, MfilesComponent, ProjectWorkareaFormsComponent, PermissionMentionsComponent, SupervisionMainDashboardComponent, SupervisionCustomersComponent];
const COMPONENTS_DYNAMIC = [];

@NgModule({
  imports: [SharedModule, ProjectsRoutingModule, NgApexchartsModule, AgmCoreModule.forRoot(googleMapsParams),MatGoogleMapsAutocompleteModule, ModalModule.forRoot(),AngularMultiSelectModule, TransactionsModule, TreeModule, NgbPaginationModule, MatStepperModule, NgxSkeletonLoaderModule, MatChipsModule],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, MfilesComponent, ProjectWorkareaFormsComponent, PermissionMentionsComponent, SupervisionMainDashboardComponent, SupervisionMyTransctionsComponent, PlatformDetailsComponent, SupervisionTestReportWorkareaComponent, TRWDialogComponent, IphasesDialogComponent, TrwViewDialogComponent, TrwHistoryComponent, TemplateDialogComponent, ZoneDialogComponent, CompeletionRateFiltersComponent, PriceFiltersComponent, CommunicationComponent, CompeletionFiltersComponent],
  entryComponents: COMPONENTS_DYNAMIC,
  providers : [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ]
})
export class ProjectsModule { }