import { NgModule } from '@angular/core';
import { ContractorsRoutingModule } from './contractors-routing.module';
import { CtsettingsComponent } from './ctsettings/ctsettings.component';
import { CtcreateComponent } from './ctcreate/ctcreate.component';
import { CtlistComponent } from './ctlist/ctlist.component';
import { CtviewComponent } from './ctview/ctview.component';
import { CteditComponent } from './ctedit/ctedit.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/custom-material-config';
import { SharedModule } from '@shared';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CtNotesComponent } from './ct-notes/ct-notes.component';
import { CtAttachmentsComponent } from './ct-attachments/ct-attachments.component';
import { CtUsersComponent } from './ct-users/ct-users.component';

const COMPONENTS = [CtsettingsComponent, CtcreateComponent, CtlistComponent, CtviewComponent, CteditComponent];
const COMPONENTS_DYNAMIC = [];
@NgModule({
  imports: [
    SharedModule,
    ContractorsRoutingModule,
    ModalModule.forRoot()
  ],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, CtNotesComponent, CtAttachmentsComponent, CtUsersComponent],
  entryComponents: COMPONENTS_DYNAMIC,
  providers : [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ]
})
export class ContractorsModule { }
