<section class="panel-expansion matborderno" *ngIf="branch_id && branch_id !='0' ">
    <mat-accordion class="accounts_settings" >
        <mat-expansion-panel class="mpanel" [expanded]="true" [disabled]="true">
            <i class="together-icon">
                <i class="fa fa-plus faadd mnabricon" *ngIf="ds.allowMe('accounts', 'accounts_add')" (click)="emptyinvformdata('ADD');openModal(InvSettingsOperation);" matTooltip="{{'lang_add_settings' | language }}"></i>
            </i>
            <mat-expansion-panel-header class="mpanel-header">{{'lang_invoice_settings' | language }}</mat-expansion-panel-header>
            <div class="material-panel-body">
                <div class="mbrl15">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <select class="form-control almnabrformcontrol" [(ngModel)]="searchType" (change)="spinner.show();load_invoice_settings();" matTooltip="{{'lang_search_type' | language }}">
                                <option value="">{{'lang_all' | language }}</option>
                                <option value="SINV">{{'lang_sales_invoice' | language }}</option>
                                <option value="PINV">{{'lang_purchase_invoice' | language }}</option>
                            </select>
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-12 mb-10">
                            <input type="text" placeholder="{{'lang_search' | language }}" [(ngModel)]="searchKey" (keyup)="load_invoice_settings();" class="form-control almnabrformcontrol"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive customResponsive">
                                <table border="1" class="table CustomTable">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>{{'lang_payment_mode' | language }}</td>
                                            <td>{{'lang_invoice_type' | language }}</td>
                                            <td>{{'lang_ondate' | language }}</td>
                                            <td>{{'lang_writer' | language }}</td>
                                            <td>{{'lang_action' | language }}</td>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="asinvdata.length != '0' " class="AppendList">
                                        <tr *ngFor="let data of asinvdata; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{data.invoice_setting_id}}">
                                            <td>{{i+1}}</td>
                                            <td>{{data.payment_mode_name}}</td>
                                            <td *ngIf="data.invoice_type =='SINV' ">{{'lang_sales_invoice' | language }} </td>
                                            <td *ngIf="data.invoice_type =='PINV' ">{{'lang_purchase_invoice' | language }}</td>
                                            <td>{{data.invoice_created_datetime}}</td>
                                            <td>{{data.name}}</td>
                                            <td>
                                                <i class="fa fa-eye faicon mnabricon s2icon" matTooltip="{{'lang_view' | language }}" (click)="showASInvData(data)"></i>
                                                <i class="fa fa-pencil-square-o faicon mnabricon s2icon" *ngIf="ds.allowMe('accounts', 'accounts_edit')" (click)="editASInvData(data)" matTooltip="{{'lang_edit' | language }}"></i>
                                                <i class="fa fa-trash faicon red mnabricon s2icon" *ngIf="ds.allowMe('accounts', 'accounts_delete')" (click)="deleteInvData(data)" matTooltip="{{'lang_edit' | language }}"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="asinvdata.length == '0' " class="AppendList">
                                        <tr class="odd">
                                            <td colspan="7" align="center">{{lodingdatas}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
<ng-template #viewASInvModal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_account_settings' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRef1.hide();">&times;</button>
        </div>
        <div class="modal-body" *ngIf="viewdata">
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_type' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b *ngIf="viewdata.invoice_type =='SINV' ">{{'lang_sales_invoice' | language }}</b>
                        <b *ngIf="viewdata.invoice_type =='PINV' ">{{'lang_purchase_invoice' | language }}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_payment_mode' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{viewdata.payment_mode_name}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.customer_accounts">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_customer_accounts' | language }}:</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngFor="let cc of viewdata.customer_accounts">{{cc.label}}<br></b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.income_accounts">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_income_accounts' | language }}:</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngFor="let cc of viewdata.income_accounts">{{cc.label}}<br></b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.expanse_accounts">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_expanse_accounts' | language }}:</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngFor="let cc of viewdata.expanse_accounts">{{cc.label}}<br></b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.advance_accounts">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_advance_accounts' | language }}:</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngFor="let cc of viewdata.advance_accounts">{{cc.label}}<br></b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.discount_accounts">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_discount_accounts' | language }}:</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall" *ngFor="let cc of viewdata.discount_accounts">{{cc.label}}<br></b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_writer' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{viewdata.name}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_created_date' | language }} :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{viewdata.invoice_created_datetime}}</b>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="viewdata.invoice_updated_datetime">
                <div class="row">
                    <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_onupdate' | language }}
                        :</label>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <b class="breakall">{{viewdata.invoice_updated_datetime}}</b>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal"  (click)="modalRef1.hide();">{{'lang_close' | language }}</button>
        </div>
    </div>
</ng-template>
<ng-template #InvSettingsOperation>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_account_settings' | language}}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modelRef.hide();">&times;</button>
        </div>
        <form [formGroup]="form" class="from" (ngSubmit)="submit();">
            <div class="modal-body">            
                <input type="hidden" formControlName="branch_id">
                <div class="form-group">
                    <label for="invoice_type">{{'lang_type' | language}}:<span class="red">*</span></label>
                    <select #invoice_type type="text" required id="invoice_type" class="form-control almnabrformcontrol invoice_type"
                        matTooltip="{{'lang_type' | language}}" formControlName="invoice_type">
                        <option value="">{{'lang_choose_options' | language}}</option>
                        <option value="SINV">{{'lang_sales_invoice' | language}}</option>
                        <option value="PINV">{{'lang_purchase_invoice' | language}}</option>
                    </select>
                    <mat-error
                        *ngIf="form.controls['invoice_type'].touched && form.controls['invoice_type'].errors?.required">
                        {{'lang_field_required' | language}}
                    </mat-error>
                </div>
                <div class="form-group">
                    <label for="payment_mode">{{'lang_payment_mode' | language}}:<span class="red">*</span></label>
                    <select type="text" #payment_mode required id="payment_mode" class="form-control almnabrformcontrol payment_mode"
                        matTooltip="{{'lang_payment_mode' | language}}" formControlName="payment_mode">
                        <option *ngFor="let pay of paymodes" value="{{pay.value}}">{{pay.label}}</option>
                    </select>
                    <mat-error
                        *ngIf="form.controls['payment_mode'].touched && form.controls['payment_mode'].errors?.required">
                        {{'lang_field_required' | language}}
                    </mat-error>
                </div>
                <div class="form-group">
                    <label for="customer_accounts">{{'lang_customer_accounts' | language}}:</label>
                    <angular2-multiselect formControlName="customer_accounts" [data]="caccounts"
                        [settings]="settings">
                        <c-search>
                            <ng-template>
                                <input type="text" (keyup)="searchAccounts($event, 'caccounts')"
                                    style="border: none;width: 100%; height: 100%;outline: none;" />
                            </ng-template>
                        </c-search>
                        <c-item>
                            <ng-template let-item="item">
                                <label *ngIf="item.label" style="color: #333;width: 150px;">{{item.label}}</label>
                            </ng-template>
                        </c-item>
                    </angular2-multiselect>
                </div>
                <div class="form-group" *ngIf="form.get('invoice_type').value == 'SINV' ">
                    <label for="income_accounts">{{'lang_income_accounts' | language}}:</label>
                    <angular2-multiselect formControlName="income_accounts" [data]="iaccounts"
                        [settings]="settings">
                        <c-search>
                            <ng-template>
                                <input type="text" (keyup)="searchAccounts($event, 'iaccounts')"
                                    style="border: none;width: 100%; height: 100%;outline: none;" />
                            </ng-template>
                        </c-search>
                        <c-item>
                            <ng-template let-item="item">
                                <label *ngIf="item.label" style="color: #333;width: 150px;">{{item.label}}</label>
                            </ng-template>
                        </c-item>
                    </angular2-multiselect>
                </div>
                <div class="form-group" *ngIf="form.get('invoice_type').value == 'PINV' ">
                    <label for="expanse_accounts">{{'lang_expanse_accounts' | language}}:</label>
                    <angular2-multiselect formControlName="expanse_accounts" [data]="eaccounts"
                        [settings]="settings">
                        <c-search>
                            <ng-template>
                                <input type="text" (keyup)="searchAccounts($event, 'eaccounts')"
                                    style="border: none;width: 100%; height: 100%;outline: none;" />
                            </ng-template>
                        </c-search>
                        <c-item>
                            <ng-template let-item="item">
                                <label *ngIf="item.label" style="color: #333;width: 150px;">{{item.label}}</label>
                            </ng-template>
                        </c-item>
                    </angular2-multiselect>
                </div>
                <div class="form-group">
                    <label for="advance_accounts">{{'lang_advance_accounts' | language}}:</label>
                    <angular2-multiselect formControlName="advance_accounts" [data]="aaccounts"
                        [settings]="settings">
                        <c-search>
                            <ng-template>
                                <input type="text" (keyup)="searchAccounts($event, 'aaccounts')"
                                    style="border: none;width: 100%; height: 100%;outline: none;" />
                            </ng-template>
                        </c-search>
                        <c-item>
                            <ng-template let-item="item">
                                <label *ngIf="item.label" style="color: #333;width: 150px;">{{item.label}}</label>
                            </ng-template>
                        </c-item>
                    </angular2-multiselect>
                </div>
                <div class="form-group">
                    <label for="discount_accounts">{{'lang_discount_accounts' | language}}:</label>
                    <angular2-multiselect formControlName="discount_accounts" [data]="daccounts"
                        [settings]="settings">
                        <c-search>
                            <ng-template>
                                <input type="text" (keyup)="searchAccounts($event, 'daccounts')"
                                    style="border: none;width: 100%; height: 100%;outline: none;" />
                            </ng-template>
                        </c-search>
                        <c-item>
                            <ng-template let-item="item">
                                <label *ngIf="item.label" style="color: #333;width: 150px;">{{item.label}}</label>
                            </ng-template>
                        </c-item>
                    </angular2-multiselect>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRef.hide()">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>