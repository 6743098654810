import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

declare var $:any;
@Component({
  selector: 'app-all-files',
  templateUrl: './all-files.component.html',
  styleUrls: ['./all-files.component.scss']
})
export class AllFilesComponent implements OnInit {
	modalRefE: BsModalRef;
  	uform: FormGroup;
	apage                     = 1;
	apsize                    = 10;
	myattachments: any        = [];
	nodata: string            = this.lang.transform('lang_loading');
  	admin                     = JSON.parse(this.ls.getItem('is_admin'));
	attachdata: any           = [];
	attacheditdata: any       = []
	updatestatus: any         = false;
	updateerror: any          = "";
	formstatus: boolean       = false;
	formerror: string         = "";
  //lists
	types: any                = [];
  	levels:any                = [];
  	modules:any               = [];
  // filters
	searchKey                 = "";
	attachment_type:any       = null;
  	level_key:any             = null;
  	module_key:any            = null;
	constructor(public ds: DataService, public router: Router, public route: ActivatedRoute, public lang: LanguagePipe, public ls: LocalStorage, public fb: FormBuilder, public spinner: PreloaderService, public alert: AlertService, public dialog: MatDialog, public modalService: BsModalService) {}
	ngOnInit(): void {
    this.attachment_types();
    this.get_attachments();
		this.file_permission_levels();
    this.allmodule();
		this.uform = new FormGroup({
			file_records_id           	: new FormControl('', [Validators.required]),
			level_keys   				        : new FormControl('', [Validators.required]),
		});
	}
  openModalE(template: TemplateRef < any > ) {
		this.modalRefE = this.modalService.show(template, environment.modelconfig);
	}
	public attachment_types() {
		this.types = [];
    let param = new FormData();
    param.append('module', '');
		this.ds.getActionByUrl(this.ds.formData2string(param), "module_attach_types").subscribe(res => {
			if (res.status) {
				this.types = res.records;
			}
		});
	}
	public file_permission_levels() {
		this.levels = [];		
		this.ds.getActionByUrl([], "lflevel").subscribe(res => {
			if (res.status) {
				this.levels = res.records;
			}
		});
	}
  public allmodule() {
		this.modules = [];		
		this.ds.postActionByUrl([], "module").subscribe(res => {
			if (res.status) {
				this.modules = res.records;
			}
		});
	}
	public attachpage(size) {
		this.apsize = size;
		this.apage = 1;
		this.get_attachments();
	}
	public attachlength(page) {
		this.apage = page;
		this.get_attachments();
	}
	public search_my_attachments() {
		this.apage = 1;
		this.apsize = 10;
		this.get_attachments();
	}
	public get_attachments(): void {
		let formdata = new FormData();
		formdata.append('search_key', this.searchKey);
		formdata.append('attachment_type', this.attachment_type || '');
    formdata.append('module_key', this.module_key || '');
    formdata.append('level_key', this.level_key || '');		
		this.ds.getActionByUrl(this.ds.formData2string(formdata), "frecords/list/" + this.apage + '/' + this.apsize).subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.myattachments    = res.records;
				this.attachdata       = res;
			} else {
				this.myattachments    = [];
				this.attachdata       = [];
				this.nodata   = res.error;
			}
		}, error => {
			this.spinner.hide();
			this.myattachments      = [];
			this.attachdata         = [];
			this.nodata     = error.error;
		})
	}
	public edit_my_Attach(attach) {
		this.attacheditdata   = attach;
		this.uform.patchValue({
			file_records_id           	: attach.file_records_id,
			level_keys					        : attach.level_keys.split(','),
			attachment_descriptions   	: attach.file_name,
		});
	}
	public update() {
		this.spinner.show();
		this.ds.putActionByUrl(this.ds.formData2string(this.ds.json2formdata(this.uform.value)), 'frecords/update/'+this.uform.get('file_records_id').value).subscribe(data => {
			this.spinner.hide();
			if (data.status) {
				this.get_attachments();
				setTimeout(() => {
					this.modalRefE.hide();
				}, 1000);
				this.alert.success(data.msg);
			} else {
				this.alert.error(data.error);
			}
		}, error => {
			this.spinner.hide();
			this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
		})
	}
	public checkAllItemsAth(event) {
		if (event.target.checked) {
			$(".attach_panel .eachItemAth").prop("checked", true);
			$(".attach_panel .makeback").addClass("bground");
			$(".attach_panel .deleteIconDiv").show();
		} else {
			$(".attach_panel .eachItemAth").prop("checked", false);
			$(".attach_panel .makeback").removeClass("bground");
			$(".attach_panel .deleteIconDiv").hide();
		}
	}
	public checkEachItemAth(event) {
		if (event.target.checked) {
			const val = event.target.value;
			$(".attach_panel .removerow" + val).addClass("bground");
		} else {
			const val = event.target.value;
			$(".attach_panel .removerow" + val).removeClass("bground");
		}
		const checkedtotal = [];
		$(".attach_panel .eachItemAth:checked").each(function() {
			checkedtotal.push($(this).val());
		});
		const totalrow = [];
		$(".attach_panel .eachItemAth").each(function() {
			totalrow.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			$(".attach_panel .deleteIconDiv").show();
		} else {
			$(".attach_panel .deleteIconDiv").hide();
		}
		if (checkedtotal.length === totalrow.length) {
			$(".attach_panel #allItemsAth").prop("checked", true);
		} else {
			$(".attach_panel #allItemsAth").prop("checked", false);
		}
	}
	public deleteBulkDataAth() {
		const checkedtotal = [];
		$(".attach_panel .eachItemAth:checked").each(function() {
			checkedtotal.push($(this).val());
		});
		if (checkedtotal.length > 0) {
			const dialogRef = this.dialog.open(ConfirmationDialog, {
				disableClose: true,
				width: '400px',
				data: {
					title: this.lang.transform('lang_confirmation'),
					message: this.lang.transform('lang_are_you_sure'),
				}
			});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.spinner.show()
					this.ds.deleteActionByUrl(checkedtotal, 'frecords/delete').subscribe(
						(data) => {
							this.spinner.hide()
							if (data.status) {
								this.search_my_attachments();
								$(".attach_panel #allItemsAth").prop("checked", false);
								$(".attach_panel .deleteIconDiv").hide();
								this.ds.dialogf('', data.msg);
							} else {
								this.ds.dialogf('', data.error);
							}
						},
						(error) => {
							this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
							this.spinner.hide()
						}
					);
				}
			});
		} else {
			this.ds.dialogf();
		}
	}
}
