import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, ValidatorFn } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import * as moment from 'moment';


  
@Component({
  selector: 'app-createOverTIme',
  templateUrl: './createOverTIme.component.html',
  styleUrls: ['./createOverTIme.component.scss']
})
export class CreateOverTImeComponent implements OnInit {

  @Output() loanEvent = new EventEmitter();
  @Input() employee_data;
  @Input() form_request_id;
  @Input() form_key;
  users:any         = [];
  direct_anager:any = [];
  CreateSubmitted:boolean = false;
  min = new Date();
  form:FormGroup;
  submitted:boolean = false;
  lang_key          = localStorage.getItem('lang_key') || 'en';
  constructor(public ds:DataService, public datepipe : DatePipe ,
    private snackBar:MatSnackBar,
    public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.spinner.show();

    this.form = this.fb.group({
      financial_type:['Over Time'],
      employee_number: [this.employee_data.employee_number],
 
      direct_manager:[{value:'',disabled:this.direct_anager.length > 0 ? true:false}, [Validators.required]],
      createOverTimes: this.fb.group({  
        overtime_date_english:['', Validators.compose([ Validators.required ])],
        overtime_date_arabic:['', Validators.compose([])],
        overtime_hours:['', Validators.compose([Validators.required])],
        error_message :[''],
        overtime_amount:['', Validators.compose([])],
    
       
    } ),
    overTimes: this.fb.array([])
   
    });
    
    this.spinner.hide();
    console.log(this.form);
    
  }
  
 
  getThisMonthSalary(){
    this.CreateSubmitted  = true;
    const creds = this.form.get("overTimes") as FormArray;
       
    const val = this.form.get("createOverTimes") as FormGroup;

    this.spinner.show();

    for (let index = 0; index < creds.value.length; index++) {
      const element = creds.value[index];
      if(moment(element.overtime_date_english).isSame(val.value.overtime_date_english)){
      
        this.showMsg('lang_date_theSame_error', 'ok', 'error-snackbar');
        this.spinner.hide();
        return 0;
      }
    }

 


    let formData = new FormData();
    formData.append('employee_number',this.employee_data.employee_number);
  
    formData.append('overtime_date',moment(this.form.value.createOverTimes.overtime_date_english).format());  
    formData.append('overtime_hours',this.form.value.createOverTimes.overtime_hours);
    this.ds.post(`form/${this.form_key}/check_overtime_for_employee`, formData).subscribe(data => {
    

 
      this.form.controls.createOverTimes.patchValue({
        ...this.form?.controls?.createOverTimes.value ,
        overtime_amount: data.data
      })
   

   
  
      if(this.form?.controls?.createOverTimes.status == 'VALID'){
  
     
  
   

   
  
  
        creds.push(
          this.fb.group({
            overtime_date_arabic:[val.value.overtime_date_arabic, Validators.compose([])],
            overtime_date_english:[val.value.overtime_date_english, Validators.compose([Validators.required])],
            overtime_hours:[val.value.overtime_hours, Validators.compose([])],
            errorMessage:[''],
            overtime_amount:[val.value.overtime_amount, Validators.compose([Validators.required])],
      
            trackingId: this.generateUniqueId()
         
        })
        );
   
      
       
        
      
        val.reset();
        this.CreateSubmitted  = false;
        console.log(this.form);
        
      }else{
        this.showMsg('Please fill all fields first', 'ok', 'error-snackbar');
      }
      
      this.spinner.hide();



    }, (error) => {
      this.spinner.hide();
        this.alert.error(error);
    })
  }
 
  monthes = []

  monthesAfterSearch = []

  checkFor50Percent(){
    const val = this.form.get("createOverTimes") as FormGroup;
    /* if(val.value.amount && val.value.month_salary && +val.value.amount > (+val.value.month_salary) ){
      
     val.patchValue({
       ...val.value,
       amount: Number(val.value.amount.toString().slice(0, -1)) > val.value.month_salary? val.value.month_salary/2 : val.value.amount.toString().slice(0, -1) ,
       error_message:'lang_you_must_not_enter_num_bigger_than_salary_error_message'
     })
     console.log(val);
      
   }else  */if(val.value.amount && val.value.month_salary && +val.value.amount > (+val.value.month_salary/2)){
    val.patchValue({
      ...val.value,
      error_message:'lang_50_percent_error_message'
    })
   }else{
    val.patchValue({
      ...val.value,
      error_message:''
    })
   }
  }

  getControl(index:number){
    
    return this.form.controls.overTimes['controls'][index] as FormGroup
  }


  getItems(index:any){

   let data = this.form.get("overTimes") as FormArray;
 
   return this.monthesAfterSearch.push({
      label:data.value[index].month,
      value:data.value[index].month
    })
  }
 

  showMsg(msg:string, action:string, className:string) {
    this.snackBar.open(msg, action, {
      duration:4000,
      panelClass: [className]
    });
  }
  trackByFnCustom(index: number, item: any) {
    return item.trackingId;
  }
  generateUniqueId() {
    return (
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
    );
  }
 
  mon

  deleteCreds(index,cred){
     
   
 
    const creds = this.form.get("overTimes") as FormArray;
 
       this.monthesAfterSearch  = [...this.monthesAfterSearch,{
        "label":cred.month,
        "value":cred.month
      }]
        creds.removeAt(index);
  
    
         console.log(this.form);
     
  
         this.changeDetectorRef.detectChanges()
     
   
   }
 



   
  public isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if(charCode == 101 ||charCode == 69){
      return false;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}

submitForm(){

  console.log(this.form);
  
  this.form.controls.createOverTimes.disable();
  if(this.form?.status == 'VALID'){

 

    const creds = this.form.get("overTimes") as FormArray;
    




    this.spinner.show();
    let formData = new FormData();
    formData.append('employee_number',this.employee_data.employee_number);

  
    formData.append('direct_manager', this.form.value.direct_manager || '');
    this.form.value.overTimes.forEach((overTime,index) => {
   
      formData.append(`over_records[${index+1}][overtime_date_english]`,moment(overTime.overtime_date_english).format('YYYY/MM/DD'));
      formData.append(`over_records[${index+1}][overtime_date_arabic]`,overTime.overtime_date_arabic);
      formData.append(`over_records[${index+1}][overtime_hours]`,overTime.overtime_hours);
      formData.append(`over_records[${index+1}][overtime_amount]`,overTime.overtime_amount);  
    

    });
    
    
 
    this.ds.post(`form/${this.form_key}/cr/0`, formData).subscribe(data => {
     if(data.status) {
        
      console.log('res',data);
      
      this.alert.success(data.msg);
      this.form.reset()
      setTimeout(() => {
        this.router.navigate(['transactions/allforms/']);
      }, 1000);
      this.spinner.hide();
     
      } else {
        this.form.controls.createOverTimes.enable();
        this.spinner.hide();
        this.alert.error(data?.error);
      } 

 
    }, (error) => {
      this.spinner.hide();
      this.form.controls.createOverTimes.enable();
        this.alert.error(error);
    })
 
 
    
  }else{
    this.form.controls.createOverTimes.enable();
    this.showMsg('Please fill all fields first', 'ok', 'error-snackbar');
  }
}


public search_users(key) {
  if(key){
    this.users = [];
    let param = new FormData();
    param.append('search', key.term);
    param.append('lang_key', this.lang_key);
    param.append('user_type_id', '1');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.users = res.list;
      }
    })
  }

}

}
