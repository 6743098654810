import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  HostBinding,
  ElementRef,
  Inject,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT, formatDate } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Directionality } from '@angular/cdk/bidi';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { SettingsService, AppSettings } from '@core';
import { AppDirectionality } from '@shared';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
const MOBILE_MEDIAQUERY = 'screen and (max-width: 959px)'; // 'screen and (max-width: 599px)';
/* const TABLET_MEDIAQUERY = 'screen and (min-width: 600px) and (max-width: 959px)'; */
const MONITOR_MEDIAQUERY = 'screen and (min-width: 960px)';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  time;
  msg;
  hours;
  livedate:string;
  today = formatDate(new Date(), 'yyyy/MM/dd', 'en');
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild('content', { static: true }) content: MatSidenavContent;
  options = this.settings.getOptions();
  direction = localStorage.getItem('direction');
  private layoutChangesSubscription: Subscription;
  get isOver(): boolean {
    return this.isMobileScreen;
  }
  private isMobileScreen = false;
  @HostBinding('class.matero-content-width-fix') get contentWidthFix() {
    return (
      this.isContentWidthFixed &&
      this.options.navPos === 'side' &&
      this.options.sidenavOpened &&
      !this.isOver
    );
  }
  private isContentWidthFixed = true;
  @HostBinding('class.matero-sidenav-collapsed-fix') get collapsedWidthFix() {
    return (
      this.isCollapsedWidthFixed &&
      (this.options.navPos === 'top' || (this.options.sidenavOpened && this.isOver))
    );
  }
  private isCollapsedWidthFixed = true;
  constructor(
    public lang : LanguagePipe,
    public ds:DataService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private overlay: OverlayContainer,
    private element: ElementRef,
    private settings: SettingsService,
    @Optional() @Inject(DOCUMENT) private document: Document,
    @Inject(Directionality) public dir: AppDirectionality
  ) {
    this.dir.value = this.options.dir;
    this.document.body.dir = this.dir.value;
    this.layoutChangesSubscription = this.breakpointObserver
      .observe([MOBILE_MEDIAQUERY, MONITOR_MEDIAQUERY]) // ,TABLET_MEDIAQUERY
      .subscribe(state => {
        this.options.sidenavOpened = true;
        this.isMobileScreen = state.breakpoints[MOBILE_MEDIAQUERY];
        /* this.options.sidenavCollapsed = state.breakpoints[TABLET_MEDIAQUERY]; */
        this.isContentWidthFixed = state.breakpoints[MONITOR_MEDIAQUERY];
    });
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.content.scrollTo({ top: 0 });
      }
    });
    this.receiveOptions(this.options);
    setInterval(() => {
        this.time = new Date();
    }, 1000);
    this.clock_message_decide();
    this.get_arabic_date();
  }
  ngOnInit() {
    setTimeout(() => (this.isContentWidthFixed = this.isCollapsedWidthFixed = false));
  }
  ngOnDestroy() {
    this.layoutChangesSubscription.unsubscribe();
  }
  toggleCollapsed() {
    this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
    this.resetCollapsedState();
  }
  resetCollapsedState(timer = 400) {
    setTimeout(() => {
      this.settings.setNavState('collapsed', this.options.sidenavCollapsed);
    }, timer);
  }
  sidenavCloseStart() {
    this.isContentWidthFixed = false;
  }
  sidenavOpenedChange(isOpened: boolean) {
    this.options.sidenavOpened = isOpened;
    this.settings.setNavState('opened', isOpened);
    this.isCollapsedWidthFixed = !this.isOver;
    this.resetCollapsedState();
  }
  receiveOptions(options: AppSettings): void {
    this.options = options;
    this.toggleDarkTheme(options);
    this.toggleDirection(options);
  }
  toggleDarkTheme(options: AppSettings) {
    if (options.theme === 'dark') {
      this.element.nativeElement.classList.add('theme-dark');
      this.overlay.getContainerElement().classList.add('theme-dark');
      this.element.nativeElement.classList.remove('theme-light');
      this.overlay.getContainerElement().classList.remove('theme-light');
    } else {
      this.element.nativeElement.classList.remove('theme-dark');
      this.overlay.getContainerElement().classList.remove('theme-dark');
      this.element.nativeElement.classList.add('theme-light');
      this.overlay.getContainerElement().classList.add('theme-light');
    }
  }
  toggleDirection(options: AppSettings) {
    this.dir.value = options.dir;
    this.document.body.dir = this.dir.value;
  }
  clock_message_decide() {
    this.hours = new Date().getHours();
    if(this.hours < 10) {
      this.msg = this.lang.transform('lang_good_morning');
    } else if(this.hours < 16){
      this.msg = this.lang.transform('lang_good_afternoon');
    } else if(this.hours < 19) {
      this.msg = this.lang.transform('lang_good_evening');
    } else if(this.hours < 24) {
      this.msg = this.lang.transform('lang_good_night');
    }
  }
  public get_arabic_date() {
    this.ds.arabicToGiorgen('EN', formatDate(new Date(), 'dd/MM/yyyy', 'en')).subscribe(date => {
      if(date.status) {
        const d = date.data.hijri.date.split('-');
        let arabdate = d[2] + '/' + d[1] + '/' + d[0];
        this.livedate = this.today + ' - ' + arabdate;
      } else {
        this.livedate = "";
      }
    })
  }
}
