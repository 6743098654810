import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PlatformDetailsComponent } from './platform-details/platform-details.component';
declare var $:any;
@Component({
  selector: 'app-platforms',
  templateUrl: './platforms.component.html',
  styleUrls: ['./platforms.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlatformsComponent),
      multi: true
    }
  ]
})
export class PlatformsComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() project_data;
  @Input() supervision_data;
  @Input() tree_data;
  @Input() step1formvalue;
  @Input() weightunits;
  @Input() typeIndex;
  @Input() group1Index;
  @Input() group2Index;
  @Input() access_codes;
  @Input() maintotalamount;
  @Input() this_template_total_cost;
  @Input() step2formvalue;
  @Input() actionMode;
  @Input() platforms_data;
  @Input() type_code;
  @Input() fconsultants;
  @Input() consultantr;
  @Input() positions;
  @Input() buildingCodes;
  @Input() ncrSiIsssues;

  modalRef: BsModalRef;

  @Input() step2form:FormGroup;
  @Output() totalEvent = new EventEmitter<any>();
  form:FormGroup;
  platformformarray:FormArray;
  worklevels:any = [];
  search_list_access_codes:any = [];
  lang_key = localStorage.getItem("lang_key") || "en";
  worklevelarray:FormArray;
  //extra Type
  modalRefP: BsModalRef;
  ConsultantData = []
  extraplatformslist:any = []; //array list
  extraplatformsdata:any = null; //ngmodel
  filerequired:boolean = true;
  isFile:boolean = false;
  filename = this.lang.transform('lang_select_files');
  @Output() platforms_total_ratio = new EventEmitter<any>();
  constructor(private cdr: ChangeDetectorRef, public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  writeValue(values: any) {
    if(values) {
      this.platformformarray.controls = [];
      this.platformvalidate.controls  = [];
      values.forEach((v, k) => {
        this.platformformarray.push(this.platform_records({
          platform_group1_code_system               : v.platform_group1_code_system,
          platform_group_type_code_system           : v.platform_group_type_code_system,
          platform_group2_code_system               : v.platform_group2_code_system,
          platform_code_system                      : v.platform_code_system,
          platform_title                              : v.platform_title,
          platform_code_manual                      : v.platform_code_manual,
          platform_title_manual                     : v.platform_title_manual,
          platform_code_manual_2                    : v?.platform_code_manual_2,
          platform_title_manual_2                   : v?.platform_title_manual_2,
          platform_item_specifications              : v.platform_item_specifications,
          platform_item_specification_attachment    : null,
          platform_item_specification_attachment_s  : v.platform_item_specification_attachment_s || 0,
          platform_code_systems                     : (v.platform_code_systems) ? v.platform_code_systems.toString() : '',
          template_platform_building_codes          : (v.template_platform_building_codes) ? v.template_platform_building_codes.toString() : '',          
          consultant_required                       : v?.consultant_required,
          receipient_positions                      : v?.receipient_positions,
          authorized_positions                      : v?.authorized_positions,
          consultant_recomendations                 : v?.consultant_recomendations,
          shop_drawing_linking_codes                : v?.shop_drawing_linking_codes,
          asbuild_drawing_linking_codes             : v?.asbuild_drawing_linking_codes,
          tender_drawing_attachments                : null,
          platform_date_start                       : this.ds.encon(v.platform_date_start),
          platform_date_end                         : this.ds.encon(v.platform_date_end),
          projects_platform_weights_unit_code       : v.projects_platform_weights_unit_code,
          unit_quantity                             : v.unit_quantity,
          unit_price                                : v.unit_price,
          total_price                               : v.total_price,
          unit_ratio                                : v.unit_ratio,
          total_ratio                               : v.total_ratio,
          ncr_si_issues                             : v?.ncr_si_issues
        }));
        if(this.step1formvalue['work_levels'] && this.step1formvalue['work_levels'].length > 0) {
          this.step1formvalue['work_levels'].forEach((m, n) => {
            let l = (this.platformvalidate && this.platformvalidate.length > 0) ? this.platformvalidate.length - 1 : k;
            this.platformwlevels(l).push(this.newwlevel(v.work_levels_multiple[n]));
          });
        }
        setTimeout(() => {
          this.field_abstract_control(this.platformvalidate.controls[k], k, 'edit');
          this.calculate_total_price(this.platformvalidate.controls[k]);
        }, 500);
      });
    }
  }
  registerOnChange(fn: any) {
    fn(this.platformvalidate.value);
    this.form.valueChanges.subscribe(x => {
      fn(this.platformvalidate.value);
    })
  }
  registerOnTouched(fn: any){
    fn(this.platformvalidate.value);
    this.form.valueChanges.subscribe(x => {
      fn(this.platformvalidate.value);
    })
  }
  ngOnInit(): void {
    this.search_list_access_codes   = this.access_codes;
    this.tree_data                  = this.tree_data;
    this.worklevels                 = this.step1formvalue['work_levels'];
    this.form = new FormGroup({
        platformarray : this.fb.array([]),
    });
    this.platformformarray          = this.form.get("platformarray") as FormArray;
    this.appendcontrols();
    setTimeout(() => {
      this.make_total_calculations();
    }, 500);
    if(this.actionMode == "VIEW") {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this.getPlatformsTotalRatio();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.this_template_total_cost && changes.this_template_total_cost.currentValue) {
      this.build_ratio_calculation();
    }
  }
  private appendcontrols() {  
    this.platformformarray.controls = [];
    if(this.platforms_data) {
      var platofrm   = [];
      let records = this.platforms_data;
      Object.keys(records).forEach(function(key) {
        platofrm.push(records[key]);
      });
      if(platofrm.length > 0) {
        platofrm.forEach((v, k) => {
          this.platformformarray.push(this.platform_records(v));
          this.worklevels.forEach((m, n) => {
            this.addplatformwlevel(k);
          });
        });
      }
    }    
  }
  public platform_title(controls, field) {
    return controls.get(field).value + ' - ' + controls.get('platform_code_system').value;
  }
  public items_platform() {
		return this.form.get("platformarray") as FormArray;
  }
  get platformvalidate() {
    if(this.form && this.form.get("platformarray")) {
      return this.form.get("platformarray") as FormArray;
    }
	}
  public platform_records(value): FormGroup {
		return this.fb.group({
      platform_group1_code_system               : new FormControl(value.platform_group1_code_system),
      platform_group_type_code_system           : new FormControl(value.platform_group_type_code_system),
      platform_group2_code_system               : new FormControl(value.platform_group2_code_system),
      platform_code_system                      : new FormControl(value?.platform_code_system, [Validators.required]),
      platform_title                            : new FormControl(value?.platform_title),
      platform_code_manual                      : new FormControl(value?.platform_code_manual),
      platform_title_manual                     : new FormControl(value?.platform_title_manual),
      platform_code_manual_2                    : new FormControl(value?.platform_code_manual_2),
      platform_title_manual_2                   : new FormControl(value?.platform_title_manual_2),
      platform_item_specifications              : new FormControl(value?.platform_item_specifications),
      platform_item_specification_attachment    : new FormControl(value?.platform_item_specification_attachment),
      platform_item_specification_attachment_s  : new FormControl(value.platform_item_specification_attachment_s || 0),
      platform_code_systems                     : new FormControl({value : (value.platform_code_systems) ? value.platform_code_systems.split(',') : null,  disabled : (this.actionMode == "VIEW") ? true : false}),
      template_platform_building_codes          : new FormControl({value : (value.template_platform_building_codes) ? value.template_platform_building_codes.split(',') : null,  disabled : (this.actionMode == "VIEW") ? true : false}),     
      work_levels_multiple                      : this.fb.array([]),     
      consultant_required                       : new FormControl({value : (value.consultant_required) ? value.consultant_required : null,  disabled : (this.actionMode == "VIEW") ? true : false}),
      receipient_positions                      : new FormControl({value : (value.receipient_positions) ? value.receipient_positions : null,  disabled : (this.actionMode == "VIEW") ? true : false}),
      authorized_positions                      : new FormControl({value : (value.authorized_positions) ? value.authorized_positions : null,  disabled : (this.actionMode == "VIEW") ? true : false}),
      consultant_recomendations                 : new FormControl({value : (value.consultant_recomendations) ? value.consultant_recomendations : null,  disabled : (this.actionMode == "VIEW") ? true : false}),   
      // For Tender Drawing Type
      shop_drawing_linking_codes                : new FormControl(value?.shop_drawing_linking_codes),
      asbuild_drawing_linking_codes             : new FormControl(value?.asbuild_drawing_linking_codes),
      tender_drawing_attachments                : new FormControl(value?.tender_drawing_attachments),
      // For Bill Quantity Type OR Work request inception settings WRI Type
      platform_date_start                       : new FormControl({value : value?.platform_date_start, disabled : (this.actionMode == "VIEW") ? true : false}, [Validators.required]),
      platform_date_end                         : new FormControl({value : value?.platform_date_end, disabled : (this.actionMode == "VIEW") ? true : false}, [Validators.required]),
      projects_platform_weights_unit_code       : new FormControl({value : value?.projects_platform_weights_unit_code, disabled : (this.actionMode == "VIEW") ? true : false}, [Validators.required]),
      unit_quantity                             : new FormControl({value : value?.unit_quantity, disabled : true}, [Validators.required]),
      unit_price                                : new FormControl({value : value?.unit_price, disabled : true}, [Validators.required]),
      total_price                               : new FormControl(value?.total_price, [Validators.required]),
      unit_ratio                                : new FormControl(value?.unit_ratio),      
      total_ratio                               : new FormControl(value?.total_ratio),
      overflow_ratio_quantity                   : new FormControl(''),
      ncr_si_issues                             : new FormControl({value : (value.ncr_si_issues) ? value.ncr_si_issues : null,  disabled : (this.actionMode == "VIEW") ? true : false}),   
    });
  }
  public platformwlevels(empIndex:number) : FormArray {
    return this.items_platform().at(empIndex).get("work_levels_multiple") as FormArray
  }
  public newwlevel(value?): FormGroup {
    return this.fb.group({level: value?.level});
  }
  public addplatformwlevel(empIndex:number) {
    this.platformwlevels(empIndex).push(this.newwlevel());
  }
  public openModalP(template: TemplateRef<any>) {
    this.modalRefP = this.modalService.show(template, environment.modelconfig);
  }
  public delete_platform_record(platformindex) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.platformformarray.removeAt(platformindex);
        this.build_ratio_calculation();
      }
    });
  } 
  public get_extra_platforms(template) {
    this.extraplatformslist = [];
    let platformarray = [];
    this.platformvalidate.value.forEach((v, k) => {
      platformarray.push(v.platform_code_system);
    });
    let param = new FormData();
    param.append('platform_group1_code_system',       this.step2form.value['group1Data'][this.group1Index].platform_group1_code_system);
    param.append('platform_group_type_code_system',   this.step2form.value['group1Data'][this.group1Index]['typesData'][this.typeIndex].platform_group_type_code_system);
    param.append('platform_group2_code_system',       this.step2form.value['group1Data'][this.group1Index]['typesData'][this.typeIndex]['group2Data'][this.group2Index].platform_group2_code_system);
    param.append('platform_code_system_not_in',       platformarray.toString());
    param.append('projects_services_ids',             this.supervision_data.projects_services_id);
    this.spinner.show();
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lpplatform').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.extraplatformslist = res.records;
        this.openModalP(template);
      } else {
        this.ds.dialogf('', res.error);
      }
    }, error => {
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      this.spinner.hide();
    });
  }  
  public add_extra_platforms_items() {
    this.spinner.show();
    if(this.extraplatformsdata && this.extraplatformsdata.length > 0) {
      this.extraplatformsdata.forEach((v, k) => {
        this.platformformarray.push(this.platform_records({
          platform_group1_code_system           : v.platform_group1_code_system,
          platform_group_type_code_system       : v.platform_group_type_code_system,
          platform_group2_code_system           : v.platform_group2_code_system,
          platform_code_system                  : v.value,
          platform_code_manual                  : v.platform_code_manual,
          platform_title                          : v.label
        }));
        this.worklevels.forEach((m, n) => {
          let l = (this.platformvalidate && this.platformvalidate.length > 0) ? this.platformvalidate.length - 1 : k;
          this.addplatformwlevel(l);
        });
      });
    }
    this.extraplatformslist = [];
    this.extraplatformsdata = null;
    setTimeout(() => {
      this.spinner.hide();
      this.modalRefP.hide();
    }, 200);
  }
  public search_access_codes(key, controls, platformindex) {
    this.search_list_access_codes = [];
    if(key && key.term) {
      let param = new FormData();
      param.append('lang_key', this.lang_key);
      param.append('platform_code_systems', key.term);
      param.append('request', 'search');
      if(this.tree_data && this.tree_data.length > 0) {
        param.append('platform_group1_code_system',     controls.get('platform_group1_code_system').value);
        param.append('platform_group_type_code_system', controls.get('platform_group_type_code_system').value);
        param.append('platform_group2_code_system',     controls.get('platform_group2_code_system').value);
      } else {
        param.append('platform_group1_code_system',     this.step2formvalue.group1Data[this.group1Index].platform_group1_code_system || '0');
        param.append('platform_group_type_code_system', this.step2formvalue['group1Data'][this.group1Index]['typesData'][this.typeIndex].platform_group_type_code_system || '0');
        param.append('platform_group2_code_system',     this.step2formvalue['group1Data'][this.group1Index]['typesData'][this.typeIndex]['group2Data'][this.group2Index].platform_group2_code_system || '0');
      }
      this.ds.getActionByUrl(this.ds.formData2string(param), 'lpplatform').subscribe(res=> {
        if(res.status && res.records.length == 1) {
          this.search_list_access_codes = res.records;
        }
      });
    }
  }
  public field_abstract_control(field, index, edit?) {
    if(edit != 'edit') {
      field.get('unit_quantity').setValue('');
      field.get('unit_price').setValue('');
      field.get('total_price').setValue('');
      field.get('unit_ratio').setValue('');
      field.get('total_ratio').setValue('');
    }
    if(this.platformwlevels(index) && this.platformwlevels(index).controls && this.platformwlevels(index).controls.length > 0) {
      this.platformwlevels(index).controls.forEach((v, k) => {
        if(edit != 'edit') {
          this.platformwlevels(index).controls[k].get('level').setValue('');
        }
      });
    }
    field.get('unit_quantity').disable();
    field.get('unit_price').disable();
    if(field.get('projects_platform_weights_unit_code').value == null) {
      field.get('unit_quantity').disable();
      field.get('unit_price').disable();
      field.get('total_price').disable();
    } else if(field.get('projects_platform_weights_unit_code').value == "ratio") {
      field.get('unit_quantity').disable();
      field.get('unit_price').disable();
      field.get('total_price').enable();
    } else if(field.get('projects_platform_weights_unit_code').value != "ratio") {
      field.get('unit_quantity').enable();
      field.get('unit_price').enable();
      field.get('total_price').disable();
    }
    this.build_ratio_calculation();
    this.check_work_level_values_of_quantity(field, index);
  }
  //Calculations Starts
  public calculate_total_price(field) {
      let quantity:any = parseFloat(field.get('unit_quantity').value);
      let price:any = parseFloat(field.get('unit_price').value);
      if(quantity && price) {
        let total_price:any = (parseFloat(quantity) * parseFloat(price));
        field.get('total_price').setValue(parseFloat(total_price).toFixed(2));
      }
  }
  //Ratio
  public build_ratio_calculation() {
    if(this.form && this.form.get("platformarray")) {
      this.platformvalidate.value.forEach((v, k) => {
        let quantity:any = parseFloat(v.unit_quantity);
        let price:any = parseFloat(v.unit_price);        
        if(this.platformvalidate.controls[k].get('projects_platform_weights_unit_code').value == 'ratio') {
          let worklevelvalue:any = 0.00;
          v.work_levels_multiple.forEach((j, i) => {
            worklevelvalue += (j.level && !isNaN(j.level) && j.level !== undefined ) ? parseFloat(j.level) : 0;
          });
          this.platformvalidate.controls[k].get('unit_ratio').setValue(parseFloat(worklevelvalue).toFixed(2));
          this.platformvalidate.controls[k].get('total_ratio').setValue(parseFloat(worklevelvalue).toFixed(2));
        } else if(this.platformvalidate.controls[k].get('projects_platform_weights_unit_code').value != 'ratio' && quantity && price) {
          let total_price:any = (parseFloat(quantity) * parseFloat(price));
          let finalratio:any = (parseFloat(total_price) / parseFloat(this.maintotalamount) ) * 100;
          this.platformvalidate.controls[k].get('unit_ratio').setValue('');
          this.platformvalidate.controls[k].get('total_ratio').setValue(parseFloat(finalratio).toFixed(2));
        } else {
          this.platformvalidate.controls[k].get('unit_ratio').setValue('');
          this.platformvalidate.controls[k].get('total_ratio').setValue('');
        }
      });
      this.make_total_calculations();
    }
  }
  //emit the values to parent Controlvalueaccessor
  public make_total_calculations() {
    let system_total_amount:any = 0.00;
    let manual_total_amount:any = 0.00;
    let system_total_ratio:any  = 0.00;
    let manual_total_ratio:any  = 0.00;
    let total:any               = 0.00;
    let final_total_ratio:any   = 0.00;
    this.platformvalidate.value.forEach((v, k) => {
      let unit_quantity = this.platformvalidate.controls[k].get('unit_quantity').value;
      let unit_price = this.platformvalidate.controls[k].get('unit_price').value;
      let total_price = this.platformvalidate.controls[k].get('total_price').value;
      if( this.platformvalidate.controls[k].get('projects_platform_weights_unit_code').value != null && this.platformvalidate.controls[k].get('projects_platform_weights_unit_code').value != "ratio" && parseInt(unit_quantity) >= 0 && parseInt(unit_price) >= 0) {
        total                     += (parseFloat(unit_quantity) * parseFloat(unit_price));
        system_total_amount       += (parseFloat(unit_quantity) * parseFloat(unit_price));
        system_total_ratio        += ((parseFloat(unit_quantity) * parseFloat(unit_price)) / parseFloat(this.maintotalamount) ) * 100;       
      } else if(this.platformvalidate.controls[k].get('projects_platform_weights_unit_code').value && this.platformvalidate.controls[k].get('projects_platform_weights_unit_code').value == "ratio" && parseInt(total_price) >= 0 ) {
        this.platformvalidate.controls[k].get('unit_price').setValue('');
        total                     += parseFloat(total_price);
        manual_total_amount       += parseFloat(total_price);
      }      
      if(this.platformvalidate.controls[k].get('total_ratio').value && !isNaN(this.platformvalidate.controls[k].get('total_ratio').value)) {
        final_total_ratio         += parseFloat(this.platformvalidate.controls[k].get('total_ratio').value);
      }
      if(this.platformvalidate.controls[k].get('unit_ratio').value && !isNaN(this.platformvalidate.controls[k].get('unit_ratio').value)) {
        manual_total_ratio        += parseFloat(this.platformvalidate.controls[k].get('unit_ratio').value);
      }
    });    
    this.totalEvent.emit({
      group2Index               : this.group2Index,
      total                     : (parseInt(total) >= 0) ? parseFloat(total).toFixed(2) : 0.00,
      total_ratio               : (parseInt(final_total_ratio) >= 0) ? parseFloat(final_total_ratio).toFixed(2) : 0.00,
      system_total_amount       : (parseInt(system_total_amount) >= 0) ? parseFloat(system_total_amount).toFixed(2) : 0.00,
      manual_total_amount       : (parseInt(manual_total_amount) >= 0) ? parseFloat(manual_total_amount).toFixed(2) : 0.00,
      system_total_ratio        : (parseInt(system_total_ratio) >= 0) ? parseFloat(system_total_ratio).toFixed(2) : 0.00,
      manual_total_ratio        : (parseInt(manual_total_ratio) >= 0) ? parseFloat(manual_total_ratio).toFixed(2) : 0.00,
    });
  }
  public check_work_level_values_of_quantity(field, index) {
    let unit_code       = field.get('projects_platform_weights_unit_code').value;
    let unit_quantity   = field.get('unit_quantity').value;
    let errorelement    = "."+this.group1Index+'-'+this.typeIndex+'-'+this.group2Index+'-'+index+"Error";
    field.get('overflow_ratio_quantity').setValue('');
    if(unit_code != "ratio" && unit_quantity && this.platformwlevels(index) && this.platformwlevels(index).controls && this.platformwlevels(index).controls.length > 0) {
      let totalwlevelvalues:any = 0.00;
      this.platformwlevels(index).controls.forEach((v, k) => {
        totalwlevelvalues += (this.platformwlevels(index).controls[k].get('level').value && !isNaN(this.platformwlevels(index).controls[k].get('level').value) && this.platformwlevels(index).controls[k].get('level').value != undefined) ? parseFloat(this.platformwlevels(index).controls[k].get('level').value) : 0.00;
      });
      if(unit_quantity && totalwlevelvalues && parseFloat(unit_quantity) !== parseFloat(totalwlevelvalues)) {
        field.get('overflow_ratio_quantity').setValue('error');
      }
    }
  }
  public UploadFile(event, index) {
    if (event.target.files[0] && event.target.files[0] !== undefined && event.target.files[0].name) {
      const fileid = event.target.files[0];
      $("#uploadText" + index).text(fileid.name);
      this.platformvalidate.controls[index].get('platform_item_specification_attachment').setValue(fileid, { emitEvent: true });
      this.platformvalidate.controls[index].get('platform_item_specification_attachment_s').setValue("1");
    } else {
      this.platformvalidate.controls[index].get('platform_item_specification_attachment_s').setValue("0");
      this.platformvalidate.controls[index].get('platform_item_specification_attachment').setValue(null);
      $("#uploadText" + index).text(this.lang.transform('lang_select_files'));
    }
  }
  public removeFile(index) {
    this.isFile = false;
    this.platformvalidate.controls[index].get('platform_item_specification_attachment').setValue(null);
    $("#uploadText" + index).text(this.lang.transform('lang_select_files'));
  }


  consaltantIDS = []
  consaltantInputValue = ''
  toggleVlaue(event,cons,arrIndex){
    this.ConsultantData[arrIndex].checked = !this.ConsultantData[arrIndex].checked
    if(event){
      this.consaltantIDS.push(cons.value)
    }else{
      let crrentIndex = this.consaltantIDS.findIndex(i=>i == cons.value)
      this.consaltantIDS.splice(crrentIndex,1)
    }
    let element = ""
    for (let index = 0; index < this.consaltantIDS.length; index++) {
      if (element != '') {
        element += ',';
      }
      element += `${this.consaltantIDS[index]}`;
    }
    this.consaltantInputValue = element
    this.platformformarray['controls'][this.formIndex].patchValue({
      ...this.platformformarray['controls'][this.formIndex].value,
      consultant_required:this.consaltantInputValue
    })

  }

  formIndex = ''
  public openFConsultantDialog(template: TemplateRef<any>,formIndex) {
    let param = new FormData();
    this.spinner.show();
		param.append('lang_key', this.lang_key);
		param.append('search_key', '');
		this.ds.getActionByUrl(this.ds.formData2string(param), 'fconsultant').subscribe(res => {
			this.spinner.hide();
			if (res.status) {
				this.ConsultantData = res.records;
        this.formIndex = formIndex
        let consultant_required = this.platformformarray['value'][this.formIndex].consultant_required || ''
        let consultant_required_arr = []
        if(consultant_required){
          if(typeof(consultant_required) == 'object'){
            consultant_required_arr = consultant_required
          }else{
            consultant_required_arr = consultant_required.split(',')
          }
        }
        for (let index = 0; index < this.ConsultantData.length; index++) {
          const element = this.ConsultantData[index];
          for (let i = 0; i < consultant_required_arr.length; i++) {
            const e = consultant_required_arr[i];
            if(e == element.value){
              this.ConsultantData[index] = {...this.ConsultantData[index],checked:true}
            }else{
              this.ConsultantData[index] = {...this.ConsultantData[index],checked:false}
            }
          }
        }
        this.consaltantIDS = consultant_required_arr;
        this.consaltantInputValue = consultant_required;
       /*  this.modalService.show(template, {...environment.modelconfig, width:'400px',}); */
        this.dialog.open(template,{
          width:'90%',
        });
			}
		})

  
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  openPlatformModal(index:any) {
    let platform = Object.values(this.platforms_data)[index];
    const modal = this.dialog.open(PlatformDetailsComponent, {
      width:'800px',
      autoFocus: false,
      data: {platform: platform, buildingCodes: this.buildingCodes, positions: this.positions, consultantr: this.consultantr, fconsultants: this.fconsultants}
    });
  }

  getPlatformByIndex(index:any) {
    return Object.values(this.platforms_data)[index];
  }

  getPlatformsTotalRatio() {
    let platforms = Object.values(this.platforms_data);
    let ratioArr = platforms.map(el => el['final_ratio']);
    let priceArr = platforms.map(el => el['total_price']);
    let platformsTotalRatio = parseFloat('0.00');
    let platformsTotalPrice = parseFloat('0.00');
    for(let ratio of ratioArr) {
      if(!isNaN(parseFloat(ratio))) {
        platformsTotalRatio += parseFloat(ratio);
      }
    }
    for(let price of priceArr) {
      if(!isNaN(parseFloat(price))) {
        platformsTotalPrice += parseFloat(price);
      }
    }
    this.platforms_total_ratio.emit({total_ratio:platformsTotalRatio.toFixed(2), total_price:platformsTotalPrice.toFixed(2)});
  }
}