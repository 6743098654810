<mat-card class="card">
	<mat-card-title class="card-title">
		<h4>{{ "lang_advanced_filter" | language }}</h4>
	</mat-card-title>
	<mat-card-content>
		<form [formGroup]="filterForm">
			<div class="row">
				<div class="col-12 mt-1">
					<label for="lang_nationality_type">{{ "lang_nationality_type" | language }}</label>
					<ng-select
						[items]="nationality_type"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						appendTo="body"
						clearAllText="Clear"
						formControlName="nationality_type"
						placeholder="{{ 'lang_nationality_type' | language }}"
					>
					</ng-select>
				</div>
 
				<div class="col-12 mt-1" *ngIf="filterForm.value.nationality_type != 'saudi'">
					<label for="lang_nationality">{{ "lang_projects_nationality" | language }}</label>
					<ng-select
						[items]="countries"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						appendTo="body"
						clearAllText="Clear"
						formControlName="nationality"
						placeholder="{{ 'lang_projects_nationality' | language }}"
					>
					</ng-select>
				</div>
				<div class="col-12 mt-1" *ngIf="projects.length>0">
					<label for="lang_project">{{ "lang_project_name_en" | language }}</label>
					<ng-select
						[items]="projects"
						[multiple]="false"
						bindLabel="quotation_subject"
						bindValue="id"
						appendTo="body"
						clearAllText="Clear"
						formControlName="project_id"
						placeholder="{{ 'lang_project_name_en' | language }}"
					>
					</ng-select>
				</div>
				<div class="col-12 mt-1" *ngIf="branches.length>0">
					<label for="branch_id">{{ "lang_branch" | language }}</label>
					<ng-select
						[items]="branches"
						[multiple]="false"
						bindLabel="label"
						bindValue="value"
						
						(change)="changeVal($event)"
						clearAllText="Clear"
						formControlName="branch_id"
						placeholder="{{ 'lang_branch' | language }}"
					>
					</ng-select>
				</div>
				<div class="col-12 mt-1">
					<label for="employee_name">{{
						"lang_human_resources_employee_name" | language
					}}</label>
					<input
						type="text"
						class="form-control"
						id="employee_name"
						name="employee_name"
			 
						formControlName="employee_name"
					/>
				</div>

				<div class="col-12 mt-1">
					<label for="employee_id">{{
						"lang_human_resources_passport_number" | language
					}}</label>
					<input
						type="text"
						class="form-control"
						id="national_id"
						name="national_id"
			 
						formControlName="national_id"
					/>
				</div>
				<div class="col-12 mt-1">
					<label for="lang_employee_id">{{
						"lang_human_resources_id_number" | language
					}}</label>
					<input
						type="text"
						class="form-control"
						id="lang_employee_id"
						name="lang_employee_id"
			 
						formControlName="employee_id"
					/>
				</div>

				<div class="col-12 mt-1">
					<label for="lang_position">{{
						"lang_position" | language
					}}</label>
					<input
						type="text"
						class="form-control"
						id="form_request_id"
						name="form_request_id"
			 
						formControlName="position"
					/>
				</div>
			 


				<div class="col-12 mt-2 d-flex justify-content-between">
					<button
						type="button"
						class="albutton"
						style="width: 40% !important"
						(click)="resetFilterForm()"
					>
						{{ "lang_reset" | language }}
					</button>
					<button
						type="submit"
						class="albutton"
						style="width: 40% !important"
						(click)="sendFilterForm()"
					>
						{{ "lang_save" | language }}
					</button>
				</div>
			</div>
		</form>
	</mat-card-content>
</mat-card>
