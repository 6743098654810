<div class="card mobilization_in_contracts">
    <div class="card-header">{{'lang_mobilization_general_form_settings' | language }} </div>
    <div class="card-body pad0">
        <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
            <mat-tab label="{{'lang_site_handle' | language}}">
                <div class="custom-panel-content">
                    <app-site-handle [filelevels]="filelevels" [supervision_data]="supervision_data" [able2add]="able2add" [able2edit]="able2edit" [project_data]="project_data"></app-site-handle>
                </div>
            </mat-tab>
            <mat-tab label="{{'lang_mobilization_in_contract' | language}}">
                <div class="custom-panel-content">
                    <app-mobilization-contract [filelevels]="filelevels" [supervision_data]="supervision_data" [able2add]="able2add" [able2edit]="able2edit" [project_data]="project_data"></app-mobilization-contract>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>