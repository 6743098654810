<div class="card">
    <div class="width100">
        <div class="card-header">{{'lang_human_resources_id_details' | language }}</div>
        <div class="card-body pad0" [formGroup]="regForm">
            <div class="mbrl15" formGroupName="idDetails">
                <div class="row">
                    <div class="col-md-9 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <div *ngIf="action === 'EDIT'">
                                        <input type="hidden" formControlName="employee_id_number_old" [readonly]="true">
                                    </div>
                                    <label for="employee_id_number">{{'lang_human_resources_id_number' | language }} : <span class="red">*</span></label>
                                    <input formControlName="employee_id_number" minlength="10" maxlength="10" id="employee_id_number" type="text" placeholder="{{'lang_human_resources_id_number' | language }}" class="form-control almnabrformcontrol employee_id_number" (keypress)="ds.numberOnly($event)" (change)="employeeExists($event.target.value)"/>
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['employee_id_number'].touched && regForm.get('idDetails')['controls']['employee_id_number'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['employee_id_number'].errors?.minlength">{{'lang_minimum' | language }} 10</mat-error>
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['employee_id_number'].errors?.maxlength">{{'lang_maximum' | language }} 10</mat-error>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="branch_id">{{'lang_branch' | language }} : <span class="red">*</span></label>
                                    <select formControlName="branch_id" id="branch_id" type="text" class="form-control almnabrformcontrol branch_id">
                                        <option value="">{{'lang_choose_options' | language }}</option>
                                        <option *ngFor="let branch of branches" value="{{branch.value}}">{{branch.label}}</option>
                                    </select>
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['branch_id'].touched && regForm.get('idDetails')['controls']['branch_id'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="firstname_english">{{'lang_human_resources_fname_english' | language }} : <span class="red">*</span></label>
                                    <input formControlName="firstname_english" id="firstname_english" type="text" class="form-control almnabrformcontrol firstname_english" placeholder="{{'lang_human_resources_fname_english' | language }}" />
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['firstname_english'].touched && regForm.get('idDetails')['controls']['firstname_english'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="secondname_english">{{'lang_human_resources_sname_english' | language }} : <span class="red">*</span></label>
                                    <input formControlName="secondname_english" required id="secondname_english" type="text" class="form-control almnabrformcontrol secondname_english" placeholder="{{'lang_human_resources_sname_english' | language }}" />
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['secondname_english'].touched && regForm.get('idDetails')['controls']['secondname_english'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="thirdname_english">{{'lang_human_resources_tname_english' | language }} : </label>
                                    <input formControlName="thirdname_english" id="thirdname_english" type="text" class="form-control almnabrformcontrol thirdname_english" placeholder="{{'lang_human_resources_tname_english' | language }}" />
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="lastname_english">{{'lang_human_resources_lname_english' | language }} : <span class="red">*</span></label>
                                    <input formControlName="lastname_english" required id="lastname_english" type="text" class="form-control almnabrformcontrol lastname_english" placeholder="{{'lang_human_resources_lname_english' | language }}" />
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['lastname_english'].touched && regForm.get('idDetails')['controls']['lastname_english'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="firstname_arabic">{{'lang_human_resources_fname_arabic' | language }} : <span class="red">*</span></label>
                                    <input formControlName="firstname_arabic" required id="firstname_arabic" type="text" class="form-control almnabrformcontrol firstname_arabic" placeholder="{{'lang_human_resources_fname_arabic' | language }}" />
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['firstname_arabic'].touched && regForm.get('idDetails')['controls']['firstname_arabic'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="secondname_arabic">{{'lang_human_resources_sname_arabic' | language }} : <span class="red">*</span></label>
                                    <input formControlName="secondname_arabic" required id="secondname_arabic" type="text" class="form-control almnabrformcontrol secondname_arabic" placeholder="{{'lang_human_resources_sname_arabic' | language }}" />
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['secondname_arabic'].touched && regForm.get('idDetails')['controls']['secondname_arabic'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="thirdname_arabic">{{'lang_human_resources_tname_arabic' | language }} : </label>
                                    <input formControlName="thirdname_arabic" id="thirdname_arabic" type="text" class="form-control almnabrformcontrol thirdname_arabic" placeholder="{{'lang_human_resources_tname_arabic' | language }}" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="lastname_arabic">{{'lang_human_resources_lname_arabic' | language }} : <span class="red">*</span></label>
                                    <input formControlName="lastname_arabic" required id="lastname_arabic" type="text" class="form-control almnabrformcontrol lastname_arabic" placeholder="{{'lang_human_resources_lname_arabic' | language }}" />
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['lastname_arabic'].touched && regForm.get('idDetails')['controls']['lastname_arabic'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="iqama_expiry_date_english">{{'lang_human_resources_id_expiry_data_english' | language }} : <span class="red">*</span></label>
                                    <div class="positionrelatiove">
                                        <input formControlName="iqama_expiry_date_english" class="form-control almnabrformcontrol iqama_expiry_date_english" (dateChange)="ds.setENTOARDate($event, regForm.get('idDetails')['controls']['iqama_expiry_date_arabic'])" [readonly]="true" [matDatepicker]="IDeng" placeholder="YYYY/MM/DD" />
                                        <mat-datepicker-toggle matSuffix [for]="IDeng" class="posiab"></mat-datepicker-toggle>
                                        <mat-datepicker #IDeng disabled="false"></mat-datepicker>
                                        <mat-error *ngIf="regForm.get('idDetails')['controls']['iqama_expiry_date_english'].touched && regForm.get('idDetails')['controls']['iqama_expiry_date_english'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="iqama_expiry_date_arabic">{{'lang_human_resources_id_expiry_data_arabic' | language }} : <span class="red">*</span></label>
                                    <input formControlName="iqama_expiry_date_arabic" ngbDatepicker [readonly]="true" (click)="idarab.toggle()" #idarab="ngbDatepicker" type="text" class="form-control almnabrformcontrol" placeholder="YYYY/MM/DD" (ngModelChange)="ds.setARTOENDate($event, regForm.get('idDetails')['controls']['iqama_expiry_date_english'])"/>
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['iqama_expiry_date_arabic'].touched && regForm.get('idDetails')['controls']['iqama_expiry_date_arabic'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="copy_number">{{'lang_human_resources_copy_number' | language }} : <span class="red">*</span></label>
                                    <input formControlName="copy_number" required id="copy_number" type="text" class="form-control almnabrformcontrol copy_number" placeholder="{{'lang_human_resources_copy_number' | language }}" />
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['copy_number'].touched && regForm.get('idDetails')['controls']['copy_number'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="birth_date_english">{{'lang_human_resources_birth_date_english' | language }} : <span class="red">*</span></label>
                                    <div class="positionrelatiove">
                                        <input formControlName="birth_date_english" required id="birth_date_english" type="text" class="form-control almnabrformcontrol birth_date_english" (dateChange)="ds.setENTOARDate($event, regForm.get('idDetails')['controls']['birth_date_arabic'])" [readonly]="true" placeholder="YYYY/MM/DD" [matDatepicker]="BDeng" />
                                        <mat-datepicker-toggle matSuffix [for]="BDeng" class="posiab"></mat-datepicker-toggle>
                                        <mat-datepicker #BDeng disabled="false"></mat-datepicker>
                                        <mat-error *ngIf="regForm.get('idDetails')['controls']['birth_date_english'].touched && regForm.get('idDetails')['controls']['birth_date_english'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="birth_date_arabic">{{'lang_human_resources_birth_date_arabic' | language }} : <span class="red">*</span></label>
                                    <input formControlName="birth_date_arabic" (ngModelChange)="ds.setARTOENDate($event, regForm.get('idDetails')['controls']['birth_date_english'])" ngbDatepicker [readonly]="true" (click)="bdarab.toggle()" #bdarab="ngbDatepicker" required id="birth_date_arabic" type="text" class="form-control almnabrformcontrol birth_date_arabic" placeholder="YYYY/MM/DD" />
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['birth_date_arabic'].touched && regForm.get('idDetails')['controls']['birth_date_arabic'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="gender">{{'lang_human_resources_gender' | language }} : <span class="red">*</span></label>
                                    <select formControlName="gender" required id="gender" type="text" class="form-control almnabrformcontrol gender" matTooltip="{{'lang_human_resources_gender' | language }}">
                                        <option value="">{{'lang_choose_options' | language }}</option>
                                        <option value="M">{{'lang_human_resources_male' | language }}</option>
                                        <option value="F">{{'lang_human_resources_female' | language }}</option>
                                    </select>
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['gender'].touched && regForm.get('idDetails')['controls']['gender'].errors?.required">{{'lang_field_required' | language }} </mat-error>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="nationality">{{'lang_human_resources_nationality' | language }} : <span class="red">*</span></label>
                                    <ng-select 
                                        [items]="countries" 
                                        [multiple]="false" 
                                        bindLabel="label" 
                                        bindValue="value" 
                                        appendTo="body" 
                                        clearAllText="Clear"
                                        placeholder="{{'lang_human_resources_nationality' | language}}"
                                        formControlName="nationality">
                                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                            <span class="widthunset" style="color: #333;min-width: 150px;">{{ item.label}}</span>
                                            <img *ngIf="item.flag" src="{{apiurl}}{{item.flag}}" class="searchingImage" />
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="job_title_iqama">{{'lang_human_resources_job_title_iqama' | language }} : <span class="red">*</span></label>
                                    <input formControlName="job_title_iqama" required id="job_title_iqama" type="text" class="form-control almnabrformcontrol job_title_iqama" placeholder="{{'lang_human_resources_job_title_iqama' | language }}" />
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['job_title_iqama'].touched && regForm.get('idDetails')['controls']['job_title_iqama'].errors?.required">{{'lang_field_required' | language }} </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_work_domain' | language }} : <span class="red">*</span></label>
                                    <input formControlName="work_domain" type="text" placeholder="{{'lang_work_domain' | language }}" class="form-control almnabrformcontrol"/>
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['work_domain'].touched && regForm.get('idDetails')['controls']['work_domain'].errors?.required">{{'lang_field_required' | language }} </mat-error>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_work_location' | language }} : <span class="red">*</span></label>
                                    <input formControlName="work_location" type="text" placeholder="{{'lang_work_location' | language }}" class="form-control almnabrformcontrol"/>
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['work_location'].touched && regForm.get('idDetails')['controls']['work_location'].errors?.required">{{'lang_field_required' | language }} </mat-error>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_work_type' | language }} : <span class="red">*</span></label>
                                    <select formControlName="work_type" type="text" matTooltip="{{'lang_work_type' | language }}" class="form-control almnabrformcontrol">
                                        <option value="">{{'lang_choose_options' | language }}</option>
                                        <option value="1">{{'lang_fulltime' | language}}</option>
                                        <option value="2">{{'lang_parttime' | language}}</option>
                                        <option value="3">{{'lang_contract' | language}}</option>
                                        <option value="4">{{'lang_others' | language}}</option>
                                    </select>
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['work_type'].touched && regForm.get('idDetails')['controls']['work_type'].errors?.required">{{'lang_field_required' | language }} </mat-error>
                                </div>
                            </div>
                        </div>
















                    </div>
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_marital_status' | language }} : <span class="red">*</span></label>
                                    <select formControlName="marital_status" type="text" matTooltip="{{'lang_marital_status' | language }}" class="form-control almnabrformcontrol">
                                        <option value="">{{'lang_choose_options' | language }}</option>
                                        <option value="S">{{'lang_single' | language}}</option>
                                        <option value="M">{{'lang_married' | language}}</option>
                                        <option value="D">{{'lang_diversed' | language}}</option>
                                        <option value="O">{{'lang_others' | language}}</option>
                                    </select>
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['marital_status'].touched && regForm.get('idDetails')['controls']['marital_status'].errors?.required">{{'lang_field_required' | language }} </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label>{{'lang_religion' | language }} : <span class="red">*</span></label>
                                    <input formControlName="religion" type="text" placeholder="{{'lang_religion' | language }}" class="form-control almnabrformcontrol"/>
                                    <mat-error *ngIf="regForm.get('idDetails')['controls']['religion'].touched && regForm.get('idDetails')['controls']['religion'].errors?.required">{{'lang_field_required' | language }} </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12 pad0">
                                <div class="form-group text-center">
                                    <label>{{'lang_human_resources_profile_image' | language }} : </label>
                                        <div class="avatar-upload">
                                            <div class="avatar-edit" matTooltip="{{'lang_select_files' | language }}" placement="top">
                                                <input name="profile_image" onclick="this.value = null" accept=".png, .jpg, .jpeg" id="profile_image" type="file" class="form-control almnabrformcontrol profile_image" (change)="onFileChange($event)" />
                                                <label for="imageUpload"></label>
                                            </div>
                                            <label *ngIf="fileurl" (click)="removeProfile()" matTooltip="{{'lang_delete' | language }}" placement="top" class="removeImage"></label>
                                            <img *ngIf="fileurl" src="{{fileurl}}" class="avatar-preview"/>
                                            <img *ngIf="!fileurl" src="{{view_profile_image}}" class="avatar-preview" id="imagePreview" />
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                                <div class="form-group">
                                    <label for="employee_title">{{'lang_human_resources_emptitle' | language }} : </label>
                                    <select formControlName="employee_title" id="employee_title" type="text" matTooltip="{{'lang_human_resources_emptitle' | language }}" class="form-control almnabrformcontrol employee_title">
                                        <option value="">{{'lang_choose_options' | language }}</option>
                                        <option *ngFor="let title of etitles" value="{{title.value}}">{{title.label}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="action !== 'EDIT'">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <label for="id_filename">{{'lang_human_resources_id_filename' | language }} : <span class="red">*</span></label>
                                        <input formControlName="id_filename" required id="id_filename" type="text" placeholder="{{'lang_human_resources_id_filename' | language }}" class="form-control almnabrformcontrol id_filename"/>
                                        <mat-error *ngIf="regForm.get('idDetails')['controls']['id_filename'].touched && regForm.get('idDetails')['controls']['id_filename'].errors?.required">{{'lang_field_required' | language }} </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                                    <div class="form-group">
                                        <div class="IDDIV">
                                            <input name="idFileUpload" type="file" required onclick="this.value = null" (change)="uploadIDFile($event)" class="nghide albutton width100" accept=".pdf">
                                            <label id="uploadIDButton" class="albutton">{{idfilename}} </label>
                                            <mat-error *ngIf="regForm.get('idDetails')['controls']['idFileUpload'].touched && regForm.get('idDetails')['controls']['idFileUpload'].errors?.required">{{'lang_field_required' | language }}</mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer padb0">
                <div class="row">
                    <div class="col-md-10 col-sm-8 col-xs-12 mb-10"></div>
                    <div class="col-md-2 col-sm-4 col-xs-12 mb-10">
                        <button type="button" class="albutton" matStepperNext (click)="step1Submitted()">{{'lang_next' | language }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>