import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
interface list {
  id?: number;
  file_users_group_id: string,
  group_key: string,
  user_id: string,
  groupname: string,
  name: string,
}
@Component({
  selector: 'app-file-users-groups',
  templateUrl: './file-users-groups.component.html',
  styleUrls: ['./file-users-groups.component.scss']
})
export class FileUsersGroupsComponent implements OnInit {

  modalRefA: BsModalRef;
  tabledatas: list[];
  form:FormGroup;
  @Output() actionEvent             = new EventEmitter<any>();
  collectionSize                    = 0;
  page                              = 1;
  pageSize                          = 10;
  searchKey                         = "";
  nodata: string                    = this.lang.transform('lang_loading');
  admin                             = JSON.parse(this.ls.getItem('is_admin'));
  wholeData:any                     = [];
  tabledata:any                     = [];
  groups:any                        = [];
  formgroups:any                    = [];
  modules:any                       = [];
  users:any                         = [];
  module_key:any                    = null;
  group_key:any                     = null;
  constructor(public ds:DataService, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.spinner.show();
    this.get_modules();
    this.get_filter_groups();
    this.get_file_users_groups();
    this.form = new FormGroup({
      module_key   : new FormControl(null),
      group_key    : new FormControl(null, [Validators.required]),
      user_id      : new FormControl(null),
    });
  }
  public get_modules() {
    this.modules = [];
    this.ds.postActionByUrl([], 'module').subscribe(res => {
      if(res.status) {
        this.modules = res.records;
      }
    });
  }
  public get_filter_groups() {
    this.groups = [];
    this.ds.getActionByUrl([], 'ugroup_lists').subscribe(res => {
      if(res.status) {
        this.groups = res.records;
      }
    });
  }
  public get_groups(module_key) {
    this.formgroups = [];
    let param = new FormData();
    param.append('module_key', module_key);
    this.ds.getActionByUrl(this.ds.formData2string(param), 'ugroup_lists').subscribe(res => {
      this.spinner.hide();
      if(res.status) {
        this.formgroups = res.records;
      }
    }, error => {
      this.spinner.hide();
    });
  }
  //add, edit model
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  public get_file_users_groups() {
    let param = new FormData();
    param.append('search_key', this.searchKey || '');
    param.append('module_key', this.module_key || '');
    param.append('group_key', this.group_key || '');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'lfugroup').subscribe(res=>{
      this.spinner.hide();
      if(res.status) {
        this.wholeData      = res.records;
        this.tabledatas     = res.records;
        this.collectionSize = res.records.length;
        this.build_table_data();
      } else {
        this.wholeData = [];
        this.nodata = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholeData = [];
      this.nodata = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    }) 
  }
  public build_table_data() {
    this.tabledata = this.tabledatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  public search_users(key) {
		this.users = [];
		if (key && key.term) {
			let param = new FormData();
			param.append('search_key', key.term || '');
			this.ds.getActionByUrl(this.ds.formData2string(param), 'gfusearch').subscribe(res => {
				if (res.status) {
					this.users = res.records;
				}
			})
		}
	}
  public submit() {
    this.spinner.show();
    this.ds.postActionByUrl(this.ds.json2formdata(this.form.value), 'cfugroup').subscribe(res=> {
      this.spinner.hide();
      if(res.status) {
        this.get_file_users_groups();
        this.form.reset();
        this.actionEvent.emit('');
        this.alert.success(res.msg);
        setTimeout(() => {
          this.modalRefA.hide();
        }, 1000);
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public checkallItemsfGUlevl(event) {
    if (event.target.checked) {
      $(".appfileusersgroups .eachItemfGUlevl").prop("checked", true);
      $(".appfileusersgroups .makeback").addClass("bground");
      $(".appfileusersgroups .deleteIconDiv").show();
    } else {
      $(".appfileusersgroups .eachItemfGUlevl").prop("checked", false);
      $(".appfileusersgroups .makeback").removeClass("bground");
      $(".appfileusersgroups .deleteIconDiv").hide();
    }
  }
  public checkeachItemfGUlevl(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".appfileusersgroups .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".appfileusersgroups .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".appfileusersgroups .eachItemfGUlevl:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".appfileusersgroups .eachItemfGUlevl").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".appfileusersgroups .deleteIconDiv").show();
    } else {
      $(".appfileusersgroups .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".appfileusersgroups #allItemsfGUlevl").prop("checked", true);
    } else {
      $(".appfileusersgroups #allItemsfGUlevl").prop("checked", false);
    }
  }
  public deleteBulkDatafGUlevl() {
    const checkedtotal = [];
    $(".appfileusersgroups .eachItemfGUlevl:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog,{
        disableClose: true,
        width:'400px',
        data:{
          title: this.lang.transform('lang_confirmation'),
          message: this.lang.transform('lang_are_you_sure'),          
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show()
          this.ds.deleteActionByUrl(checkedtotal, 'dfugroup').subscribe(
            (data) => {
              this.spinner.hide()
              if (data.status) {
                this.actionEvent.emit('');
                this.get_file_users_groups();
                $(".appfileusersgroups #allItemsfGUlevl").prop("checked", false);
                $(".appfileusersgroups .deleteIconDiv").hide();
                this.ds.dialogf('', data.msg);
              } else {
                this.ds.dialogf('', data.error);
              }
            },
            (error) => {
              this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
              this.spinner.hide()
            }
          );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }
}
