import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicationsComponent } from './applications/applications.component';
import { ApplicationviewComponent } from './applicationview/applicationview.component';
import { CreateComponent } from './create/create.component';
import { EeditComponent } from './eedit/eedit.component';
import { AddGroupComponent } from './employees/all-employees/attendance/add-group/add-group.component';
import { AttendanceComponent } from './employees/all-employees/attendance/attendance.component';
import { AddShifComponent } from './employees/all-employees/attendance/shifts/add-shift/add-shift.component';
import { AllShiftsComponent } from './employees/all-employees/attendance/shifts/all-shifts/all-shifts.component';
import { EmployeesComponent } from './employees/employees.component';
import { EviewComponent } from './eview/eview.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ViewVactionComponent } from './panels/vaction/view-vaction/view-vaction.component';
import { SettingsComponent } from './settings/settings.component';
const routes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: 'Settings' }
  },
  {
    path: 'attendance',
    children: [
       
      {
        path: '',
        component:AttendanceComponent ,
        data: {
          title: 'All attendance'
        }
      },
      {
        path: 'add-group',
       component:AddGroupComponent , 
        data: {
          title: 'add attendance'
        }
      },
      {
        path: 'all-shifts',
       component:AllShiftsComponent , 
        data: {
          title: 'all shifts'
        }
      },
      {
        path: 'add-shift',
       component:AddShifComponent , 
        data: {
          title: 'add shift'
        }
      }  
    ]
    },
  /* {
    path: 'attendance',
    component: AttendanceComponent,

    data: { title: 'attendance' }
  }, */
  {
    path: 'create',
    component: CreateComponent,
    data: { title: 'Add New Employee' }
  },
  {
    path: 'employees',
    component: EmployeesComponent,
    data: { title: 'All Employees' }
  },
  {
    path: 'view/:id/:key/:bid',
    component: EviewComponent,
    data: { title: 'View Employee Details' }
  },
  {
      path: 'edit/:id/:key/:bid',
      component: EeditComponent,
      data: { title: 'Edit Employee Details' }
  },
  {
    path: 'applications',
    component: ApplicationsComponent,
    data: { title: 'All Applications' }
  },
  {
    path: 'applicationview/:id/:key',
    component: ApplicationviewComponent,
    data: { title: 'Application Details' }
  },
  {
    path: 'vaction/view/:id',

    component: ViewVactionComponent

  },
  
  {
    path: 'notifications',
    component: NotificationsComponent,
    data: { title: 'All Notifications' }
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HumanresourcesRoutingModule { }
