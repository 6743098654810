import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $:any;
@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss']
})
export class InsuranceComponent implements OnInit {
  apiurl = environment.SERVER_ORIGIN;
  @Input() able2edit;
  @Input() able2delete;
  @Input() able2add;
  @Input() id;
  @Input() branch_id;
  @Input() key;
  @Input() requestfor;
  @Input() attachments;
  @Input() user_data;
  @Input() filepermissionlevels;
  @Output() childToParentAttach = new EventEmitter<String>();
  @Input() attachform;
  public insmainform: FormGroup;
  public insurancelist: FormArray;
  myinsurances: any = [];
  insdata: any = [];
  inserrormsglist: string = this.lang.transform('lang_loading');
  inserrorMsg: any = '';
  ins_error_status: boolean = false;
  ipsize: any = 10;
  ipage: any = 1;
  insuranceviewdata: any = [];
  inseditform: FormGroup;
  ins_edit_status: boolean = false;
  insediterrorMsg: string = "";
  idfilename = this.lang.transform('lang_select_files');
  formstatus: boolean = false;
  formerror: string = "";
  modalRefA: BsModalRef;
  modalRefV: BsModalRef;
  modalRefF: BsModalRef;
  modalRefU: BsModalRef;
  searchKey = "";
  searchStatus = "";
  expandstatus = false;
  constructor(public router:Router, public ds:DataService,public route :ActivatedRoute, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }
  ngOnInit(): void {
    this.expandstatus = (this.requestfor == 'profile') ? true : false;
    this.ins_main_form();
    this.ins_edit_form();
    this.list_of_insurance_dependents();
  }
  public openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true,
      class : 'width70'
    });
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  public openModalU(template: TemplateRef<any>) {
    this.modalRefU = this.modalService.show(template, environment.modelconfig);
  }
  public openModalFile(template: TemplateRef<any>) {
    this.modalRefF = this.modalService.show(template, environment.modelconfig);
  }
  get insuranceFormGroup() {
    return this.insmainform.get("insgroups") as FormArray;
  }
  public ins_main_form() {
    this.insmainform = new FormGroup({
      insurance_number: new FormControl('', [Validators.required]),
      insurance_date: new FormControl('', [Validators.required]),
      insurance_type_class: new FormControl('', [Validators.required]),
      insgroups: this.fb.array([]),
    });
    this.insurancelist = this.insmainform.get("insgroups") as FormArray;
  }
  public createInsurance(): FormGroup {
    return this.fb.group({
      employee_number: new FormControl(this.id, [Validators.required]),
      insurance_dependent_name: new FormControl('', [Validators.required]),
      insurance_dependent_number: new FormControl('', [Validators.required]),
      insurance_dependent_ins_no: new FormControl('', [Validators.required]),
      insurance_dependent_reaplationship: new FormControl("", [Validators.required]),
      insurance_dependent_date: new FormControl('', [Validators.required]),
    });
  }
  public dynamicInsuranceAdd() {
    if (this.insurancelist.controls.length < 5) {
      this.insurancelist.push(this.createInsurance());
    }
  }
  public dynamicInsuranceRemove(index) {
    this.insurancelist.removeAt(index);
  }
  public emptyInsuranceForm(number, date, inclass) {
    this.inserrorMsg = '';
    this.ins_error_status = false;
    this.insmainform.patchValue({
      insurance_number: number,
      insurance_date: this.ds.encon(date),
      insurance_type_class: inclass
    })
    this.insurancelist.controls = [];
  }
  public create_insurance() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('branch_id', this.branch_id);
    formData.append('id', this.id);
    formData.append('employee_id_number', this.user_data.employee_id_number);
    formData.append('insurance_number', this.insmainform.get('insurance_number').value || '');
    formData.append('insurance_date', this.insmainform.get('insurance_date').value || '');
    formData.append('insurance_type_class', this.insmainform.get('insurance_type_class').value || '');
    if(this.insuranceFormGroup.value.length > 0) {
      this.insuranceFormGroup.value.forEach((v, k) => {
        formData.set("insgroups[" + k + "][insurance_dependent_name]", v.insurance_dependent_name || '');
        formData.set("insgroups[" + k + "][insurance_dependent_ins_no]", v.insurance_dependent_ins_no || '');
        formData.set("insgroups[" + k + "][insurance_dependent_number]", v.insurance_dependent_number || '');
        formData.set("insgroups[" + k + "][insurance_dependent_reaplationship]", v.insurance_dependent_reaplationship || '');
        formData.set("insgroups[" + k + "][insurance_dependent_date]", v.insurance_dependent_date || '');
      });
    }
    this.ds.postActionByUrl(formData, 'hr_create_insurance').subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.search_my_insurance();
        this.alert.success(data.msg);
        this.insmainform.reset();
        this.insurancelist.controls = [];
        this.childToParentAttach.emit('');
        setTimeout(() => {
          this.modalRefA.hide();
        }, 2000);
      } else {
        this.alert.error(data.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error(error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public inspage(size) {
    this.ipsize = size;
    this.ipage = 1;
    this.list_of_insurance_dependents();
  }
  public inslength(page) {
    this.ipage = page;
    this.list_of_insurance_dependents();
  }
  public search_my_insurance() {
    this.ipage = 1;
    this.ipsize = 10;
    this.list_of_insurance_dependents();
  }
  public list_of_insurance_dependents() {
    let formData = new FormData();
    formData.append('id', this.id);
    formData.append('employee_id_number', this.user_data.employee_id_number);
    formData.append('branch_id', this.branch_id);
    formData.append('searchKey', this.searchKey || '');
    formData.append('searchStatus', this.searchStatus || '');
    let url = (this.requestfor === "profile") ? 'my_dependents' : 'hr_insurance_dependents';
    this.ds.postActionByUrl(formData, url + "/" + this.ipage + '/' + this.ipsize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.insdata = res;
      } else {
        this.insdata = [];
        this.inserrormsglist = res.error;
      }
    }, error => {
      this.spinner.hide();
      this.insdata = [];
      this.inserrormsglist = error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }
  public view_my_insurance(ins) {
    this.insuranceviewdata = ins;
  }
  public ins_edit_form() {
    this.inseditform = new FormGroup({
      insurance_dependent_id: new FormControl('', [Validators.required]),
      insurance_dependent_name: new FormControl('', [Validators.required]),
      insurance_dependent_number: new FormControl('', [Validators.required]),
      insurance_dependent_ins_no: new FormControl('', [Validators.required]),
      insurance_dependent_reaplationship: new FormControl('', [Validators.required]),
      insurance_dependent_date: new FormControl('', [Validators.required]),
    });
  }
  public edit_insurance(ins) {
    this.inseditform.patchValue({
      insurance_dependent_id: ins.insurance_dependent_id,
      insurance_dependent_name: ins.insurance_dependent_name,
      insurance_dependent_number: ins.insurance_dependent_number,
      insurance_dependent_ins_no: ins.insurance_dependent_ins_no,
      insurance_dependent_reaplationship: ins.insurance_dependent_reaplationship,
      insurance_dependent_date: this.ds.encon(ins.insurance_dependent_date),
    });
  }
  public update_insurance() {
    this.spinner.show();
    const formData = this.ds.json2formdata(this.inseditform.value);
    formData.append('branch_id', this.branch_id);
    formData.append('id', this.id);
    formData.append('employee_id_number', this.user_data.employee_id_number);
    formData.append('insurance_dependent_date', this.inseditform.get('insurance_dependent_date').value || '');
    this.ds.putActionByUrl(this.ds.formData2string(formData), 'hr_update_insurance').subscribe(data => {      
      this.spinner.hide();
      if (data.status) {
        this.alert.success(data.msg);
        this.search_my_insurance();
        setTimeout(() => {
          this.modalRefU.hide();
        }, 2000);
      } else {
        this.alert.error(data.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error(error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public checkAllIns(event) {
    if (event.target.checked) {
        $(".appinsurances .eachItemIns").prop("checked", true);
        $(".appinsurances .makeback").addClass("bground");
        $(".appinsurances .deleteIconDiv").show();
    } else {
        $(".appinsurances .eachItemIns").prop("checked", false);
        $(".appinsurances .makeback").removeClass("bground");
        $(".appinsurances .deleteIconDiv").hide();
    }
  }
  public checkEachItemIns(event) {
      if (event.target.checked) {
          const val = event.target.value;
          $(".appinsurances .removerow" + val).addClass("bground");
      } else {
          const val = event.target.value;
          $(".appinsurances .removerow" + val).removeClass("bground");
      }
      const checkedtotal = [];
      $(".appinsurances .eachItemIns:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      const totalrow = [];
      $(".appinsurances .eachItemIns").each(function() {
          totalrow.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          $(".appinsurances .deleteIconDiv").show();
      } else {
          $(".appinsurances .deleteIconDiv").hide();
      }
      if (checkedtotal.length === totalrow.length) {
          $(".appinsurances #allItemsIns").prop("checked", true);
      } else {
          $(".appinsurances #allItemsIns").prop("checked", false);
      }
  }
  public deleteBulkDataIns() {
      const checkedtotal = [];
      $(".appinsurances .eachItemIns:checked").each(function() {
          checkedtotal.push($(this).val());
      });
      if (checkedtotal.length > 0) {
          const dialogRef = this.dialog.open(ConfirmationDialog, {
              disableClose: true,
              width: '400px',
              data: {
                  title: this.lang.transform('lang_confirmation'),
                  message: this.lang.transform('lang_are_you_sure'),
              }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                  this.spinner.show()
                  this.ds.deleteActionByUrl(checkedtotal, "01f5086b879a62a05da4094dac203558/INSURANCE/"+this.id+'/'+this.branch_id).subscribe(
                      (data) => {
                          this.spinner.hide()
                          if (data.status) {
                              this.search_my_insurance();
                              $(".appinsurances #allItemsIns").prop("checked", false);
                              $(".appinsurances .deleteIconDiv").hide();
                              this.ds.dialogf('', data.msg);
                          } else {
                              this.ds.dialogf('', data.error);
                          }
                      },
                      (error) => {
                          this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
                          this.spinner.hide()
                      }
                  );
              }
          });
      } else {
          this.ds.dialogf();
      }
  }
  public emptyAttachForm(type) {
    this.formstatus = false;
    this.formerror = "";
    this.attachform.reset();
    this.attachform.get("employee_number").setValue(this.id);
    this.attachform.get("attachment_type").setValue(type);
    this.idfilename = this.lang.transform('lang_select_files');
    this.alert.clear();
  }
  public uploadAttachFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.idfilename = fileid.name;
      this.attachform.get("attachment_link").setValue(fileid, { emitEvent: true });
    } else {
      this.attachform.get("attachment_link").setValue(null);
      this.idfilename = this.lang.transform('lang_select_files');
    }
  }
  public upload_files() {
    this.spinner.show();
    this.ds.postActionByUrl(this.ds.json2formdata(this.attachform.value), 'hr_upload_attachments').subscribe(data => {
      this.spinner.hide();
      this.formstatus = data.status;
      if (data.status) {
        this.childToParentAttach.emit('INSURANCE');
        setTimeout(() => {
          this.modalRefF.hide();
        }, 2000);
        this.alert.success(data.msg);
      } else {
        this.alert.error(data.error);
      }
    }, error => {
        this.spinner.hide();
        this.alert.error(error.error + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    });
  }
}
