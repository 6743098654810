import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { DwsrService } from '../../FORM_PS_TR1/services/dwsr.service';

declare var $:any;
@Component({
  selector: 'app-form-ncr-add',
  templateUrl: './form-ncr-add.component.html',
  styleUrls: ['./form-ncr-add.component.scss']
})
export class FormNcrAddComponent implements OnInit {

  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  @Input() unitsOfTemplate;
  transaction_request:any;
  persons: any = [];
  notes: any = [];
  form_url = "";
  form:FormGroup;
  lang_key = localStorage.getItem('lang_key');
//stepper
@ViewChild('stepper') myStepper: MatStepper;
totalStepsCount: number = 13;
selectedIndex: number = 0;
stepProgressValue = 100 / this.totalStepsCount;
progressValue = this.stepProgressValue;
stepsTitles = [
  `${this.lang.transform("lang_configurations")}`,
  'Contractor Manager Approval',
  'Confirm Receive NCR',
  'View NCR',
  'Confirm Receive NCR',
  'View NCR',
  'Contractor Manager Approval',
  'Close NCR Request',
  'Contractor Manager Approval',
  "Confirm Receive NCR",
  'Close NCR Request',
  'Project Manager Approval',
  'Final Close'
];
stepTitle =this.stepsTitles[this.selectedIndex];
@ViewChild('stepTitleEl') stepTitleEl:any;
varSettimeout:any;

  isLinear:boolean      = false;
  showStatus:boolean    = false;
  currentStep           = 0;
  @Output() showAction  = new EventEmitter();
  constructor(public ds:DataService, public datepipe : DatePipe ,  private dwsrService: DwsrService, public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) {
    this.dwsrService.data.subscribe(data => {
      this.allData = data;
      console.log(data);
      this.persons = this.allData?.view_request?.transactions_persons?.records || [];
    this.notes   = this.allData?.view_request?.transactions_notes?.records || [];
    this.transaction_request = this.allData?.view_request?.transactions_request?.records || {};
    })
  }

  ngOnInit(): void {
    this.form_url = "FORM_NCR"; //"FORM_"+this.template_data?.template_platform_group_type_code_system;
    this.currentStep = 0;
    this.get_create_template_options();
  }
  ngAfterViewInit(): void {
    this.varSettimeout = setTimeout(() => {
      this.styleStepperTitle();
    }, 1100);
  }

  selectionChange(e: any) {
    this.selectedIndex = e.selectedIndex;
    this.progressValue = this.stepProgressValue * (this.selectedIndex + 1);
    this.styleStepperTitle();
    this.stepTitle =this.stepsTitles[this.selectedIndex];
  }

  styleStepperTitle() {
    if(this.selectedIndex + 1 == this.totalStepsCount) {
      $('#stepTitle').css(`${this.lang_key=='en'?'left':'right'}`, `${this.progressValue - 12}%`);
    } else {
      $('#stepTitle').css(`${this.lang_key=='en'?'left':'right'}`, `${this.progressValue - 6}%`);
    }
    
  }
  moveStepper(action: string, mode:string) {
    if(mode == 'create') {
      return false;
    } else {
      if(action == 'next' && (this.myStepper.selectedIndex + 1) < this.myStepper.steps.length) {
        this.myStepper.selectedIndex++;
      } else if(action == 'prev' && this.myStepper.selectedIndex > 0) {
        this.myStepper.selectedIndex--;
      } else {
        return false;
      }
    }
  }

  current_stepper(step) {
    this.currentStep = parseInt(step);
  }
  public get_create_template_options() {
    this.spinner.show();
    let param = new FormData();
    param.append('projects_work_area_id', this.supervision_data?.projects_work_area_id);
    param.append('platform_code_system', this.form_key);
    param.append('template_id', this.template_data?.template_id);
    this.ds.postActionByUrl(param, 'form/'+this.form_url+'/cr/0/0').subscribe((res) => {
      this.spinner.hide();
      this.showStatus = res.status;
      if(res.status) {
        //
      } else {
        this.ds.dialogf('', res.error);
      }
    }, error => {
      this.spinner.hide();
      this.showStatus = true;
      this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }

  ngOnDestroy(): void {
    clearTimeout(this.varSettimeout);
  }

}
