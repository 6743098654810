import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { PreloaderService } from '@core/bootstrap/preloader.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
 

@Component({
  selector: 'app-finanial-details',
  templateUrl: './finanial-details.component.html',
  styleUrls: ['./finanial-details.component.scss'],

})
export class FinanialDetialsComponent implements OnInit {


  loading = true;
  searchText = '';
  @Output() showForm = new EventEmitter();
  @Input() able2edit;
  @Input() able2delete;
  @Input() able2add;
  @Input() id;
  @Input() branch_id;
  @Input() key;
  @Input() types;
  @Input() requestfor;
  @Input() user_data;
  @Input() attachments;
  modalRefV: BsModalRef;
//  noteform: FormGroup;
  modalRefA: BsModalRef;
  npsize: any = 10;
  npage: any = 1;
  lang_key = this.ls.getItem('lang_key') || 'en';

  search: string;
  pageSize = 10;
  pageIndex = 0;
  totalElements = 100;
  viewData:any;
  DialogRef: MatDialogRef<any>;

  // lang = "en";

  form:FormGroup = this.fb.group({
    date_from:null,
    date_to:null,
    type:null,
    search_value:'',
    accepted:null,
  });

  typesOfFinance=[  
    {
      label:'All',
      id:'all'
    },
    {
      label:'Deduction',
      id:'deduct'
    },
    {
      label:'Bonus',
      id:'bonus'
    },
    {
      label:'Loan',
      id:'loan'
    },
    {
      label:'Overtime',
      id:'overtime'
    },
  ]

  accepted=[  
    {
      label:'All',
      id:'All'
    },
    {
      label:'Accepted',
      id:'Accepted'
    },
    {
      label:'Refused',
      id:'Refused'
    },
   
    
  ]
  noteform: FormGroup = this.fb.group({
   
});
  branches :[] =[];


  searchKey             = "";
  module_filter         = "";
  wholedata: any        = [];

  vacations:any           = [];
  expandstatus          = false;
  page                  = 1;
  size                  = 10;
  // errormessagef: any    = this.lang.transform('lang_loading');
  moduleusers:any       = [];
  moduledata:any        = [];
  history:any           = [];
  persons:any           = [];
  collectionSize:any;

  constructor(public router:Router, public ds:DataService,public route :ActivatedRoute, public lang:LanguagePipe, public ls:LocalStorage, public fb:FormBuilder, public spinner:PreloaderService, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService) { }

  ngOnInit(): void {
    this.expandstatus = (this.requestfor == 'profile') ? true : false;
    this.getEmployeeInfo();
    this.search_employee_modules();
 
   
    
  }
 

  public load_page_size(pageno) {
    this.spinner.show();
    this.size = pageno;
    this.get_employee_modules();
  }

  public page_length(page) {
    this.spinner.show();
    this.page = page;
    this.get_employee_modules();
  }


  public search_employee_modules() {
    this.size = 10;
    this.page = 1;
    this.get_employee_modules();
  }

  refreshLists() {

  }
  errormessagef: any = this.lang.transform('lang_loading');
  public get_employee_modules() {
    const param = new FormData();
    /* param.append("search_key", this.searchKey || ''); */
    this.spinner.show();
    param.append("finical_type", this.form.value.type || 'all');
    param.append("search_value", this.form.value.search_value);
    param.append("employee_number", this.id);
    let url =  'human_resources/get_employee_finical_history';
    this.ds.post(url+'/'+this.page+'/'+this.size,param).subscribe(data => {
      this.spinner.hide();
      if (data.status) {
        this.wholedata = data;
        if(!data.records){
          this.wholedata.records=[]
        }
      } else {
        this.wholedata.records = [];
        this.errormessagef = data.error;
      }
    }, error => {
      this.spinner.hide();
      this.wholedata = [];
      this.errormessagef = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
      // this.errormessagef = (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }

  

  /* showVacationDetails(id) {
    const dialogRef = this.dialog.open(ViewVactionComponent);

  } */
  getEmployeeInfo() {
    let data = new FormData();
    data.append('employee_number', this.user_data.employee_number)
    this.ds.post('form/FORM_HRV1/get_employee_info', data).subscribe(res => {
      if(res.status) {
        console.log(res);
        this.viewData = res.result;
      }
      
    })
  }


  public note_operations() {
    this.spinner.show();
    if(this.noteform.get('note_id').value) {
      const formData = new FormData();
      formData.append('note_id', this.noteform.get('note_id').value);
      formData.append('note_description', this.noteform.get('note_description').value || '');
      formData.append('note_remainder_status', this.noteform.get('note_remainder_status').value);
      if(this.noteform.get('note_remainder_status').value == "1") {
        formData.append('note_remainder_date', this.noteform.get('note_remainder_date').value || '');
      }
      formData.append('show_status', this.noteform.get('show_status').value);
      formData.append('link_with_view_list', this.noteform.get('link_with_view_list').value);   
      formData.append('id', this.id);
      this.ds.putActionByUrl(this.ds.formData2string(formData), 'hr_update_notes').subscribe(data => {
        this.spinner.hide();
        if (data.status) {
          this.alert.success(data.msg);
          this.noteform.reset();
          this.list_of_notes();
          setTimeout(() => {
            this.modalRefA.hide();
          }, 1000);
        } else {
          this.alert.error(data.error);
        }
      }, error => {
        this.spinner.hide();
        // this.alert.error(error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    } else {
      const formData = new FormData();
      formData.append('note_description', this.noteform.get('note_description').value || '');
      formData.append('note_remainder_status', this.noteform.get('note_remainder_status').value);
      if(this.noteform.get('note_remainder_status').value == "1") {
        formData.append('note_remainder_date', this.noteform.get('note_remainder_date').value || '');
      }
      formData.append('show_status', this.noteform.get('show_status').value);
      formData.append('link_with_view_list', this.noteform.get('link_with_view_list').value);
      formData.append('id', this.id);
      this.ds.postActionByUrl(formData, 'hr_create_notes').subscribe(data => {
        this.spinner.hide();
        if (data.status) {
          this.alert.success(data.msg);
          this.noteform.reset();
          this.list_of_notes();
          setTimeout(() => {
            this.modalRefA.hide();
          }, 1000);
        } else {
          this.alert.error(data.error);
        }
      }, error => {
        this.spinner.hide();
        // this.alert.error(error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
      })
    }


















  }




  public branchlists() {
    this.branches = [];
    this.types = [];
    // this.etitles = [];
    // this.countries = [];
    // let permit = (this.action === "EDIT") ? 'human_resources_edit' : 'human_resources_add';
    this.ds.postActionByUrl([],'form/FORM_HRV1/get_vacation_type').subscribe(data => {
      console.log(data)
      this.branches = data.records;

      if(data.status) {
        this.branches = data.records;
        // this.etitles = data.titles;
        // this.countries = data.countries;
        // this.types = data.types
      }
    });
  }





  public list_of_notes() {
    let formData = new FormData();
    formData.append('id', this.id);
    formData.append('branch_id', this.branch_id);
    formData.append('search_key', this.searchKey || '');
    // formData.append('search_status', this.searchStatus || '');
    let url = (this.requestfor !== "profile") ? 'hr_notes' : 'get_my_notes';
    this.ds.postActionByUrl(formData, url+'/'+this.npage+'/'+this.npsize).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        // this.notedata = res;
      } else {
        // this.notedata = [];
        // this.noteerrormsg = res.error;
      }
    }, error => {
      this.spinner.hide();
      // this.notedata = [];
      // this.noteerrormsg = error.error + ' ' + (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error');
    })
  }




}





