import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Formhrv1AddComponent } from '../transactions/FORM_HRV1/formhrv1-add/formhrv1-add.component';
import { AllFilesComponent } from './admin/all-files/all-files.component';
import { FileSettingsComponent } from './admin/file-settings/file-settings.component';
import { LangphrasesComponent } from './admin/langphrases/langphrases.component';
import { RegistrationListsComponent } from './admin/registration-lists/registration-lists.component';
import { UsersListComponent } from './admin/users-list/users-list.component';
import { CpasswordComponent } from './cpassword/cpassword.component';
import { OprofileComponent } from './oprofile/oprofile.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileCreateVacationComponent } from './profileCreateVacation/profileCreateVacation.component';
const routes: Routes = [
  {
    path:'cneeHLhJ7c',
    component:LangphrasesComponent,
    data: { title: 'Languages & Phrases' }    
  },
  {
    path:'registrations',
    component:RegistrationListsComponent,
    data: { title: 'Registrations' }
  },
  {
    path:'LwvlpJCsbv',
    component:UsersListComponent,
    data: { title: 'Users List' }
  },
  {
    path:'LwvlpJdersD',
    component:FileSettingsComponent,
    data: { title: 'File Permissions' }
  },
  {
    path:'files',
    component:AllFilesComponent,
    data: { title: 'All Files' }
  },  
  {
    path:'profile',
    component:ProfileComponent,
    data: { title: 'Profile' }    
  },
  {
    path:'profile/create-vacation/:id/:bid',
    component:ProfileCreateVacationComponent,
    data: { title: 'create-vacation' }    
  },
  {
    path:'oprofile',
    component:OprofileComponent,
    data: { title: 'Profile' }    
  },  
  {
    path:'cpassword',
    component:CpasswordComponent,
    data: { title: 'Change Password' }    
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DefaultRoutingModule { }
