<form [formGroup]="form" (ngSubmit)="completeStep2()">
	<div class="card">
		<div class="card-header">
			{{ "lang_contractor_team_approval" | language }}
			<i
				style="padding: 0 10px"
				class="fa fa-info-circle orangestatus-color"
				matTooltip="{{ 'lang_contractor_team_approval_tooltip' | language }}"
			></i>
		</div>
		<div class="mbrl15">
			<div class="row mb-10">
				<div class="col-sm-6 col-xs-12 mb-10">
					<label class="mb-10">
						{{ "lang_transaction_approval" | language }} !?.
						<span class="red"> * </span> </label
					><br />
					<mat-radio-group
						aria-label="Select an option"
						formControlName="contractor_approval_status"
						(change)="form.get('contractor_rejected_notes').setValue('')"
					>
						<mat-radio-button value="Return">{{
							"lang_return_to_update" | language
						}}</mat-radio-button>
						<mat-radio-button value="Approve">{{
							"lang_approve" | language
						}}</mat-radio-button>
					</mat-radio-group>
				</div>
				<div class="col-sm-6 col-xs-12 mb-10">
					<div *ngIf="form.get('contractor_approval_status').value == 'Return'">
						<label
							>{{ "lang_rejected_notes" | language }} :
							<span class="red">*</span></label
						>
						<textarea
							class="form-control almnabrformcontrol"
							formControlName="contractor_rejected_notes"
						></textarea>
					</div>
				</div>
				<div class="col-sm-12">
					<app-alert id="default-alert"></app-alert>
				</div>
				<div class="col-sm-4 col-xs-12 mb-10"></div>
				<div class="col-sm-4 col-xs-12 mb-10"></div>
				<div class="col-sm-4 col-xs-12 mb-10">
					<div>
						<input
							type="submit"
							value="{{ 'lang_submit' | language }}"
							class="albutton mnabrbutton mat-accent"
							mat-button
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
