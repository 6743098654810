import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { environment } from '@env/environment';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
 
import {Observable, of, OperatorFunction} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap} from 'rxjs/operators';
import { ViolationHistoryComponent } from '../send-code/violation-history.component';

@Component({
  selector: 'app-createViolation',
  templateUrl: './createViolation.component.html',
  styleUrls: ['./createViolation.component.scss']
})
export class CreateViolationComponent implements OnInit {

  @Output() violationEvent = new EventEmitter();
  @Input() employee_data;
  @Input() form_request_id;
  @Input() form_key;
  @Input() id;
 
  users:any         = [];
  direct_anager:any = [];
  form:FormGroup;
  submitted:boolean = false;
  lang_key          = localStorage.getItem('lang_key') || 'en';
  constructor(public ds:DataService, public datepipe : DatePipe, public dialog: MatDialog,  private snackBar:MatSnackBar, public lang:LanguagePipe, public spinner : PreloaderService, public alert:AlertService, public fb: FormBuilder, public router: Router, public changeDetectorRef: ChangeDetectorRef) { }


  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  ngOnInit(): void {
    this.spinner.show();
    
    this.form = this.fb.group({
      violation_id:['', Validators.required],
      employee_number: [this.employee_data.employee_number],
      violation_date_en: [new Date(), Validators.required],
      violation_date_ar: ['', Validators.required],
      violation_data_en: ['', ],
      violation_data_ar: ['',  ],
      violation_punishment_id: ['', Validators.required],
      violation_punishment: ['', Validators.required],
      violation_count: ['', Validators.required],
      violation_statement_en: ['', Validators.required],
      violation_statement_ar: ['', Validators.required],
      violation_punishment_type: ['', Validators.required],
      violation_deduct_percentage: ['', Validators.required],
      violation_amount: ['', Validators.required],
 
      direct_manager: ['', Validators.required],
   
    });
    this.ds.arabicToGiorgen("EN",this.datepipe.transform(new Date(), "dd/MM/yyyy")).subscribe(
      (data) => {
        if (data.status) {
          let r = data.data.hijri.date.split("-");
          let reqdate = r[0] + "/" + r[1] + "/" + r[2];
         /*  this.form.get('violation_date_ar').patchValue({ 
            ...this.form.value,
            violation_date_ar :reqdate
          }); */
          this.form.get('violation_date_ar').setValue(reqdate);
        } else {
          this.form.get('violation_date_ar').setValue(null);
        }
      },
      (error) => {
        this.form.get('violation_date_ar').setValue(null);
      }
    );

    this.searchFunc()
    this.spinner.hide();
    console.log(this.form);
   
  }

  searchArray = []
  searchFunc() {
   

  
   

  /*   this.spinner.show(); */
    let formData = new FormData();
    /* formData.append('search',term); */


  
    return  this.ds.post(`form/${this.form_key}/search_violation`, formData).subscribe(
      (response:any) => {
        console.log(response);
      /*   this.spinner.hide(); */
    
      this.searchArray =  response.records.map(re=>{
        return {
          label:this.lang_key == 'en'? re.violation_en : re.violation_ar,
          value:re.violation_id
        }
      })
      })

   
 
  }
  
 
  getViolations(e){
    moment.locale('en')

  /*   this.spinner.show(); */
  let formData = new FormData();
  formData.append('employee_number',this.form.value.employee_number); 
  formData.append('violation_id',e.value); 
  formData.append('violation_date_english',moment(this.form.value.violation_date_en).format('YYYY/MM/DD')); 



  return  this.ds.post(`form/${this.form_key}/check_violation_for_employee`, formData).subscribe(
    (response:any) => {
      console.log(response);
      if(response.status){
        this.form.patchValue({
          ...this.form.value,
          violation_id:response.records.violation_id,
          violation_punishment_id:response.records.violation_punishment_id,
          violation_punishment:response.records.punishment_en,
          violation_count:response.records.alert_time,
          violation_punishment_type:response.records.punishment_type,
          violation_deduct_percentage:+response.records.deduct_percentage||0,
          violation_amount:+response.records.deduct_amount||0,
        })
      }else{

      }
   
    })

 

  }


  submit(){
    this.submitted = true;
    if(this.form?.status == 'VALID'){

      this.spinner.show();
      let formData = new FormData();
      formData.append('employee_number',this.employee_data.employee_number);
      formData.append('violation_date_en',moment(this.form.value.violation_date_en).format('YYYY/MM/DD'));
      formData.append('violation_date_ar',this.form.value.violation_date_ar);
      formData.append('violation_id',this.form.value.violation_id);
      formData.append('violation_punishment_id',this.form.value.violation_punishment_id);
      formData.append('direct_manager', this.form.value.direct_manager || '');
      formData.append('violation_punishment',this.form.value.violation_punishment);
      formData.append('violation_count',this.form.value.violation_count);
      formData.append('violation_statement_en',this.form.value.violation_statement_en);
      formData.append('violation_statement_ar',this.form.value.violation_statement_ar);
      formData.append('violation_punishment_type',this.form.value.violation_punishment_type);
      this.form.value.violation_deduct_percentage = +this.form.value.violation_deduct_percentage
      formData.append('violation_deduct_percentage',this.form.value.violation_deduct_percentage);
      formData.append('violation_amount',this.form.value.violation_amount);

      this.ds.post(`form/${this.form_key}/cr/0`, formData).subscribe(data => {
        
        console.log('res',data);
        
        this.alert.success(data.msg);
        this.submitted = false;
        this.form.reset()
        this.showMsg(this.lang.transform('lang_created_successfully'), 'ok', 'success-snackbar');
        setTimeout(() => {
          this.router.navigate(['transactions/allforms/']);
        }, 1000);
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
          this.alert.error(error);
      })
   
   
      
    }else{
      /* this.form.controls.createInstallments.enable(); */
      this.showMsg('Please fill all fields first', 'ok', 'error-snackbar');
    }
  }


  showMsg(msg:string, action:string, className:string) {
    this.snackBar.open(msg, action, {
      duration:4000,
      panelClass: [className]
    });
  }

  public search_users(key) {
    if(key){
      this.users = [];
      let param = new FormData();
      param.append('search', key.term);
      param.append('lang_key', this.lang_key);
      param.append('user_type_id', '1');
      this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      })
    }
  
  }


  violationsArr=[]
  modalRef: BsModalRef;
  get_employee_violations(){
 
  if(!this.form.value.violation_id){
    this.showMsg(this.lang.transform('lang_please_select_violation_first'), 'ok', 'error-snackbar');
    return 0;
  }

  /*   this.spinner.show(); */
  let formData = new FormData();
  formData.append('employee_number',this.form.value.employee_number); 
  formData.append('violation_id',this.form.value.violation_id); 
  formData.append('lang_key',this.lang_key); 

  this.spinner.show();


  return  this.ds.post(`form/${this.form_key}/get_employee_violations`, formData).subscribe(
    (response:any) => {
     
      this.spinner.hide();
 
      if(response.status){
        this.violationsArr = response.records;
      
        this.openModal()
      }else{
        this.showMsg(response.error, 'ok', 'error-snackbar');
      }
   
    }), (error) => {
      this.spinner.hide();
      this.alert.error(error);
      
    }
 

 

  } 
  
  openModal() {
    const dialogRef = this.dialog.open(ViolationHistoryComponent,{
      disableClose: true,
      width:'70%',
      data: { violationsArr: this.violationsArr}
      
    });
  
  }
  
 
  

  get violation_id (){
    return this.form.get('violation_id') as FormControl;
  }
}
