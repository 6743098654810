<div class="card supervision_time_schedules">
    <div class="card-header">{{'lang_time_schedule' | language }} </div>
    <div class="card-body pad0">
        <div class="mbrl15">
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-12 mb-10">
                    <select (change)="spinner.show();search_supervision_time_schedules();" [(ngModel)]="scheduleStatus" class="form-control almnabrformcontrol">
                        <option value="1" selected>{{'lang_active' | language }}</option>
                        <option value="0">{{'lang_history' | language }}</option>
                        <option>{{'lang_all' | language }}</option>
                    </select>
                </div>
                <div class="col-md-9 col-sm-8 col-xs-12 mb-10">
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control almnabrformcontrol" [(ngModel)]="searchKey" (keyup)="search_supervision_time_schedules();" placeholder="{{'lang_search' | language }}" />
                        <span class="input-group-btn" *ngIf="able2add">
                            <button id="btnSearch" type="button" matTooltip="{{'lang_add' | language }}" class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" (click)="emptyform();openModal(scheduleForm);"></button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive dropdownyes customResponsive">
                        <table border="1" class="table CustomTable">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>
                                        <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                            <input type="checkbox" class="allItemsSch checkbox" id="AllItemsSch" (click)="checkAllItemsSch($event)"/>
                                            <label for="checkbox"></label>
                                        </div>
                                    </td>
                                    <td>{{'lang_description' | language }}</td>
                                    <td>{{'lang_file_level' | language }}</td>
                                    <td>{{'lang_status' | language }}</td>
                                    <td>{{'lang_writer' | language }}</td>
                                    <td>{{'lang_ondate' | language }}</td>
                                    <td>{{'lang_action' | language }}</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="timeListData.length != '0' " class="AppendList">
                                <tr *ngFor="let list of timeListData.records; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{list.time_schedule_id}}">
                                    <td>{{i+1}}</td>
                                    <td>
                                        <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                            <input type="checkbox"  class="eachItemSch checkbox" value="{{list.time_schedule_id}}" (click)="checkEachItemSch($event)"/>
                                            <label for="checkbox"></label>
                                        </div>
                                    </td>
                                    <td>{{list.time_schedule_description}}</td>
                                    <td>{{list.level_keys}}</td>
                                    <td *ngIf="list.time_schedule_status == '0'">
                                        <i class="fa fa-icon fa-circle red" matTooltip="{{'lang_history' | language }}" aria-hidden="true"></i>
                                    </td>
                                    <td *ngIf="list.time_schedule_status == '1'">
                                        <i class="fa fa-icon fa-circle green" matTooltip="{{'lang_active' | language }}" aria-hidden="true"></i>
                                    </td>
                                    <td>{{list.writer}}</td>
                                    <td>{{list.created_datetime}}</td>
                                    <td>
                                        <i class="together-icons">
                                            <i matTooltip="{{'lang_file' | language }}" (click)="ds.previewData(list.time_schedule_attachment_url,'#pdfPreview1')" class="fa fa-paperclip fa-icon s2icon mnabricon" ></i>
                                            <i *ngIf="able2edit" matTooltip="{{'lang_edit' | language }}" (click)="emptyEditform(list); openModal(scheduleForm)" class="fa fa-pencil-square-o fa-icon s2icon mnabricon"></i>
                                        </i>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="timeListData.length == '0' " class="AppendList">
                                <tr class="odd">
                                    <td colspan="8" align="center">{{timenodata}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="timeListData.length != '0' " class="card-footer padb0">
            <div class="row">
                <div *ngIf="able2delete" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                    <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataSch()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                </div>
                <div class="col-lg-2 col-sm-6 col-6  mb-10">
                    {{'lang_total' | language }} : {{timeListData.page.total_records}}
                </div>
                <div class="col-lg-5 col-sm-6 mb-10">
                    <mat-radio-group>
                        <mat-radio-button value="10" checked (click)="load_supervision_Schedules_pagesize(10)"> 10 </mat-radio-button>
                        <mat-radio-button value="20" (click)="load_supervision_Schedules_pagesize(20)"> 20 </mat-radio-button>
                        <mat-radio-button value="50" (click)="load_supervision_Schedules_pagesize(50)"> 50 </mat-radio-button>
                        <mat-radio-button value="100" (click)="load_supervision_Schedules_pagesize(100)"> 100 </mat-radio-button>
                        <mat-radio-button value="500" (click)="load_supervision_Schedules_pagesize(500)"> 500 </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="col-lg-4 col-sm-6">
                    {{'lang_goto' | language }} :
                    <select class="customselect" (change)="load_supervision_Schedules_page($event.target.value)">
                        <option *ngFor="let ks of ds.getrange(timeListData.page.total_pages)" value="{{ks}}">{{ks}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #scheduleForm>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_time_schedule' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="modal-body"> 
                <div class="form-group">
                    <label for="level_keys">{{'lang_file_secutrity_levels' | language }} : <span class="red">*</span></label>
                    <ng-select 
                        [items]="filelevels" 
                        [multiple]="true" 
                        bindLabel="label" 
                        appendTo="body" 
                        bindValue="value" 
                        clearAllText="Clear"
                        placeholder="{{'lang_file_secutrity_levels' | language}}"
                        formControlName="level_keys">
                    </ng-select>
                    <mat-error *ngIf="form.controls['level_keys'].touched && form.controls['level_keys'].errors?.required"> {{'lang_field_required' | language }} </mat-error>
                </div>
                <div class="form-group">
                    <label for="time_schedule_description">{{'lang_description' | language }}:<span class="red">*</span></label>
                    <input type="text" class="form-control almnabrformcontrol" formControlName="time_schedule_description" placeholder="{{'lang_description' | language }}">                        
                    <mat-error *ngIf="form.controls['time_schedule_description'].touched && form.controls['time_schedule_description'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
                <div class="form-group">
                    <label for="implimentation_phases_required">{{'lang_attachments' | language }}:
                        <span *ngIf="!form.get('time_schedule_id').value"class="red"> * </span>
                        <span *ngIf="isFile" for="time_schedule_attachment_url"> <i aria-hidden="true" (click)="removeFile()" class="fa faicon fa-close red"></i> </span>
                    </label>
                    <div class="IDDIV">
                        <input style="display: block;" name="time_schedule_attachment_url" type="file" required onclick="this.value = null" (change)="UploadFile($event)" class="nghide albutton width100">
                        <label id="uploadText" class="albutton">{{filename}} </label>  
                    </div>
                    <mat-error *ngIf="form.controls['time_schedule_attachment_url'].touched && form.controls['time_schedule_attachment_url'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRef.hide()">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<div class="modal" id="pdfPreview1">
    <div class="modal-dialog width70">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{'lang_preview' | language }}</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="pdf-container">
            <iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'lang_close' | language }}</button>
        </div>
      </div>
    </div>
</div>