import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import {
	FormGroup,
	FormArray,
	FormBuilder,
	FormControl,
	Validators,
	ValidatorFn,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import * as mnabreditor from "../../../../../../ckeditor5/build/ckeditor";
import Stepper from "bs-stepper";
import { MatStepper } from "@angular/material/stepper";
import { COMMA, ENTER, X } from "@angular/cdk/keycodes";

import { MatChipInputEvent } from "@angular/material/chips";
export function customValidateArrayGroup(): ValidatorFn {
	return (formArray: FormArray): { [key: string]: any } | null => {
		let valid: boolean = true;
		formArray.controls.forEach((x: FormGroup, index) => {
			let numOfTotals = 0;
			for (let ii = 0; ii < x.value.levels.length; ii++) {
				const ee = x.value.levels[ii];
				numOfTotals += ee.level_value;
			}
			if (x.value.levels_total != numOfTotals) {
				valid = false;
			}

			let levels_total = formArray.controls[index]["controls"]
				.levels_total as FormControl;
			if (valid) {
				return levels_total.setErrors(null);
			} else {
				return levels_total.setErrors({
					notEqual: true,
				});
			}
		});

		return null;
	};
}
declare var $: any;
@Component({
	selector: "app-formpq2-add",
	templateUrl: "./formpq2-add.component.html",
	styleUrls: ["./formpq2-add.component.scss"],
})
export class Formpq2AddComponent implements OnInit {
	private stepper: Stepper | any;
	public Editor = mnabreditor;
	@Input() form_key;
	@Input() form_request_id;
	@Input() project_data;
	@Input() service_data;
	@Output() quotEvent = new EventEmitter();
	lang_key = localStorage.getItem("lang_key") || "en";

	tabs = ["First", "Second", "Third"];
	selected = new FormControl(0);

	addTab(selectAfterAdding: boolean) {
		this.tabs.push("New");

		if (selectAfterAdding) {
			this.selected.setValue(this.tabs.length - 1);
		}
	}

	removeTab(index: number) {
		this.tabs.splice(index, 1);
	}

	form: FormGroup | any;
	languages = [];
	vat_list = [
		{
			label: "test",
			value: "test",
		},
	];
	engineering_type = [
		{
			label: "test",
			value: "test",
		},
	];
	arch_list = [
		{
			label: "test1",
			value: "test1",
		},
		{
			label: "test2",
			value: "test2",
		},
		{
			label: "test3",
			value: "test3",
		},
		{
			label: "test4",
			value: "test4",
		},
	];
	quotation_type_list = [
		{
			label: "standard",
			value: "standard",
		},
		{
			label: "open",
			value: "open",
		},
	];

	soil_sensor_status = [
		{
			label: "Yes",
			value: "1",
		},
		{
			label: "No",
			value: "0",
		},
	];
	occupancies = [
		{
			label: "standard",
			value: "standard",
		},
		{
			label: "open",
			value: "open",
		},
	];
	occupancies_list = [];
	planreq = [];
	plans = [];
	constructor(
		private fb: FormBuilder,
		private spinner: PreloaderService,
		private snackBar: MatSnackBar,
		private ds: DataService
	) {}
	showMsg(msg: string, action: string, className: string) {
		this.snackBar.open(msg, action, {
			duration: 4000,
			panelClass: [className],
		});
	}

	ngOnInit() {
		this.buildForm();
		this.get_languages();
		this.get_required_data("vat");
		// this.get_required_data('qtype');
		this.get_required_data("occupy");
		this.get_required_data("plans", "ALL");
		this.get_required_data("planreq");
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.stepper = new Stepper(document.querySelector("#stepper1"), {
			linear: false,
			animation: true,
		});
		console.log(this.stepper);
	}

	buildForm() {
		this.form = this.fb.group({
			stepOne: this.fb.group({
				projects_profile_id: [null],
				projects_ps_id: [null],
				lang_key: [null, Validators.required],
				quotation_type: ["standard", Validators.required],
				vat_key: [null, Validators.required],
				subject: [null, Validators.required],
				care: [null, Validators.required],

				city: [null, Validators.required],
				total_area_m2: [null, Validators.required],
				duration_days: [null, Validators.required],
				main_occupancies: [null, Validators.required],
				description: [null, Validators.required],
			}),
			stepTwo: this.fb.group({
				/* description: [null, Validators.required], */
				/* project_name: [null, Validators.required], */

				add_terms: [null, Validators.required],
				financial: [null, Validators.required],
				technical_term_Arr: this.fb.array([]),

				add_general_terms: [null, Validators.required],
				technical_general_terms_Arr: this.fb.array([]),

				standard_steps: this.fb.group({
					// Step 1
					project_plan_requirements: this.fb.array([]),

					// Step 2

					project_component_soil_sensor: this.fb.group({
						project_component_soil_sensor_status: [null, Validators.required],
						project_component_soil_sensor_legal_notes: [],
					}),

					// Step 3

					project_analysis_data: this.fb.array([
						this.fb.group({
							project_component_subject_english: [null, Validators.required],
							project_component_subject_arabic: [null, Validators.required],
							project_component_space: [null, Validators.required],
							project_component_building_quantity: [null, Validators.required],
							project_component_level_quantity: [1, Validators.required],

							project_plan_requirements: this.fb.array(
								[
									this.fb.group({
										levels_total: [null, Validators.required],
										trackingId: [this.generateUniqueId()],
										levels: this.fb.array([
											this.fb.group({
												level_value: [null, Validators.required],
												level_occupancy: [null, Validators.required],
												trackingId: [this.generateUniqueId()],
											}),
										]),
									}),
								],
								customValidateArrayGroup()
							),
							project_component_soil_sensor_building_quantity: [
								"",
								this.validationOnSoil == 1 ? [Validators.required] : [],
							],
							project_component_soil_sensor_depth_level_quantity: [
								"",
								this.validationOnSoil == 1 ? [Validators.required] : [],
							],
							project_component_soil_sensor_cost_per_lmeter: [
								"",
								this.validationOnSoil == 1 ? [Validators.required] : [],
							],
							trackingId: [this.generateUniqueId()],
							plan_quantities: this.fb.array([
								this.fb.group({
									plan_quantity_count: ["", [Validators.required]],
									plan_keyword: ["", [Validators.required]],
									trackingId: [this.generateUniqueId()],
								}),
							]),
						}),
					]),

					// Step 4

					surveying_costs: this.fb.group({
						surveying_business_costs_status: [null, Validators.required],
						surveying_business_costs_legal_notes: [null],
						surveying_costs: this.fb.array([
							this.fb.group({
								surveying_title_english: ["", [Validators.required]],
								surveying_title_arabic: ["", [Validators.required]],
								surveying_cost_value: ["", [Validators.required]],
								surveying_cost_total_value: ["", [Validators.required]],
								trackingId: [this.generateUniqueId()],
							}),
						]),
					}),



					
					// Step 5

					printing_costs: this.fb.group({
						printing_costs_status: [null, Validators.required],
						printing_costs_legal_notes: [null],
						printing_costs: this.fb.array([
							this.fb.group({
								printing_title_english: ["", [Validators.required]],
								printing_title_arabic: ["", [Validators.required]],
								printing_costs_no_of_copies: ["", [Validators.required]],
								printing_costs_no_of_panels: ["", [Validators.required]],
								printing_costs_ratio_cost: ["", [Validators.required]],
								trackingId: [this.generateUniqueId()],
							}),
						]),
					}),

					// Step 6

					engineering_costs: this.fb.array([
						this.fb.group({
							engineering_cost_title_english: [null, Validators.required],
							engineering_cost_title_arabic: [null, Validators.required],
							plan_keyword: [null, Validators.required],
							quantity: [null, Validators.required],
							cost_per_day: [null, Validators.required],
						}),
					]),

					// Step 7

					government_services: this.fb.group({
						government_services_status: [null, Validators.required],

						government_services: this.fb.array([
							this.fb.group({
								government_services_subject_english: [
									"",
									[Validators.required],
								],
								government_services_subject_arabic: ["", [Validators.required]],
								government_services_quantity: ["", [Validators.required]],
								government_services_ratio_cost: ["", [Validators.required]],
								trackingId: [this.generateUniqueId()],
							}),
						]),
					}),

					pricing_costs: this.fb.group({
						subject: [null, Validators.required],
					}),
					//step 8
					other_costs: this.fb.group({
						other_costs_status: [null, Validators.required],
						other_costs: this.fb.array([
							this.fb.group({
								other_costs_subject_english: [
									"",
									[Validators.required],
								],
								other_costs_subject_arabic: ["", [Validators.required]],
								other_costs_quantity: ["", [Validators.required]],
								other_costs_ratio_cost: ["", [Validators.required]],
								trackingId: [this.generateUniqueId()],
							}),
						]),
					}),
					//step 9
					main_analysis_costs: this.fb.group({
						main_analysis_total: [null, Validators.required],
						total_analysis: [null, Validators.required]
					}),

					//step 10
					quotation_price: this.fb.group({
						subject: [null, Validators.required]
					}),
				}),
			}),
		});

		console.log(this.form);
	}

	public isNumber(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	getMainAnalysisCosts() {

	}

	changePlanReq(evt, i) {
		let project_plan_requirements = this.getstandard_steps.controls[
			"project_analysis_data"
		]["controls"][i]["controls"]?.project_plan_requirements as FormArray;
		project_plan_requirements.clear();

		for (let index = 0; index < evt.target.value; index++) {
			project_plan_requirements.push(
				this.fb.group({
					levels_total: [null, Validators.required],
					trackingId: [this.generateUniqueId()],
					levels: this.fb.array([
						this.fb.group({
							level_value: [null, Validators.required],
							level_occupancy: [null, Validators.required],
							trackingId: [this.generateUniqueId()],
						}),
					]),
				})
			);
		}

		console.log(project_plan_requirements);
	}

	calcTotalForServaying(i) {
		var surveying_costs = this.getSmallSteps.surveying_costs["controls"]
			.surveying_costs["controls"][i] as FormGroup;
		let total_area_m2 = this.form.controls.stepOne.controls.total_area_m2.value;
		surveying_costs.patchValue({
			surveying_cost_total_value:
				+surveying_costs.value.surveying_cost_value * +total_area_m2,
		});
	}
	customNumValidation(evt) {
		evt = evt ? evt : window.event;
		var charCode = evt.which ? evt.which : evt.keyCode;
		if (charCode == 44) {
			return true;
		}
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		if (evt.target.value == 0) {
			return false;
		}
		return true;
	}

	changeQout(e) {
		console.log(e);
	}

	validationOnSoil = 0;

	changeSoilSensor(e) {
		console.log(e);
		this.validationOnSoil = e.value;

		let project_analysis_data_arr = this.getSmallSteps.project_analysis_data;

		for (let index = 0; index < project_analysis_data_arr.length; index++) {
			/* 	const element = project_analysis_data_arr[index]; */
			let project_component_level_quantity = project_analysis_data_arr[
				"controls"
			][index]["controls"].project_component_level_quantity as FormControl;
			let project_component_soil_sensor_depth_level_quantity = project_analysis_data_arr[
				"controls"
			][index]["controls"]
				.project_component_soil_sensor_depth_level_quantity as FormControl;
			let project_component_soil_sensor_building_quantity = project_analysis_data_arr[
				"controls"
			][index]["controls"]
				.project_component_soil_sensor_building_quantity as FormControl;

			if (e.value == 1) {
				project_component_soil_sensor_building_quantity.setValidators(
					Validators.required
				);

				project_component_soil_sensor_depth_level_quantity.setValidators(
					Validators.required
				);

				project_component_level_quantity.setValidators(Validators.required);
				project_component_soil_sensor_building_quantity.updateValueAndValidity();
				project_component_soil_sensor_depth_level_quantity.updateValueAndValidity();
				project_component_level_quantity.updateValueAndValidity();
			} else {
				project_component_soil_sensor_building_quantity.clearValidators();

				project_component_soil_sensor_depth_level_quantity.clearValidators();

				project_component_level_quantity.clearValidators();

				project_component_soil_sensor_building_quantity.updateValueAndValidity();
				project_component_soil_sensor_depth_level_quantity.updateValueAndValidity();
				project_component_level_quantity.updateValueAndValidity();
			}
		}

		let project_component_soil_sensor = this.getSmallSteps
			.project_component_soil_sensor["controls"]
			.project_component_soil_sensor_legal_notes as FormControl;
		project_component_soil_sensor.clearValidators();
		project_component_soil_sensor.updateValueAndValidity();
	}

	printing_business_costs(e) {
		if (e.value == 0) {
			this.getSmallSteps.printing_costs[
				"controls"
			].printing_costs.clearValidators();
			this.getSmallSteps.printing_costs[
				"controls"
			].printing_costs.updateValueAndValidity();
			this.getSmallSteps.printing_costs["controls"].printing_costs.disable();
			this.getSmallSteps.printing_costs[
				"controls"
			].printing_costs_legal_notes.setValidators(Validators.required);
			this.getSmallSteps.printing_costs[
				"controls"
			].printing_costs_legal_notes.updateValueAndValidity();
		} else {
			this.getSmallSteps.printing_costs[
				"controls"
			].printing_costs_legal_notes.clearValidators();
			this.getSmallSteps.printing_costs[
				"controls"
			].printing_costs_legal_notes.updateValueAndValidity();
			this.getSmallSteps.printing_costs["controls"].printing_costs.enable();
			this.getSmallSteps.printing_costs[
				"controls"
			].printing_costs.setValidators(Validators.required);
			this.getSmallSteps.printing_costs[
				"controls"
			].printing_costs.updateValueAndValidity();
		}
	}

	surveying_business_costs(e) {
		console.log(e);
		/* this.validationOnSoil  =e.value */
		if (e.value == 0) {
			this.getSmallSteps.surveying_costs[
				"controls"
			].surveying_costs.clearValidators();
			this.getSmallSteps.surveying_costs[
				"controls"
			].surveying_costs.updateValueAndValidity();
			this.getSmallSteps.surveying_costs["controls"].surveying_costs.disable();
			this.getSmallSteps.surveying_costs[
				"controls"
			].surveying_business_costs_legal_notes.setValidators(Validators.required);
			this.getSmallSteps.surveying_costs[
				"controls"
			].surveying_business_costs_legal_notes.updateValueAndValidity();
		} else {
			this.getSmallSteps.surveying_costs[
				"controls"
			].surveying_business_costs_legal_notes.clearValidators();
			this.getSmallSteps.surveying_costs[
				"controls"
			].surveying_business_costs_legal_notes.updateValueAndValidity();
			this.getSmallSteps.surveying_costs["controls"].surveying_costs.enable();
			this.getSmallSteps.surveying_costs[
				"controls"
			].surveying_costs.setValidators(Validators.required);
			this.getSmallSteps.surveying_costs[
				"controls"
			].surveying_costs.updateValueAndValidity();
		}
	}
	OPEN_CLOSE_GOVENTMENT_SERVICES(e) {
		if (e.value == 0) {
			this.getSmallSteps.government_services[
				"controls"
			].government_services.clearValidators();
			this.getSmallSteps.government_services[
				"controls"
			].government_services.updateValueAndValidity();
			this.getSmallSteps.government_services[
				"controls"
			].government_services.disable();
		} else {
			this.getSmallSteps.government_services[
				"controls"
			].government_services.enable();
			this.getSmallSteps.government_services[
				"controls"
			].government_services.setValidators(Validators.required);
			this.getSmallSteps.government_services[
				"controls"
			].government_services.updateValueAndValidity();
		}
	}
	OPEN_CLOSE_OTHER_COSTS(e) {
		if (e.value == 0) {
			this.getSmallSteps.other_costs[
				"controls"
			].other_costs.clearValidators();
			this.getSmallSteps.other_costs[
				"controls"
			].other_costs.updateValueAndValidity();
			this.getSmallSteps.other_costs[
				"controls"
			].other_costs.disable();
		} else {
			this.getSmallSteps.other_costs[
				"controls"
			].other_costs.enable();
			this.getSmallSteps.other_costs[
				"controls"
			].other_costs.setValidators(Validators.required);
			this.getSmallSteps.other_costs[
				"controls"
			].other_costs.updateValueAndValidity();
		}
	}
	MATNext(stepper: MatStepper, stepNum?: string) {
		if (stepNum == "first" && this.form.get("stepOne").status === "VALID") {
			stepper.next();
		} else if (
			stepNum == "second" &&
			this.form.get("stepTwo").status === "VALID"
		) {
			stepper.next();
		} else {
			this.showMsg("lang_fix_invalid_fields", "ok", "error-snackbar");
		}
	}
	MATPrev(stepper: MatStepper) {
		stepper.previous();
	}
	currentActiveStep = 1;
	setActive(num) {
		this.currentActiveStep = num;
	}
	next() {
		if (this.currentActiveStep == 1) {
			if (this.getSmallSteps.project_plan_requirements.status == "INVALID") {
				this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
				return 0;
			}
		} else if (this.currentActiveStep == 2) {
			if (
				this.getSmallSteps.project_component_soil_sensor.status == "INVALID"
			) {
				this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
				return 0;
			}
		} else if (this.currentActiveStep == 3) {
			if (this.getSmallSteps.project_analysis_data.status == "INVALID") {
				this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
				return 0;
			}
		} else if (this.currentActiveStep == 4) {
			if (this.getSmallSteps.surveying_costs.status == "INVALID") {
				this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
				return 0;
			}
		} else if (this.currentActiveStep == 5) {
			if (this.getSmallSteps.printing_costs.status == "INVALID") {
				this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
				return 0;
			}
		} else if (this.currentActiveStep == 6) {
			if (this.getSmallSteps.engineering_costs.status == "INVALID") {
				this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
				return 0;
			}
		} else if (this.currentActiveStep == 7) {
			if (this.getSmallSteps.government_services.status == "INVALID") {
				this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
				return 0;
			}
		} else if (this.currentActiveStep == 8) {
			if (this.getSmallSteps.other_costs.status == "INVALID") {
				this.showMsg("lang_fix_invalid_field", "ok", "error-snackbar");
				return 0;
			}
		}
		this.stepper.next();
		if (this.currentActiveStep < 9) {
			this.currentActiveStep++;
		}
	}

	back() {
		this.stepper.previous();
		if (this.currentActiveStep > 1) {
			this.currentActiveStep--;
		}
	}
	addTerms() {
		if (!this.form.controls.stepTwo.value.add_terms) {
			this.showMsg("lang_enter_a_value_first", "ok", "error-snackbar");
			return 0;
		}

		const creds = this.form.controls.stepTwo["controls"]
			.technical_term_Arr as FormArray;
		console.log(creds);

		creds.push(
			this.fb.group({
				terms: [this.form.controls.stepTwo.value.add_terms],
				trackingId: [this.generateUniqueId()],
			})
		);
		this.form.controls.stepTwo.get("add_terms").setValidators([]);
		this.form.controls.stepTwo.get("add_terms").updateValueAndValidity();
	}

	addGeneralTerms() {
		if (!this.form.controls.stepTwo.value.add_general_terms) {
			this.showMsg("lang_enter_a_value_first", "ok", "error-snackbar");
			return 0;
		}

		const creds = this.form.controls.stepTwo["controls"]
			.technical_general_terms_Arr as FormArray;

		creds.push(
			this.fb.group({
				terms: [this.form.controls.stepTwo.value.add_general_terms],
				trackingId: [this.generateUniqueId()],
			})
		);
		this.form.controls.stepTwo.get("add_general_terms").setValidators([]);
		this.form.controls.stepTwo
			.get("add_general_terms")
			.updateValueAndValidity();
	}
	//get languages
	get_languages() {
		this.spinner.show();
		this.languages = [];
		this.ds.getActionByUrl([], "tc/tlanguages").subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.languages = res.records;
				}
			},
			(error) => {
				this.spinner.hide();
			}
		);
	}

	get getSmallSteps() {
		return this.form.controls.stepTwo.controls.standard_steps.controls;
	}

	get getstandard_steps() {
		return this.form.controls.stepTwo.controls.standard_steps;
	}
	trackByFnCustom(index: number, item: any) {
		return item.trackingId;
	}
	generateUniqueId() {
		return (
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15)
		);
	}

	deleteTerm(index) {
		const creds = this.form.controls.stepTwo["controls"]
			.technical_term_Arr as FormArray;
		if (this.form.controls.stepTwo["controls"].technical_term_Arr.length == 1) {
			this.showMsg("lang_you_must_keep_one_field", "ok", "error-snackbar");
			return 0;
		}
		creds.removeAt(index);
	}

	deleteGeneralTerm(index) {
		const creds = this.form.controls.stepTwo["controls"]
			.technical_general_terms_Arr as FormArray;
		if (
			this.form.controls.stepTwo["controls"].technical_general_terms_Arr
				.length == 1
		) {
			this.showMsg("lang_you_must_keep_one_field", "ok", "error-snackbar");
			return 0;
		}
		creds.removeAt(index);
	}
	deletePaln(index: number) {
		if (
			index <
			this.getSmallSteps.project_plan_requirements?.value.length -
				this.plans.length
		) {
			var project_plan_requirements = this.getSmallSteps
				.project_plan_requirements as FormArray;

			project_plan_requirements.removeAt(index);
		}
	}

	deleteEngineeringCosts(index: number) {
		if (index > 0) {
			var project_plan_requirements = this.getSmallSteps
				.engineering_costs as FormArray;

			project_plan_requirements.removeAt(index);
		}
	}

	deleteServayingCost(index: number) {
		if (index < 0) {
			var surveying_costs = this.getSmallSteps.surveying_costs["controls"]
				.surveying_costs as FormArray;
			surveying_costs.removeAt(index);
		}
	}

	deleteGovCosts(index: number) {
		if (index > 0) {
			var government_services = this.getSmallSteps.government_services[
				"controls"
			].government_services as FormArray;
			government_services.removeAt(index);
		}
	}

	deleteOtherCosts(index: number) {
		if (index > 0) {
			var other_costs = this.getSmallSteps.other_costs[
				"controls"
			].other_costs as FormArray;
			other_costs.removeAt(index);
		}
	}

	deletePrintingCost(index: number) {
		if (index > 0) {
			var printing_costs = this.getSmallSteps.printing_costs["controls"]
				.printing_costs as FormArray;
			printing_costs.removeAt(index);
		}
	}

	deleteProjectAnalysis(index: number) {
		var project_analysis_data = this.getSmallSteps
			.project_analysis_data as FormArray;

		project_analysis_data.removeAt(index);
	}

	//get required data
	get_required_data(request_type?, return_type?) {
		let param = new FormData();
		param.append("lang_key", this.lang_key);
		param.append("request_type", request_type);
		if (return_type) {
			param.append("return_type", return_type);
		}

		this.spinner.show();
		this.ds
			.getActionByUrl(this.ds.formData2string(param), "form/FORM_PQ2/requests")
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status) {
						if (request_type == "qtype") {
							this.quotation_type_list = res.data;
						}
						if (request_type == "vat") {
							this.vat_list = res.data;
						}
						if (request_type == "occupy") {
							this.occupancies_list = res.data;
						}
						if (request_type == "plans") {
							this.plans = res.data;

							var project_plan_requirements = this.getSmallSteps
								.project_plan_requirements as FormArray;
							for (let index = 0; index < this.plans.length; index++) {
								const element = this.plans[index];
								project_plan_requirements.push(
									this.fb.group({
										plan_title_english: [
											{
												value: element.form_pq2_request_type_title_english,
												disabled: true,
											},
											Validators.required,
										],
										plan_title_arabic: [
											{
												value: element.form_pq2_request_type_title_arabic,
												disabled: true,
											},
											Validators.required,
										],
										plan_keyword: [
											{
												value: element.form_pq2_request_type_keyword,
												disabled: true,
											},
											Validators.required,
										],
										plan_technical_ids: [null, Validators.required],
										plan_hour_value: [
											{
												value: element.form_pq2_request_type_value,
												disabled: true,
											},
											Validators.required,
										],
										plan_total_hours: ["", Validators.required],
										trackingId: [this.generateUniqueId()],
									})
								);
							}
						}

						if (request_type == "planreq") {
							this.planreq = res.data;
						}
						// if(request_type == "qprice") {
						//   this.qprice = res.records;
						// }
						// if(request_type == "stages") {
						//   this.stages = res.records;
						// }
					}
				},
				(error) => {
					this.spinner.hide();
				}
			);
	}
	addProjectAnalysis() {
		var project_analysis_data = this.getSmallSteps
			.project_analysis_data as FormArray;
		project_analysis_data.insert(
			0,
			this.fb.group({
				project_component_subject_english: [null, Validators.required],
				project_component_subject_arabic: [null, Validators.required],
				project_component_space: [null, Validators.required],
				project_component_building_quantity: [null, Validators.required],
				project_component_level_quantity: [1, Validators.required],

				project_plan_requirements: this.fb.array([
					this.fb.group({
						levels_total: [null, Validators.required],
						levels: this.fb.array([
							this.fb.group({
								level_value: [null, Validators.required],
								level_occupancy: [null, Validators.required],
							}),
						]),
					}),
				]),
				project_component_soil_sensor_building_quantity: [
					"",
					this.validationOnSoil == 1 ? [Validators.required] : [],
				],
				project_component_soil_sensor_depth_level_quantity: [
					"",
					this.validationOnSoil == 1 ? [Validators.required] : [],
				],
				project_component_soil_sensor_cost_per_lmeter: [
					"",
					this.validationOnSoil == 1 ? [Validators.required] : [],
				],

				plan_quantities: this.fb.array([
					this.fb.group({
						plan_quantity_count: ["", [Validators.required]],
						plan_keyword: ["", [Validators.required]],
					}),
				]),
			})
		);
	}

	deleteLevel(i, index) {
		var project_analysis_data_levels = this.getSmallSteps.project_analysis_data[
			"controls"
		][i]["controls"]["project_plan_requirements"] as FormArray;
		if (project_analysis_data_levels.length == 1) {
			this.showMsg("lang_you_must_keep_one_field", "ok", "error-snackbar");
			return 0;
		}
		project_analysis_data_levels.removeAt(index);
	}

	delete_plan_quantities(i, index) {
		var plan_quantities = this.getSmallSteps.project_analysis_data["controls"][
			i
		]["controls"]["plan_quantities"] as FormArray;
		if (plan_quantities.length == 1) {
			this.showMsg("lang_you_must_keep_one_field", "ok", "error-snackbar");
			return 0;
		}
		plan_quantities.removeAt(index);
	}

	Invalid = false;
	customValidateArrayGroup(): ValidatorFn {
		return (formArray: FormArray): { [key: string]: any } | null => {
			let valid: boolean = false;

			formArray.controls.forEach((x: FormGroup) => {
				let numOfTotals = 0;
				for (let ii = 0; ii < x.value.levels.length; ii++) {
					const ee = x.value.levels[ii];
					numOfTotals += ee.level_value;
				}
				if (x.value.levels_total == numOfTotals) {
					valid = true;
				}
			});
			return valid ? null : { error: "Total must be quall" };
		};
	}
	levelsCheck() {
		let project_analysis_data = this.form.value.stepTwo.standard_steps
			.project_analysis_data;
		this.Invalid = false;

		for (let index = 0; index < project_analysis_data.length; index++) {
			const element = project_analysis_data[index];
			for (let i = 0; i < element.project_plan_requirements.length; i++) {
				const e = element.project_plan_requirements[i];
				let numOfTotals = 0;
				for (let ii = 0; ii < e.levels.length; ii++) {
					const ee = e.levels[ii];
					numOfTotals += ee.level_value;
				}
				if (numOfTotals != e.levels_total) {
					this.showMsg(
						"lang_total_levels_must_be_equal_to_nuber_of_subs_value",
						"ok",
						"error-snackbar"
					);
					this.Invalid = true;
					break;
				}
			}
		}
		if (!this.Invalid) {
			this.setActive(4);
		}
	}

	deleteSUbLevel(i, ii, index) {
		var project_analysis_data_levels = this.getSmallSteps.project_analysis_data[
			"controls"
		][i]["controls"]["project_plan_requirements"]["controls"][ii]["controls"][
			"levels"
		] as FormArray;
		if (project_analysis_data_levels.length == 1) {
			this.showMsg("lang_you_must_keep_one_field", "ok", "error-snackbar");
			return 0;
		}
		project_analysis_data_levels.removeAt(index);
	}

	addLevels(i, ii) {
		var project_analysis_data_levels = this.getSmallSteps.project_analysis_data[
			"controls"
		][i]["controls"]["project_plan_requirements"]["controls"][ii]["controls"][
			"levels"
		] as FormArray;
		project_analysis_data_levels.insert(
			0,
			this.fb.group({
				level_value: [null, Validators.required],
				level_occupancy: [null, Validators.required],
			})
		);
	}

	add_plan_quantities(i) {
		var plan_quantities = this.getSmallSteps.project_analysis_data["controls"][
			i
		]["controls"]["plan_quantities"] as FormArray;
		plan_quantities.insert(
			0,
			this.fb.group({
				plan_keyword: [null, Validators.required],
				plan_quantity_count: [null, Validators.required],
			})
		);
	}

	addPlan() {
		var project_plan_requirements = this.getSmallSteps
			.project_plan_requirements as FormArray;
		project_plan_requirements.insert(
			0,
			this.fb.group({
				plan_title_english: [
					{ value: "", disabled: false },
					Validators.required,
				],
				plan_title_arabic: [
					{ value: "", disabled: false },
					Validators.required,
				],
				plan_keyword: [{ value: "", disabled: false }, Validators.required],
				plan_technical_ids: [null, Validators.required],
				plan_hour_value: [{ value: "", disabled: false }, Validators.required],
				plan_total_hours: ["", Validators.required],
				trackingId: [this.generateUniqueId()],
			})
		);
	}

	addEngineeringCosts() {
		var engineering_costs = this.getSmallSteps.engineering_costs as FormArray;
		engineering_costs.insert(
			0,
			this.fb.group({
				engineering_cost_title_english: [null, Validators.required],
				engineering_cost_title_arabic: [null, Validators.required],
				plan_keyword: [null, Validators.required],
				quantity: [null, Validators.required],
				cost_per_day: [null, Validators.required],
				trackingId: [this.generateUniqueId()],
			})
		);
	}

	addSurveyingCosts() {
		var surveying_costs = this.getSmallSteps.surveying_costs["controls"]
			.surveying_costs as FormArray;
		surveying_costs.insert(
			0,
			this.fb.group({
				surveying_title_english: ["", [Validators.required]],
				surveying_title_arabic: ["", [Validators.required]],
				surveying_cost_value: ["", [Validators.required]],
				surveying_cost_total_value: ["", [Validators.required]],
				trackingId: [this.generateUniqueId()],
			})
		);
	}

	addPrintingCosts() {
		var printing_costs = this.getSmallSteps.printing_costs["controls"]
			.printing_costs as FormArray;
		printing_costs.insert(
			0,
			this.fb.group({
				printing_title_english: ["", [Validators.required]],
				printing_title_arabic: ["", [Validators.required]],
				printing_costs_no_of_copies: ["", [Validators.required]],
				printing_costs_no_of_panels: ["", [Validators.required]],
				printing_costs_ratio_cost: ["", [Validators.required]],
				trackingId: [this.generateUniqueId()],
			})
		);
	}
	addGovernmentServices() {
		var government_services = this.getSmallSteps.government_services["controls"]
			.government_services as FormArray;
			government_services.insert(
			0,
			this.fb.group({
				government_services_subject_english: [
					"",
					[Validators.required],
				],
				government_services_subject_arabic: ["", [Validators.required]],
				government_services_quantity: ["", [Validators.required]],
				government_services_ratio_cost: ["", [Validators.required]],
				trackingId: [this.generateUniqueId()],
			})
		);
	}
	addOtherCosts() {
		var other_costs = this.getSmallSteps.other_costs["controls"]
			.other_costs as FormArray;
			other_costs.insert(
			0,
			this.fb.group({
				other_costs_subject_english: [
					"",
					[Validators.required],
				],
				other_costs_subject_arabic: ["", [Validators.required]],
				other_costs_quantity: ["", [Validators.required]],
				other_costs_ratio_cost: ["", [Validators.required]],
				trackingId: [this.generateUniqueId()],
			})
		);
	}
	makekeyword(event, index) {
		let value: string = event.target.value.toLowerCase();
		value = value.replace(/\s+/g, "_").toLowerCase();
		var project_plan_requirements = this.getSmallSteps
			.project_plan_requirements as FormArray;
		project_plan_requirements["controls"][index].patchValue({
			plan_keyword: value,
		});

		console.log(project_plan_requirements);
	}

	makekeywordForEngineeringCosts(event, index) {
		let value: string = event.target.value.toLowerCase();
		value = value.replace(/\s+/g, "_").toLowerCase();
		var engineering_costs = this.getSmallSteps.engineering_costs as FormArray;
		engineering_costs["controls"][index].patchValue({
			plan_keyword: value,
		});

		console.log(engineering_costs);
	}

	addOnBlur = true;
	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	fruits: any[] = [{ name: "Lemon" }, { name: "Lime" }, { name: "Apple" }];

	add(event: MatChipInputEvent): void {
		const value = (event.value || "").trim();

		// Add our fruit
		if (value) {
			this.fruits.push({ name: value });
		}

		// Clear the input value
		/* event.chipInput!.clear(); */
	}

	remove(el: any): void {
		/* const index = this.getSmallSteps.P_Q_P_L.get('arch_list').value.indexOf(el);
  
	  if (index >= 0) {
 

		
	  } */
		this.getSmallSteps.P_Q_P_L.patchValue({
			arch_list: this.getSmallSteps.P_Q_P_L.get("arch_list").value.filter(
				(d) => d != el
			),
		});
	}
}
