<div class="card tender_drawings">
    <div class="card-header">{{'lang_tender_drawing' | language }} </div>
    <div class="card-body pad0">
        <div class="mbrl15">
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-12 mb-10">
                    <ng-select 
                        [items]="Dtypes" 
                        [multiple]="true" 
                        bindLabel="label" 
                        bindValue="value" 
                        clearAllText="Clear"
                        appendTo="body" 
                        [(ngModel)]="drawingItems"
                        placeholder="{{'lang_tender_drawing_types' | language}}"
                        (change)="spinner.show();search_supervision_tender_drawing();">
                    </ng-select>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-12 mb-10">
                    <select class="form-control almnabrformcontrol" [(ngModel)]="drawingStatus" autocomplete="off" (change)="spinner.show();search_supervision_tender_drawing();">
                        <option value=""> {{'lang_all' | language }}</option>
                        <option value="1"> {{'lang_verified_tender_drawings' | language }}</option>
                        <option value="0"> {{'lang_unverified_tender_drawings' | language }}</option>
                    </select>
                </div>
                <div class="col-md-6 col-sm-4 col-xs-12 mb-10">
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control almnabrformcontrol" [(ngModel)]="searchKey" (keyup)="search_supervision_tender_drawing();" placeholder="{{'lang_search' | language }}" />
                        <span class="input-group-btn" *ngIf="able2add">
                            <button id="btnSearch" type="button" matTooltip="{{'lang_add' | language }}" class="btnSearch h34 btn btn-md btn-gray fa fa-plus mnabricon" (click)="emptyform();openModal(AddTenderDrawingForm);"></button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive dropdownyes customResponsive">
                        <table border="1" class="table CustomTable">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>
                                        <div class="checkbox"  matTooltip="{{'lang_check_all' | language}}">
                                            <input type="checkbox" class="allItemsDraw checkbox" id="allItemsDraw" (click)="checkAllItemsDraw($event)"/>
                                            <label for="checkbox"></label>
                                        </div>
                                    </td>
                                    <td>{{'lang_drawing_name' | language }}</td>
                                    <td>{{'lang_drawing_type' | language }}</td>
                                    <td>{{'lang_drawing_number' | language }}</td>
                                    <td>{{'lang_writer' | language }}</td>
                                    <td>{{'lang_ondate' | language }}</td>
                                    <td>{{'lang_status' | language }}</td>
                                    <td>{{'lang_action' | language }}</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="DListData.length != '0' " class="AppendList">
                                <tr *ngFor="let list of DListData.records; let i = index;let odd=odd; let even=even" [ngClass]="{ odd: odd, even: even }" class="makeback removerow{{list.tender_drawings_id}}">
                                    <td>{{i+1}}</td>
                                    <td>
                                        <div class="checkbox" matTooltip="{{'lang_check' | language}}">
                                            <input type="checkbox"  class="eachItemDraw checkbox" value="{{list.tender_drawings_id}}" (click)="checkEachItemDraw($event)"/>
                                            <label for="checkbox"></label>
                                        </div>
                                    </td>
                                    <td>{{list.tender_drawings_name}}</td>
                                    <td>{{list.tender_drawing_type}}</td>
                                    <td>{{list.tender_drawings_dwgnumber}}</td>
                                    <td>{{list.writer}}</td>
                                    <td>{{list.tender_drawings_created_datetime}}</td>
                                    <td *ngIf="list.tender_drawings_status == '0'">
                                        <i class="fa fa-icon fa-circle yellowcolor" matTooltip="{{'lang_pending' | language }}" aria-hidden="true"></i>
                                    </td>
                                    <td *ngIf="list.tender_drawings_status == '1'">
                                        <i class="fa fa-icon fa-circle green" matTooltip="{{'lang_approved' | language }}" aria-hidden="true"></i>
                                    </td>
                                    <td class="dropdown">
                                       <mat-icon (click)="ds.previewData(list.tender_drawings_attachment_url,'#pdfPreviewDraw')" class="mnabricon" matTooltip="{{'lang_file' | language}}">attachment</mat-icon>
                                       <mat-icon (click)="viewdata(list);openModal(Dviewform);" class="mnabricon" matTooltip="{{'lang_view' | language}}">remove_red_eye</mat-icon>
                                       <mat-icon *ngIf="able2edit" (click)="editdata(list);openModal(AddTenderDrawingForm);" class="mnabricon" matTooltip="{{'lang_edit' | language}}">edit</mat-icon>
                                       <mat-icon *ngIf="able2edit && list.tender_drawings_status == '0' " (click)="approve_tender_drawing(list)" class="mnabricon yellowcolor" matTooltip="{{'lang_approve' | language}}">check_circle</mat-icon>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="DListData.length == '0' " class="AppendList">
                                <tr class="odd">
                                    <td colspan="9" align="center">{{Dnodata}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="DListData.length != '0' " class="card-footer padb0">
            <div class="row">
                <div *ngIf="able2delete" class="deleteIconDiv none col-lg-1 col-sm-6 col-6 mb-10">
                    <i class="fa fa-trash-o faicon red deleteIcon" (click)="deleteBulkDataDraw()" aria-hidden="true" matTooltip="{{'lang_delete' | language }}"></i>
                </div>
                <div class="col-lg-2 col-sm-6 col-6  mb-10">
                    {{'lang_total' | language }} : {{DListData.page.total_records}}
                </div>
                <div class="col-lg-5 col-sm-6 mb-10">
                    <mat-radio-group>
                        <mat-radio-button value="10" checked (click)="load_tender_drawings_pagesize(10)"> 10 </mat-radio-button>
                        <mat-radio-button value="20" (click)="load_tender_drawings_pagesize(20)"> 20 </mat-radio-button>
                        <mat-radio-button value="50" (click)="load_tender_drawings_pagesize(50)"> 50 </mat-radio-button>
                        <mat-radio-button value="100" (click)="load_tender_drawings_pagesize(100)"> 100 </mat-radio-button>
                        <mat-radio-button value="500" (click)="load_tender_drawings_pagesize(500)"> 500 </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="col-lg-4 col-sm-6">
                    {{'lang_goto' | language }} :
                    <select class="customselect" (change)="load_tender_drawings_page($event.target.value)">
                        <option *ngFor="let ks of ds.getrange(DListData.page.total_pages)" value="{{ks}}">{{ks}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #AddTenderDrawingForm>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">{{'lang_tender_drawing' | language }}</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()">&times;</button>
        </div>
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="modal-body">            
                <div class="form-group" *ngIf="!form.get('tender_drawings_id').value">
                    <label for="tender_drawings_type_id">{{'lang_tender_drawing_types' | language }}:<span class="red">*</span></label>
                    <angular2-multiselect class="needremovesearch" formControlName="tender_drawings_type_id" [data]="Dtypes" [settings]="single"></angular2-multiselect>
                    <mat-error *ngIf="form.controls['tender_drawings_type_id'].touched && form.controls['tender_drawings_type_id'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
                <div class="form-group">
                    <label for="tender_drawings_name">{{'lang_drawing_name' | language }}:<span class="red">*</span></label>
                    <input type="text" class="form-control almnabrformcontrol" formControlName="tender_drawings_name">                        
                    <mat-error *ngIf="form.controls['tender_drawings_name'].touched && form.controls['tender_drawings_name'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
                <div class="form-group">
                    <label for="tender_drawings_dwgnumber">{{'lang_drawing_number' | language }}:<span class="red">*</span></label>
                    <input type="text" class="form-control almnabrformcontrol" formControlName="tender_drawings_dwgnumber">                        
                    <mat-error *ngIf="form.controls['tender_drawings_dwgnumber'].touched && form.controls['tender_drawings_dwgnumber'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>
                <div class="form-group">
                    <label for="tender_drawings_attachment_url">{{'lang_attachments' | language }}:
                        <span class="red" *ngIf="!form.get('tender_drawings_id').value"> * </span>
                        <span *ngIf="isFile" for="tender_drawings_attachment_url"> <i aria-hidden="true" (click)="removeFile()" class="fa faicon fa-close red"></i> </span>
                    </label>
                    <div class="IDDIV">
                        <input style="display: block;" name="tender_drawings_attachment_url" type="file" required onclick="this.value = null" (change)="UploadFile($event)" class="nghide albutton width100">
                        <label id="uploadText" class="albutton">{{filename}} </label>  
                    </div>
                    <mat-error *ngIf="form.controls['tender_drawings_attachment_url'].touched && form.controls['tender_drawings_attachment_url'].errors?.required">
                        {{'lang_field_required' | language }}
                    </mat-error>
                </div>            
            </div>
            <div class="card-footer pad15">
                <div class="row">
                    <div class="col-12 mb-10">
                        <app-alert id="default-alert"></app-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="submit" class="albutton">{{'lang_submit' | language }}</button>
                    </div>
                    <div class="col-md-6 col-xs-12 mb-10">
                        <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRef.hide()">{{'lang_close' | language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #Dviewform>
    <div class="modal-content">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{'lang_view' | language }}</h4>
                <button type="button" class="close" data-dismiss="modal" (click)="modalRef.hide();">&times;</button>
            </div>
            <div class="modal-body" *ngIf="Dviewdata.length !='0' ">
                <div class="form-group">
                    <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_drawing_name' | language }} :</label>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <b class="breakall">{{Dviewdata.tender_drawings_name}}</b>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_drawing_type' | language }} :</label>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <b class="breakall">{{Dviewdata.tender_drawing_type}}</b>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_drawing_number' | language }} :</label>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <b class="breakall">{{Dviewdata.tender_drawings_dwgnumber}}</b>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_status' | language }} :</label>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <b *ngIf="Dviewdata.tender_drawings_status == '0'" class="breakall yellowcolor"> {{'lang_pending' | language }}</b>
                            <b *ngIf="Dviewdata.tender_drawings_status == '1'" class="breakall" style="color: green !important;"> {{'lang_approved' | language }}</b>
                        </div>
                    </div>
                </div>                
                <div class="form-group">
                    <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_writer' | language }} :</label>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <b class="breakall">{{Dviewdata.writer}}</b>
                        </div>
                    </div>
                </div>                
                <div class="form-group">
                    <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_ondate' | language }} :</label>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <b class="breakall">{{Dviewdata.tender_drawings_created_datetime}}</b>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-12">{{'lang_onupdate' | language }} :</label>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <b class="breakall" *ngIf="Dviewdata.tender_drawings_updated_datetime">{{Dviewdata.tender_drawings_updated_datetime}}</b>
                            <b class="breakall" *ngIf="!Dviewdata.tender_drawings_updated_datetime">----</b>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary secondarybutton" data-dismiss="modal" (click)="modalRef.hide();">{{'lang_close' | language }}</button>
            </div>
        </div>
    </div>
</ng-template>
<div class="modal" id="pdfPreviewDraw">
    <div class="modal-dialog width70">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{'lang_preview' | language }}</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="pdf-container">
            <iframe id="mnabrPdf" src="" width="100%" height="700"></iframe>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'lang_close' | language }}</button>
        </div>
      </div>
    </div>
</div>