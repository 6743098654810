import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { platformRequirements } from 'app/routes/transactions/FORM_PS_TR1/formdata';
import { DwsrService } from 'app/routes/transactions/FORM_PS_TR1/services/dwsr.service';
import { ByPhasesComponent } from 'app/routes/transactions/FORM_PS_TR1/steps/t-step1/by-phases/by-phases.component';
import { GeneralNumbersComponent } from 'app/routes/transactions/FORM_PS_TR1/steps/t-step1/general-numbers/general-numbers.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
 
@Component({
  selector: 'app-overtime-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class OvertimeStepsComponent implements OnInit {
 @Input() request;
  @Input() form_key;
  @Input() form_request_id;
  @Input() project_data;
  @Input() supervision_data;
  @Input() template_data;
  @Input() allData;
  modalRef: BsModalRef;
  
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  transaction_request:any;
  persons: any = [];
  notes: any = [];
  history:any = [];
  collectionSize:any;
  page = 1;
	pageSize = 10;
  form_url = "";
  form:FormGroup;
  changeSignatureForm:FormGroup;

  lang_key = localStorage.getItem('lang_key');
//stepper
  @ViewChild('stepper') myStepper: MatStepper;
  totalStepsCount: number = 7;
  selectedIndex: number = 0;
  stepProgressValue = 100 / this.totalStepsCount;
  progressValue = this.stepProgressValue;
  stepsTitles = [
    {
      label:`${this.lang.transform("lang_configurations")}`,
      value:'CONFIGURATION'
    },
    {
      label:`${this.lang.transform("lang_overtime_employee")}`,
      value:'EMPLOYEE'
    },
    {
      label: `${this.lang.transform("lang_direct_manager")}`,
      value:'DIRECT_MANAGER'
    },
    {
      label: `${this.lang.transform("lang_human_resource_team")}`,
      value:'HUMAN_RESOURCE_TEAM'
    },
    {
      label: `${this.lang.transform("lang_account_team")}`,
      value:'ACCOUNT_TEAM'
    },
 
    {
      label: `${this.lang.transform("lang_human_resource_manager")}`,
      value:'HUMAN_RESOURCE_MANAGER'
    },
    {
      label: `${this.lang.transform("lang_last_overtime_step")}`,
      value:'last'
    },
    
];
  stepTitle =this.stepsTitles[this.selectedIndex].label;
  @ViewChild('stepTitleEl', {static: false}) stepTitleEl?: ElementRef<HTMLElement>;
  varSettimeout:any;
  isLinear:boolean      = false;
  showStatus:boolean    = false;
  @Output() showAction  = new EventEmitter();
  Configurations_Attachments = [];
  Evaludation_Result_Attachments = [];
  Manager_Approval_Attachments = [];
  Technical_Assistants_Evaluation_Attachments = [];
  All_Attachments = [];
  lastStepOpened = '';
  lastStepOpenedShow = '';
  projects_work_area_id = 0;
  usersTooltip:string='';
  is_admin:any =''
  constructor(public ds:DataService, public datepipe : DatePipe , public ls:LocalStorage,public modalService: BsModalService,
     private dwsrService: DwsrService, public lang:LanguagePipe, public spinner : PreloaderService,
      public alert:AlertService, public fb: FormBuilder, public router: Router,
       public changeDetectorRef: ChangeDetectorRef) {
        this.is_admin = this.ls.getItemString('is_admin')
        console.log('is_admin',this.is_admin);
        
    this.form_url = "FORM_HRLN1";
    this.dwsrService.getData().subscribe(data => {

      if(data.status) {
        this.changeSignatureForm  = new FormGroup({
          transaction_request_id                   : new FormControl(this.form_request_id, [Validators.required]),
          user_id               : new FormControl('', [Validators.required]),
           
        });
      
        this.allData = data;
    this.persons = this.allData?.transactions_persons?.records || [];
    this.notes   = this.allData?.transactions_notes?.records || [];
    this.transaction_request = this.allData?.transactions_request?.records || {};
    this.history = this.allData?.transactions_records.records || [];
    this.collectionSize = this.history.length;
    if(this.allData?.Configurations_Attachments?.status) {
      this.Configurations_Attachments = this.allData?.Configurations_Attachments?.records;
    }
    if(this.allData?.Evaludation_Result_Attachments?.status) {
      this.Evaludation_Result_Attachments = this.allData?.Evaludation_Result_Attachments?.records;
    }
    if(this.allData?.Manager_Approval_Attachments?.status) {
      this.Manager_Approval_Attachments = this.allData?.Manager_Approval_Attachments?.records;
    }
    if(this.allData?.Technical_Assistants_Evaluation_Attachments?.status) {
      this.Technical_Assistants_Evaluation_Attachments = this.allData?.Technical_Assistants_Evaluation_Attachments?.records;
    }
    this.All_Attachments = this.Configurations_Attachments.concat(...this.Evaludation_Result_Attachments, ...this.Manager_Approval_Attachments, ...this.Technical_Assistants_Evaluation_Attachments);
    
    this.lastStepOpened = this.allData?.last_request_step;
    this.projects_work_area_id = this.allData?.form_dwsr_data?.records[0]?.projects_work_area_id;
    this.selectedIndex = this.stepsTitles.findIndex(el => {return el.value == this.lastStepOpened});
    this.lastStepOpenedShow = this.stepsTitles[this.selectedIndex]?.label;
    if(this.lastStepOpened == 'last') {
      this.selectedIndex = 6;
      this.lastStepOpenedShow = this.lang.transform('lang_last');
    }  else if(this.lastStepOpened == 'completed') {
      this.selectedIndex = 6;
      this.lastStepOpenedShow = this.lang.transform('lang_completed');
    }  
    
    this.progressValue = this.stepProgressValue * (this.selectedIndex + 1);
 
    this.getLastOpenedUsers()
      } else {
        this.alert.error(data.error);
        this.router.navigate(['transactions/allforms']);
      }
    
    }, err => {
      this.alert.error(err);
      this.router.navigate(['transactions/allforms']);
    });
    

  }
  ngOnInit(): void {
    this.refreshLists();
    this.getLastOpenedUsers()
  }


  refreshLists() {
    this.history = this.history.map(
			(list, i) => ({
				id: i + 1,
				...list
			})).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  selectionChange(e: any) {
    this.selectedIndex = e.selectedIndex;
    this.progressValue = this.stepProgressValue * (this.selectedIndex + 1);
    this.stepTitle =this.stepsTitles[this.selectedIndex].label;
  }

 moveStepper(action: string) {

    if(action == 'next' && (this.myStepper.selectedIndex + 1) < this.myStepper.steps.length) {
      this.myStepper.selectedIndex++;
    } else if(action == 'prev' && this.myStepper.selectedIndex > 0) {
      this.myStepper.selectedIndex--;
    } else {
      return false;
    }
  }

  
  
  
  submitForm = false;
  submit(){
    this.submitForm = true;
    console.log(this.changeSignatureForm);
    
    if(this.changeSignatureForm.valid) {
      this.spinner.show();
      let formData = new FormData();
      formData.append('user_id',this.changeSignatureForm.value.user_id);
      formData.append('transaction_request_id',this.changeSignatureForm.value.transaction_request_id);

      this.ds.post(`form/${this.form_key}/asp`, formData).subscribe(data => {
        if(data.status) {
          this.spinner.hide();
          this.alert.success(data.msg);
           
          this.submitForm = false;
          setTimeout(() => {
            location.reload();
          }, 2000);
        } else {
          this.spinner.hide();
          this.alert.error(data?.error);
        }
      }, (error) => {
        this.spinner.hide();
          this.alert.error(error);
      })

    }else{
      this.alert.error(this.lang.transform('lang_all_fields_required'));

    }
  }



  getLastOpenedUsers() {
   
    let users = this.persons.filter((item) => {
      return item?.transactions_persons_action_status == '0';
    });
    this.usersTooltip = this.lang.transform('lang_users_access_open_step') + ' : ';
    for(let i=0; i<users?.length; i++) {
      this.usersTooltip += ' ' + users[i]?.person_name  + (i != users[i].length - 1? ' - ':'') ; 
    }
  }
  users:any= []
  public search_users(key) {
    this.users = [];
    let param = new FormData();
    param.append('search', key.term || '');
    param.append('lang_key', this.lang_key);
    param.append('user_type_id', '1');
    this.ds.getActionByUrl(this.ds.formData2string(param), 'tc/getformuserslist').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.users = res.list;
      }
    })
  }

  ngOnDestroy(): void {
    clearTimeout(this.varSettimeout);
  }

}
