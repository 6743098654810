import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { DwsrService } from 'app/routes/transactions/FORM_PS_TR1/services/dwsr.service';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-versions-show',
  templateUrl: './versions-show.component.html',
  styleUrls: ['./versions-show.component.scss']
})
export class VersionsShowComponent implements OnInit {
  versions:any;
  projects_work_area_id:any;
  platform_code_system:any;
  template_type:any;
  lang_key = 'en';
  versions_page_no = '1';
  versions_page_size = '10';
  template_id:any;
  form_request_id = this.route.snapshot.paramMap.get('form_request_id');
  form_url = this.route.snapshot.paramMap.get('form_key')
  unit_levels = [];
  units_levels_edited:any;
  nodata: string = this.lang.transform('lang_loading');

  constructor(public ds:DataService, public ls:LocalStorage, public lang:LanguagePipe, public dwsrService:DwsrService,
    public spinner : PreloaderService, public alert:AlertService, public router:Router, private route:ActivatedRoute ) {
      this.dwsrService.form_key = this.form_url;
      this.dwsrService.form_request_id = this.form_request_id;
      this.dwsrService.getData().subscribe((data) => {
        if(this.form_url == 'FORM_DSR') {
          this.projects_work_area_id = data?.view_request?.form_dsr_data?.records[0].projects_work_area_id;
        this.platform_code_system = data?.view_request?.form_dsr_data?.records[0].platform_code_system;
        this.template_id = data?.view_request?.form_dsr_data?.records[0].template_id;
        this.form_request_id = data?.view_request?.form_dsr_data?.records[0].transaction_request_id;
        }
        if(this.form_url == 'FORM_WIR') {
          this.projects_work_area_id = data?.view_request?.form_wir_data?.records[0].projects_work_area_id;
        this.platform_code_system = data?.view_request?.form_wir_data?.records[0].platform_code_system;
        this.template_id = data?.view_request?.form_wir_data?.records[0].template_id;
        this.form_request_id = data?.view_request?.form_wir_data?.records[0].transaction_request_id;
        }
        if(this.form_url == 'FORM_MSR') {
          this.projects_work_area_id = data?.view_request?.form_msr_data?.records[0].projects_work_area_id;
        this.platform_code_system = data?.view_request?.form_msr_data?.records[0].platform_code_system;
        this.template_id = data?.view_request?.form_msr_data?.records[0].template_id;
        this.form_request_id = data?.view_request?.form_msr_data?.records[0].transaction_request_id;
        this.template_type = data?.view_request?.form_msr_data?.records[0].template_type;
        }
        if(this.form_url == 'FORM_SQR') {
          this.projects_work_area_id = data?.view_request?.form_sqr_data?.records[0].projects_work_area_id;
        this.platform_code_system = data?.view_request?.form_sqr_data?.records[0].platform_code_system;
        this.template_id = data?.view_request?.form_sqr_data?.records[0].template_id;
        this.form_request_id = data?.view_request?.form_sqr_data?.records[0].transaction_request_id;
        this.template_type = data?.view_request?.form_sqr_data?.records[0].template_type;
        }
        if(this.form_url == 'FORM_MIR') {
          this.projects_work_area_id = data?.view_request?.form_mir_data?.records[0].projects_work_area_id;
        this.platform_code_system = data?.view_request?.form_mir_data?.records[0].platform_code_system;
        this.template_id = data?.view_request?.form_mir_data?.records[0].template_id;
        this.form_request_id = data?.view_request?.form_mir_data?.records[0].transaction_request_id;
        this.template_type = data?.view_request?.form_mir_data?.records[0].template_type;
        }
        this.lang_key = data?.view_request?.transactions_request?.records?.lang_key;
        this.form_url = data?.form_url;
        let units_level_data = data?.view_request?.project_supervision_form_unit_level?.records;
        if(units_level_data && units_level_data?.length) {
          for(let item of units_level_data) {
            this.unit_levels.push({
              work_levels: item?.work_level_key,
              units:item?.unit_id
            })
          }
          this.units_levels_edited = this.dwsrService.refinementData(this.unit_levels);
        }
        this.get_form_versions(this.dwsrService.form_key);
      });
    }

  ngOnInit(): void {
    
  }

  get_form_versions(form_url) {
    this.alert.clear();
    this.spinner.show();
      let formData = new FormData();
      formData.append('projects_work_area_id', this.projects_work_area_id);
      formData.append('platform_code_system',  this.platform_code_system);
      formData.append('lang_key', this.lang_key);
      formData.append('page_no', this.versions_page_no);
      formData.append('page_size', this.versions_page_size);
      if(this.unit_levels?.length && this.unit_levels) {
        formData.append('transaction_separation', '0');
        formData.append('work_site', 'GN');
        formData.append('method', 'VIEW');
        for(let key in this.units_levels_edited) {
          formData.append(`units_and_level[${key}]`, this.units_levels_edited[key]);
        }
      } else {
        formData.append('method', 'VIEW');
      }
      
      
      formData.append('template_id', this.template_id);
      let url = 'cr/3'
      console.log(form_url)
      if(form_url == 'FORM_MSR' || form_url == 'FORM_SQR') {
        url = 'cr/4'
      }
      let stepUrl = `${this.dwsrService.form_key}/${url}/${this.form_request_id}`;
      
      this.ds.post('form/'+ stepUrl, formData).subscribe(res => {
        this.spinner.hide();
        if(res.status) {
          this.versions = res;
          this.nodata = res?.msg;
        } else {
          if(res?.error) {
            this.nodata = res?.error;
          }
          
          if(res?.msg) {
            this.nodata = res?.msg;
          }
          this.versions = [];
        }
        
      }, err => {
        this.spinner.hide();
        this.alert.error(this.lang.transform('lang_server_error'));
      })
  }

  //load data by page Size
  public load_by_page_size(size, tableName:string) {
    if(tableName == 'versions') {
      this.versions_page_size = size;
      this.get_form_versions(this.dwsrService.form_key);
    }
    
  }
  // load data by page size
  public load_by_page_no(e, tableName:string) {
    if(tableName == 'versions') {
      this.versions_page_no = e.value;
      this.get_form_versions(this.dwsrService.form_key);
    }
  }

}
