import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
import { environment } from '@env/environment';
import { AlertService } from '@shared/components/alert/alert.service';
import { ConfirmationDialog } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
interface list {
  id?: number;
  finance_id: string,
  branch_id: string,
  label: string,
  writer_name: string,
  created_date:string,
  finance_status:string,
}
@Component({
  selector: 'app-financeyear',
  templateUrl: './financeyear.component.html',
  styleUrls: ['./financeyear.component.scss']
})
export class FinanceyearComponent implements OnInit {
  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  @ViewChild('NewFinancial') financeadd;
  form: FormGroup;
  listofdatas: list[];
  data:any = [];
  page = 1;
  abranches:any = [];
  pageSize = 10;
  collectionSize;
  branch_id: any = (localStorage.getItem('selectedBranch') != null) ? localStorage.getItem('selectedBranch') : '0';
  lodingdatas = this.lang.transform('lang_loading');
  constructor(public ds:DataService,public ls:LocalStorage ,public lang:LanguagePipe, public spinner:PreloaderService, public fb:FormBuilder, public alert:AlertService, public dialog:MatDialog, public modalService: BsModalService,) { }
  ngOnInit(): void {
    this.spinner.show();
    this.buildform();
    if (this.branch_id && this.branch_id != '0') {
      this.load_financial_years();
    }
  }
  public changeBranch(branch) {
    this.spinner.show();
    this.branch_id = branch;
    this.load_financial_years();
  }
  public load_financial_years() {
    this.ds.getActionByUrl([], 'financial/' + this.branch_id).subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.listofdatas = res.records;
        this.collectionSize = res.records.length;
        this.refreshLists();
      } else {
        this.data = [];
      }
    }, error => {
      this.spinner.hide();
      this.data = [];
    })
  }
  public refreshLists() {
    this.data = this.listofdatas.map((list, i) => ({ id: i + 1, ...list })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }
  public load_add_branches() {
    this.abranches = [];
    this.form.reset();
    this.spinner.show();
    this.ds.getActionByUrl([], 'ab/accounts_add').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.abranches = res.records;
        this.openModal(this.financeadd);
      }
    }, error => {
      this.spinner.hide();
    })
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      financial_start_date: new FormControl('', [Validators.required]),
      financial_end_date: new FormControl('', [Validators.required]),
    })
  }
  public emptyform() {
    this.alert.clear();
    this.form.reset();
    this.form.get('branch_id').setValue(this.branch_id);
  }
  public submit() {
    this.spinner.show();
    let param = new FormData();
    param.append('branch_id', this.branch_id);   
    param.append('financial_start_date', this.form.get('financial_start_date').value || '');
    param.append('financial_end_date', this.form.get('financial_end_date').value || '');
    this.ds.postActionByUrl(param, 'cfyear').subscribe(res => {
      this.spinner.hide();
      if (res.status) {
        this.form.reset();
        this.load_financial_years();
        this.alert.success(res.msg);
        this.modalRef.hide();
      } else {
        this.alert.error(res.error);
      }
    }, error => {
      this.spinner.hide();
      this.alert.error((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
    })
  }
  public close_financial_year(his) {
    const dialogRef = this.dialog.open(ConfirmationDialog,{
      disableClose: true,
      width:'400px',
      data:{
        title: this.lang.transform('lang_confirmation'),
        message: this.lang.transform('lang_are_you_sure'),          
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show()
        this.ds.getActionByUrl([], 'closeyear/' + his.branch_id + '/' + his.finance_id).subscribe((data) => {
          this.spinner.hide();
          if (data.status) {
            this.load_financial_years();
            this.ds.dialogf('', data.msg);
          } else {
            this.ds.dialogf('', data.error);
          }
        },(error) => {
            this.spinner.hide();
            this.ds.dialogf('', (error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'));
          }
        ); 
      }
    });
  }
}
